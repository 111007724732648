import {NgModule} from "@angular/core";
import {ClarityModule, ClrModalModule, ClrTabsModule} from "@clr/angular";
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from '@angular/platform-browser';
import {AdministrationRoutingModule} from './administration-routing.module';

import {MngRolesComponent} from "./mng-roles/mng-roles.component";
import {MngUsersComponent} from "./mng-users/mng-users.component";
import {MngCoursesComponent} from "./mng-courses/mng-courses.component";
import {MngSkillsComponent} from "./mng-skills/mng-skills.component";
import {MngMasterSurveyComponent} from "./mng-master-survey/mng-master-survey.component";
import {MngRolesProfilesComponent} from "./mng-roles-profiles/mng-roles-profiles.component";
import {MngEmailTemplatesComponent} from "./mng-email-templates/mng-email-templates.component";
import {TabRolesUsersComponent} from './tab-roles-users/tab-roles-users.component';
import {EditEmailTemplateComponent} from './mng-email-templates/edit-email-template/edit-email-template.component';
import {TabMasterSurveyComponent} from './mng-master-survey/tab-master-survey/tab-master-survey.component';
import {MasterSurveyComponent} from './mng-master-survey/master-survey/master-survey.component';
import {MapSkillComponent} from './mng-master-survey/map-skill/map-skill.component';
import {PreviewComponent} from './mng-master-survey/preview/preview.component';
import {MngSectionComponent} from "./mng-master-survey/master-survey/mng-section/mng-section.component";
import {MngRoleProfileGroupsComponent} from "./mng-role-profile-groups/mng-role-profile-groups.component";
import {FreeTextComponent} from './mng-master-survey/master-survey/mng-section/free-text/free-text.component';
import {RadioButtonComponent} from './mng-master-survey/master-survey/mng-section/radio-button/radio-button.component';
import {
  MultipleChoiceComponent
} from './mng-master-survey/master-survey/mng-section/multiple-choice/multiple-choice.component';
import {
  DescriptionTextComponent
} from './mng-master-survey/master-survey/mng-section/description-text/description-text.component';
import {ScaleComponent} from './mng-master-survey/master-survey/mng-section/scale/scale.component';

//summernote module
import {NgxSummernoteModule} from 'ngx-summernote';
import {SafePipeModule} from 'safe-pipe';

//translation modules
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
  TranslateService,
  TranslateStore
} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {MatrixTableComponent} from "./mng-master-survey/master-survey/mng-section/matrix-table/matrix-table.component";
import {MandatoryPopUpComponent} from './mng-master-survey/master-survey/mandatory-pop-up/mandatory-pop-up.component';
import {MapSkillPopUpComponent} from './mng-master-survey/master-survey/map-skill-pop-up/map-skill-pop-up.component';
import {
  ImageUploadPopUpComponent
} from './mng-master-survey/master-survey/image-upload-pop-up/image-upload-pop-up.component';
import {
  DisplayLogicPopUpComponent
} from './mng-master-survey/master-survey/display-logic-pop-up/display-logic-pop-up.component';
import {SkipLogicPopUpComponent} from './mng-master-survey/master-survey/skip-logic-pop-up/skip-logic-pop-up.component';
import {
  SelectRoleProfileComponent
} from './mng-master-survey/master-survey/mng-section/select-role-profile/select-role-profile.component';

import {FiltersModule} from "../filters/filters.module";
import {DirectiveModule} from "../../directives/directive.module";
import {MngSkillOrderComponent} from './mng-skill-order/mng-skill-order.component';
import {
  SectionDisplayLogicPopUpComponent
} from './mng-master-survey/master-survey/mng-section/section-display-logic-pop-up/section-display-logic-pop-up.component';
import {CdsAlertModule} from "@cds/angular";
import {CanDeactivateGuard} from "../../services/check-survey.service";
import {
  TranslateMasterSurveyComponent
} from './mng-master-survey/translate-master-survey/translate-master-survey.component';
import {
  TranslateMasterSurveyQuestionComponent
} from './mng-master-survey/translate-master-survey/translate-master-survey-question/translate-master-survey-question.component';
import {FilterSurveyL10nStatusPipe} from './mng-master-survey/filter-survey-l10n-status.pipe';
import {
  MngSkillsRoleProfilesAndGroupsComponent
} from './mng-skills-role-profiles-and-groups/mng-skills-role-profiles-and-groups.component';
import {UpperCamelCasePipe} from "../../pipes/upper-camel-case.pipe";
import {
  TabMngSkillsSkillOrderRoleProfilesAndRoleProfileGroupsComponent
} from './tab-mng-skills-skill-order-role-profiles-and-role-profile-groups/tab-mng-skills-skill-order-role-profiles-and-role-profile-groups.component';
import {
  MngRolesProfilesSaveModalComponent
} from './mng-roles-profiles/mng-roles-profiles-save-modal/mng-roles-profiles-save-modal.component';
import {PipesModule} from '../../pipes/pipes.module';

export function translateServiceFactory(store: TranslateStore, loader: TranslateLoader, compiler: TranslateCompiler, parser: TranslateParser, missingHandler: MissingTranslationHandler) {
  // @ts-ignore
  const res: TranslateService = new TranslateService(store, loader, compiler, parser, missingHandler);
  res.setDefaultLang('en-us');
  return res;
}

export function translateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    MngRolesComponent,
    MngUsersComponent,
    MngSkillsComponent,
    MngMasterSurveyComponent,
    MngRolesProfilesComponent,
    MngEmailTemplatesComponent,
    MngCoursesComponent,
    TabRolesUsersComponent,
    MngSectionComponent,
    TabMasterSurveyComponent,
    MasterSurveyComponent,
    MapSkillComponent,
    PreviewComponent,
    MngRoleProfileGroupsComponent,
    EditEmailTemplateComponent,
    FreeTextComponent,
    RadioButtonComponent,
    MultipleChoiceComponent,
    DescriptionTextComponent,
    ScaleComponent,
    MatrixTableComponent,
    MandatoryPopUpComponent,
    MapSkillPopUpComponent,
    ImageUploadPopUpComponent,
    DisplayLogicPopUpComponent,
    SkipLogicPopUpComponent,
    SelectRoleProfileComponent,
    MngSkillOrderComponent,
    SectionDisplayLogicPopUpComponent,
    TranslateMasterSurveyComponent,
    TranslateMasterSurveyQuestionComponent,
    FilterSurveyL10nStatusPipe,
    MngSkillsRoleProfilesAndGroupsComponent,
    UpperCamelCasePipe,
    TabMngSkillsSkillOrderRoleProfilesAndRoleProfileGroupsComponent,
    MngRolesProfilesSaveModalComponent
  ],
  imports: [
    DirectiveModule,
    AdministrationRoutingModule,
    ClrModalModule,
    SafePipeModule,
    ClrTabsModule,
    CommonModule,
    BrowserModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    FiltersModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoader,
        deps: [HttpClient]
      }
    }),
    CdsAlertModule,
    PipesModule
  ],
  exports: [
    DirectiveModule,
    MngRolesComponent,
    MngUsersComponent,
    MngCoursesComponent,
    MngSkillsComponent,
    MngMasterSurveyComponent,
    MngRolesProfilesComponent,
    MngEmailTemplatesComponent,
    TabRolesUsersComponent,
    MandatoryPopUpComponent
  ],
  providers: [CanDeactivateGuard],
  entryComponents: [FreeTextComponent, RadioButtonComponent, MultipleChoiceComponent, ScaleComponent, DescriptionTextComponent]
})
export class AdministrationModule {
}
