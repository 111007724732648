/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ResponseColumnWithParticipants} from "../models/respose-data.model";
import {ResponseColumnWithParticipantsEntity} from "../models/analysis-response-data.model";

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  constructor(private translate: TranslateService) {
  }

  public getAnswerFromParticipant(participantAnswersForAllQuestions: (ResponseColumnWithParticipantsEntity | ResponseColumnWithParticipants)[], id: number): string {
    const responseColumn: ResponseColumnWithParticipantsEntity | ResponseColumnWithParticipants = participantAnswersForAllQuestions
      ?.find((participantAnswerForQuestion) => id && participantAnswerForQuestion?.responseColumnDisplay?.id === id);

    return responseColumn && responseColumn.answerText ? responseColumn.answerText : this.translate.instant('analysisAndReports.reports.notAvailable');
  }
}
