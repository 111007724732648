import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-checkbox-filter',
  templateUrl: './checkbox-filter.component.html'
})
export class CheckboxFilterComponent {

  options: string = "";
  @Output() statusFilter = new EventEmitter<any>();

  show() {
    if (this.options === "None")
      this.options = "";
    this.statusFilter.emit(this.options);
  }
}
