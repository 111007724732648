import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MasterSurveyService} from '../../../../../services/master-survey.service';
import {SurveyControls} from "../../../../../enums/survey-controls.enum";
import {ShareSurveyDataService} from "../../../../../services/share-survey-data.service";
import {Subscription} from "rxjs";
import {hideSpinner, showSpinner} from "../../../../../common/spinner";
import {finalize} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {SkillsService} from "../../../../../services/skills.service";
import {Status} from "../../../../../enums/status.enum";

@Component({
  selector: 'app-map-skill-pop-up',
  templateUrl: './map-skill-pop-up.component.html',
  styleUrls: ['./map-skill-pop-up.component.scss']
})
export class MapSkillPopUpComponent implements OnInit, OnDestroy {
  @Input() questionData;
  @Input() sectionReference;
  @Input() showMapSkillPopUp;
  @Output() showMapSkill: EventEmitter<boolean> = new EventEmitter();
  selectedSkillToMap: any;
  mapSkill: {
    id: number
  } = {
    id: 0
  }
  activeSkills: any[] = [];
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly masterService: MasterSurveyService,
              private readonly shareSurveyData: ShareSurveyDataService,
              private readonly translate: TranslateService,
              private readonly skillService: SkillsService) {
  }


  ngOnInit(): void {
    if (this.sectionReference?.sectionData?.sectionQuestions?.length > 0) {
      this.selectedSkillToMap = this.sectionReference.sectionData.sectionQuestions.find(x => x.id == this.questionData.value.id).skill;
    }
    const surveyTemplateSubscription = this.shareSurveyData.getSurvey().subscribe(surveyData => {
      this.loadSkills(surveyData?.surveyTemplateConfigVersion?.id);
    });
    this.subscriptions.push(surveyTemplateSubscription);
  }

  trackById(index, skill) {
    return skill.id;
  }

  private loadSkills(surveyTemplateConfigVersionId: number) {
    if (surveyTemplateConfigVersionId && this.activeSkills?.length === 0) {
      showSpinner(this.translate.instant("spinnerLabels.common.loading"));
      const activeSkillsSubscription = this.skillService.getSkillsByConfigVersionIdAndStatus(surveyTemplateConfigVersionId, Status.Active)
        .pipe(finalize(hideSpinner))
        .subscribe(data => {
          this.activeSkills = data;
        });
      this.subscriptions.push(activeSkillsSubscription);
    }
  }

  onMapSkillToQuestion(): void {
    this.setSurveyUpdateStatus();
    this.showMapSkill.emit(false);
    this.mapSkill.id = this.selectedSkillToMap.id;
    this.questionData.get(this.surveyControlsEnum.skill).setValue(this.mapSkill);
  }

  onClosePopUp() {
    this.showMapSkill.emit(false)
  }

  setSurveyUpdateStatus(): void {
    this.masterService.setSurveyUpdateStatus(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
