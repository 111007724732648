import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MasterSurveyService} from '../../../../../services/master-survey.service';
import {SurveyControls} from "../../../../../enums/survey-controls.enum";

@Component({
  selector: 'app-mandatory-pop-up',
  templateUrl: './mandatory-pop-up.component.html',
  styleUrls: ['./mandatory-pop-up.component.scss']
})
export class MandatoryPopUpComponent implements OnInit {
  @Input() questionData;
  @Input() showMandatoryTypePopUp;
  @Output() showMandatory: EventEmitter<boolean> = new EventEmitter();
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  originalSelection: boolean;
  revertChange: boolean = true;

  constructor(private masterSurveyService: MasterSurveyService) { }

  ngOnInit(): void {
    this.originalSelection = this.questionData.get(this.surveyControlsEnum.isMandatory).value;
  }

  onMandatoryQuestionInput(value: boolean): void {
    this.questionData.get(this.surveyControlsEnum.isMandatory).setValue(value);
  }

  onClosePopUp(): void{
    this.showMandatory.emit(false);
  }

  setSurveyUpdateStatus(): void{
    this.masterSurveyService.setSurveyUpdateStatus(false);
  }

  onCancelMandatorySelection(): void{
    if (this.revertChange) {
      this.questionData.get(this.surveyControlsEnum.isMandatory).setValue(this.originalSelection);
      this.showMandatory.emit(false);
    }
  }

  setMandatorySelection(): void{
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.showMandatory.emit(false);
  }
}
