/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SkipCondition} from '../models/ksa-role-profile-skill.model';
import {EditingSurveyData} from '../models/editing-survey-data.model';
import {EditingInfo} from '../models/editing-info';
import {environment} from '../../environments/environment';
import {MasterSurvey, MasterSurveySimple} from '../models/master-survey.model';
import {API_KSA_CORE_V1} from '../common/constants/api-prefix.constants';

@Injectable({
  providedIn: 'root'
})
export class MasterSurveyService {

  private readonly masterSurveyURL = `${API_KSA_CORE_V1}/masterSurvey/`;
  private readonly surveyTagURL = `${API_KSA_CORE_V1}/surveytag/`;
  private surveyTemplateUpdateBtnStatus: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private previewStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private surveyStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  masterSurvey: MasterSurvey;


  editingData: EditingSurveyData;

  constructor(private http: HttpClient) {
  }

  setEditingUserData(survey: MasterSurvey): void {
    this.editingData = new EditingSurveyData(survey);
  }

  getSurveyUpdateStatus(): Observable<boolean> {
    return this.surveyTemplateUpdateBtnStatus.asObservable();
  }

  setSurveyUpdateStatus(data: boolean) {
    this.surveyTemplateUpdateBtnStatus.next(data);
  }

  clearSurveyUpdateStatus() {
    this.surveyTemplateUpdateBtnStatus.complete();
  }

  getPreviewState(): Observable<boolean> {
    return this.previewStatus.asObservable();
  }

  setPreviewState(data: boolean) {
    this.previewStatus.next(data);
  }

  getSurveyState(): Observable<boolean> {
    return this.surveyStatus.asObservable();
  }

  setSurveyState(data: boolean) {
    this.surveyStatus.next(data);
  }


  getMasterSurvey(): Observable<MasterSurvey[]> {
    return this.http.get<MasterSurvey[]>(environment.API_URL + this.masterSurveyURL);
  }

  getPublishedMasterSurvey(): Observable<MasterSurvey[]> {
    return this.http.get<MasterSurvey[]>(environment.API_URL + this.masterSurveyURL + 'published/');
  }

  getMasterSurveyById(publishedId: number): Observable<MasterSurvey> {
    return this.http.get<MasterSurvey>(environment.API_URL + this.masterSurveyURL + publishedId);
  }

  addMasterSurvey(masterSurveyDetail: MasterSurvey): Observable<any> {
    return this.http.post<MasterSurvey>(environment.API_URL + this.masterSurveyURL, masterSurveyDetail);
  }

  editMasterSurvey(masterSurveyDetail: MasterSurvey): Observable<MasterSurvey> {
    return this.http.put<MasterSurvey>(environment.API_URL + this.masterSurveyURL, masterSurveyDetail);
  }

  deleteMasterSurvey(surveyId: number): Observable<void> {
    return this.http.delete<void>(environment.API_URL + this.masterSurveyURL + surveyId);
  }

  uploadImage(imageData: FormData): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}${API_KSA_CORE_V1}/uploadImage`, imageData);
  }

  publishMasterSurvey(surveyId: number): Observable<any> {
    return this.http.put<any>(environment.API_URL + this.masterSurveyURL + 'publish/', surveyId);
  }

  archiveMasterSurvey(surveyId: number): Observable<any> {
    return this.http.put<any>(environment.API_URL + this.masterSurveyURL + 'archive/', surveyId);
  }

  archiveMultipleMasterSurveys(ids: number[]): Observable<any> {
    return this.http.put<any>(environment.API_URL + this.masterSurveyURL + 'archiveSurveys/', ids);
  }

  getSurveyName(surveyName: any) {
    const sectionNamesURL = 'surveyname/';
    return this.http.get<MasterSurvey[]>(environment.API_URL + this.masterSurveyURL + sectionNamesURL + encodeURIComponent(`${surveyName}`));
  }

  getQuestionSkipTypes(): Observable<any> {
    return this.http.get<any>(environment.API_URL + this.masterSurveyURL + `questiontype`)
  }

  getMasterSurveyPageEnhanced(): Observable<MasterSurveySimple[]> {
    return this.http.get<MasterSurveySimple[]>(`${environment.API_URL + this.masterSurveyURL}all/surveys`);
  }

  getMasterSurveyDetails(id): Observable<any> {
    return this.http.get<any>(`${environment.API_URL + this.masterSurveyURL}${id}`);
  }

  getSurveyTag(): Observable<any> {
    return this.http.get<any[]>(environment.API_URL + this.surveyTagURL);
  }

  createSurveyTag(tag): Observable<any> {
    return this.http.post<any>(environment.API_URL + this.surveyTagURL, tag);
  }

  getAllSkipConditions(): Observable<SkipCondition[]> {
    return this.http.get<SkipCondition[]>(environment.API_URL + this.masterSurveyURL + `getAllSkipConditions`)
  }

  updateMasterSurveyEditor(surveyId: number): Observable<EditingInfo> {
    return this.http.put<EditingInfo>(`${environment.API_URL + this.masterSurveyURL}editor/${surveyId}`, {});
  }

}
