<clr-modal id="participant-dialog"
           [clrModalStaticBackdrop]="false"
           [clrModalOpen]="true" (clrModalOpenChange)="close()">
  <h3 *ngIf="dialogMode === DIALOG_MODES.CREATE" class="modal-title">
    {{'ksaEngagement.emailManagement.sendInvite.participantDialog.title.add'| translate}}
  </h3>
  <h3 *ngIf="dialogMode === DIALOG_MODES.EDIT" class="modal-title">
    {{'ksaEngagement.emailManagement.sendInvite.participantDialog.title.edit'| translate}}
  </h3>
  <div class="modal-body">
    <form clrForm #participantForm="ngForm" [clrLabelSize]="4">
      <clr-alert clrAlertType="danger" *ngIf="errorMessage" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
        <clr-alert-item>{{errorMessage}}</clr-alert-item>
      </clr-alert>
      <clr-alert clrAlertType="danger" *ngIf="isEmailExist(participant.email)"
                 [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
        <clr-alert-item>{{'ksaEngagement.emailManagement.sendInvite.participantDialog.validation.duplicateMail' | translate}}</clr-alert-item>
      </clr-alert>
      <clr-select-container *ngIf="dialogMode === DIALOG_MODES.EDIT">
        <label for="selectParticipant" class="clr-control-label required-input">
          {{'ksaEngagement.emailManagement.sendInvite.participantDialog.selectParticipant' | translate}}
        </label>
        <select id="selectParticipant" (ngModelChange)="onParticipantChanged($event)" clrSelect name="participant"
                [(ngModel)]="selectedParticipant" class="clr-control-inline clr-col-8">
          <option *ngFor="let participant of participants" [ngValue]="participant">
            {{participant.email}}
          </option>
        </select>
        <clr-control-helper>
          {{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.selectParticipant' | translate}}
        </clr-control-helper>
        <span class="clr-subtext">Error message</span>
      </clr-select-container>
      <div
        *ngIf="dialogMode === DIALOG_MODES.CREATE || (dialogMode === DIALOG_MODES.EDIT && participant.id)">
        <clr-input-container>
          <label for="firstName" class="clr-control-label required-input">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.firstName' | translate}}
          </label>
          <input id="firstName" type="text" [(ngModel)]="participant.firstName" name="firstName"
                 placeholder="{{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.firstName' | translate}}"
                 clrInput required/>
          <clr-control-error *clrIfError="'required'">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.validation.field' | translate}}
          </clr-control-error>
        </clr-input-container>
        <clr-input-container>
          <label for="lastName" class="clr-control-label required-input">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.lastName' | translate}}
          </label>
          <input id="lastName" type="text" [(ngModel)]="participant.lastName" name="lastName"
                 placeholder="{{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.lastName' | translate}}"
                 clrInput required/>
          <clr-control-error *clrIfError="'required'">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.validation.field'|translate}}
          </clr-control-error>
        </clr-input-container>
        <clr-input-container>
          <label for="lastName" class="clr-control-label required-input">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.email' | translate}}
          </label>
          <input id="email" type="text" [(ngModel)]="participant.email" name="email"
                 placeholder="{{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.email' | translate}}"
                 clrInput required/>
          <clr-control-error *clrIfError="'required'">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.validation.field' | translate}}
          </clr-control-error>
        </clr-input-container>
        <clr-input-container>
          <label for="jobTitle" class="clr-control-label">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.jobTitle' | translate}}
          </label>
          <input id="jobTitle" type="text" [(ngModel)]="participant.jobTitle" name="jobTitle"
                 placeholder="{{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.jobTitle' | translate}}"
                 clrInput/>
        </clr-input-container>
        <clr-input-container>
          <label for="teamName" class="clr-control-label">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.teamName' | translate}}
          </label>
          <input id="teamName" type="text" [(ngModel)]="participant.teamName" name="teamName"
                 placeholder="{{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.teamName' | translate}}"
                 clrInput/>
        </clr-input-container>
        <clr-input-container>
          <label for="managerName" class="clr-control-label">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.managerName' | translate}}
          </label>
          <input id="managerName" type="text" [(ngModel)]="participant.managerName" name="managerName"
                 placeholder="{{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.managerName' | translate}}"
                 clrInput/>
        </clr-input-container>
        <clr-input-container>
          <label for="sponsorName" class="clr-control-label">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.sponsorName' | translate}}
          </label>
          <input id="sponsorName" type="text" [(ngModel)]="participant.sponsorName" name="sponsorName"
                 placeholder="{{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.sponsorName' | translate}}"
                 clrInput/>
        </clr-input-container>
        <clr-input-container>
          <label for="sponsorTitle" class="clr-control-label">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.sponsorTitle' | translate}}
          </label>
          <input id="sponsorTitle" type="text" [(ngModel)]="participant.sponsorTitle" name="sponsorTitle"
                 placeholder="{{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.sponsorTitle' | translate}}"
                 clrInput/>
        </clr-input-container>
        <clr-input-container>
          <label for="language" class="clr-control-label">
            {{'ksaEngagement.emailManagement.sendInvite.participantDialog.language' | translate}}
          </label>
          <input id="language" type="text" [(ngModel)]="participant.language" name="language"
                 placeholder="{{'ksaEngagement.emailManagement.sendInvite.participantDialog.placeholders.language' | translate}}"
                 clrInput/>
        </clr-input-container>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button id="close" type="button" class="btn btn-link" (click)="close()">
      {{'ksaEngagement.emailManagement.sendInvite.participantDialog.buttons.cancel'|translate}}
    </button>
    <button id="saveBtn" type="submit" class="btn btn-primary" (click)="onSave()" [disabled]="isEmailExist(participant.email)">
      <span *ngIf="dialogMode === DIALOG_MODES.CREATE">{{'ksaEngagement.emailManagement.sendInvite.participantDialog.buttons.add' | translate}}</span>
      <span
        *ngIf="dialogMode === DIALOG_MODES.EDIT">{{'ksaEngagement.emailManagement.sendInvite.participantDialog.buttons.save' | translate}}</span>
    </button>
  </div>
</clr-modal>
