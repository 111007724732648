<div class="flex clr-flex-items-sm-between" *ngIf="hasWritePermissions">

  <button class="btn btn-sm btn-warning-outline"
          (click)="onDeactivateConfigVersions()" [disabled]="selectedConfigVersionIds.length === 0">
    {{'admin.mngSkillsRoleProfilesAndGroups.deactivate' | translate}}
  </button>

  <div class="btn-group btn-primary">
    <div class="btn-group-overflow" [ngClass]="{'open': createConfigBtnsExpanded}">
      <button class="btn btn-sm dropdown-toggle"
              (click)="createConfigBtnsExpanded = !createConfigBtnsExpanded">
        {{'admin.mngSkillsRoleProfilesAndGroups.createConfig' | translate}}
      </button>
      <div class="dropdown-menu">
        <button class="btn" (click)="onCreate()">
          {{'admin.mngSkillsRoleProfilesAndGroups.new' | translate}}</button>
        <button (click)="openCloneSurveyModal()"
                class="btn">{{'admin.mngSkillsRoleProfilesAndGroups.fromExisting' | translate}}</button>
      </div>
    </div>
  </div>

</div>


<clr-datagrid class="parent-datagrid" [(clrDgSelected)]="selectedConfigVersionIds" [clrDgRowSelection]="false">

  <clr-dg-column>{{'admin.mngSkillsRoleProfilesAndGroups.skillAndRoleProfileConfigName' | translate}}</clr-dg-column>
  <clr-dg-column>{{'admin.mngSkillsRoleProfilesAndGroups.surveyTemplateName' | translate}}</clr-dg-column>
  <clr-dg-column>{{'admin.mngSkillsRoleProfilesAndGroups.status' | translate}}</clr-dg-column>

  <clr-dg-row *ngFor="let configVersion of allConfigVersions" [clrDgItem]="configVersion.id"
              [clrDgSelectable]="configVersion.status === surveyTemplateConfigStatus.ACTIVE">

    <clr-dg-cell><a class="href-underline label-light-blue"
                    (click)="routeToEditConfigPage(configVersion.id)">{{configVersion.name}}</a>
    </clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let name of configVersion.masterSurveyNames">
        <li>{{name}}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell>{{configVersion.status | upperCamelCase}}</clr-dg-cell>

  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgPage]="page" [clrDgTotalItems]="allConfigVersions.length">
      <clr-dg-page-size
        [clrPageSizeOptions]="[10,20,50,100]">{{'admin.mngSkillsRoleProfilesAndGroups.itemsPerPage' | translate}}</clr-dg-page-size>
      {{pagination.firstItem + 1}}
      - {{pagination.lastItem + 1}} {{'admin.mngSkillsRoleProfilesAndGroups.of' | translate}}
      {{pagination.totalItems}} {{'admin.mngSkillsRoleProfilesAndGroups.items' | translate}}
    </clr-dg-pagination>
  </clr-dg-footer>

</clr-datagrid>

<clr-modal *ngIf="cloneSurveyTemplateConfigModalOpen" [(clrModalOpen)]="cloneSurveyTemplateConfigModalOpen"
           [clrModalClosable]="true" class="modal-xl">
  <h3 class="modal-title">{{'admin.mngSkillsRoleProfilesAndGroups.createNewConfigFromExisting' | translate}}</h3>

  <div class="modal-body modal-body-layout">
    <h5>{{'admin.mngSkillsRoleProfilesAndGroups.selectConfigToCreateNew' | translate}}</h5>

    <clr-datagrid class="parent-datagrid" [(clrDgSelected)]="selectedConfigVersionIdsToClone"
                  [clrDgRowSelection]="false">

      <clr-dg-column>{{'admin.mngSkillsRoleProfilesAndGroups.skillAndRoleProfileConfigName' | translate}}</clr-dg-column>
      <clr-dg-column>{{'admin.mngSkillsRoleProfilesAndGroups.surveyTemplateName' | translate}}</clr-dg-column>

      <clr-dg-row *ngFor="let configVersion of allConfigVersions" [clrDgItem]="configVersion">

        <clr-dg-cell>{{configVersion.name}}</clr-dg-cell>
        <clr-dg-cell>
          <ul *ngFor="let name of configVersion.masterSurveyNames">
            <li>{{name}}</li>
          </ul>
        </clr-dg-cell>

      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgPage]="page"
                           [clrDgTotalItems]="allConfigVersions.length">
          <clr-dg-page-size
            [clrPageSizeOptions]="[10,20,50,100]">{{'admin.mngSkillsRoleProfilesAndGroups.itemsPerPage' | translate}}</clr-dg-page-size>
          {{pagination.firstItem + 1}}
          - {{pagination.lastItem + 1}} {{'admin.mngSkillsRoleProfilesAndGroups.of' | translate}}
          {{pagination.totalItems}} {{'admin.mngSkillsRoleProfilesAndGroups.items' | translate}}
        </clr-dg-pagination>


      </clr-dg-footer>
    </clr-datagrid>

  </div>
  <div class="modal-footer">
    <button type="button" (click)="cloneSurveyTemplateConfigModalOpen=false"
            class="btn btn-sm btn-outline">{{'admin.mngSkillsRoleProfilesAndGroups.cancel' | translate}}</button>
    <button type="submit" [disabled]="selectedConfigVersionIdsToClone.length!==1"
            (click)="cloneSurveyTemplateConfigVersion()"
            class="btn btn-sm btn-primary">{{'admin.mngSkillsRoleProfilesAndGroups.create' | translate}}</button>
  </div>
</clr-modal>
