/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SkillPage} from "../models/skill-page.model";
import {SkillGroup} from "../models/skillgroup.model";
import {SkillGroupOrder} from "../models/skill-group-order.model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";
import {Status} from "../enums/status.enum";

@Injectable({
  providedIn: 'root'
})
export class SkillsService {

  private readonly skillUrl: string = `${environment.API_URL}${API_KSA_CORE_V1}/skill/`;
  private readonly skillGroupUrl: string = `${environment.API_URL}${API_KSA_CORE_V1}/skillgroup/`;
  private readonly skillEndpoint: string = `${API_KSA_CORE_V1}/skill`;

  constructor(private http: HttpClient) {
  }

  getSkillGroups(surveyTemplateConfigVersionId: number): Observable<SkillGroup> {
    return this.http.get<SkillGroup>(`${this.skillGroupUrl}?surveyTemplateConfigVersionId=${surveyTemplateConfigVersionId}`);
  }

  getSkillGroupsOrder(surveyTemplateConfigVersionId: number): Observable<SkillGroupOrder[]> {
    return this.http.get<SkillGroupOrder[]>(`${this.skillGroupUrl}order?surveyTemplateConfigVersionId=${surveyTemplateConfigVersionId}`);
  }

  updateSkillGroupOrder(skillGroup: SkillGroupOrder[], surveyTemplateConfigVersionId: number): Observable<SkillGroupOrder[]> {
    return this.http.put<SkillGroupOrder[]>(`${this.skillGroupUrl}order/survey-template-configs/${surveyTemplateConfigVersionId}`, skillGroup);
  }

  deleteSkillGroup(id: any): Observable<any> {
    return this.http.delete<any>(`${this.skillGroupUrl}${id}`)
      .pipe(
        catchError(this.handleError<any>('skill', []))
      );
  }

  updateSkillGroup(skillGroupName: any): Observable<any> {
    return this.http.put<any>(this.skillGroupUrl, skillGroupName);
  }

  saveSkillGroup(skillGroupName: any): Observable<any> {
    return this.http.post<any>(this.skillGroupUrl, skillGroupName);
  }

  getSkills(surveyTemplateConfigVersionId, filter1, filter2, filter3, filter4, filter5, from, to, sort): Observable<any> {
    filter1 = encodeURIComponent(filter1);
    filter2 = encodeURIComponent(filter2);
    filter3 = encodeURIComponent(filter3);
    filter4 = encodeURIComponent(filter4);
    return this.http.get<any>(`${this.skillUrl}?surveyTemplateConfigVersionId=${surveyTemplateConfigVersionId}&skillName=${filter1}&skillDescription=${filter2}&skillGroup.skillGroupName=${filter3}&tagSkillMaps.tag.tagName=${filter4}&status=${filter5}&page=${from}&size=${to}` + sort)
  }

  getActiveCoursesForSkill(): Observable<any[]> {
    return this.http.get<any[]>(environment.API_URL + this.skillEndpoint + '/active/courses/')
      .pipe(
        catchError(this.handleError<any[]>('skill', []))
      );
  }

  getSkillsByConfigVersionIdAndStatus(surveyTemplateConfigVersionId: number, status?: Status): Observable<any[]> {
    return this.http.get<any[]>(`${environment.API_URL}${this.skillEndpoint}/survey-template-configs/${surveyTemplateConfigVersionId}`,
      {params: status ? new HttpParams().set('status', status) : {}})
      .pipe(
        catchError(this.handleError<any[]>('skill', []))
      );
  }

  saveSkill(skill: SkillPage): Observable<any> {
    return this.http.post(environment.API_URL + this.skillEndpoint + '/', skill)
  }

  updateSkill(skill: SkillPage): Observable<any> {
    return this.http.put(environment.API_URL + this.skillEndpoint + '/', skill)
  }

  activateSkill(id: any): Observable<any> {
    return this.http.put<any>(environment.API_URL + this.skillEndpoint + `/active/`, id)
      .pipe(
        catchError(this.handleError<any>('skill', []))
      );
  }

  archiveSkill(id: any): Observable<any> {
    return this.http.put<any>(environment.API_URL + this.skillEndpoint + `/archive/`, id)
      .pipe(
        catchError(this.handleError<any>('skill', []))
      );
  }

  deleteSkill(id: any): Observable<any> {
    return this.http.delete<any>(environment.API_URL + this.skillEndpoint + '/' + id)
      .pipe(
        catchError(this.handleError<any>('skill', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}

