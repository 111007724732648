<div class="questionSettings">
  <div class="btn-group btn-primary btn-icon buttonUpDown">
    <clr-icon shape="angle-double" (click)="onCheckUpdateStatus();move.emit(-1)"
              [hidden]="isUpArrayHidden(questionData?.value?.sectionQuestionSequenceNo)" class="btn-sm up-down-arrow">
    </clr-icon>
    <clr-icon shape="angle-double" (click)="onCheckUpdateStatus();move.emit(1)"
              [hidden]="isDownArrayHidden(questionData?.value?.sectionQuestionSequenceNo)" class="btn-sm up-down-arrow"
              id="downArrow"></clr-icon>
  </div>
  <clr-dropdown [clrCloseMenuOnItemClick]="true" *ngIf="hasWritePermission">
    <button clrDropdownTrigger aria-label="Dropdown demo button">
      <clr-icon shape="ellipsis-vertical"></clr-icon>
    </button>
    <clr-dropdown-menu *clrIfOpen clrPosition="left-top">
      <div (click)="showDeleteQuestionPopUp = true" aria-haspopup="true" aria-label="Dropdown header MapSkill"
           clrDropdownItem>
        {{'surveyTemplate.questionSheet.isDelete'|translate}}
      </div>
      <div (click)="isAddNoteTrue=true" aria-haspopup="true" aria-label="Dropdown header MapSkill" clrDropdownItem
           *ngIf="!questionData.controls.isImported.value">
        {{'surveyTemplate.questionSheet.isNote'|translate}}
      </div>
      <div (click)="onAddPageBreak()" [hidden]="isLastQuestion()" aria-haspopup="true"
        aria-label="Dropdown header MapSkill" clrDropdownItem>
        {{'surveyTemplate.questionSheet.isPageBreak'|translate}}
      </div>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>
<clr-accordion>
  <clr-accordion-panel [clrAccordionPanelOpen]="true">
    <clr-accordion-title class="clr-row">
      <div class="clr-col-12">
        {{'surveyTemplate.questionSheet.selectRoleProfile'|translate}}<span class="mandatory-span" *ngIf="!questionData.isMandatory">
                {{questionData?.controls?.isMandatory?.value ? '*':''}}
              </span>
      </div>
    </clr-accordion-title>
    <clr-accordion-content>
      <form [formGroup]="questionData">
        <div>
          <br>
          <form formGroupName="question" clrForm>
            <clr-select-container>
              <label class="skip-padding">
                {{'surveyTemplate.questionSheet.selectRoleProfile'|translate}}</label>
              <select clrSelect formControlName='questionText' class="clr-col-3">
                <option disabled selected value="">
                  {{'surveyTemplate.questionSheet.selectRoleProfile'|translate}}</option>
              </select>
            </clr-select-container>
          </form>
          <br><br>
          <div *ngIf="isAddNoteTrue || this.questionData.controls.questionNote?.value" class="clr-row">
            <div class="clr-col-lg-11">
              <clr-input-container>
                <input autocomplete="off" class="noteText" clrInput formControlName='questionNote' name="text"
                  placeholder="Enter Note Text!" (keyup)="onCheckUpdateStatus()"/>
                <clr-control-error *clrIfError="'maxlength'" class="error">
                  {{'surveyTemplate.questionSheet.isMaxCharAllowed'|translate}}
                </clr-control-error>
                <clr-control-error *clrIfError="'pattern'" class="error">
                  {{'surveyTemplate.masterSurvey.surveyCharErrorMsg'|translate}}
                </clr-control-error>
              </clr-input-container>
            </div>
          </div>
        </div>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
<div *ngIf="isAddPageBreak" draggable="true" class="PageBreak Draggable">
  <clr-icon (click)="onRemovePageBreak()" aria-label="warning" class="btn-sm btn-warning-outline minusIconPagebrak"
            shape="minus-circle">
  </clr-icon>
  <div class="PBInner">
    <div class="perforation">
      <span class="text">{{'surveyTemplate.questionSheet.isPageBreak'|translate}}</span>
    </div>
  </div>
</div>
<clr-modal *ngIf="showDeleteQuestionPopUp" [(clrModalOpen)]="showDeleteQuestionPopUp">
  <h3 class="modal-title">{{'surveyTemplate.questionSheet.isDeleteQuestion'|translate}}</h3>
  <div class="modal-body">
    {{'surveyTemplate.mngSurvey.unDeleteMsg' | translate}}
    <div *ngIf="errorOnDisplayLogicItemDelete" class="alert alert-danger" id="alert-message" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
            {{'errorMsg.canNotDeleteQuestion'|translate}}  {{dependentQuestionDisplayLogic}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="showDeleteQuestionPopUp = false"
      class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}</button>
    <button (click)="onRemoveQuestion()" class="btn btn-outline-warning btn-sm">{{'surveyTemplate.mngSurvey.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>
