<clr-modal [(clrModalOpen)]="showSkipLogicModal" [clrModalClosable]="true"
           (clrModalOpenChange)="showSkipLogic.emit(false)" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{'surveyTemplate.questionSheet.isSkipLogic'|translate}}</h3>
  <div class="modal-body">
    <form clrForm class="clr-form skipLogic skip-content">
      <clr-select-container *ngIf="(questionType=='Radio') || (questionType=='Multi-Choice')" class="clr-row">
        <label class="skip-padding clr-col-4">{{'surveyTemplate.questionSheet.isSelectlabelOption'|translate}}</label>
        <select clrSelect class="clr-col-8" name="skipLogicLabel"
                (change)="onSkipLogicValueAnswerLabelSelect($event.target.value)"
                [(ngModel)]="selectedAnswerLabelSkipLogic" [ngModelOptions]="{standalone: true}">
          <option disabled selected value="">{{'surveyTemplate.questionSheet.isSelectLabel'|translate}}</option>
          <option
            *ngFor="let item of questionData.controls.question.controls['answerLabels'].controls"
                    value={{item.value.answerLabelSequenceNo}}>
                    {{item.value.answerLabelSequenceNo}} : {{item.value.labelText}}
                  </option>
                </select>
              </clr-select-container>
      <clr-select-container *ngIf="(questionType=='Matrix')" class="clr-row">
          <label class="skip-padding clr-col-4">{{'surveyTemplate.questionSheet.isSelectAnswerRow'|translate}}</label>
                <select clrSelect class="clr-col-8" name="skipLogicLabel" (change)="onSkipLogicRowValueAnswerSelect($event.target.value)"
                [(ngModel)]="selectedRowAnswer" [ngModelOptions]="{standalone: true}">
                  <option disabled selected value="">{{'surveyTemplate.questionSheet.isSelectLabel'|translate}}</option>
                  <option *ngFor="let item of answerLabelRows" value={{item.answerLabelSequenceNo}}>
                    {{item.answerLabelSequenceNo}} : {{item.labelText}}
                  </option>
                </select>
      </clr-select-container>
      <clr-select-container *ngIf="(questionType=='Matrix')" class="clr-row">
          <label class="skip-padding clr-col-4">{{'surveyTemplate.questionSheet.isSelectAnswerCols'|translate}}</label>
                <select clrSelect class="clr-col-8" name="skipLogicLabel" (change)="onSkipLogicColumnValueAnswerSelect($event.target.value)"
                [(ngModel)]="selectedColumnAnswer" [ngModelOptions]="{standalone: true}">
                  <option disabled selected value="">{{'surveyTemplate.questionSheet.isSelectLabel'|translate}}</option>
                  <option *ngFor="let item of answerLabelColumn" value={{item.answerLabelSequenceNo}}>
                    {{item.answerLabelSequenceNo}} : {{item.labelText}}
                  </option>
                </select>
      </clr-select-container>
      <clr-select-container class="clr-row">
        <label class="skip-content-padding  clr-col-4">{{'surveyTemplate.questionSheet.isSelect'|translate}} </label>
              <select clrSelect class="clr-col-8" (change)="onSkipLogicSkipTypeSelect(selectDisplayType.value)" #selectDisplayType
               [(ngModel)]="selectedSkipType" [ngModelOptions]="{standalone: true}">
                <option disabled selected value="">{{'surveyTemplate.questionSheet.isSelecctDisplayType'|translate}}
                </option>
                <option *ngFor="let item of questionSkipTypes" value={{item?.skipCondition?.id}}>
                  {{item?.skipCondition?.skipConditionName}}
                </option>
              </select>
      </clr-select-container>
      <clr-input-container *ngIf="(questionType=='Scale')" class="clr-row">
        <label class="clr-col-4">Skip Value</label>
              <input class="skip-value-input clr-col-3" type="number" clrInput [(ngModel)]="questionData.value.questionSkipCondition.displayValue" [ngModelOptions]="{standalone: true}">
      </clr-input-container>
      <clr-select-container class="clr-row">
        <label class="skip-content-padding  clr-col-4">{{'surveyTemplate.questionSheet.isSkip'|translate}} </label>
              <select clrSelect class="clr-col-8"  (change)="onSkipTypeSelect($event.target.value)"
              [(ngModel)]="skipToAnswerLabelSkipLogic" [ngModelOptions]="{standalone: true}">
                <option disabled selected value="">{{'surveyTemplate.questionSheet.isSelectSkip'|translate}}</option>
                <option value={{Section}}>
                  {{'surveyTemplate.questionSheet.isEndOf'|translate}}{{Section}}
                </option>
                <option value={{Survey}}>
                  {{'surveyTemplate.questionSheet.isEndOf'|translate}} {{Survey}}
                </option>
              </select>
      </clr-select-container>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="onCancelSkipCondition()" class="btn btn-outline btn-sm"
      type="button">{{'surveyTemplate.questionSheet.isCLear'|translate}}</button>
    <button (click)="onOkSkipLogic();showSkipLogicModal = false" class="btn btn-primary btn-sm"
      type="button" [disabled]="isDisabledSkipLogicButton()">{{'surveyTemplate.questionSheet.isOk'|translate}}</button>
  </div>
</clr-modal>
