/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {HomeComponent} from "./components/home/home.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {AuthService} from "./services/auth.service";
import {AuthGuard} from "./guards/auth.guard";
import {AnonymousComponent} from "./components/participants/anonymous/anonymous.component";
import {DemoComponent} from './components/participants/demo/demo.component';
import {ParticipantComponent} from './components/participants/participant/participant.component';
import {SurveyThanksComponent} from './components/participants/survey-thanks/survey-thanks.component';
import {UserResolve} from "./services/user-resolve.service";
import {
  ResponseOriginalDataComponent
} from "./components/analysis-reports/response-management/response-original-data/response-original-data.component";
import {
  ParticipantAnswerReportComponent
} from "./components/analysis-reports/participant-answer-report/participant-answer-report.component";
import {
  CertificateOriginalDataComponent
} from './components/analysis-reports/cert-report/certificate-original-data/certificate-original-data.component';

const routes: Routes = [
  {
    path: "ParticipantAnswers/:ksaId/:participantId", component: ParticipantAnswerReportComponent
  },
  {
    path: "ResponseOriginalData/:id", component: ResponseOriginalDataComponent
  },
  {
    path: "CertificateOriginalData/:id", component: CertificateOriginalDataComponent
  },
  {
    path: "survey/:id", component: ParticipantComponent
  },
  {
    path: "survey/anonymous/:id", component: AnonymousComponent
  },
  {
    path: "survey/demo/:id", component: DemoComponent
  },
  {
    path: "completed", component: SurveyThanksComponent
  },
  {
    path: "login", component: LoginComponent
  },
  {
    path: "", component: HomeComponent, canActivate: [AuthGuard], children: [
      {path: '', component: DashboardComponent}
    ]
  },
  {
    path: 'Home', component: HomeComponent, children: [
      {
        path: 'Dashboard',
        component: DashboardComponent,
        resolve: {
          user: UserResolve
        },
        data: {breadcrumb: 'Dashboard'},
      }
    ],
    data: {breadcrumb: {skip: true}}
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AuthService
  ]
})
export class AppRoutingModule {
}
