/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {MasterSurvey} from '../models/master-survey.model';
import {QuestionType} from '../enums/questionType.enum';
import {SkipCondition} from '../models/ksa-role-profile-skill.model';
import {ValidateQuestionDisplayLogicResponse} from '../models/master-survey-section.model';

@Injectable({
  providedIn: 'root'
})
export class ShareSurveyDataService {

  private masterSurvey: BehaviorSubject<MasterSurvey> = new BehaviorSubject({});
  private surveyData: Subject<void> = new Subject();
  private surveySaveAlert: Subject<boolean> = new Subject();
  private IsAddMasterSurvey: boolean = false;
  private isSurveyInvalid: boolean = false;
  questionTypeEnum: typeof QuestionType = QuestionType;
  private showMasterSurveyTab: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private skipConditions: BehaviorSubject<SkipCondition[]> = new BehaviorSubject([]);

  private surveyDataSubscription: Subscription;
  private surveySaveAlertSubscription: Subscription;

  getSurvey(): BehaviorSubject<MasterSurvey> {
    return this.masterSurvey;
  }

  setSurvey(survey: MasterSurvey) {
    this.masterSurvey.next(survey);
  }

  getSurveyData(): Subject<void> {
    return this.surveyData;
  }

  saveSurvey() {
    this.surveyData.next();
  }

  getSurveySaveAlert(): Subject<boolean> {
    return this.surveySaveAlert;
  }

  setSurveySaveAlert(hasSuccess: boolean): void {
    this.surveySaveAlert.next(hasSuccess);
  }

  clearSurvey() {
    this.masterSurvey.next(null);
  }

  getIsAddMasterSurvey() {
    return this.IsAddMasterSurvey;
  }

  setIsAddMasterSurvey(val: any) {
    this.IsAddMasterSurvey = val;
  }

  getIsSurveyInvalid(): boolean {
    return this.isSurveyInvalid;
  }

  setIsSurveyInvalid(isInvalid: boolean) {
    this.isSurveyInvalid = isInvalid;
  }

  setShowSurveyTab(value: boolean) {
    this.showMasterSurveyTab.next(value);
  }

  subscribeForSurveyData(handler: (value: any) => void) {
    this.unsubscribeForSurveyData();
    this.surveyDataSubscription = this.surveyData.subscribe(handler);
  }

  subscribeForSurveySaveAlert(handler: (value: any) => void) {
    this.unsubscribeForSurveySaveAlert();
    this.surveySaveAlertSubscription = this.surveySaveAlert.subscribe(handler);
  }

  unsubscribeForSurveyData() {
    if(this.surveyDataSubscription) {
      this.surveyDataSubscription.unsubscribe();
    }
  }

  unsubscribeForSurveySaveAlert() {
    if(this.surveySaveAlertSubscription) {
      this.surveySaveAlertSubscription.unsubscribe();
    }
  }

  validateQuestionDisplayLogic(questionData): ValidateQuestionDisplayLogicResponse {
    const validateQuestionDisplayLogicResponse = {
      isValidQuestion: true,
      dependentQuestion: undefined,
      dependentSection: ''
    }
    this.getSurvey().subscribe(survey => {
      survey?.surveySections?.some(section => {
        section?.sectionQuestions?.some(question => {
          const questionItem = question;
          if (questionItem.questionDisplayCondition?.skipCondition?.id) {
            if (questionData.value.tempSurveyQuestionId == questionItem.questionDisplayCondition.tempSurveyQuestionId) {
              validateQuestionDisplayLogicResponse.isValidQuestion = false;
              validateQuestionDisplayLogicResponse.dependentQuestion = questionItem;
              return;
            }
          }
        });
        if (section.sectionSkipCondition?.skipCondition?.id) {
          if (section.sectionSkipCondition.tempSurveyQuestionId == questionData.value.tempSurveyQuestionId) {
            validateQuestionDisplayLogicResponse.isValidQuestion = false;
            validateQuestionDisplayLogicResponse.dependentSection = section.section.sectionName;
            return;
          }
        }
      });
    });
    return validateQuestionDisplayLogicResponse;
  }

  getSkipConditions(): Observable<SkipCondition[]> {
    return this.skipConditions.asObservable();
  }

  setSkipConditions(value) {
    return this.skipConditions.next(value);
  }

  findMaxQuestionId(): number {
    let maxQuestionValue = 0;
    this.getSurvey().subscribe(survey => {
      let tempMaxQuestionValue;
      if (survey?.surveySections) {
        if (survey.surveySections.length > 0) {
          survey.surveySections.forEach(section => {
            const questionData = section.sectionQuestions.filter(function (question) {
              return question !== undefined;
            });
            if (questionData.length > 0) {
              tempMaxQuestionValue = questionData.reduce(function (prev, current) {
                return (prev.tempSurveyQuestionId > current.tempSurveyQuestionId) ? prev : current
              });
              if (tempMaxQuestionValue.tempSurveyQuestionId > maxQuestionValue) {
                maxQuestionValue = tempMaxQuestionValue.tempSurveyQuestionId;
              }
            }
          });
        }
      } else {
        return 0;
      }
    });
    return maxQuestionValue;
  }

  validateLastQuestionOnSurvey(tempSurveyQuestionId: number): boolean {
    let isLastQuestion = false;
    this.getSurvey().subscribe(survey => {
      if (survey) {
        if (survey?.surveySections) {
          if (survey.surveySections[survey.surveySections.length - 1]) {
            const sectionLast = survey.surveySections[survey.surveySections.length - 1];
            if (sectionLast.sectionQuestions[sectionLast.sectionQuestions.length - 1]?.tempSurveyQuestionId === tempSurveyQuestionId) {
              isLastQuestion = true;
              return;
            }
          }
        }
      }
    });
    return isLastQuestion;
  }
}
