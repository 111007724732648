/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {KsaTeam} from "../models/ksa-team.model";
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({providedIn: 'root'})
export class KsaTeamsService {
  private ksaTeamsUrl: string;
  ksaTeam: KsaTeam[] = [];

  constructor(private http: HttpClient) {
    this.ksaTeamsUrl = `${environment.API_URL}${API_KSA_CORE_V1}/ksateam/`;
  }

  getksaTeams(): Observable<any> {
    return this.http.get<any>(`${this.ksaTeamsUrl}`);
  }

  getKsaTeamsData(filter1,filter2,from, to, sortby, sortorder) : Observable<KsaTeam[]>{
    filter1 = encodeURIComponent(filter1);
    filter2 = encodeURIComponent(filter2);
    return this.http.get<KsaTeam[]>(`${this.ksaTeamsUrl}?name=${filter1}&description=${filter2}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`);
  }

  createKsaTeam(ksaTeam: any): Observable<any>{
    return this.http.post(`${this.ksaTeamsUrl}`,ksaTeam);
  }

  getKSATeamUser(id:any):Observable<any>{
    return this.http.get((`${this.ksaTeamsUrl}`+`${id}/`+`users`))
  }

  updateKsaTeam(ksaTeam: any): Observable<any>{
    return this.http.put(`${this.ksaTeamsUrl}`,ksaTeam);
  }

  onDeleteKsaTeam(id:any):Observable<any>{
    return  this.http.delete<any>(`${this.ksaTeamsUrl}`+id)
  }

  onDeleteSelectedTeam(id:any):Observable<any>{
    return  this.http.delete<any>(`${this.ksaTeamsUrl}`+`multiple`+`/`+id)
  }
}
