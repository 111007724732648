<div *ngIf="surveySaveSuccessAlert && messageSuccessTimer" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">
        {{'admin.coursesAndSkills.skills.orderSavedSkill' | translate}}
      </span>
    </div>
  </div>
</div>
<div *ngIf="surveyErrorAlert" class="alert alert-danger" id="alert-message" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span class="alert-text">
        {{'surveyTemplate.masterSurvey.errorMsg'|translate}}
      </span>
    </div>
  </div>
</div>
<br>

<div class="clr-row">
  <div class="clr-col-11">
  </div>
  <div class="clr-col-1">
    <span>
      <div
        *ngIf="skillGroups?.length>=1 && skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)"
        class=" saveButton">
        <button (click)="onSaveSkillOrder()" *hasAccess="functionality.manageSkill" class="btn btn-sm btn-primary"
                type="submit">{{'admin.saveBtn'|
          translate}}
        </button>
      </div>
    </span>
  </div>
</div>
<div class="dataSkillOrder">
  <clr-accordion *ngFor="let skillGroupItem of skillGroups; let first = first;let last = last">
    <clr-accordion-panel [clrAccordionPanelOpen]="false">
      <clr-accordion-title class="accordionSkillGroup">
        <div class="clr-row">
          <div class="clr-col-10">
            <strong>{{skillGroupItem.skillGroupName}}</strong>
          </div>
          <div class="clr-col-2" *hasAccess="functionality.manageSkill">
            <div class="btn-group btn-primary btn-icon actionButtons">
              <clr-icon (click)="moveSkillGroup(-1,skillGroupItem)" [hidden]="first" class="btn-sm up-down-arrow"
                        id="upArrowSkillGroup" shape="angle-double"></clr-icon>
              <clr-icon (click)="moveSkillGroup(1,skillGroupItem)" [hidden]="last" class="btn-sm up-down-arrow"
                        id="downArrowSkillGroup" shape="angle-double"></clr-icon>
            </div>
          </div>
        </div>
      </clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <div *ngFor="let skillItem of skillGroupItem?.skills; let first=first;let last=last"
             class="clr-col-lg-12 clr-col-md-8 clr-col-12">
          <div class="card">
            <div class="card-block">
              <div class="clr-row">
                <div class="clr-col-11">
                  {{skillItem?.skillName}}
                </div>
                <div class="clr-col-1" *hasAccess="functionality.manageSkill">
                  <div class="btn-group btn-primary btn-icon buttonUpDown">
                    <clr-icon (click)="moveSkill(-1,skillItem,skillGroupItem)" [hidden]="first"
                              class="btn-sm up-down-arrow" shape="angle-double"></clr-icon>
                    <clr-icon (click)="moveSkill(1,skillItem, skillGroupItem)" [hidden]="last"
                              class="btn-sm up-down-arrow" id="downArrow" shape="angle-double"></clr-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
</div>
