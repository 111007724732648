export class SurveyQuestionImport {
    public sequenceNo?: number;
    public id?: string;
    public name?: string;
    public details?: string;
    public type?: string;
    public sectionName?: string;
}
export interface ResponseFileQuestion{
    errors ?: string,
    isValidQuestions ?: boolean,
}