/* *********************************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * *********************************************************************/

const DEFAULT_LOADING_LABEL: string = "Loading KSA portal";
let spinnerCounter = 0;

export function showSpinner(label?: string): void {
  document.getElementById('spinnerMask').style.display = '';
  document.getElementById('loader-text-wrapper').setAttribute('loader-text', label || DEFAULT_LOADING_LABEL);
  spinnerCounter++;
}

export function hideSpinner(): void {
  spinnerCounter--;
  if (spinnerCounter <= 0) {
    spinnerCounter = 0;
    document.getElementById('spinnerMask').style.display = 'none';
  }
}
