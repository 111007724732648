/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EmailTemplate, PlaceHolder} from "../../../../models/email-template.model";
import {EmailTemplateService} from "../../../../services/email-template.service";
import {EmailValidationService} from "../../../../services/email-validation.service";
import {hideSpinner, showSpinner} from "../../../../common/spinner";
import {finalize} from "rxjs/operators";
import {isEqual} from "lodash";

declare let $: any;

@Component({
  selector: 'app-edit-email-template',
  templateUrl: './edit-email-template.component.html',
  styleUrls: ['./edit-email-template.component.scss']
})
export class EditEmailTemplateComponent implements OnInit {
  @Input() placeHolderNameList: any[] = [];
  data = '';
  @ViewChild('r') r: ElementRef;
  newEmailTemplate: EmailTemplate = {
    emailTemplateName: '',
    description: '',
    subject: '',
    emailBody: '',
    emailTemplateType: {
      "id": null,
      "emailTemplateTypeName": '',
    },
  }

  currentEmailTemplate: any = {
    emailTemplateName: '',
    description: '',
    subject: '',
    emailBody: '',
    emailTemplateType: {
      id: 0,
      emailTemplateTypeName: ''
    }
  }
  emailTemplateTypes: any
  placeHolders: PlaceHolder[] = [];
  @Input() templateId: any;
  showImageUploadPopUp: boolean = false;
  templateName: any;
  templateType: any;
  subject: any;
  mailBody: any;
  options: any;
  description: any;
  showSaveConfirmationDialog: boolean = false;
  imageURL: string = '';
  receivedImageData: any;
  convertedImage: any;
  convertedImageFooter: any;
  headerImage: any;
  indexInput: number = 0;
  showImageDeletePopUp: boolean = false;
  errorMessage: string = '';
  config: any = {
    height: '130px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'hr']],
      ['customButtons', ['placeholder']]
    ],
    buttons: {
      'placeholder': this.customPlaceHolderButton()
    }
  };
  @Output() isAddTemplate: EventEmitter<boolean> = new EventEmitter();
  @Output() isEditTemplate: EventEmitter<boolean> = new EventEmitter();
  emailSaveSuccessAlert: boolean = false;
  invalidEmailAlert: boolean = false;
  undefinedPlaceHolders: string = '';
  specialCharacterPattern: string = "^[^!@#*&(){}<>\\/;`%$^:?]*$";
  isFormUpdated: boolean = false;
  initialEmailTemplate: EmailTemplate;

  constructor(private emailTemplateService: EmailTemplateService, private changeDetector: ChangeDetectorRef,
              private emailValidationService: EmailValidationService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    if (this.templateId) {
      this.getEmailTemplateForEdit(this.templateId);
    } else {
      this.templateId = 0;
    }
    this.getEmailTemplateType();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  customPlaceHolderButton() {
    return (context) => {
      const ui = $.summernote.ui;
      const blockChar1 = '${';
      const blockChar2 = '}';
      const list = this.placeHolderNameList;
      const button = ui.buttonGroup([
        ui.button({
          className: 'dropdown-toggle',
          contents: '<span class="template"/> Place Holder <span class="caret"></span>',
          tooltip: 'Place Holder',
          data: {
            toggle: 'dropdown'
          },
          click: function () {
            context.invoke('editor.saveRange');
          }
        }),
        ui.dropdown({
          className: 'dropdown-style',
          items: list,
          click: function (event) {
            event.preventDefault();
            const $button = $(event.target);
            const placeHolderValue = $button.data('value').replace(/ /g, "");
            const placeHolderText = blockChar1 + placeHolderValue + blockChar2;
            context.invoke('editor.insertText', placeHolderText);
          },
          template: function(item)
          {
            const content = (typeof item === 'string') ? item : (item.content || item.value || '');
            return content;
          }
        })
      ]);
      return button.render();
    }
  }

  onChange(e) {
    let newContent
    const selection = document.getSelection();
    const cursorPos = selection.anchorOffset;
    const oldContent = selection.anchorNode.nodeValue;
    if (oldContent == null) {
      newContent = "${" + e + "}";
      selection.anchorNode.nodeValue = "test";
    } else {
      newContent = oldContent.substring(0, cursorPos) + "${" + e + "}" + oldContent.substring(cursorPos);
    }
    selection.anchorNode.nodeValue = newContent;
  }

  isBackToEmailTemplateTable() {
    this.isEditTemplate.emit(false);
    this.isAddTemplate.emit(false);
  }

  getEmailTemplateType() {
    this.emailTemplateService.getEmailTemplateType().subscribe(data => {
        this.emailTemplateTypes = data;
      },
      error => console.log(error));
  }

  getEmailTemplateForEdit(id: number) {
    this.emailTemplateService.getEmailTemplateById(id).subscribe(data => {
        this.currentEmailTemplate = data;
        this.initialEmailTemplate = {...data};
        this.initialEmailTemplate.emailTemplateType = {...data.emailTemplateType};
        this.setIsFormUpdated();
      },
      error => this.errorMessage = error.error.message);
  }

  onSaveEmailTemplate(emailTemplate: EmailTemplate): void {
    if (!this.isValidEmail(emailTemplate)) {
      return;
    }
    if (this.currentEmailTemplate.id) {
      this.onUpdateEmailTemplate(this.currentEmailTemplate);
    } else {
      showSpinner(this.translate.instant("spinnerLabels.common.saving"));
      this.emailTemplateService.createEmailTemplate(emailTemplate)
        .pipe(finalize(() => hideSpinner()))
        .subscribe(data => {
            this.currentEmailTemplate = data;
            this.emailSaveSuccessAlert = true;
            this.showSaveConfirmationDialog = false;
            this.errorMessage = '';
            setTimeout(() => {                                  //Timeout for success/error message alert.
              this.emailSaveSuccessAlert = false;
            }, 3000);
          },
          error => {
            this.errorMessage = error.error.message;
            this.showSaveConfirmationDialog = false
          });
    }
  }

  onUpdateEmailTemplate(emailTemplate: EmailTemplate): void {
    if (!this.isValidEmail(emailTemplate)) {
      return;
    }

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.emailTemplateService.updateEmailTemplate(emailTemplate)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.emailSaveSuccessAlert = true;
          this.isFormUpdated = false;
          this.showSaveConfirmationDialog = false;
          setTimeout(() => {
            this.emailSaveSuccessAlert = false;
          }, 3000);
        },
        error => this.errorMessage = error.error.message);
  }

  private isValidEmail(emailTemplate: EmailTemplate): boolean {
    this.invalidEmailAlert = false;
    const undefinedPlaceholdersArr: string[] = this.emailValidationService.getUndefinedPlaceholders(emailTemplate, this.placeHolderNameList);
    if (undefinedPlaceholdersArr.length > 0) {
      this.invalidEmailAlert = true;
      this.undefinedPlaceHolders = undefinedPlaceholdersArr.join(", ");
      return false;
    }
    return true;
  }

  setIsFormUpdated(): void {
    this.isFormUpdated = !isEqual(this.currentEmailTemplate, this.initialEmailTemplate);
  }
}
