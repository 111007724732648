<clr-modal [(clrModalOpen)]="showMapSkillPopUp" [clrModalClosable]="true" (clrModalOpenChange)="onClosePopUp()">
<h3 class="modal-title">{{'surveyTemplate.questionSheet.isMapSkillToQuestion'|translate}}</h3>
<div class="modal-body">
  <clr-datagrid [(clrDgSingleSelected)]="selectedSkillToMap" [clrDgRowSelection]="false"
                class="popup-datagrid">
    <clr-dg-column [clrDgField]="'skillName'">{{'surveyTemplate.questionSheet.isSkillName'|translate}}</clr-dg-column>
    <clr-dg-column [clrDgField]="'course'">{{'surveyTemplate.questionSheet.isCourses'|translate}}</clr-dg-column>
    <clr-dg-column
      [clrDgField]="'skillGroupName'">{{'surveyTemplate.questionSheet.isSkillGroup'|translate}}</clr-dg-column>
    <clr-dg-column
      [clrDgField]="'description'">{{'surveyTemplate.questionSheet.isDescription'|translate}}</clr-dg-column>
    <clr-dg-row *clrDgItems="let skill of activeSkills; trackBy:trackById" [clrDgItem]="skill">
      <clr-dg-cell>{{skill.skillName}}</clr-dg-cell>
      <clr-dg-cell>
        <ul *ngFor="let c of skill.courseSkillMaps">
          <li>{{c.course.courseName}}</li>
        </ul>
      </clr-dg-cell>
      <clr-dg-cell>{{skill.skillGroup.skillGroupName}}</clr-dg-cell>
      <clr-dg-cell>{{skill.skillDescription}}</clr-dg-cell>
    </clr-dg-row>
  </clr-datagrid>
</div>
<div class="modal-footer">
  <button (click)="showMapSkill.emit(false)" class="btn btn-outline btn-sm" type="button">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}</button>
  <button (click)="onMapSkillToQuestion()" class="btn btn-primary btn-sm" type="button">{{'surveyTemplate.questionSheet.isMapSkill'|translate}}</button>
</div>
</clr-modal>
