<div *ngIf="showKsaTable">
  <div>
    <clr-alert clrAlertType="danger" *ngIf="errorMsg != '' && !showMultiDeleteWarning && !showDeleteWarning"
               [clrAlertClosable]="true"
               [clrAlertSizeSmall]="true" (clrAlertClosedChange)="errorMsg = ''">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </div>
  <div class="button-container" *ngIf="hasWritePermission">
    <button id="{{btnId.btnKsaAdministrationAdd}}" (click)="onAddKsaSurveyBtn()" class="btn btn-sm btn-primary">
      {{'ksaEngagement.ksaAdministration.addNewKsa' | translate}}
    </button>
    <button id="{{btnId.btnKsaAdministrationMultiDelete}}" (click)="OnDeleteMultiSelectKsa(selectedKsas)"
            class="btn btn-sm btn-primary">
      {{'ksaEngagement.ksaAdministration.deleteKsaBtn' | translate}}
    </button>
  </div>
  <clr-datagrid [clrDgLoading]="loading" [(clrDgSelected)]="selectedKsas" class="parent-datagrid">
    <clr-dg-column [clrDgField]="'ksaName'">{{'ksaEngagement.ksaAdministration.ksa' | translate}}</clr-dg-column>
    <clr-dg-column
      [clrDgField]="'team'">{{'ksaEngagement.ksaAdministration.ksaInformation' | translate}}</clr-dg-column>
    <clr-dg-column [clrDgField]="'geo'">{{'ksaEngagement.ksaAdministration.geo' | translate}}</clr-dg-column>
    <clr-dg-column [clrDgField]="'language'">{{'ksaEngagement.ksaAdministration.language' | translate}}</clr-dg-column>
    <clr-dg-column [clrDgField]="'status'">{{'ksaEngagement.ksaAdministration.status' | translate}}
      <clr-dg-filter>
        <app-status-filter [statusOptions]="surveyStatusOption" (statusFilter)='getStatusFilter($event)'>
        </app-status-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'expirationDate'">{{'ksaEngagement.ksaAdministration.expirationDate' | translate}}
      <clr-dg-filter>
        <app-date-filter (filterDate)='getDateFilter($event)'></app-date-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'invitedAt'">{{'ksaEngagement.ksaAdministration.invitedAt' | translate}}
      <clr-dg-filter>
        <app-date-filter (filterDate)='getDateFilter($event)'></app-date-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'closedAt'">{{'ksaEngagement.ksaAdministration.closedAt' | translate}}
      <clr-dg-filter>
        <app-date-filter (filterDate)='getDateFilter($event)'></app-date-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'duration'">{{'ksaEngagement.ksaAdministration.activeDuration' | translate}}
      <clr-dg-filter>
        <app-date-filter (filterDate)='getDateFilter($event)'></app-date-filter>
      </clr-dg-filter>
    </clr-dg-column>
      <clr-dg-column class="action-cell"></clr-dg-column>
      <clr-dg-row *clrDgItems="let ksa of ksa" [clrDgItem]="ksa">
        <clr-dg-cell>{{'ksaEngagement.ksaAdministration.ksaId' | translate}}: {{ksa.ksaName}}
          <br> {{'ksaEngagement.ksaAdministration.description' | translate}}:{{ksa.description}}</clr-dg-cell>
        <clr-dg-cell>Team : {{ksa.team}}<br>
          Members: <a href="javascript:void(0)" role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-bottom-right">
            <cds-icon shape="eye"></cds-icon>
            <div class="tooltip-content">
              <div *ngFor="let member of ksa.membersList">
                {{member}}
              </div>
            </div>
            </a>
        </clr-dg-cell>
        <clr-dg-cell>{{ksa.geo}}/{{ksa.country}}</clr-dg-cell>
        <clr-dg-cell>{{ksa.language}}</clr-dg-cell>
        <clr-dg-cell>{{ksa.status}}</clr-dg-cell>
        <clr-dg-cell>{{ksa.expirationDate|date}}</clr-dg-cell>
        <clr-dg-cell>{{this.getFirstInviteText(ksa)}}</clr-dg-cell>
        <clr-dg-cell>{{this.getCloseDateText(ksa)}}</clr-dg-cell>
        <clr-dg-cell>{{ksa.duration}}</clr-dg-cell>
        <clr-dg-cell class="action-cell" *ngIf="ksa.status != statusEnum.Expired">
          <clr-dg-action-overflow>
            <button id="{{btnId.btnKsaAdministrationEdit}}" type="button" class="action-item"
                    (click)="onEditKsaDetails(ksa.id);">{{(hasWritePermission ? 'admin.editBtn' : 'admin.viewBtn')| translate}}</button>
            <button id="{{btnId.btnKsaAdministrationActivate}}" type="button"
                    class="action-item"
                    (click)="onActivateKsa(ksa.id);"
                    *ngIf="ksa.status != statusEnum.Active && hasWritePermission">{{'admin.activateBtn' | translate}}</button>
            <button id="{{btnId.btnKsaAdministrationClone}}" type="button"
                    class="action-item"
                    (click)="onCloseKsa(ksa.id);"
                    *ngIf="ksa.status == statusEnum.Active && hasWritePermission">{{'admin.closeBtn' | translate}}</button>
            <button id="{{btnId.btnKsaAdministrationExpire}}" type="button"
                    class="action-item"
                    (click)="onExpireKsa(ksa.id);"
                    *ngIf="ksa.status == statusEnum.Closed && hasWritePermission">{{'admin.expireBtn' | translate}}</button>
            <button id="{{btnId.btnKsaAdministrationDelete}}" type="button"
                    class="action-item"
                    (click)="onShowDeleteWarning(ksa.id);"
                    *ngIf="ksa.status == statusEnum.Draft && hasWritePermission">{{'admin.deleteBtn' | translate}}</button>
          </clr-dg-action-overflow>
        </clr-dg-cell>
        <clr-dg-cell class="action-cell" *ngIf="ksa.status == statusEnum.Expired"></clr-dg-cell>
      </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'ksaEngagement.ksaPerPage'|translate}}</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} {{'admin.of'|translate}} {{pagination.totalItems}} {{'ksaEngagement.ksa'|translate}}
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>
<div *ngIf="!showKsaTable">
  <app-tab-ksa (showKsaTable)="onShowKsaTable($event)"></app-tab-ksa>
</div>

<clr-modal [(clrModalOpen)]="showDeleteWarning" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'ksaEngagement.ksaAdministration.deleteKsa' | translate}}?
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'ksaEngagement.ksaAdministration.actionWarning' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteWarning = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteKsa()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showMultiDeleteWarning" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'ksaEngagement.ksaAdministration.deleteKsa' | translate}}?</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>
    {{'ksaEngagement.ksaAdministration.actionWarning' | translate}}.
  </div>
  <div class="modal-footer">
    <button (click)="showMultiDeleteWarning = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteMultipleKsa()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>

<clr-modal *ngIf="isUserEditingKsa" [clrModalOpen]="true" [(clrModalOpen)]="isUserEditingKsa">
  <h3 class="modal-title">{{'ksaEngagement.ksaAdministration.ksaUnderEditingModalTitle' | translate}}</h3>
  <div class="modal-body">
    <div class="modal-body">
      <div class="modal-body">
        <div class="alert alert-info">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
              </div>
              <hr>
              <span class="alert-text">
              {{'ksaEngagement.ksaAdministration.ksaUnderEditingModalText' | translate}}
                <hr>
              <form class="clr-form clr-form-horizontal">
                <div class="clr-form-control">
                  <label for="userName" class="clr-control-label"><strong>User:</strong></label>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" id="userName"
                             value="{{ksaAdministrationService.editingData.firstName}} {{ksaAdministrationService.editingData.lastName}}"
                             readonly class="clr-input"/>
                    </div>
                  </div>
                </div>
                <div class="clr-form-control">
                  <label for="email" class="clr-control-label"><strong>Email:</strong></label>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" id="email"
                             value="{{ksaAdministrationService.editingData.email}}"
                             readonly class="clr-input"/>
                    </div>
                  </div>
                </div>
              </form>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"
            (click)="isUserEditingKsa = false">{{'ksaEngagement.ksaAdministration.ksaUnderEditingModalOkButton' | translate}}</button>
  </div>
</clr-modal>
