/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {SurveyControls} from '../enums/survey-controls.enum';
import {ParticipantQuestion, SkipLogicParameter} from '../models/participant-ksa.model';
import {SkipAndDisplayLogicConditions} from '../enums/display-skip-conditions';
import {SkipLogicFunctionalityService} from './skip-logic-functionality-service';

@Injectable({
  providedIn: 'root'
})
export class SkipLogicService {

  SkipLogicConditions = SkipAndDisplayLogicConditions;

  skipLogicParams: SkipLogicParameter = {
    selectedQuestionToDisplay: null,
    answerValuesText: null,
    isDisplayQuestion: null,
    skipConditionAnswerLabelID: null,
    selectedAnswerLabelsID: null,
    selectedColAnswerLabelID: null,
    selectedAnswerAction: null,
    displayValueQuestion: null,
    questionListParticipant: null
  };

  surveyControlsEnum: typeof SurveyControls = SurveyControls;

  constructor(private skipLogicFunctionalityService: SkipLogicFunctionalityService) {
  }

  getSkipConditionName(skipConditionID: number): string {
    const resultString: any = this.SkipLogicConditions.find(skipCondition => skipCondition.id === skipConditionID);
    return resultString.skipConditionName;
  }

  skipLogicForFree(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[]): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    const skipConditionID = question.questionSkipCondition.skipCondition.id;
    const skipConditionName = this.getSkipConditionName(skipConditionID);
    this.skipLogicParams.questionListParticipant = questionListParticipant;
    this.skipLogicParams.selectedQuestionToDisplay = question;
    this.skipLogicParams.isDisplayQuestion = question.isRoleProfileDisplay;
    this.skipLogicParams.answerValuesText = question.question.answerValuesText;
    resultQuestionList = this.skipLogicFunctionalityService[skipConditionName](this.skipLogicParams);
    return resultQuestionList;
  }

  skipLogicForRadio(question: ParticipantQuestion,
                    questionListParticipant: ParticipantQuestion[]): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    const skipConditionID = question.questionSkipCondition.skipCondition.id;
    const skipConditionName = this.getSkipConditionName(skipConditionID);
    const selectedAnswerLabels = question.question.answerLabels.filter(answer => {
      return answer.id === question.questionSkipCondition.answerLabel.id;
    });
    this.skipLogicParams.selectedQuestionToDisplay = question;
    this.skipLogicParams.questionListParticipant = questionListParticipant;
    this.skipLogicParams.isDisplayQuestion = question.isRoleProfileDisplay;
    this.skipLogicParams.skipConditionAnswerLabelID = question.questionSkipCondition.answerLabel.id;
    this.skipLogicParams.selectedAnswerLabelsID = selectedAnswerLabels[0].id;
    this.skipLogicParams.selectedAnswerAction = selectedAnswerLabels[0].isChecked;
    resultQuestionList = this.skipLogicFunctionalityService[skipConditionName](this.skipLogicParams);
    return resultQuestionList;
  }

  skipLogicForMultiple(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[]): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    const skipConditionID = question.questionSkipCondition.skipCondition.id;
    const skipConditionName = this.getSkipConditionName(skipConditionID);
    const selectedAnswerLabels = question.question.answerLabels.filter(answer => {
      return answer.id === question.questionSkipCondition.answerLabel.id;
    });
    this.skipLogicParams.selectedQuestionToDisplay = question;
    this.skipLogicParams.questionListParticipant = questionListParticipant;
    this.skipLogicParams.isDisplayQuestion = question.isRoleProfileDisplay;
    this.skipLogicParams.skipConditionAnswerLabelID = question.questionSkipCondition.answerLabel.id;
    this.skipLogicParams.selectedAnswerLabelsID = selectedAnswerLabels[0].id;
    this.skipLogicParams.selectedAnswerAction = selectedAnswerLabels[0].isChecked;
    resultQuestionList = this.skipLogicFunctionalityService[skipConditionName](this.skipLogicParams);
    return resultQuestionList;
  }

  skipLogicForScale(question: ParticipantQuestion,
                    questionListParticipant: ParticipantQuestion[]): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    const skipConditionID = question.questionSkipCondition.skipCondition.id;
    const skipConditionName = this.getSkipConditionName(skipConditionID);

    this.skipLogicParams.selectedQuestionToDisplay = question;
    this.skipLogicParams.questionListParticipant = questionListParticipant;
    this.skipLogicParams.isDisplayQuestion = question.isRoleProfileDisplay;
    this.skipLogicParams.answerValuesText = question.question.answerValuesText as number;
    this.skipLogicParams.displayValueQuestion = question.questionSkipCondition.displayValue;
    resultQuestionList = this.skipLogicFunctionalityService[skipConditionName](this.skipLogicParams);
    return resultQuestionList;
  }

  skipLogicForMatrixRadio(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[],
                          answerValue: boolean, rowNumber: number, columnNumber: number): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    const skipConditionID = question.questionSkipCondition.skipCondition.id;
    this.skipLogicParams.selectedQuestionToDisplay = question;
    this.skipLogicParams.questionListParticipant = questionListParticipant;
    this.skipLogicParams.isDisplayQuestion = question.isRoleProfileDisplay;
    this.skipLogicParams.selectedAnswerAction = answerValue;

    if (rowNumber === question.questionSkipCondition?.answerLabel?.answerLabelSequenceNo) {
      const listRowAnswerSelected = question.question.answerControls.filter(control => {
        return control.rowNumber === rowNumber;
      });

      listRowAnswerSelected?.forEach(control => {

        if (question.questionSkipCondition?.colAnswerLabel?.answerLabelSequenceNo === control.colNumber) {
          this.skipLogicParams.skipConditionAnswerLabelID =
            question.questionSkipCondition?.colAnswerLabel?.answerLabelSequenceNo;
          this.skipLogicParams.selectedAnswerLabelsID = control.colNumber;
          this.skipLogicParams.selectedAnswerAction = control.isChecked;
          const skipConditionName = this.getSkipConditionName(skipConditionID);
          resultQuestionList = this.skipLogicFunctionalityService[skipConditionName](this.skipLogicParams);
        }
      });
    }

    return resultQuestionList;
  }

  skipLogicForMatrixCheckBox(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[], answerValue: boolean,
                             rowNumber: number, columnNumber: number): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    const skipConditionID = question.questionSkipCondition.skipCondition.id;
    this.skipLogicParams.selectedQuestionToDisplay = question;
    this.skipLogicParams.questionListParticipant = questionListParticipant;
    this.skipLogicParams.isDisplayQuestion = question.isRoleProfileDisplay;
    this.skipLogicParams.selectedAnswerAction = answerValue;

    if (rowNumber === question.questionSkipCondition?.answerLabel?.answerLabelSequenceNo &&
      columnNumber === question.questionSkipCondition?.colAnswerLabel?.answerLabelSequenceNo) {

      const listRowAnswerSelected = question.question.answerControls.filter(control => {
        return control.rowNumber === rowNumber;
      });

      listRowAnswerSelected?.forEach(control => {

        if (question.questionSkipCondition?.colAnswerLabel?.answerLabelSequenceNo === control.colNumber &&
          question.questionSkipCondition?.answerLabel?.answerLabelSequenceNo === control.rowNumber) {
          this.skipLogicParams.skipConditionAnswerLabelID =
            question.questionSkipCondition?.colAnswerLabel?.answerLabelSequenceNo;
          this.skipLogicParams.selectedAnswerLabelsID = control.colNumber;
          this.skipLogicParams.selectedAnswerAction = control.isChecked;

          const skipConditionName = this.getSkipConditionName(skipConditionID);
          resultQuestionList = this.skipLogicFunctionalityService[skipConditionName](this.skipLogicParams);
        }
      });
    }

    return resultQuestionList;
  }
}
