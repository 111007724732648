/*
 * ************************************************************************************
 * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 * ************************************************************************************
 */

export enum EmailTemplateTypeEnum {
  INVITE = 'INVITE',
  REMINDER = 'REMINDER',
  THANKS = 'THANKS',
}
