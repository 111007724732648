<clr-alert clrAlertType="danger" *ngIf="errorMessage !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
  <clr-alert-item>{{errorMessage}}</clr-alert-item>
</clr-alert>
<clr-alert clrAlertType="success" *ngIf="isEmailSendToAll" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
  <clr-alert-item>{{'ksaEngagement.emailManagement.sendInvite.successMessageAll' | translate}}</clr-alert-item>
</clr-alert>
<clr-alert clrAlertType="success" *ngIf="isEmailSendToUpdated" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
  <clr-alert-item>{{'ksaEngagement.emailManagement.sendInvite.successMessageUpdated' | translate}}</clr-alert-item>
</clr-alert>
<clr-alert clrAlertType="warning" *ngIf="showInvalidParticipantAlert" [clrAlertClosable]="false"
           [clrAlertSizeSmall]="true">
  <clr-alert-item>{{'ksaEngagement.emailManagement.InvalidEmailAlert' | translate}}
    : {{invalidParticipant}}</clr-alert-item>
</clr-alert>
<clr-alert clrAlertType="info" *ngIf="isSendingInProgress()" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
  <clr-alert-item>{{'ksaEngagement.emailManagement.sendInvite.sendingInProgress' | translate}}</clr-alert-item>
</clr-alert>

<form clrForm id="send-invite-form">
  <div class="clr-row invite-title">
    <h4 *ngIf="isInvite">{{'ksaEngagement.emailManagement.sendInvite.inviteTitle' | translate}}</h4>
    <h4 *ngIf="isThanks" id="thanksTitle">{{'ksaEngagement.emailManagement.sendInvite.thanksTitle' | translate}}</h4>
  </div>
  <div class="clr-row" *ngIf="!isThanks">
    <div class="clr-col participant-btn">
      <button id="clear-all-btn" (click)="onClearParticipants()" class="btn btn-link btn-sm right-border-gray">
        <clr-icon shape="trash"></clr-icon>
        {{'ksaEngagement.emailManagement.sendInvite.buttons.clearAll' | translate}}
      </button>
      <button id="addParticipantBtn" (click)="onManageParticipant(DIALOG_MODES.CREATE)" class="btn btn-link btn-sm right-border-gray">
        <clr-icon shape="plus-circle"></clr-icon>
        {{'ksaEngagement.emailManagement.sendInvite.buttons.addParticipant' | translate}}
      </button>
      <button id="manageParticipantBtn" (click)="onManageParticipant(DIALOG_MODES.EDIT)" class="btn btn-link btn-sm">
        <clr-icon shape="note"></clr-icon>
        {{'ksaEngagement.emailManagement.sendInvite.buttons.editParticipant' | translate}}
      </button>
      <label for="txtFileUpload" id="importCsvBtn">
        <span class="btn btn-sm btn-primary line-btn">{{'ksaEngagement.emailManagement.sendInvite.buttons.importParticipants' | translate}}</span>
      </label>
      <input name="UploadCSV" id="txtFileUpload" onclick="(this.value=null)" (change)="uploadListener($event)"  accept=".csv" class="clr-file"  type="file"/>
    </div>
  </div>
  <div class="clr-row">
    <div class="clr-col">
      <div class="clr-row">
        <label class="clr-control-container clr-col-md-1 required-input label-container">
          {{'ksaEngagement.emailManagement.sendInvite.labels.to' | translate}}</label>
        <div class="to-email-body clr-col">
          <button class="label clickable" name="{{item.email}}" *ngFor="let item of toEmailParticipants"
                  (click)="onDeleteParticipant(item)">X {{item.email}}</button>
        </div>
      </div>
    </div>
  </div>
    <div class="clr-row">
      <div class="clr-col sub-col">
        <clr-input-container>
          <label class="clr-control-container clr-col-md-1 clr-col-1 label-container" >
            {{'ksaEngagement.emailManagement.sendInvite.labels.cc' | translate}}</label>
          <input [(ngModel)]="ccEmail" autocomplete="off"
                 id="ccInputField"
                 class="clr-control-container inputField"
                 clrInput name="ccEmailValue"
                 (keyup)="onSurveyPresent(ccEmail)"
                 placeholder="{{'placeholder.enterEmail'|translate}}"
                 pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4};?)+$"/>
          <clr-control-error *clrIfError="'pattern'" class="error">
            {{'ksaEngagement.emailManagement.emailMsgValidation' | translate}}
          </clr-control-error>
        </clr-input-container>
      </div>
    </div>
    <div class="clr-row">
      <div class="clr-col sub-col">
        <clr-input-container>
          <label class="clr-control-container clr-col-sm-1 label-container">
            {{'ksaEngagement.emailManagement.sendInvite.labels.subject' | translate}}</label>
          <input [(ngModel)]="emailSubject" autocomplete="off" class="clr-control-container inputField"
                  clrInput  name="Select Email Subject" disabled
                  placeholder="{{'placeholder.emailSubject'|translate}}" />
        </clr-input-container>
      </div>
    </div>
    <br>
    <br>
  <div class="clr-row">
    <label class="clr-control-label clr-col-sm-auto label-container">
      {{'ksaEngagement.emailManagement.sendInvite.labels.emailBody' | translate}}</label>
    <div class="send-email-body clr-col">
      <div [innerHTML]="emailBody | safe: 'html'"></div>
    </div>
  </div>
  <br>
  <div class="clr-row">
    <div class="clr-col-1">
      <button (click)="onBack()" class="btn btn-sm">{{'emailTemplate.backBtn' | translate}}</button>
    </div>
    <div class="clr-col participant-btn">
      <button id="sendInviteBtn" [clrLoading]="sendBtnState" (click)="sendAll()"
              class="btn btn-primary btn-sm"
              [disabled]="isAllParticipantsEmailsSend()">
        {{'ksaEngagement.emailManagement.sendInvite.buttons.'.concat(isSendToAllLabel() ? 'sendAll' : 'sendToUpdated') | translate}}
      </button>
    </div>
  </div>
</form>

<clr-modal [(clrModalOpen)]="shouldShowDeleteConfirmDialog">
  <h3 class="modal-title" *ngIf="shouldDeleteAllParticipants">{{'ksaEngagement.emailManagement.sendInvite.confirmationDialog.deleteAllTitle' | translate}}</h3>
  <div class="modal-body" *ngIf="shouldDeleteAllParticipants">{{'ksaEngagement.emailManagement.sendInvite.confirmationDialog.deleteAllBody' | translate}}</div>
  <h3 class="modal-title" *ngIf="!shouldDeleteAllParticipants">{{'ksaEngagement.emailManagement.sendInvite.confirmationDialog.deleteSingleTitle' | translate}}</h3>
  <div class="modal-body" *ngIf="!shouldDeleteAllParticipants">{{'ksaEngagement.emailManagement.sendInvite.confirmationDialog.deleteSingleBody' | translate}}</div>
  <div class="modal-footer">
    <button (click)="onDeleteParticipantCancel()" id="onDeleteParticipantCancelBtn"
            class="btn btn-link btn-sm">{{'ksaEngagement.emailManagement.sendInvite.confirmationDialog.buttons.no' | translate}}
    </button>
    <button (click)="onDeleteParticipantsConfirmed(shouldDeleteAllParticipants)"
            class="btn btn-primary btn-sm">{{'ksaEngagement.emailManagement.sendInvite.confirmationDialog.buttons.yes' | translate}}
    </button>
  </div>
</clr-modal>
<app-participant-dialog *ngIf="isParticipantDialogOpened"
                        [dialogMode]="participantDialogMode"
                        [participants]="toEmailParticipants"
                        [ksaId]="this.ksaEmail.masterKsaId"
                        (onParticipantUpdate)="updateParticipant($event)"
                        (onDialogClose)="onParticipantWindowClose()">
</app-participant-dialog>
