/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {MasterSurvey} from "./master-survey.model";

/**
 * @author avalchev
 */
export class EditingSurveyData {

  surveyId: number = 0;
  userId: number = 0;
  firstName: string = "";
  lastName: string = "";
  email: string = "";

  constructor(survey?: MasterSurvey) {
    if (survey) {
      this.surveyId = survey.id;
      this.userId = survey.editingUser.id;
      this.firstName = survey.editingUser.firstName;
      this.lastName = survey.editingUser.lastName;
      this.email = survey.editingUser.emailId;
    }
  }

}
