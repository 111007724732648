<ng-container *ngIf="surveyTemplateConfig">

  <button type="button" class="btn btn-sm btn-link" id="backBtn"
          [routerLink]="['/Home/ManageSkillsRoleProfilesAndGroups']">
    <cds-icon shape="angle" class="back-btn-icon"></cds-icon>
    {{'ksaEngagement.ksaAdministration.mngSkillsRoleProfilesAndGroups.backBtn' | translate}}
  </button>
  <form clrForm>
    <clr-input-container>
      <label
        class="clr-col-2">{{'ksaEngagement.ksaAdministration.mngSkillsRoleProfilesAndGroups.renameConfig' | translate}}</label>
      <input clrInput name="surveyTemplateConfigName" class="clr-col-3"
             type="text" (blur)="updateSurveyTemplateConfigName()"
             [(ngModel)]="surveyTemplateConfigName"
             [disabled]="surveyTemplateConfig.status === SurveyTemplateConfigStatus.INACTIVE">
    </clr-input-container>
  </form>

  <clr-tabs>

    <clr-tab *hasPermission="[Functionality.manageSkill]; op 'OR'">
      <button clrTabLink>{{'tab.coursesAndSkills.mngSkillsTab' | translate}}</button>
      <clr-tab-content *clrIfActive>
        <app-mng-skills [surveyTemplateConfigVersion]="surveyTemplateConfig"></app-mng-skills>
      </clr-tab-content>
    </clr-tab>

    <clr-tab *hasPermission="[Functionality.manageSkill]; op 'OR'">
      <button clrTabLink>{{'tab.coursesAndSkills.mngSkillsSequenceTab' | translate}}</button>
      <clr-tab-content *clrIfActive>
        <app-mng-skill-order [surveyTemplateConfigVersion]="surveyTemplateConfig"></app-mng-skill-order>
      </clr-tab-content>
    </clr-tab>

    <clr-tab *hasPermission="[Functionality.manageRoleProfile]; op 'OR'">
      <button clrTabLink>{{'tab.roleProfilesAndGroups.mngRoleProfilesTab' | translate}}</button>
      <clr-tab-content *clrIfActive>
        <app-mng-roles-profiles [surveyTemplateConfigVersion]="surveyTemplateConfig"></app-mng-roles-profiles>
      </clr-tab-content>
    </clr-tab>

    <clr-tab *hasPermission="[Functionality.manageRoleProfileGroup]; op 'OR'">
      <button clrTabLink>{{'tab.roleProfilesAndGroups.mngRoleProfileGroupsTab' | translate}}</button>
      <clr-tab-content *clrIfActive>
        <app-mng-role-profile-groups [surveyTemplateConfigVersion]="surveyTemplateConfig"></app-mng-role-profile-groups>
      </clr-tab-content>
    </clr-tab>

  </clr-tabs>

</ng-container>
