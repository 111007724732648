/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Roles, RootObject} from "../models/roles.model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  roleUrl: string;

  constructor(private http: HttpClient) {
    this.roleUrl = `${environment.API_URL}${API_KSA_CORE_V1}/ksarole/`;
  }

  getRole(filter1, filter2, from, to, sortby, sortorder): Observable<Roles> {
    filter1 = encodeURIComponent(filter1);
    filter2 = encodeURIComponent(filter2);
    return this.http.get<Roles>(`${this.roleUrl}?roleName=${filter1}&roleDescription=${filter2}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`)
  }

  getRolePermission(): Observable<RootObject[]> {
    return this.http.get<RootObject[]>(this.roleUrl + 'getfunctionalities');
  }

  addRolePermission(RoleDet: any): Observable<any> {
    return this.http.post(this.roleUrl, RoleDet);
  }

  editRolePermission(id: any): Observable<Roles[]> {
    return this.http.get<any>(this.roleUrl + id + '/' + 'rolefunctionalities');
  }

  updateRolePermission(RoleDet: any): Observable<any> {
    return this.http.put(this.roleUrl, RoleDet);
  }

  deleteRole(id: any): Observable<void> {
    return this.http.delete<void>(this.roleUrl + id);
  }

}
