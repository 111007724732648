/*******************************************************************************
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 ******************************************************************************/

import {ClrNotificationDialogOptions} from "./clr-notification-dialog-source.model";
import {ClrNotificationDialogService} from "./clr-notification-dialog.service";
import {NotificationDialogAction} from "./notification-dialog-action.model";
import {Component, ViewChild, ViewContainerRef} from "@angular/core";

@Component({
   selector: 'clr-notification-dialog',
   templateUrl: './clr-notification-dialog.component.html'
})
export class ClrNotificationDialogComponent {

   isOpen: boolean;

   dialogSource: ClrNotificationDialogOptions;
   onRejection: () => void;
   actionClicked: boolean = false;
   @ViewChild("customComponentContainer", {read: ViewContainerRef})
   private readonly customComponentContainer: ViewContainerRef;

   constructor(private readonly clrNotificationDialogService: ClrNotificationDialogService) {
      this.clrNotificationDialogService.openWindow.subscribe((dialogSource) => this.open(dialogSource));
   }

   open(dialogSource: ClrNotificationDialogOptions): void {
      this.dialogSource = dialogSource;
      this.customComponentContainer?.clear(); //NOSONAR - Mutting "Expected an assignment or function call and instead saw an expression" as this is not an expression - this is a function call
      if (dialogSource?.component) {
         dialogSource.componentRef = this.customComponentContainer.createComponent(dialogSource.component);
      }
      this.isOpen = true;

      this.onRejection = () => {
         this.onActionClick(this.dialogSource.rejectAction);
      };
   }

   ngOnDestroy() {
      this.dialogSource?.componentRef?.destroy(); //NOSONAR - Mutting "Expected an assignment or function call and instead saw an expression" as this is not an expression - this is a function call
   }

   close(): void {
      this.isOpen = false;
   }

   onOpenChange(): void {
      if (this.isOpen) {
         if (!this.actionClicked) {
            this.onRejection();
         }
      }
      this.actionClicked = false;
   }

   onActionClick(dialogAction: NotificationDialogAction): void {
      this.actionClicked = true;
      this.close();
      if (dialogAction && dialogAction.callback) {
         dialogAction.callback();
      }
   }

}
