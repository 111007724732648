import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs/Observable";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";
import {SurveyTemplateConfigVersionWithMasterSurveyName} from "../models/config-version-with-master-survey-names.model";
import {SurveyTemplateConfigStatus, SurveyTemplateConfigVersion} from "../models/config-version.model";

@Injectable({
  providedIn: 'root'
})
export class ConfigVersionService {

  private readonly url: string;

  constructor(private readonly http: HttpClient) {
    this.url = `${environment.API_URL}${API_KSA_CORE_V1}/survey_template_config_version`;
  }

  getAll(status?: SurveyTemplateConfigStatus): Observable<SurveyTemplateConfigVersion[]> {
    return this.http.get<SurveyTemplateConfigVersion[]>(this.url, {
      params: status ? new HttpParams().set('status', status) : {}
    });
  }

  getAllConfigVersionsWithMasterSurveyNames(): Observable<SurveyTemplateConfigVersionWithMasterSurveyName[]> {
    return this.http.get<SurveyTemplateConfigVersionWithMasterSurveyName[]>(`${this.url}/with_master_survey_names`);
  }

  getById(id: number): Observable<SurveyTemplateConfigVersion> {
    return this.http.get<any>(`${this.url}/${id}`);
  }

  create(config: SurveyTemplateConfigVersion): Observable<SurveyTemplateConfigVersion> {
    return this.http.post<any>(this.url, config);
  }

  clone(existingConfig: SurveyTemplateConfigVersion): Observable<SurveyTemplateConfigVersion> {
    return this.http.put<any>(`${this.url}/clone/${existingConfig.id}`, existingConfig);
  }

  deactivateAll(configVersionIds: number[]): Observable<any> {
    return this.http.put<any>(`${this.url}/deactivate`, configVersionIds);
  }

  update(surveyTemplateConfig: SurveyTemplateConfigVersion): Observable<any> {
    return this.http.put<any>(`${this.url}/${surveyTemplateConfig.id}`, surveyTemplateConfig);
  }
}
