<div *ngIf="showSuccessMessage && messageSuccessTimer" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
      </div>
      <span class="alert-text">
        {{'analysisAndReports.responseManagement.certificateSuccessMessage' | translate}}
      </span>
    </div>
  </div>
</div>

<div *ngIf="showErrorMessage" class="alert alert-danger" id="alert-message" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
      </div>
      <span class="alert-text">
        {{'surveyTemplate.masterSurvey.errorMsg'|translate}}
      </span>
    </div>
  </div>
</div>

<div *ngIf="showLoader">
  <clr-spinner>Loading ...</clr-spinner>
</div>
<div *ngIf="!showLoader">
  <div class="clr-row btn-group-response">
    <div class="left-btn clr-col-3">
    <button (click)="onShowOriginalData()" class="btn btn-primary btn-sm"
            [disabled]="!showDataGrid">
      {{'analysisAndReports.responseManagement.viewOriginalData' | translate}}</button>
    </div>
    <div class="right-btn clr-col-9" *hasAccess="functionality.analysis">
    <button (click)="showRestoreResponse = true" class="btn btn-primary btn-sm"
            [disabled]="!showDataGrid">
      {{'analysisAndReports.responseManagement.reset' | translate}}</button>
    <button (click)="onRefresh()" class="btn btn-sm btn-primary" [disabled]="!showDataGrid">
      {{"analysisAndReports.reports.refresh" | translate}}
    </button>
    <button (click)="onOpenNewWindow()" *ngIf="showNewWindowButton" class="btn btn-sm btn-primary"
            [disabled]="!showDataGrid">
      {{"analysisAndReports.reports.openNewWindow" | translate}}
    </button>
    <button (click)="onSaveButton()" [disabled]="!certificateDetails || !showDataGrid"
            class="btn btn-primary btn-sm">{{'surveyTemplate.masterSurvey.save'|translate}}
    </button>
    </div>
  </div>
  <div *ngIf="isSavedResponse" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text">{{'analysisAndReports.responseManagement.responseSuccess' | translate}}</span>
      </div>
    </div>
  </div>
  <div *ngIf="isResetResponse" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text">{{'analysisAndReports.responseManagement.certificateResetSuccess' | translate}}</span>
      </div>
    </div>
  </div>
  <clr-datagrid *ngIf="showDataGrid" class="certificate-datagrid" [clrDgLoading]="loading"
                (clrDgRefresh)="certificationRefresh($event)">

    <ng-container *ngFor="let certificationColumn of responseColumnSettings; let i = index">
      <clr-dg-column class="width-175" [clrDgField]="certificationColumn.responseColumnDisplay.questionName"
                     [ngClass]="(pinnedColumn == certificationColumn.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="responseColumnSettings[i].isHideable">
          {{certificationColumn.responseColumnDisplay.questionName}}
        </ng-template>
      </clr-dg-column>
    </ng-container>
    <clr-dg-column *ngFor="let header of certificateDetails?.headerDTOS" class="center-align-title, width-175">
      {{header?.name}}
    </clr-dg-column>
    <clr-dg-row>
      <clr-dg-cell *ngFor="let certificationColumn of responseColumnSettings; let i = index"
                   [ngClass]="(pinnedColumn == certificationColumn.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''"
                   style="text-align: center">
        <clr-icon shape="pin" title="pin" (click)="setPinnedColumn(certificationColumn.responseColumnDisplay.questionName)"
                  [ngClass]="(pinnedColumn == certificationColumn.responseColumnDisplay.questionName) ? 'pinned-column' : ''">
        </clr-icon>
      </clr-dg-cell>
      <clr-dg-cell *ngFor="let certificationColumn of certificateDetails?.headerDTOS"></clr-dg-cell>
    </clr-dg-row>
    <clr-dg-row *ngFor="let certificationColumn of certificateDetails?.certificateData; let i = index">
      <clr-dg-cell class="width-175" *ngFor="let columnSettings of responseColumnSettings"
                   [attr.name]="columnSettings?.responseColumnDisplay?.questionName+i"
                   [ngClass]="(pinnedColumn == columnSettings.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''">
        {{analysisService.getAnswerFromParticipant(certificationColumn.responseColumnWithParticipants, columnSettings?.responseColumnDisplay?.id)}}
      </clr-dg-cell>

      <clr-dg-cell class="width-175"
                   *ngFor="let item of certificateDetails?.certificateData[i].certificationByParticipants">
        <div class="achieved" *ngIf="item.resultAchieved != null">
          <div class="clr-row" *ngIf="item.resultAchieved != null">
            <div class="clr-col-6 certification-icon">
              <clr-tooltip>
                <clr-icon badge="success" clrTooltipTrigger shape="certificate"></clr-icon>
                <clr-tooltip-content>
                  {{'analysisAndReports.responseManagement.achieved' | translate}}
                </clr-tooltip-content>
              </clr-tooltip>
            </div>
            <div class="clr-col-6">
              <input [(ngModel)]="item.resultAchieved" [checked]="item.resultAchieved" clrCheckbox
                     name="resultAchieved" type="checkbox" />
            </div>
            </div>
        </div>
        <div *ngIf="item.resultDesired !=null" class="desired">
          <div class="clr-row">
            <div class="clr-col-6 certification-icon">
              <clr-tooltip>
                <clr-icon badge="success" clrTooltipTrigger shape="bullseye" ></clr-icon>
                <clr-tooltip-content>
                  {{'analysisAndReports.responseManagement.desired' | translate}}
                </clr-tooltip-content>
              </clr-tooltip>
            </div>
            <div class="clr-col-6">
              <input [(ngModel)]="item.resultDesired" [checked]="item.resultDesired" clrCheckbox
                     name="resultDesired"
                     type="checkbox"/>
            </div>
          </div>
        </div>
      </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-column-toggle>
        <clr-dg-column-toggle-title class="column-toggle-title">Columns
          <clr-icon role="button" shape="floppy" title="Save" (click)="onSaveResponseColumns()"  class="save-col-icon"> </clr-icon>
        </clr-dg-column-toggle-title>
      </clr-dg-column-toggle>
      <clr-dg-pagination #pagination [clrDgPageSize]="pageSizeService.getPageSize() | async" [clrDgTotalItems]="total"
                         [(clrDgPage)]="page">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Certifications per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Certifications
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>

<clr-modal [(clrModalOpen)]="showRestoreResponse">
  <h3 class="modal-title">
    {{"analysisAndReports.responseManagement.restoreConfirmationMsgHeader" | translate}}
  </h3>
  <div class="modal-body">
    <p>{{"analysisAndReports.responseManagement.restoreConfirmationMsg" | translate}}</p>
  </div>
  <div class="modal-footer">
    <button (click)="showRestoreResponse = false" class="btn btn-outline btn-sm">
      {{'admin.cancelBtn' | translate}}
    </button>
    <button (click)="onResetData()" class="btn btn-primary btn-sm">
      {{"analysisAndReports.responseManagement.reset" | translate}}
    </button>
  </div>
</clr-modal>
