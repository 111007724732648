/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MngEmailTemplatesComponent} from './mng-email-templates/mng-email-templates.component';
import {MngMasterSurveyComponent} from './mng-master-survey/mng-master-survey.component';
import {HomeComponent} from '../home/home.component';
import {TabRolesUsersComponent} from "./tab-roles-users/tab-roles-users.component";
import {AuthGuard} from "../../guards/auth.guard";
import {CanDeactivateGuard} from '../../services/check-survey.service';
import {
  TranslateMasterSurveyComponent
} from "./mng-master-survey/translate-master-survey/translate-master-survey.component";
import {
  MngSkillsRoleProfilesAndGroupsComponent
} from "./mng-skills-role-profiles-and-groups/mng-skills-role-profiles-and-groups.component";
import {MngCoursesComponent} from "./mng-courses/mng-courses.component";
import {
  TabMngSkillsSkillOrderRoleProfilesAndRoleProfileGroupsComponent
} from "./tab-mng-skills-skill-order-role-profiles-and-role-profile-groups/tab-mng-skills-skill-order-role-profiles-and-role-profile-groups.component";

const routes: Routes = [
  {
    path: 'Home', component: HomeComponent, children: [
      {
        path: 'ManageRolesUsers', component: TabRolesUsersComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Administration / Manage Roles & Users'
        }
      },
      {
        path: 'ManageCourses', component: MngCoursesComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Administration / Manage Courses'
        }
      },
      {
        path: 'ManageEmailTemplates', component: MngEmailTemplatesComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Administration / Manage Email Templates'
        }
      },
      {
        path: 'ManageMasterSurvey', component: MngMasterSurveyComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Administration / Manage Survey Templates'
        }
      },
      {
        path: 'ManageSurveyTranslation/:surveyId/:language', component: TranslateMasterSurveyComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Administration / Manage Survey Translation'
        }
      },
      {
        path: 'ManageSkillsRoleProfilesAndGroups', component: MngSkillsRoleProfilesAndGroupsComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Administration / Manage Skills, Role Profiles & Groups'
        }
      },
      {
        path: 'ManageSkillsRoleProfilesAndGroups/:id',
        component: TabMngSkillsSkillOrderRoleProfilesAndRoleProfileGroupsComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Administration / Manage Skills, Role Profiles & Groups'
        }
      }
    ],
    data: {breadcrumb: {skip: true}}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule {
}
