/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {QuestionMode} from '../enums/ksa-survey-settings.enum';
import {QuestionType} from '../enums/questionType.enum';
import {SurveyControls} from '../enums/survey-controls.enum';
import {NextButtonParticipant, ParticipantQuestion, PreButtonParticipant} from '../models/participant-ksa.model';
import {DisplayLogicService} from './display-logic.service';
import {KsaRoleProfileService} from './ksa-role-profile.service';
import {ParticipantService} from './participant.service';
import {SkipLogicService} from './skip-logic.service';
import {AnswerLabel} from "../models/master-survey-questions.model";

@Injectable({
  providedIn: 'root'
})
export class KsaSurveyPageFunctionalityService {
  questionTypeEnum: typeof QuestionType = QuestionType;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  questionModeEnum: typeof QuestionMode = QuestionMode;

  constructor(private ksaRoleProfileService: KsaRoleProfileService,
              private displayLogicService: DisplayLogicService,
              private participantService: ParticipantService,
              private skipLogicService: SkipLogicService) {
  }

  onDisplayNextList(start: number, questionList: ParticipantQuestion[]): NextButtonParticipant {
    let questionsToDisplay = [];
    let validPageFound = false;
    let displayPreButton: boolean;
    for (let questionIndex = start; questionIndex < questionList.length; questionIndex++) {
      questionsToDisplay.push(questionList[questionIndex]);
      if (questionList[questionIndex]?.isPageBreakAdded) {
        if (start === 0) {
          start = questionIndex + 1;
          break;
        } else {
          displayPreButton = true;
          break;
        }
      }
    }
    do {
      validPageFound = questionsToDisplay.some(q => q.isDisplay)
      if (!validPageFound) {
        start = questionsToDisplay[questionsToDisplay.length - 1].index;
        questionsToDisplay = [];
        for (let questionIndex = start; questionIndex < questionList.length; questionIndex++) {
          questionsToDisplay.push(questionList[questionIndex]);
          if (questionList[questionIndex]?.isPageBreakAdded) {
            if (start === 0) {
              start = questionIndex + 1;
              break;
            } else {
              displayPreButton = true;
              break;
            }
          }
        }
      }
    }
    while(!validPageFound)
    return {
      questionsToDisplay,
      start,
      displayPreButton
    };
  }

  checkIfNextPageIsBlank(start: number, questionList: ParticipantQuestion[]) {
    let isNextPageBlank = false;
    for (let questionIndex = start; questionIndex < questionList.length; questionIndex++) {
      if(questionList[questionIndex]?.isDisplay){
        isNextPageBlank = true;
        break;
      }
    }
    return isNextPageBlank;
  }

  onDisplayPreList(questionList: ParticipantQuestion[],
                   initial: number): PreButtonParticipant {
    let validPageFound = false;
    let end = initial;
    let questionsToDisplay = [];
    let displayPreButton: boolean;
    let nextIndex: number;
    do {
      for (let questionIndex = end - 1; questionIndex >= 0; questionIndex--) {
        questionsToDisplay.push(questionList[questionIndex]);
        if (questionList[questionIndex].isDisplay) {
          validPageFound = true;
        }
        if (questionList[questionIndex - 1]?.isPageBreakAdded === true) {
          initial = questionIndex;
          displayPreButton = true;
          break;
        }
      }
      if (validPageFound) {
        questionsToDisplay.sort((previousQuestion, nextQuestion) => {
          return previousQuestion.index - nextQuestion.index;
        });
        nextIndex = questionsToDisplay[questionsToDisplay.length - 1]?.index;
        displayPreButton = true;
      }
      else{
        questionsToDisplay.sort((previousQuestion, nextQuestion) => {
          return previousQuestion.index - nextQuestion.index;
        });
        end = questionsToDisplay[0].index - 1;
        initial = questionsToDisplay[0].index;
        nextIndex = questionsToDisplay[questionsToDisplay.length -1].index;
        questionsToDisplay = [];
      }
    }
    while (!validPageFound)
    return {
      questionsToDisplay,
      initial,
      displayPreButton,
      nextIndex
    };
  }


  mandatoryQuestionAnswered(questionsToDisplay: ParticipantQuestion[], ksaTypeIsAdvance: boolean): boolean {
    let unansweredQuestionFound = false;
    questionsToDisplay.forEach(question => {
      const questionTypeId = question.question.questionType.id;
      if ((question.isMandatory && question.isDisplay) || (questionTypeId === this.questionTypeEnum.RoleProfile &&
        question.isDisplay)) {
        switch (questionTypeId) {
          case this.questionTypeEnum.FreeText: {
            if (question.question.answerValuesText === '' || question.question.answerValuesText === null) {
              unansweredQuestionFound = true;
              return;
            }
          }
            break;
          case this.questionTypeEnum.RoleProfile: {
            if ((question.question.answerValuesText === '' || question.question.answerValuesText === null) && ksaTypeIsAdvance == true) {
              unansweredQuestionFound = true;
              return;
            }
          }
            break;
          case this.questionTypeEnum.RadioButton: {
            let isRadioQuestionSelected = {};
            isRadioQuestionSelected = question.question.answerLabels.find(label => {
              return label.isChecked === true;
            });
            if (isRadioQuestionSelected === undefined) {
              unansweredQuestionFound = true;
              return;
            }
          }
            break;
          case this.questionTypeEnum.MultipleChoice: {
            let isMultipleChoiceQuestionSelected = {};
            isMultipleChoiceQuestionSelected = question.question.answerLabels.find(label => {
              return label.isChecked === true;
            });
            if (isMultipleChoiceQuestionSelected === undefined) {
              unansweredQuestionFound = true;
              return;
            }
          }
            break;
          case this.questionTypeEnum.Scale: {
            if (question.question.answerValuesText === '' || question.question.answerValuesText === null) {
              unansweredQuestionFound = true;
              return;
            }
          }
            break;
          case this.questionTypeEnum.MatrixTable: {
            if (question.question.answerProperty.isRadio) {
              question.question.matrixRows.forEach(row => {
                const tempRow = question.question.answerControls.filter(control => {
                  return control.rowNumber === row.answerLabelSequenceNo;
                });
                const tempColumn = tempRow.find(col => {
                  return col.isChecked === true;
                });
                if (tempColumn == null) {
                  unansweredQuestionFound = true;
                  return;
                }
              });
            }
            else {
              let isMatrixQuestionSelected = {};
              isMatrixQuestionSelected = question.question.answerControls.find(label => {
                return label.isChecked === true;
              });
              if (isMatrixQuestionSelected === undefined) {
                unansweredQuestionFound = true;
                return;
              }
            }
          }
            break;
          case 7: {
            if (question.question.answerValuesText === '') {
              unansweredQuestionFound = true;
              return;
            }
          }
            break;
          default:
            break;
        }
      }
    });
    return unansweredQuestionFound;
  }

  onRoleProfileSelect(id: number, questionList: ParticipantQuestion[], surveyQuestionMode): number[] {
    let skillsIdsJobRoleProfile: number[] = [];
    this.participantService.getKsaRoleProfileSkill(id).subscribe(response => {
      skillsIdsJobRoleProfile = response;
      questionList.forEach(question => {
        question.isDisplay = true;
        question.isRoleProfileDisplay = true;
        if (surveyQuestionMode != this.questionModeEnum.ExposeAllQuestionSections) {
          if (question.skill?.id) {
            if (skillsIdsJobRoleProfile.indexOf(question.skill?.id) > -1) {
              question.isDisplay = true;
              question.isRoleProfileDisplay = true;
            } else {
              question.isDisplay = false;
              question.isRoleProfileDisplay = false;
            }
          }
        }
      });
    });
    return skillsIdsJobRoleProfile;
  }

  setDisplayOnInitialize(questionList: ParticipantQuestion[]): void {
    questionList.forEach(question => {
      if (question?.question?.questionType.id === this.questionTypeEnum.FreeText) {
        questionList = this.updateFreeTextQuestions(question, questionList);
      } else if (question?.question?.questionType.id === this.questionTypeEnum.RadioButton) {
        questionList = this.updateRadioButtonQuestions(question, questionList);
      } else if (question?.question?.questionType.id === this.questionTypeEnum.Scale) {
        questionList = this.updateScaleQuestions(question, questionList);
      } else if (question?.question?.questionType.id === this.questionTypeEnum.MatrixTable) {
        questionList = this.updateMatrixQuestions(question, questionList);
      } else if (question?.question?.questionType.id === this.questionTypeEnum.MultipleChoice) {
        questionList = this.updateMultipleChoiceQuestions(question, questionList);
      }
    });
  }

  updateFreeTextQuestions(question: ParticipantQuestion, questionList: ParticipantQuestion[]): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionList;
    if (question.questionSkipCondition?.id) {
      resultQuestionList = this.skipLogicService.skipLogicForFree(question, questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      resultQuestionList = this.displayLogicService.displayLogicForFree(question, questionList, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      resultQuestionList = this.displayLogicService.displayLogicForFree(question, questionList, false);
    }

    return resultQuestionList;
  }

  updateRadioButtonQuestions(question: ParticipantQuestion, questionList: ParticipantQuestion[]): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionList;
    if (question.questionSkipCondition?.id) {
      resultQuestionList = this.skipLogicService.skipLogicForRadio(question, questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      resultQuestionList = this.displayLogicService.displayLogicForRadio(question, questionList, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      resultQuestionList = this.displayLogicService.displayLogicForRadio(question, questionList, false);
    }

    return resultQuestionList;
  }

  private updateScaleQuestions(question: ParticipantQuestion, questionList: ParticipantQuestion[]) {
    let resultQuestionList: ParticipantQuestion[] = questionList;
    if (question.questionSkipCondition?.id) {
      resultQuestionList = this.skipLogicService.skipLogicForScale(question, questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      resultQuestionList = this.displayLogicService.displayLogicForScale(question, questionList,
        question.question.answerValuesText, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      resultQuestionList = this.displayLogicService.displayLogicForScale(question, questionList,
        question.question.answerValuesText, false);
    }

    return resultQuestionList;
  }

  private updateMatrixQuestions(question: ParticipantQuestion, questionList: ParticipantQuestion[]) {
    let resultQuestionList: ParticipantQuestion[] = questionList;
    const answerProperty = question.question.answerProperty;
    question.question.answerControls?.forEach(control => {
      if (answerProperty.isRadio) {
        if (question.questionSkipCondition?.id) {
          resultQuestionList = this.skipLogicService.skipLogicForMatrixRadio(question, questionList, control.isChecked,
            control.rowNumber, control.colNumber);
        }

        if (question.questionDisplayConditions?.length > 0) {
          resultQuestionList = this.displayLogicService.displayLogicForMatrixRadio(question, questionList, control.isChecked,
            control.rowNumber, control.colNumber, true);
        }

        if (question.sectionSkipConditions?.length > 0) {
          resultQuestionList = this.displayLogicService.displayLogicForMatrixRadio(question, questionList, control.isChecked,
            control.rowNumber, control.colNumber, false);
        }
      } else {
        if (question.questionSkipCondition?.id) {
          resultQuestionList = this.skipLogicService.skipLogicForMatrixCheckBox(question, questionList, control.isChecked,
            control.rowNumber, control.colNumber);
        }

        if (question.questionDisplayConditions?.length > 0) {
          resultQuestionList = this.displayLogicService.displayLogicForMatrixCheckBox(question, questionList, control.isChecked,
            control.rowNumber, control.colNumber, true);
        }

        if (question.sectionSkipConditions?.length > 0) {
          resultQuestionList = this.displayLogicService.displayLogicForMatrixCheckBox(question, questionList, control.isChecked,
            control.rowNumber, control.colNumber, false);
        }
      }
    });

    return resultQuestionList;
  }

  private updateMultipleChoiceQuestions(question: ParticipantQuestion, questionList: ParticipantQuestion[]) {
    let resultQuestionList: ParticipantQuestion[] = questionList;
    question.question.answerLabels.forEach(answerLabel => {
      if (question.questionSkipCondition?.id) {
        resultQuestionList = this.skipLogicService.skipLogicForMultiple(question, questionList);
      }

      if (question.questionDisplayConditions?.length > 0) {
        resultQuestionList = this.displayLogicService.displayLogicForMultiple(question, questionList, answerLabel, true);
      }

      if (question.sectionSkipConditions?.length > 0) {
        resultQuestionList = this.displayLogicService.displayLogicForMultiple(question, questionList, answerLabel, false);
      }
    });

    return resultQuestionList;
  }

  selectAnswerInputRadio(answerLabels: AnswerLabel[], selectedAnswerLabel: AnswerLabel): void {
    const lastSelectedAnswerLabel: AnswerLabel = answerLabels.find(answerLabel => answerLabel.isChecked);
    answerLabels.forEach(answerLabel =>
      answerLabel.isChecked = answerLabel.id === selectedAnswerLabel.id && lastSelectedAnswerLabel?.id !== selectedAnswerLabel.id);
  }
}
