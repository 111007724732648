export enum QuestionMode{
    ExposeAllQuestionSections= 1,
    ManuallyChooseQuestionSections= 2,
    RoleProfileDriven= 3
}

export enum GlobalThreshold{
    GlobalThreshold = 65
}

export enum AnonymityTypeIdEnum {
  Anonymous = 1,
  Semi_Anonymous = 2,
  None = 3
}
