import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionType} from '../../../../../enums/questionType.enum';
import {SurveyControls} from '../../../../../enums/survey-controls.enum';
import {AnswerLabel, MasterSurveyQuestion} from '../../../../../models/master-survey-questions.model';
import {MasterSurveySection} from '../../../../../models/master-survey-section.model';
import {QuestionDisplayCondition} from '../../../../../models/participant-ksa.model';
import {MasterSurveyService} from '../../../../../services/master-survey.service';

@Component({
  selector: 'app-display-logic-pop-up',
  templateUrl: './display-logic-pop-up.component.html',
  styleUrls: ['./display-logic-pop-up.component.scss']
})
export class DisplayLogicPopUpComponent implements OnInit {

  @Input() questionData: any;
  @Input() showDisplayLogicModal;
  @Input() sectionReference;
  @Output() showDisplayLogic: EventEmitter<boolean> = new EventEmitter();
  @Output() onUpdateDisplay: EventEmitter<boolean> = new EventEmitter();

  warningTextDisplayLogic: string;
  showDisplayAnswerLabel: boolean = false;
  selectedSection: MasterSurveySection[];
  questionsSelectedSection: MasterSurveyQuestion[];
  selectedSectionDisplayLogic: MasterSurveySection;
  selectedQuestionDisplayLogic: MasterSurveyQuestion;
  selectedAnswerLabelDisplayLogic: string = '';
  selectedSkipTypeDisplayLogic: number;
  displayValueDisplayLogic: number = undefined;
  selectedColumnAnswer: string = '';
  selectedRowAnswer = '';
  answerLabelSelectedQuestionColumn = new Array<AnswerLabel>();
  answerLabelSelectedQuestionRow = new Array<AnswerLabel>();
  answerLabelSelectedQuestion: AnswerLabel[];
  valueAnswerLabelOptions: AnswerLabel[];
  displayValueInput: boolean;
  showMatrixAnswerLabel: boolean;
  questionTypeEnum: typeof QuestionType = QuestionType;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;

  constructor(private masterService: MasterSurveyService) { }

  ngOnInit(): void {
    this.onShowDisplayLogicModal();
    if (this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1].questionDisplayCondition) {
      if (this.questionData.value.questionDisplayCondition.answerLabel) {
        this.questionData.value.questionDisplayCondition.answerLabelSequenceNo = this.questionData.value.questionDisplayCondition.answerLabel.answerLabelSequenceNo;
      }
      if (this.questionData.value.questionDisplayCondition.colAnswerLabel) {
        this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo = this.questionData.value.questionDisplayCondition.colAnswerLabel.answerLabelSequenceNo;
      }
      this.onInitializationDisplayLogic(this.questionData.value.questionDisplayCondition);
    } else {
      this.questionData.value.questionDisplayCondition = this.createDisplayControl();
    }
  }

  onInitializationDisplayLogic(questionDisplayCondition: QuestionDisplayCondition): QuestionDisplayCondition {
    [...this.questionsSelectedSection] = [];
    this.selectedSection?.forEach(section => {

      if (section.tempSectionId == questionDisplayCondition?.tempSectionId) {
        this.selectedSectionDisplayLogic = section;

        this.selectedQuestionDisplayLogic = this.selectedSectionDisplayLogic.sectionQuestions.find(question => {
          return question.tempSurveyQuestionId == questionDisplayCondition?.tempSurveyQuestionId;
        });

        [...this.questionsSelectedSection] = this.selectedSectionDisplayLogic.sectionQuestions.filter(questionval =>
          { return questionval.question?.questionType?.id !== this.questionTypeEnum.Description &&
            questionval.question.questionText &&
             questionval.question?.questionType?.id !== this.questionTypeEnum.RoleProfile });
        [...this.questionsSelectedSection] = this.selectedSectionDisplayLogic.sectionQuestions.filter(question => {
          return question.tempSurveyQuestionId != this.questionData.value.tempSurveyQuestionId && question.question.questionText;
        });
        this.valueAnswerLabelOptions = [];
        this.onFetchSkipType();
        this.selectedSkipTypeDisplayLogic = questionDisplayCondition?.skipCondition?.id;

        if (this.selectedQuestionDisplayLogic?.question?.questionType?.id != this.questionTypeEnum.MatrixTable) {
          if (questionDisplayCondition?.answerLabelSequenceNo) {
            this.showDisplayAnswerLabel = true;
            this.answerLabelSelectedQuestion = this.selectedQuestionDisplayLogic?.question?.answerLabels;
            this.selectedQuestionDisplayLogic.question?.answerLabels?.forEach(answer => {
              if (answer?.answerLabelSequenceNo == questionDisplayCondition?.answerLabelSequenceNo) {
                this.selectedAnswerLabelDisplayLogic = answer?.answerLabelSequenceNo + ' : ' + answer?.labelText;
              }
            });
          } else {
            this.answerLabelSelectedQuestion = [];
            this.showDisplayAnswerLabel = false;
          }
          if (questionDisplayCondition?.displayValue) {
            this.displayValueInput = true;
            this.displayValueDisplayLogic = questionDisplayCondition?.displayValue;
          } else {
            this.displayValueInput = false;
          }
        } else {
          this.showDisplayAnswerLabel = false;
          this.showMatrixAnswerLabel = true;
          this.answerLabelSelectedQuestionColumn = [];
          this.answerLabelSelectedQuestionRow = [];
          const labels: AnswerLabel[] = this.selectedQuestionDisplayLogic.question?.answerLabels;
          labels?.forEach(label => {
            if (label.isRow) {
              this.answerLabelSelectedQuestionRow.push(label);
            } else {
              this.answerLabelSelectedQuestionColumn.push(label);
            }
          })
          this.answerLabelSelectedQuestionRow?.forEach(answerRow => {
            if (answerRow.answerLabelSequenceNo == questionDisplayCondition?.answerLabelSequenceNo) {
              this.selectedRowAnswer = answerRow.answerLabelSequenceNo + ' : ' + answerRow.labelText;
            }
          });
          this.answerLabelSelectedQuestionColumn?.forEach(answerColumn => {
            if (answerColumn.answerLabelSequenceNo == questionDisplayCondition?.colAnswerLabelSequenceNo) {
              this.selectedColumnAnswer = answerColumn.answerLabelSequenceNo + ' : ' + answerColumn.labelText;
            }
          });
        }
      }
    });

    return ({
      answerLabelSequenceNo: questionDisplayCondition.answerLabel?.answerLabelSequenceNo,
      displayValue: questionDisplayCondition.displayValue,
      colAnswerLabelSequenceNo: questionDisplayCondition.colAnswerLabelSequenceNo,
      skipCondition: {
        id: questionDisplayCondition.skipCondition.id
      },
      tempSectionId: this.questionData.value.questionDisplayCondition.tempSectionId,
      tempSurveyQuestionId: this.questionData.value.questionDisplayCondition.tempSurveyQuestionId,
    });
  }

  onShowDisplayLogicModal(): void {
    this.selectedSectionDisplayLogic = undefined;
    this.selectedQuestionDisplayLogic =undefined;
    this.selectedRowAnswer ='';
    this.selectedAnswerLabelDisplayLogic = '';
    this.selectedSkipTypeDisplayLogic = undefined;
    this.selectedColumnAnswer = '';
    this.selectedRowAnswer = '';
    this.showMatrixAnswerLabel = false;
    this.showDisplayAnswerLabel = false;
    [...this.selectedSection] =[];
    this.showDisplayLogicModal = true;
    this.warningTextDisplayLogic = 'Please make sure the order of questions and sections are correct, before adding display logic input.';
    [...this.selectedSection] = this.sectionReference.surveySections;
  }



  onDisplayLogicSectionSelect(): void {
    this.selectedQuestionDisplayLogic =null;
    this.selectedRowAnswer ='';
    this.selectedAnswerLabelDisplayLogic = '';
    this.selectedSkipTypeDisplayLogic = undefined;
    this.selectedColumnAnswer = '';
    this.selectedRowAnswer = '';
    this.showMatrixAnswerLabel = false;
    this.showDisplayAnswerLabel = false;
    this.answerLabelSelectedQuestion=[];
    this.answerLabelSelectedQuestionColumn=[];
    this.answerLabelSelectedQuestionRow=[];

    this.questionData.value.questionDisplayCondition.tempSectionId = this.selectedSectionDisplayLogic.tempSectionId;

    [...this.questionsSelectedSection] = this.selectedSectionDisplayLogic.sectionQuestions.filter(question => {
      return question.tempSurveyQuestionId != this.questionData.value.tempSurveyQuestionId && question.question.questionText;
    });

    this.questionsSelectedSection = this.questionsSelectedSection.filter(questionval =>
      { return questionval.question.questionType.id !== this.questionTypeEnum.Description &&
        questionval.question.questionText &&
        questionval.question?.questionType?.id !== this.questionTypeEnum.RoleProfile});

    this.showDisplayAnswerLabel = false;
  }

  onDisplayLogicQuestionSelect(): void {
    this.questionData.value.questionDisplayCondition.tempSurveyQuestionId = null;
    this.questionData.value.questionDisplayCondition.answerLabelSequenceNo = null;
    this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo = null;
    this.questionData.value.questionDisplayCondition.displayValue = null;
    this.questionData.value.questionDisplayCondition.answerLabel = null;
    this.questionData.value.questionDisplayCondition.colAnswerLabel = null;
    this.questionData.value.questionDisplayCondition.tempSurveyQuestionId = this.selectedQuestionDisplayLogic.tempSurveyQuestionId;
    this.selectedSkipTypeDisplayLogic = null;
    this.valueAnswerLabelOptions = [];
    this.answerLabelSelectedQuestion=[];
    this.answerLabelSelectedQuestionColumn=[];
    this.answerLabelSelectedQuestionRow=[];
    this.onFetchSkipType();
  }

  onFetchSkipType(): void {
    this.valueAnswerLabelOptions = [];
    const questionType = this.selectedQuestionDisplayLogic?.question?.questionType?.id;
    switch (questionType) {
      case this.questionTypeEnum.FreeText:
        this.showDisplayAnswerLabel = false;
        this.displayValueInput = false;
        this.showMatrixAnswerLabel = false;
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[0].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition);
            }
          });
        });
        break;
      case this.questionTypeEnum.RadioButton:
        this.showDisplayAnswerLabel = true;
        this.displayValueInput = false;
        this.showMatrixAnswerLabel = false;
        [...this.answerLabelSelectedQuestion] = [...this.selectedQuestionDisplayLogic.question.answerLabels];
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[1].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition);
            }
          });
        });
        break;
      case this.questionTypeEnum.MultipleChoice:
        this.showDisplayAnswerLabel = true;
        this.displayValueInput = false;
        this.showMatrixAnswerLabel = false;
        [...this.answerLabelSelectedQuestion] = [...this.selectedQuestionDisplayLogic.question.answerLabels];
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[2].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition);
            }
          });
        });
        break;
      case this.questionTypeEnum.Scale:
        this.showDisplayAnswerLabel = false;
        this.displayValueInput = true;
        this.showMatrixAnswerLabel = false;
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[3].questionTypeSkipConditionMaps.forEach(condition => {
            this.valueAnswerLabelOptions.push(condition.skipCondition)
          });
        });
        break;
      case this.questionTypeEnum.Description:
        this.showDisplayAnswerLabel = false;
        this.displayValueInput = false;
        this.showMatrixAnswerLabel = false;
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[4].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition)
            }
          });
        });
        break;
      case this.questionTypeEnum.MatrixTable:
        this.showMatrixAnswerLabel = true;
        this.showDisplayAnswerLabel = false;
        this.displayValueInput = false;
        for (let i = 0; i < this.selectedQuestionDisplayLogic.question.answerLabels.length; i++) {
          const isRowValue = this.selectedQuestionDisplayLogic.question.answerLabels[i].isRow;
          if (isRowValue) {
            this.answerLabelSelectedQuestionRow.push(this.selectedQuestionDisplayLogic.question?.answerLabels[i]);
          } else {
            this.answerLabelSelectedQuestionColumn.push(this.selectedQuestionDisplayLogic.question?.answerLabels[i]);
          }
        }
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[5].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition);
            }
          });
        });
        break;
    }
  }

  onDisplayLogicValueAnswerLabelSelect(data: any): void {
    this.questionData.value.questionDisplayCondition.answerLabelSequenceNo = Number((data.target.value as string).charAt(0));
  }

  onDisplayLogicSkipTypeSelect(data: number): void {
    if (data == 3 || data == 4) {
      this.showMatrixAnswerLabel = false;
      this.showDisplayAnswerLabel = false;
      this.selectedColumnAnswer = '';
      this.selectedRowAnswer = '';
      this.selectedAnswerLabelDisplayLogic = '';
      this.questionData.value.questionDisplayCondition.answerLabelSequenceNo = undefined;
      this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo = undefined;
    } else {
      this.onFetchSkipType();
    }
    this.questionData.value.questionDisplayCondition.skipCondition.id = Number(data);
  }

  onDisplayValueInput(data: number): void {
    this.questionData.value.questionDisplayCondition.displayValue = data;
  }

  onCancelDisplayLogic(): void {
    this.showDisplayLogic.emit(false);
    this.selectedSectionDisplayLogic = null;
    this.selectedQuestionDisplayLogic = null;
    this.selectedAnswerLabelDisplayLogic = '';
    this.selectedSkipTypeDisplayLogic = undefined;
    this.selectedColumnAnswer = '';
    this.selectedRowAnswer = '';
    this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1].questionDisplayCondition = null;
    this.masterService.setSurveyUpdateStatus(false);
    this.onUpdateDisplay.emit(false);
  }

  createDisplayControl(): QuestionDisplayCondition {
    return {
      id: null,
      conditionMasterSurveySection: null,
      conditionSectionQuestion: null,
      answerLabel: null,
      colAnswerLabel: null,
      answerLabelSequenceNo: null,
      colAnswerLabelSequenceNo: null,
      displayValue: null,
      skipCondition: {
        id: null
      },
      tempSectionId: null,
      tempSurveyQuestionId: null
    }
  }

  isDisabledDisplayLogicButton(): boolean {
    let isDisableButton = false;
    if (!this.selectedSectionDisplayLogic || !this.selectedQuestionDisplayLogic || !this.selectedSkipTypeDisplayLogic) {
      isDisableButton = true;
      return isDisableButton;
    }
    if (this.selectedQuestionDisplayLogic?.question.questionType.id == this.questionTypeEnum.Scale &&
      !this.displayValueDisplayLogic) {
      isDisableButton = true;
      return isDisableButton;
    }
    if ((this.selectedQuestionDisplayLogic?.question.questionType.id == this.questionTypeEnum.RadioButton ||
      this.selectedQuestionDisplayLogic?.question.questionType.id == this.questionTypeEnum.MultipleChoice) &&
      !this.selectedAnswerLabelDisplayLogic) {
      isDisableButton = true;
      return isDisableButton;
    }
    if (this.selectedQuestionDisplayLogic?.question.questionType.id == this.questionTypeEnum.MatrixTable &&
      (!this.selectedColumnAnswer ||
        !this.selectedRowAnswer)) {
      isDisableButton = true;
      return isDisableButton;
    }
    return isDisableButton;
  }

  onDisplayLogicColumnValueAnswerSelect(data: any): void {
    this.questionData.value.questionSkipCondition.colAnswerLabel = null;
    this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo = Number((data.target.value as string).charAt(0));
  }

  onDisplayLogicRowValueAnswerSelect(data: any): void {
    this.questionData.value.questionSkipCondition.answerLabel = null;
    this.questionData.value.questionDisplayCondition.answerLabelSequenceNo = Number((data.target.value as string).charAt(0));
  }

  onOkDisplayLogic() {
    this.masterService.setSurveyUpdateStatus(false);
    this.showDisplayLogicModal = false;
    this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1].questionDisplayCondition = this.questionData.value.questionDisplayCondition;
    this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1].questionDisplayCondition.tempSectionId = this.questionData.value.questionDisplayCondition.tempSectionId;
    this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1].questionDisplayCondition.tempSurveyQuestionId = this.questionData.value.questionDisplayCondition.tempSurveyQuestionId;
    if (this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
      this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1].questionDisplayCondition.answerLabelSequenceNo = this.questionData.value.questionDisplayCondition.answerLabelSequenceNo;
    }
    if (this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo) {
      this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1].questionDisplayCondition.colAnswerLabelSequenceNo = this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo;
    }
    this.onUpdateDisplay.emit(true);
  }
  ngOnDestroy() {
    this.showDisplayLogicModal = false;
  }

}
