/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EmailData, EmailTemplate} from "../../../../models/email-template.model";
import {EmailTemplateService} from "../../../../services/email-template.service";
import {KsaEmailManagementService} from "../../../../services/ksa-email-management.service";
import {DatePipe} from '@angular/common';
import {Status} from '../../../../enums/status.enum';
import {ReminderEmail} from '../../../../models/kas-email.model';
import {EmailValidationService} from "../../../../services/email-validation.service";
import {isEqual} from "lodash";
import {finalize} from "rxjs/operators";
import {hideSpinner, showSpinner} from "../../../../common/spinner";
import {KsaEmailDTO, KsaEmailTemplateDTO} from "../../../../models/ksa-email-dto.model";
import {TranslateService} from "@ngx-translate/core";
import {EmailTemplateTypeEnum} from "../../../../enums/email-template-type.enum";
import {NgForm} from "@angular/forms";

declare let $: any;

@Component({
  selector: 'app-compose-email',
  templateUrl: './compose-email.component.html',
  styleUrls: ['./compose-email.component.scss']
})
export class ComposeEmailComponent implements OnInit {

  masterEmailTemplates: EmailTemplate[] = [];
  @Input() ksaId: number;
  @Input() placeHolderNameList: any[] = [];
  @Input() isThanks: boolean;
  @Input() isInvite: boolean;
  @Input() isScheduler: boolean;
  @Input() showReminderButton: boolean;
  private ksaEmail: KsaEmailDTO;
  showKsaDetailsWarning: boolean = false;
  saveEmailTemplateInfo: boolean = false;
  showDeleteSchedulerPopUp: boolean = false;
  errorMessage: string = '';
  emailTemplateId: number;
  ksaEmailSubject: string = '';
  ksaEmailBody: string = '';
  datePipe = new DatePipe("en-US");
  timeZoneList: string = '';
  isEmailReminderSet: boolean = false;
  @Output() isComposeTemplate: EventEmitter<boolean> = new EventEmitter();
  emailSaveSuccessAlert: boolean = false;
  showSetReminderModal: boolean = false;
  public config: any;
  timeZoneLists = [];
  schedulerJob = [];
  errorMsg: string = '';
  isCurrentJobId: string = '';
  isCurrentJobGroupId = '';
  reminderList: ReminderEmail = {
    ksaEmailId: 0,
    status: '',
    dateTime: '',
    timeZone: '',
    jobId: '',
    jobGroup: ''
  }
  emailReminderData: ReminderEmail = {
    timeEntered: '',
    tillDate: ',',
    zoneEntered: '',
    emailReminderData: '',
    jobId: '',
    jobGroup: ''
  }
  isEditReminder: boolean = false;
  statusEnum: typeof Status = Status;
  invalidEmailAlert: boolean = false;
  undefinedPlaceHolders: string = '';
  isFormUpdated: boolean = false
  isFormSaved: boolean = false;
  initialEmail: EmailData = {
    emailTemplateId: null,
    ksaEmailSubject: '',
    ksaEmailBody: ''
  }

  @ViewChild('emailForm') emailForm: NgForm;

  constructor(private readonly emailTemplateService: EmailTemplateService,
              public readonly ksaEmailService: KsaEmailManagementService,
              private readonly emailValidationService: EmailValidationService,
              private readonly translate: TranslateService) {
    this.config = {
      height: '130px',
      toolbar: [
        ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
        ['customButtons', ['placeholder']],
        ['insert', ['table', 'picture', 'link', 'hr']]
      ],
      buttons: {
        'placeholder': this.customPlaceHolderButton()
      }
    };
  }


  ngOnInit(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaEmailService.getKsaEmailDtoByKsaId(this.ksaId)
      .pipe(finalize(hideSpinner))
      .subscribe((response: KsaEmailDTO) => {
        this.ksaEmail = response;
        this.setEmailTemplateValuesBasedOnType();
        this.setAppropriateEmailTemplates();
        if (!this.ksaEmail) {
          this.createKsaEmail();
        }
      }, error => this.setErrorMessage(error))
  }




  customPlaceHolderButton() {
    return (context) => {
      const ui = $.summernote.ui;
      const blockChar1 = '${';
      const blockChar2 = '}';
      const list = this.placeHolderNameList//['FirstName','LastName','SurveyUrl'];
      const button = ui.buttonGroup([
        ui.button({
          className: 'dropdown-toggle',
          contents: '<span class="template"/> Place Holder <span class="caret"></span>',
          tooltip: 'Place Holder',
          data: {
            toggle: 'dropdown'
          },
          click: function () {
            context.invoke('editor.saveRange');
          }
        }),
        ui.dropdown({
          className: 'dropdown-style',
          items: list,
          click: function (event) {
            event.preventDefault();
            const $button = $(event.target);
            const placeHolderValue = $button.data('value').replace(/ /g, "");
            const placeHolderText = blockChar1 + placeHolderValue + blockChar2;
            context.invoke('editor.insertText', placeHolderText);
          },
          template: function (item) {
            const content = (typeof item === 'string') ? item : (item.content || item.value || '');
            return content;
          }
        })
      ]);
      return button.render();
    }
  }

  onChangeEmailTemplate(value) {
    const emailTemplate: EmailTemplate = this.masterEmailTemplates.find(template => template.id == value);

    this.emailTemplateId = emailTemplate?.id ?? null;
    this.ksaEmailSubject = emailTemplate?.subject ?? '';
    this.ksaEmailBody = emailTemplate?.emailBody ?? '';
  }

  onBackButtonClick() {
    this.isComposeTemplate.emit(false);
  }

  getInviteEmailTemplates() {
    this.emailTemplateService.getInviteEmailTemplates().subscribe(data => {
      this.masterEmailTemplates = data;
    }, error => this.setErrorMessage(error));
  }

  getThanksEmailTemplates() {
    this.emailTemplateService.getThanksEmailTemplates().subscribe(data => {
      this.masterEmailTemplates = data;
    }, error => this.setErrorMessage(error));
  }

  getSchedulerEmailTemplates() {
    this.isEmailReminderSet = true;
    this.emailTemplateService.getSchedulerEmailTemplates().subscribe(data => {
      this.masterEmailTemplates = data;
    }, error => this.setErrorMessage(error));
  }

  onSaveKSAEmailTemplate(): void {
    const templateData: KsaEmailTemplateDTO = {
      id: null,
      emailBody: this.ksaEmailBody,
      subject: this.ksaEmailSubject,
      emailTemplateId: this.emailTemplateId ?? null
    }

    if (!this.isValidEmail(templateData)) {
      return;
    }
    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.ksaEmailService.addKsaEmailTemplate(this.ksaEmail.id, this.getEmailTemplateType(), templateData)
      .pipe(finalize(hideSpinner))
      .subscribe((response: KsaEmailDTO) => {
        this.ksaEmail = response;
        this.setEmailTemplateValuesBasedOnType();
        this.emailSaveSuccessAlert = true;
        this.showKsaDetailsWarning = false;
        this.isFormUpdated = false;
        this.isFormSaved = true;
        setTimeout(() => {                                  //Timeout for success/error message alert.
          this.emailSaveSuccessAlert = false;
        }, 3000);
      }, error => this.setErrorMessage(error))
  }

  onUpdateKsaEmailTemplate(): void {

    const templateData: KsaEmailTemplateDTO = {
      id: this.getKsaEmailTemplateId(),
      emailBody: this.ksaEmailBody,
      subject: this.ksaEmailSubject,
      emailTemplateId: this.emailTemplateId ?? null
    }

    if (!this.isValidEmail(templateData)) {
      return;
    }

    this.ksaEmailService.updateKsaEmailTemplate(this.ksaEmail.id, templateData)
      .pipe(finalize(hideSpinner))
      .subscribe((response: KsaEmailDTO) => {
        this.ksaEmail = response;
        this.setEmailTemplateValuesBasedOnType();
        this.emailSaveSuccessAlert = true;
        this.showKsaDetailsWarning = false;
        this.isFormUpdated = false;
        setTimeout(() => {
          this.emailSaveSuccessAlert = false;
        }, 3000)
      }, error => this.setErrorMessage(error))
  }

  private isValidEmail(emailTemplate: KsaEmailTemplateDTO): boolean {
    this.invalidEmailAlert = false;
    const undefinedPlaceholdersArr: string[] = this.emailValidationService.getUndefinedPlaceholders(emailTemplate, this.placeHolderNameList);
    if (undefinedPlaceholdersArr.length > 0) {
      this.invalidEmailAlert = true;
      this.undefinedPlaceHolders = undefinedPlaceholdersArr.join(", ");
      return false;
    }
    return true;
  }

  onSendReminderOpen() {
    this.showSetReminderModal = true;
    this.isEditReminder = false;
    this.getReminder(this.ksaEmail.id);
    this.ksaEmailService.getTimeZoneScheduler().subscribe(data => {
      this.timeZoneLists = data;
    })

  }

  getReminder(ksaEmailId): void {
    this.ksaEmailService.getReminderDetail(ksaEmailId).subscribe(data => {
      this.schedulerJob = data.listKSARemindersStatus;
    })
  }

  onSaveScheduler(emailReminderData): void {
    const latest_date = this.datePipe.transform(emailReminderData.tillDate, 'yyyy-MM-dd');
    const dateTime = latest_date.concat('', `T${emailReminderData.timeEntered}`);
    this.reminderList.ksaEmailId = this.ksaEmail.id;
    this.reminderList.status = this.statusEnum.Active;
    this.reminderList.dateTime = dateTime;
    this.reminderList.timeZone = emailReminderData.zoneEntered;
    this.ksaEmailService.createScheduleJob(this.reminderList).subscribe(data => {
      this.getReminder(this.ksaEmail.id);
      this.emailReminderData.tillDate = '';
      emailReminderData.timeEntered = '';
      emailReminderData.zoneEntered = '';
      this.errorMsg = '';
    },error => this.errorMsg=error.error.message)
  }

  onEditInitialize(emailReminderData): void {
    this.errorMsg = '';
    this.isEditReminder = true;
    this.emailReminderData.jobId = emailReminderData.jobId;
    this.emailReminderData.jobGroup = emailReminderData.jobGroup;
    this.emailReminderData.zoneEntered = emailReminderData.timeZone;
    this.emailReminderData.tillDate = this.datePipe.transform(emailReminderData.dateTime, 'MM-dd-yyyy');
    this.emailReminderData.timeEntered = emailReminderData.dateTime.split('T')[1];
  }

  onEditScheduler(emailReminderData): void {
    const latest_date = this.datePipe.transform(emailReminderData.tillDate, 'yyyy-MM-dd');
    const dateTime = latest_date.concat('', `T${emailReminderData.timeEntered}`);
    this.reminderList.dateTime = dateTime;
    this.reminderList.timeZone = emailReminderData.zoneEntered;
    this.reminderList.jobId = this.emailReminderData.jobId;
    this.reminderList.jobGroup = this.emailReminderData.jobGroup;
    delete this.reminderList.status;
    delete this.reminderList.ksaEmailId;
    this.ksaEmailService.updateScheduleJob(this.reminderList).subscribe(data => {
      this.errorMsg = '';
      this.getReminder(this.ksaEmail.id);
      this.emailReminderData.tillDate = '';
      emailReminderData.timeEntered = '';
      emailReminderData.zoneEntered = '';
    },error => this.errorMsg = error.error.message)
  }

  onCancelEditScheduler(editForm){
    editForm.reset();
    this.isEditReminder = false;
  }

  onShowDeletePopUp(jobId, jobGroup): void {
    this.isCurrentJobId = jobId;
    this.isCurrentJobGroupId = jobGroup;
    this.showDeleteSchedulerPopUp = true;
  }

  onDeleteJob(): void {
    this.ksaEmailService.deleteScheduleJob(this.isCurrentJobId, this.isCurrentJobGroupId).subscribe(data => {
      this.showDeleteSchedulerPopUp = false;
      this.getReminder(this.ksaEmail.id);
      this.isCurrentJobId = '';
      this.isCurrentJobGroupId = '';
    });
  }

  resetError() {
    this.errorMsg = '';
    this.emailReminderData.tillDate = '';
    this.emailReminderData.timeEntered = '';
    this.emailReminderData.zoneEntered = '';
  }

  closePopup(): void {
    this.showSetReminderModal = false
    this.errorMsg = ''
    this.emailReminderData.tillDate = '';
    this.emailReminderData.timeEntered = '';
    this.emailReminderData.zoneEntered = '';
  }

  onEmailFormChanged(): void {
    const currentEmail: EmailData = {
      emailTemplateId: this.emailTemplateId,
      ksaEmailSubject: this.ksaEmailSubject,
      ksaEmailBody: this.ksaEmailBody
    }

    this.isFormUpdated = this.isInEditMode()
      ? !isEqual(this.initialEmail, currentEmail)
      : this.ksaEmailSubject !== '' || this.ksaEmailBody !== '';
  }


  onSave(): void {
    this.isInEditMode() ? this.onUpdateKsaEmailTemplate() : this.onSaveKSAEmailTemplate();
  }

  onBack(): void {
    this.isFormUpdated && !this.isFormSaved ? this.showKsaDetailsWarning = true : this.onBackButtonClick()
  }

  isInEditMode(): boolean {
    return this.isInvite && !!this.ksaEmail?.inviteEmailTemplate?.id ||
      this.isThanks && !!this.ksaEmail?.thanksEmailTemplate?.id ||
      this.isScheduler && !!this.ksaEmail?.reminderEmailTemplate?.id
  }

  private setInitialEmailProperties(emailTemplate: KsaEmailTemplateDTO): void {
    this.initialEmail.emailTemplateId = emailTemplate?.emailTemplateId;
    this.initialEmail.ksaEmailSubject = emailTemplate?.subject;
    this.initialEmail.ksaEmailBody = emailTemplate?.emailBody;
  }

  private createKsaEmail(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaEmailService.addKsaEmail({masterKsaId: this.ksaId} as KsaEmailDTO)
      .pipe(finalize(hideSpinner))
      .subscribe((response: KsaEmailDTO) => this.ksaEmail = response, error => this.setErrorMessage(error));
  }

  private setEmailTemplateValuesBasedOnType() {
    if (!this.ksaEmail) {
      return;
    }

    if (this.isInvite && this.ksaEmail.inviteEmailTemplate) {
      this.setEmailTemplateValues(this.ksaEmail.inviteEmailTemplate);
    } else if (this.isScheduler && this.ksaEmail.reminderEmailTemplate) {
      this.setEmailTemplateValues(this.ksaEmail.reminderEmailTemplate);
    } else if (this.isThanks && this.ksaEmail.thanksEmailTemplate) {
      this.setEmailTemplateValues(this.ksaEmail.thanksEmailTemplate);
    }
  }

  private setEmailTemplateValues(template: KsaEmailTemplateDTO) {
    this.emailTemplateId = template.emailTemplateId;
    this.ksaEmailSubject = template.subject;
    this.ksaEmailBody = template.emailBody;
    this.setInitialEmailProperties(template);
  }

  private setAppropriateEmailTemplates() {
    if (this.isInvite) {
      this.getInviteEmailTemplates();
    } else if (this.isScheduler) {
      this.getSchedulerEmailTemplates();
    } else {
      this.getThanksEmailTemplates();
    }
  }

  private getEmailTemplateType(): EmailTemplateTypeEnum {
    if (this.isInvite) {
      return EmailTemplateTypeEnum.INVITE;
    } else if (this.isScheduler) {
      return EmailTemplateTypeEnum.REMINDER;
    } else {
      return EmailTemplateTypeEnum.THANKS;
    }
  }

  private getKsaEmailTemplateId() {
    if (this.isInvite) {
      return this.ksaEmail.inviteEmailTemplate.id;
    } else if (this.isScheduler) {
      return this.ksaEmail.reminderEmailTemplate.id;
    } else {
      return this.ksaEmail.thanksEmailTemplate.id;
    }
  }

  private setErrorMessage(error): void {
    this.errorMessage = error.error.message
    setTimeout(() => {
      this.errorMessage = '';
    }, 10000);
  }
}
