/*
 *  *********************************************************************
 *  * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

export interface SurveyTemplateConfigVersion {
  id: number;
  name: string;
  status: SurveyTemplateConfigStatus;
}

export enum SurveyTemplateConfigStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
