<clr-main-container>
  <div class="content-container">
    <main class="content-area">
<clr-datagrid class="original-certificate-datagrid " [clrDgLoading]="loading"
              (clrDgRefresh)="refreshCertificateDatagrid($event)">
  <ng-container *ngFor="let column of responseColumnSettings; let i = index" >
    <clr-dg-column [clrDgField]="column.responseColumnDisplay.questionName">
      <ng-template clrDgHideableColumn [(clrDgHidden)]="responseColumnSettings[i].isHideable">
        {{column.responseColumnDisplay.questionName}}
      </ng-template>
    </clr-dg-column>
  </ng-container>

  <clr-dg-column *ngFor="let column of certificateDetails?.headerDTOS" class="center-align-title">
      {{column?.name}}
  </clr-dg-column>
  <clr-dg-row *ngFor="let column of certificateDetails?.certificateData; let i = index">
    <ng-container *ngFor="let certificationColumn of certificateDetails?.certificateData[i].responseColumnWithParticipantDTOS; let i = index" >
      <clr-dg-column [clrDgField]="certificationColumn.answerText">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="responseColumnSettings[i].isHideable">
          {{certificationColumn.answerText}}
        </ng-template>
      </clr-dg-column>
    </ng-container>
    <clr-dg-cell *ngFor="let col of certificateDetails?.certificateData[i].responseColumnWithParticipantDTOS">{{col.answerText}}
    </clr-dg-cell>
    <clr-dg-cell *ngFor="let item of certificateDetails?.certificateData[i].analysisCertificationByParticipantDTOS">

      <div *ngIf="item.resultAchieved" class="achieved">

            <clr-tooltip>
              <clr-icon badge="success" clrTooltipTrigger shape="certificate"></clr-icon>
              <clr-tooltip-content>
                {{'analysisAndReports.responseManagement.achieved' | translate}}
              </clr-tooltip-content>
            </clr-tooltip>

      </div>

      <div *ngIf="item.resultDesired" class="desired">

            <clr-tooltip>
              <clr-icon badge="success" clrTooltipTrigger shape="bullseye"></clr-icon>
              <clr-tooltip-content>
                {{'analysisAndReports.responseManagement.desired' | translate}}
              </clr-tooltip-content>
            </clr-tooltip>

      </div>

    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgTotalItems]="total" [(clrDgPage)]="page">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Responses per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} responses
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
    </main>
  </div>
</clr-main-container>
