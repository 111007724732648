/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MasterSurveyQuestion,} from '../../../../../../models/master-survey-questions.model';
import {MasterSurveyService} from '../../../../../../services/master-survey.service';
import {MngSectionComponent} from '../mng-section.component';
import {SurveyControls} from "../../../../../../enums/survey-controls.enum";
import {QuestionType} from '../../../../../../enums/questionType.enum';
import {ShareSurveyDataService} from '../../../../../../services/share-survey-data.service';
import {UserService} from '../../../../../../services/user.service';
import {Functionality} from '../../../../../../enums/functionality.enum';

@Component({
  selector: 'app-select-role-profile',
  templateUrl: './select-role-profile.component.html',
  styleUrls: ['./select-role-profile.component.scss']
})
export class SelectRoleProfileComponent implements OnInit, AfterViewChecked, AfterViewInit {
  index: number;
  move = new EventEmitter();
  sectionReference!: MngSectionComponent;
  sectionQuestions!: MasterSurveyQuestion[];
  questionData: any;
  questionSequence!: number;
  tempSurveyQuestionId: number;
  isAddNoteTrue: boolean = false;
  isDisable: boolean;
  isAddPageBreak: boolean = false;
  showDeleteQuestionPopUp: boolean = false;
  showDisplayAnswerLabel: boolean;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  questionTypeEnum: typeof QuestionType = QuestionType;
  errorOnDisplayLogicItemDelete: boolean = false;
  dependentQuestionDisplayLogic: string = '';
  hasWritePermission: boolean;

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef, private shareSurveyData: ShareSurveyDataService,
              private masterSurveyService: MasterSurveyService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.manageSurveyTemplate);
    if (this.questionData) {
      this.onInitializationRoleProfileQuestionData();
    } else {
      this.setDefaultQuestionData();
      this.changeDetectOnQuestion();
    }
    if(this.sectionReference){
      this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1] = this.questionData.value;
    }
  }

  changeDetectOnQuestion() : void{
    this.questionData.valueChanges.subscribe((val: MasterSurveyQuestion) => {
      this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1] = this.questionData.getRawValue();
    });
  }

  ngAfterViewInit(): void {
    this.changeDetectOnQuestion();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  setDefaultQuestionData(): void {
    this.questionData = this.formBuilder.group({
      question: this.formBuilder.group({
        questionType: {
          id: this.questionTypeEnum.RoleProfile,
        },
        questionText: [this.surveyControlsEnum.selectRoleProfile],
        image: [],
        answerLabels: [],
        answerProperty: [],
      }),
      skill: null,
      questionNote: ['', {
        validators: [Validators.maxLength(100)],
      }],
      language: {
        id: 1
      },
      sectionQuestionSequenceNo: this.questionSequence,
      tempSurveyQuestionId: this.shareSurveyData.findMaxQuestionId()+1,
      isMandatory: [true],
      isPageBreakAdded: [false],
      isImported: [false],
      isVersioned: [false],
      questionDisplayCondition: this.createDisplayControl(),
      questionSkipCondition: this.createSkipControl(),
    })
  }

  onInitializationRoleProfileQuestionData(): void {
    if (this.questionData.isPageBreakAdded) {
      this.isAddPageBreak = true;
    }
    this.assignQuestionData();
  }

    assignQuestionData(): void {
    this.questionData = this.formBuilder.group({
      id: this.questionData.id,
      question: this.formBuilder.group({
        id: this.questionData.question.id,
        questionType: {
          id: this.questionData.question.questionType.id,
        },
        questionText: [this.questionData.question.questionText],
        image: [],
        answerLabels: [],
        answerProperty: {},
      }),
      language: {
        id: 1
      },
      skill: null,
      questionNote: [this.questionData.questionNote, {
        validators: [Validators.maxLength(100)],
      }],
      sectionQuestionSequenceNo: this.questionData.sectionQuestionSequenceNo,
      tempSurveyQuestionId: this.questionData.tempSurveyQuestionId,
      isMandatory: [true],
      isPageBreakAdded: [this.questionData.isPageBreakAdded],
      isImported: [this.questionData.isImported],
      isVersioned: [this.questionData.isVersioned],
      questionDisplayCondition: this.createDisplayControl(),
      questionSkipCondition: this.createSkipControl(),
    });
  }

  createDisplayControl(): FormGroup {
    return this.formBuilder.group({
      answerLabelSequenceNo: null,
      colAnswerLabelSequenceNo: null,
      displayValue: null,
      skipCondition: {
        id: null
      },
      tempSectionId:null,
      tempSurveyQuestionId:null
    });
  }

  createSkipControl(): FormGroup {
    return this.formBuilder.group({
      skipCondition: this.formBuilder.group({
        id: null
      }),
      skipType: null
    })
  }

  onAddPageBreak(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.isAddPageBreak = true;
    this.questionData.controls.isPageBreakAdded.setValue(true);
  }

  onRemovePageBreak(): void {
    this.isAddPageBreak = false;
    this.questionData.controls.isPageBreakAdded.setValue(false);
  }

  isUpArrayHidden(data: number): boolean {
    if (data == 1) {
      return true;
    }
    return false;
  }

  isDownArrayHidden(data: number): boolean {
    if (this.sectionReference?.sectionData.sectionQuestions.length == data) {
      return true;
    }
    return false;
  }

  isLastQuestion(): boolean {
    if (this.sectionReference?.sectionData.sectionQuestions.length == this.questionData.value.sectionQuestionSequenceNo
      && this.sectionReference.surveySections.length == this.sectionReference.sectionData.sectionSequenceNo) {
      return true;
    }
    return false;
  }

  isFirstQuestion(): boolean {
    if (this.sectionReference?.sectionData.sectionSequenceNo == 1 && this.questionData.value.sectionQuestionSequenceNo == 1) {
      return true;
    }
    return false;
  }

  onRemoveQuestion(): void {
    this.dependentQuestionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.shareSurveyData.validateQuestionDisplayLogic(this.questionData);
    if(validateQuestionDisplayLogicResponse.isValidQuestion){
      this.sectionReference.removeQuestion(this.questionData);
      this.showDeleteQuestionPopUp = false;
      this.masterSurveyService.setSurveyUpdateStatus(false);
    }else{
      this.showDeleteQuestionPopUp = true;
      this.errorOnDisplayLogicItemDelete = true;
      if(validateQuestionDisplayLogicResponse.dependentQuestion){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentQuestion.question.questionText;
      }
      if(validateQuestionDisplayLogicResponse.dependentSection){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      }
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
      }, 5000);
    }
  }

  onCheckUpdateStatus(): void{
    this.masterSurveyService.setSurveyUpdateStatus(false);
  }

  ngOnDestroy(): void {
    this.questionData.valueChanges.unsubscribe();
  }
}
