<clr-modal (clrModalOpenChange)="onCancel()" [clrModalClosable]="true"
           [clrModalOpen]="true"
           class="modal-lg">
  <h3 class="modal-title">
    {{ headerTitle | translate}}
    <clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true" clrAlertType="danger">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">
    <form #addForm="ngForm" (ngSubmit)="onSave(addForm)" autocomplete="off" clrForm>
      <clr-input-container class="clr-row">
        <label class="clr-col-4">{{'admin.roleProfilesAndGroups.roleProfiles.roleProfile' | translate}}*</label>
        <input [(ngModel)]="roleProfile.name" class="clr-col-8" clrInput name="name"
               pattern="^[^!@#*&(){}<>'\/;`%$^:?]*$"
               placeholder="{{'placeholder.roleProfileName' | translate}}" required
               type="text"/>
        <clr-control-error
          *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}</clr-control-error>
        <clr-control-error
          *clrIfError="'required'">{{'errorMsg.roleProfileNameRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-textarea-container class="clr-row">
        <label class="clr-col-4">{{'admin.roleProfilesAndGroups.roleProfiles.description' | translate}}</label>
        <textarea [(ngModel)]="roleProfile.description" class="clr-col-8" clrTextarea name="description"
                  placeholder="{{'placeholder.roleProfileDescription' | translate}}" rows="1"></textarea>
      </clr-textarea-container>
      <br/>
      <div class="button-container"><span
        class="clr-control-label">{{'admin.roleProfilesAndGroups.roleProfiles.selectSkill' | translate}}* </span></div>
      <clr-datagrid [(clrDgSelected)]="roleProfile.roleProfileSkillMaps" [clrDgPreserveSelection]="true"
                    class="popup-datagrid-role">
        <clr-dg-column [clrDgField]="'skillName'"> {{'admin.roleProfilesAndGroups.roleProfiles.skill' | translate}}*
        </clr-dg-column>
        <clr-dg-column>{{'admin.roleProfilesAndGroups.roleProfiles.threshold' | translate}} (%)*</clr-dg-column>
        <clr-dg-column>{{'admin.roleProfilesAndGroups.roleProfiles.comment' | translate}}</clr-dg-column>
        <clr-dg-row *clrDgItems="let rpsm of roleProfileSkillMap; let i = index" [clrDgItem]="rpsm">
          <clr-dg-cell>{{rpsm.skill.skillName}}</clr-dg-cell>
          <clr-dg-cell>
            <input
              [(ngModel)]="rpsm.threshold"
              [disabled]="!roleProfile.roleProfileSkillMaps.includes(rpsm)"
              clrInput
              max="100"
              min="1"
              name="threshold{{i}}"
              oninput="if(this.value > 100 || this.value < 0) {this.value = null;}"
              placeholder="00"
              required
              type="number"
            />
          </clr-dg-cell>
          <clr-dg-cell>
            <input [(ngModel)]="rpsm.comment" [disabled]="!roleProfile.roleProfileSkillMaps.includes(rpsm)"
                   clrInput name="comment{{i}}" placeholder="{{'placeholder.addComment' | translate}}" type="text"/>
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
      <div class="modal-footer">
        <button (click)="onCancel()"
                class="btn btn-sm btn-outline" type="button">{{'admin.cancelBtn' | translate}}</button>
        <button [disabled]="addForm.invalid || roleProfile.roleProfileSkillMaps.length === 0"
                class="btn btn-sm btn-primary"
                type="submit">{{submitButtonTitle | translate}}</button>
      </div>
    </form>
  </div>
</clr-modal>
