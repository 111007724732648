/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MasterSurveyService} from '../../../../services/master-survey.service';
import {EditableType} from "../../../../models/editable-type.constans";
import {Interval} from "../../../../models/interval-constants";
import {EditingInfoService} from "../../../../services/editing-info.service";
import {ZoneManager} from "../../../../services/zone-manager.service";
import {Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {EditingSurveyData} from "../../../../models/editing-survey-data.model";
import {ShareSurveyDataService} from "../../../../services/share-survey-data.service";

@Component({
  selector: 'app-tab-master-survey',
  templateUrl: './tab-master-survey.component.html',
  styleUrls: ['./tab-master-survey.component.scss']
})
export class TabMasterSurveyComponent implements OnInit, OnDestroy {
  @Output() showMasterSurveyTab: EventEmitter<boolean> = new EventEmitter();
  @Input() showPreview: boolean;
  @Input() isAdd: boolean;
  @Input() disableSurveyNameNewVersion: boolean;
  @Input() parentSurveyId: number | undefined;
  @Input() disableMasterSurveyTab: boolean | undefined;
  @Input() isClone: boolean;
  @Input() isNewVersion: boolean;

  checkEditingStatus: number;
  editingSessionExpired: boolean = false;

  constructor(private masterSurveyService: MasterSurveyService,
              private readonly editingInfoService: EditingInfoService,
              private readonly zoneManager: ZoneManager,
              private readonly router: Router,
              private readonly shareSurveyService: ShareSurveyDataService) {
  }

  ngOnInit(): void {
    this.checkForUserInactivity();
  }

  onShowMasterSurveyTab(data: boolean): void {
    this.showMasterSurveyTab.emit(data);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.unsetEditingUser();
    this.clearUserEditingChecks();
    this.masterSurveyService.setSurveyState(true);
    this.masterSurveyService.setSurveyUpdateStatus(true);
    this.masterSurveyService.masterSurvey = undefined;
    this.shareSurveyService.unsubscribeForSurveyData();
    this.shareSurveyService.unsubscribeForSurveySaveAlert();
  }


  private checkForUserInactivity(): void {
    this.checkEditingStatus = this.zoneManager.runIntervalInZone(() => {
      const editingData = this.masterSurveyService.editingData;
      if (!editingData?.surveyId && (!this.masterSurveyService?.masterSurvey?.id)) {
        return;
      }
      const surveyId: number = this.getSurveyId(editingData);
      this.prepopulateEditingData(editingData);
      this.editingInfoService.getEditingInfo(surveyId, EditableType.SURVEY_TEMPLATE).subscribe(editingInfo => {
        if (this.hasEditingSessionExpired(editingInfo.lastEditedOn) && surveyId) {
          this.editingInfoService.updateEditingInfo(surveyId, EditableType.SURVEY_TEMPLATE).subscribe(() => {
            this.masterSurveyService.editingData = new EditingSurveyData();
            this.editingSessionExpired = true;
          });
        }
      });

    }, Interval.EDIT_CHECK_REQUEST_INTERVAL);
  }

  prepopulateEditingData(editingData: EditingSurveyData): void {
    if (!editingData) {
      this.masterSurveyService.editingData = new EditingSurveyData(this.masterSurveyService.masterSurvey);
    }
  }

  getSurveyId(editingData: EditingSurveyData): number {
    if (editingData?.surveyId) {
      return editingData.surveyId;
    } else {
      return this.masterSurveyService.masterSurvey.id;
    }
  }

  onSessionExpireClick() {
    this.router.navigate([`${environment.API_URL}` + '/Home/Dashboard']);
    this.editingSessionExpired = false;
  }

  hasEditingSessionExpired(lastEditedOn: Date): boolean {
    if (!lastEditedOn) {
      return false;
    }
    return (new Date().getTime() - new Date(lastEditedOn).getTime()) > Interval.EDIT_PROJECT_INACTIVE_TIME_PERIOD;
  }

  clearUserEditingChecks(): void {
    if (this.checkEditingStatus) {
      clearInterval(this.checkEditingStatus);
    }
  }

  unsetEditingUser(): void {
    let editingData = this.masterSurveyService.editingData;
    if (editingData && editingData.userId) {
      this.editingInfoService.updateEditingInfo(editingData.surveyId, EditableType.SURVEY_TEMPLATE).subscribe(() => {
        this.masterSurveyService.editingData = new EditingSurveyData();
      });
    } else if (this.masterSurveyService?.masterSurvey) {
      editingData = new EditingSurveyData(this.masterSurveyService.masterSurvey);
      this.editingInfoService.updateEditingInfo(editingData.surveyId, EditableType.SURVEY_TEMPLATE).subscribe(() => {
        this.masterSurveyService.editingData = new EditingSurveyData();
      });
    }
  }
}
