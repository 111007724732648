/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {EmailManagementComponent} from "./email-management/email-management.component";
import {KsaAdministrationComponent} from "./ksa-administration/ksa-administration.component";
import {AuthGuard} from "../../guards/auth.guard";
import {KsaTeamsComponent} from './ksa-teams/ksa-teams.component';

const routes: Routes = [
  {
    path: 'Home', component: HomeComponent, children: [
      {
        path: 'KsaAdministration', component: KsaAdministrationComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'KSA Engagement / KSA Administration'
        }
      },
      {
        path: 'EmailManagement', component: EmailManagementComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'KSA Engagement / Email Management'
        }
      },
      {
        path: 'TeamManagement', component: KsaTeamsComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'KSA Engagement /  KSA Team Management'
        }
      }
    ],
    data: {breadcrumb: {skip: true}}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KsaEngagementRoutingModule {
}
