/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {Analysis} from '../enums/analaysis.enum';
import {AnalysisResponse, AnalysisResponseData} from '../models/analysis-response-data.model';
import {KsaDetails} from '../models/master-ksa.model';
import {RoleProfileSet} from '../models/ksa-role-profile-set.model';
import {ColorCodeService} from './color-code.service';
import {DataGridTypeEnum} from "../enums/data-grid-type.enum";
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";
import {KsaAdministrationService} from './ksa-administration.service';
import {hideSpinner, showSpinner} from '../common/spinner';
import {finalize} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AnalysisShareService {

  private answersUrl: string;
  private ksaDetails: BehaviorSubject<KsaDetails> = new BehaviorSubject(null);
  private responseData: BehaviorSubject<AnalysisResponseData[]> = new BehaviorSubject(null);
  private ksaChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  private globalThreshold: BehaviorSubject<number> = new BehaviorSubject(0);
  private closedKsaStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  analysisEnum: typeof Analysis = Analysis;
  private responseUrl: string;

  constructor(private httpClient: HttpClient,
              private readonly colorCodeService: ColorCodeService,
              private readonly ksaAdministrationService: KsaAdministrationService,
              private readonly translate: TranslateService) {
    this.answersUrl = `${environment.API_URL}`;
    this.responseUrl = `${environment.API_URL}${API_KSA_CORE_V1}/response`;
  }

  getKSADetailsAnalysis(): Observable<any> {
    return this.ksaDetails.asObservable();
  }

  getAnalysisResponseData(): Observable<any> {
    return this.responseData.asObservable();
  }

  getKsaChanged(): Observable<any> {
    return this.ksaChanged.asObservable();
  }

  getClosedKsaListForAnalysis(): Observable<any[]> {
    return this.httpClient.get<KsaDetails[]>(`${this.answersUrl}${API_KSA_CORE_V1}/ksaRoleProfile/ksa/active/closed`);
  }

  getClosedKsaListForClone(): Observable<any> {
    return this.httpClient.get<any>(`${this.answersUrl}${API_KSA_CORE_V1}/ksaRoleProfile/ksaDTO/active/closed`);
  }

  getDefaultKsaSetInfoForAnalysis(ksaId): Observable<any> {
    return this.httpClient.get<any>(`${this.answersUrl}${API_KSA_CORE_V1}/ksaRoleProfile/defaultset/${ksaId}`);
  }

  getKsaJobProfileSets(ksaId): Observable<RoleProfileSet> {
    return this.httpClient.get<RoleProfileSet>(`${this.answersUrl}${API_KSA_CORE_V1}/ksaRoleProfile/?masterKsa=${ksaId}`);
  }

  getKsaDetails(ksaId): Observable<KsaDetails> {
    return this.httpClient.get<KsaDetails>(`${this.answersUrl}${API_KSA_CORE_V1}/masterksa/ksa/${ksaId}`);
  }

  getGlobalThreshold(): Observable<number> {
    return this.globalThreshold.asObservable();
  }

  getClosedKsaStatus(): Observable<boolean> {
    return this.closedKsaStatus.asObservable();
  }

  setGlobalThreshold(threshold: number) {
    this.globalThreshold.next(threshold);
  }

  setClosedKsaStatu(status: boolean) {
    this.closedKsaStatus.next(status);
  }

  setKSADetailsAnalysis(ksaData: KsaDetails) {
    this.ksaDetails.next(ksaData);
  }

  setAnalysisResponseData(data: AnalysisResponseData[]) {
    this.responseData.next(data);
  }

  setKsaChanged(data) {
    this.ksaChanged.next(data);
  }

  clearKSADetailsAnalysis() {
    this.ksaDetails.next(null);
  }

  clearKSAresponseData() {
    this.responseData.next(null);
  }

  getParticipantAnswers(ksaId, particpantId): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.answersUrl}${API_KSA_CORE_V1}/questionanswer/${ksaId}/${particpantId}`);
  }

  getHeatMapAndRoleMapData(ksaId): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.answersUrl}${API_KSA_CORE_V1}/heatmapData/${ksaId}`);
  }

  getKsaRoleProfilesByKsaSetAndKsaId(ksaId): Observable<any> {
    return this.httpClient.get<any>(`${this.answersUrl}${API_KSA_CORE_V1}/ksaroleprofiles/${ksaId}`);
  }

  mapColorToHeatandRoleMap(gridData, colorData, isHeatMap): void {
    gridData.forEach((data, index) => {
      data.participantAnalysisList.forEach(heatMapData => {
        heatMapData[this.analysisEnum.colorCode] = "";
        heatMapData[this.analysisEnum.tableBackgroundColorCode] = "";
        colorData.forEach(color => {
          if (isHeatMap) {
            if (heatMapData.heatMapValue != null && color.from <= heatMapData.heatMapValue && color.to >= heatMapData.heatMapValue) {
              heatMapData[this.analysisEnum.colorCode] = color.backgroundColorHexCode;
            }
          } else {
            if (heatMapData.roleMapAnalysisList[0].deltaValue != null && color.from <= heatMapData.roleMapAnalysisList[0].deltaValue && color.to >= heatMapData.roleMapAnalysisList[0].deltaValue) {
              heatMapData[this.analysisEnum.tableBackgroundColorCode] = color.backgroundColorHexCode;
            }
          }
        });
      })
    })
  }

  mapColorToGivenTableType(gridData, colorData, tableType: DataGridTypeEnum): void {
    switch (tableType){
      case DataGridTypeEnum.HEAT_MAP:
        gridData.forEach((data, index) => {
          data.participantAnalysisList.forEach(heatMapData => {
            heatMapData[this.analysisEnum.colorCode] = "";
            heatMapData[this.analysisEnum.fontColorCode] = "";
            colorData.forEach(color => {
              if (heatMapData.heatMapValue != null && color.from <= heatMapData.heatMapValue && color.to >= heatMapData.heatMapValue) {
                heatMapData[this.analysisEnum.colorCode] = color.backgroundColorHexCode;
                heatMapData[this.analysisEnum.fontColorCode] = color.fontColorHexCode;
              }
            });
          })
        });
        break;
      default:
        gridData?.forEach((data, index) => {
          data.participantAnalysisList.forEach(heatMapData => {
            heatMapData[this.analysisEnum.tableBackgroundColorCode] = "";
            heatMapData[this.analysisEnum.tableFontColorCode] = "";
            colorData.forEach(color => {
              if (heatMapData.roleMapAnalysisList[0].deltaValue != null && color.from <= heatMapData.roleMapAnalysisList[0].deltaValue && color.to >= heatMapData.roleMapAnalysisList[0].deltaValue) {
                heatMapData[this.analysisEnum.tableBackgroundColorCode] = color.backgroundColorHexCode;
                heatMapData[this.analysisEnum.tableFontColorCode] = color.fontColorHexCode;
              }
            });
          })
        });
    }
  }

  isValidColorRange(value, highValue, colorData): boolean {
    let valid = true;
    colorData.forEach(colorDetails => {
      if (this.onTestRangeColor(value, colorDetails.from, colorDetails.to) ||
        this.onTestRangeColor(highValue, colorDetails.from, colorDetails.to)) {
        valid = false;
      }
    });
    return valid;
  }

  isValidColor(selectedColor, colorData): boolean {
    let valid = true;
    colorData.forEach(colorDetails => {
      if (selectedColor == colorDetails.backgroundColorHexCode) {
        valid = false;
      }
    });
    return valid;
  }

  onTestRangeColor(numberToCheck, bottom, top): boolean {
    return (numberToCheck >= bottom && numberToCheck <= top);
  }

  addColorToList(selectedColor, fontColor, value, highValue, ksaId, colorData, dataGridType: DataGridTypeEnum): void {
    const data = {
      id: 0,
      fontColorHexCode: fontColor,
      backgroundColorHexCode: selectedColor,
      from: value,
      to: highValue,
      masterKsa: {
        id: ksaId
      },
      dataGridType: dataGridType
    }
      colorData.push(data);
  }

  getRecommendedCourses(ksaId): Observable<any> {
    return this.httpClient.get<any[]>(`${this.answersUrl}${API_KSA_CORE_V1}/skillcourses/${ksaId}`);
  }

  saveParticipantAnalysisData(ksaId, data): Observable<any> {
    return this.httpClient.put<any[]>(`${this.answersUrl}${API_KSA_CORE_V1}/training/participant/${ksaId}`, data);
  }

  getMappedDeltaValues(ksaId, participantId, roleProfileId): Observable<any> {
    return this.httpClient.put<any[]>(`${this.answersUrl}${API_KSA_CORE_V1}/rolemap/roleprofile/${ksaId}/${participantId}/${roleProfileId}`, {});
  }

  getCertificationData(ksaId, participantId): Observable<any> {
    return this.httpClient.get<any[]>(`${this.answersUrl}${API_KSA_CORE_V1}/${ksaId}/${participantId}`);
  }

  saveRoleMapData(ksaId, data): Observable<any> {
    return this.httpClient.put<any[]>(`${this.answersUrl}${API_KSA_CORE_V1}/training/allparticipant/${ksaId}`, data);
  }

  resetRoleMap(ksaId, participantId): Observable<any> {
    return this.httpClient.put<any[]>(`${this.answersUrl}${API_KSA_CORE_V1}/rolemap/reset/${ksaId}/${participantId}`, {});
  }

  changeRoleMapDataWithKsaSet(ksaId, setId): Observable<any> {
    return this.httpClient.get<any[]>(`${this.answersUrl}${API_KSA_CORE_V1}/loadset/${ksaId}/${setId}`)
  }

  saveAllRoleProfiles(data): Observable<void> {
    return this.httpClient.put<void>(`${this.answersUrl}${API_KSA_CORE_V1}/ksaRoleProfile/roleprofiles`, data);
  }

  updateResponse(data): Observable<AnalysisResponse> {
    return this.httpClient.post<AnalysisResponse>(`${this.answersUrl}${API_KSA_CORE_V1}/heatmapData/filter`, data);
  }

  exportExcelData(generateExcelCallback, ksaId: number, dataGridType: DataGridTypeEnum, isAdvance?: boolean): void {
    const data = {
      ksaId,
      size: null,
      sortColumn: '',
      sortOrder: 'ASC',
      page: 0
    };
    showSpinner(this.translate.instant('spinnerLabels.common.downloadingFile'));
    forkJoin({
      analysisResponse: this.updateResponse(data),
      ksaName: this.ksaAdministrationService.getMasterKsaName(ksaId),
      colorData: this.colorCodeService.getAllColorCodesByKsaIdAndTypeOrDefault(ksaId, dataGridType)
    })
      .pipe(finalize(hideSpinner))
      .subscribe(({analysisResponse, ksaName, colorData}) => {
        this.mapColorToGivenTableType(analysisResponse.responseData, colorData, dataGridType);
        generateExcelCallback(analysisResponse.responseData, ksaName, isAdvance);
      });
  }

}
