import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {cloneDeep} from "lodash";
import {TranslateService} from "@ngx-translate/core";
import {MasterSurveyTranslationQuestion} from "../../../../../models/master-survey-translation.model";
import {TranslateMasterSurveyService} from "../translate-master-survey.service";
import {Subscription} from "rxjs";
import {QuestionTypeFile} from "../../../../../enums/questionTypeFile.enum";
import {LocalizationStatusEnum} from "../../../../../enums/localization-status.enum";

@Component({
  selector: 'app-translate-master-survey-question',
  templateUrl: './translate-master-survey-question.component.html',
  styleUrls: ['./translate-master-survey-question.component.scss']
})
export class TranslateMasterSurveyQuestionComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  private originalQuestion: MasterSurveyTranslationQuestion;

  @Input() masterSurveyLocalizationStatus: LocalizationStatusEnum;
  @Input() question: MasterSurveyTranslationQuestion;
  @Output() onSave = new EventEmitter<MasterSurveyTranslationQuestion>();

  hasUnsavedChanges = false;
  questionTypeEnum: typeof QuestionTypeFile = QuestionTypeFile;

  readonly emptyConfig = {toolbar: []}
  readonly config = {
    toolbar: [
      ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'color', 'fontsize']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['table', 'link', 'picture']]
    ],
    fontNames: ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Helvetica', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Clarity City', 'Metropolis', 'sans-serif']
  }


  constructor(public readonly translate: TranslateService,
              private readonly translateMasterSurveyService: TranslateMasterSurveyService) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.originalQuestion = cloneDeep(this.question);
    this.subscription = this.translateMasterSurveyService.questionsSubject.subscribe((questions: Array<MasterSurveyTranslationQuestion>) => {
      const updatedQuestion = questions?.find(q => this.originalQuestion?.id === q.id);
      if (updatedQuestion) {
        this.originalQuestion = cloneDeep(updatedQuestion);
        this.checkForUnsavedChanges();
      }
    })
  }

  save(): void {
    this.onSave.emit(this.question);
  }

  revertTranslation(): void {

  }

  checkForUnsavedChanges(): void {
    if (this.originalQuestion.translation !== this.question.translation) {
      this.hasUnsavedChanges = true;
      return;
    }

    if (!!this.originalQuestion.answers.length) {
      for (let x = 0; x < this.originalQuestion.answers.length; ++x) {
        if (this.originalQuestion.answers[x].translation !== this.question.answers[x].translation) {
          this.hasUnsavedChanges = true;
          return;
        }
      }
    }

    this.hasUnsavedChanges = false;
  }

  updateTranslation(updatedTranslation: string) {
    this.question.translation = updatedTranslation;
    this.checkForUnsavedChanges()
  }

  get localizationStatusEnum(): typeof LocalizationStatusEnum {
    return LocalizationStatusEnum;
  }
}
