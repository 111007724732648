/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {TealiumUtagService} from "../services/utag.service";

@Injectable()
export class AuthGuard implements CanActivate {
  public Dashboard: any
  readonly UNAUTHORIZED: string = " (Unauthorized)";

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private utagService: TealiumUtagService
  ) {
    this.Dashboard = this.route.snapshot.data['resolve'];
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService.isAuthenticated();
    if (user) {
      //track user's steps
      this.utagService.trackStep(state.url);
      // authorised so return true
      return true;
    }
    //track unauthorized attempts.
    this.utagService.trackStep(state.url + this.UNAUTHORIZED);

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }

}
