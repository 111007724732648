/********************************************************************** *
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
 **********************************************************************/

import {Options} from "@angular-slider/ngx-slider";
import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {ChartComponent} from "ng-apexcharts";
import {finalize} from 'rxjs/operators';
import {Analysis} from '../../../enums/analaysis.enum';
import {AnalysisResponseData, RoleProfilesAnalysis, SkillGroup} from '../../../models/analysis-response-data.model';
import {ContentEntity} from "../../../models/ksa-role-profile-set.model";
import {ChartOptions, ResponseColumnSettings} from '../../../models/respose-data.model';
import {AnalysisShareService} from '../../../services/analysis-share.service';
import {ExcelService} from '../../../services/excel.service';
import {HeatMapService} from '../../../services/heat-map.service';
import {ResponseService} from '../../../services/response.service';
import {ColorCode} from "../../../models/color-data.model";
import {ColorCodeService} from "../../../services/color-code.service";
import {Functionality} from "../../../enums/functionality.enum";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {ClrDatagridStateInterface} from "@clr/angular";
import {AnalysisService} from "../../../services/analysisService";
import {TranslateService} from "@ngx-translate/core";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {DataGridTypeEnum} from "../../../enums/data-grid-type.enum";
import {PageSizeService} from "../../../services/page-size.service";

@Component({
  selector: 'app-role-map-report',
  templateUrl: './role-map-report.component.html',
  styleUrls: ['./role-map-report.component.scss']
})
export class RoleMapReportComponent implements OnDestroy, OnChanges {

  page: number;
  loading: boolean = false;
  total: number;
  errorMsg: string = '';
  skillGroups: (SkillGroup)[] = [];
  disabled: boolean = false;
  isColorModelOpen: boolean = false;
  colorData: (ColorCode)[] = [];
  selectedColor: string = '#b41e1e';
  selectedFontColor: string = '#666666';
  value: number = 40;
  highValue: number = 60;
  options: Options = {
    floor: -100,
    ceil: 100
  };
  dataGridType: DataGridTypeEnum = DataGridTypeEnum.ROLE_MAP;
  @Input() responseData: AnalysisResponseData[];
  messageSuccessTimer: boolean = true;
  analysisEnum: typeof Analysis = Analysis;
  showAnswers: boolean = false;
  isParticipantDetails: boolean = false;
  participantId: number;
  showDatagrid: boolean = false;
  @Input() ksaId: number;
  ksaSet: RoleProfilesAnalysis[] = [];
  skillGroupSelected = [];
  showLoadKsaSetPopup: boolean = false;
  ksaSets: ContentEntity[] = [];
  roleProfileSelected: ContentEntity;
  saveSuccess: boolean = false;
  resetSuccess: boolean = false;
  colorRangeErrorAlert: boolean = false;
  colorDuplicateAlert: boolean = false;
  @ViewChild("chart") chart: ChartComponent;
  chartOptions: Partial<ChartOptions>;
  chartOptionsList: (ChartOptions)[] = [];
  participantData: AnalysisResponseData;
  participantName: string = '';
  functionality : typeof Functionality = Functionality;
  showSetChangeMessage: boolean = false;
  setSelectedFromDropDown: ContentEntity;
  @Input() showNewWindowButton: boolean = true;
  isSavedResponse: boolean = false;
  responseColumnSettings: (ResponseColumnSettings)[];
  successMsgTimeout: number = 5000;
  pinnedColumn: string;
  showLoader: boolean;
  state: ClrDatagridStateInterface;

  constructor(public responseService: ResponseService,
              private heatMapService: HeatMapService, private colorCodeService: ColorCodeService,
              private analysisShareService: AnalysisShareService,
              private excelService: ExcelService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              public analysisService: AnalysisService,
              private readonly translate: TranslateService,
              public readonly pageSizeService: PageSizeService) {
  }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    this.showDatagrid = false;
    this.skillGroups = [];
    if (this.responseData) {
      this.operations();
    }
  }

  async operations() {
    await this.getAllResponseColumnData();
    await this.getDefaultKsaSet();
    await this.getKsaRoleProfilesByKsaSetAndKsaId(this.ksaId);
    await this.getAllSkillGroups();
    await this.loadKsaSetList();
    await this.getAllColorCodesById();
    await this.fillRoleAlignmentChartData();
  }

  getAllResponseColumnData(): void {
    this.responseService.getAllResponseColumnData(this.ksaId).subscribe(data => {
      if (data) {
        this.responseColumnSettings = data;
      }
    });
  }

  getKsaRoleProfilesByKsaSetAndKsaId(ksaId: number): void {
    this.showDatagrid = false;
    this.analysisShareService.getKsaRoleProfilesByKsaSetAndKsaId(ksaId).subscribe(data => {
      if (data) {
        this.ksaSet = data;
      }
    });
  }

  loadKsaSetList(): void {
    this.analysisShareService.getKsaJobProfileSets(this.ksaId).subscribe(data => {
      if (data) {
        this.ksaSets = data.content;
      }
    });
  }

  getDefaultKsaSet(): void {
    this.analysisShareService.getDefaultKsaSetInfoForAnalysis(this.ksaId).subscribe(data => {
      if (data) {
        this.roleProfileSelected = data;
      }
    });
  }

  onLoadRoleProfileSet() {
    this.ksaSet = [];
    this.responseData = [];
    this.showDatagrid = false;
    this.showDatagrid = false;
    this.analysisShareService.changeRoleMapDataWithKsaSet(this.ksaId, this.roleProfileSelected.id).subscribe(data => {
      this.roleMapRefresh(this.state);
      this.mapColorToTable();
      this.analysisShareService.setAnalysisResponseData(this.responseData);
      this.showDatagrid = true;
      this.analysisShareService.getKsaRoleProfilesByKsaSetAndKsaId(this.ksaId).subscribe(data => {
        this.ksaSet = data;
      })
    });
    this.showLoadKsaSetPopup = false;
  }

  mapColorToTable(): void {
    if (this.colorData && this.colorData.length > 0) {
      this.analysisShareService.mapColorToGivenTableType(this.responseData, this.colorData, this.dataGridType);
    } else {
      this.applyDefaultTableColors();
    }
    this.showDatagrid = true;
  }

  applyDefaultTableColors(): void {
    this.colorCodeService.setLoadDefaultColorByColorDto(this.ksaId, this.dataGridType).subscribe(colorData => {
      if (colorData) {
        this.analysisShareService.mapColorToGivenTableType(this.responseData, colorData, this.dataGridType);
        this.showDatagrid = true;
      }
    });
  }

  addColor(color: ColorCode):void{
    this.colorData.push(color);
    this.mapColorToTable();
  }

  between(x, min, max) {
    return x >= min && x <= max;
  }

  getAllColorCodesById(): void {
    this.colorCodeService.getAllColorCodesByKsaIdAndType(this.ksaId, this.dataGridType).subscribe(data => {
      if (data) {
        this.colorData = data;
        this.mapColorToTable();
      }
    });
  }

  onShowColorModel(): void {
    this.isColorModelOpen = true;
    this.getAllColorCodesById();
  }

  onHideColorModal(): void{
    this.mapColorToTable();
    this.isColorModelOpen = false;
  }

  onShowAnswers(response): void {
    this.participantId = response.participantId;
    this.participantName = response?.participantAnalysisList[0]?.participant?.firstName + " " +
      response?.participantAnalysisList[0]?.participant?.lastName;
    response.visited = true;
    this.showAnswers = true;
    this.router.navigate([]).then((result) => {
      window.open(`${environment.API_URL}` +'/#/ParticipantAnswers/'+ this.ksaId+'/'+this.participantId, '_blank');
    });
  }

  onShowParticipantDetails(data): void {
    this.isParticipantDetails = true;
    data.visited = true;
    this.participantData = data;
  }

  onShowParticipant(data: boolean): void {
    this.isParticipantDetails = data;
  }

  getAllSkillGroups(): void {
    this.skillGroupSelected = [];
    this.heatMapService.getAllSkillGroups(this.ksaId).subscribe(data => {
      this.skillGroups = data;
      this.skillGroups.forEach(data => {
        this.skillGroupSelected?.push(data.skillGroupName);
      })
    })
  }

  changeDeltaValues(responseId, roleProfileId): void {
    this.analysisShareService.getMappedDeltaValues(this.ksaId, responseId, roleProfileId).pipe(finalize(() => {
      this.mapColorToTable();
      this.fillRoleAlignmentChartData();
    })).subscribe(data => {
      const responseDataRow = this.responseData.find(dataRow => dataRow.participantId === data.participantId);
      responseDataRow.participantAnalysisList = [];
      responseDataRow.participantAnalysisList = data?.participantAnalysisList;
      responseDataRow.roleAlignmentAverage = data?.roleAlignmentAverage;
      responseDataRow.visited = true;
    })
  }

  saveRoleMap(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.analysisShareService.saveRoleMapData(this.ksaId, this.responseData)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.analysisShareService.setAnalysisResponseData(data);
        this.saveSuccess = true;
        this.mapColorToTable();
        setTimeout(() => {
          this.saveSuccess = false;
        }, 3000);
      });
  }

  exportExcelRoleMap(): void {
    this.analysisShareService.exportExcelData(this.excelService.generateRoleMapExcelData.bind(this.excelService), this.ksaId, this.dataGridType);
  }

  onResetRoleMap(participantId, index): void {
    this.analysisShareService.resetRoleMap(this.ksaId, participantId).subscribe(data => {
      this.roleMapRefresh(this.state);
      this.mapColorToTable();
      this.resetSuccess = true;
    })
    setTimeout(() => {
      this.resetSuccess = false;
    }, 3000);
  }

  onChangeRoleMapDataWithKsaSet(event): void {
    this.showSetChangeMessage = true;

    this.setSelectedFromDropDown = this.ksaSets.find(set => {
      return set.id == event.target.value;
    });
  }

  fillRoleAlignmentChartData(): void {
    this.chartOptionsList = [];
    this.responseData?.forEach(data => {
      const x = data.roleAlignmentAverage < 0 ? 100 + data.roleAlignmentAverage : 100;
      this.chartOptions = {
        series: [Number((x).toFixed())],
        chart: {
          type: "radialBar",
          height: 100,
          offsetY: -20
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "20%"
            },
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#ff0000",
              strokeWidth: "97%",
              margin: 5,
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                opacity: 0.31,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: false
              }
            }
          }
        },
        labels: [""]
      };
      this.chartOptionsList.push(this.chartOptions);
      }
    );
  }

  ngOnDestroy(): void {
    this.analysisShareService.setAnalysisResponseData(this.responseData);
  }

  onOpenNewWindow(): void {
    window.open(`${environment.API_URL}` + '/#' + '/AnalysisReport/' + 'RoleMap' + '/' + this.ksaId, '_blank', 'location=yes,height=1000,width=1000,scrollbars=yes,status=yes');
  }

  onRefresh(): void {
    this.roleMapRefresh(this.state);
  }

  onSaveColumnSettings() {
    this.responseService.updateResponseColumnData(this.responseColumnSettings).subscribe(data => {
      if (data) {
        this.responseColumnSettings = data;
        this.isSavedResponse = true;
      }
      setTimeout(() => {
        this.isSavedResponse = false;
      }, this.successMsgTimeout);
    })
  }

  changeSet(): void {
    this.roleProfileSelected = this.setSelectedFromDropDown;
    this.onLoadRoleProfileSet();
    this.showSetChangeMessage = false;
  }

  onCancelSetChange(): void{
    (<HTMLInputElement>document.getElementById("roleProfileSetDropdown")).value = (this.roleProfileSelected.id).toString();
    this.showSetChangeMessage = false;
  }

  setPinnedColumn(questionName: string) {
    this.pinnedColumn = '';
    this.pinnedColumn = questionName;
  }

  roleMapRefresh(state: ClrDatagridStateInterface) {
    this.pageSizeService.setPageSize(state?.page?.size);
    this.state = state;
    this.loading = true;
    const colFilters: ({ columnName: string; columnValue: string; })[] = [];
    let columnName = '';
    let data;
    if (state?.filters) {
      state.filters.forEach((filter,i)=> {
        const {property, value} = <{ property: string; value: string }>filter;
        colFilters.push({columnValue : value, columnName : property});
        columnName = property;
      })
      data={
        ksaId: this.ksaId,
        size: state?.page.size,
        sortColumn: columnName,
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false)  ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0,
        analysisFilterDTOS: colFilters
      }
    } else {
      data = {
        ksaId: this.ksaId,
        size: state?.page.size,
        sortColumn: state?.sort ? state.sort.by : "",
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false) ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0
      }
    }
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.analysisShareService.updateResponse(data)
        .pipe(finalize(() => hideSpinner()))
        .subscribe((results) => {
          this.responseData = results.responseData;
          this.total = results.total;
          this.getAllColorCodesById();
          this.loading = false;
        });
    });
  }
}
