  <app-header></app-header>
  <clr-main-container>
    <div class="content-container">
      <app-navigation></app-navigation>
      <main class="content-area">
        <!-- You can put an optional element here -->
        <xng-breadcrumb seperator=">"></xng-breadcrumb>
        <router-outlet>
        </router-outlet> <!-- This holds child routes of Home primary navigation item -->
      </main>
    </div>
  </clr-main-container>
  <clr-notification-dialog></clr-notification-dialog>
