/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {SharedAuthService} from '../services/shared-auth.service';
import {UserCacheService} from "../services/user-cache.service";

declare let $: any;

@Directive({
  selector: '[hasAccess]'
})
export class HasAccessDirective implements OnInit {
  private currentUser;
  private accessScreen: any;
  private isDisabled = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: SharedAuthService,
    private userCacheService: UserCacheService
  ) {

  }

  ngOnInit() {
    this.currentUser = this.userCacheService.getLoginDataFromLocalStorage();
    this.updateView();
  }

  @Input()
  set hasAccess(val) {
    this.accessScreen = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkAccess()) {
      if (this.isDisabled) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isDisabled = false;
      }
    } else {
      this.isDisabled = true;
      this.viewContainer.clear();
    }
  }

  private checkAccess(): boolean {
    //converting hashmap to array for find method use
    const permissionArr = this.currentUser?.permissionMap ? Object.values(this.currentUser?.permissionMap) : [];

    return permissionArr.some((x: any) => x.screen.toUpperCase() === this.accessScreen.toUpperCase() && x.write);
  }

}
