import {Component, EventEmitter, HostListener, OnDestroy, OnInit, Output} from '@angular/core';
import {KsaShareService} from '../../../../services/ksa-share.service';
import {EditableType} from "../../../../models/editable-type.constans";
import {Interval} from "../../../../models/interval-constants";
import {ZoneManager} from "../../../../services/zone-manager.service";
import {EditingInfoService} from "../../../../services/editing-info.service";
import {KsaAdministrationService} from "../../../../services/ksa-administration.service";
import {EditingKsaData} from "../../../../models/editing-ksa-data.model";
import {environment} from "../../../../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'app-tab-ksa',
  templateUrl: './tab-ksa.component.html',
  styleUrls: ['./tab-ksa.component.scss']
})
export class TabKsaComponent implements OnInit, OnDestroy {
  @Output() showKsaTable: EventEmitter<boolean> = new EventEmitter();
  showSettingsTab: boolean = false;
  showKsaTab: boolean = false;
  showTablePopUp: boolean = false;
  showMetaDataTab: boolean = false;
  showRoleProfileTab: boolean = false;
  showPreviewTab: boolean = false;
  disableSettingsTab: boolean = false;
  enableRoleProfileTab: boolean = false;
  checkEditingStatus: number;
  editingSessionExpired: boolean = false;

  constructor(private ksaShareService: KsaShareService,
              private readonly zoneManager: ZoneManager, private readonly editingInfoService: EditingInfoService,
              public ksaAdministrationService: KsaAdministrationService,
              private readonly router: Router) {
  }


  ngOnInit(): void {
    this.checkForUserInactivity();
  }

  onShowKSATab(data): void {
    this.showKsaTab = data;
  }

  onShowRoleProfileTab(data): void {
    this.showRoleProfileTab = data;
  }

  onShowMetadataTab(data): void {
    this.showMetaDataTab = data;
  }

  onShowSettingsTab(data): void {
    this.showSettingsTab = data;
  }

  onShowTable(): void {
    this.showKsaTable.emit(true)
  }

  onShowPreviewTab(data): void {
    this.showPreviewTab = data;
  }

  onEnableRoleProfileTab(data): void {
    this.enableRoleProfileTab = data;
  }

  onDisableOtherTabs(data): void {
    this.disableSettingsTab = data;
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.unsetEditingUser();
    this.clearUserEditingChecks();
    this.ksaShareService.clearKSADetails();
    this.ksaAdministrationService.ksa = undefined;
  }


  private checkForUserInactivity(): void {
    this.checkEditingStatus = this.zoneManager.runIntervalInZone(() => {
      const editingData = this.ksaAdministrationService.editingData;
      if ((!editingData?.ksaId) && (!this.ksaAdministrationService?.ksa?.id)) {
        return;
      }
      const ksaId: number = this.getKsaId(editingData);
      this.prepopulateEditingData(editingData);
      this.editingInfoService.getEditingInfo(ksaId, EditableType.KSA).subscribe(editingInfo => {
        if (this.hasEditingSessionExpired(editingInfo.lastEditedOn) && ksaId) {
          this.editingInfoService.updateEditingInfo(ksaId, EditableType.KSA).subscribe(() => {
            this.ksaAdministrationService.editingData = new EditingKsaData();
            this.editingSessionExpired = true;
          });
        }
      });

    }, Interval.EDIT_CHECK_REQUEST_INTERVAL);
  }

  prepopulateEditingData(editingData: EditingKsaData): void {
    if (!editingData) {
      this.ksaAdministrationService.editingData = new EditingKsaData(this.ksaAdministrationService.ksa);
    }
  }

  getKsaId(editingData: EditingKsaData): number {
    if (editingData?.ksaId) {
      return editingData.ksaId;
    } else {
      return this.ksaAdministrationService.ksa.id;
    }
  }

  hasEditingSessionExpired(lastEditedOn: Date): boolean {
    if (!lastEditedOn) {
      return false;
    }
    return (new Date().getTime() - new Date(lastEditedOn).getTime()) > Interval.EDIT_PROJECT_INACTIVE_TIME_PERIOD;
  }

  clearUserEditingChecks(): void {
    if (this.checkEditingStatus) {
      clearInterval(this.checkEditingStatus);
    }
  }

  onSessionExpireClick() {
    this.router.navigate([`${environment.API_URL}` + '/Home/Dashboard']);
    this.editingSessionExpired = false;
  }

  unsetEditingUser(): void {
    let editingData = this.ksaAdministrationService.editingData;
    if (editingData && editingData.userId) {
      this.editingInfoService.updateEditingInfo(editingData.ksaId, EditableType.KSA).subscribe(() => {
        this.ksaAdministrationService.editingData = new EditingKsaData();
      });
    } else if (this.ksaAdministrationService?.ksa) {
      editingData = new EditingKsaData(this.ksaAdministrationService.ksa);
      this.editingInfoService.updateEditingInfo(editingData.ksaId, EditableType.KSA).subscribe(() => {
        this.ksaAdministrationService.editingData = new EditingKsaData();
      });
    }
  }
}
