/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedAuthService {
  private currentUserSubject = new BehaviorSubject<any>({});

  constructor() { }

  setUser(user) {
    this.currentUserSubject.next(user);
  }

  getCurrentUser(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }
}
