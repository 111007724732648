/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {Workbook} from 'exceljs';
import * as fs from 'file-saver';
import {cloneDeep} from 'lodash';
import {AnalysisResponseData, ResponseColumnWithParticipantsEntity} from "../models/analysis-response-data.model";
import {ColorCodeColors} from "../models/color-data.model";
import {ResponseData} from "../models/respose-data.model";
import {CertificateData, CertificateDetails, CertificationByParticipants} from "../models/certification.model";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  private excelExtension: string = ".xlsx";
  private excelFormulaSymbols: string = "=-+@";

  constructor(private translate: TranslateService) {
  }

  generateHeatMapExcelData(data, ksaName: string) {

    const heatMapJsonString = JSON.stringify(data);
    const heatMapJsonObject = JSON.parse(heatMapJsonString);
    const heatMapExcelRowCount = heatMapJsonObject.length;
    const heatMapExcelNonTechColumns: Array<ResponseColumnWithParticipantsEntity> = this.extractNonTechColumns(heatMapJsonObject);

    const heatMapExcelColumns = [];
    const heatMapExcelUniqueColumns = [];

    heatMapExcelNonTechColumns.forEach(column => heatMapExcelColumns.push(column.responseColumnDisplay.questionName));

    for (let rowIndex = 0; rowIndex < heatMapExcelRowCount; rowIndex++) {
      for (let index = 0; index < heatMapJsonObject[rowIndex].participantAnalysisList.length; index++) {
        const skillName: string = this.quotesAllFormulaSymbols(heatMapJsonObject[rowIndex].participantAnalysisList [index].skill.skillName);
        if (!heatMapExcelColumns.includes(skillName)) {
          heatMapExcelColumns.push(skillName);
          heatMapExcelUniqueColumns.push(skillName);
        }
      }
    }

    const heatMapExcelRows = [];
    const heatMapExcelColorCodeRows = [];

    for (let rowIndex = 0; rowIndex < heatMapExcelRowCount; rowIndex++) {
      const heatMapExcelRow = [];
      const heatMapExcelColorCodeRow: ColorCodeColors[] = [];

      heatMapExcelNonTechColumns.forEach(column => {
        const indexOfAnswer: number = heatMapJsonObject[rowIndex].responseColumnWithParticipants
          .findIndex(questionAnswerForParticipant => questionAnswerForParticipant.responseColumnDisplay.questionId === column.responseColumnDisplay.questionId);
        if (indexOfAnswer >= 0) {
          heatMapExcelRow.push(this.quotesAllFormulaSymbols(heatMapJsonObject[rowIndex].responseColumnWithParticipants[indexOfAnswer].answerText));
        } else {
          heatMapExcelRow.push("N/A");
        }
        heatMapExcelColorCodeRow.push({backGroundColor: "", fontColor: ""});
      });

      for (let techIndex = 0; techIndex < heatMapExcelUniqueColumns.length; techIndex++) {
        if (heatMapJsonObject[rowIndex].participantAnalysisList[techIndex] === undefined || heatMapJsonObject[rowIndex].participantAnalysisList[techIndex] == null) {
          heatMapExcelRow.push("NA");
          heatMapExcelColorCodeRow.push({backGroundColor: "", fontColor: ""});
        } else {
          if (heatMapExcelUniqueColumns[techIndex] == this.quotesAllFormulaSymbols(heatMapJsonObject[rowIndex].participantAnalysisList[techIndex].skill.skillName)) {
            heatMapExcelRow.push(heatMapJsonObject[rowIndex].participantAnalysisList[techIndex].heatMapValue == null ? "NA" : heatMapJsonObject[rowIndex].participantAnalysisList[techIndex].heatMapValue + "%");
            const colorCode: string = heatMapJsonObject[rowIndex].participantAnalysisList[techIndex].colorCode;
            const fontColorCode: string = heatMapJsonObject[rowIndex].participantAnalysisList[techIndex].fontColorCode;
            heatMapExcelColorCodeRow.push({backGroundColor: colorCode, fontColor: fontColorCode});
          } else {
            heatMapExcelRow.push("NA");
            heatMapExcelColorCodeRow.push({backGroundColor: "", fontColor: ""});
          }
        }
      }
      heatMapExcelRows.push(heatMapExcelRow);
      heatMapExcelColorCodeRows.push(heatMapExcelColorCodeRow);
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('heatMap');
    worksheet.properties.defaultColWidth = 20;

    worksheet.addRow(heatMapExcelColumns);

    heatMapExcelRows.forEach((sheetRowData, index) => {
      const row = worksheet.addRow(sheetRowData);
      const colorLength = heatMapExcelColorCodeRows[index].length;
      for (let colorIndex = 0; colorIndex < colorLength; colorIndex++) {
        if (heatMapExcelColorCodeRows[index][colorIndex].backGroundColor) {
          const qty = row.getCell(colorIndex + 1);
          const colors: ColorCodeColors = heatMapExcelColorCodeRows[index][colorIndex];
          qty.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: this.addColorCodePrefix(colors.backGroundColor)},
          };
          qty.font = {color: {argb: this.addColorCodePrefix(colors.fontColor)}};
        }
      }
    });

    this.downloadExcel(workbook, heatMapExcelRows, 'HeatMap.' + ksaName);

  }

  generateResponseManagementExcelData(data: ResponseData[], certificationData: CertificateDetails, ksaName: string) {
    const responseManagementData = cloneDeep(data);
    const responseManagementExcelRowCount = responseManagementData.length;
    const certificationDataLength = certificationData?.headerDTOS?.length || 0;
    if (responseManagementExcelRowCount == 0) {
      const workbook = new Workbook();
      workbook.addWorksheet('Response Management');

      this.downloadExcel(workbook, [], 'Response-Management.' + ksaName);
      return;
    }

    const responseManagementExcelColumnIndexes = {};

    // Extract the sequence number for each column
    for (const row of responseManagementData) {
      for (const column of row.responseColumnWithParticipants) {
        const question = column.responseColumnDisplay.questionName;
        const sequenceNumber: number = +column.responseColumnDisplay.sequenceNumber;
        responseManagementExcelColumnIndexes[question] = sequenceNumber;
      }
    }

    // Order the columns by their sequence numbers (aka in the same order as on the FE)
    const responseManagementExcelColumnPairsOrdered = Object.entries(responseManagementExcelColumnIndexes)
      .sort((a: [string, number], b: [string, number]) => a[1] - b[1]) // order by sequence number
      .map((questionSequenceNumberPair: [string, number], i) => [questionSequenceNumberPair[0], i]); // re-index the columns so they are 0-based

    // Extract the column names in the same order as on the FE
    const responseManagementExcelColumns = responseManagementExcelColumnPairsOrdered
      .map((questionSequenceNumberPair: [string, number]) => this.quotesAllFormulaSymbols(questionSequenceNumberPair[0]));
    certificationData?.headerDTOS?.forEach(e => responseManagementExcelColumns.push(e.name));

    // Get the indexes of the questions | Note: the sequence number might not start from 0. That's why we need to convert the sequence number in to 0-based index
    responseManagementExcelColumnPairsOrdered.forEach((questionSequenceNumberPair: [string, number]) => {
      responseManagementExcelColumnIndexes[questionSequenceNumberPair[0]] = questionSequenceNumberPair[1];
    });

    const responseManagementExcelRows = [];

    for (const row of responseManagementData) {
      // Create a new empty row with all columns set to empty strings
      const responseManagementExcelRow = Array.apply(null, Array(responseManagementExcelColumns.length)).map(() => '');
      for (const column of row.responseColumnWithParticipants) {
        const question = column.responseColumnDisplay.questionName;
        // Fill the answer on the index in the row where it is supposed to be (on the index of the question)
        responseManagementExcelRow[responseManagementExcelColumnIndexes[question]] = this.quotesAllFormulaSymbols(column.answerText);
      }

      const certificateData: CertificateData = certificationData?.certificateData?.find(e => row.participantId === e.participant.id);
      if (certificateData) {
        let i = responseManagementExcelColumns.length - certificationDataLength;
        certificateData.certificationByParticipants?.forEach(e => {
          responseManagementExcelRow[i] = this.extractCertificationAnswer(e)
          i++;
        })
      }
      responseManagementExcelRows.push(responseManagementExcelRow);
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Response Management');
    worksheet.properties.defaultColWidth = 20;

    worksheet.addRow(responseManagementExcelColumns);

    responseManagementExcelRows.forEach((sheetRowData, index) => {
      worksheet.addRow(sheetRowData);
    });

    this.downloadExcel(workbook, responseManagementExcelRows, 'Response-Management.' + ksaName);

  }

  generateRoleMapExcelData(data, ksaName: string) {

    const roleMapJsonString = JSON.stringify(data);
    const roleMapJsonObject = JSON.parse(roleMapJsonString);
    const roleMapExcelRowCount = roleMapJsonObject.length;
    const roleMapExcelNonTechColumns = this.extractNonTechColumns(roleMapJsonObject);

    const roleMapExcelColumns = [];
    const heatMapExcelUniqueColumns = [];

    roleMapExcelNonTechColumns.forEach(column => roleMapExcelColumns.push(column.responseColumnDisplay.questionName));

    roleMapExcelColumns.push('Role Profile');
    roleMapExcelColumns.push('Role Alignment');

    for (let rowIndex = 0; rowIndex < roleMapExcelRowCount; rowIndex++) {
      for (let index = 0; index < roleMapJsonObject[rowIndex].participantAnalysisList.length; index++) {
        const skillName: string = this.quotesAllFormulaSymbols(roleMapJsonObject[rowIndex].participantAnalysisList[index].skill.skillName);
        if (!roleMapExcelColumns.includes(skillName)) {
          roleMapExcelColumns.push(skillName);
          heatMapExcelUniqueColumns.push(skillName);
        }
      }
    }

    const roleMapExcelRows = [];
    const roleMapExcelColorCodeRows = [];

    for (let rowIndex = 0; rowIndex < roleMapExcelRowCount; rowIndex++) {
      const roleMapExcelRow = [];
      const roleMapExcelColorCodeRow: ColorCodeColors[] = [];

      roleMapExcelNonTechColumns.forEach(column => {
        const indexOfAnswer: number = roleMapJsonObject[rowIndex].responseColumnWithParticipants.findIndex(e => e.responseColumnDisplay.questionId === column.responseColumnDisplay.questionId);
        if (indexOfAnswer >= 0) {
          roleMapExcelRow.push(this.quotesAllFormulaSymbols(roleMapJsonObject[rowIndex].responseColumnWithParticipants[indexOfAnswer].answerText));
        } else {
          roleMapExcelRow.push("N/A");
        }
        roleMapExcelColorCodeRow.push({backGroundColor: "", fontColor: ""});
      });

      roleMapExcelRow.push(this.quotesAllFormulaSymbols(roleMapJsonObject[rowIndex].participantAnalysisList[0].roleMapAnalysisList[0].participantRoleProfile.ksaRoleProfile.ksaRoleProfileName));
      roleMapExcelColorCodeRow.push({backGroundColor: "", fontColor: ""});
      roleMapExcelRow.push(roleMapJsonObject[rowIndex].roleAlignmentAverage + "%");
      roleMapExcelColorCodeRow.push({
        backGroundColor: this.getRoleAlignmentColor(roleMapJsonObject[rowIndex].roleAlignmentAverage),
        fontColor: ""
      });

      for (let techIndex = 0; techIndex < heatMapExcelUniqueColumns.length; techIndex++) {
        if (roleMapJsonObject[rowIndex].participantAnalysisList[techIndex] === undefined || roleMapJsonObject[rowIndex].participantAnalysisList[techIndex] == null) {
          roleMapExcelRow.push("NA");
          roleMapExcelColorCodeRow.push({backGroundColor: "", fontColor: ""});
        } else {
          if (heatMapExcelUniqueColumns[techIndex] == this.quotesAllFormulaSymbols(roleMapJsonObject[rowIndex].participantAnalysisList[techIndex].skill.skillName)) {
            roleMapExcelRow.push(roleMapJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].deltaValue == null ? "NA" : roleMapJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].deltaValue + "%");
            const colorCode: string = roleMapJsonObject[rowIndex].participantAnalysisList[techIndex].tableBackgroundColorCode;
            const fontColorCode: string = roleMapJsonObject[rowIndex].participantAnalysisList[techIndex].tableFontColorCode;
            roleMapExcelColorCodeRow.push({backGroundColor: colorCode, fontColor: fontColorCode});
          } else {
            roleMapExcelRow.push("NA");
            roleMapExcelColorCodeRow.push({backGroundColor: "", fontColor: ""});
          }
        }
      }
      roleMapExcelRows.push(roleMapExcelRow);
      roleMapExcelColorCodeRows.push(roleMapExcelColorCodeRow);
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('RoleMap');
    worksheet.properties.defaultColWidth = 20;

    worksheet.addRow(roleMapExcelColumns);

    roleMapExcelRows.forEach((sheetRowData, index) => {
      const row = worksheet.addRow(sheetRowData);
      const colorLength = roleMapExcelColorCodeRows[index].length;
      for (let colorIndex = 0; colorIndex < colorLength; colorIndex++) {
        if (roleMapExcelColorCodeRows[index][colorIndex].backGroundColor) {
          const qty = row.getCell(colorIndex + 1);
          const colors: ColorCodeColors = roleMapExcelColorCodeRows[index][colorIndex];
          qty.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: this.addColorCodePrefix(colors.backGroundColor)}
          };
          qty.font = {color: {argb: this.addColorCodePrefix(colors.fontColor)}};
        }
      }
    });

    this.downloadExcel(workbook, roleMapExcelRows, 'RoleMap.' + ksaName);

  }

  generateTrainingRecExcelData(data: AnalysisResponseData[], ksaName: string, isAdvanceKsa: boolean) {
    const trainingRecJsonString = JSON.stringify(data);
    const trainingRecJsonObject = JSON.parse(trainingRecJsonString);
    const trainingRecExcelRowCount = trainingRecJsonObject.length;
    const trainingRecExcelNonTechColumns: Array<ResponseColumnWithParticipantsEntity> = this.extractNonTechColumns(trainingRecJsonObject);

    const trainingRecExcelColumns = [];
    const heatMapExcelUniqueColumns = [];

    //Populate Non Technical Questions order by question id.
    trainingRecExcelNonTechColumns.forEach(column => trainingRecExcelColumns.push(column.responseColumnDisplay.questionName));

    trainingRecExcelColumns.push('Course Counts');

    for (let rowIndex = 0; rowIndex < trainingRecExcelRowCount; rowIndex++) {
      for (let index = 0; index < trainingRecJsonObject[rowIndex].participantAnalysisList.length; index++) {
        const skillName: string = this.quotesAllFormulaSymbols(trainingRecJsonObject[rowIndex].participantAnalysisList[index].skill.skillName);
        if (!trainingRecExcelColumns.includes(skillName)) {
          trainingRecExcelColumns.push(skillName);
          heatMapExcelUniqueColumns.push(skillName);
        }
      }
    }

    const trainingRecExcelRows = [];
    const courseCounts = ['Score', 'RPScore', 'Delta', 'Training'];
    const trainingRecExcelColorCodeRows = [];

    for (let rowIndex = 0; rowIndex < trainingRecExcelRowCount; rowIndex++) {
      let trainingRecExcelRow = [];
      let trainingRecExcelColorCodeRow = [];

      trainingRecExcelNonTechColumns.forEach(column => {
        const indexOfAnswer: number = trainingRecJsonObject[rowIndex].responseColumnWithParticipants.findIndex(e => e.responseColumnDisplay.questionId === column.responseColumnDisplay.questionId);
        if (indexOfAnswer >= 0) {
          trainingRecExcelRow.push(this.quotesAllFormulaSymbols(trainingRecJsonObject[rowIndex].responseColumnWithParticipants[indexOfAnswer].answerText));
        } else {
          trainingRecExcelRow.push("N/A");
        }
        trainingRecExcelColorCodeRow.push("");
      });

      trainingRecExcelRow.push(courseCounts[0]);
      trainingRecExcelColorCodeRow.push("");
      for (let techIndex = 0; techIndex < heatMapExcelUniqueColumns.length; techIndex++) {
        if (trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex] === undefined || trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex] == null) {
          trainingRecExcelRow.push("NA");
          trainingRecExcelColorCodeRow.push("");
        } else {
          if (heatMapExcelUniqueColumns[techIndex] == this.quotesAllFormulaSymbols(trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].skill.skillName)) {
            trainingRecExcelRow.push(trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].deltaValue === null ? "NA" : (trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].heatMapValue === null && trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].deltaValue !== null) ? 0 + "%" : trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].heatMapValue + "%");
            trainingRecExcelColorCodeRow.push("");
          } else {
            trainingRecExcelRow.push("NA");
            trainingRecExcelColorCodeRow.push("");
          }
        }
      }
      trainingRecExcelRows.push(trainingRecExcelRow);
      trainingRecExcelColorCodeRows.push(trainingRecExcelColorCodeRow);

      if (isAdvanceKsa) {
        trainingRecExcelRow = [];
        trainingRecExcelColorCodeRow = [];
        for (let blankRowCellIndex = 0; blankRowCellIndex < trainingRecExcelNonTechColumns.length; blankRowCellIndex++) {
          trainingRecExcelRow.push("");
          trainingRecExcelColorCodeRow.push("");
        }

        trainingRecExcelRow.push(courseCounts[1]);
        trainingRecExcelColorCodeRow.push("");
        for (let techIndex = 0; techIndex < heatMapExcelUniqueColumns.length; techIndex++) {
          if (trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex] === undefined || trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex] == null) {
            trainingRecExcelRow.push("NA");
            trainingRecExcelColorCodeRow.push("");
          } else {
            if (heatMapExcelUniqueColumns[techIndex] == this.quotesAllFormulaSymbols(trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].skill.skillName)) {
              trainingRecExcelRow.push(trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].roleMapValue == null ? "NA" : trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].roleMapValue + "%");
              trainingRecExcelColorCodeRow.push("");
            } else {
              trainingRecExcelRow.push("NA");
              trainingRecExcelColorCodeRow.push("");
            }
          }
        }
        trainingRecExcelRows.push(trainingRecExcelRow);
        trainingRecExcelColorCodeRows.push(trainingRecExcelColorCodeRow);

        trainingRecExcelRow = [];
        trainingRecExcelColorCodeRow = [];
        for (let blankRowCellIndex = 0; blankRowCellIndex < trainingRecExcelNonTechColumns.length; blankRowCellIndex++) {
          trainingRecExcelRow.push("");
          trainingRecExcelColorCodeRow.push("");
        }
        trainingRecExcelRow.push(courseCounts[2]);
        trainingRecExcelColorCodeRow.push("");
        for (let techIndex = 0; techIndex < heatMapExcelUniqueColumns.length; techIndex++) {
          if (trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex] === undefined || trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex] == null) {
            trainingRecExcelRow.push("NA");
            trainingRecExcelColorCodeRow.push("");
          } else {
            if (heatMapExcelUniqueColumns[techIndex] == this.quotesAllFormulaSymbols(trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].skill.skillName)) {
              trainingRecExcelRow.push(trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].deltaValue == null ? "NA" : trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].deltaValue + "%");
              trainingRecExcelColorCodeRow.push(trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].tableBackgroundColorCode);
            } else {
              trainingRecExcelRow.push("NA");
              trainingRecExcelColorCodeRow.push("");
            }
          }
        }
        trainingRecExcelRows.push(trainingRecExcelRow);
        trainingRecExcelColorCodeRows.push(trainingRecExcelColorCodeRow);
      }

      trainingRecExcelRow = [];
      trainingRecExcelColorCodeRow = [];
      for (let blankRowCellIndex = 0; blankRowCellIndex < trainingRecExcelNonTechColumns.length; blankRowCellIndex++) {
        trainingRecExcelRow.push("");
        trainingRecExcelColorCodeRow.push("");
      }
      trainingRecExcelRow.push(courseCounts[3]);
      trainingRecExcelColorCodeRow.push("");
      for (let techIndex = 0; techIndex < heatMapExcelUniqueColumns.length; techIndex++) {
        if (trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex] === undefined || trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex] == null) {
          trainingRecExcelRow.push("NA");
          trainingRecExcelColorCodeRow.push("");
        } else {
          if (heatMapExcelUniqueColumns[techIndex] == trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].skill.skillName) {
            trainingRecExcelRow.push(trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].recommendedCourse == null ? "No" : this.quotesAllFormulaSymbols(trainingRecJsonObject[rowIndex].participantAnalysisList[techIndex].roleMapAnalysisList[0].recommendedCourse.courseName));
            trainingRecExcelColorCodeRow.push("");
          } else {
            trainingRecExcelRow.push("NA");
            trainingRecExcelColorCodeRow.push("");
          }
        }
      }
      trainingRecExcelRows.push(trainingRecExcelRow);
      trainingRecExcelColorCodeRows.push(trainingRecExcelColorCodeRow);
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Training Recommendations');
    worksheet.properties.defaultColWidth = 20;

    worksheet.addRow(trainingRecExcelColumns);

    trainingRecExcelRows.forEach((sheetRowData, index) => {
      const row = worksheet.addRow(sheetRowData);
      const colorLength = trainingRecExcelColorCodeRows[index].length
      for (let colorIndex = 0; colorIndex < colorLength; colorIndex++) {
        if (trainingRecExcelColorCodeRows[index][colorIndex] != "" && trainingRecExcelColorCodeRows[index][colorIndex] != undefined) {
          const qty = row.getCell(colorIndex + 1);
          let color = trainingRecExcelColorCodeRows[index][colorIndex];
          color = "ff" + color.replace('#', "");
          qty.font = {
            color: {argb: color}
          }
        }
      }
    });

    let startRow = 2;
    const lastEndColumn = trainingRecExcelNonTechColumns.length;
    let endRow = isAdvanceKsa ? 5 : 3;
    const rowCount = trainingRecExcelRows.length / (isAdvanceKsa ? 4 : 2);
    const startEndRowMerge = isAdvanceKsa ? 4 : 2;

    for (let rowCountIndex = 0; rowCountIndex < rowCount; rowCountIndex++) {
      for (let columnCountIndex = 0; columnCountIndex < lastEndColumn; columnCountIndex++) {
        worksheet.mergeCells(startRow, columnCountIndex + 1, endRow, columnCountIndex + 1);
      }
      startRow = startRow + startEndRowMerge;
      endRow = endRow + startEndRowMerge;
    }

    this.downloadExcel(workbook, trainingRecExcelRows, 'Training-Recommendations.' + ksaName);

  }

  private downloadExcel(workbook, workbookRow, excelFileName): void {
    workbook.xlsx.writeBuffer().then((workbookRow: any) => {
      const blob = new Blob([workbookRow], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      fs.saveAs(blob, excelFileName + this.excelExtension);
    });
  }

  private getRoleAlignmentColor(roleAlignmentValue: number): string {
    const colorShade = ['#CD0000', '#FF0000', '#FF4D4D', '#FF9999', '#FFE5E5', '#92D050'];

    if (roleAlignmentValue <= -81) {
      return colorShade[0];
    } else if (roleAlignmentValue >= -80 && roleAlignmentValue <= -61) {
      return colorShade[1];
    } else if (roleAlignmentValue >= -60 && roleAlignmentValue <= -41) {
      return colorShade[2];
    } else if (roleAlignmentValue >= -40 && roleAlignmentValue <= -21) {
      return colorShade[3];
    } else if (roleAlignmentValue >= -20 && roleAlignmentValue <= -1) {
      return colorShade[4];
    } else if (roleAlignmentValue >= 0) {
      return colorShade[5];
    }
  }

  private quotesAllFormulaSymbols(rowInput: string): string {
    const firstSymbolInExcelRow = rowInput?.trim().charAt(0);
    const existsExcelFormula = rowInput && this.excelFormulaSymbols.indexOf(firstSymbolInExcelRow) >= 0;

    if (existsExcelFormula) {
      return "'".concat(rowInput.charAt(0)).concat("'").concat(rowInput.trim().slice(1));
    } else {
      return rowInput;
    }
  }

  private addColorCodePrefix(colorCode: string): string {
    return "ff" + colorCode.replace('#', "");
  }

  private extractNonTechColumns(exportedData: (any)[]): Array<ResponseColumnWithParticipantsEntity> {
    var nonTechnicalColumns: Array<ResponseColumnWithParticipantsEntity> = [];
    exportedData.forEach(data => {
      if (nonTechnicalColumns.length < data.responseColumnWithParticipants.length) {
        nonTechnicalColumns = data.responseColumnWithParticipants;
      }
    });
    return nonTechnicalColumns.sort((column1, column2) =>
      column1.responseColumnDisplay.questionId - column2.responseColumnDisplay.questionId);
  }

  //This method is kind of workaround for our certification data and will be addressed with the task for new excel export
  private extractCertificationAnswer(certificationByParticipant: CertificationByParticipants): string {
    if (certificationByParticipant.resultDesired) {
      if (certificationByParticipant.resultAchieved) {
        return this.translate.instant("analysisAndReports.responseManagement.desiredAndAchieved");
      } else {
        return this.translate.instant("analysisAndReports.responseManagement.desiredButNotAchieved");
      }
    } else {
      if (certificationByParticipant.resultAchieved) {
        return this.translate.instant("analysisAndReports.responseManagement.achievedButNotDesired");
      } else {
        return this.translate.instant("analysisAndReports.responseManagement.notDesiredNotAchieved");
      }
    }
  }
}
