<clr-modal [(clrModalOpen)]="addUserFormOpen" [clrModalClosable]="true"
           (clrModalOpenChange)="onCancelAddingUser(addForm)"
           class="modal-lg">
  <h3 class="modal-title">{{'admin.rolesAndUser.user.addUserModalTitle' | translate}}</h3>
  <div class="modal-body modal-body-layout">
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== '' && !isEmailValidationMsg" [clrAlertClosable]="false"
               [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
    <clr-alert clrAlertType="danger" *ngIf="isEmailValidationMsg" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{emailValidationMsg}}</clr-alert-item>
    </clr-alert>
    <form clrForm clrLabelSize="6" autocomplete="off" (ngSubmit)="onSaveUser()" #addForm="ngForm">
      <clr-input-container class="clr-row">
        <label class="clr-col-3">{{'admin.rolesAndUser.user.emailId' | translate}}*</label>
        <input #isEmailFilled="ngModel" clrInput class="input-container clr-col-9" type="text"
               [(ngModel)]="addUser.emailId" name="userEmailId" required
               pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
               placeholder="{{'placeholder.searchByEmail' | translate}}"
        />
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterEmail' | translate}}</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">{{'errorMsg.invalidEmail' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-input-container class="clr-row">
        <label class="clr-col-3">{{'admin.rolesAndUser.user.firstName' | translate}}*</label>
        <input clrInput class="input-container clr-col-9" type="text" [(ngModel)]="addUser.firstName"
               name="userFirstName"
               autocomplete="nope" required/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterFirstName' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-input-container class="clr-row">
        <label class="clr-col-3">{{'admin.rolesAndUser.user.lastName' | translate}}*</label>
        <input clrInput class="input-container clr-col-9" type="text" [(ngModel)]="addUser.lastName" name="userLastName"
               required/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterLastName' | translate}}</clr-control-error>
      </clr-input-container>
      <br>
      <div class="clr-row">
        <div class="button-container clr-col-3">
          <span class="clr-control-label">{{'admin.rolesAndUser.user.selectRole' | translate}}* </span>
        </div>
        <clr-datagrid class="datagrid-compact popup-datagrid clr-col-9" [(clrDgSelected)]="addUser.userRoleMaps"
                      [clrDgPreserveSelection]="true">
          <clr-dg-column [clrDgField]="'roleName'">{{'admin.rolesAndUser.user.role' | translate}}</clr-dg-column>
          <clr-dg-row *clrDgItems="let role of roles.content" [clrDgItem]="role">
            <clr-dg-cell>{{role.roleName}}</clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-outline" (click)="onCancelAddingUser(addForm)">{{'admin.cancelBtn' |
          translate}}</button>
        <button type="submit" class="btn btn-sm btn-primary"
                [disabled]="addForm.invalid || addUser.firstName =='' || addUser.lastName =='' || addUser.userRoleMaps.length === 0||isEmailFilled.errors?.pattern">{{'admin.addBtn'
          | translate}}</button>
      </div>
    </form>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="editUserFormOpen" (clrModalOpenChange)="onResetUser(editForm)" [clrModalClosable]="true">
  <h3 class="modal-title">{{'admin.rolesAndUser.user.editUserModalTitle' | translate}}</h3>
  <div class="modal-body modal-body-layout">
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== '' && !isEmailValidationMsg" [clrAlertClosable]="false"
      [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
    <clr-alert clrAlertType="danger" *ngIf="isEmailValidationMsg" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{emailValidationMsg}}</clr-alert-item>
    </clr-alert>
    <form clrForm  clrLabelSize="6" (ngSubmit)="onUpdateUser(editForm)" #editForm="ngForm">
      <clr-input-container class="clr-row">
        <label class="clr-col-3">{{'admin.rolesAndUser.user.emailId' | translate}}*</label>
        <input #isEmailEditFilled="ngModel" clrInput class="input-container clr-col-9" type="text"
               [(ngModel)]="editUser.emailId" name="userEmailId"
               pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
        />
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterEmail' | translate}}</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">{{'errorMsg.invalidEmail' | translate}}</clr-control-error>
        <clr-control-error></clr-control-error>
      </clr-input-container>
      <clr-input-container class="clr-row">
        <label class="clr-col-3">{{'admin.rolesAndUser.user.firstName' | translate}}*</label>
        <input clrInput class="input-container clr-col-9" type="text" autocomplete="nope"
               [(ngModel)]="editUser.firstName" name="userFirstName"
               required/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterFirstName' | translate}}</clr-control-error>

      </clr-input-container>
      <clr-input-container class="clr-row">
        <label class="clr-col-3">{{'admin.rolesAndUser.user.lastName' | translate}}*</label>
        <input clrInput class="input-container clr-col-9" type="text" [(ngModel)]="editUser.lastName"
               name="userLastName"
               required/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterLastName' | translate}}</clr-control-error>
      </clr-input-container>
      <br>
      <div class="clr-row">
        <div class="button-container clr-col-3">
          <span class="clr-control-label">{{'admin.rolesAndUser.user.selectRole' | translate}}* </span>
        </div>
        <clr-datagrid class="datagrid-compact popup-datagrid clr-col-9" [(clrDgSelected)]="editUser.userRoleMaps"
                      [clrDgPreserveSelection]="true">
          <clr-dg-column [clrDgField]="'ksaRole.roleName'">{{'admin.rolesAndUser.user.role' | translate}}
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let role of allRoles" [clrDgItem]="role">
            <clr-dg-cell>{{role.ksaRole.roleName}}</clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
      <div class="modal-footer">
        <button class="btn btn-sm btn-outline"
                (click)="onResetUser(editForm)">{{'admin.cancelBtn' | translate}}</button>
        <button type="submit" class="btn btn-sm btn-primary"
                [disabled]="editForm.invalid || editUser.userRoleMaps.length === 0||isEmailEditFilled.errors?.pattern">{{'admin.saveBtn' | translate}}</button>
      </div>
    </form>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showDeleteUserPopUp" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'admin.rolesAndUser.user.deleteUserMsg' | translate}}
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'admin.rolesAndUser.user.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteUserPopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteUser()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showMultideletePopup" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'admin.rolesAndUser.user.deleteUserMsg' | translate}}
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'admin.rolesAndUser.user.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showMultideletePopup = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteSelectedUsers()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>

<div class="button-container" *hasAccess="functionality.manageUser">
  <button id="{{btnId.btnMngUserAdd}}" (click)="onAddUser()" id="addRowBtn" class="btn btn-sm btn-primary">
    {{'admin.rolesAndUser.user.addUserBtn' | translate}}
  </button>
  <button id="{{btnId.btnMngUserMultipleDelete}}"
          [disabled]="!selected || selected.length === 0"
          (click)="onDeleteMultiSelect(selected)" class="btn btn-sm btn-primary">
    {{'admin.rolesAndUser.user.deleteUsersBtn' | translate}}
  </button>
</div>

<clr-datagrid id="manageUsersGrid" (clrDgRefresh)="refresh($event)" [(clrDgSelected)]="selected" [clrDgLoading]="loading"
  [clrDgRowSelection]="false" class="parent-datagrid">
  <clr-dg-column [clrDgField]="'firstName'">{{'admin.rolesAndUser.user.firstName' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'lastName'">{{'admin.rolesAndUser.user.lastName' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'emailId'">{{'admin.rolesAndUser.user.emailId' | translate}}</clr-dg-column>
  <clr-dg-column>{{'admin.rolesAndUser.user.role' | translate}}
  </clr-dg-column>
  <clr-dg-column class="action-cell"></clr-dg-column>
  <clr-dg-row *ngFor="let user of users.content" [clrDgItem]="user">
    <clr-dg-cell>{{user.firstName}}</clr-dg-cell>
    <clr-dg-cell>{{user.lastName}}</clr-dg-cell>
    <clr-dg-cell>{{user.emailId}}</clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let role of user.userRoleMaps">
        <li>{{role.ksaRole.roleName}}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell class="action-cell">
      <clr-dg-action-overflow *hasAccess="functionality.manageUser">
        <button id="{{btnId.btnMngUserEdit}}" type="button" class="action-item"
                (click)="onEditUser(user)">{{'admin.editBtn' | translate}}</button>
        <button id="{{btnId.btnMngUserDelete}}" type="button" class="action-item"
                (click)="onShowDeleteCoursePopUp(user.id)">{{'admin.deleteBtn' |translate}}</button>
      </clr-dg-action-overflow>
    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Users per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} users
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
