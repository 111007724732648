/********************************************************************** *
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
 **********************************************************************/

import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {KsaDetails} from "../../../models/master-ksa.model";
import {AnalysisShareService} from "../../../services/analysis-share.service";
import {ResponseService} from "../../../services/response.service";
import {HeatMapService} from "../../../services/heat-map.service";
import {ColorCode} from "../../../models/color-data.model";
import {Options} from "@angular-slider/ngx-slider";
import {ColorCodeService} from "../../../services/color-code.service";
import {Analysis} from "../../../enums/analaysis.enum";
import {ExcelService} from "../../../services/excel.service";
import {
  AnalysisResponseData,
  CourseList,
  DefaultCourseOrRecommendedCourse,
  ParticipantAnalysisListEntity,
  SkillGroup
} from "../../../models/analysis-response-data.model";
import {Functionality} from "../../../enums/functionality.enum";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {ResponseColumnSettings} from "../../../models/respose-data.model";
import {NgForm} from '@angular/forms';
import {ClrDatagridStateInterface} from "@clr/angular";
import {AnalysisService} from "../../../services/analysisService";
import {TranslateService} from '@ngx-translate/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from "rxjs/operators";
import {Status} from "../../../enums/status.enum";
import {DataGridTypeEnum} from "../../../enums/data-grid-type.enum";
import {PageSizeService} from "../../../services/page-size.service";

@Component({
  selector: 'app-training-recommendations',
  templateUrl: './training-recommendations.component.html',
  styleUrls: ['./training-recommendations.component.scss']
})
export class TrainingRecommendationsComponent implements OnInit {

  statusToFilter: string = "";
  page: number;
  loading: boolean = false;
  total: number;
  errorMsg: string = '';
  isEditResponse: boolean = false;
  isColorModelOpen: boolean = false;
  skillGroups: (SkillGroup)[] = [];
  disabled: boolean = false;
  skillGroupSelected = [];
  selectedKsaId: number = 0;
  isParticipantAnswerReport: boolean = false;
  isParticipantDetails: boolean = false;
  @Input() responseData: AnalysisResponseData[];
  participantId: number;
  showDatagrid: boolean = false;
  @Input() ksaId: number;
  colorData: (ColorCode)[] = [];
  dataGridType: DataGridTypeEnum = DataGridTypeEnum.TRAINING_RECOMMENDATIONS;
  value: number = 40;
  options: Options = {
    floor: -100,
    ceil: 100
  };
  hasErrorOnSave: boolean;
  colorDefaultErrorAlert: boolean;
  colorDefaultSuccessAlert: boolean;
  messageSuccessTimer: boolean = true;
  analysisEnum: typeof Analysis = Analysis;
  courseList: (CourseList)[] = [];
  isSavedRecommendation: boolean = false;
  isRestoredRecommendation: boolean = false;
  participantName: string;
  showRestoreRecommendation: boolean = false;
  participantData: AnalysisResponseData;
  participantIndex: number;
  loadDefaultBtnEnable: boolean = false;
  showLoadDefaultPopUp: boolean = false;
  showThresholdSetter: boolean = false;
  threshold: number = 0;
  functionality : typeof Functionality = Functionality;
  isSavedResponse: boolean = false;
  responseColumnSettings: (ResponseColumnSettings)[];
  successMsgTimeout: number = 5000;
  pinnedColumn: string;
  @Input() showNewWindowButton: boolean = true;
  showLoader: boolean = false;
  ksaDetails: KsaDetails;
  state: ClrDatagridStateInterface;

  constructor(private analysisShareService: AnalysisShareService,
              private changeDetector: ChangeDetectorRef,
              private responseService: ResponseService,
              private heatMapService: HeatMapService,
              private colorCodeService: ColorCodeService,
              private excelService: ExcelService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              public analysisService: AnalysisService,
              private readonly translate: TranslateService,
              public readonly pageSizeService: PageSizeService) {
  }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.getKsaDetails();
  }

  ngOnChanges(): void {
    this.showDatagrid = false;
    this.skillGroups = [];
    if (this.responseData) {
      this.operations();
    }
  }

  async operations() {
    await this.getAllResponseColumnData();
    await this.getAllSkillGroups();
    await this.getAllCoursesById();
    await this.getAllColorCodesById();
  }

  ngAfterContentInit(): void {
    this.changeDetector.detectChanges();
  }

  getKsaDetails(): void{
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.analysisShareService.getKsaDetails(this.ksaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.threshold = data.globalThreshold;
      this.ksaDetails = data;
    })
  }


  getAllResponseColumnData(): void {
    this.responseService.getAllResponseColumnData(this.ksaId).subscribe(data => {
      if (data) {
        this.showDatagrid = true;
        this.responseColumnSettings = data;
      }
    })
  }

  getAllCoursesById(): void {
    this.heatMapService.getAllCoursesById(this.ksaId)
      .subscribe(data => this.courseList = data.filter(skill => skill.courseList.status !== Status.Archived.toString()));
  }

  areCoursesEqual(courseA: any, courseB: any): boolean {
    return courseA && courseB ? courseA.id === courseB.id : courseA === courseB;
  }

  onCancelParticipantAnswerReport(): void {
    this.isParticipantAnswerReport = false;
  }

  extractRecommendedCourseName(participantAnalysisRecommendedCourse: DefaultCourseOrRecommendedCourse): string {
    if (participantAnalysisRecommendedCourse
      && participantAnalysisRecommendedCourse.courseName
      && participantAnalysisRecommendedCourse.status !== Status.Archived) {
      return participantAnalysisRecommendedCourse.courseName;
    } else {
      return this.translate.instant("placeholder.noRecommendedCourseLabel");
    }
  }

  onShowParticipant(data: boolean): void {
    this.isParticipantDetails = data;
  }

  onShowParticipantDetails(data, index): void {
    data.visited = true;
    this.isParticipantDetails = true;
    this.participantIndex = index;
    this.participantData = data;
  }

  onShowResponse(response): void {
    this.participantId = response.participantId;
    response.visited = true;
    this.router.navigate([]).then((result) => {
      window.open(`${environment.API_URL}` +'/#/ParticipantAnswers/'+ this.ksaId+'/'+this.participantId, '_blank');
    });
  }

  getAllSkillGroups(): void {
    this.skillGroupSelected = [];
    this.heatMapService.getAllSkillGroups(this.ksaId).subscribe(data => {
      this.skillGroups = data;
      this.skillGroups.forEach(data => {
        this.skillGroupSelected?.push(data.skillGroupName);
      })
    })
  }

  getAllColorCodesById(): void {
    this.colorCodeService.getAllColorCodesByKsaIdAndType(this.ksaId,DataGridTypeEnum.TRAINING_RECOMMENDATIONS).subscribe(data => {
      if (data) {
        this.colorData = data;
        this.mapColorToTable();
      }
    });
  }

  onShowColorModel(): void {
    this.isColorModelOpen = true;
    this.getAllColorCodesById();
  }

  onHideColorModal(): void{
    this.mapColorToTable();
    this.isColorModelOpen = false;
  }

  onSaveTrainingRecommendation(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.heatMapService.saveTrainingRecommendation(this.ksaId, this.responseData)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        if (data) {
          this.analysisShareService.setAnalysisResponseData(data);
        }
        this.trainingRecommendationRefresh(this.state);
        this.mapColorToTable();
        this.isSavedRecommendation = true;
        this.hasErrorOnSave = false;
        setTimeout(() => {
          this.isSavedRecommendation = false;
        }, this.successMsgTimeout);
      }, () => {
        this.hasErrorOnSave = true;
      })
  }

  onRestoreAllTrainings(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.showRestoreRecommendation = false;
    this.heatMapService.resetTrainingRecommendation(this.ksaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.trainingRecommendationRefresh(this.state);
        this.isRestoredRecommendation = true;
        setTimeout(() => {
          this.isRestoredRecommendation = false;
        }, this.successMsgTimeout);
      })
  }

  onResetByParticipant(participantId: number, index: number): void {
    this.heatMapService.resetTrainingByParticipant(this.ksaId, participantId).subscribe(data => {
      if (data) {
        this.trainingRecommendationRefresh(this.state);
        this.isRestoredRecommendation = true;
      }
      setTimeout(() => {
        this.isRestoredRecommendation = false;
      }, this.successMsgTimeout);
    })
  }

  exportTrainingRecommendation(): void {
    this.analysisShareService.exportExcelData(this.excelService.generateTrainingRecExcelData.bind(this.excelService), this.ksaId, this.dataGridType, this.ksaDetails.isAdvance);
  }

  onSetThreshold(): void {
    this.getKsaDetails();
    this.showThresholdSetter = true;
  }

  onOpenNewWindow(): void {
    window.open(`${environment.API_URL}` + '/#' + '/AnalysisReport/' + 'TrainingRecommendation' + '/' + this.ksaId, '_blank', 'location=yes,height=1000,width=1000,scrollbars=yes,status=yes');
  }

  onRefresh(): void {
    this.trainingRecommendationRefresh(this.state);
  }

  saveThreshold(): void{
    this.responseData[0].globalThreshold = this.threshold;
    this.onSaveTrainingRecommendation();
    this.showThresholdSetter = false;
  }

  onCancelSettingThreshold(form: NgForm): void{
    this.getKsaDetails();
  }

  onSaveColumnSettings() {
    this.responseService.updateResponseColumnData(this.responseColumnSettings).subscribe(data => {
      if (data) {
        this.responseColumnSettings = data;
        this.isSavedResponse = true;
      }
      setTimeout(() => {
        this.isSavedResponse = false;
      }, this.successMsgTimeout);
    })
  }

  setPinnedColumn(questionName: string) {
    this.pinnedColumn = '';
    this.pinnedColumn = questionName;
  }

  trainingRecommendationRefresh(state: ClrDatagridStateInterface) {
    this.pageSizeService.setPageSize(state?.page?.size);
    this.state = state;
    this.loading = true;
    const colFilters: ({ columnName: string; columnValue: string; })[] = [];
    let columnName = '';
    let data;
    if (state?.filters) {
      state.filters.forEach((filter,i)=> {
        const {property, value} = <{ property: string; value: string }>filter;
        colFilters.push({columnValue : value, columnName : property});
        columnName = property;
      })
      data={
        ksaId: this.ksaId,
        size: state?.page.size,
        sortColumn: columnName,
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false)  ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0,
        analysisFilterDTOS: colFilters
      }
    } else {
      data = {
        ksaId: this.ksaId,
        size: state?.page.size,
        sortColumn: state?.sort ? state.sort.by : "",
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false) ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0
      }
    }

    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.analysisShareService.updateResponse(data)
        .pipe(finalize(() => hideSpinner()))
        .subscribe((results) => {
          this.responseData = results.responseData;
          this.total = results.total;
          this.getAllColorCodesById();
          this.loading = false;
        });
    });
  }

  getCourseNameWithVersion(course: any): string {
    return course?.courseName + (course?.courseVersion ? (' [' + course.courseVersion + ']') : '');
  }

  mapColorToTable(): void {
    if (this.colorData && this.colorData.length > 0) {
      this.analysisShareService.mapColorToGivenTableType(this.responseData, this.colorData, this.dataGridType);
    } else {
      this.applyDefaultTableColors();
    }
    this.showDatagrid = true;
  }

  applyDefaultTableColors(): void {
    this.colorCodeService.setLoadDefaultColorByColorDto(this.ksaId, this.dataGridType).subscribe(colorData => {
      if (colorData) {
        this.analysisShareService.mapColorToGivenTableType(this.responseData, colorData, this.dataGridType);
        this.showDatagrid = true;
      }
    });
  }

  addColor(color: ColorCode):void{
    this.colorData.push(color);
    this.mapColorToTable();
  }

  hasHeatMapValue(analysisEntry: ParticipantAnalysisListEntity): boolean {
    return analysisEntry.roleMapAnalysisList[0].deltaValue !== null && analysisEntry.heatMapValue !== null;
  }
}
