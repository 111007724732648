<clr-vertical-nav [clrVerticalNavCollapsed]="false" [clrVerticalNavCollapsible]="true" class="nav-trigger--bottom">
  <a *hasPermission="[functionality.dashboard]; op 'OR'"
     clrVerticalNavLink routerLink="{{getRouterLink('Dashboard')}}" routerLinkActive="active">
    <clr-icon clrVerticalNavIcon shape="dashboard"></clr-icon>
    {{'navigation.dashboard' | translate}}
  </a>
  <clr-vertical-nav-group clrVerticalNavGroupExpanded="true" routerLinkActive="active"
                          *hasPermission="[functionality.ksaAdministration,
                          functionality.ksaEmailManagement,
                          functionality.ksaTeamManagement]; op 'OR'">
    <clr-icon clrVerticalNavIcon shape="clipboard"></clr-icon>
    {{'navigation.ksaEngagement' | translate}}
    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink routerLink="{{getRouterLink('KsaAdministration')}}" routerLinkActive="active"
         *hasPermission="[functionality.ksaAdministration]; op 'OR'">
        {{'navigation.ksaAdministration' | translate}}
      </a>
      <a clrVerticalNavLink routerLink="{{getRouterLink('EmailManagement')}}" routerLinkActive="active"
         *hasPermission="[functionality.ksaEmailManagement]; op 'OR'">
        {{'navigation.emailManagement' | translate}}
      </a>
      <a clrVerticalNavLink routerLink="{{getRouterLink('TeamManagement')}}" routerLinkActive="active"
         *hasPermission="[functionality.ksaTeamManagement]; op 'OR'">
        {{'navigation.ksaTeam' | translate}}
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>
  <clr-vertical-nav-group clrVerticalNavGroupExpanded="true" routerLinkActive="active"
                          *hasPermission="[functionality.reports,
                          functionality.responseManagement,
                          functionality.tracking,
                          functionality.analysis]; op 'OR'">
    <clr-icon clrVerticalNavIcon shape="file-group"></clr-icon>
    {{'navigation.analysisAndReports' | translate}}
    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink routerLink="{{getRouterLink('ResponseManagement')}}" routerLinkActive="active"
         *hasPermission="[functionality.responseManagement]; op 'OR'">
        {{'navigation.responseManagement' | translate}}
      </a>
      <a clrVerticalNavLink routerLink="{{getRouterLink('Analysis')}}" routerLinkActive="active"
         *hasPermission="[functionality.analysis]; op 'OR'">
        {{'navigation.analysis' | translate}}
      </a>
      <a clrVerticalNavLink routerLink="{{getRouterLink('Reports')}}" routerLinkActive="active"
         *hasPermission="[functionality.reports]; op 'OR'">
        {{'navigation.reports' | translate}}
      </a>
      <a clrVerticalNavLink routerLink="{{getRouterLink('Tracking')}}" routerLinkActive="active"
         *hasPermission="[functionality.tracking]; op 'OR'">
        {{'navigation.tracking' | translate}}
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>
  <clr-vertical-nav-group clrVerticalNavGroupExpanded="true" routerLinkActive="active"
                          *hasPermission="[functionality.manageCourse, functionality.manageSkill,
                          functionality.manageRole, functionality.manageUser,
                          functionality.manageEmailTemplate,
                          functionality.manageSurveyTemplate,
                          functionality.manageRoleProfile, functionality.manageRoleProfileGroup,
                          functionality.editTranslation]; op 'OR'">
    <clr-icon clrVerticalNavIcon shape="administrator"></clr-icon>
    {{'navigation.administration' | translate}}
    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink routerLink="{{getRouterLink('ManageRolesUsers')}}" routerLinkActive="active"
         *hasPermission="[functionality.manageRole, functionality.manageUser]; op 'OR'">
        {{'navigation.mngRolesAndUsers' | translate}}
      </a>
      <a clrVerticalNavLink routerLink="{{getRouterLink('ManageCourses')}}" routerLinkActive="active"
         *hasPermission="[functionality.manageCourse]; op 'OR'">
        {{'navigation.mngCourses' | translate}}
      </a>
      <a clrVerticalNavLink routerLink="{{getRouterLink('ManageSkillsRoleProfilesAndGroups')}}"
         routerLinkActive="active" *ngIf="hasAnyPermissions">
        {{'navigation.mngSkillsRoleProfilesAndGroups' | translate}}
      </a>
      <a clrVerticalNavLink routerLink="{{getRouterLink('ManageEmailTemplates')}}" routerLinkActive="active"
         *hasPermission="[functionality.manageEmailTemplate]; op 'OR'">
        {{'navigation.mngEmailTemplates' | translate}}
      </a>
      <a clrVerticalNavLink routerLink="{{getRouterLink('ManageMasterSurvey')}}" routerLinkActive="active"
         *hasPermission="[functionality.manageSurveyTemplate, functionality.editTranslation]; op 'OR'">
        {{'navigation.mngSurveyTemplates' | translate}}
      </a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>
</clr-vertical-nav>
