<clr-modal  [(clrModalOpen)]="showMandatoryTypePopUp" [clrModalClosable]="true" (clrModalOpenChange)="onCancelMandatorySelection()">
<h3 class="modal-title">{{'surveyTemplate.questionSheet.isMandatroyQnType'|translate}}</h3>
<div class="modal-body">
    <clr-radio-wrapper>
        <input (change)="onMandatoryQuestionInput(true)" [checked]="questionData.get('isMandatory').value" clrRadio
            name="mandatoryType" type="radio" />
        <label>{{'surveyTemplate.questionSheet.isMandatoryQuestion'|translate}}</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
        <input (change)="onMandatoryQuestionInput(false)" [checked]="!questionData.get('isMandatory').value" clrRadio
            name="mandatoryType" type="radio" />
        <label>{{'surveyTemplate.questionSheet.isNonMandatoryQuestion'|translate}}</label>
    </clr-radio-wrapper>
</div>
<div class="modal-footer">
  <button (click)="onCancelMandatorySelection()" class="btn btn-outline btn-sm" type="button">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}</button>
  <button (click)="revertChange = false;setMandatorySelection()" class="btn btn-primary btn-sm" type="button">{{'admin.saveBtn' | translate}}</button>
</div>
</clr-modal>
