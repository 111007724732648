<div class="sectionSettings">
  <div class="btn-group btn-primary btn-icon buttonUpDown">
    <clr-icon shape="angle-double" [hidden]="isUpArrayHidden(sectionData)" class="btn-sm up-down-arrow"
              (click)="onCheckUpdateStatus();moveSection.emit(-1)"></clr-icon>
    <clr-icon id="downArrow" [hidden]="isDownArrayHidden(sectionData)" class="btn-sm up-down-arrow" shape="angle-double"
              (click)="onCheckUpdateStatus();moveSection.emit(1)"></clr-icon>
  </div>
  <clr-dropdown [clrCloseMenuOnItemClick]="true" *ngIf="hasWritePermission">
    <button clrDropdownTrigger aria-label="Dropdown demo button">
      <clr-icon shape="ellipsis-vertical"></clr-icon>
    </button>
    <clr-dropdown-menu clrPosition="left-top" *clrIfOpen>
      <!-- Section Display Logic is not intended to be used right now so the button will be temporarily removed: -->
      <!--button (click)="showDisplayLogicPopUp=true" *ngIf="sectionData.sectionSequenceNo!=1"
              clrDropdownItem>{{'surveyTemplate.questionSheet.commonDLogic'|translate}}
      </button-->
      <button (click)="openSectionTypePopup()"
              clrDropdownItem>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.sectionTypeModal'|translate}}
      </button>
      <button (click)="openMandatorySectionPopup()"
              clrDropdownItem>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.mandatoryModal'|translate}}
      </button>
      <button (click)="showDeleteSectionPopUp = true" clrDropdownItem>{{'admin.deleteBtn'|translate}}</button>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>
<clr-accordion>
  <clr-accordion-panel [clrAccordionPanelOpen]="isPanelOpen"
                       (clrAccordionPanelOpenChange)="onAccordionPanelOpen($event)">
    <clr-accordion-title><strong>{{sectionName}}</strong>
    </clr-accordion-title>
    <clr-accordion-content>
      <div class="section-name-container">
        <div class="QuestionBanners" draggable="false">
          <div *ngIf="showDisplayMessage" class="displayCondition">
            <div class="DynamicChoicesBox LogicBox">
              <table aria-describedby="Display Logic Table">
                <tbody>
                <tr>
                  <th id="DisplayTab" class="InfoBarDisplayTab"></th>
                  <td class="DisplayDesc">
                    <div class="DisplayLogicHeading">
                      <h6>{{'surveyTemplate.questionSheet.isDisplay'|translate}}</h6>
                    </div>
                    <div [ngSwitch]="displayLogicToShow.questionTypeId">
                      <div *ngSwitchCase="questionTypeEnum.FreeText" class="all-question">
                        {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                        {{displayLogicToShow.questionText}}
                        {{displayLogicToShow.skipConditionName}}
                      </div>
                      <div *ngSwitchCase="questionTypeEnum.RadioButton" class="all-question">
                        {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                        {{displayLogicToShow.answerLabel}}
                        {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                        {{displayLogicToShow.questionText}}
                        {{displayLogicToShow.skipConditionName}} {{displayLogicToShow.displayValue}}
                      </div>
                      <div *ngSwitchCase="questionTypeEnum.MultipleChoice" class="all-question">
                        {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                        {{displayLogicToShow.answerLabel}}
                        {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                        {{displayLogicToShow.questionText}}
                        {{displayLogicToShow.skipConditionName}} {{displayLogicToShow.displayValue}}
                      </div>
                      <div *ngSwitchCase="questionTypeEnum.Scale" class="all-question">
                        {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                        {{displayLogicToShow?.questionText}}
                        {{displayLogicToShow?.skipConditionName}} {{displayLogicToShow.displayValue}}
                      </div>
                      <div *ngSwitchCase="questionTypeEnum.MatrixTable" class="all-question">
                        {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                        {{displayLogicToShow.colAnswerLabel}}
                        {{displayLogicToShow.answerLabel}}
                        {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                        {{displayLogicToShow.questionText}}
                        {{displayLogicToShow.skipConditionName}}
                      </div>
                    </div>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <form clrForm autocomplete="off">
          <clr-input-container class="survey-input-container">
            <input [(ngModel)]="sectionName" autocomplete="off" class="clr-control-container clr-col-md-6 clr-col-6"
                   (keyup)="onSectionNameInput($event.target.value)" [disabled]="sectionData.isImportedSection"
                   clrInput
                   maxLength="100" name="sectionName" pattern="^[^!@#*&(){}<>\/;`%$^:?]*$"
                   placeholder="Enter Section Name*"
                   required/>
            <clr-control-error *clrIfError="'required'" class="error">{{'errorMsg.sectionNameIsRequired' | translate}}</clr-control-error>
            <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}
            </clr-control-error>
          </clr-input-container>
          <div *ngIf="showDuplicateSectionError" class="errorSectionName">{{'errorMsg.sectionNameExistMsg' | translate}}
          </div>
        </form>
      </div>
      <div class="section-image-container card-title" *ngIf="convertedImage">
        <div class="card">
          <div class="clr-row">
            <div class="clr-col-12">
              <img [src]="convertedImage" alt="Question Image" class="sectionImage">
              <clr-icon (click)="showImageDeletePopUp = true" [hidden]="sectionData?.isImportedSection" aria-label="warning"
                        class="btn-sm btn-warning-outline minusIconSectionImage" shape="minus-circle"></clr-icon>

            </div>
          </div>
        </div>
      </div>
      <div class="question-container">
        <ng-container #viewContainerReference></ng-container>
      </div>
      <div class="section-btn-container">
        <button (click)="showImageUploadPopUp = true" *ngIf="!convertedImage && hasWritePermission"
                class="btn btn-outline btn-sm">
          {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.addImgForSectionBtn'|translate}}
        </button>
        <clr-dropdown *ngIf="hasWritePermission">
          <button class="btn btn-primary btn-sm import-question" clrDropdownTrigger>
            {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.importQuestion'|translate}}
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="top-right">
            <button (click)="onImportQuestion()" clrDropdownItem>
              {{'surveyTemplate.masterSurvey.existingSurvey'|translate}}
            </button>
            <div aria-hidden="true" class="dropdown-divider" role="separator"></div>
            <button (click)="onImportQuestionFile()"
                    clrDropdownItem>{{'surveyTemplate.masterSurvey.file'|translate}}</button>
          </clr-dropdown-menu>
        </clr-dropdown>
        <clr-dropdown *ngIf="hasWritePermission">
          <button class="btn btn-primary btn-sm add-question" clrDropdownTrigger>
            {{'surveyTemplate.masterSurvey.addQuestion'|translate}}
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="top-right">
            <button (click)="onCreateQuestion(questionTypeEnum.Description)"
                    clrDropdownItem>
              {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.addQuestionMenu.descriptionText'|translate}}
            </button>
            <button (click)="onCreateQuestion(questionTypeEnum.FreeText)"
                    clrDropdownItem>
              {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.addQuestionMenu.freeText'|translate}}
            </button>
            <button (click)="onCreateQuestion(questionTypeEnum.RadioButton)"
                    clrDropdownItem>
              {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.addQuestionMenu.radioBtn'|translate}}
            </button>
            <button (click)="onCreateQuestion(questionTypeEnum.MultipleChoice)"
                    clrDropdownItem>
              {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.addQuestionMenu.multipleChoice'|translate}}
            </button>
            <button (click)="onCreateQuestion(questionTypeEnum.Scale)"
                    clrDropdownItem>
              {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.addQuestionMenu.scale'|translate}}
            </button>
            <button (click)="onCreateQuestion(questionTypeEnum.MatrixTable)"
                    clrDropdownItem>
              {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.addQuestionMenu.matrixTable'|translate}}
            </button>
            <div class="dropdown-divider"></div>
            <button (click)="onCreateQuestion(questionTypeEnum.RoleProfile)"
                    clrDropdownItem>
              {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.addQuestionMenu.roleProfile'|translate}}
            </button>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-modal *ngIf="showDeleteSectionPopUp" [(clrModalOpen)]="showDeleteSectionPopUp">
  <h3 class="modal-title">{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.deleteSectionModalTitle'|translate}}
    <strong>{{sectionData.section.sectionName}}</strong> ?
  </h3>
  <div class="modal-body">
    {{'surveyTemplate.mngSurvey.unDeleteMsg'|translate}}
    <div *ngIf="errorOnDisplayLogicItemDelete" class="alert alert-danger" id="alert-message" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
            {{'errorMsg.canNotDeleteSection'|translate}} {{dependentSectionDisplayLogic}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="showDeleteSectionPopUp = false"
            class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="onRemoveSection(sectionData)"
            class="btn btn-outline-warning btn-sm">{{'surveyTemplate.mngSurvey.deleteBtn' | translate}}
    </button>
  </div>
</clr-modal>

<clr-modal *ngIf="showImageUploadPopUp" [(clrModalOpen)]="showImageUploadPopUp">
  <h5 class="modal-title"> {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.uploadImageModalTitle'|translate}}
    <strong>{{sectionData.section.sectionName}}</strong>
  </h5>
  <div class="modal-body">
    <form class="clr-form">
      <div class="clr-form-control">
        <label class="clr-control-label"></label>
        <div class="clr-control-container">
          <div class="clr-file-wrapper">
            <label class="clr-control-label" for="basic"><span class="btn btn-sm">{{'admin.browseBtn' |
              translate}}</span></label>
            <input (change)="onImageSelect($event)" accept="image/png, image/jpeg, image/jpg" class="clr-file" id="basic" type="file"/>
          </div>
          <div *ngIf="imageURL" class="clr-row">
            <div class="clr-col-12">
              <div class="card">
                <img [src]="imageURL" alt="Question Image" height="200" width="500">
              </div>
            </div>
          </div>
          <div *ngIf="isUploadImageInvalid" class="alert alert-danger" id="alert-message" role="alert">
            <div class="alert-items">
              <div class="alert-item static">
                <div class="alert-icon-wrapper">
                  <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                </div>
                <span class="alert-text">
                  {{'surveyTemplate.masterSurvey.'+ imageInvalidErrorMsg |translate}}
                </span>
              </div>
            </div>
          </div>
          <div *ngIf="serverUploadError" class="alert alert-danger" id="alert-message" role="alert">
            <div class="alert-items">
              <div class="alert-item static">
                <div class="alert-icon-wrapper">
                  <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                </div>
                <span class="alert-text">
                  {{'surveyTemplate.masterSurvey.ServerImageErrorMsg' |translate}}{{serverUploadErrorMsg}}
                  </span>
              </div>
            </div>
          </div>
          <div *ngIf="!imageURL">
            <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
            <span class="clr-subtext">{{'surveyTemplate.questionSheet.isImageTypeSupport'|translate}}</span>
            <span>{{'surveyTemplate.questionSheet.isMaxSizeImage'|translate}}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="resetUploadFilePopUpMsg()" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button [disabled]="isUploadButtonDisabled()" (click)="onCheckUpdateStatus();onUploadImage()"
            class="btn btn-primary btn-sm">{{'surveyTemplate.questionSheet.isUpload'|translate}}
    </button>
  </div>
</clr-modal>
<clr-modal *ngIf="showImageDeletePopUp" [(clrModalOpen)]="showImageDeletePopUp">
  <h3 class="modal-title">{{'surveyTemplate.questionSheet.isDeleteImageMsg'|translate}}</h3>
  <div class="modal-body">
    <img [src]="convertedImage" alt="Question Image" height="100" width="480">
  </div>
  <div class="modal-footer">
    <button (click)="showImageDeletePopUp = false" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn'
      | translate}}
    </button>
    <button (click)="onDeleteImageSection()"
            class="btn btn-outline-warning btn-sm">{{'surveyTemplate.mngSurvey.deleteBtn' | translate}}
    </button>
  </div>
</clr-modal>

<clr-modal *ngIf="showSectionTypePopUp" [(clrModalOpen)]="showSectionTypePopUp"
           (clrModalOpenChange)="onCancelSectionTypeSelection()">
  <h3 class="modal-title">{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.sectionTypeModal'|translate}}</h3>
  <div class="modal-body">
    <clr-radio-wrapper>
      <input (change)="onTechnicalSectionInput(true)" [checked]="sectionData.isTechnicalSection" clrRadio
             name="sectionType" type="radio"/>
      <label>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.sectionType.techSection'|translate}}</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
      <input (change)="onTechnicalSectionInput(false)" [checked]="!sectionData.isTechnicalSection" clrRadio
             name="sectionType" type="radio"/>
      <label>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.sectionType.nonTechSection'|translate}}</label>
    </clr-radio-wrapper>
  </div>
  <div class="modal-footer">
    <button (click)="onCancelSectionTypeSelection()" class="btn btn-outline btn-sm"
            type="button">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="revertTypeChange = false;onCheckUpdateStatus();showSectionTypePopUp = false" class="btn btn-primary btn-sm"
            type="button">{{'surveyTemplate.masterSurvey.save'|translate}}
    </button>
  </div>
</clr-modal>

<clr-modal *ngIf="showMandatoryTypePopUp" [(clrModalOpen)]="showMandatoryTypePopUp"
           (clrModalOpenChange)="onCancelMandatorySelection()">
  <h3 class="modal-title">
    {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.sectionType.sectionTypeMandatory'|translate}}</h3>
  <div class="modal-body">
    <clr-radio-wrapper>
      <input (change)="onMandatorySectionInput(true)" [checked]="sectionData.isMandatorySection" clrRadio
             name="mandatoryType" type="radio"/>
      <label>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.mandatory.mandatorySection'|translate}}</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
      <input (change)="onMandatorySectionInput(false)" [checked]="!sectionData.isMandatorySection" clrRadio
             name="mandatoryType" type="radio"/>
      <label>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.mandatory.nonMandatorySection'|translate}}</label>
    </clr-radio-wrapper>
  </div>
  <div class="modal-footer">
    <button (click)="onCancelMandatorySelection()" class="btn btn-outline btn-sm"
            type="button">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="revertMandatoryChange = false;onCheckUpdateStatus();showMandatoryTypePopUp = false"
            class="btn btn-primary btn-sm"
            type="button">{{'surveyTemplate.masterSurvey.save'|translate}}
    </button>
  </div>
</clr-modal>

<clr-modal *ngIf="showImportQuestionModal" [(clrModalOpen)]="showImportQuestionModal">
  <h6 *ngIf="!showSpinnerImportQuestion" class="modal-title">
    {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.importQuestion'|translate}}</h6>
  <div class="modal-body">
    <div *ngIf="showSpinnerImportQuestion" class="content-container" id="saveSurveySpin">
      <div class="content-area centered-content-area">
        <div class="spinner spinner-sm">{{'surveyTemplate.masterSurvey.loadingSpinner'|translate}}</div>
      </div>
    </div>
    <div *ngIf="!showSpinnerImportQuestion" class="clr-col-sm-12">
      <clr-select-container>
        <label>{{'surveyTemplate.masterSurvey.selectPublishedSurvey'|translate}}</label>
        <select (change)="onSelectPublishedSurvey($event.target.value)" clrSelect name="publishedSurvey">
          <option disabled selected value="">{{'surveyTemplate.masterSurvey.selectSurvey'|translate}}</option>
          <option *ngFor="let item of publishedSurveys" [value]="item.id">
            {{item.masterSurveyName}} [{{item.version}}]
          </option>
        </select>
      </clr-select-container>
    </div>
    <br>
    <div *ngIf="!showSpinnerImportQuestion" class="clr-col-sm-12">
      <clr-datagrid (clrDgSingleSelectedChange)="onSelectSection($event)" [clrDgSingleSelected]="selectedSection"
                    class="popup-datagrid">
        <clr-dg-column [clrDgField]="'section.sectionName'">{{'surveyTemplate.masterSurvey.section'|translate}}</clr-dg-column>
        <clr-dg-row *clrDgItems="let section of sectionListOfSelectedSurvey" [(clrDgSelected)]="section.selected"
                    [clrDgItem]="section">
          <clr-dg-cell>{{section.section.sectionName}}</clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
    </div>
    <div *ngIf="!showSpinnerImportQuestion" class="clr-col-sm-12">
      <clr-datagrid [(clrDgSelected)]="selected" [clrDgRowSelection]="true" class="popup-datagrid">
        <clr-dg-column [clrDgField]="'question.questionText'">Questions</clr-dg-column>
        <clr-dg-row *clrDgItems="let item of questionListOfSelectedSection" [clrDgItem]="item">
          <clr-dg-cell>Q. {{item.sectionQuestionSequenceNo}} - {{item.question.questionText}}</clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
    </div>
  </div>
  <div *ngIf="!showSpinnerImportQuestion" class="modal-footer">
    <button (click)="onCancelImportQuestion()" class="btn btn-outline btn-sm"
            type="button">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="onAddImportedQuestions(selected)" class="btn btn-primary btn-sm"
            type="button">{{'admin.addBtn'|translate}}
    </button>
  </div>
</clr-modal>
<app-section-display-logic-pop-up *ngIf="showDisplayLogicPopUp" [showDisplayLogicPopUp]="showDisplayLogicPopUp"
                                  [surveySections]="surveySections"
                                  [sectionData]="sectionData" (showDisplayLogicModal)="showDisplayLogicPopUp=false"
                                  (onUpdateDisplay)=onUpdateDisplay($event)></app-section-display-logic-pop-up>

<clr-modal *ngIf="showUploadQuestionFile" [(clrModalOpen)]="showUploadQuestionFile">
  <h3 class="modal-title">{{'surveyTemplate.mngSurvey.importQuestionFile' | translate}}</h3>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-2">
        <div class="clr-file-wrapper">
          <label class="clr-control-label" for="basic"><span class="btn btn-sm">{{'admin.browseBtn' | translate}}</span></label>
          <input #fileInput (change)="onImportQuestionSelectFile($event)" accept=".csv" class="clr-file" id="basic"
                 type="file"/>
        </div>
      </div>
      <div class="clr-col-6">
        <label class="clr-control-label" for="basic"><strong>{{selectedFileName}}</strong></label>
      </div>
    </div>
    <clr-alert clrAlertType="danger" *ngIf="errorOnUploadFileQuestion !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorOnUploadFileQuestion}}</clr-alert-item>
    </clr-alert>
  </div>
  <div class="modal-footer">
    <button (click)="onCancelFileSectionImport()" class="btn btn-outline btn-sm"
      type="button">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="onOkImportFileQuestion()" class="btn btn-primary btn-sm"
      type="button">{{'surveyTemplate.questionSheet.isOk'|translate}}
    </button>
  </div>
</clr-modal>
