/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Status} from '../../../enums/status.enum';
import {MasterSurveySection} from '../../../models/master-survey-section.model';
import {FunctionTypeSurvey, MasterSurvey, MasterSurveySimple} from '../../../models/master-survey.model';
import {MasterSurveyService} from '../../../services/master-survey.service';
import {ClrDatagridStateInterface} from "@clr/angular";
import {ShareSurveyDataService} from "../../../services/share-survey-data.service";
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {QuestionType} from '../../../enums/questionType.enum';
import {SurveyControls} from '../../../enums/survey-controls.enum';
import {finalize} from "rxjs/operators";
import {TranslateService} from '@ngx-translate/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {TealiumUtagService} from "../../../services/utag.service";
import {AnalyticsEvents} from "../../../enums/analytics-events.enum";
import {MasterSurveyTranslationService} from "../../../services/master-survey-translation.service";
import {LocalizationStatusEnum} from "../../../enums/localization-status.enum";
import {LocalizationLocaleEnum} from "../../../enums/localization-locale.enum";
import {User} from '../../../models/auth-user.model';
import {UserCacheService} from '../../../services/user-cache.service';

const INITIAL_SURVEY_VERSION = 0.01;

@Component({
  selector: 'app-mng-master-survey',
  templateUrl: './mng-master-survey.component.html',
  styleUrls: ['./mng-master-survey.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MngMasterSurveyComponent implements OnInit {
  state: ClrDatagridStateInterface;
  statusToFilter: string = '';
  page: number;
  loading: boolean = true;
  total: number;
  surveySections: MasterSurveySection[] = [];
  surveyID: number | undefined;
  statusEnum: typeof Status = Status;
  survey: MasterSurvey = {};
  selected: MasterSurvey[] = [];
  surveyStatusOption: string[] = ['Draft', 'Archived', 'Published'];
  supportedTranslationLanguages: LocalizationLocaleEnum[] = [];
  selectedNewTranslationLanguage: LocalizationLocaleEnum;
  addTranslationSurveyId: number;
  masterSurveyDraftLanguages: string[] = [];
  selectedEditTranslationLanguage: string;
  editTranslationSurveyId: number;
  functionType: FunctionTypeSurvey = {
    newVersion: "newVersion",
    clone: "clone"
  }
  showDeletePopUp: boolean = false;
  showVersionTreePopUp: boolean = false;
  showMasterSurveyTab: boolean = false;
  showPreview: boolean;
  showClonePopUp: boolean = false;
  showPublishPopUp: boolean = false;
  showNewVersionPopUp: boolean = false;
  showArchivePopUp: boolean = false;
  showAddTranslationModal: boolean = false;
  showAddTranslationErrorModal: boolean = false;
  showEditTranslationModal: boolean = false;
  parentSurveyId: number;
  disableMasterSurveyTab: boolean = true;
  rootVersion: MasterSurvey[] = [];
  isExpanded: boolean = true;
  isAdd: boolean = false;
  disableSurveyNameNewVersion: boolean = false;
  questionTypeEnum: typeof QuestionType = QuestionType;
  disablePublishButton: boolean;
  isUserEditingSurvey: boolean = false;

  isClone: boolean = false;
  isNewVersion: boolean = false;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  functionality: typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;
  allSurveyData: MasterSurveySimple[] = [];
  surveyId: number;
  showTreeLoading: boolean;

  constructor(public readonly masterSurveyService: MasterSurveyService,
              private readonly shareSurveyData: ShareSurveyDataService,
              private readonly masterSurveyTranslationService: MasterSurveyTranslationService,
              private readonly translate: TranslateService,
              private readonly utagService: TealiumUtagService,
              private readonly userCacheService: UserCacheService) {
  }

  ngOnInit(): void {
    this.showMasterSurveyTab = false;
    this.shareSurveyData.setShowSurveyTab(false);
    this.masterSurveyService.setSurveyState(true);
    this.getAllMasterSurveyDetails();
  }

  getAllMasterSurveyDetails(): void {
    this.loading = true;
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.loadSurveyData();
    });
  }

  onShowTreeView(survey: MasterSurvey): void {
    this.showTreeLoading = true;
    this.showVersionTreePopUp = true;
    this.masterSurveyService.getMasterSurveyDetails(survey.id).subscribe(data => {
      this.showVersionTreePopUp = true;
      this.rootVersion = [];
      this.rootVersion.push(this.getParent(data));
      this.showTreeLoading = false;
    });
  }

  getParent(survey): MasterSurvey {
    if (survey?.parent != null) {
      return this.getParent(survey.parent);
    }
    return survey;
  }

  getChildren = survey => survey.children;

  onAddMasterSurveyBtn(): void {
    this.masterSurveyService.setSurveyUpdateStatus(true);
    this.shareSurveyData.setSurvey({});
    this.showPreview = false;
    this.shareSurveyData.setIsAddMasterSurvey(true);
    this.isAdd = true;
    this.showMasterSurveyTab = true;
    this.disableMasterSurveyTab = true;
    this.shareSurveyData.setShowSurveyTab(true);
    this.disableSurveyNameNewVersion = false;
    this.masterSurveyService.setSurveyState(true);
    this.parentSurveyId = null;
  }

  onShowMasterSurveyTab(data: boolean): void {
    this.showMasterSurveyTab = data;
    this.shareSurveyData.setShowSurveyTab(data);
    this.getAllMasterSurveyDetails();
  }

  onEditSurvey(id: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.masterSurveyService.getMasterSurveyDetails(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(mSurvey => {
        const masterSurvey: MasterSurvey = mSurvey as MasterSurvey;
        const currentUser: User = this.userCacheService.getLoginDataFromLocalStorage();
        if (!masterSurvey.editingUser || masterSurvey.editingUser?.emailId === currentUser?.email) {
          this.masterSurveyService.updateMasterSurveyEditor(id).subscribe(editingInfo => {
            this.masterSurveyService.setSurveyUpdateStatus(true);
            this.masterSurveyService.setSurveyState(true);
            if (masterSurvey.parent?.id) {
              this.parentSurveyId = masterSurvey.parent?.id;
            } else {
              this.parentSurveyId = null;
            }
            masterSurvey.editingUser = editingInfo.editingUser;
            masterSurvey.lastEditedOn = editingInfo.lastEditedOn;
            this.shareSurveyData.setSurvey(masterSurvey);
            this.disableSurveyNameNewVersion = masterSurvey?.version > INITIAL_SURVEY_VERSION;
            this.shareSurveyData.setIsAddMasterSurvey(false);
            this.showMasterSurveyTab = true;
            this.shareSurveyData.setShowSurveyTab(true);
            this.disableMasterSurveyTab = true;
            this.showPreview = false;
            this.isAdd = false;
            this.masterSurveyService.setEditingUserData(masterSurvey);
          });
          return;
        }

        this.masterSurveyService.setEditingUserData(masterSurvey);
        this.isUserEditingSurvey = true;
      });
  }

  onInitializeClone(id: number): void {
    this.surveyId = id;
    this.shareSurveyData.setIsAddMasterSurvey(false);
    this.showClonePopUp = true;
    this.survey = {};
  }

  onCloneSurvey(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.masterSurveyService.getMasterSurveyDetails(this.surveyId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(survey => {
        this.survey = survey;
        this.survey.surveySections.forEach(section => section[this.surveyControlsEnum.isImportedSection] = false);
        this.masterSurveyService.setSurveyUpdateStatus(true);
        this.masterSurveyService.setSurveyState(true);
        this.showClonePopUp = false;
        this.survey.masterSurveyName = "";
        this.setConditions();
        this.deleteSurveyIds(this.functionType.clone);
        this.shareSurveyData.setSurvey(this.survey);
        this.disableSurveyNameNewVersion = false;
        this.isClone = true;
        this.isNewVersion = false;
        this.isAdd = false;
        this.parentSurveyId = null;
        this.shareSurveyData.setIsAddMasterSurvey(false);
      })
  }

  onCloneCancel(): void {
    this.showMasterSurveyTab = false;
    this.shareSurveyData.setShowSurveyTab(false);
    this.showClonePopUp = false;
    this.shareSurveyData.setSurvey({});
    this.survey = {};
  }

  onInitializeNewVersion(id: number): void {
    this.surveyId = id;
    this.shareSurveyData.setIsAddMasterSurvey(false);
    this.showNewVersionPopUp = true;
    this.survey = {};
  }

  onNewVersionSurvey(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.masterSurveyService.getMasterSurveyDetails(this.surveyId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(survey => {
        this.survey = survey;
        this.parentSurveyId = null;
        this.survey.surveySections.forEach(section => section[this.surveyControlsEnum.isImportedSection] = true);
        this.masterSurveyService.setSurveyUpdateStatus(true);
        this.masterSurveyService.setSurveyState(true);
        this.shareSurveyData.setIsAddMasterSurvey(false);
        this.parentSurveyId = this.survey.id;
        this.showNewVersionPopUp = false;
        this.setConditions();
        this.deleteSurveyIds(this.functionType.newVersion);
        this.shareSurveyData.setSurvey(this.survey);
        this.disableSurveyNameNewVersion = true;
        this.isNewVersion = true;
        this.isClone = false;
        this.isAdd = false;
      });
  }

  onNewVersionCancel(): void {
    this.parentSurveyId = null;
    this.showMasterSurveyTab = false;
    this.shareSurveyData.setShowSurveyTab(false);
    this.showNewVersionPopUp = false;
    this.shareSurveyData.setSurvey({});
    this.survey = {};
  }

  deleteSurveyIds(val: string): void {
    delete this.survey.id;
    this.survey.surveySections.forEach(surveyVal => {
      delete surveyVal.id;
      delete surveyVal.image?.id;
      if (val === this.functionType.clone) {
        delete surveyVal?.sectionSkipCondition?.id;
        delete surveyVal.section.id;
        surveyVal.isImported = false;
        surveyVal.sectionQuestions.forEach(questionVal => {
          delete questionVal.id;
          questionVal.isImported = false;
          questionVal.isVersioned = false;
          delete questionVal.question.id;
          delete questionVal.question.image?.id;
          delete questionVal.questionDisplayCondition?.id;
          delete questionVal.questionSkipCondition?.id;
          delete questionVal.questionSkipCondition?.answerLabel?.id;
          delete questionVal.questionSkipCondition?.colAnswerLabel?.id;
          questionVal.sectionSkipConditions = [];
          questionVal.question.answerLabels?.forEach(answerLabel => {
            delete answerLabel.id;
          });
          questionVal.question?.mapSurveyQuestionWithTags?.forEach(tag => {
            delete tag.id;
          });
          delete questionVal.question?.answerProperty?.id;
        });
      } else {
        delete surveyVal?.sectionSkipCondition?.id;
        surveyVal.sectionQuestions.forEach(questionVal => {
          delete questionVal.questionDisplayCondition?.id;
          delete questionVal.questionSkipCondition?.id;
          questionVal.sectionSkipConditions = [];
          delete questionVal.id;
          questionVal.isImported = true;
          questionVal.isVersioned = true;
        });
      }
    });
  }

  setConditions(): void {
    this.showMasterSurveyTab = true;
    this.shareSurveyData.setShowSurveyTab(true);
    this.showPreview = false;
    this.disableMasterSurveyTab = true;
  }

  onInitializePublish(id: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.masterSurveyService.getMasterSurveyDetails(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(survey => {
        const isValidSurvey = this.checkSurveyIsValid(survey);
        this.disablePublishButton = !isValidSurvey;
        if (isValidSurvey) {
          this.surveyID = survey?.id;
          this.surveyId = id;
          this.showPublishPopUp = true;
        }
      });
  }

  onPublishSurvey(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));

    this.showPublishPopUp = false;
    this.masterSurveyService.publishMasterSurvey(this.surveyId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        if (data) {
          this.getAllMasterSurveyDetails();
          this.utagService.trackEvent(AnalyticsEvents.PUBLISHED_SURVEY_TEMPLATE);
        }
      });
  }

  onPublishCancel(): void {
    this.showPublishPopUp = false;
    this.surveyID = undefined;
  }

  onInitializeArchive(id: number): void {
    this.surveyId = id;
    this.shareSurveyData.setIsAddMasterSurvey(false);
    this.showArchivePopUp = true;
  }

  onArchiveSurvey(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));

    this.masterSurveyService.archiveMasterSurvey(this.surveyId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        if (data) {
          this.showArchivePopUp = false;
          this.getAllMasterSurveyDetails();
          this.utagService.trackEvent(AnalyticsEvents.ARCHIVED_SURVEY_TEMPLATE);
        }
      });
  }

  onArchiveSurveys(selected: MasterSurvey[]): void {
    const ids = selected.map(selectedSurvey => selectedSurvey.id);

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.masterSurveyService.archiveMultipleMasterSurveys(ids)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(() => this.getAllMasterSurveyDetails());
  }

  onArchiveCancel(): void {
    this.showMasterSurveyTab = false;
    this.shareSurveyData.setShowSurveyTab(false);
    this.showArchivePopUp = false;
    this.shareSurveyData.setSurvey({});
  }

  onPreviewSurvey(id: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.masterSurveyService.getMasterSurveyDetails(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(survey => {
        this.shareSurveyData.setSurvey(survey);
        this.showPreview = true;
        this.showMasterSurveyTab = true;
        this.shareSurveyData.setShowSurveyTab(true);
        if (survey?.status == Status.Published) {
          this.disableMasterSurveyTab = false;
        }
        this.masterSurveyService.setPreviewState(true);
      });
  }

  assignIdOnDelete(id: number): void {
    this.surveyId = id;
    this.showDeletePopUp = true;
  }

  onDeleteSurvey(): void {
    if (this.surveyId) {
      showSpinner(this.translate.instant("spinnerLabels.common.loading"));
      this.masterSurveyService.deleteMasterSurvey(this.surveyId)
        .pipe(finalize(() => hideSpinner()))
        .subscribe(() => {
          this.getAllMasterSurveyDetails();
          this.surveyID = 0;
          this.showDeletePopUp = false;
        });
    }
  }

  //Below functions for pagination functionalities

  getCreatedDateFilter(date: string): void {
    this.loading = true;
    this.masterSurveyService.getMasterSurveyPageEnhanced()
      .pipe(finalize(() => this.loading = false))
      .subscribe(survey => this.allSurveyData = date !== '' ?
        survey.filter(data => data.createdDate.split("T")[0] === date) : survey);
  }

  getModifiedDateFilter(date: string): void {
    this.loading = true;
    this.masterSurveyService.getMasterSurveyPageEnhanced()
      .pipe(finalize(() => this.loading = false))
      .subscribe(survey => this.allSurveyData = date !== '' ?
        survey.filter(data => data.lastModifiedDate.split("T")[0] === date) : survey);
  }

  getStatusFilter(status: string): void {
    this.loading = true;
    this.statusToFilter = status;
    this.masterSurveyService.getMasterSurveyPageEnhanced().subscribe(data => {
      this.allSurveyData = status ? data.filter(d => d.status === status) : data;
      this.loading = false;
    });
  }

  checkSurveyIsValid(body: MasterSurvey): boolean {
    body.surveySections.forEach(section => {
      if (section?.section?.sectionName == '') {
        return false;
      }
      section.sectionQuestions.forEach(question => {
        if (question?.question?.questionText == '') {
          return false;
        }
        const questionTypeId = question?.question?.questionType.id;
        if (questionTypeId == this.questionTypeEnum.RadioButton ||
          questionTypeId == this.questionTypeEnum.MultipleChoice ||
          questionTypeId == this.questionTypeEnum.MatrixTable) {
          question?.question?.answerLabels.forEach(answer => {
            if (answer?.labelText == '') {
              return false;
            }
          })
        }
      });
    });
    return true;
  }

  openAddTranslationDialog(masterSurveyId: number): void {
    delete this.selectedNewTranslationLanguage;
    this.addTranslationSurveyId = masterSurveyId;

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.masterSurveyTranslationService.getSupportedTranslationLanguages()
      .pipe(finalize(() => hideSpinner()))
      .subscribe(allSupportedLanguages => {
        const existingTranslations = this.allSurveyData.filter(s => s.id === masterSurveyId)[0].localizationStatuses.map(t => t.locale);
        this.supportedTranslationLanguages = allSupportedLanguages.filter(l => !existingTranslations.includes(l));
        this.showAddTranslationModal = true;
      });
  }

  addTranslation(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));

    this.masterSurveyTranslationService.addTranslation(this.addTranslationSurveyId, this.selectedNewTranslationLanguage)
      .subscribe(translationStatus => {
        this.showAddTranslationModal = false;
        if (translationStatus === LocalizationStatusEnum.PARTIALLY_TRANSLATED) {
          this.showAddTranslationErrorModal = true;
        }
        this.loadSurveyData();
      });
  }

  displayEditTranslationBtn(survey: MasterSurveySimple): boolean {
    if (survey.status !== 'Published') {
      return false;
    }

    return survey.localizationStatuses.filter(l => l.status !== LocalizationStatusEnum.PUBLISHED).length > 0;
  }

  openEditTranslationDialog(masterSurveyId: number): void {
    this.masterSurveyDraftLanguages = [];
    this.editTranslationSurveyId = masterSurveyId;
    this.selectedEditTranslationLanguage = null;

    this.showEditTranslationModal = true;
    showSpinner(this.translate.instant('spinnerLabels.common.loading'))

    this.masterSurveyTranslationService.getTranslatedLanguages(masterSurveyId, LocalizationStatusEnum.DRAFT)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(languages => this.masterSurveyDraftLanguages = languages);
  }

  get localizationStatusEnum(): typeof LocalizationStatusEnum {
    return LocalizationStatusEnum;
  }

  private loadSurveyData(): void {
    this.masterSurveyService.getMasterSurveyPageEnhanced()
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.allSurveyData = data;
        this.allSurveyData.sort((surveyPrevious, surveyNext) =>
          surveyPrevious.lastModifiedDate > surveyNext.lastModifiedDate ? -1 : 1);
        this.loading = false;
      });
  }
}
