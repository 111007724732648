<clr-datagrid *ngIf="!isEditTemplate && !isSendParticipant" [clrDgRowSelection]="false" class="parent-datagrid">
  <clr-dg-column [clrDgField]="'ksaName'">{{"ksaEngagement.ksaAdministration.ksa" | translate}}</clr-dg-column>

  <clr-dg-column>{{"ksaEngagement.emailManagement.emailStatus" | translate}}</clr-dg-column>

  <clr-dg-column [clrDgField]="'geo'">{{"ksaEngagement.ksaAdministration.geo" | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'status'">{{"ksaEngagement.ksaAdministration.status" | translate}}
    <clr-dg-filter>
      <app-status-filter (statusFilter)='filterByStatus($event)' [statusOptions]="surveyStatusOption">
      </app-status-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column>{{"ksaEngagement.emailManagement.template" | translate}}</clr-dg-column>
  <clr-dg-column>{{"ksaEngagement.ksaAdministration.expirationDate" | translate}}</clr-dg-column>
  <clr-dg-column class="action-cell"></clr-dg-column>
  <clr-dg-row *clrDgItems="let ksaEmail of ksaEmailDetails">
    <clr-dg-cell>
      <div>
        <strong>{{"ksaEngagement.ksaAdministration.ksaId" | translate}}:</strong>
        {{ksaEmail.ksaName}}
      </div>
      <div>
        <strong>{{"ksaEngagement.ksaAdministration.customer" | translate}}:</strong>
        {{ksaEmail.customer}}
      </div>
      <div>
        <strong>{{"ksaEngagement.ksaAdministration.team" | translate}}:</strong>
        {{ksaEmail.team}}
      </div>
      <div>
        <strong>{{"ksaEngagement.ksaAdministration.language" | translate}}:</strong>
        {{ksaEmail.language}}
      </div>
    </clr-dg-cell>
    <clr-dg-cell>
      <div>
        {{"ksaEngagement.emailManagement.invited" | translate}}:
        {{ksaEmail?.lastInvitedDate | date}}
        <br>
        {{"ksaEngagement.emailManagement.thanks" | translate}}:
        {{ksaEmail?.lastThanksDate | date}}
        <br>
        {{"ksaEngagement.emailManagement.reminder" | translate}}:
        {{ ksaEmail?.reminderDate | date}}
      </div>
    </clr-dg-cell>
    <clr-dg-cell>
      <div>
        {{"ksaEngagement.ksaAdministration.geo" | translate}}:
        {{ksaEmail.geo}}
      </div>
      <div>
        {{"ksaEngagement.emailManagement.country" | translate}}:
        {{ksaEmail.country}}
      </div>
    </clr-dg-cell>
    <clr-dg-cell>{{ksaEmail.status}}</clr-dg-cell>
    <clr-dg-cell>
      {{"ksaEngagement.emailManagement.invite" | translate}}:
      {{ksaEmail.isInviteAvailable ? "Available" : "Not Available"}}
      <br>
      {{"ksaEngagement.emailManagement.thanks" | translate}}:
      {{ksaEmail.isThanksAvailable ? "Available" : "Not Available"}}
      <br>
      {{"ksaEngagement.emailManagement.reminder" | translate}}:
      {{ksaEmail.isReminderAvailable ? "Available" : "Not Available"}}
    </clr-dg-cell>
    <clr-dg-cell>{{ksaEmail.expirationDate|date}}</clr-dg-cell>
    <clr-dg-cell class="action-cell">
      <clr-dg-action-overflow *hasAccess="functionality.ksaEmailManagement">
        <!--//invite email-->
        <button id="{{btnId.btnEmailManagementAddInviteMail}}" type="button"
                (click)="onEditInviteTemplate(ksaEmail.id)"
                *ngIf="ksaEmail.status !== statusEnum.Closed && ksaEmail.status !== statusEnum.Expired && !ksaEmail.isInviteAvailable && ksaEmail.anonymity_name != anonymityType.Anonymous"
                class="action-item">{{"ksaEngagement.emailManagement.addInviteTemplate" |
          translate}}
        </button>
        <button (click)="onEditInviteTemplate(ksaEmail.id)"
                *ngIf="ksaEmail.status !== statusEnum.Closed && ksaEmail.status !== statusEnum.Expired && ksaEmail.isInviteAvailable && ksaEmail.anonymity_name != anonymityType.Anonymous"
                class="action-item"
                id="{{btnId.btnEmailManagementEditInviteMail}}"
                type="button">{{"ksaEngagement.emailManagement.editInviteTemplate" |
          translate}}
        </button>
        <button (click)="onShowInviteDeleteWarning(ksaEmail?.id)"
                *ngIf="ksaEmail.status !== statusEnum.Closed && ksaEmail.status !== statusEnum.Expired && ksaEmail.isInviteAvailable && ksaEmail.anonymity_name != anonymityType.Anonymous"
                class="action-item"
                id="{{btnId.btnEmailManagementDeleteInviteMail}}"
                type="button">{{"ksaEngagement.emailManagement.deleteInviteTemplate"
          | translate}}
        </button>
        <button (click)="onParticipantInvite(ksaEmail.id)"
                *ngIf="ksaEmail.status === statusEnum.Active && ksaEmail.anonymity_name != anonymityType.Anonymous"
                [disabled]="!ksaEmail.isInviteAvailable"
                class="action-item" id="{{btnId.btnEmailManagementSendInviteMail}}"
                type="button">{{"ksaEngagement.emailManagement.sendEmailInviteToParticipant"
          | translate}}
        </button>

        <!--//thanks email-->
        <button id="{{btnId.btnEmailManagementAddThanksMail}}" type="button"
                (click)="onEditThanksTemplate(ksaEmail.id)"
                *ngIf="ksaEmail.status === statusEnum.Closed && !ksaEmail.isThanksAvailable && ksaEmail.anonymity_name != anonymityType.Anonymous"
                class="action-item">{{"ksaEngagement.emailManagement.addThanksEmail" |
          translate}}
        </button>
        <button id="{{btnId.btnEmailManagementEditThanksMail}}" type="button"
                (click)="onEditThanksTemplate(ksaEmail.id)"
                *ngIf="ksaEmail.status === statusEnum.Closed && ksaEmail.isThanksAvailable && ksaEmail.anonymity_name != anonymityType.Anonymous"
                class="action-item">{{"ksaEngagement.emailManagement.editThanksEmail" |
          translate}}
        </button>
        <button id="{{btnId.btnEmailManagementDeleteThanksMail}}" type="button"
                (click)="onShowThanksDeleteWarning(ksaEmail.id)"
                *ngIf="ksaEmail.status === statusEnum.Closed && ksaEmail.isThanksAvailable && ksaEmail.anonymity_name != anonymityType.Anonymous"
                class="action-item">{{"ksaEngagement.emailManagement.deleteThanksTemplate"
          | translate}}
        </button>
        <button (click)="onParticipantThanks(ksaEmail.id)"
                *ngIf="ksaEmail.status === statusEnum.Closed && ksaEmail.anonymity_name != anonymityType.Anonymous"
                [disabled]="!ksaEmail.isThanksAvailable" class="action-item"
                id="{{btnId.btnEmailManagementSendThanksMail}}"
                type="button">{{"ksaEngagement.emailManagement.sendThanksEmail" |
          translate}}
        </button>

        <!--//anonymous url-->
        <button id="{{btnId.btnEmailManagementAnonymousUrl}}" type="button"
                (click)="getAnonymousUrl(ksaEmail.id)"
                *ngIf="ksaEmail.status == statusEnum.Active && ksaEmail.anonymity_name == anonymityType.Anonymous"
                class="action-item">{{"ksaEngagement.emailManagement.anonymousUrl" |
          translate}}
        </button>

        <!--//demo url-->
        <button (click)="getDemoUrl(ksaEmail.id)" class="action-item" id="{{btnId.btnEmailManagementDemoUrl}}"
                type="button">{{"ksaEngagement.emailManagement.demoURL" | translate}}
        </button>

        <!-- send reminderDate -->
        <button (click)="onParticipantReminder(ksaEmail.id)"
                *ngIf="ksaEmail.status === statusEnum.Active && ksaEmail.anonymity_name != anonymityType.Anonymous"
                class="action-item" id="{{btnId.btnEmailManagementReminderMail}}"
                type="button">{{(ksaEmail.isReminderAvailable ? "ksaEngagement.emailManagement.editReminderTemplate" : "ksaEngagement.emailManagement.addReminderTemplate")
          | translate}}
        </button>
        <button (click)="onSchedulerInviteDeleteWarning(ksaEmail.id)"
                *ngIf="ksaEmail.status === statusEnum.Active  && ksaEmail.isReminderAvailable && ksaEmail.anonymity_name != anonymityType.Anonymous"
                class="action-item" id="{{btnId.btnEmailManagementDeleteReminderMail}}"
                type="button">{{"ksaEngagement.emailManagement.deleteEmailReminderToParticipant"
          | translate}}
        </button>
      </clr-dg-action-overflow>
    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{"ksaEngagement.ksaPerPage" | translate}}
      </clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
      {{"ksaEngagement.ksaAdministration.ksa" | translate}}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<div *ngIf="isEditTemplate">
  <app-compose-email [isScheduler]="isScheduler" [isInvite]="isInvite" [isThanks]="isThanks"
                     [showReminderButton]="showReminderButton"
                     [ksaId]="selectedKsaId"
                     [placeHolderNameList]="placeHolderNameList"
                     (isComposeTemplate)="isComposeTemplateValue()"></app-compose-email>
</div>

<div *ngIf="isSendParticipant">
  <app-send-email-invite [isInvite]="isInvite" [isThanks]="isThanks" [ksaId]="selectedKsaId"
                         (isSendParticipant)="isSendParticipantValue()">
  </app-send-email-invite>
</div>

<clr-modal [(clrModalOpen)]="showInviteDeleteWarning">
  <h3 class="modal-title"> {{'surveyTemplate.mngSurvey.deleteMsg' | translate}}</h3>
  <div class="modal-body">{{'surveyTemplate.mngSurvey.unDeleteMsg' | translate}}</div>
  <div class="modal-footer">
    <button (click)="showInviteDeleteWarning = false"
            class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="onDeleteInviteTemplate()"
            class="btn btn-outline-warning btn-sm">{{'surveyTemplate.mngSurvey.deleteBtn' | translate}}
    </button>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="showSchedulerDeleteWarning">
  <h3 class="modal-title"> {{'surveyTemplate.mngSurvey.deleteMsg' | translate}}</h3>
  <div class="modal-body">{{'surveyTemplate.mngSurvey.unDeleteMsg' | translate}}</div>
  <div class="modal-footer">
    <button (click)="showSchedulerDeleteWarning = false"
            class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="onDeleteSchedulerTemplate()"
            class="btn btn-outline-warning btn-sm">{{'surveyTemplate.mngSurvey.deleteBtn' | translate}}
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showThanksDeleteWarning">
  <h3 class="modal-title"> {{'surveyTemplate.mngSurvey.deleteMsg' | translate}}</h3>
  <div class="modal-body">{{'surveyTemplate.mngSurvey.unDeleteMsg' | translate}}</div>
  <div class="modal-footer">
    <button (click)="showThanksDeleteWarning = false"
            class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="onDeleteThanksTemplate()"
            class="btn btn-outline-warning btn-sm">{{'surveyTemplate.mngSurvey.deleteBtn' | translate}}
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="demoUrlDialog">
  <h3 class="modal-title">{{'ksaEngagement.emailManagement.demoURL' | translate}}</h3>
  <div class="modal-body">
    <textarea class="url-textarea" clrTextarea [(ngModel)]="demoUrl" name="description" #inputTarget></textarea>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="demoUrlDialog = false"
            [ngxClipboard]="inputTarget">{{'admin.copyUrlBtn' | translate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="anonymousUrlDialog">
  <h3 class="modal-title">{{'ksaEngagement.emailManagement.anonymousUrl' | translate}}</h3>
  <div class="modal-body">
    <textarea #inputTargetLink [(ngModel)]="anonymousUrl" class="url-textarea" clrTextarea
              name="description"></textarea>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="anonymousUrlDialog = false"
            [ngxClipboard]="inputTargetLink">{{'admin.copyUrlBtn' | translate}}</button>
  </div>
</clr-modal>
