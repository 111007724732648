import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ButtonId} from '../../../../enums/buttonId.enum';
import {hideSpinner, showSpinner} from '../../../../common/spinner';
import {finalize} from 'rxjs/operators';
import {PersonalData} from '../../../../models/scorecard.model';
import {TranslateService} from '@ngx-translate/core';
import {ScorecardsService} from '../../../../services/scorecards.service';
import {RegexValidation} from '../../../constants/regex-validation.constants';

@Component({
  selector: 'app-scorecard-cc-modal',
  templateUrl: './scorecard-cc-modal.component.html',
  styleUrls: ['./scorecard-cc-modal.component.scss']
})
export class ScorecardCcModalComponent implements OnInit {


  constructor(private fb: FormBuilder,
              private readonly translateService: TranslateService,
              private readonly scorecardsService: ScorecardsService) {
  }

  @Input() isOpen: boolean;

  @Input() ksaId: number;

  @Input() participantIds: number[];

  @Output() completion: EventEmitter<PersonalData[]> = new EventEmitter<PersonalData[]>();

  @Output() errorOnSend: EventEmitter<string> = new EventEmitter<string>();

  @Output() scorecardCcModalClose: EventEmitter<string> = new EventEmitter<string>();

  emailForm: FormGroup;
  btnId: typeof ButtonId = ButtonId;

  private static extractEmails(emails: string): string[] {
    return emails?.split(',').map((email: string) => email.trim()).filter(email => !!email) || [];
  }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      emails: ['', [this.emailFormatValidator]]
    });
  }

  emailFormatValidator(control: FormGroup): { [key: string]: any } | null {
    if (control.value) {
      const emails = ScorecardCcModalComponent.extractEmails(control.value);
      const isValid = emails.every(email => RegexValidation.EMAIL_REGEX.test(email));
      return isValid ? null : {emailFormat: true};
    }
    return null;
  }


  onSubmit(): void {
    if (this.emailForm.valid) {
      const emails = ScorecardCcModalComponent.extractEmails(this.emailForm.value.emails);
      this.sendScorecard(emails);
    }
  }


  onClose(): void {
    this.scorecardCcModalClose.emit();
  }

  private sendScorecard(ccList: string[]): void {
    if (!this.ksaId || !this.participantIds || this.participantIds.length === 0) {
      this.errorOnSend.emit(this.translateService.instant('analysisAndReports.scorecards.ksaAndParticipantRequired'));
      return;
    }
    showSpinner(this.translateService.instant('spinnerLabels.scorecards.sending'));
    this.scorecardsService.sendScorecards(this.ksaId, this.participantIds, ccList)
      .pipe(finalize(() => hideSpinner()))
      .subscribe((updatedPersonalData: PersonalData[]) => {
          this.completion.emit(updatedPersonalData);
        },
        error => {
          this.errorOnSend.emit(error?.error?.message);
        });
  }
}
