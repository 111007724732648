/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {UserCacheService} from "../../../services/user-cache.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  username: any;

  constructor(private authService: AuthService, private userCacheService: UserCacheService) {
  }

  ngOnInit(): void {
    const data = this.userCacheService.getLoginDataFromLocalStorage();
    this.username = data?.email;
  }

  logout() {
    this.authService.logout();
  }

}
