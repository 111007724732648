<div class="button-container" *hasAccess="functionality.manageCourse" >
  <button id="{{btnId.btnMngCourseAdd}}" (click)="onSetAddCourseFormOpen()" class="btn btn-sm btn-primary">
    {{'admin.coursesAndSkills.courses.addCourseBtn' | translate}}
  </button>
</div>

<div *ngIf="isSavedResponse" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">{{'admin.coursesAndSkills.columnSavedMsg' | translate}}</span>
    </div>
  </div>
</div>

<clr-modal (clrModalOpenChange)="onCancelAdding(addForm)" [(clrModalOpen)]="addCourseFormOpen"
           [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{'admin.coursesAndSkills.courses.addCourseModalTitle' | translate}}</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>
    <form #addForm="ngForm" (ngSubmit)="onAddCourse(addForm)" autocomplete="off" clrForm clrLabelSize="6">
      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.courseId' | translate}}*</label>
        <input [(ngModel)]="currentCourse.courseId" class="input-container" clrInput name="courseId"
              placeholder="{{'placeholder.courseId' | translate}}" required (change)="onInputCourseId($event.target.value, undefined)"
               type="text"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseIdRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <span *ngIf="isDuplicateCourseId" id="duplicateCourseIdMessage">{{'errorMsg.courseIdUnique' | translate}}</span>
      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.courseName' | translate}}*</label>
        <input [(ngModel)]="currentCourse.courseName" class="input-container" clrInput name="courseName"
              placeholder="{{'placeholder.courseName' | translate}}" required
               type="text"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseNameRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-textarea-container>
        <label>{{'admin.coursesAndSkills.courses.description' | translate}}</label>
        <textarea [(ngModel)]="currentCourse.courseDescription" clrTextarea
                  maxlength="1024" name="courseDescription"
                  placeholder="{{'placeholder.courseDescription' | translate}}"  rows="1"></textarea>
      </clr-textarea-container>
      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.version' | translate}}</label>
        <input [(ngModel)]="currentCourse.courseVersion" class="input-container" clrInput name="courseVersion"
               pattern="{{validationPattern}}" placeholder="{{'placeholder.courseVersion' | translate}}"
               type="text"/>
        <clr-control-error *clrIfError="'pattern'">{{currentCourse.courseVersion.length > 10
          ? ('errorMsg.maxCharacterLength' | translate)
          : ('errorMsg.specialCharacterErrorMsg' | translate)}}
        </clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.url' | translate}}</label>
        <input [(ngModel)]="currentCourse.courseUrl" class="input-container" clrInput name="courseUrl"
               pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
               placeholder="{{'placeholder.courseUrl' | translate}}" type="url"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseUrlRequired' | translate}}</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">{{'errorMsg.invalidUrl' | translate}}</clr-control-error>
      </clr-input-container>
      <div class="clr-row course-bundle-container">
        <div class="clr-col-3 course-bundle-label-container">
          <label class="clr-control-label course-bundle-label">{{'admin.coursesAndSkills.courses.isThisACourseBundle'
            | translate}}?</label>
        </div>
        <div class="clr-col-9 flex">
          <input class="flex-child" [(ngModel)]="courseBundleIsChecked" clrCheckbox name="isCourseBundle"
                 type="checkbox"
                 value="option1"/>
          <clr-tooltip class="clr-col-1 flex-child tooltip tooltip-xs tooltip-trigger toolTip-icon">
            <clr-icon shape="help" clrTooltipTrigger badge="info"></clr-icon>
            <clr-tooltip-content>
              {{'admin.coursesAndSkills.courses.createBundleHelp' |translate}}
            </clr-tooltip-content>
          </clr-tooltip>
        </div>
      </div>
      <div *ngIf="courseBundleIsChecked">
        <div class="button-container clr-row">
          <div class="clr-col-6">
            <span class="clr-control-label">{{'admin.coursesAndSkills.courses.selectChildCoursesMsg' | translate}}*
            </span>
          </div>
        </div>
        <clr-datagrid [(clrDgSelected)]="currentCourse.childCourses"
                      class="datagrid-compact child-course-datagrid popup-datagrid">
          <clr-dg-column [clrDgField]="'courseName'">{{'admin.coursesAndSkills.courses.courseName' | translate}}
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'courseVersion'" class="child-datagrid-second-column">
            {{'admin.coursesAndSkills.courses.version' | translate}}</clr-dg-column>
          <clr-dg-row *clrDgItems="let course of coursesNotBundle" [clrDgItem]="course">
            <clr-dg-cell>{{course.courseName}}</clr-dg-cell>
            <clr-dg-cell class="child-datagrid-second-column">{{course.courseVersion}}</clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
      <div class="clr-row pre-requisite-outer-container">
        <div class="clr-col-3">
          <label class="clr-control-label pre-requisite-label">{{'admin.coursesAndSkills.courses.trainingPreReqsMsg' |
            translate}}</label>
        </div>
        <div class="clr-col-5 outer-datalist-container">
          <clr-datalist-container class="datalist-container">
            <input [(ngModel)]="trainingPreRequisiteToAdd" class="datalist-input" clrDatalistInput
                   name="trainingPreRequisiteToAdd" name="Option"
                   placeholder="{{'placeholder.typeToAdd' | translate}}"/>
            <datalist>
              <option *ngFor="let item of courses.content"
                      [value]="item.courseVersion === '' ? item.courseName : item.courseName + '(' +item.courseVersion +')'">
              </option>
            </datalist>
          </clr-datalist-container>
        </div>
        <div class="clr-col-4 pre-req-btn flex">
          <button (click)="onAddValue()" [disabled]="trainingPreRequisiteToAdd===''"
                  class="btn btn-sm btn-outline btn-icon plus-icon" type="button">
            <clr-icon shape="plus"></clr-icon>
          </button>
          <clr-tooltip class="clr-col-1 flex-child tooltip tooltip-xs tooltip-trigger toolTip-icon">
            <clr-icon shape="help" clrTooltipTrigger badge="info"></clr-icon>
            <clr-tooltip-content>
              {{'admin.coursesAndSkills.courses.trainingPrerequisiteHelp' |translate}}
            </clr-tooltip-content>
          </clr-tooltip>
        </div>
      </div>
      <div class="pre-requisite-container clear">
        <div *ngFor="let preRequisite of currentCourse.preRequisiteCourses; let prequisiteCourseIndex = index"
             class="pre-requisite-sub-container">
          <span class="pre-requisite-name">{{preRequisite.trainingPreRequisiteCourse}}
            <clr-icon (click)="onRemovePrequisiteCourse(prequisiteCourseIndex)" class="pre-requisite-delete-button"
                      shape="times">
            </clr-icon>
          </span>
        </div>
      </div>
      <div class="add-edit-btn-container clear">
        <button (click)="onCancelAdding(addForm)" class="btn btn-sm btn-outline" type="button">{{'admin.cancelBtn' |
          translate}}</button>
        <button
          [disabled]="addForm.invalid ||isDuplicateCourseId || (courseBundleIsChecked && currentCourse.childCourses.length === 0)"
          class="btn btn-sm btn-primary" type="submit">{{'admin.addBtn'
          | translate}}</button>
      </div>
    </form>
  </div>
</clr-modal>

<clr-modal (clrModalOpenChange)="onCancelNewVersion(editForm)"
           [(clrModalOpen)]="editCourseFormOpen"
           [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{'admin.rolesAndUser.user.editCourseModalTitle' | translate}}</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>

    <form #editForm="ngForm" (ngSubmit)="onEditCourse(editForm)" autocomplete="off" clrForm clrLabelSize="6">

      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.courseId' | translate}}*</label>
        <input [(ngModel)]="currentCourse.courseId" class="input-container" clrInput name="courseId"
              placeholder="{{'placeholder.courseId' | translate}}" required (change)="onInputCourseId($event.target.value, currentCourse.id)"
               type="text"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseIdRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <span *ngIf="isDuplicateCourseId" id="duplicateCourseIdMessage">{{'errorMsg.courseIdUnique' | translate}}</span>

      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.courseName' | translate}}*</label>
        <input [(ngModel)]="currentCourse.courseName" class="input-container" clrInput name="courseName"
              placeholder="{{'placeholder.courseName' | translate}}" required
               type="text"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseNameRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-textarea-container>
        <label>{{'admin.coursesAndSkills.courses.description' | translate}}</label>
        <textarea [(ngModel)]="currentCourse.courseDescription" clrTextarea
                  maxlength="1024" name="courseDescription"
                  placeholder="{{'placeholder.courseDescription' | translate}}"  rows="1"></textarea>
      </clr-textarea-container>
      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.version' | translate}}</label>
        <input [(ngModel)]="currentCourse.courseVersion" class="input-container" clrInput name="courseVersion"
               pattern="{{validationPattern}}" placeholder="{{'placeholder.courseVersion' | translate}}"
               type="text"/>
        <clr-control-error *clrIfError="'pattern'">{{currentCourse.courseVersion.length > 10
          ? ('errorMsg.maxCharacterLength' | translate)
          : ('errorMsg.specialCharacterErrorMsg' | translate)}}
        </clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.url' | translate}}</label>
        <input [(ngModel)]="currentCourse.courseUrl" class="input-container" clrInput name="courseUrl"
               pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
               placeholder="{{'placeholder.courseUrl' | translate}}" type="url"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseVUrlRequired' | translate}}</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">{{'errorMsg.invalidUrl' | translate}}</clr-control-error>
      </clr-input-container>
      <div class="clr-row course-bundle-container">
        <div class="clr-col-3 course-bundle-label-container">
          <label class="clr-control-label course-bundle-label">{{'admin.coursesAndSkills.courses.isThisACourseBundle'
            | translate}}?</label>
        </div>
        <div class="clr-col-9 flex">
          <input class="flex-child" [(ngModel)]="courseBundleIsChecked" clrCheckbox name="isCourseBundle"
                 type="checkbox"
                 value="option1"/>
          <clr-tooltip class="clr-col-1 flex-child tooltip tooltip-xs tooltip-trigger toolTip-icon">
            <clr-icon shape="help" clrTooltipTrigger badge="info"></clr-icon>
            <clr-tooltip-content>
              {{'admin.coursesAndSkills.courses.createBundleHelp' |translate}}
            </clr-tooltip-content>
          </clr-tooltip>
        </div>
      </div>
      <div *ngIf="courseBundleIsChecked">
        <div class="button-container clr-row">
          <div class="clr-col-6">
            <span class="clr-control-label">{{'admin.coursesAndSkills.courses.selectChildCoursesMsg' | translate}}*
            </span>
          </div>
        </div>
        <clr-datagrid [(clrDgSelected)]="currentCourse.childCourses"
                      class="datagrid-compact child-course-datagrid popup-datagrid">
          <clr-dg-column [clrDgField]="'courseName'">{{'admin.coursesAndSkills.courses.courseName' | translate}}
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'version'" class="child-datagrid-second-column">
            {{'admin.coursesAndSkills.courses.version' | translate}}</clr-dg-column>
          <clr-dg-row *clrDgItems="let course of allCourses" [clrDgItem]="course">
            <clr-dg-cell>{{course.courseName}}</clr-dg-cell>
            <clr-dg-cell class="child-datagrid-second-column">{{course.version}}</clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
      <div class="clr-row pre-requisite-outer-container">
        <div class="clr-col-3">
          <label class="clr-control-label pre-requisite-label">{{'admin.coursesAndSkills.courses.trainingPreReqsMsg' |
            translate}}</label>
        </div>
        <div class="clr-col-5 outer-datalist-container">
          <clr-datalist-container class="datalist-container">
            <input [(ngModel)]="trainingPreRequisiteToAdd" class="datalist-input" clrDatalistInput
                   name="trainingPreRequisiteToAdd" name="Option"
                   placeholder="{{'placeholder.typeToAdd' | translate}}"/>
            <datalist>
              <option *ngFor="let item of courses.content"
                      [value]="item.courseVersion === '' ? item.courseName : item.courseName + '(' +item.courseVersion +')'">
              </option>
            </datalist>
          </clr-datalist-container>
        </div>
        <div class="clr-col-4 pre-req-btn flex">
          <button (click)="onAddValue()" [disabled]="trainingPreRequisiteToAdd===''"
                  class="btn btn-sm btn-outline btn-icon plus-icon" type="button">
            <clr-icon shape="plus"></clr-icon>
          </button>
          <clr-tooltip class="clr-col-1 flex-child tooltip tooltip-xs tooltip-trigger toolTip-icon">
            <clr-icon shape="help" clrTooltipTrigger badge="info"></clr-icon>
            <clr-tooltip-content>
              {{'admin.coursesAndSkills.courses.trainingPrerequisiteHelp' |translate}}
            </clr-tooltip-content>
          </clr-tooltip>
        </div>
      </div>
      <div class="pre-requisite-container clear">
        <div *ngFor="let preRequisite of currentCourse.preRequisiteCourses; let prequisiteCourseIndex = index"
             class="pre-requisite-sub-container">
          <span class="pre-requisite-name">{{preRequisite.trainingPreRequisiteCourse}}
            <clr-icon (click)="onRemovePrequisiteCourse(prequisiteCourseIndex)" class="pre-requisite-delete-button"
                      shape="times">
            </clr-icon>
          </span>
        </div>
      </div>
      <div class="add-edit-btn-container clear">
        <button (click)="onCancelEditing(editForm);" class="btn btn-sm btn-outline">{{'admin.cancelBtn' |
          translate}}</button>
        <button
          [disabled]="editForm.invalid || isDuplicateCourseId || (courseBundleIsChecked && currentCourse.childCourses.length === 0)"
          class="btn btn-sm btn-primary" type="submit">{{'admin.saveBtn'
          | translate}}</button>
      </div>
    </form>
  </div>
</clr-modal>

<clr-modal (clrModalOpenChange)="onCancelAdding(newVersionForm)"
           [(clrModalOpen)]="newVersionFormOpen"
           [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{'admin.coursesAndSkills.courses.createNewVersionModalTitle' | translate}}</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>

    <form #newVersionForm="ngForm" (ngSubmit)="onCreateNewVersion(newVersionForm)" autocomplete="off" clrForm
          clrLabelSize="6">
      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.courseId' | translate}}*</label>
        <input [(ngModel)]="currentCourse.courseId" class="input-container" clrInput name="courseId"
              placeholder="{{'placeholder.courseId' | translate}}" required (change)="onInputCourseId($event.target.value, currentCourse.id)"
               type="text"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseIdRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <span *ngIf="isDuplicateCourseId" id="duplicateCourseIdMessage">{{'errorMsg.courseIdUnique' | translate}}</span>

      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.courseName' | translate}}*</label>
        <input [(ngModel)]="currentCourse.courseName" class="input-container" clrInput name="courseName"
               placeholder="{{'placeholder.courseName' | translate}}" required
               type="text"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseNameRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-textarea-container>
        <label>{{'admin.coursesAndSkills.courses.description' | translate}}</label>
        <textarea [(ngModel)]="currentCourse.courseDescription" clrTextarea
                  maxlength="1024" name="courseDescription"
                  placeholder="{{'placeholder.courseDescription' | translate}}" rows="1"></textarea>
      </clr-textarea-container>
      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.version' | translate}}*</label>
        <input [(ngModel)]="currentCourse.courseVersion" class="input-container" clrInput name="courseVersion"
               pattern="{{validationPattern}}" placeholder="{{'placeholder.courseVersion' | translate}}" required
               type="text"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseVersionRequired' | translate}}</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">{{currentCourse.courseVersion.length > 10
          ? ('errorMsg.maxCharacterLength' | translate)
          : ('errorMsg.specialCharacterErrorMsg' | translate)}}
        </clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>{{'admin.coursesAndSkills.courses.url' | translate}}</label>
        <input [(ngModel)]="currentCourse.courseUrl" class="input-container" clrInput name="courseUrl"
               pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
               placeholder="{{'placeholder.courseUrl' | translate}}" type="url"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.courseUrlRequired' | translate}}</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">{{'errorMsg.invalidUrl' | translate}}</clr-control-error>
      </clr-input-container>
      <div class="clr-row course-bundle-container">
        <div class="clr-col-3 course-bundle-label-container">
          <label class="clr-control-label course-bundle-label">{{'admin.coursesAndSkills.courses.isThisACourseBundle'
            | translate}}?</label>
        </div>
        <div class="clr-col-9 flex">
          <input class="flex-child" [(ngModel)]="courseBundleIsChecked" clrCheckbox name="isCourseBundle"
                 type="checkbox"
                 value="option1"/>
          <clr-tooltip class="clr-col-1 flex-child tooltip tooltip-xs tooltip-trigger toolTip-icon">
            <clr-icon shape="help" clrTooltipTrigger badge="info"></clr-icon>
            <clr-tooltip-content>
              {{'admin.coursesAndSkills.courses.createBundleHelp' |translate}}
            </clr-tooltip-content>
          </clr-tooltip>
        </div>
      </div>
      <div *ngIf="courseBundleIsChecked">
        <div class="button-container clr-row">
          <div class="clr-col-6">
            <span class="clr-control-label">{{'admin.coursesAndSkills.courses.selectChildCoursesMsg' | translate}}*
            </span>
          </div>
        </div>
        <clr-datagrid [(clrDgSelected)]="currentCourse.childCourses"
                      class="datagrid-compact child-course-datagrid popup-datagrid">
          <clr-dg-column [clrDgField]="'courseName'">{{'admin.coursesAndSkills.courses.courseName' | translate}}
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'version'" class="child-datagrid-second-column">
            {{'admin.coursesAndSkills.courses.version' | translate}}</clr-dg-column>
          <clr-dg-row *clrDgItems="let course of allCourses" [clrDgItem]="course">
            <clr-dg-cell>{{course.courseName}}</clr-dg-cell>
            <clr-dg-cell class="child-datagrid-second-column">{{course.version}}</clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
      <div class="clr-row pre-requisite-outer-container">
        <div class="clr-col-3">
          <label class="clr-control-label pre-requisite-label">{{'admin.coursesAndSkills.courses.trainingPreReqsMsg' |
            translate}}</label>
        </div>
        <div class="clr-col-5 outer-datalist-container">
          <clr-datalist-container class="datalist-container">
            <input [(ngModel)]="trainingPreRequisiteToAdd" class="datalist-input" clrDatalistInput
                   name="trainingPreRequisiteToAdd" name="Option"
                   placeholder="{{'placeholder.typeToAdd' | translate}}"/>
            <datalist>
              <option *ngFor="let item of courses.content"
                      [value]="item.courseVersion === '' ? item.courseName : item.courseName + '(' +item.courseVersion +')'">
              </option>
            </datalist>
          </clr-datalist-container>
        </div>
        <div class="clr-col-4 pre-req-btn flex">
          <button (click)="onAddValue()" [disabled]="trainingPreRequisiteToAdd===''"
                  class="btn btn-sm btn-outline btn-icon plus-icon" type="button">
            <clr-icon shape="plus"></clr-icon>
          </button>
          <clr-tooltip class="clr-col-1 flex-child tooltip tooltip-xs tooltip-trigger toolTip-icon">
            <clr-icon shape="help" clrTooltipTrigger badge="info"></clr-icon>
            <clr-tooltip-content>
              {{'admin.coursesAndSkills.courses.trainingPrerequisiteHelp' |translate}}
            </clr-tooltip-content>
          </clr-tooltip>
        </div>
      </div>
      <div class="pre-requisite-container clear">
        <div *ngFor="let preRequisite of currentCourse.preRequisiteCourses; let prequisiteCourseIndex = index"
             class="pre-requisite-sub-container">
          <span class="pre-requisite-name">{{preRequisite.trainingPreRequisiteCourse}}
            <clr-icon (click)="onRemovePrequisiteCourse(prequisiteCourseIndex)" class="pre-requisite-delete-button"
                      shape="times">
            </clr-icon>
          </span>
        </div>
      </div>
      <div class="add-edit-btn-container clear">
        <button (click)="onCancelNewVersion(newVersionForm);" class="btn btn-sm btn-outline">{{'admin.cancelBtn' |
          translate}}</button>
        <button
          [disabled]="newVersionForm.invalid || isDuplicateCourseId || (courseBundleIsChecked && currentCourse.childCourses.length === 0)"
          class="btn btn-sm btn-primary" type="submit">{{'admin.createBtn'
          | translate}}</button>
      </div>
    </form>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showDeleteCoursePopUp" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'admin.coursesAndSkills.courses.deleteCourse' | translate}}?</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>

    {{'admin.roleProfilesAndGroups.roleProfiles.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteCoursePopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteCourse()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>

<clr-datagrid class="parent-datagrid" (clrDgRefresh)="refresh($event)">
  <ng-container *ngFor="let column of courseColumnHeaderList; let i = index">
    <clr-dg-column *ngIf="column.columnFilterName" [clrDgField]="column.columnFilterName">
      <ng-template clrDgHideableColumn [(clrDgHidden)]="courseColumnHeaderList[i].isHide">
        {{column.columnName}}
      </ng-template>
      <clr-dg-filter *ngIf="column.columnFilterName == 'status'">
        <app-status-filter [statusOptions]="surveyStatusOption" (statusFilter)='getStatusFilter($event)'>
        </app-status-filter>
      </clr-dg-filter>
    </clr-dg-column>
      <clr-dg-column *ngIf="!column.columnFilterName" class="width-min">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="courseColumnHeaderList[i].isHide">
          {{column.columnName}}
        </ng-template>
        <clr-dg-filter *ngIf="column.columnFilterName == 'status'">
          <app-status-filter [statusOptions]="surveyStatusOption" (statusFilter)='getStatusFilter($event)'>
          </app-status-filter>
        </clr-dg-filter>
      </clr-dg-column>
  </ng-container>
  <clr-dg-column class="action-cell"></clr-dg-column>
  <clr-dg-row *ngFor="let course of courses?.content" [clrDgItem]="course">
    <clr-dg-cell>{{course?.courseId}}</clr-dg-cell>
    <clr-dg-cell>{{course?.courseName}}</clr-dg-cell>
    <clr-dg-cell class="description-tooltip-cell width-min">
      <clr-tooltip class=" tooltip tooltip-xs tooltip-trigger description-tooltip"  >
        <span clrTooltipTrigger > {{course?.courseDescription}}</span>
        <clr-tooltip-content clrSize="lg" *clrIfOpen clrPosition="top-right">
          {{course?.courseDescription}}
        </clr-tooltip-content>
      </clr-tooltip>
      </clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let item of course?.childCourses">
        <li>{{item?.childCourse?.courseName}}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let preReqCourses of course?.preRequisiteCourses">
        <li>{{preReqCourses?.trainingPreRequisiteCourse}}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell>{{course?.courseVersion}}</clr-dg-cell>
    <clr-dg-cell>{{course?.status}}</clr-dg-cell>
    <clr-dg-cell class="action-cell">
      <clr-dg-action-overflow *hasAccess="functionality.manageCourse">
        <button id="{{btnId.btnMngCourseEdit}}" type="button" class="action-item" *ngIf="(course.status === 'Active')"
                (click)="onEditCourseDetails(course);">{{'admin.editBtn' | translate}}</button>
        <button id="{{btnId.btnMngCourseArchive}}" type="button" class="action-item"
                *ngIf="(course.status === 'Active')"
                (click)="onArchiveCourse(course.id)">{{'admin.archiveBtn' | translate}}</button>
        <button id="{{btnId.btnMngCourseActivate}}" type="button" class="action-item"
                *ngIf="(course.status === 'Archived')"
                (click)="onActivateCourse(course.id)">{{'admin.activateBtn' | translate}}</button>
        <button id="{{btnId.btnMngCourseNewVersion}}" type="button" class="action-item"
                *ngIf="(course.status === 'Active')"
                (click)="onSelectNewVersionDetails(course);">{{'admin.newVersionBtn' | translate}}</button>
        <button id="{{btnId.btnMngCourseDelete}}" type="button" class="action-item" *ngIf="(course.status === 'Active')"
                (click)="onShowDeleteCoursePopUp(course?.id)">{{'admin.deleteBtn' | translate}}</button>

      </clr-dg-action-overflow>
    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-column-toggle>
      <clr-dg-column-toggle-title class="column-toggle-title">Columns
        <clr-icon role="button" shape="floppy" title="Save" (click)="onSaveResponseColumns()" class="save-col-icon"> </clr-icon>
      </clr-dg-column-toggle-title>
    </clr-dg-column-toggle>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Courses per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} courses
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
