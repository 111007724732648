<form clrForm #viewForm="ngForm" autocomplete="off" class="clr-form clr-row" clrLabelSize="4">
  <div class="clr-col-10 clr-row">
    <clr-alert class="clr-col-10" clrAlertType="danger" *ngIf="errorMessage" [clrAlertClosable]="true"
               [clrAlertSizeSmall]="false">
      <clr-alert-item>{{errorMessage}}</clr-alert-item>
    </clr-alert>
    <clr-alert class="clr-col-10" clrAlertType="success" *ngIf="isIndividualScorecardSend" [clrAlertClosable]="true"
               [clrAlertSizeSmall]="false">
      <clr-alert-item>{{'analysisAndReports.scorecards.individualScorecardSendingMessage' | translate}}</clr-alert-item>
    </clr-alert>
  </div>
  <div class="clr-col-7 clr-row clr-align-items-center">
    <clr-select-container class="clr-col-12">
      <label class="clr-col-2">{{'analysisAndReports.scorecards.ksaId' | translate}}*</label>
      <select id="select-ksa" class="clr-col-6" (change)="onSelectKsaDetails($event.target.value)" clrSelect>
        <option disabled selected value="">
          {{'analysisAndReports.scorecards.selectKsa'| translate}}</option>
        <option *ngFor="let ksaDetails of closedKsaList" [value]="ksaDetails.id">
          {{ksaDetails.ksaName}}
        </option>
      </select>
      <clr-control-error>{{'errorMsg.ksaIsRequired'|translate}}</clr-control-error>
    </clr-select-container>
  </div>
  <div class="clr-col-5 justify-right">
    <button style="margin-top: 20px;" id="{{btnId.btnSendScorecards}}" class="btn btn-sm btn-primary"
            *ngIf="hasWritePermission"
            (click)="confirmSendingScorecard=true"
            [disabled]="!hasSelectedUnsentScorecards()">
      {{'analysisAndReports.scorecards.sendScorecards' | translate}}
    </button>
    <button style="margin-top: 20px;" id="{{btnId.btnDownloadScorecards}}" class="btn btn-sm btn-primary"
            (click)="downloadScorecards()"
            [disabled]="!personalData || personalData.length===0">
      {{(selected.length === 0 ? 'analysisAndReports.scorecards.downloadAllScorecards' : 'analysisAndReports.scorecards.downloadSelectedScorecards')| translate}}
    </button>
  </div>
</form>
<clr-datagrid *ngIf="showTable" [(clrDgSelected)]="selected" class="parent-datagrid">
  <clr-dg-column class="action-cell"></clr-dg-column>
  <clr-dg-column [clrDgField]="'firstName'">{{'analysisAndReports.scorecards.firstName' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'lastName'">{{'analysisAndReports.scorecards.lastName' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'email'">{{'analysisAndReports.scorecards.email' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'jobTitle'">{{'analysisAndReports.scorecards.jobTitle' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'manager'">{{'analysisAndReports.scorecards.manager' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'company'">{{'analysisAndReports.scorecards.company' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'team'">{{'analysisAndReports.scorecards.team' | translate}}</clr-dg-column>
  <clr-dg-column
    [clrDgField]="'scorecardStatus'">{{'analysisAndReports.scorecards.scorecardStatus' | translate}}</clr-dg-column>
  <clr-dg-row *clrDgItems="let personalData of personalData" [clrDgItem]="personalData">
    <clr-dg-cell class="action-cell">
      <button id="view-button" (click)="onOpenScorecardView(personalData)">
        <clr-icon shape="eye"></clr-icon>
      </button>
    </clr-dg-cell>
    <clr-dg-cell>{{personalData?.firstName || ''}}</clr-dg-cell>
    <clr-dg-cell>{{personalData?.lastName || ''}}</clr-dg-cell>
    <clr-dg-cell>{{personalData?.email || ''}}</clr-dg-cell>
    <clr-dg-cell>{{personalData?.jobTitle || ''}}</clr-dg-cell>
    <clr-dg-cell>{{personalData?.manager || ''}}</clr-dg-cell>
    <clr-dg-cell>{{personalData?.company || ''}}</clr-dg-cell>
    <clr-dg-cell>{{personalData?.team || ''}}</clr-dg-cell>
    <clr-dg-cell>{{personalData?.scorecardStatus || ''}}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
      <clr-dg-page-size
        [clrPageSizeOptions]="[10,20,50,100]">{{'ksaEngagement.ksaPerPage'|translate}}</clr-dg-page-size>
      {{pagination.firstItem + 1}}
      - {{pagination.lastItem + 1}} {{'admin.of'|translate}} {{pagination.totalItems}} {{'ksaEngagement.ksa'|translate}}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<clr-modal *ngIf="!!selectedParticipant" [clrModalOpen]="!!selectedParticipant"
           (clrModalOpenChange)="onCancelScorecardView()"
           [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{'analysisAndReports.scorecards.scorecardPreview' | translate}}</h3>
  <div class="modal-body">
    <scorecard-modal [participantId]="selectedParticipant.id" [ksaId]="selectedKsaId"></scorecard-modal>
  </div>
  <div class="modal-footer">
    <button id="{{btnId.btnCancelScorecard}}" class="btn btn-sm" (click)="onCancelScorecardView()">
      {{'common.cancel' | translate}}
    </button>
    <button id="{{btnId.btnNextScorecard}}" class="btn btn-sm btn-primary"
            *ngIf="hasWritePermission"
            (click)="openCcModal();"
            [disabled]="!isSendAvailable(selectedParticipant)">
      {{'analysisAndReports.scorecards.sendScorecardBtn' | translate}}
    </button>
  </div>
</clr-modal>

<clr-modal *ngIf="confirmSendingScorecard" [(clrModalOpen)]="confirmSendingScorecard"
           [clrModalClosable]="true">
  <h3 class="modal-title">{{'analysisAndReports.scorecards.sendScorecards' | translate}}</h3>
  <div class="modal-body">
    <p>{{'analysisAndReports.scorecards.reviewMessage' | translate}}</p>
    <p>{{'analysisAndReports.scorecards.scorecardIncludeMessage' | translate}}</p>
    <ul style="margin-left:10px">
      <li>{{'analysisAndReports.scorecards.personalDetails' | translate}}</li>
      <li>{{'analysisAndReports.scorecards.summaryNotes' | translate}}</li>
      <li>{{'analysisAndReports.scorecards.skillsAndTrainingRecommendations' | translate}}</li>
    </ul>
  </div>
  <div class="modal-footer">
    <button id="{{btnId.btnCancelScorecard}}" class="btn btn-sm" (click)="confirmSendingScorecard=false">
      {{'common.cancel' | translate}}
    </button>
    <button id="{{btnId.btnNextScorecard}}" class="btn btn-sm btn-primary"
            (click)="openCcModal(); confirmSendingScorecard=false;"
            [disabled]="!hasSelectedUnsentScorecards()">
      {{'analysisAndReports.scorecards.nextBtn' | translate}}
    </button>
  </div>
</clr-modal>

<app-scorecard-cc-modal *ngIf="isCcListOpen"
                        [isOpen]="isCcListOpen"
                        [ksaId]="selectedKsaId"
                        [participantIds]="getSelectedUnsentScorecardsIds()"
                        (completion)="onSendCompletion($event); closeCcModal()"
                        (errorOnSend)="onSendError($event); closeCcModal()"
                        (scorecardCcModalClose)="closeCcModal()"></app-scorecard-cc-modal>
