/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/

import {Component, Input, OnInit} from '@angular/core';
import {KsaAdministrationService} from '../../../services/ksa-administration.service';
import {NgForm} from '@angular/forms';
import {RoleProfileStandardProperties} from '../../../enums/ksa-role-profile.enum';
import {
  ActiveSkill,
  Ksa,
  KsaRoleSkill,
  RoleProfileResult,
  RolesReceivedByKsaIdSetIdList,
  SkillList,
  SkillListArray
} from '../../../models/ksa-role-profile-skill.model';
import {KsaRoleProfileService} from '../../../services/ksa-role-profile.service';
import {SkillsService} from '../../../services/skills.service';
import {AnalysisShareService} from '../../../services/analysis-share.service';
import {
  ContentEntity,
  KsaRoleProfileGroupsEntity,
  KsaRoleProfilesEntity
} from '../../../models/ksa-role-profile-set.model';
import {RoleProfileAnalysis} from '../../../models/respose-data.model';
import {Functionality} from "../../../enums/functionality.enum";
import {RoleProfileInitialize} from '../../../models/ksa-role-profile-initialize.model';
import {RoleProfileSkillMapsEntity} from '../../../models/role-profile-page.model';
import {TranslateService} from "@ngx-translate/core";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from 'rxjs/operators';
import {DataGridTypeEnum} from "../../../enums/data-grid-type.enum";
import {Options} from "@angular-slider/ngx-slider";
import {ColorCode} from "../../../models/color-data.model";
import {ColorCodeService} from "../../../services/color-code.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-role-profiling',
  templateUrl: './role-profiling.component.html',
  styleUrls: ['./role-profiling.component.scss']
})
export class RoleProfilingComponent implements OnInit {

  addRoleProfileFormOpen: boolean = false;
  errorMsg: string = '';
  ksaSets: ContentEntity[];
  importedRoleProfileGroupSelected: KsaRoleProfileGroupsEntity = null;
  rolesReceivedByKsaIdSetId: (RolesReceivedByKsaIdSetIdList)[] = [];
  deleteRoleProfileId: number;
  showDeleteWarning: boolean = false;
  selectedRoles: RoleProfileResult[] = [];
  roleProfileEnum: typeof RoleProfileStandardProperties = RoleProfileStandardProperties;
  editRoleProfile: RoleProfileResult = {
    id: 0,
    ksaRoleProfileName: '',
    description: '',
    profileSetId: 0,
    profileSetName: '',
    roleProfileGroupID: 0,
    roleProfileGroupName: '',
    roleProfileGroupDescription: '',
    profileSetDescription: '',
    skills: [{
      skillName: '',
      skillId: 0,
      ksaSkillId: '',
      threshold: 0,
      KSASkillWithRoleProfileComment: '',
    }]
  }
  newRoleProfile = this.initializeRoleProfile();
  editRoleProfileToPush = this.initializeRoleProfile();
  skills: ActiveSkill[];
  result: RoleProfileResult[] = [];
  skillsList: SkillListArray = [];
  selectedSkills: KsaRoleSkill[] = [];
  skillGroupList: string[] = [];

  //Clone Profile Set modal
  showImportksaSetPopup: boolean = false;
  cloneErrorMsg: string = '';
  importedKsaSelected: RoleProfileAnalysis;
  cloneRoleProfileSetSelected: ContentEntity;
  newRoleProfileSetName: string = '';

  //Clone Role Profile modal
  showCloneRoleProfilePopUp: boolean = false;
  cloneRoleProfileDialogErrorMsg: string = '';
  cloneRoleProfileDialogImportedKsaSelected: RoleProfileAnalysis;
  cloneRoleProfileDialogSelectedProfileSet: ContentEntity;
  cloneRoleProfileSelected: KsaRoleProfilesEntity;
  roleProfileListForCloning: KsaRoleProfilesEntity[] = [];
  cloneRoleProfileGroupSelected: KsaRoleProfileGroupsEntity;
  newRoleProfileName: string = '';
  newSkills: RoleProfileSkillMapsEntity[] = [];
  cloneSkills: RoleProfileSkillMapsEntity[] = [];

  importedKsas: RoleProfileAnalysis[];
  ksa: Ksa = {
    masterKsa: {
      id: 0
    }
  }
  ksaJobProfileSets: ContentEntity[] = [];
  currentSetSelected: ContentEntity;
  @Input() ksaId: number;
  dataGridType: DataGridTypeEnum = DataGridTypeEnum.ROLE_PROFILES;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  colorData: (ColorCode)[] = [];
  skillColors: {
    skillId: number,
    backgroundColor: string,
    fontColor: string
  }[];
  isColorModelOpen: boolean;
  successMsgTimeout: number;
  functionality: typeof Functionality = Functionality;
  dropdownSetId: number = 0;
  editAll: boolean = false;
  saveSuccess: boolean = false;
  @Input() showNewWindowButton: boolean = true;
  showLoader: boolean = false;
  showDeleteMultipleWarning: boolean = false;

  constructor(private skillService: SkillsService, private ksaRoleProfileService: KsaRoleProfileService,
              private ksaAdministrationService: KsaAdministrationService, private analysisShareService: AnalysisShareService,
              private translate: TranslateService,
              private colorCodeService: ColorCodeService) {
  }

  ngOnInit(): void {
    this.getKsaData();
    this.getColorCodeData();
  }

  getImportedKsaRoleProfileSet(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.analysisShareService.getClosedKsaListForClone()
      .pipe(finalize(() => hideSpinner()))
      .subscribe((data) => {
      this.importedKsas = data;
    })
  }

  getColorCodeData(): void {
    this.colorCodeService.getAllColorCodesByKsaIdAndType(this.ksaId, this.dataGridType).subscribe(data => {
      if (data && data.length > 0) {
        this.colorData = data;
        this.mapColorToTable(this.colorData);
      } else {
        this.applyDefaultTableColors();
      }
    });
  }

  mapColorToTable(colorData: (ColorCode)[]): void {
    this.result?.flatMap(result => result.skills).forEach(skill => {
      skill.backgroundColor = '';
      skill.fontColor = '';
      const colorCodeMatch: ColorCode = colorData?.find(color => skill.threshold >= color.from && skill.threshold <= color.to);
      if (skill.threshold && colorCodeMatch) {
        skill.backgroundColor = colorCodeMatch.backgroundColorHexCode;
        skill.fontColor = colorCodeMatch.fontColorHexCode;
      }
    });
  }

  applyDefaultTableColors(): void {
    this.colorCodeService.setLoadDefaultColorByColorDto(this.ksaId, this.dataGridType).subscribe(colorData => {
      if (colorData) {
        this.mapColorToTable(colorData);
      }
    });
  }

  addColor(color: ColorCode): void {
    this.colorData.push(color);
    this.mapColorToTable(this.colorData);
  }

  getSkillColor(skillId: number, isFontColor: boolean): string {
    const skillColors = this.skillColors?.find(sc => sc.skillId === skillId);
    if (skillColors) {
      return isFontColor ? skillColors.fontColor : skillColors.backgroundColor;
    } else {
      return '';
    }
  }

  onShowColorModel(): void {
    this.isColorModelOpen = true;
    this.getColorCodeData();
  }

  onHideColorModal(): void {
    if (this.colorData && this.colorData.length > 0) {
      this.mapColorToTable(this.colorData);
    } else {
      this.applyDefaultTableColors();
    }
    this.isColorModelOpen = false;
  }

  selectKsa(selectedKsa: RoleProfileAnalysis): void {
    this.getKsaSetsDropdownForClone(selectedKsa);
  }

  getKsaSetsDropdownForClone(selectedKsa: RoleProfileAnalysis): void {
    this.analysisShareService.getKsaJobProfileSets(selectedKsa.id).subscribe(data => {
      this.ksaJobProfileSets = data.content;
    })
  }

  getKsaData(): void {
    if (this.ksaId) {
      showSpinner(this.translate.instant("spinnerLabels.common.loading"));
      this.analysisShareService.getKsaJobProfileSets(this.ksaId).subscribe(sets => {
        this.ksaSets = sets.content;
        this.analysisShareService.getDefaultKsaSetInfoForAnalysis(this.ksaId)
          .pipe(finalize(() => hideSpinner()))
          .subscribe(data => {
          this.currentSetSelected = data;
          this.dropdownSetId = data.id;
          this.displayRoleAndSkillsBySetId();
          this.showLoader = false;
        });
      });
    }
  }

  onEditRoleProfile(roleProfile): void {
    this.editAll = false;
    this.editRoleProfile = roleProfile;
  }

  onCloneKsaSet(): void {
    this.checkEditAllStatus();
    this.errorMsg = '';
    this.getImportedKsaRoleProfileSet();
    this.showImportksaSetPopup = true;
  }

  onSetAddKsaFormOpen(): void {
    this.checkEditAllStatus();
    this.errorMsg = '';
    this.getSkills();
    this.addRoleProfileFormOpen = true;
  }

  getSkills(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaAdministrationService.getSkillsByKsaId(this.ksaId)
      .pipe(finalize(hideSpinner))
      .subscribe(data => {
        this.skills = data;
      });
  }

  getRoleProfilesByKsaAndSetId(ksaId, setId): void {
    this.ksaRoleProfileService.getRoleProfilesByKsaAndSetId(ksaId, setId).subscribe(data => {
      this.rolesReceivedByKsaIdSetId = Object.values(data.reduce((groupedSkills, role) => {
        const id = role.id;

        if (groupedSkills[id] == null) {
          groupedSkills[id] = [];
        }

        groupedSkills[id].push(role);
        return groupedSkills;
      }, {}))
      this.mapSkills();
      this.mapRoleProfiles();
      this.getColorCodeData();
    })
  }

  mapSkills(): void {
    this.skillsList = [];
    this.rolesReceivedByKsaIdSetId.forEach((roleProfile) => {
      const skills: SkillList[] = [];
      roleProfile.forEach((data) => {
        if (!this.skillGroupList.includes(data.skillGroupName)) {
          this.skillGroupList.push(data.skillGroupName);
        }
        skills.push({
          skillName: data?.skillName,
          skillId: data?.skillId,
          ksaSkillId: data?.ksaSkillId,
          threshold: data?.threshold,
          KSASkillWithRoleProfileComment: data?.KSASkillWithRoleProfileComment,
          skillGroupName: data?.skillGroupName
        })
      })
      this.skillsList.push(skills);
    })
  }

  mapRoleProfiles(): void {
    this.result = this.rolesReceivedByKsaIdSetId.map((data, index) => ({
      id: data[0]?.id,
      ksaRoleProfileName: data[0]?.ksaRoleProfileName,
      description: data[0]?.description,
      profileSetId: data[0]?.profileSetId,
      profileSetName: data[0]?.profileSetName,
      roleProfileGroupID: data[0]?.roleProfileGroupID,
      roleProfileGroupName: data[0]?.roleProfileGroupName,
      roleProfileGroupDescription: data[0]?.roleProfileGroupDescription,
      profileSetDescription: data[0]?.profileSetDescription,
      skills: this.skillsList[index]
    }));
  }

  onEditAll(): void {
    this.errorMsg = '';
    this.editAll = true;
  }

  onCancelEditAll(): void{
    this.errorMsg = '';
    this.editAll = false;
    this.getRoleProfilesByKsaAndSetId(this.ksaId, this.currentSetSelected.id);
  }

  checkEditAllStatus(): void{
    if(this.editAll){
      this.getRoleProfilesByKsaAndSetId(this.ksaId, this.currentSetSelected.id);
      this.editAll = false;
    }
  }

  onCreateCloneKsaSet(form: NgForm): void {
    this.ksa.masterKsa.id = this.ksaId;
    const roleProfileSetToClone = {...this.cloneRoleProfileSetSelected, ...this.ksa};
    roleProfileSetToClone.name = this.newRoleProfileSetName;
    delete roleProfileSetToClone.id;
    roleProfileSetToClone.ksaRoleProfileGroups.forEach(group => {
      delete group.id;
      group.ksaRoleProfiles.forEach(roleProfile => {
        delete roleProfile.id;
        roleProfile.ksaSkillWithRoleProfiles.forEach(skill => {
          delete skill.id;
        })
      })
    })

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.ksaRoleProfileService.createKsaRoleProfileSet(roleProfileSetToClone)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.cloneErrorMsg = '';
        form.reset();
        this.dropdownSetId = data.id;
        this.currentSetSelected = data;
        this.showImportksaSetPopup = false;
        this.analysisShareService.getKsaJobProfileSets(this.ksaId).subscribe(sets => {
          this.ksaSets = sets.content;
        });
        this.skills = [];
        this.skillsList = [];
        this.result = [];
        this.getSkills();
        this.getRoleProfilesByKsaAndSetId(this.ksaId, data.id);
      },
      error => this.cloneErrorMsg = error.error.message);
  }

  onAddRoleProfile(addForm: NgForm): void {
    this.newRoleProfile.roleProfileGroupName = this.importedRoleProfileGroupSelected?.roleProfileGroupName;
    this.newRoleProfile.description = this.importedRoleProfileGroupSelected?.description;
    this.newRoleProfile.id = this.importedRoleProfileGroupSelected?.id;
    this.newRoleProfile.ksaJobProfileSet.id = this.currentSetSelected?.id;
    this.newRoleProfile.ksaJobProfileSet.name = this.currentSetSelected?.name;
    this.newRoleProfile.ksaJobProfileSet.description = this.currentSetSelected?.description;
    this.newRoleProfile.ksaRoleProfiles[0].ksaSkillWithRoleProfiles = this.selectedSkills?.map((skill) => ({
      ksaSkill: {id: skill?.id},
      threshold: skill?.threshold,
      comment: skill?.comment
    }))

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.ksaRoleProfileService.createKsaRoleProfile(this.newRoleProfile)
      .pipe(finalize(() => hideSpinner())).subscribe(data => {
        addForm?.onReset();
        this.addRoleProfileFormOpen = false;
        this.selectedSkills = [];
        this.getRoleProfilesByKsaAndSetId(this.ksaId, this.currentSetSelected.id);
        this.clearRoleProfileDetails();
      },
      error => this.errorMsg = error.error.message);
  }

  onSaveAllSets(): void {
    this.errorMsg = '';
    const editRoleProfileArray = [];
    this.result.forEach((roleProfile) => {
      this.editRoleProfileToPush.roleProfileGroupName = roleProfile.roleProfileGroupName;
      this.editRoleProfileToPush.description = roleProfile.roleProfileGroupDescription;
      this.editRoleProfileToPush.id = roleProfile.roleProfileGroupID;
      this.editRoleProfileToPush.ksaJobProfileSet.id = roleProfile.profileSetId;
      this.editRoleProfileToPush.ksaJobProfileSet.name = roleProfile.profileSetName;
      this.editRoleProfileToPush.ksaJobProfileSet.description = roleProfile.profileSetDescription;
      this.editRoleProfileToPush.ksaRoleProfiles[0].id = roleProfile.id;
      this.editRoleProfileToPush.ksaRoleProfiles[0].ksaRoleProfileName = roleProfile.ksaRoleProfileName;
      this.editRoleProfileToPush.ksaRoleProfiles[0].description = roleProfile.description;
      this.editRoleProfileToPush.ksaRoleProfiles[0].ksaSkillWithRoleProfiles = roleProfile.skills.map(skill => ({
        id: skill.ksaSkillId,
        ksaSkill: {id: skill?.skillId},
        threshold: skill.threshold,
        comment: skill.ksaskillWithRoleProfileComment,
      }));
      editRoleProfileArray.push(this.editRoleProfileToPush);
      this.editRoleProfileToPush = this.initializeRoleProfile();
    })

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.analysisShareService.saveAllRoleProfiles(editRoleProfileArray)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(() => {
          this.getRoleProfilesByKsaAndSetId(this.ksaId, this.currentSetSelected.id);
          this.editRoleProfile = {};
          this.editAll = false;
          this.errorMsg = '';
          this.saveSuccess = true;
          this.analysisShareService.getHeatMapAndRoleMapData(this.ksaId).subscribe(data => {
            this.analysisShareService.setAnalysisResponseData(data);
          });
          setTimeout(() => {
            this.saveSuccess = false;
          }, 3000);
        },
        error => this.errorMsg = error.error.message);
  }

  onEditRoleProfileRow(roleProfile): void {
    this.editRoleProfileToPush.roleProfileGroupName = roleProfile.roleProfileGroupName;
    this.editRoleProfileToPush.description = roleProfile.roleProfileGroupDescription;
    this.editRoleProfileToPush.id = roleProfile.roleProfileGroupID;
    this.editRoleProfileToPush.ksaJobProfileSet.id = roleProfile.profileSetId;
    this.editRoleProfileToPush.ksaJobProfileSet.name = roleProfile.profileSetName;
    this.editRoleProfileToPush.ksaJobProfileSet.description = roleProfile.profileSetDescription;
    this.editRoleProfileToPush.ksaRoleProfiles[0].id = roleProfile.id;
    this.editRoleProfileToPush.ksaRoleProfiles[0].ksaRoleProfileName = roleProfile.ksaRoleProfileName;
    this.editRoleProfileToPush.ksaRoleProfiles[0].description = roleProfile.description;
    this.editRoleProfileToPush.ksaRoleProfiles[0].ksaSkillWithRoleProfiles = roleProfile.skills.map(skill => ({
      id: skill.ksaSkillId,
      ksaSkill: {id: skill?.skillId},
      threshold: skill.threshold,
      comment: skill.ksaskillWithRoleProfileComment,
    }));

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.ksaRoleProfileService.updateKsaRoleProfile(this.editRoleProfileToPush)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.saveSuccess = true;
        this.getRoleProfilesByKsaAndSetId(this.ksaId, this.currentSetSelected.id);
        this.editRoleProfile = {};
        this.editAll = false;
        this.errorMsg = '';
        this.analysisShareService.getHeatMapAndRoleMapData(this.ksaId).subscribe(data => {
          this.analysisShareService.setAnalysisResponseData(data);
        });
        setTimeout(() => {
          this.saveSuccess = false;
        }, 3000);
      },
      error => this.errorMsg = error.error.message);
  }

  onDeleteRoleProfile(): void {
    this.ksaRoleProfileService.deleteRoleProfile(this.deleteRoleProfileId).subscribe(data => {
        this.displayRoleAndSkillsBySetId();
        this.showDeleteWarning = false;
        this.deleteRoleProfileId = 0;
        this.errorMsg = '';
      },
      error => this.errorMsg = error.error.message);
  }

  onDeleteMultipleRoleProfile(selected): void {
    this.errorMsg = '';
    const ids: number[] = [];
    selected.forEach(function (value) {
      ids.push(value.id);
    });
    this.ksaRoleProfileService.deleteMultipleRoleProfiles(ids).subscribe(data => {
        this.displayRoleAndSkillsBySetId();
        this.errorMsg = '';
        this.showDeleteMultipleWarning = false;
      },
      error => {
        this.errorMsg = error.error.message;
      })
  }

  onShowDeleteWarning(id: number): void {
    this.errorMsg = '';
    this.deleteRoleProfileId = id;
    this.showDeleteWarning = true;
  }

  onCancelAdding(addForm: NgForm): void {
    addForm.reset();
    this.addRoleProfileFormOpen = false;
    this.clearRoleProfileDetails();
  }

  onCancelEditing(): void {
    this.editRoleProfile = {};
    this.getRoleProfilesByKsaAndSetId(this.ksaId, this.currentSetSelected.id);
  }

  clearRoleProfileDetails(): void {
    this.newRoleProfile = this.initializeRoleProfile();
    this.editRoleProfileToPush = this.initializeRoleProfile();
    this.errorMsg = '';
    this.importedRoleProfileGroupSelected = null;
  }

  initializeRoleProfile(): RoleProfileInitialize {
    return {
      roleProfileGroupName: '',
      description: '',
      id: 0,
      ksaRoleProfiles: [
        {
          ksaRoleProfileName: this.roleProfileEnum.roleName,
          description: '',
          ksaSkillWithRoleProfiles: [
            {
              ksaSkill: {
                id: 0
              },
              threshold: 0,
              comment: ''
            }
          ]
        }
      ],
      ksaJobProfileSet: {
        id: 0,
        name: '',
        description: ''
      }
    }
  }

  displayRoleAndSkillsBySetId(): void {
    this.skills = [];
    this.skillsList = [];
    this.result = [];
    this.getSkills();
    this.getRoleProfilesByKsaAndSetId(this.ksaId, this.currentSetSelected.id);
  }

  displayRoleAndSkillsBySetIdInDropdown(event): void {
    this.errorMsg = '';
    this.editAll = false;
    this.editRoleProfile = {};
    this.currentSetSelected = this.ksaSets.find(set =>
      set.id == event.target.value
    );
    this.skills = [];
    this.skillsList = [];
    this.result = [];
    this.getSkills();
    this.getRoleProfilesByKsaAndSetId(this.ksaId, this.currentSetSelected.id);
  }

  onCloseCloneSet(form: NgForm): void {
    form.reset();
    this.cloneErrorMsg = '';
    this.importedKsaSelected = {};
    this.cloneRoleProfileSetSelected = {};
    this.newRoleProfileSetName = '';
    this.importedKsas = [];
    this.showImportksaSetPopup = false;
  }

  onOpenNewWindow(): void {
    this.errorMsg = '';
    window.open(`${environment.API_URL}/#/AnalysisReport/RoleProfile/${this.ksaId}`, '_blank', 'location=yes,height=1000,width=1000,scrollbars=yes,status=yes');
  }

  onRefresh(): void {
    this.errorMsg = '';
    this.showLoader = true;
    this.getKsaData();
    this.getColorCodeData();
  }

  onCloneRoleProfile(): void {
    this.checkEditAllStatus();
    this.errorMsg = '';
    this.cloneRoleProfileDialogErrorMsg = '';
    this.getImportedKsaRoleProfileSet();
    this.getSkills();
    this.cloneRoleProfileGroupSelected = {};
    this.cloneRoleProfileDialogSelectedProfileSet = {};
    this.showCloneRoleProfilePopUp = true;
  }

  fetchRoleGroups(): void {
    this.roleProfileListForCloning = [];
    this.cloneRoleProfileDialogSelectedProfileSet?.ksaRoleProfileGroups?.forEach(group => {
      group.ksaRoleProfiles.forEach(role => {
        this.roleProfileListForCloning.push(role);
      })
    })
  }

  mapSkillWithThreshold(): void{
    this.cloneSkills = [];
    this.cloneRoleProfileSelected.ksaSkillWithRoleProfiles.forEach(skill => {
      const skillToPush: RoleProfileSkillMapsEntity = {};
      skillToPush.id = skill.ksaSkill.id;
      skillToPush.skillName = skill.ksaSkill.skillName;
      skillToPush.comment = skill.comment;
      skillToPush.threshold = skill.threshold;
      this.cloneSkills.push(skillToPush);
    })
    this.skills.forEach(skill => {
      if (!this.cloneSkills.some(item => item.id === skill.id)) {
        const skillToPush: RoleProfileSkillMapsEntity = {};
        skillToPush.id = skill.id;
        skillToPush.skillName = skill.skillName;
        skillToPush.comment = null;
        skillToPush.threshold = null;
        this.cloneSkills.push(skillToPush);
      }
    })
    this.newSkills = this.cloneSkills.filter(skill => {

      if (skill.threshold) {
        return skill;
      }
    })
  }

  onCreateCloneRoleProfile(form: NgForm): void {
    const roleProfileToClone = this.initializeRoleProfile();
    roleProfileToClone.roleProfileGroupName = this.cloneRoleProfileGroupSelected?.roleProfileGroupName;
    roleProfileToClone.description = this.cloneRoleProfileGroupSelected?.description;
    roleProfileToClone.id = this.cloneRoleProfileGroupSelected?.id;
    roleProfileToClone.ksaJobProfileSet.id = this.currentSetSelected?.id;
    roleProfileToClone.ksaJobProfileSet.name = this.currentSetSelected?.name;
    roleProfileToClone.ksaJobProfileSet.description = this.currentSetSelected?.description;
    roleProfileToClone.ksaRoleProfiles[0].ksaRoleProfileName = this.newRoleProfileName;
    roleProfileToClone.ksaRoleProfiles[0].description = this.cloneRoleProfileSelected.description;
    roleProfileToClone.ksaRoleProfiles[0].ksaSkillWithRoleProfiles =
      this.newSkills?.map((skill) => ({
        ksaSkill: {id: skill.id},
        threshold: skill.threshold,
        comment: skill.comment,
      }))

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.ksaRoleProfileService.createKsaRoleProfile(roleProfileToClone)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        form?.onReset();
        this.newRoleProfileName = '';
        this.showCloneRoleProfilePopUp = false;
        this.getRoleProfilesByKsaAndSetId(this.ksaId, this.currentSetSelected.id);
        this.clearCloneProfileDetails(form);
      },
      error => this.cloneRoleProfileDialogErrorMsg = error.error.message);
  }

  clearCloneProfileDetails(form: NgForm): void {
    form.reset();
    this.cloneRoleProfileDialogErrorMsg = '';
    this.cloneRoleProfileDialogImportedKsaSelected = {};
    this.cloneRoleProfileDialogSelectedProfileSet = {};
    this.importedKsas = [];
    this.cloneRoleProfileGroupSelected = {};
    this.roleProfileListForCloning = [];
    this.cloneRoleProfileSelected = {};
    this.newRoleProfileName = '';
    this.showCloneRoleProfilePopUp = false;
  }
}
