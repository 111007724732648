/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {ResponseManagementComponent} from "./response-management/response-management.component";
import {TabReportsComponent} from "./tab-reports/tab-reports.component";
import {TrackingComponent} from "./tracking/tracking.component";
import {AuthGuard} from "../../guards/auth.guard";
import {TabAnalysisComponent} from "./tab-analysis/tab-analysis.component";

const routes: Routes = [
  {
    path: 'Home', component: HomeComponent, children: [
      {
        path: 'ResponseManagement', component: ResponseManagementComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Analysis & Reports / Response Management'
        }
      },
      {
        path: 'Reports', component: TabReportsComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Analysis & Reports / Reports'
        }
      },
      {
        path: 'Analysis', component: TabAnalysisComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Analysis & Reports / Analysis'
        }
      },
      {
        path: 'Tracking', component: TrackingComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Analysis & Reports / Tracking'
        }
      }
    ],
    data: {breadcrumb: {skip: true}}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnalysisReportRoutingModule {
}
