/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CertificateDetails} from '../models/certification.model';
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({providedIn: 'root'})

export class CertificateService {

  private certificateUrl: string;

  constructor(private http: HttpClient) {
    this.certificateUrl = `${environment.API_URL}${API_KSA_CORE_V1}/certification/`;
  }

  getCertificateData(id: number): Observable<any> {
    return this.http.get<CertificateDetails[]>(this.certificateUrl + id);
  }

  updateCertificateData(certificateDetails: CertificateDetails): Observable<CertificateDetails> {
    return this.http.put(this.certificateUrl, certificateDetails);
  }

  getOriginalCertificateData(id: number): Observable<any> {
    return this.http.get<CertificateDetails[]>(this.certificateUrl + 'response/original/' + id);
  }

  resetDataCertificate(data): Observable<any> {
    return this.http.post(`${this.certificateUrl}reset`, data);
  }

  updateCertification(data): Observable<CertificateDetails>  {
    return this.http.post(`${this.certificateUrl}filter`,data);
  }

  updateOriginalCertification(data): Observable<CertificateDetails>  {
    return this.http.post(`${this.certificateUrl}filter/original`,data);
  }
}
