/*
 * ************************************************************************************
 * Copyright 2024 VMware LLC.  All rights reserved. VMware Confidential
 * ************************************************************************************
 */

import {ParticipantQuestion} from '../../../models/participant-ksa.model';

export class QuestionVisibilityUtil {
  /**
   * Hides questions when the role profile filter is applied.
   * @param question The question to modify.
   */
  static hideQuestionsWhenRoleProfileFilterIsApplied(question: ParticipantQuestion): void {
    question.isDisplay = false;
    question.isRoleProfileDisplay = false;
    question.question.isModified = true;
  }

  /**
   * Groups questions by their section id.
   * @param questions An array of questions.
   * @returns A map of questions grouped by section id.
   */
  static groupQuestionsBySectionId(questions: ParticipantQuestion[]): Map<number, ParticipantQuestion[]> {
    return questions.reduce((map, q) => {
      if (!map.has(q.sectionID)) {
        map.set(q.sectionID, []);
      }
      map.get(q.sectionID)?.push(q);
      return map;
    }, new Map<number, ParticipantQuestion[]>());
  }

  /**
   * Determines if a section should be visible based on the questions it contains.
   * @param questions An array of questions.
   * @returns True if the section should be visible, false otherwise.
   */
  static shouldSectionBeVisible(questions: ParticipantQuestion[]): boolean {
    const hasAnySkillInSection = questions.some(q => q.skill?.id);
    const hasAnyVisibleSkillQuestion = questions.some(q => q.skill?.id && q.isDisplay);
    return !hasAnySkillInSection || (hasAnySkillInSection && hasAnyVisibleSkillQuestion);
  }
}
