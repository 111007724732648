<div class="button-container" *hasAccess="functionality.ksaTeamManagement">
  <button id="{{btnId.btnTeamManagementAdd}}" class="btn btn-sm btn-primary" (click)="onSetAddKsaTeamFormOpen()">
    {{'ksaEngagement.ksaTeamManagement.addTeam' | translate}}
  </button>
  <button (click)="onShowDeleteMultipleTeamPopUp(selected)" class="btn btn-sm btn-primary"
          id="{{btnId.btnTeamManagementMultipleDelete}}">
    {{'ksaEngagement.ksaTeamManagement.deleteTeam' | translate}}
  </button>
</div>
<clr-datagrid (clrDgRefresh)="refresh($event)" [(clrDgSelected)]="selected" [clrDgLoading]="loading"
              [clrDgRowSelection]="false" class="parent-datagrid">
  <clr-dg-column [clrDgField]="'name'">{{'ksaEngagement.ksaTeamManagement.teamName' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'description'">{{'ksaEngagement.ksaTeamManagement.description' | translate}}
  </clr-dg-column>
  <clr-dg-column>{{'ksaEngagement.ksaTeamManagement.teamUser' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="">{{'ksaEngagement.ksaTeamManagement.teamKsa' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="">{{'ksaEngagement.ksaTeamManagement.teamRegion' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="">{{'ksaEngagement.ksaTeamManagement.teamGeo' | translate}}</clr-dg-column>
  <clr-dg-column class="action-cell"></clr-dg-column>
  <clr-dg-row *ngFor="let ksaTeam of ksaTeams.content" [clrDgItem]="ksaTeam">
    <clr-dg-cell>{{ksaTeam.name}}</clr-dg-cell>
    <clr-dg-cell>{{ksaTeam.description}}</clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let ksaUser of ksaTeam.ksaTeamUserMaps">
        <li>{{ksaUser.user.firstName}}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let masterKsa of ksaTeam.masterKsa">
        <li>{{masterKsa.ksaName}}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let masterKsa of ksaTeam.masterKsa">
        <li>{{masterKsa.country.countryName}}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let masterKsa of ksaTeam.masterKsa">
        <li>{{masterKsa.geo.geoName}}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell class="action-cell">
      <clr-dg-action-overflow *hasAccess="functionality.ksaTeamManagement">
        <button id="{{btnId.btnTeamManagementEdit}}" type="button" class="action-item"
                (click)="onEditKsaTeamDetails(ksaTeam)">{{'admin.editBtn' | translate}}
        </button>
        <button (click)="onShowSingleDeleteTeamPopUp(ksaTeam.id)" class="action-item"
                id="{{btnId.btnTeamManagementDelete}}" type="button">{{'admin.deleteBtn' |
          translate}}
        </button>
      </clr-dg-action-overflow>
    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'ksaEngagement.ksaTeamManagement.teamsPerPage' |
        translate}}
      </clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} {{'ksaEngagement.ksaTeamManagement.teamOf' |
      translate}} {{pagination.totalItems}}
      {{'ksaEngagement.ksaTeamManagement.teams' | translate}}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
<clr-modal (clrModalOpenChange)="clearForm(addForm)" [(clrModalOpen)]="addKsaTeamFormOpen" [clrModalClosable]="true">
  <h3 class="modal-title">{{'ksaEngagement.ksaTeamManagement.addTeam' | translate}}</h3>
  <div class="modal-body">
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
    <form clrForm (ngSubmit)="onAddKsaTeam(addForm)" #addForm="ngForm" autocomplete="off">
      <clr-input-container class="clr-row">
        <label class="clr-col-4">{{'ksaEngagement.ksaTeamManagement.teamName' | translate}}* </label>
        <input #isTeamInput="ngModel" [(ngModel)]="getTeamData.name" class="clr-col-8" clrInput name="ksaTeamName"
               placeholder="{{'ksaEngagement.ksaTeamManagement.enterTeamName' | translate}}" required type="text"
               pattern="^[^!@#*&(){}<>\/;`%$^:?]*$"/>
        <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}
        </clr-control-error>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterTeam' | translate}}
        </clr-control-error>
      </clr-input-container>
      <clr-textarea-container class="clr-row">
        <label class="clr-col-4">{{'ksaEngagement.ksaTeamManagement.teamDescription' | translate}}</label>
        <textarea #isTeamsDescInput="ngModel" [(ngModel)]="getTeamData.description" class="clr-col-8" clrTextarea
                  name="roleDescription" placeholder="{{'ksaEngagement.ksaTeamManagement.teamDescription' | translate}}"
                   rows="1"></textarea>
      </clr-textarea-container>
      <br>
      <clr-input-container class="clr-row">
        <label class="clr-col-4 clr-control-label">{{'ksaEngagement.ksaTeamManagement.selectUsers' |
          translate}}*</label>
      </clr-input-container>
      <clr-datagrid class="datagrid-compact child-course-datagrid popup-datagrid clear"
                    [(clrDgSelected)]="getTeamData.ksaTeamUserMaps" [clrDgPreserveSelection]="true">
        <clr-dg-column [clrDgField]="'firstName'">{{'ksaEngagement.ksaTeamManagement.firstName' | translate}}
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'lastName'">{{'ksaEngagement.ksaTeamManagement.lastName' | translate}}
        </clr-dg-column>
        <clr-dg-column>{{'ksaEngagement.ksaTeamManagement.role' | translate}}</clr-dg-column>
        <clr-dg-row *clrDgItems="let user of addTeamRoles; let i = index" [clrDgItem]="user"
                    [clrDgSelectable]="user.emailId!=this.username">
          <clr-dg-cell>{{user.firstName}}</clr-dg-cell>
          <clr-dg-cell>{{user.lastName}}</clr-dg-cell>
          <clr-dg-cell>
            <ul *ngFor="let userRole of user.userRoleMaps">
              <li>{{userRole.ksaRole.roleDescription}}</li>
            </ul>
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-outline"
                (click)="onCancelForm()">{{'admin.cancelBtn'|translate}}</button>
        <button type="submit" class="btn btn-sm btn-primary"
                [disabled]="addForm.invalid || getTeamData.ksaTeamUserMaps.length===0">{{'admin.addBtn'|translate}}</button>
      </div>
    </form>
  </div>
</clr-modal>
<clr-modal (clrModalOpenChange)="clearForm(addForm)" [(clrModalOpen)]="editKsaTeamFormOpen" [clrModalClosable]="true">
  <h3 class="modal-title">{{'ksaEngagement.ksaTeamManagement.editTeam' | translate}}</h3>
  <div class="modal-body">
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
    <form #addForm="ngForm" (ngSubmit)="onUpdateKsaTeam(addForm)" autocomplete="off" clrForm>
      <clr-input-container class="clr-row">
        <label class="clr-col-4">{{'ksaEngagement.ksaTeamManagement.teamName' | translate}}* </label>
        <input #isTeamFilled="ngModel" [(ngModel)]="newTeamToAdd.name" class="clr-col-8" clrInput name="ksaTeamName"
               placeholder="{{'ksaEngagement.ksaTeamManagement.teamDescription' | translate}}" required type="text"
               pattern="^[^!@#*&(){}<>\/;`%$^:?]*$"
/>
        <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}
        </clr-control-error>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterTeam' | translate}}
        </clr-control-error>
      </clr-input-container>
      <clr-textarea-container class="clr-row">
        <label class="clr-col-4">{{'ksaEngagement.ksaTeamManagement.teamDescription' | translate}}*</label>
        <textarea #isTeamDescFilled="ngModel" [(ngModel)]="newTeamToAdd.description" class="clr-col-8" clrTextarea
                  name="ksaTeamDesc" placeholder="{{'ksaEngagement.ksaTeamManagement.teamDescription' | translate}}"
                  rows="1"></textarea>
      </clr-textarea-container>
      <clr-input-container class="clr-row">
        <label class="clr-col-4 clr-control-label">{{'ksaEngagement.ksaTeamManagement.selectUsers' |
          translate}}*</label>
      </clr-input-container>
      <clr-datagrid [(clrDgSelected)]="newTeamToAdd.ksaTeamUserMaps" class="datagrid-compact popup-datagrid" [clrDgPreserveSelection]="true">
        <clr-dg-column [clrDgField]="'user.firstName'">{{'ksaEngagement.ksaTeamManagement.firstName' | translate}}
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'user.lastName'">{{'ksaEngagement.ksaTeamManagement.lastName' | translate}}
        </clr-dg-column>
        <clr-dg-column>{{'ksaEngagement.ksaTeamManagement.role' | translate}}</clr-dg-column>
        <clr-dg-row *clrDgItems="let user of this.allTeamUser; let i = index" [clrDgItem]="user"
                    [clrDgSelectable]="user.user.emailId!=this.username">
          <clr-dg-cell>{{user.user.firstName}}</clr-dg-cell>
          <clr-dg-cell>{{user.user.lastName}}</clr-dg-cell>
          <clr-dg-cell>
            <ul *ngFor="let userRole of user.user.userRoleMaps">
              <li>{{userRole.ksaRole.roleDescription}}</li>
            </ul>
          </clr-dg-cell>

        </clr-dg-row>
      </clr-datagrid>
      <div class="modal-footer">
        <button (click)="editKsaTeamFormOpen = false" class="btn btn-sm btn-outline"
                type="button">{{'admin.cancelBtn'|translate}}
        </button>
        <button type="submit" class="btn btn-sm btn-primary"
                [disabled]="isTeamFilled.errors?.required || isTeamDescFilled.errors?.required || isTeamFilled.errors?.pattern">
          {{'admin.saveBtn'|translate}}
        </button>
      </div>
    </form>
  </div>
</clr-modal>
<clr-modal (clrModalOpenChange)="resetError()" [(clrModalOpen)]="showDeleteTeamPopUp">
  <h3 class="modal-title">{{'ksaEngagement.ksaTeamManagement.teamDeleteMsg' | translate}}
    <clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true" clrAlertType="danger">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'admin.roleProfilesAndGroups.roleProfiles.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="onCancel()" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}
    </button>
    <button (click)="onDeleteKsaTeam()" *ngIf="this.isSingleDelete" class="btn btn-outline-warning btn-sm">
      {{'admin.deleteBtn' |
      translate}}
    </button>
    <button (click)="onDeleteSelectedTeam()" *ngIf="this.isMultipleDelete" class="btn btn-outline-warning btn-sm">
      {{'admin.deleteBtn' |
      translate}}
    </button>
  </div>
</clr-modal>
