/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {EmailParticipant, EmailParticipantImport} from "../models/participant.model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class EmailParticipantService {
  private readonly emailParticipantUrl: string;
  private readonly participantUrl: string;

  constructor(private http: HttpClient) {
    this.emailParticipantUrl = `${environment.API_URL}${API_KSA_CORE_V1}/participant`;
    this.participantUrl = '/participant/';
  }

  getAllParticipants(ksaId :number): Observable<EmailParticipant[]> {
    return this.http.get<EmailParticipant[]>(this.emailParticipantUrl + this.participantUrl, {
      params: new HttpParams().set('ksaId', ksaId.toString())
    });
  }

  createParticipant(participant: EmailParticipant): Observable<EmailParticipant> {
    return this.http.post<EmailParticipant>(this.emailParticipantUrl + this.participantUrl, participant);
  }

  updateParticipant(id: number, participant: EmailParticipant): Observable<EmailParticipant> {
    return this.http.put<EmailParticipant>(this.emailParticipantUrl + this.participantUrl + id, participant);
  }

  deleteParticipant(id: number): Observable<void> {
    return this.http.delete<void>(this.emailParticipantUrl + this.participantUrl + id);
  }

  deleteAllParticipants(ksaId: number): Observable<void> {
    return this.http.delete<void>(this.emailParticipantUrl + '/participant', {
      params: new HttpParams().set('ksaId', ksaId.toString())
    });
  }

  importParticipant(file: File, ksaId: number): Observable<EmailParticipantImport> {
    const participantImportData: FormData = new FormData();
    participantImportData.append('file', file, file.name);
    participantImportData.append('ksaId', ksaId + '');
    return this.http.post<EmailParticipantImport>(this.emailParticipantUrl + '/import', participantImportData);
  }
}
