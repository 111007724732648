/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {AnswerLabel} from '../models/master-survey-questions.model';
import {DisplayLogicParameter, ParticipantQuestion, QuestionDisplayCondition} from '../models/participant-ksa.model';
import {SectionSkipConditions} from '../models/section-skip-conditions.model';
import {DisplayLogicFunctionalityService} from './display-logic-functionality';
import {SkipAndDisplayLogicConditions} from '../enums/display-skip-conditions';

@Injectable({
  providedIn: 'root'
})
export class DisplayLogicService {

  skipLogicConditions = SkipAndDisplayLogicConditions;

  displayLogicDynamicParams: DisplayLogicParameter = {
    selectedQuestionToDisplay: null,
    answerValuesText: null,
    isDisplayQuestion: false,
    displayConditionAnswerLabelID: null,
    selectedAnswerLabelsID: null,
    selectedAnswerAction: false,
    displayValueQuestion: null,
    questionListParticipant: null,
    isQuestionDisplayAction: false,
  };

  constructor(private displayLogicFunctionalityService: DisplayLogicFunctionalityService) {
  }

  getSkipConditionName(skipConditionID: number): string {
    const resultString: any = this.skipLogicConditions.find(skipCondition =>
      skipCondition.id === skipConditionID);
    return resultString.displayConditionName;
  }

  mapDisplayLogicDynamicParams(displayCondition: QuestionDisplayCondition | SectionSkipConditions,
                               question: ParticipantQuestion,
                               questionListParticipant: ParticipantQuestion[]): string {
    const skipConditionID = displayCondition.skipCondition?.id;
    const skipConditionName = this.getSkipConditionName(skipConditionID);
    this.displayLogicDynamicParams.questionListParticipant = questionListParticipant;
    this.displayLogicDynamicParams.selectedQuestionToDisplay = displayCondition.sectionQuestionToDisplay;
    this.displayLogicDynamicParams.isDisplayQuestion = question.isRoleProfileDisplay &&
      question.isSkipDisplay &&
      question.isSectionQuestionDisplay;
    return skipConditionName;
  }

  displayLogicForFree(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[],
                      isQuestionDisplayAction: boolean): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    this.displayLogicDynamicParams.isQuestionDisplayAction = isQuestionDisplayAction;

    if (isQuestionDisplayAction) {
      question.questionDisplayConditions.forEach(displayCondition => {
        const skipConditionName = this.mapDisplayLogicDynamicParams(displayCondition, question,
          questionListParticipant);
        this.displayLogicDynamicParams.answerValuesText = question.question.answerValuesText;
        resultQuestionList = this.displayLogicFunctionalityService[skipConditionName](this.displayLogicDynamicParams);
      });
    } else {
      question.sectionSkipConditions.forEach(displayCondition => {
        const skipConditionName = this.mapDisplayLogicDynamicParams(displayCondition, question,
          questionListParticipant);
        this.displayLogicDynamicParams.answerValuesText = question.question.answerValuesText;
        this.displayLogicDynamicParams.surveySectionID = displayCondition.surveySection.id;
        resultQuestionList = this.displayLogicFunctionalityService[skipConditionName](this.displayLogicDynamicParams);
      });
    }
    return resultQuestionList;
  }

  displayLogicForRadio(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[],
                       isQuestionDisplayAction: boolean): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    this.displayLogicDynamicParams.isQuestionDisplayAction = isQuestionDisplayAction;
    if (isQuestionDisplayAction) {
      question.question.answerLabels.forEach(AnswerLabel => {
        question.questionDisplayConditions.forEach(displayCondition => {
          if (displayCondition.answerLabel.id === AnswerLabel.id) {
            const skipConditionName = this.mapDisplayLogicDynamicParams(displayCondition, question,
              questionListParticipant);
            this.displayLogicDynamicParams.displayConditionAnswerLabelID = displayCondition.answerLabel.id;
            this.displayLogicDynamicParams.selectedAnswerLabelsID = AnswerLabel.id;
            this.displayLogicDynamicParams.selectedAnswerAction = AnswerLabel.isChecked;
            resultQuestionList = this.displayLogicFunctionalityService[skipConditionName](this.displayLogicDynamicParams);
          }
        });
      });
    } else {
      question.question.answerLabels.forEach(AnswerLabel => {
        question.sectionSkipConditions.forEach(displayCondition => {
          if (displayCondition.answerLabel.id === AnswerLabel.id) {
            const skipConditionName = this.mapDisplayLogicDynamicParams(displayCondition, question,
              questionListParticipant);
            this.displayLogicDynamicParams.displayConditionAnswerLabelID = displayCondition.answerLabel.id;
            this.displayLogicDynamicParams.selectedAnswerLabelsID = AnswerLabel.id;
            this.displayLogicDynamicParams.selectedAnswerAction = AnswerLabel.isChecked;
            this.displayLogicDynamicParams.surveySectionID = displayCondition.surveySection.id;
            resultQuestionList = this.displayLogicFunctionalityService[skipConditionName](this.displayLogicDynamicParams);
          }
        });
      });
    }
    return resultQuestionList;
  }

  displayLogicForMultiple(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[],
                          answerLabel: AnswerLabel,
                          isQuestionDisplayAction: boolean): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    this.displayLogicDynamicParams.isQuestionDisplayAction = isQuestionDisplayAction;

    if (isQuestionDisplayAction) {
      question.questionDisplayConditions.forEach(displayCondition => {
        const skipConditionName = this.mapDisplayLogicDynamicParams(displayCondition, question,
          questionListParticipant);
        this.displayLogicDynamicParams.displayConditionAnswerLabelID = displayCondition.answerLabel.id;
        question.question.answerLabels.forEach(AnswerLabel => {
          if (AnswerLabel.id === displayCondition.answerLabel.id) {
            this.displayLogicDynamicParams.selectedAnswerLabelsID = AnswerLabel.id;
            this.displayLogicDynamicParams.selectedAnswerAction = AnswerLabel.isChecked;
            resultQuestionList = this.displayLogicFunctionalityService[skipConditionName](this.displayLogicDynamicParams);
          }
        });
      });
    } else {
      question.sectionSkipConditions.forEach(displayCondition => {
        const skipConditionName = this.mapDisplayLogicDynamicParams(displayCondition, question,
          questionListParticipant);
        this.displayLogicDynamicParams.displayConditionAnswerLabelID = displayCondition.answerLabel.id;
        this.displayLogicDynamicParams.surveySectionID = displayCondition.surveySection.id;
        question.question.answerLabels.forEach(AnswerLabel => {

          if (AnswerLabel.id === displayCondition.answerLabel.id) {
            this.displayLogicDynamicParams.selectedAnswerLabelsID = AnswerLabel.id;
            this.displayLogicDynamicParams.selectedAnswerAction = AnswerLabel.isChecked;
            resultQuestionList = this.displayLogicFunctionalityService[skipConditionName](this.displayLogicDynamicParams);
          }
        });
      });
    }
    return resultQuestionList;
  }

  displayLogicForScale(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[],
                       answer: string | number,
                       isQuestionDisplayAction: boolean): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    this.displayLogicDynamicParams.isQuestionDisplayAction = isQuestionDisplayAction;

    if (isQuestionDisplayAction) {
      question.questionDisplayConditions.forEach(displayCondition => {
        const skipConditionName = this.mapDisplayLogicDynamicParams(displayCondition, question,
          questionListParticipant);
        this.displayLogicDynamicParams.answerValuesText = answer;
        this.displayLogicDynamicParams.displayValueQuestion = displayCondition.displayValue;
        resultQuestionList = this.displayLogicFunctionalityService[skipConditionName](this.displayLogicDynamicParams);
      });
    } else {

      question.sectionSkipConditions.forEach(displayCondition => {
        const skipConditionName = this.mapDisplayLogicDynamicParams(displayCondition, question,
          questionListParticipant);
        this.displayLogicDynamicParams.answerValuesText = answer;
        this.displayLogicDynamicParams.displayValueQuestion = displayCondition.displayValue;
        this.displayLogicDynamicParams.surveySectionID = displayCondition.surveySection.id;
        resultQuestionList = this.displayLogicFunctionalityService[skipConditionName](this.displayLogicDynamicParams);
      });
    }
    return resultQuestionList;
  }

  displayLogicForMatrixRadio(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[],
                             answerValue: boolean, rowNumber: number, columnNumber: number,
                             isQuestionDisplayAction: boolean): ParticipantQuestion[] {
    const resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    this.displayLogicDynamicParams.isQuestionDisplayAction = isQuestionDisplayAction;

    if (isQuestionDisplayAction) {
      question.questionDisplayConditions.forEach(displayCondition => {
        this.mapMatrixResultQuestionList(isQuestionDisplayAction, displayCondition,
          questionListParticipant, question, rowNumber, resultQuestionList);
      });
    } else {
      question.sectionSkipConditions.forEach(displayCondition => {
        this.mapMatrixResultQuestionList(isQuestionDisplayAction, displayCondition,
          questionListParticipant, question, rowNumber, resultQuestionList);
      });
    }
    return resultQuestionList;
  }

  displayLogicForMatrixCheckBox(question: ParticipantQuestion, questionListParticipant: ParticipantQuestion[],
                                answerValue: boolean, rowNumber: number, columnNumber: number,
                                isQuestionDisplayAction: boolean): ParticipantQuestion[] {
    const resultQuestionList: ParticipantQuestion[] = questionListParticipant;
    this.displayLogicDynamicParams.isQuestionDisplayAction = isQuestionDisplayAction;

    if (isQuestionDisplayAction) {
      question.questionDisplayConditions.forEach(displayCondition => {
        this.mapMatrixResultQuestionList(isQuestionDisplayAction, displayCondition,
          questionListParticipant, question, rowNumber, resultQuestionList);
      });
    } else {
      question.sectionSkipConditions.forEach(displayCondition => {
        this.mapMatrixResultQuestionList(isQuestionDisplayAction, displayCondition,
          questionListParticipant, question, rowNumber, resultQuestionList);
      });
    }

    return resultQuestionList;
  }

  mapMatrixResultQuestionList(isQuestionDisplayAction: boolean,
                              displayCondition, questionListParticipant: ParticipantQuestion[],
                              question: ParticipantQuestion,
                              rowNumber: number, resultQuestionList: ParticipantQuestion[]): void {
    const skipConditionID = displayCondition.skipCondition?.id;
    const skipConditionName = this.getSkipConditionName(skipConditionID);
    this.displayLogicDynamicParams.questionListParticipant = questionListParticipant;
    this.displayLogicDynamicParams.isDisplayQuestion = question.isRoleProfileDisplay &&
      question.isSkipDisplay &&
      question.isSectionQuestionDisplay;
    const selectedRowAnswerLabelSequence = displayCondition.answerLabel.answerLabelSequenceNo;
    const selectedColAnswerLabelSequence = displayCondition.colAnswerLabel.answerLabelSequenceNo;
    const listRowAnswerSelected = question.question.answerControls.filter(control => {
      return control.rowNumber === rowNumber;
    });
    listRowAnswerSelected.forEach(control => {
      this.displayLogicDynamicParams.displayConditionAnswerLabelID = selectedColAnswerLabelSequence;
      this.displayLogicDynamicParams.selectedAnswerLabelsID = control.colNumber;
      this.displayLogicDynamicParams.selectedQuestionToDisplay = displayCondition.sectionQuestionToDisplay;
      this.displayLogicDynamicParams.selectedAnswerAction = control.isChecked;

      if (!isQuestionDisplayAction) {
        this.displayLogicDynamicParams.surveySectionID = displayCondition.surveySection.id;
      }

      if (control.rowNumber === selectedRowAnswerLabelSequence &&
        control.colNumber === selectedColAnswerLabelSequence) {
        resultQuestionList = this.displayLogicFunctionalityService[skipConditionName](this.displayLogicDynamicParams);
      }

    });
  }
}
