/*******************************************************************
 Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, OnInit} from '@angular/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from "rxjs/operators";
import {ScorecardsService} from "../../../services/scorecards.service";
import {PersonalData, Scorecard, ScorecardStatus} from "../../../models/scorecard.model";
import {TranslateService} from "@ngx-translate/core";
import {KsaDetails} from "../../../models/master-ksa.model";
import {ResponseService} from "../../../services/response.service";
import {ButtonId} from "../../../enums/buttonId.enum";
import {UserService} from '../../../services/user.service';
import {Functionality} from '../../../enums/functionality.enum';

@Component({
  selector: 'scorecards',
  templateUrl: './scorecards.component.html',
  styleUrls: ['./scorecards.component.scss']
})
export class ScorecardsComponent implements OnInit {
  closedKsaList: KsaDetails[] = [];
  selected: PersonalData[] = [];
  personalData: PersonalData[];
  scorecards: Scorecard[];
  confirmSendingScorecard = false;
  isCcListOpen = false;
  isKsaSelected: boolean = false;
  selectedKsaId: number;
  isErrorOnLoad: boolean = false;
  isIndividualScorecardSend: boolean = false;
  errorMessage: string = '';
  showTable: boolean = false;
  page: number;
  total: number;
  btnId: typeof ButtonId = ButtonId;
  selectedParticipant: PersonalData;
  hasWritePermission: boolean;

  constructor(private readonly translateService: TranslateService,
              private readonly scorecardsService: ScorecardsService,
              private readonly responseService: ResponseService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.reports);
    this.getClosedKsaList();
  }

  onSelectKsaDetails(ksaId: number) {
    this.selectedKsaId = ksaId;
    this.isKsaSelected = !!ksaId;
    this.getPersonalDataByKsaId(ksaId);
  }

  onOpenScorecardView(participant: PersonalData) {
    this.selectedParticipant = participant;
  }

  onCancelScorecardView(): void {
    this.selectedParticipant = null;
  }

  isSendAvailable(personalData: PersonalData): boolean {
    return personalData?.scorecardStatus === ScorecardStatus.NOT_SENT || personalData?.scorecardStatus === ScorecardStatus.FAILED;
  }

  getSelectedUnsentScorecardsIds(): number[] {
    if (this.selectedParticipant && this.isScorecardUnsent(this.selectedParticipant)) {
      return [this.selectedParticipant.id];
    }
    return this.selected
      .filter(p => this.isScorecardUnsent(p))
      .map(p => p.id);
  }

  private isScorecardUnsent(p: PersonalData): boolean {
    return p.scorecardStatus === ScorecardStatus.NOT_SENT || p.scorecardStatus === ScorecardStatus.FAILED;
  }

  hasSelectedUnsentScorecards(): boolean {
    return this.selected.some(p => p.scorecardStatus === ScorecardStatus.NOT_SENT || p.scorecardStatus === ScorecardStatus.FAILED);
  }

  private getPersonalDataByKsaId(ksaId: number) {
    showSpinner(this.translateService.instant("spinnerLabels.common.loading"));
    this.scorecardsService.getPersonalDataByKsaId(ksaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(personalData => {
          this.personalData = personalData;
          this.isErrorOnLoad = false;
          this.showTable = true;
          this.isIndividualScorecardSend = false;
          this.errorMessage = '';
        },
        error => {
          this.isErrorOnLoad = true;
          this.showTable = false;
          this.errorMessage = error.error.message;
        });
  }

  private updateParticipantPersonalData(updatedData: PersonalData[]) {
    updatedData?.forEach(p => {
      const index = this.personalData.findIndex(pd => pd.id === p.id)
      this.personalData[index] = p;
    });
  }

  private getClosedKsaList(): void {
    this.translateService.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.responseService.getAllClosedNonAnonymousKsa()
        .pipe(finalize(() => hideSpinner())).subscribe(ksaList => {
          this.closedKsaList = ksaList;
          this.isErrorOnLoad = false;
          this.errorMessage = '';
        },
        error => {
          this.isErrorOnLoad = true;
          this.errorMessage = error?.error?.message;
          this.showTable = false;
        });
    });
  }

  downloadScorecards(): void {
    showSpinner(this.translateService.instant('spinnerLabels.common.downloading'));
    const participantIds = this.selected.length === 0 ? this.personalData.map(e => e.id) : this.selected.map(e => e.id);
    const selectedKsa = this.closedKsaList.find(e => e.id == this.selectedKsaId);
    this.scorecardsService.downloadScorecards(this.selectedKsaId, participantIds)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(response => {
        this.selected = [];
        saveAs(response.body, `${selectedKsa.ksaName}.zip`);
      });

  }

  onSendCompletion(updatedPersonalData: PersonalData[]): void {
    this.isErrorOnLoad = false;
    this.errorMessage = '';
    this.isIndividualScorecardSend = true;
    this.selected = [];
    this.selectedParticipant = null;
    this.updateParticipantPersonalData(updatedPersonalData);
  }

  onSendError(errorMessage: string): void {
    this.selectedParticipant = null;
    this.isErrorOnLoad = true;
    this.errorMessage = errorMessage;
  }

  closeCcModal(): void {
    this.selectedParticipant = null;
    this.isCcListOpen = false;
  }

  openCcModal(): void {
    this.isCcListOpen = true;
  }
}
