/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Options} from "@angular-slider/ngx-slider";
import {DataGridTypeEnum} from "../../../enums/data-grid-type.enum";
import {ResponseService} from "../../../services/response.service";
import {HeatMapService} from "../../../services/heat-map.service";
import {ColorCodeService} from "../../../services/color-code.service";
import {AnalysisShareService} from "../../../services/analysis-share.service";
import {AnalysisService} from "../../../services/analysisService";
import {Analysis} from "../../../enums/analaysis.enum";
import {ColorCode} from "../../../models/color-data.model";
import {finalize} from "rxjs/operators";
import {ArrayType} from "@angular/compiler";
import {Observable} from "rxjs/Observable";
import {zip} from "rxjs";

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})

export class ColorPickerComponent implements OnInit {

  colorDefaultSuccessAlert: boolean;
  colorDefaultErrorAlert: boolean;
  colorDefaultChangesAlert: boolean;
  colorRangeErrorAlert: boolean = false;
  colorDuplicateAlert: boolean = false;
  colorsFailSaveAlert: boolean = false;
  messageSuccessTimer: boolean = true;
  value: number = 40;
  highValue: number = 60;
  selectedColor: string;
  selectedFontColor: string;
  showLoadDefaultPopUp: boolean = false;
  loadDefaultBtnEnable: boolean = false;
  @Input() options: Options;
  @Input() successMsgTimeout: number;
  @Input() ksaId: number;
  @Input() dataGridType: DataGridTypeEnum;
  @Input() colorData: Array<ColorCode>;
  @Input() isColorModalOpen: boolean;
  @Output() closeColorPicker: EventEmitter<void> = new EventEmitter<void>();
  @Output() addColorToTable: EventEmitter<ColorCode> = new EventEmitter<ColorCode>();
  @Output() deleteColorFromTable: EventEmitter<number> = new EventEmitter<number>();
  newAddedColorCodes: Array<ColorCode> = new Array<ColorCode>();
  deletedColorCodes: Array<ColorCode> = new Array<ColorCode>();


  constructor(public responseService: ResponseService,
              private heatMapService: HeatMapService,
              private colorCodeService: ColorCodeService,
              private analysisShareService: AnalysisShareService,
              public analysisService: AnalysisService) {
  }

  ngOnInit(): void {
    this.isDefaultPreferencesAvailable();
    this.selectedColor = Analysis.defaultColor;
    this.selectedFontColor = Analysis.defaultFontColor;
  }

  onAddColorToList(): void {
    if (!this.analysisShareService.isValidColor(this.selectedColor, this.getAvailableColorCodes())) {
      this.colorDuplicateAlert = true;
    } else {
      if (this.analysisShareService.isValidColorRange(this.value, this.highValue, this.getAvailableColorCodes())) {
        this.addColorToList();
        this.analysisShareService.addColorToList(this.selectedColor, this.selectedFontColor, this.value, this.highValue, this.ksaId, [], this.dataGridType);
        this.colorRangeErrorAlert = false;
        this.colorDuplicateAlert = false;
      } else {
        this.colorRangeErrorAlert = true;
      }
    }
  }

  addColorToList(): void {
    const newColor: ColorCode = this.buildColorCodeFromNewInput();
    const similarColorToDelete = this.getColorFromArrayBasedOnSimilarProperties(newColor, this.deletedColorCodes);
    if (similarColorToDelete) {
      this.removeColorOfDeleteList(similarColorToDelete);
    } else {
      this.newAddedColorCodes.push(newColor);
    }
  }

  persistAddedColors(): void {
    if (this.newAddedColorCodes.length === 0) {
      this.closeColorPicker.emit();
      return;
    }
    const savedColors: Array<ColorCode> = new Array<ColorCode>();
    this.colorCodeService.addColorCodesMatrix(this.newAddedColorCodes)
      .pipe(finalize(() => {
        if (this.colorsFailSaveAlert) {
          return;
        }
        savedColors.forEach(color => this.addColorToMainComponent(color));
        this.closeColorPicker.emit();
      }))
      .subscribe(data => {
        data.forEach(color => savedColors.push(color));
        this.colorsFailSaveAlert = false;
      }, error => this.colorsFailSaveAlert = true);
  }

  addColorToMainComponent(color: ColorCode): void {
    this.addColorToTable.emit(color);
  }

  onDeleteColorCode(deletedColor: ColorCode): void {
    if (deletedColor && !this.deletedColorCodes.includes(deletedColor)) {
      if (this.newAddedColorCodes.includes(deletedColor)) {
        const deletedColorIndex: number = this.newAddedColorCodes.indexOf(deletedColor);
        this.newAddedColorCodes.splice(deletedColorIndex, 1);
      } else {
        this.deletedColorCodes.push(deletedColor);
      }
    }
  }

  deleteColors(): void {
    this.deletedColorCodes.filter(color => color.id).forEach(colorCode => {
      this.colorCodeService.deleteColorCodeMatrix(colorCode.id).subscribe(data => {
      });
      const deletedColorIndex: number = this.colorData.indexOf(colorCode);
      this.deleteColorFromTable.emit(deletedColorIndex);
    });
    this.deletedColorCodes = [];
  }

  openLoadDefaultPopUp(): void {
    this.showLoadDefaultPopUp = true;
  }

  isDefaultPreferencesAvailable(): void {
    this.colorCodeService.getColorCodePreferenceByType(this.dataGridType).subscribe(data => {
      this.loadDefaultBtnEnable = data;
    });
  }

  setLoadDefaultColorCode(): void {
    this.colorCodeService.setLoadDefaultColorByColorDto(this.ksaId, this.dataGridType).subscribe(data => {
      if (data) {
        this.clearChanges();
        this.deletedColorCodes = Array.from(this.colorData);
        data.forEach(color => {
          const componentSimilarColor = this.getColorFromArrayBasedOnSimilarProperties(color, this.colorData);
          if (componentSimilarColor) {
            this.removeColorOfDeleteList(componentSimilarColor);
          } else {
            this.transformPreferenceToNewColor(color);
            this.newAddedColorCodes.push(color);
          }
        })
        this.showLoadDefaultPopUp = false;
      }
    });
  }

  setDefaultColorCode(): void {
    this.colorCodeService.setAsDefaultColor(this.dataGridType, this.getAvailableColorCodes()).subscribe(data => {
        this.colorDefaultSuccessAlert = true;
        this.messageSuccessTimer = true;
        this.colorDefaultErrorAlert = false;
        this.isDefaultPreferencesAvailable();
        setTimeout(() => {
          this.messageSuccessTimer = false;
        }, this.successMsgTimeout);
      },
      error => {
        this.colorDefaultSuccessAlert = false;
        this.colorDefaultErrorAlert = true;
      });
  }

  getAvailableColorCodes(): (ColorCode)[] {
    const availableColorCodes: (ColorCode)[] = this.colorData ? Array.from(this.colorData) : [];
    this.newAddedColorCodes.forEach(color => availableColorCodes.push(color));
    return availableColorCodes.filter(color => !this.deletedColorCodes.includes(color));
  }

  clearChanges(): void {
    this.newAddedColorCodes = [];
    this.deletedColorCodes = [];
  }

  applyColorCodeChangesToComponent(): void {
    this.deleteColors();
    this.persistAddedColors();
  }

  private buildColorCodeFromNewInput(): ColorCode {
    return {
      id: 0,
      fontColorHexCode: this.selectedFontColor,
      backgroundColorHexCode: this.selectedColor,
      from: this.value,
      to: this.highValue,
      masterKsa: {
        id: this.ksaId
      },
      dataGridType: this.dataGridType
    };
  }

  private getColorFromArrayBasedOnSimilarProperties(compareColor: ColorCode, fromArray: (ColorCode)[]): ColorCode {
    if (!fromArray || !compareColor) {
      return null;
    }
    return fromArray.find(color => color.backgroundColorHexCode === compareColor.backgroundColorHexCode &&
      color.fontColorHexCode === compareColor.fontColorHexCode &&
      color.from === compareColor.from &&
      color.to === compareColor.to);
  }

  private removeColorOfDeleteList(colorToRemove): void {
    if (colorToRemove) {
      const indexOfColor: number = this.deletedColorCodes.lastIndexOf(colorToRemove);
      if (indexOfColor >= 0) {
        this.deletedColorCodes.splice(this.deletedColorCodes.lastIndexOf(colorToRemove), 1);
      }
    }
  }

  private transformPreferenceToNewColor(colorCode: ColorCode): void {
    if (colorCode) {
      colorCode.id = null;
      colorCode.masterKsa = {id: this.ksaId};
    }
  }
}
