<div  *ngIf="!closedKsaStatus">
  <div class="thanks-heading">
    <h1>{{"ksaEngagement.emailManagement.participantKSA.participateKSA.thankYou" | translate}}</h1>
    <h2>...............................................................</h2>
  </div>
  <div class="thanks-body">
      <h4>
        {{"ksaEngagement.emailManagement.participantKSA.participateKSA.surveyThanksMessage" | translate}}
      </h4>
  </div>
</div>
<div *ngIf="closedKsaStatus">
  <div class="sorry-heading" >
    <h1>{{"ksaEngagement.emailManagement.participantKSA.participateKSA.closedKsaMessage" | translate}}</h1>
    <h2>..........................................................................................................</h2>
  </div>
  <div class="contact-body">
      <h4>
        {{"ksaEngagement.emailManagement.participantKSA.participateKSA.contactVmware" | translate}}
      </h4>
  </div>
  
</div>
