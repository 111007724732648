/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {AnalysisResponseData, ClosedKsaList} from '../../../models/analysis-response-data.model';
import {KsaDetails} from '../../../models/master-ksa.model';
import {AnalysisShareService} from '../../../services/analysis-share.service';
import {KsaAdministrationService} from '../../../services/ksa-administration.service';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {ResponseService} from "../../../services/response.service";

@Component({
  selector: 'app-tab-analysis',
  templateUrl: './tab-analysis.component.html',
  styleUrls: ['./tab-analysis.component.scss']
})
export class TabAnalysisComponent implements OnInit, OnDestroy{

  closedKsaList:KsaDetails[] =[];
  ksaIdIsAdvancedMap: Map<number, boolean> = new Map<number, boolean>();
  isErrorOnLoad: boolean = false;
  isKsaSelected: boolean = false;
  selectedKsa: ClosedKsaList;
  ksaId: number;
  newKsaId: number;
  heatMapSelected: boolean = false;
  roleMapSelected: boolean = false;
  roleProfileSelected: boolean = false;
  trainingSelected: boolean = false;
  certificationSelected: boolean = false;
  responseData: AnalysisResponseData[];
  globalThreshold: number;
  errorMessage: string;

  constructor(private ksaAdministrationService: KsaAdministrationService,private analysisShareService : AnalysisShareService,
              private responseService: ResponseService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getClosedKsaList();
  }

  getClosedKsaList(): void {
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.responseService.getAllClosedKsa()
        .pipe(finalize(() => hideSpinner()))
        .subscribe(ksaList => {
            this.closedKsaList = ksaList;
            ksaList.forEach(item => {
              this.ksaIdIsAdvancedMap[item.id] = item.isAdvance;
            });
            this.isErrorOnLoad = false;
            this.errorMessage = '';
          },
          error => {
            this.isErrorOnLoad = true;
            this.errorMessage = error.error.message;
          });
    });
  }

  onSelectKsaDetails(ksaId: number) : void{
    this.analysisShareService.clearKSAresponseData();
    this.heatMapSelected = true;
    this.roleMapSelected = false;
    this.roleProfileSelected = false;
    this.trainingSelected = false;
    this.certificationSelected = false;
    this.ksaId = ksaId;
    this.selectedKsa = this.closedKsaList.find(ksa => ksa.id == ksaId);
    this.selectedKsa.isAdvance = this.ksaIdIsAdvancedMap[this.ksaId];
    this.globalThreshold = this.selectedKsa.globalThreshold;
    this.analysisShareService.setGlobalThreshold(this.globalThreshold);
    this.analysisShareService.getHeatMapAndRoleMapData(this.ksaId).subscribe(data => {
        this.responseData = data;
        this.isKsaSelected = true;
        this.isErrorOnLoad = false;
        this.errorMessage = '';
      },
      error => {
        this.responseData = [];
        this.isErrorOnLoad = true;
        this.errorMessage = error.error.message;
      });
  }

  onSelectKsaMode(ksaDataIsAdvance: boolean) : void{
    this.selectedKsa.isAdvance = ksaDataIsAdvance;
    this.analysisShareService.setKSADetailsAnalysis(this.selectedKsa);
  }

  ngOnDestroy(): void {
    this.analysisShareService.clearKSAresponseData();
  }
}
