export const SkipAndDisplayLogicConditions = [
  {
    id: 1,
    displayConditionName: 'setIsSelectedDisplayLogic',
    skipConditionName: 'setIsSelectedSkipLogic'
  },
  {
    id: 2,
    displayConditionName: 'setIsNotSelectedDisplayLogic',
    skipConditionName: 'setIsNotSelectedSkipLogic'
  },
  {
    id: 3,
    displayConditionName: 'setIsDisplayedDisplayLogic',
    skipConditionName: 'setIsDisplayedSkipLogic'
  },
  {
    id: 4,
    displayConditionName: 'setIsNotDisplayedDisplayLogic',
    skipConditionName: 'setIsNotDisplayedSkipLogic'
  },
  {
    id: 5,
    displayConditionName: 'setIsEqualToDisplayLogic',
    skipConditionName: 'setIsEqualToSkipLogic'
  },
  {
    id: 6,
    displayConditionName: 'setIsNotEqualToDisplayLogic',
    skipConditionName: 'setIsNotEqualToSkipLogic'
  },
  {
    id: 7,
    displayConditionName: 'setIsGreaterThenDisplayLogic',
    skipConditionName: 'setIsGreaterThenSkipLogic'
  },
  {
    id: 8,
    displayConditionName: 'setIsNotGreaterThenDisplayLogic',
    skipConditionName: 'setIsNotGreaterThenSkipLogic'
  },
  {
    id: 9,
    displayConditionName: 'setIsGreaterThenOrEqualToDisplayLogic',
    skipConditionName: 'setIsGreaterThenOrEqualToSkipLogic'
  },
  {
    id: 10,
    displayConditionName: 'setIsLessThenDisplayLogic',
    skipConditionName: 'setIsLessThenSkipLogic'
  },
  {
    id: 11,
    displayConditionName: 'setIsNotLessThenDisplayLogic',
    skipConditionName: 'setIsNotLessThenSkipLogic'
  },
  {
    id: 12,
    displayConditionName: 'setIsLessThenOrEqualToDisplayLogic',
    skipConditionName: 'setIsLessThenOrEqualToSkipLogic'
  },
  {
    id: 13,
    displayConditionName: 'setIsNullDisplayLogic',
    skipConditionName: 'setIsNullSkipLogic'
  },
  {
    id: 14,
    displayConditionName: 'setIsNotNullDisplayLogic',
    skipConditionName: 'setIsNotNullSkipLogic'
  }
];
