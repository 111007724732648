<clr-modal [clrModalOpen]="isOpen" (clrModalOpenChange)="onClose()">
  <h3 class="modal-title">{{'analysisAndReports.scorecards.sendScorecards' | translate}}</h3>
  <div class="modal-body">
    <form clrForm [formGroup]="emailForm">
      <p>{{'analysisAndReports.scorecards.ccMessage' | translate}}</p>
      <clr-input-container>
        <label for="emailInput">{{'analysisAndReports.scorecards.ccLabel' | translate}}</label>
        <input clrInput type="text" id="emailInput" formControlName="emails"/>
        <clr-control-helper *ngIf="!emailForm.controls.emails.errors">
          {{'analysisAndReports.scorecards.ccHelper' | translate}}
        </clr-control-helper>
        <clr-control-error *ngIf="emailForm.controls.emails.errors?.emailFormat">
          {{'analysisAndReports.scorecards.ccError' | translate}}
        </clr-control-error>
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button id="{{btnId.btnCancelScorecard}}" class="btn btn-sm" (click)="onClose()">
      {{'common.cancel' | translate}}
    </button>
    <button id="{{btnId.btnSendScorecard}}" class="btn btn-sm btn-primary"
            (click)="onSubmit()">
      {{'analysisAndReports.scorecards.sendScorecardBtn' | translate}}
    </button>
  </div>
</clr-modal>
