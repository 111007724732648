/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AnalysisShareService} from "../../../services/analysis-share.service";

@Component({
  selector: 'app-participant-answer-report',
  templateUrl: './participant-answer-report.component.html',
  styleUrls: ['./participant-answer-report.component.scss']
})
export class ParticipantAnswerReportComponent implements OnInit, OnDestroy {

  ksaId: number;
  participantId: number

  users: any = []

  constructor(private analysisService: AnalysisShareService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.ksaId = params.ksaId;
      this.participantId = params.participantId;
    });
    this.analysisService.getParticipantAnswers(this.ksaId, this.participantId).subscribe(data =>{
      this.users = data;
    });
  }

  ngOnDestroy(): void {
    this.users = [];
  }
}
