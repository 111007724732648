<div class="login-wrapper">
  <form class="login">
    <label class="title">
      <h3 class="welcome">{{'login.welcomeMsg' | translate}}</h3>
      {{'login.portalMsg' | translate}}
      <h5 class="hint">{{'login.signInInfo' | translate}}</h5>
    </label>
    <div class="login-group">
      <div *ngIf="loginError" class="error active">
        {{'login.loginFailMessage'|translate}}
      </div>
      <button class="btn btn-lg btn-primary btn-block" type="button" (click)="onLogin()">
        {{'login.signIn' | translate}}</button>
    </div>
  </form>
</div>
<app-footer class="footer"></app-footer>
