<div *ngIf="showLoader">
  <clr-spinner>Loading ...</clr-spinner>
</div>
<div *ngIf="!showLoader">
  <div *ngIf="!isParticipantDetails">
    <div class="datagrid-area">
    <form #addForm="ngForm" autocomplete="off" class=" clr-row clr-form" clrForm>
      <clr-combobox-container class="clr-col-3 clr-row">
        <label class="clr-col-4">{{'surveyTemplate.questionSheet.isSkillGroup'|translate}}</label>
        <clr-combobox [(ngModel)]="skillGroupSelected" [disabled]="disabled" class="clr-col-8" clrMulti="true"
                      name="multiSelect">
          <ng-container *clrOptionSelected="let selected">
            {{selected}}
          </ng-container>
          <clr-options>
            <clr-option *clrOptionItems="let skill of skillGroups" [clrValue]="skill.skillGroupName">
              {{skill.skillGroupName}}
            </clr-option>
          </clr-options>
        </clr-combobox>
      </clr-combobox-container>
      <div *hasAccess="functionality.analysis" class="clr-col-9 btn-alignment">
        <button (click)="onRefresh()" [disabled]="!showDatagrid" class="btn btn-sm btn-primary">
          {{"analysisAndReports.reports.refresh" | translate}}
        </button>
        <button (click)="onOpenNewWindow()" *ngIf="showNewWindowButton" [disabled]="!showDatagrid"
                class="btn btn-sm btn-primary">
          {{"analysisAndReports.reports.openNewWindow" | translate}}
        </button>
        <button (click)="onShowColorModel()" [disabled]="!showDatagrid" class="btn btn-sm btn-primary">
          {{"analysisAndReports.reports.colorPickerBtn" | translate}}</button>
        <button (click)="exportExcelHeatMap()" [disabled]="!showDatagrid" class="btn btn-sm btn-primary">
          {{"analysisAndReports.reports.export" | translate}}</button>
      </div>
    </form>
      <div *ngIf="isSavedResponse" class="alert alert-success" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
            </div>
            <span class="alert-text">{{'analysisAndReports.responseManagement.responseSuccess' | translate}}</span>
          </div>
        </div>
      </div>
    <clr-datagrid class="role-profile-datagrid" *ngIf="showDatagrid" (clrDgRefresh)="heatMapRefresh($event)" [clrDgLoading]="loading">
      <clr-dg-column class="width-100">{{"analysisAndReports.reports.actions" | translate}}</clr-dg-column>
      <clr-dg-column *ngFor="let column of responseColumnSettings; let i = index"
                     class="width-175" [clrDgField]="column?.responseColumnDisplay?.questionName"
                     [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="responseColumnSettings[i].isHideable">
          {{column?.responseColumnDisplay?.questionName}}
        </ng-template>
      </clr-dg-column>
      <clr-dg-column *ngFor="let column of (responseData[0]?.participantAnalysisList || [])"
        [hidden]="!skillGroupSelected?.includes(column.skillGroupName)"
        class="width-175">
        {{column?.skill?.skillName}}
      </clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell class="width-100">Pin</clr-dg-cell>
        <clr-dg-cell *ngFor="let column of responseColumnSettings; let i = index"
                     [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''"
                     style="text-align: center" class="width-175">
          <clr-icon shape="pin" title="pin" (click)="setPinnedColumn(column.responseColumnDisplay.questionName)"
                    [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'pinned-column' : ''">
          </clr-icon>
        </clr-dg-cell>
        <clr-dg-cell *ngFor="let column of (responseData[0]?.participantAnalysisList || [])"
                     [hidden]="!skillGroupSelected?.includes(column.skillGroupName)" class="width-175"></clr-dg-cell>
      </clr-dg-row>
        <clr-dg-row *ngFor="let response of responseData; let i = index">
          <clr-dg-cell class="width-100">
            <clr-icon (click)="onShowParticipantDetails(response,i)" shape="eye"></clr-icon>
            <clr-icon (click)="onShowAnswers(response)" class="left-margin" shape="pop-out"></clr-icon>
            <clr-icon *ngIf="response.visited" class="left-margin" shape="check" style="color:#2e8500"></clr-icon>
          </clr-dg-cell>
          <clr-dg-cell *ngFor="let column of responseColumnSettings" class="width-175"
                       [attr.name]="column?.responseColumnDisplay?.questionName+i"
                       [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''">
            {{analysisService.getAnswerFromParticipant(response.responseColumnWithParticipants, column?.responseColumnDisplay?.id)}}
          </clr-dg-cell>
          <clr-dg-cell *ngFor="let column of getParticipantAnalysisList(response)"
                       [hidden]="!skillGroupSelected?.includes(column.skillGroupName)"
                       [style.background]="column.colorCode" [style.color]="column.fontColorCode"
                       [attr.name]="column?.skill?.skillName+i"
                       class="width-175" id="analysis">
            {{column.heatMapValue === null ? ('analysisAndReports.reports.notAvailable' | translate) : column.heatMapValue + '%'}}
          </clr-dg-cell>
        </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-column-toggle>
          <clr-dg-column-toggle-title class="column-toggle-title">Columns
            <clr-icon role="button" shape="floppy" title="Save" (click)="onSaveColumnSettings()" class="save-col-icon"> </clr-icon>
          </clr-dg-column-toggle-title>
        </clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="pageSizeService.getPageSize() | async" [clrDgTotalItems]="total"
                           [(clrDgPage)]="page">
          <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Responses per page</clr-dg-page-size>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} responses
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
    </div>
  </div>

  <div *ngIf="isParticipantDetails">
    <app-participant-details (isParticipantDetails)="onShowParticipant($event)" [ksaId]="ksaId"
                             [participantData]="participantData"
                             [participantIndex]="participantIndex"></app-participant-details>
  </div>
</div>

<color-picker *ngIf="isColorModelOpen" [options]="options" [colorData]="this.colorData" [ksaId]="ksaId"
              [isColorModalOpen]="isColorModelOpen" [dataGridType]="dataGridType" (addColorToTable)="addColor($event)"
              [successMsgTimeout]="this.successMsgTimeout" (closeColorPicker)="onHideColorModal()"
              (deleteColorFromTable)="colorData.splice($event,1)"></color-picker>
