export enum ButtonId {
  // Manage User
  btnMngUserEdit = 'btn-mng-user-edit',
  btnMngUserAdd = 'btn-mng-user-add',
  btnMngUserDelete = 'btn-mng-user-delete',
  btnMngUserMultipleDelete = 'btn-mng-user-multiple-delete',

  // Manage Role
  btnMngRoleEdit = 'btn-mng-role-edit',
  btnMngRoleAdd = 'btn-mng-role-add',
  btnMngRoleDelete = 'btn-mng-role-delete',

  // Manage Course
  btnMngCourseEdit = 'btn-mng-course-edit',
  btnMngCourseAdd = 'btn-mng-course-add',
  btnMngCourseDelete = 'btn-mng-course-delete',
  btnMngCourseArchive = 'btn-mng-course-archive',
  btnMngCourseActivate = 'btn-mng-course-activate',
  btnMngCourseNewVersion = 'btn-mng-course-new-version',

  // Manage Skill
  btnMngSkillEdit = 'btn-mng-skill-edit',
  btnMngSkillAdd = 'btn-mng-skill-add',
  btnMngSkillDelete = 'btn-mng-skill-delete',
  btnMngSkillArchive = 'btn-mng-skill-archive',
  btnMngSkillActivate = 'btn-mng-skill-activate',
  btnMngSkillGroup = 'btn-mng-skill-group',

  // Manage Role Profile
  btnMngRoleProfileEdit = 'btn-mng-role-profile-edit',
  btnMngRoleProfileAdd = 'btn-mng-role-profile-add',
  btnMngRoleProfileDelete = 'btn-mng-role-profile-delete',
  btnMngRoleProfileClone = 'btn-mng-role-profile-duplicate',

  // Manage Role Profile Group
  btnMngRoleProfileGroupEdit = 'btn-mng-role-profile-group-edit',
  btnMngRoleProfileGroupAdd = 'btn-mng-role-profile-group-add',
  btnMngRoleProfileGroupDelete = 'btn-mng-role-profile-group-delete',

  // Manage Email Template
  btnMngEmailTemplateEdit = 'btn-mng-email-template-edit',
  btnMngEmailTemplateAdd = 'btn-mng-email-template-add',
  btnMngEmailTemplateDelete = 'btn-mng-email-template-delete',
  btnMngEmailTemplateMultipleDelete = 'btn-mng-email-template-multiple-delete',

  // Manage Survey Template
  btnMngSurveyTemplateEdit = 'btn-mng-survey-template-edit',
  btnMngSurveyTemplateAdd = 'btn-mng-survey-template-add',
  btnMngSurveyTemplateDelete = 'btn-mng-survey-template-delete',
  btnMngSurveyTemplateArchive = 'btn-mng-survey-template-archive',
  btnMngSurveyTemplateMultiArchive = 'btn-mng-survey-template-multi-archive',
  btnMngSurveyTemplatePublish = 'btn-mng-survey-template-publish',
  btnMngSurveyTemplateNewVersion = 'btn-mng-survey-template-new-version',
  btnMngSurveyTemplateClone = 'btn-mng-survey-template-clone',
  btnMngSurveyTemplatePreview = 'btn-mng-survey-template-preview',

  // Manage Survey Template - Translations
  btnMngSurveyTranslationOpenAddTranslationDialog = 'btn-mng-survey-translation-open-add-translation-dialog',
  btnMngSurveyTranslationCloseAddTranslationDialog = 'btn-mng-survey-translation-close-add-translation-dialog',
  btnMngSurveyTranslationSubmitAddTranslation = 'btn-mng-survey-translation-submit-add-translation-dialog',
  btnMngSurveyTranslationOpenEditTranslationDialog = 'btn-mng-survey-translation-open-edit-translation-dialog',
  btnMngSurveyTranslationCloseEditTranslationDialog = 'btn-mng-survey-translation-close-edit-translation-dialog',
  btnMngSurveyTranslationSubmitEditTranslation = 'btn-mng-survey-translation-submit-edit-translation-dialog',
  btnEditTranslationPublish = 'btn-edit-translation-publish',

  // KSA Administration
  btnKsaAdministrationAdd = 'btn-ksa-administration-add',
  btnKsaAdministrationEdit = 'btn-ksa-administration-edit',
  btnKsaAdministrationDelete = 'btn-ksa-administration-delete',
  btnKsaAdministrationMultiDelete = 'btn-ksa-administration-multiple-delete',
  btnKsaAdministrationClone = 'btn-ksa-administration-clone',
  btnKsaAdministrationExpire = 'btn-ksa-administration-expire',
  btnKsaAdministrationActivate = 'btn-ksa-administration-activate',

  // KSA Email Management
  btnEmailManagementAddThanksMail = 'btn-email-management-add-thanks-email',
  btnEmailManagementEditThanksMail = 'btn-email-management-edit-thanks-email',
  btnEmailManagementDeleteThanksMail = 'btn-email-management-delete-thanks-email',
  btnEmailManagementSendThanksMail = 'btn-email-management-send-thanks-email',
  btnEmailManagementAddInviteMail = 'btn-email-management-add-invite-email',
  btnEmailManagementEditInviteMail = 'btn-email-management-edit-invite-email',
  btnEmailManagementDeleteInviteMail = 'btn-email-management-delete-invite-email',
  btnEmailManagementSendInviteMail = 'btn-email-management-send-invite-email',
  btnEmailManagementReminderMail = 'btn-email-management-set-reminder-email',
  btnEmailManagementDeleteReminderMail = 'btn-email-management-delete-reminder-email',
  btnEmailManagementDemoUrl = 'btn-email-management-demo-url',
  btnEmailManagementAnonymousUrl = 'btn-email-management-anonymous-url',

  // KSA Team Management
  btnTeamManagementEdit = 'btn-team-management-edit',
  btnTeamManagementAdd = 'btn-team-management-add',
  btnTeamManagementDelete = 'btn-team-management-delete',
  btnTeamManagementMultipleDelete = 'btn-team-management-multiple-delete',

  // Scorecards
  btnSendScorecards = 'btn-send-scorecards',
  btnDownloadScorecards = 'btn-download-scorecards',
  btnSendScorecard = 'btn-send-scorecard',
  btnNextScorecard = 'btn-next-scorecard',
  btnCancelScorecard = 'btn-cancel-scorecard'
}
