<clr-tabs class="master-surveyTabs">
  <clr-tab *ngIf="disableMasterSurveyTab">
    <button clrTabLink>{{'surveyTemplate.masterSurveyTabs.tabSurveytemplate'|translate}}</button>
    <clr-tab-content *clrIfActive>
      <app-master-survey [disableSurveyNameNewVersion]="disableSurveyNameNewVersion" [isAdd]="isAdd"
                         [isClone]="isClone" [isNewVersion]="isNewVersion"
                         (showMasterSurveyTab)="onShowMasterSurveyTab($event)"
                         [parentSurveyId]="parentSurveyId"></app-master-survey>
    </clr-tab-content>
  </clr-tab>
  <clr-tab *ngIf="disableMasterSurveyTab">
    <button clrTabLink>{{'tab.masterSurvey.mapSkill'|translate}}</button>
    <clr-tab-content *clrIfActive>
      <app-map-skill></app-map-skill>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>{{'tab.masterSurvey.preview'|translate}}</button>
    <clr-tab-content *clrIfActive="showPreview">
      <app-preview (showMasterSurveyTab)="onShowMasterSurveyTab($event)"></app-preview>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>
<clr-modal *ngIf="editingSessionExpired" [clrModalOpen]="true" (clrModalOpenChange)=onSessionExpireClick()>
  <h3 class="modal-title">{{'tab.masterSurvey.sessionExpiredModalTitle'|translate}}
  </h3>
  <div class="modal-body">
    <div class="alert alert-warning">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <hr>
          <span class="alert-text">
              {{'tab.masterSurvey.sessionExpiredModalTextSentenceInfo'|translate}}
            <br>
            {{'tab.masterSurvey.sessionExpiredModalTextSentenceAction'|translate}}
            </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"
            (click)="onSessionExpireClick()">{{'tab.masterSurvey.sessionExpiredModalRedirectButtonText'|translate}}</button>
  </div>
</clr-modal>
