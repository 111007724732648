import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Functionality} from "../../../enums/functionality.enum";
import {ConfigVersionService} from "../../../services/config-version.service";
import {SurveyTemplateConfigStatus, SurveyTemplateConfigVersion} from "../../../models/config-version.model";

@Component({
  selector: 'app-tab-mng-skills-skill-order-role-profiles-and-role-profile-groups',
  templateUrl: './tab-mng-skills-skill-order-role-profiles-and-role-profile-groups.component.html',
  styleUrls: ['./tab-mng-skills-skill-order-role-profiles-and-role-profile-groups.scss']
})
export class TabMngSkillsSkillOrderRoleProfilesAndRoleProfileGroupsComponent implements OnInit {

  surveyTemplateConfig: SurveyTemplateConfigVersion;
  surveyTemplateConfigName = ''

  constructor(private readonly route: ActivatedRoute,
              private readonly configVersionService: ConfigVersionService) {
  }

  ngOnInit(): void {
    const surveyTemplateConfigId = +this.route.snapshot.paramMap.get('id');

    this.configVersionService.getById(surveyTemplateConfigId)
      .subscribe(data => {
        this.surveyTemplateConfig = data;
        this.surveyTemplateConfigName = data.name;
      });
  }

  updateSurveyTemplateConfigName(): void {
    if (this.surveyTemplateConfigName === this.surveyTemplateConfig.name) {
      return;
    }
    this.configVersionService.update({
      ...this.surveyTemplateConfig, name: this.surveyTemplateConfigName
    }).subscribe(() => []);
  }

  get Functionality(): typeof Functionality {
    return Functionality;
  }

  get SurveyTemplateConfigStatus(): typeof SurveyTemplateConfigStatus {
    return SurveyTemplateConfigStatus;
  }
}
