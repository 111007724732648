/********************************************************************** *
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
 **********************************************************************/

import {ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {SkillsService} from '../../../services/skills.service';
import {SkillGroup, SkillGroupContent} from "../../../models/skillgroup.model";
import {NgForm} from '@angular/forms';
import {CoursesService} from '../../../services/courses.service';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {CourseContent, CourseSkillRequest, Tag} from '../../../models/course.model';
import {TranslateService} from "@ngx-translate/core";
import {skillColumnHeaderList, SkillContentEntity, SkillPage} from '../../../models/skill-page.model';
import {SurveyControls} from "../../../enums/survey-controls.enum";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from "rxjs/operators";
import {SurveyTemplateConfigVersion} from "../../../models/config-version.model";
import {
  SkillRoleProfileManagementService
} from "../tab-mng-skills-skill-order-role-profiles-and-role-profile-groups/skill-role-profile-management.service";

@Component({
  selector: 'app-mng-skills',
  templateUrl: './mng-skills.component.html',
  styleUrls: ['./mng-skills.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MngSkillsComponent implements OnInit {

  @Input() surveyTemplateConfigVersion: SurveyTemplateConfigVersion;

  surveyStatusOption: string[] = ['Draft', 'Archived', 'Active'];
  state: ClrDatagridStateInterface;
  statusToFilter: string = '';
  page: number;
  loading: boolean = false;
  total: number;
  allSkills: SkillPage = {
    content: []
  };
  addSkillFormOpen: boolean = false;
  editSkillFormOpen: boolean = false;
  isSkillGroupVisible: boolean = false;
  showDeleteSkillPopUp: boolean = false;
  showDeleteSkillGroupPopUp: boolean = false;
  skillGroups: SkillGroup = {
    content: []
  };
  editSkillGroup: SkillGroupContent;
  deleteId: number;
  errorMsg: string = '';
  skillGroupAddBtn: boolean = true;
  lastSequenceNoSkillGroup: number = 0;
  courseTags = Array<Tag>();
  activeCourses: CourseContent[] = [];
  selected: CourseContent[] = [];
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  previousSelectedState: CourseContent[] = [];

  addSkillGroup: SkillGroupContent = {
    id: 0,
    skillGroupName: '',
    isSkillMapped: false
  };

  newSkill: Partial<SkillContentEntity> = {
    skillName: '',
    skillDescription: '',
    skillGroup: {},
    tagSkillMaps: []
  };

  newSkillToPush: Partial<SkillContentEntity> = {
    skillName: '',
    skillDescription: '',
    skillGroup: {},
    tagSkillMaps: []
  };

  functionality: typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;

  skillColumnHeaderList: (skillColumnHeaderList)[] = this.getSkillColumnHeaders();
  isSavedResponse: boolean = false;

  successMsgTimeout: number = 5000;
  selectedTag! :Tag;
  warningSequenceNoChange:boolean;

  constructor(public skillService: SkillsService, public coursesService: CoursesService, private translate: TranslateService,
              private readonly changeDetector: ChangeDetectorRef, public skillRoleProfileManagementService: SkillRoleProfileManagementService) {
  }

  ngOnInit(): void {
    this.populateSkillGroups();
    this.getActiveCourses();

    const columnHeaderString = sessionStorage.getItem("skillColumnHeaders");
    if (columnHeaderString) {
      this.skillColumnHeaderList = JSON.parse(columnHeaderString);
    }
  }

  ngAfterContentInit(): void {
    this.changeDetector.detectChanges();
  }

  getActiveCourses(): void {
    this.activeCourses = [];
    this.coursesService.getActiveCourses().subscribe((courseSkillRequest: CourseSkillRequest[]) => {
        courseSkillRequest.forEach(request => {
          request.course.isDefault = false;
        });
        this.activeCourses = courseSkillRequest.map(csr => csr.course);
        this.activeCourses.sort((a, b) => (a.courseName.toLowerCase() > b.courseName.toLowerCase()) ? 1 : -1)
      },
      error => console.log(error));
  }

  isCurrentCourseSelected(course: CourseContent): boolean {
    return !(this.selected.filter(selectedCourse => selectedCourse === course).length > 0);
  }

  getSkillColumnHeaders(): skillColumnHeaderList[] {
    this.skillColumnHeaderList = [
      {
        columnName: "Skill Name",
        isHide: false,
        sequenceNumber: 1,
        columnFilterName: "skillName"
      },
      {
        columnName: "Skill Description",
        isHide: false,
        sequenceNumber: 2,
        columnFilterName: "skillDescription"
      },
      {
        columnName: "Skill Group",
        isHide: false,
        sequenceNumber: 3,
        columnFilterName: "skillGroup.skillGroupName"
      },
      {
        columnName: "Courses",
        isHide: false,
        sequenceNumber: 4
      },
      {
        columnName: "Status",
        isHide: false,
        sequenceNumber: 5,
        columnFilterName: "status"
      }
    ]

    return this.skillColumnHeaderList;
  }

  onAddSkill(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.newSkillToPush.skillName = (this.newSkill.skillName).trim();
    this.newSkillToPush.skillDescription = (this.newSkill.skillDescription).trim();
    this.newSkillToPush.skillGroup.id = this.newSkill.skillGroup.id;
    this.newSkillToPush.surveyTemplateConfigVersion = this.surveyTemplateConfigVersion;
    this.newSkillToPush.skillCourses = [];
    for (const course of this.selected) {
      this.newSkillToPush.skillCourses.push({
        course: course,
        isDefault: course.isDefault
      });
    }
    delete this.newSkillToPush.id;

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.skillService.saveSkill(this.newSkillToPush)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.addSkillFormOpen = false;
        form.onReset();
        this.refresh(this.state);
        this.newSkill = {
          skillName: '',
          skillDescription: '',
          skillGroup: {},
          tagSkillMaps: []
        }
        this.errorMsg = '';
      },
      error => this.errorMsg = error.error.message);
  }

  onEditSkill(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.newSkillToPush.id = this.newSkill.id;
    this.newSkillToPush.skillName = (this.newSkill.skillName).trim();
    this.newSkillToPush.skillDescription = (this.newSkill.skillDescription).trim();
    this.newSkillToPush.skillGroup.id = this.newSkill.skillGroup.id;
    this.newSkillToPush.status = this.newSkill.status;
    this.newSkillToPush.tagSkillMaps = [];
    this.newSkillToPush.skillCourses = [];
    this.newSkillToPush.surveyTemplateConfigVersion = this.surveyTemplateConfigVersion;
    for (const course of this.selected) {
      this.newSkillToPush.skillCourses.push({
        course: course,
        isDefault: course.isDefault
      });
    }
    this.newSkillToPush.sequenceNo = this.newSkill.sequenceNo;

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.skillService.updateSkill(this.newSkillToPush)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.editSkillFormOpen = false;
        form.onReset();
        this.refresh(this.state);
        this.newSkill = {
          skillName: '',
          skillDescription: '',
          skillGroup: {},
          tagSkillMaps: []
      },
        this.errorMsg = '';
    },
      error => this.errorMsg = error.error.message);
  }

  onSetAddSkillFormOpen(): void {
    this.getActiveCourses();
    this.newSkill.skillGroup.id = 0;
    this.addSkillFormOpen = true;
    this.selectedTag = {};
  }

  onSetEditSkillFormOpen(): void {
    this.editSkillFormOpen = true;
  }

  onEditSkillDetails(skill: SkillContentEntity): void {
    this.activeCourses = [];

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.coursesService.getActiveCourses()
      .pipe(finalize((() => hideSpinner())))
      .subscribe(courseSkillRequest => {
          courseSkillRequest.forEach(request => request.course.isDefault = false);
          this.activeCourses = courseSkillRequest.map(csr => csr.course);
          const courses: CourseContent[] = [];
          for (const courseSkill of courseSkillRequest) {
            for (const courseSkillMap of courseSkill.skillMappingDtos) {
              if (courseSkillMap.skill.id === skill.id) {
                courseSkill.course.isDefault = courseSkillMap.isDefault;
                courses.push(courseSkill.course);
              }
            }
          }
          this.selected = [];
          this.newSkill.skillCourses = [];
          for (const currentCourse of courses) {
            this.removeDuplicateCourse(this.selected, currentCourse);
            this.removeDuplicateCourse(this.activeCourses, currentCourse);
            this.selected.push(currentCourse);
            this.activeCourses.push(currentCourse);
            this.selected.sort((a, b) => (a.courseName.toLowerCase() > b.courseName.toLowerCase()) ? 1 : -1)
            this.activeCourses.sort((a, b) => (a.courseName.toLowerCase() > b.courseName.toLowerCase()) ? 1 : -1)
          }
          this.newSkill.id = skill.id
          this.newSkill.skillName = skill.skillName;
          this.newSkill.skillDescription = skill.skillDescription;
          this.newSkill.skillGroup.id = skill.skillGroup.id;
          this.newSkill.status = skill.status;
          this.newSkill.sequenceNo = skill.sequenceNo;
          this.newSkill.surveyTemplateConfigVersion = this.surveyTemplateConfigVersion;

          this.onSetEditSkillFormOpen();
        },
        error => console.log(error));

  }

  removeDuplicateCourse(courses: CourseContent[], currentCourse: CourseContent): void {
    courses.forEach((element, index) => {
      if (element.id === currentCourse.id) {
        courses.splice(index, 1);
      }
    });
  }

  onDeleteSkill(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.skillService.deleteSkill(this.deleteId)
      .pipe(finalize(() => hideSpinner())).subscribe(data => {
        this.refresh(this.state);
        this.showDeleteSkillPopUp = false
        this.deleteId = 0;
      },
      error => this.errorMsg = error.error.message);
  }

  onActivateSkill(id: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.skillService.activateSkill(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.refresh(this.state);
      },
      error => this.errorMsg = error.error.message);
  }

  onArchiveSkill(id: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.skillService.archiveSkill(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.refresh(this.state);
      },
      error => this.errorMsg = error.error.message);
  }

  onCancelAdding(form: NgForm): void {
    form.reset();
    this.newSkill = {
      skillName: '',
      skillDescription: '',
      skillGroup: {},
      tagSkillMaps: []
    }
    this.addSkillFormOpen = false;
    this.errorMsg = '';
  }

  onCancelEditing(form: NgForm): void {
    form.reset();
    this.newSkill = {
      skillName: '',
      skillDescription: '',
      skillGroup: {},
      tagSkillMaps: []
    }
    this.editSkillFormOpen = false;
    this.errorMsg = '';
  }

  resetError(): void {
    this.errorMsg = '';
  }

  onShowDeleteSkillPopUp(id: number): void {
    this.deleteId = id;
    this.showDeleteSkillPopUp = true;
  }

  onShowDeleteSkillGroupPopUp(id: number): void {
    this.deleteId = id;
    this.showDeleteSkillGroupPopUp = true;
  }

  onAddSkillGroupRow(): void {
    this.skillGroups.content.unshift(this.addSkillGroup);
    this.skillGroupAddBtn = false;
  }

  onSetSkillGroup(): void {
    this.isSkillGroupVisible = true;
    this.populateSkillGroups();
  }

  onCancelAddingSkillGroup(): void {
    this.skillGroups.content.shift();
    this.skillGroupAddBtn = true;
    this.addSkillGroup = this.initializeSkillGroup();
    this.errorMsg = '';
  }

  populateSkillGroups(): void {
    this.skillService.getSkillGroups(this.surveyTemplateConfigVersion.id).subscribe((results) => {
      this.skillGroups = results;
      let maxSkillGroupSequenceNo = 0;
      this.skillGroups.content.forEach(skillGroupItem => {
        if (skillGroupItem.sequenceNo > maxSkillGroupSequenceNo) {
          maxSkillGroupSequenceNo = skillGroupItem.sequenceNo;
        }
      });
      this.lastSequenceNoSkillGroup = maxSkillGroupSequenceNo;
    });
  }

  onSaveSkillGroup(): void {
    const skillGroupBody = {
      skillGroupName: this.addSkillGroup.skillGroupName,
      sequenceNo: this.lastSequenceNoSkillGroup + 1,
      surveyTemplateConfigVersion: this.surveyTemplateConfigVersion
    };
    this.addSkillGroup = this.initializeSkillGroup();

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.skillService.saveSkillGroup(skillGroupBody)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.populateSkillGroups();
        (<HTMLInputElement>document.getElementById("addSkillGroupRowBtn")).disabled = false;
        this.errorMsg='';
      },
      error => {this.errorMsg = error.error.message;
    });
  }

  onUpdateSkillGroup(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.skillService.updateSkillGroup(this.editSkillGroup)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.populateSkillGroups();
          this.editSkillGroup = null;
          this.errorMsg = '';
        },
        error => this.errorMsg = error.error.message);
  }

  onDeleteSkillGroup(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.skillService.deleteSkillGroup(this.deleteId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.populateSkillGroups();
        this.showDeleteSkillGroupPopUp = false;
        this.deleteId = 0;
      },
      error => this.errorMsg = error.error.message);
  }

  onResetSkillGroup(): void {
    this.editSkillGroup = {
      id: 0,
      skillGroupName: '',
      isSkillMapped: false
    };
    this.errorMsg = '';
    this.populateSkillGroups();
  }

  resetSkillGroup(): void {
    (<HTMLInputElement>document.getElementById("addSkillGroupRowBtn")).disabled = false;
  }

  closeSkillGroup(): void {
    this.errorMsg ='';
    this.skillGroupAddBtn = true;
    this.addSkillGroup = this.initializeSkillGroup();
  }

  getStatusFilter(status: string) {
    if (status.toString() === "undefined--undefined") {
      this.statusToFilter = "";
    } else {
      this.statusToFilter = status;
    }
    this.refresh(this.state);
  }

  initializeSkillGroup() {
    return {
      id: 0,
      skillGroupName: '',
      isSkillMapped: false,
    };
  }

  refresh(state: ClrDatagridStateInterface) {
    this.state = state;
    this.loading = true;
    const filters: { [prop: string]: any } = {};
    if (state.filters) {
      for (const filter of state.filters) {
        const {property, value} = <{ property: string; value: string }>filter;
        filters[property] = value;
      }
    }
    filters.status = this.statusToFilter;

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));

    this.skillService.getSkills(this.surveyTemplateConfigVersion.id, filters?.skillName === undefined ? '' : filters.skillName,
      filters?.skillDescription === undefined ? '' : filters.skillDescription,
      filters?.['skillGroup.skillGroupName'] === undefined ? '' : filters?.['skillGroup.skillGroupName'],
      filters?.['tagSkillMaps.tag.tagName'] === undefined ? '' : filters?.['tagSkillMaps.tag.tagName'],
      filters?.status === undefined ? '' : filters.status,
      state.page.current - 1, state.page.size, this.multiSort(state))
      .pipe(finalize(() => hideSpinner()))
      .subscribe((results) => {
        this.allSkills.content = results.content.map(value => {
          value.skill.courses = value.courseMappingDto
            .filter(courseData => courseData.course?.courseName)
            .map(courseData => this.getCourseNameWithVersion(courseData.course) + (courseData.isDefault ? ' - default' : ''));
          return value.skill;
        });
        this.total = results.totalElements;
        this.loading = false;
      });

  }

  multiSort(state): string {
    const sortBy = state?.sort?.reverse ? 'DESC' : 'ASC';
    let sort = state?.sort?.by === undefined ? '' : '&sort=' + state?.sort?.by + ',' + sortBy;
    sort = sort + '&sort=' + this.surveyControlsEnum.createdDate + ',DESC';
    return sort
  }

  onSaveResponseColumns() {
    sessionStorage.setItem("skillColumnHeaders", JSON.stringify(this.skillColumnHeaderList));
    this.isSavedResponse = true;
    setTimeout(() => {
      this.isSavedResponse = false;
    }, this.successMsgTimeout);
  }

  onSelectCourse(courses: CourseContent[]): void {
    const arrayLength: number = courses.length;
    if (arrayLength === 1) {
      courses[0].isDefault = true;
    }

    if (this.previousSelectedState.length > this.selected.length) {
      const deselectedCourse: CourseContent = this.previousSelectedState.filter(item => this.selected.indexOf(item) < 0)[0];
      deselectedCourse.isDefault = false;
    }

    this.previousSelectedState = [...this.selected];
  }

  onDefaultSelected(state: any, currentlySelectedCourse: CourseContent): void {
    if (!state.currentTarget.checked) {
      return;
    }
    let isFirstIteration: boolean = true;
    for (const course of this.selected) {
      if (course === currentlySelectedCourse && isFirstIteration) {
        course.isDefault = true;
        isFirstIteration = false;
        continue;
      }
      course.isDefault = false;
    }
  }

  onSelectTag(courseTag: Tag): void {
    this.courseTags.forEach((element) => {
      element['isDefault'] = false;
    });
    courseTag.isDefault = true;
    this.selectedTag = courseTag;
  }

  getWarningMessage(): void {
    this.warningSequenceNoChange = true;
    setTimeout(() => {
      this.warningSequenceNoChange = false;
    }, 3000);
  }

  getCourseNameWithVersion(course: CourseContent): string {
    return course?.courseName + (course?.courseVersion ? (' [' + course.courseVersion + ']') : '');
  }

}
