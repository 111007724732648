/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {SurveyControls} from '../enums/survey-controls.enum';
import {AnswerLabel} from '../models/master-survey-questions.model';
import {AnswerControl, ParticipantQuestion} from '../models/participant-ksa.model';
import {MatrixMap} from '../models/particpant-survey.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionInitializeService {

  setScaleTypeOnView(surveyQuestion: ParticipantQuestion): void {
    const answerFrom = surveyQuestion?.question.answerProperty.answerFrom;
    const answerTo = surveyQuestion?.question.answerProperty.answerTo;
    const stepSize = surveyQuestion?.question.answerProperty.stepSize;
    surveyQuestion.question[SurveyControls.optionsScale] = [];
    for (let index = answerFrom; index <= answerTo; index = index + stepSize) {
      surveyQuestion.question[SurveyControls.optionsScale].push({
        optionLabel: index
      });
    }
  }

  setMatrixTypeOnView(surveyQuestion: ParticipantQuestion): void {
    const answerLabel: AnswerLabel[] = surveyQuestion?.question.answerLabels;
    const answerValueMatrix: AnswerLabel = answerLabel.reduce((grouped, item) => {
      const isRow = String(item.isRow);
      if (grouped[isRow] == null) {
        grouped[isRow] = [];
      }
      grouped[isRow].push(item);
      return grouped;
    }, {});
    surveyQuestion.question[SurveyControls.matrixCols] = answerValueMatrix?.false;
    surveyQuestion.question[SurveyControls.matrixRows] = answerValueMatrix?.true;
  }

  setAnswereControlList(rowCount: number, columnCount: number): AnswerControl[] {
    const resultAnswereControl: AnswerControl[] = [];
    let indexId = 1;
    for (let rowNumber = 1; rowNumber <= rowCount; rowNumber++) {
      for (let columnNumber = 1; columnNumber <= columnCount; columnNumber++) {
        resultAnswereControl.push(this.answerControlFormat(indexId, rowNumber, columnNumber));
        indexId++;
      }
    }
    return resultAnswereControl;
  }

  setLabelIndex(question: ParticipantQuestion): ParticipantQuestion {
    let indexId = 1;
    question.question.answerLabels.forEach(label => {
      label.indexId = indexId;
      indexId++;
    })
    return question;
  }

  setScaleIndex(question: ParticipantQuestion): ParticipantQuestion {
    let indexId = 1;
    question.question.optionsScale.forEach(label => {
      label.indexId = indexId;
      indexId++;
    })
    return question;
  }

  answerControlFormat(index: number, rowNumber: number, columnNumber: number): AnswerControl {
    return {
      indexId: index,
      rowNumber,
      colNumber: columnNumber,
      isChecked: false
    };
  }

  setAnswerControlMatrixListForTechnicalSection(question): MatrixMap[] {
    return Object.assign(Object.values
    (question?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.ksaTechnicalAnswerByParticipants?.reduce((grouped, row) => {
      const isRow = row.rowNumber;
      if (grouped[isRow] == null) {
        grouped[isRow] = [];
      }
      grouped[isRow].push(row);
      return grouped;
    }, {})));
  }

  setAnswerControlMatrixListForNonTechnicalSection(question): MatrixMap[] {
    return Object.assign(
      Object.values(question?.ksaQuestionAnswerByParticipant?.[0]?.ksaAnswerByParticipants?.reduce((grouped, row) => {
        const isRow = row.rowNumber;
        if (grouped[isRow] == null) {
          grouped[isRow] = [];
        }
        grouped[isRow].push(row);
        return grouped;
      }, {})));
  }
}
