/*************************************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *************************************************************************/

import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Router} from "@angular/router";

/**
 * This service is used for tracking custom events and broadcasting them to Adobe Analytics using Tealium
 */
@Injectable({providedIn: 'root'})
export class TealiumUtagService {
  private readonly AA_SEPARATOR = " : ";
  private readonly VMWARE_SUITE = "vmware";
  private readonly HOST = "ksa-survey-portal";

  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(private readonly router: Router) {
    if (environment.useAnalytics) {
      (window as any).utag_cfg_ovrd = {noview: true};
      (window as any).utag_data = {};
    }
  }

  // Data layer is optional set of key/value pairs
  track(tealiumEvent: string, data?: any): void {
    if (!environment.useAnalytics) {
      return;
    }
    const utag = (window as any).utag;
    if (!utag) {
      console.log('Tealium config not set.');
      return;
    }
    utag.track(tealiumEvent, data);
  }

  view(data?: EventData): void {
    this.track('view', data);
  }

  link(data?: EventData): void {
    this.track('link', data);
  }

  trackStep(url): void {
    if (!url || !environment.useAnalytics) {
      return;
    }
    const pageData: EventData = this.configurePageData(this.getPage(url));
    pageData['tealium_event'] = 'myvm_page_view';
    pageData['_utag_event'] = 'view';
    this.view(pageData);
  }

  trackEvent(event: string, data?: EventData): void {
    if (!environment.useAnalytics) {
      return;
    }
    const eventData: EventData = this.configureEventData(event, data);
    this.link(eventData);
  }

  trackSearchEvent(eventName: string, searchPhrase: string, searchResults?: number): void {
    if (!environment.useAnalytics) {
      return;
    }
    const eventData: EventData = new EventData();
    eventData.search_term = searchPhrase;
    if (searchResults) {
      eventData.search_results = searchResults;
    }
    this.trackEvent(eventName, eventData);
  }

  trackTimeSpentEvent(eventName: string, timeSpent: number): void {
    const eventData = new EventData();
    eventData.cxs_time_spent = timeSpent;
    this.trackEvent(eventName, eventData);
  }

  private configurePageData(stateName: string, data?: EventData, event?: String): EventData {
    let pageName: string;
    if (event) {
      pageName = [this.VMWARE_SUITE, this.HOST, stateName, event].join(this.AA_SEPARATOR);
    } else {
      pageName = [this.VMWARE_SUITE, this.HOST, stateName].join(this.AA_SEPARATOR);
    }

    const pageSections: Array<string> = pageName.split(this.AA_SEPARATOR);

    if (data) {
      data.page_name = pageName;
      data.site_section1 = this.HOST;
      data.site_section2 = pageSections[0];
      data.site_section3 = pageSections[1];
      return data;
    } else {
      return new EventData(pageName, this.HOST, pageSections[0], pageSections[1]);
    }
  }

  private configureEventData(event: string, data?: EventData): EventData {
    const page: string = this.getPage(this.router.url);
    data = this.configurePageData(page, data, event);
    data.link_name = [this.VMWARE_SUITE, this.HOST, event].join(this.AA_SEPARATOR);
    return data;
  }

  private getPage(url: string): string {
    return url.split("/").pop();
  }
}

export class EventData {
  constructor(public page_name?: string, public site_section1?: string, public site_section2?: string,
              public site_section3?: string, public link_name?: string, public search_term?: string,
              public search_results?: number, public cxs_time_spent?: number) {
  }
}
