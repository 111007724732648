/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {KsaDetails, MasterKsa, MasterKsaSimple} from "../models/master-ksa.model";
import {EditingKsaData} from "../models/editing-ksa-data.model";
import {EditingInfo} from "../models/editing-info";
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";
import {UpdateMasterKsaStatus} from "../models/update-master-ksa-status";
import {ActiveSkill} from "../models/ksa-role-profile-skill.model";
import {RoleProfile} from "../models/role-profile.model";

@Injectable({providedIn: 'root'})
export class KsaAdministrationService {

  private ksaAdministrationUrl: string;
  private geoUrl: string;
  ksa: MasterKsaSimple;

  editingData: EditingKsaData;

  constructor(private http: HttpClient) {
    this.ksaAdministrationUrl = `${environment.API_URL}${API_KSA_CORE_V1}/masterksa/`;
    this.geoUrl = `${environment.API_URL}${API_KSA_CORE_V1}/geo/`;
  }

  getKsa(filter1, geo, status, validTill, from, to, sortby, sortorder): Observable<MasterKsa> {
    filter1 = encodeURIComponent(filter1);
    geo = encodeURIComponent(geo);
    validTill = encodeURIComponent(validTill);
    return this.http.get<MasterKsa>(`${this.ksaAdministrationUrl}?ksaName=${filter1}&geo.geoName=${geo}&status=${status}&validTill=${validTill}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`);
  }

  getKsaEnhanced(): Observable<any> {
    return this.http.get<any>(`${this.ksaAdministrationUrl}all/ksa`);
  }

  getMasterKsa(id): Observable<any> {
    return this.http.get<any>(`${this.ksaAdministrationUrl}${id}`);
  }

  getMasterKsaName(id): Observable<string> {
    return this.http.get(`${this.ksaAdministrationUrl}name/${id}`, {responseType: 'text'});
  }

  getMasterSurveyWithVersion(): Observable<any[]> {
    return this.http.get<any[]>(`${this.ksaAdministrationUrl}version`);
  }

  getAnonymityTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.ksaAdministrationUrl}anonymitytypes`);
  }

  getSurveyQuestionModes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.ksaAdministrationUrl}surveyquestionmodes`);
  }

  getMasterSurveyTemplateById(id: number): Observable<any> {
    return this.http.get<any>(`${this.ksaAdministrationUrl}mastersurvey/${id}`);
  }

  checkIfKsaNameExists(name: string): Observable<any> {
    return this.http.get<any>(`${this.ksaAdministrationUrl}get/${name}`);
  }

  getGeo(): Observable<any[]> {
    return this.http.get<any[]>(`${this.geoUrl}`);
  }

  createKsa(ksa: any): Observable<any> {
    return this.http.post(`${this.ksaAdministrationUrl}`, ksa);
  }

  updateKsa(ksa: any): Observable<any> {
    return this.http.put(`${this.ksaAdministrationUrl}`, ksa);
  }

  deleteKsa(id: number): Observable<any> {
    return this.http.delete(`${this.ksaAdministrationUrl}${id}`);
  }

  activateKsa(id: number): Observable<UpdateMasterKsaStatus> {
    return this.http.put<UpdateMasterKsaStatus>(`${this.ksaAdministrationUrl}active/${id}`, null);
  }

  archiveKsa(id: number): Observable<any> {
    return this.http.put(`${this.ksaAdministrationUrl}archive/${id}`, null);
  }

  closeKsa(id: number): Observable<UpdateMasterKsaStatus> {
    return this.http.put<UpdateMasterKsaStatus>(`${this.ksaAdministrationUrl}closed/${id}`, null);
  }

  expireKsa(id: number): Observable<UpdateMasterKsaStatus> {
    return this.http.put<UpdateMasterKsaStatus>(`${this.ksaAdministrationUrl}expired/${id}`, null);
  }

  checkIfRoleProfileTypeQuestionExist(id: number): Observable<any> {
    return this.http.get<any>(`${this.ksaAdministrationUrl}survey/${id}`);
  }

  deleteMultipleKsa(ids: any[]): Observable<any[]> {
    return this.http.delete<any[]>(`${this.ksaAdministrationUrl}multiple/` + ids)
  }

  getMasterKsaDetailsById(id: number): Observable<any> {
    return this.http.get<any>(`${this.ksaAdministrationUrl}` + id);
  }

  getClosedKsaList(): Observable<KsaDetails[]> {
    return this.http.get<KsaDetails[]>(`${this.ksaAdministrationUrl}closed`);
  }

  updateMasterKsaEditor(masterKsaId: number): Observable<EditingInfo> {
    return this.http.put<EditingInfo>(`${this.ksaAdministrationUrl}editor/${masterKsaId}`, {});
  }

  setEditingUserData(ksa: MasterKsaSimple): void {
    this.editingData = new EditingKsaData(ksa);
  }

  getSkillsByKsaId(ksaId: number): Observable<ActiveSkill[]> {
    return this.http.get<ActiveSkill[]>(`${this.ksaAdministrationUrl}skills/${ksaId}`);
  }

  getRoleProfilesByKsaId(ksaId: number): Observable<RoleProfile[]> {
    return this.http.get<RoleProfile[]>(`${this.ksaAdministrationUrl}role-profile/${ksaId}`);
  }

}
