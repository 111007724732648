export enum Functionality {
  manageRole = 'Manage Role',
  manageUser = 'Manage User',
  manageCourse = 'Manage Course',
  manageSkill = 'Manage Skill',
  manageRoleProfile = 'Manage Role Profile',
  manageRoleProfileGroup = 'Manage Role Profile Group',
  manageEmailTemplate = 'Manage Email Template',
  manageSurveyTemplate = 'Manage Survey Template',
  editTranslation = 'Edit Translation',
  ksaAdministration = 'KSA Administration',
  ksaEmailManagement = 'KSA Email Management',
  ksaTeamManagement = 'KSA Team Management',

  responseManagement = 'Response Management',
  reports = 'Reports',
  tracking = 'Tracking',
  analysis = 'Analysis',
  lastModifiedDate = 'lastModifiedDate',
  createdDate = 'createdDate',
  dashboard = 'Dashboard'
}
