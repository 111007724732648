<clr-alert clrAlertType="danger" *ngIf="hasError" [clrAlertClosable]="false"
           [clrAlertSizeSmall]="true">
  <clr-alert-item>{{'common.errorMessage' | translate}}</clr-alert-item>
</clr-alert>
<div *ngIf="isLoaded" class="container">
  <div class="logo-container">
    <div class="learning-logo"></div>
  </div>
  <div class="rectangle-linear"></div>
  <h1>{{'analysisAndReports.scorecards.scorecardTitle' | translate}}</h1>
  <div>
    <p>{{'analysisAndReports.scorecards.dear' | translate}} {{scorecard?.personalDetailsDto?.firstName || scorecard?.personalDetailsDto?.fullName}}
      ,</p>
    <p>{{'analysisAndReports.scorecards.intro' | translate}}</p>
    <div class="section">
      <div class="section-header-box">{{'analysisAndReports.scorecards.personalDetails' | translate}}</div>
      <div class="section-content-box-grid">
        <ng-container *ngIf="!!scorecard?.personalDetailsDto?.jobTitle">
          <div><strong>{{'analysisAndReports.scorecards.jobTitle' | translate}}</strong></div>
          <div>{{scorecard.personalDetailsDto.jobTitle}}</div>
        </ng-container>
        <ng-container *ngIf="!!scorecard?.personalDetailsDto?.team">
          <div><strong>{{'analysisAndReports.scorecards.team' | translate}}</strong></div>
          <div>{{scorecard.personalDetailsDto.team}}
          </div>
        </ng-container>
        <ng-container *ngIf="!!scorecard?.personalDetailsDto?.company">
          <div><strong>{{'analysisAndReports.scorecards.company' | translate}}</strong></div>
          <div>{{scorecard.personalDetailsDto.company}}</div>
        </ng-container>
        <ng-container *ngIf="!!scorecard?.personalDetailsDto?.manager">
          <div><strong>{{'analysisAndReports.scorecards.manager' | translate}}</strong></div>
          <div>{{scorecard.personalDetailsDto.manager}}</div>
        </ng-container>
        <ng-container *ngIf="!!scorecard?.personalDetailsDto?.country">
          <div><strong>{{'analysisAndReports.scorecards.country' | translate}}</strong></div>
          <div>{{scorecard.personalDetailsDto.country}}</div>
        </ng-container>
        <ng-container *ngIf="!!scorecard?.personalDetailsDto?.keyResponsibilities">
          <div><strong>{{'analysisAndReports.scorecards.keyResponsibilities' | translate}}</strong></div>
          <div>{{scorecard.personalDetailsDto.keyResponsibilities}}</div>
        </ng-container>
        <ng-container *ngIf="!!scorecard?.personalDetailsDto?.ksaRoleProfile">
          <div><strong>{{'analysisAndReports.scorecards.ksaRoleProfile' | translate}}</strong></div>
          <div>{{scorecard.personalDetailsDto.ksaRoleProfile}}</div>
        </ng-container>
      </div>
    </div>

    <div class="section" *ngIf="!!scorecard.note">
      <div class="section-header-box">{{'analysisAndReports.scorecards.summary' | translate}}</div>
      <div class="section-content-box-flex">
        <p>
          {{scorecard.note}}
        </p>
      </div>
    </div>

    <div class="section">
      <div
        class="section-header-box">{{'analysisAndReports.scorecards.skillLevelCourseRecommendations' | translate}}</div>
      <ng-container *ngIf="scorecard?.skillsAndCoursesDtos">
        <div class="section-content-box-flex"
             *ngFor="let skillsAndCourses of scorecard.skillsAndCoursesDtos; last as isLast">
          <h3>{{skillsAndCourses.skillName}}</h3>
          <div class="progress-bar">
            <div *ngIf="skillsAndCourses.participantScore" [style.width.%]="skillsAndCourses.participantScore"
                 class="score-bar"></div>
            <div *ngIf="skillsAndCourses.targetScore" [style.width.%]="calculateTargetPercentage(skillsAndCourses)"
                 class="target-bar"></div>
          </div>
          <div class="score">
            <ul class="trg-score-blue">
              <li>{{'analysisAndReports.scorecards.score' | translate}}:
                <ng-container *ngIf="isScoreValid(skillsAndCourses.participantScore); else scoreNa">
                  <strong>{{skillsAndCourses.participantScore }}%</strong>
                </ng-container>
                <ng-template #scoreNa>
                  <strong>{{'common.na' | translate}}</strong>
                </ng-template>
              </li>
            </ul>
            <ul class="trg-score-green">
              <li>{{'analysisAndReports.scorecards.target' | translate}}:
                <ng-container *ngIf="skillsAndCourses.targetScore; else targetNa">
                  <strong>{{skillsAndCourses.targetScore}}% </strong>
                </ng-container>
                <ng-template #targetNa>
                  <strong>{{'common.na' | translate}}</strong>
                </ng-template>
              </li>
            </ul>
          </div>
          <div class="text-color">
            <strong
              *ngIf="skillsAndCourses.recommendedCourse || isScoreValid(skillsAndCourses.participantScore)">{{'analysisAndReports.scorecards.trainingRecommendations' | translate}}
              :
            </strong> <br>
            <ng-container *ngIf="skillsAndCourses?.recommendedCourse?.courseUrl; else noCourseUrl">
              <a target="_blank"
                 [href]="skillsAndCourses.recommendedCourse.courseUrl">{{getTrainingRecommendationMessage(skillsAndCourses)}}</a>
            </ng-container>
            <ng-template #noCourseUrl>
              {{getTrainingRecommendationMessage(skillsAndCourses)}}
            </ng-template>
          </div>
          <hr *ngIf="!isLast">
        </div>
      </ng-container>
    </div>
  </div>
  <div style="margin-top: 25px" class="rectangle-linear"></div>
  <div class="logo-container">
    <div class="logo"></div>
  </div>
</div>
