/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from "@angular/core";
import {
  Router, Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import {AuthService} from "./auth.service";

@Injectable()
export class UserResolve implements Resolve<any>{
  constructor(private authService: AuthService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.authService.requestUserDetails().then(user => {
      if (this.authService.isAuthenticated() && user) {
        return user;
      }else{
        this.router.navigate(['/login']);
        return false;
      }
    });
  }
}
