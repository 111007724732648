<div *ngIf="!showMasterSurveyTab">
  <div *hasAccess="functionality.manageSurveyTemplate">
    <button (click)="onAddMasterSurveyBtn()" class="btn btn-sm btn-primary" id="{{btnId.btnMngSurveyTemplateAdd}}"
            type="button">
      {{'surveyTemplate.mngSurvey.addSurveyTemp'| translate}}
    </button>
    <button (click)="onArchiveSurveys(selected)" [disabled]="!selected.length"
            class="btn btn-sm btn-primary" id="{{btnId.btnMngSurveyTemplateMultiArchive}}" type="button">
      {{'surveyTemplate.mngSurvey.archiveSurvey' | translate}}
    </button>
  </div>
  <clr-datagrid [(clrDgSelected)]="selected"
                [clrDgRowSelection]="false" class="parent-datagrid" [clrDgLoading]="loading">
    <clr-dg-column [clrDgField]="'masterSurveyName'">{{'admin.surveyTemplates.surveyTemplateName'|translate}}
    </clr-dg-column>
    <clr-dg-column>{{'admin.surveyTemplates.questions'|translate}}</clr-dg-column>
    <clr-dg-column>{{'admin.surveyTemplates.sections'|translate}}</clr-dg-column>
    <clr-dg-column [clrDgField]="'createdDate'">{{'admin.surveyTemplates.createdDate'|translate}}
      <clr-dg-filter>
        <app-date-filter (filterDate)='getCreatedDateFilter($event)'></app-date-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'lastModifiedDate'">{{'admin.surveyTemplates.modifiedDate'|translate}}
      <clr-dg-filter>
        <app-date-filter (filterDate)='getModifiedDateFilter($event)'></app-date-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>{{'admin.surveyTemplates.published'|translate}}</clr-dg-column>
    <clr-dg-column>{{'admin.surveyTemplates.draft'|translate}}</clr-dg-column>
    <clr-dg-column [clrDgField]="'status'">{{'admin.surveyTemplates.status'|translate}}
      <clr-dg-filter>
        <app-status-filter (statusFilter)='getStatusFilter($event)' [statusOptions]="surveyStatusOption">
        </app-status-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column class="action-cell"></clr-dg-column>
    <clr-dg-row *clrDgItems="let survey of allSurveyData;let i = index" [clrDgItem]="survey"
                [clrDgSelectable]="survey.status==='Published'">
      <clr-dg-cell>
        {{'admin.surveyTemplates.id'|translate}} : {{survey.id}}
        <div class="clr-row">
          <div class="clr-col-8">
            {{'admin.surveyTemplates.name'|translate}} : {{survey.masterSurveyName}}
            <br>{{'admin.surveyTemplates.version'|translate}} : {{survey.version}}
          </div>
          <ng-container *hasPermission="[functionality.manageSurveyTemplate]">
            <div class="clr-col-2" *ngIf="survey.version > 0.01">
              <button (click)="onShowTreeView(survey)" class="btn btn-icon btn-sm" type="button">
                <clr-icon shape="tree-view"></clr-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </clr-dg-cell>
      <clr-dg-cell>
        {{survey.questions}}
      </clr-dg-cell>
      <clr-dg-cell>{{survey.sections}}</clr-dg-cell>
      <clr-dg-cell>{{survey.createdDate | date}}</clr-dg-cell>
      <clr-dg-cell>{{survey.lastModifiedDate | date}}</clr-dg-cell>
      <clr-dg-cell>
        <div *ngFor="let ls of survey.localizationStatuses | filterSurveyL10nStatus : localizationStatusEnum.PUBLISHED">
          {{ls.locale}}
          <clr-tooltip>
            <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20" class="color-blue"></cds-icon>
            <clr-tooltip-content [clrPosition]="i < 2 ? 'bottom-right': 'top-right'" clrSize="md" *clrIfOpen>
              {{'surveyTemplate.mngSurvey.modifiedBy'|translate}}:<br>
              {{ls.lastModifiedBy}}
              <br><br>
              {{'surveyTemplate.mngSurvey.modifiedAt'|translate}}:<br>
              {{ls.lastModifiedAt | date : 'dd-MMM-yyyy, hh:mm:ss a'}}
              <br>
            </clr-tooltip-content>
          </clr-tooltip>
        </div>
      </clr-dg-cell>
      <clr-dg-cell>
        <div *ngFor="let ls of survey.localizationStatuses | filterSurveyL10nStatus : localizationStatusEnum.DRAFT">
          {{ls.locale}}
          <clr-tooltip>
            <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20" class="color-blue"></cds-icon>
            <clr-tooltip-content [clrPosition]="i < 2 ? 'bottom-right': 'top-right'" clrSize="md" *clrIfOpen>
              {{'surveyTemplate.mngSurvey.modifiedBy'|translate}}:<br>
              {{ls.lastModifiedBy}}
              <br><br>
              {{'surveyTemplate.mngSurvey.modifiedBy'|translate}}:<br>
              {{ls.lastModifiedAt | date : 'dd-MMM-yyyy, hh:mm:ss a'}}
            </clr-tooltip-content>
          </clr-tooltip>
        </div>
      </clr-dg-cell>
      <clr-dg-cell>{{survey.status}}</clr-dg-cell>
      <clr-dg-cell class="action-cell">
        <clr-dg-action-overflow
          *hasPermission="[functionality.manageSurveyTemplate, functionality.editTranslation]; op 'OR'">
          <ng-container *hasAccess="functionality.manageSurveyTemplate">
            <button (click)="assignIdOnDelete(survey.id)" *ngIf="survey.status === 'Draft'" class="action-item"
                    id="{{btnId.btnMngSurveyTemplateDelete}}" type="button">
              {{'surveyTemplate.mngSurvey.deleteBtn' |translate}}</button>
          </ng-container>
          <ng-container *hasPermission="[functionality.manageSurveyTemplate]">
            <button (click)="onPreviewSurvey(survey.id)"
                    *ngIf="survey.status === 'Archived' || survey.status === 'Published' ||survey.status === 'Draft'"
                    class="action-item"
                    id="{{btnId.btnMngSurveyTemplatePreview}}"
                    type="button">
              {{'surveyTemplate.mngSurvey.previewBtn' | translate}}</button>
          </ng-container>
          <ng-container *hasAccess="functionality.manageSurveyTemplate">
            <button *ngIf="survey.status === 'Published'" (click)="openAddTranslationDialog(survey.id)"
                    class="action-item" [id]="btnId.btnMngSurveyTranslationOpenAddTranslationDialog" type="button">
              {{'surveyTemplate.mngSurvey.addTranslationBtn' | translate}}
            </button>
          </ng-container>
          <ng-container *hasPermission="[functionality.manageSurveyTemplate, functionality.editTranslation]; op 'OR'">
            <button (click)="openEditTranslationDialog(survey.id)" *ngIf="displayEditTranslationBtn(survey)"
                    class="action-item" [id]="btnId.btnMngSurveyTranslationOpenEditTranslationDialog" type="button">
              {{'surveyTemplate.mngSurvey.editTranslationBtn' | translate}}
            </button>
          </ng-container>
          <ng-container *hasAccess="functionality.manageSurveyTemplate">
            <button (click)="onInitializePublish(survey.id)"
                    *ngIf="survey.status === 'Archived' || survey.status === 'Draft'" class="action-item"
                    id="{{btnId.btnMngSurveyTemplatePublish}}" type="button">
              {{'surveyTemplate.mngSurvey.publishBtn' | translate}}</button>
          </ng-container>
          <ng-container *hasAccess="functionality.manageSurveyTemplate">
            <button (click)="onEditSurvey(survey.id)" *ngIf="survey.status === 'Draft'" class="action-item"
                    id="{{btnId.btnMngSurveyTemplateEdit}}" type="button">
              {{'surveyTemplate.mngSurvey.editBtn' | translate}}</button>
          </ng-container>
          <ng-container *hasAccess="functionality.manageSurveyTemplate">
            <button (click)="onInitializeNewVersion(survey.id)" *ngIf="survey.status === 'Published'"
                    class="action-item" id="{{btnId.btnMngSurveyTemplateNewVersion}}" type="button">
              {{'surveyTemplate.mngSurvey.newVersion' | translate}}</button>
          </ng-container>
          <ng-container *hasAccess="functionality.manageSurveyTemplate">
            <button (click)="onInitializeArchive(survey.id)" *ngIf="survey.status === 'Published'" class="action-item"
                    id="{{btnId.btnMngSurveyTemplateArchive}}" type="button">
              {{'surveyTemplate.mngSurvey.archiveBtn'| translate}}</button>
          </ng-container>
          <ng-container *hasAccess="functionality.manageSurveyTemplate">
            <button (click)="onInitializeClone(survey.id)"
                    *ngIf="survey.status === 'Archived' || survey.status === 'Published' || survey.status === 'Draft'"
                    class="action-item"
                    id="{{btnId.btnMngSurveyTemplateClone}}"
                    type="button">
              {{'surveyTemplate.mngSurvey.cloneBtn' | translate}}</button>
          </ng-container>
        </clr-dg-action-overflow>
      </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
          {{'surveyTemplate.mngSurvey.surveyPerPage' | translate}}</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
        {{'surveyTemplate.mngSurvey.surveyPagination' | translate}}
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>

<div *ngIf="showMasterSurveyTab">
  <app-tab-master-survey (showMasterSurveyTab)="onShowMasterSurveyTab($event)" [showPreview]="showPreview"
                         [disableMasterSurveyTab]="disableMasterSurveyTab"
                         [disableSurveyNameNewVersion]="disableSurveyNameNewVersion"
                         [isAdd]="isAdd" [isClone]="isClone" [isNewVersion]="isNewVersion"
                         [parentSurveyId]="parentSurveyId">
  </app-tab-master-survey>
</div>

<clr-modal [(clrModalOpen)]="showDeletePopUp">
  <h3 class="modal-title"> {{'surveyTemplate.mngSurvey.deleteMsg' | translate}}</h3>
  <div class="modal-body">{{'surveyTemplate.mngSurvey.unDeleteMsg' | translate}}</div>
  <div class="modal-footer">
    <button (click)="showDeletePopUp = false" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteSurvey()" class="btn btn-outline-warning btn-sm">{{'surveyTemplate.mngSurvey.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="showVersionTreePopUp">
  <h3 class="modal-title">{{'surveyTemplate.mngSurvey.versionTree' | translate}}</h3>
  <div class="modal-body treenode-model">
    <div *ngIf="showTreeLoading">
      <clr-spinner [clrMedium]="true"></clr-spinner>
    </div>
    <clr-tree *ngIf="!showTreeLoading">
      <clr-tree-node *clrRecursiveFor="let survey of rootVersion; getChildren : getChildren "
                     [(clrExpanded)]="isExpanded" [clrExpandable]="false">
        <div class="treenode-header">{{survey.masterSurveyName}} [{{survey.version}}]</div>
        {{survey.surveyDescription}}
      </clr-tree-node>
    </clr-tree>
  </div>
  <div class="modal-footer">
    <button (click)="showVersionTreePopUp= false" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn'
      | translate}}</button>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="showClonePopUp">
  <h3 class="modal-title"> {{'surveyTemplate.mngSurvey.cloneMsg' | translate}}</h3>
  <div class="modal-footer">
    <button (click)="onCloneCancel()" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' |
      translate}}</button>
    <button (click)="onCloneSurvey()" class="btn btn-outline-success btn-sm">{{'surveyTemplate.mngSurvey.cloneBtn' |
      translate}}</button>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="showArchivePopUp">
  <h3 class="modal-title"> {{'surveyTemplate.mngSurvey.archMsg' | translate}}</h3>
  <div class="modal-footer">
    <button (click)="onArchiveCancel()" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' |
      translate}}</button>
    <button (click)="onArchiveSurvey()" class="btn btn-outline-success btn-sm">{{'surveyTemplate.mngSurvey.archiveBtn' |
      translate}}</button>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="showNewVersionPopUp">
  <h3 class="modal-title">{{'surveyTemplate.mngSurvey.createVersionTemplate' | translate}}</h3>
  <div class="modal-footer">
    <button (click)="onNewVersionCancel()" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' |
      translate}}</button>
    <button (click)="onNewVersionSurvey()"
            class="btn btn-outline-success btn-sm">{{'surveyTemplate.mngSurvey.newVersion' | translate}}</button>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="showPublishPopUp">
  <h3 class="modal-title">{{'surveyTemplate.mngSurvey.publishMsg' | translate}}</h3>
  <div *ngIf="disablePublishButton" class="modal-body">{{'errorMsg.surveyNotValidMsg' | translate}}</div>
  <div class="modal-footer">
    <button (click)="onPublishCancel()" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' |
      translate}}</button>
    <button (click)="onPublishSurvey()" [disabled]="disablePublishButton"
            class="btn btn-outline-success btn-sm">{{'surveyTemplate.mngSurvey.publishBtn' | translate}}</button>
  </div>
</clr-modal>

<clr-modal *ngIf="isUserEditingSurvey" [clrModalOpen]="true">
  <h3 class="modal-title">{{'surveyTemplate.mngSurvey.surveyUnderEditingModalTitle' | translate}}</h3>
  <div class="modal-body">
    <div class="modal-body">
      <div class="alert alert-info">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
            </div>
            <hr>
            <span class="alert-text">
              {{'surveyTemplate.mngSurvey.surveyUnderEditingModalMessage' | translate}}
              <hr>
              <form class="clr-form clr-form-horizontal">
                <div class="clr-form-control">
                  <label for="userName" class="clr-control-label"><strong>User:</strong></label>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" id="userName"
                             value="{{masterSurveyService.editingData.firstName}} {{masterSurveyService.editingData.lastName}}"
                             readonly class="clr-input"/>
                    </div>
                  </div>
                </div>
                <div class="clr-form-control">
                  <label for="email" class="clr-control-label"><strong>Email:</strong></label>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" id="email"
                             value="{{masterSurveyService.editingData.email}}"
                             readonly class="clr-input"/>
                    </div>
                  </div>
                </div>
              </form>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"
            (click)="isUserEditingSurvey = false">{{'surveyTemplate.mngSurvey.surveyUnderEditingModalOkButton' | translate}}</button>
  </div>
</clr-modal>

<clr-modal *ngIf="showAddTranslationModal" [(clrModalOpen)]="showAddTranslationModal">
  <h3 class="modal-title">{{'surveyTemplate.mngSurvey.selectLanguage' | translate}}</h3>
  <div class="modal-body add-translation-modal-body">
    <select clrSelect [(ngModel)]="selectedNewTranslationLanguage">
      <option *ngFor="let l of supportedTranslationLanguages" [value]="l">{{l}}</option>
    </select>
  </div>
  <div class="modal-footer">
    <button (click)="showAddTranslationModal=false" [id]="btnId.btnMngSurveyTranslationCloseAddTranslationDialog"
            class="btn btn-link">{{'surveyTemplate.mngSurvey.cancelBtn' |
      translate}}</button>
    <button (click)="addTranslation()" [disabled]="!selectedNewTranslationLanguage"
            [id]="btnId.btnMngSurveyTranslationSubmitAddTranslation"
            class="btn btn-primary">{{'surveyTemplate.mngSurvey.translateBtn' | translate}}</button>
  </div>
</clr-modal>

<clr-modal *ngIf="showEditTranslationModal" [(clrModalOpen)]="showEditTranslationModal">
  <h3 class="modal-title">{{'surveyTemplate.mngSurvey.editTranslationsLabel' | translate}}</h3>
  <div class="modal-body add-translation-modal-body">
    <select clrSelect [(ngModel)]="selectedEditTranslationLanguage">
      <option *ngFor="let l of masterSurveyDraftLanguages" [value]="l">{{l}}</option>
    </select>
  </div>
  <div class="modal-footer">
    <button (click)="showEditTranslationModal=false" [id]="btnId.btnMngSurveyTranslationCloseEditTranslationDialog"
            class="btn btn-link">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}</button>
    <button
      [routerLink]="'/Home/ManageSurveyTranslation/' + editTranslationSurveyId + '/' + selectedEditTranslationLanguage"
      [disabled]="!selectedEditTranslationLanguage" [id]="btnId.btnMngSurveyTranslationSubmitEditTranslation"
      class="btn btn-primary">{{'surveyTemplate.mngSurvey.editTranslationBtn' | translate}}</button>
  </div>
</clr-modal>

<clr-modal *ngIf="showAddTranslationErrorModal" [(clrModalOpen)]="showAddTranslationErrorModal"
           (close)="showAddTranslationErrorModal = false">
  <h3 class="modal-title">{{'surveyTemplate.mngSurvey.addTranslationErrorTitle' | translate}}</h3>
  <div class="modal-body add-translation-modal-body text-justify">
    <div class="text-center">
      <clr-icon shape="sad-face" size="30"></clr-icon>
    </div>
    {{'surveyTemplate.mngSurvey.addTranslationErrorMessage' | translate }}
  </div>
</clr-modal>
