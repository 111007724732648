<div class="content-container" *ngIf="showSpinner" id="saveSurveySpin">
  <div class="content-area centered-content-area">
    <div class="spinner">{{'surveyTemplate.masterSurvey.loadingSpinner'|translate}}</div>
  </div>
</div>
<div class="survey-Container" *ngIf="!showSpinner">
  <div *ngIf="surveySaveSuccessAlert && messageSuccessTimer && !errorMessage" class="alert alert-success"
       id="hideMessage" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{'ksaEngagement.masterKsa.message.ksaSavedMsg'|translate}}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="errorMessage && !surveySaveSuccessAlert" class="alert alert-danger" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="error-standard"></cds-icon>
        </div>
        <span class="alert-text">
          {{'ksaEngagement.masterKsa.message.errorMsgUpdate'|translate}}
        </span>
      </div>
    </div>
  </div>
</div>

<form clrForm>
  <div class="clr-row">
    <div class="clr-col-3">
      <clr-input-container class="survey-input-container">
        <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.ksaId' | translate}}</label>
        <input type="text" [(ngModel)]="ksaName" autocomplete="off" class="clr-control-container clr-col-6"
               [disabled]="true" clrInput name="ksaName"/>
      </clr-input-container>
    </div>
    <div class="clr-col-4">
      <clr-input-container class="survey-input-container">
        <label class="clr-col-6">{{'admin.surveyTemplates.surveyTemplateName'|translate}}</label>
        <input type="text" [(ngModel)]="selectedSurveyName" autocomplete="off" class="clr-control-container clr-col-6"
               [disabled]="true" clrInput name="surveyName"/>
      </clr-input-container>
    </div>
    <div class="clr-col-3">
      <clr-input-container class="survey-input-container">
        <label class="clr-col-3">{{'admin.coursesAndSkills.courses.version' | translate}}</label>
        <input type="number" [(ngModel)]="selectedSurveyVersion" autocomplete="off"
               class="clr-control-container clr-col-6"
               [disabled]="true" clrInput name="surveyVersion"/>
      </clr-input-container>
    </div>
  </div>
  <br>
  <div class="clr-row">
    <div class="clr-col-3">
      <clr-date-container>
        <label class="clr-col-4">{{'ksaEngagement.masterKsa.placeholder.validTill'|translate}}</label>
        <input (ngModelChange)="selectedKSAValidTill = $event" [min]="minDate" [ngModel]="selectedKSAValidTill"
               class="clr-control-container clr-col-6" clrDate
               name="demo" type="date"/>
      </clr-date-container>
    </div>
    <div class="clr-col-9 survey-btn-container">
      <button *ngIf="hasWritePermission" (click)="onSaveKSA()" class="btn btn-primary btn-sm"
              [disabled]="disableSaveBtnWhileSaving">
        {{'admin.saveBtn' | translate}}</button>
    </div>
  </div>
</form>
<div *ngIf="ksaSectionData && ksaSectionData.length" class="card-block section-card-block">
  <div *ngFor="let section of ksaSectionData">
    <div class="sectionSettings">
      <clr-dropdown [clrCloseMenuOnItemClick]="true">
        <button clrDropdownTrigger aria-label="Dropdown demo button">
          <clr-icon shape="ellipsis-vertical"></clr-icon>
        </button>
        <clr-dropdown-menu clrPosition="left-top" *clrIfOpen>
          <button (click)="openMandatorySectionPopup(section)" [disabled]="ksaDetails.status !== statusEnum.Draft"
                  clrDropdownItem>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.mandatoryModal'|translate}}
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
    <clr-accordion>
      <clr-accordion-panel [clrAccordionPanelOpen]="true">
        <clr-accordion-title>{{section.sectionName}}</clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <app-ksa-section [ksaSectionData]="section" [ksaLanguage]="ksaDetails.language"
                           (updateQuestion)="onQuestionUpdated($event)"></app-ksa-section>
        </clr-accordion-content>
      </clr-accordion-panel>
    </clr-accordion>
  </div>
</div>
<div *ngIf="!ksaSectionData || !ksaSectionData.length" class="noNonTechQuestions">
  <span><em>{{'ksaEngagement.masterKsa.noNonTechnicalQuestionsMsg'|translate}}</em></span>
</div>

<clr-modal *ngIf="showMandatoryTypePopUp" [(clrModalOpen)]="showMandatoryTypePopUp"
           (clrModalOpenChange)="onCancelMandatorySelection()">
  <h3 class="modal-title">
    {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.sectionType.sectionTypeMandatory'|translate}}</h3>
  <div class="modal-body">
    <clr-radio-wrapper>
      <input (change)="onMandatorySectionInput(true)" [checked]="selectedSectionData.section.isMandatorySection"
             clrRadio name="mandatoryType" type="radio"/>
      <label>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.mandatory.mandatorySection'|translate}}</label>
    </clr-radio-wrapper>
    <clr-radio-wrapper>
      <input (change)="onMandatorySectionInput(false)" [checked]="!selectedSectionData.section.isMandatorySection"
             clrRadio name="mandatoryType" type="radio"/>
      <label>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.mandatory.nonMandatorySection'|translate}}</label>
    </clr-radio-wrapper>
  </div>
  <div class="modal-footer">
    <button (click)="onCancelMandatorySelection()" class="btn btn-outline btn-sm"
            type="button">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="onSaveMandatorySelection()"
            class="btn btn-primary btn-sm"
            type="button">{{'surveyTemplate.masterSurvey.save'|translate}}
    </button>
  </div>
</clr-modal>
