/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {QuestionType} from '../../../../enums/questionType.enum';
import {Status} from '../../../../enums/status.enum';
import {SurveyControls} from '../../../../enums/survey-controls.enum';
import {MasterSurveySection} from '../../../../models/master-survey-section.model';
import {MasterSurvey} from '../../../../models/master-survey.model';
import {SurveyQuestionImport} from '../../../../models/survey-import-questions.model';
import {ImportQuestionFileService} from '../../../../services/import-question-file.service';
import {MasterSurveyService} from '../../../../services/master-survey.service';
import {ShareSurveyDataService} from '../../../../services/share-survey-data.service';
import {MngSectionComponent} from './mng-section/mng-section.component';
import {hideSpinner, showSpinner} from "../../../../common/spinner";
import {finalize} from "rxjs/operators";
import {TealiumUtagService} from "../../../../services/utag.service";
import {AnalyticsEvents} from "../../../../enums/analytics-events.enum";
import {ConfigVersionService} from "../../../../services/config-version.service";
import {SurveyTemplateConfigStatus, SurveyTemplateConfigVersion} from "../../../../models/config-version.model";
import {ClrNotificationDialogService} from "../../../../common/notification-dialog/clr-notification-dialog.service";
import {NotificationDialogAction} from "../../../../common/notification-dialog/notification-dialog-action.model";
import {
  NotificationDialogActionType
} from "../../../../common/notification-dialog/notification-dialog-action-type.constants";
import {NgForm} from "@angular/forms";
import {cloneDeep} from "lodash";
import {UserService} from '../../../../services/user.service';
import {Functionality} from '../../../../enums/functionality.enum';


@Component({
  selector: 'app-master-survey',
  templateUrl: './master-survey.component.html',
  styleUrls: ['./master-survey.component.scss']
})
export class MasterSurveyComponent implements OnInit {
  surveySections: MasterSurveySection[] = [];
  surveyItemMaster: MasterSurvey = {content: []};
  @Input() parentSurveyId: number | undefined;
  @Output() disableMasterSurveyTab: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MngSectionComponent) sectionComponent: MngSectionComponent;
  masterSurveyName: string = '';
  surveyDescription: string = '';
  surveyVersionDescription: string = '';
  surveyTemplateConfigVersion: SurveyTemplateConfigVersion;
  surveyStatus!: Status;
  statusEnum: typeof Status = Status;
  masterId: number | undefined;
  surveyID: number | undefined;
  @Input() isAdd: boolean;
  isEdit: boolean;
  @Input() isClone: boolean;
  @Input() isNewVersion: boolean;
  showDuplicateSurveyError: boolean = false;
  messageSuccessTimer: boolean = true;
  masterSurveyPresent: MasterSurvey[];
  errorMessage: string = '';
  showImportSectionModal: boolean = false;
  publishedSurveys: MasterSurvey[];
  sectionListOfSelectedSurvey: MasterSurveySection[];
  selectedSectionsForImport: MasterSurveySection[] = [];
  surveySaveSuccessAlert: boolean = false;
  surveyUpdateSuccessAlert: boolean = false;
  surveyErrorAlert: boolean;
  surveyErrorAlertsOnQuestion: string[] = [];
  questionTypeEnum: typeof QuestionType = QuestionType;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  @Input() disableSurveyNameNewVersion: boolean;
  showTablePopUp: boolean = false;
  @Output() showMasterSurveyTab: EventEmitter<boolean> = new EventEmitter();
  showSpinnerImportSection: boolean = false;
  isImportSection: boolean = false;
  duplicateSectionError: boolean = false;
  duplicateSectionNamesOnImport: string[] = [];
  showSpecialCharacterSurveyError: boolean = false;
  disableSaveUpdateButton: boolean = true;
  masterSurveyId: number;
  masterSurveyToImportSectionsFrom: MasterSurvey;
  showUploadSectionFile: boolean = false;
  errorOnUploadFileSection: string = '';
  csvFileSectionData: SurveyQuestionImport[];
  listOfImportedSectionFile: MasterSurveySection[];
  @ViewChild('fileInput')
  fileInputSection: ElementRef;
  selectedFileName: string = '';
  @ViewChild('surveyTemplateForm')
  surveyTemplateForm: NgForm;
  hasWritePermission: boolean;

  activeSurveyTemplateConfigVersions: SurveyTemplateConfigVersion[] = [];


  constructor(private readonly masterSurveyService: MasterSurveyService,
              private readonly shareSurveyData: ShareSurveyDataService,
              private readonly importQuestionFileService: ImportQuestionFileService,
              private readonly translate: TranslateService,
              private readonly utagService: TealiumUtagService,
              public readonly configVersionService: ConfigVersionService,
              private readonly notificationDialogService: ClrNotificationDialogService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.manageSurveyTemplate);
    this.configVersionService.getAll(SurveyTemplateConfigStatus.ACTIVE).subscribe(response => {
      this.activeSurveyTemplateConfigVersions = response;
    });
    if (!this.shareSurveyData.getIsAddMasterSurvey()) {
      this.mapSurveyData();
    }
    this.shareSurveyData.subscribeForSurveyData(() => {
      // Questions mappings are updated in the survey by Map Skill button.
      if (this.isEdit) {
        this.onUpdateSurvey();
      } else {
        this.onSaveSurvey();
      }
    });
    this.showSpecialCharacterSurveyError = this.checkSurveyNamePattern();
    this.getSurveyUpdateStatus();
    this.getSkipConditions();
  }

  getSkipConditions() {
    this.masterSurveyService.getAllSkipConditions().subscribe(data => {
      this.shareSurveyData.setSkipConditions(data);
    });
  }

  getSurveyUpdateStatus() {
    this.masterSurveyService.getSurveyUpdateStatus().subscribe(data => {
      this.disableSaveUpdateButton = data;
    })
  }

  mapSurveyData(): void {
    this.shareSurveyData.getSurvey().subscribe(surveyData => {
      if (surveyData !== {}) {
        this.surveyItemMaster = surveyData;
      }
      this.masterId = surveyData?.id;
      if (this.masterId) {
        this.isEdit = true;
      }
      if (this.surveyItemMaster) {
        if (Object.keys(this.surveyItemMaster)?.length !== 0) {
          if (this.surveyItemMaster.id) {
            this.isEdit = true;
          }
          this.initializeData(this.surveyItemMaster);
        }
      }
    });
  }

  onSaveSurvey(): void {
    this.surveyDetails();
    const body: MasterSurvey = this.surveyItemMaster;
    if (this.checkSurveyIsValid(body)) {
      body.isNewVersion = this.isNewVersion;
      this.setNullOnDisplayLogics(body);

      showSpinner(this.translate.instant("spinnerLabels.common.saving"));
      this.shareSurveyData.setIsSurveyInvalid(false);
      this.masterSurveyService.addMasterSurvey(body)
        .pipe(finalize(() => hideSpinner()))
        .subscribe(
        surveyData => {
          if (surveyData) {
            this.masterSurveyService.setSurveyUpdateStatus(true);
            this.masterSurveyService.masterSurvey = surveyData;
            this.isEdit = true;
            this.surveySaveSuccessAlert = true;
            this.surveyUpdateSuccessAlert = false;
            this.shareSurveyData.setIsAddMasterSurvey(false);
            this.onAddUpdateData(surveyData);
            this.masterId = surveyData.id;
            this.shareSurveyData.setSurvey(surveyData);
            this.shareSurveyData.setSurveySaveAlert(true);
            this.surveyItemMaster = null;
            this.surveyErrorAlert = false;
            this.isNewVersion = false;
            this.utagService.trackEvent(AnalyticsEvents.CREATED_SURVEY_TEMPLATE);
          }
        },
        error => {
          this.surveySaveSuccessAlert = false;
          this.surveyErrorAlert = true;
          this.shareSurveyData.setSurveySaveAlert(false);
        }
      );
    } else {
      this.surveyUpdateSuccessAlert = false;
      this.surveySaveSuccessAlert = false;
      this.shareSurveyData.setIsSurveyInvalid(true);
      setTimeout(() => {
        this.messageSuccessTimer = false;
        this.surveyErrorAlertsOnQuestion = [];
        this.shareSurveyData.setIsSurveyInvalid(false);
      }, 5000);
      //Timeout for success/error message alert.
    }
  }

  removeWrongQuestions(): void {
    this.surveySections.forEach(section => {
      section.sectionQuestions = section.sectionQuestions.filter(question => {
        return question !== undefined
      });
    });
  }

  onUpdateSurvey(): void {
    this.surveyDetails();
    const body: MasterSurvey = this.surveyItemMaster;
    body.id = this.masterId;
    body.status = this.surveyStatus;
    if (this.checkSurveyIsValid(body)) {
      this.setNullOnDisplayLogics(body);

      showSpinner(this.translate.instant("spinnerLabels.common.saving"));
      this.shareSurveyData.setIsSurveyInvalid(false);
      this.masterSurveyService.editMasterSurvey(body)
        .pipe(finalize(() => hideSpinner()))
        .subscribe(
        surveyData => {
          if (surveyData) {
            this.masterSurveyService.setSurveyUpdateStatus(true);
            this.surveyUpdateSuccessAlert = true;
            this.surveySaveSuccessAlert = false;
            this.onAddUpdateData(surveyData);
            this.shareSurveyData.setSurvey(surveyData);
            this.shareSurveyData.setSurveySaveAlert(true);
            this.surveyItemMaster = null;
            this.surveyErrorAlert = false;
          }
        },
        error => {
          this.surveyErrorAlert = true;
          this.surveySaveSuccessAlert = false;
          this.surveyUpdateSuccessAlert = false;
          this.shareSurveyData.setSurveySaveAlert(false);
        }
      );
    } else {
      this.surveyUpdateSuccessAlert = false;
      this.surveySaveSuccessAlert = false;
      this.shareSurveyData.setIsSurveyInvalid(true);
      setTimeout(() => {
        this.messageSuccessTimer = false;
        this.surveyErrorAlertsOnQuestion = [];
        this.shareSurveyData.setIsSurveyInvalid(false);
      }, 5000);
    }
  }

  setNullOnDisplayLogics(survey: MasterSurvey): void {
    survey?.surveySections.map(section => {
      if (!section?.sectionSkipCondition?.skipCondition?.id) {
        section.sectionSkipCondition = null
      }
      section?.sectionQuestions.map(question => {
        question.sectionSkipConditions = [];
        question.questionDisplayConditions = [];
        if (!question?.questionDisplayCondition?.skipCondition?.id) {
          question.questionDisplayCondition = null;
        }
        if (!question?.questionSkipCondition?.skipCondition?.id) {
          question.questionSkipCondition = null;
        }
      });
    });
  }

  checkDuplicateSectionNameInSurvey(): boolean {
    const sectionNames: string[] = this.surveySections.map(sectionData => sectionData?.section?.sectionName) || [];

    return (sectionNames.length === new Set(sectionNames).size);
  }

  checkSurveyIsValid(body: MasterSurvey): boolean {
    let isValidMasterSurvey: boolean = true;

    if (body?.masterSurveyName == '' || body?.masterSurveyName == undefined) {
      isValidMasterSurvey = false;
      this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.MissingSurveyNameMessage)
    } else if (this.showDuplicateSurveyError) {
      isValidMasterSurvey = false;
      this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.duplicateSurveyError);
    } else if (this.showSpecialCharacterSurveyError) {
      isValidMasterSurvey = false;
      this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.patternSurveyError);
    } else {
      isValidMasterSurvey = true;
    }

    for (let indexSection = 0; indexSection < body.surveySections.length; indexSection++) {
      if (body.surveySections[indexSection]?.section?.sectionName == '') {
        isValidMasterSurvey = false;
        this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.MissingSectionMessage + body.surveySections[indexSection].sectionSequenceNo);
        break;
      }
      if (body.surveySections[indexSection]?.isSectionNameRequiredError || !this.checkDuplicateSectionNameInSurvey()) {
        isValidMasterSurvey = false;
        this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.DuplicateSectionMessage);
        break;
      }
      if (body.surveySections[indexSection]?.isSectionNamePatternError) {
        isValidMasterSurvey = false;
        this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.SectionNamePatternMessage + "'" +
          body.surveySections[indexSection].section?.sectionName + "'");
        break;
      }
      body.surveySections[indexSection]?.sectionQuestions.forEach(question => {
        if (!question) {
          isValidMasterSurvey = false;
          this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.MissingQuestionPropertyMessage + "'" +
            body.surveySections[indexSection]?.section?.sectionName + "'");
        }
        if (question?.question?.questionText == '' || !question.question.questionText) {
          isValidMasterSurvey = false;
          this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.MissingQuestionTextMessage + "'" +
            body.surveySections[indexSection]?.section?.sectionName + "'" + this.surveyControlsEnum.QuestionSequeneMessage + "'" +
            question.sectionQuestionSequenceNo + "'");
        }
        const questionTypeId = question?.question?.questionType.id;
        if (questionTypeId == this.questionTypeEnum.RadioButton ||
          questionTypeId == this.questionTypeEnum.MultipleChoice ||
          questionTypeId == this.questionTypeEnum.MatrixTable) {
          if (question?.question?.answerLabels) {
            question?.question?.answerLabels.forEach(answer => {
              if (answer?.labelText == '') {
                isValidMasterSurvey = false;
                this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.MisingAnswerLabelMessage + "'" +
                  body.surveySections[indexSection]?.section?.sectionName + "'" + this.surveyControlsEnum.QuestionSequeneMessage + "'" +
                  question.sectionQuestionSequenceNo + "'");
              }
            });
          }
        }
        if (questionTypeId == this.questionTypeEnum.Scale) {
          if (!question.question.answerProperty.answerFrom || !question.question.answerProperty.answerTo ||
            !question.question.answerProperty.stepSize) {
            isValidMasterSurvey = false;
            this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.MisingPropertyMessage + "'" +
              body.surveySections[indexSection]?.section?.sectionName + "'" + this.surveyControlsEnum.QuestionSequeneMessage + "'" +
              question.sectionQuestionSequenceNo + "'");
          }

        }
        const isLastQuestion = this.shareSurveyData.validateLastQuestionOnSurvey(question.tempSurveyQuestionId);
        if (isLastQuestion && question.isPageBreakAdded) {
          isValidMasterSurvey = false;
          this.surveyErrorAlertsOnQuestion.push(this.surveyControlsEnum.PageBreakErrorMessage);
        }
      });
    }
    return isValidMasterSurvey;
  }

  onAddUpdateData(surveyDetails: MasterSurvey): void {
    this.initializeData(surveyDetails);
    this.surveyErrorAlert = false;
    setTimeout(() => {
      this.messageSuccessTimer = false;
    }, 3000);
  }

  surveyDetails(): void {
    this.messageSuccessTimer = true;
    if (this.isAdd && this.shareSurveyData.getIsAddMasterSurvey() || this.isClone) {
      this.parentSurveyId = undefined;
    }
    if (this.parentSurveyId) {
      this.surveyItemMaster = {
        masterSurveyName: this.masterSurveyName,
        surveyDescription: this.surveyDescription,
        surveyVersionDescription: this.surveyVersionDescription,
        surveySections: this.surveySections,
        parent: {
          id: this.parentSurveyId
        },
        surveyTemplateConfigVersion: this.surveyTemplateConfigVersion
      }
    } else {
      this.surveyItemMaster = {
        masterSurveyName: this.masterSurveyName,
        surveyDescription: this.surveyDescription,
        surveyVersionDescription: this.surveyVersionDescription,
        surveySections: this.surveySections,
        surveyTemplateConfigVersion: this.surveyTemplateConfigVersion
      }
    }
    this.surveyErrorAlertsOnQuestion = [];
  }

  initializeData(surveyDetails: MasterSurvey): void {
    this.masterId = surveyDetails?.id;
    this.masterSurveyName = surveyDetails?.masterSurveyName;
    this.surveyDescription = surveyDetails?.surveyDescription;
    this.surveyVersionDescription = surveyDetails?.surveyVersionDescription;
    this.surveyStatus = surveyDetails?.status;
    this.surveyTemplateConfigVersion = surveyDetails?.surveyTemplateConfigVersion;
    if (surveyDetails?.surveySections) {
      surveyDetails?.surveySections.sort((previousSection, nextSection) =>
        (previousSection.sectionSequenceNo > nextSection.sectionSequenceNo ? 1 : -1));
      [...this.surveySections] = [...surveyDetails?.surveySections];
    }
    if (this.checkIfTempSectionIdExists()) {
      this.mapSectionAndQuestionTempId();
    }
  }

  onRemoveSection(sectionDetails: MasterSurveySection): void {
    this.surveySections = this.surveySections.filter(sectionItem => sectionItem !== sectionDetails);
    this.surveySections.forEach((section, sectionNumber) => {
      section.sectionSequenceNo = sectionNumber + 1;
    });
    this.setSharedSurveyData();
  }

  onAddSection(): void {
    this.surveySections.push({
      section: {
        sectionName: ''
      },
      sectionSequenceNo: this.surveySections.length + 1,
      isMandatorySection: true,
      isImportedSection: false,
      isTechnicalSection: true,
      sectionQuestions: [],
      sectionSkipCondition: {
        answerLabelSequenceNo: undefined,
        colAnswerLabelSequenceNo: undefined,
        displayValue: undefined,
        questionSequenceNo: undefined,
        sectionSequenceNo: undefined,
        skipCondition: {
          id: undefined
        }
      },
      tempSectionId: this.findMaxSectionId() + 1,
    });
    this.setSharedSurveyData();
  }

  onImportSection(): void {
    this.publishedSurveys = [];
    this.showSpinnerImportSection = true;
    this.showImportSectionModal = true;

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.masterSurveyService.getPublishedMasterSurvey()
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.publishedSurveys = data
      },
      error => {
        this.surveyErrorAlert = true;
        this.showSpinnerImportSection = false;
      })
    this.showSpinnerImportSection = false;
  }

  onSelectPublishedSurvey(surveyId: number): void {
    this.showSpinnerImportSection = true;
    this.masterSurveyService.getMasterSurveyById(surveyId).subscribe(results => {
      this.masterSurveyToImportSectionsFrom = results;
      this.showSpinnerImportSection = false;
    });
  }

  resetImportModal(): void {
    this.publishedSurveys = [];
    this.masterSurveyToImportSectionsFrom = null;
    this.masterSurveyId = null;
  }

  onAddImportedSections(): void {
    if (this.checkDuplicateSurveyImport(this.selectedSectionsForImport)) {
      if (this.isSameSurveyConfigVersion()) {
        this.addImportedSections();
      } else {
        this.showDifferentSurveyConfigTemplateDialog();
      }
    } else {
      this.showImportSectionModal = true;
    }
  }

  checkDuplicateSurveyImport(importedSections: MasterSurveySection[]): boolean {
    this.duplicateSectionNamesOnImport = [];
    let isValidMasterSurvey = true;
    const sectionNames: string[] = this.surveySections.map(sectionData => sectionData?.section?.sectionName) || [];
    const duplicateSections = importedSections.filter(obj => sectionNames.includes(obj.section.sectionName));

    if (duplicateSections.length > 0) {
      isValidMasterSurvey = false;
      this.duplicateSectionNamesOnImport = duplicateSections.map(duplicateSection => duplicateSection?.section?.sectionName);
    }
    return isValidMasterSurvey;
  }

  private showDifferentSurveyConfigTemplateDialog() {
    this.notificationDialogService.show({
      title: this.translate.instant("surveyTemplate.masterSurvey.import.surveyTemplateConfigVersion.title"),
      message: this.translate.instant("surveyTemplate.masterSurvey.import.surveyTemplateConfigVersion.message"),
      listOfActions: [new NotificationDialogAction(NotificationDialogActionType.OK, () => {
        this.addImportedSections();
      })],
      rejectAction: new NotificationDialogAction(NotificationDialogActionType.CANCEL, () => {
      })
    });
  }

  onMoveSection(data: number, sectionItem: MasterSurveySection, indexSection: number): void {
    if (data == -1) {
      this.moveSectionUp(indexSection, sectionItem);
    } else {
      this.moveSectionDown(indexSection, sectionItem);
    }
    this.setSharedSurveyData();
  }

  moveSectionUp(indexSection: number, sectionItem: MasterSurveySection): void {
    if (indexSection - 1 < 0) {
      return;
    }
    this.surveySections[indexSection] = this.surveySections[indexSection - 1];
    this.surveySections[indexSection - 1] = sectionItem;
    this.surveySections[indexSection].sectionSequenceNo = [this.surveySections[indexSection - 1].sectionSequenceNo,
      this.surveySections[indexSection - 1].sectionSequenceNo = this.surveySections[indexSection].sectionSequenceNo][0];
  }

  moveSectionDown(indexSection: number, sectionItem: MasterSurveySection): void {
    if (indexSection + 1 >= this.surveySections.length) {
      return;
    }
    this.surveySections[indexSection] = this.surveySections[indexSection + 1];
    this.surveySections[indexSection + 1] = sectionItem;
    this.surveySections[indexSection].sectionSequenceNo = [this.surveySections[indexSection + 1].sectionSequenceNo,
      this.surveySections[indexSection + 1].sectionSequenceNo = this.surveySections[indexSection].sectionSequenceNo][0];
  }

  onSurveyPresent(masterSurveyName: string): void {
    this.onChange();
    this.showSpecialCharacterSurveyError = this.checkSurveyNamePattern();

    this.showDuplicateSurveyError = false;
    if (masterSurveyName != '') {
      this.masterSurveyService.getSurveyName(masterSurveyName.trim()).subscribe(data => {
          this.masterSurveyPresent = data;
          this.showDuplicateError();
        },
        error => this.errorMessage = error?.error?.message);
    }
  }

  showDuplicateError(): void {
    let masterSurveyDuplicateValue = this.masterSurveyPresent;
    if (this.isEdit) {
      masterSurveyDuplicateValue = [];
      masterSurveyDuplicateValue = this.masterSurveyPresent.filter(survey => {
        if (survey.id != this.masterId && survey.masterSurveyName == this.masterSurveyName) {
          return survey;
        }
      });
    }
    if (masterSurveyDuplicateValue.length > 0) {
      this.showDuplicateSurveyError = true;
      this.masterSurveyName = this.masterSurveyName.trim();
    } else {
      this.showDuplicateSurveyError = false;
    }
  }


  onShowTable(): void {
    this.showMasterSurveyTab.emit(false);
    this.masterSurveyService.getMasterSurveyPageEnhanced().subscribe(data => {
      data.sort((surveyPrevious, surveyNext) =>
        surveyPrevious.lastModifiedDate > surveyNext.lastModifiedDate ? -1 : 1);
    });
  }

  setSharedSurveyData(): void {
    this.shareSurveyData.setSurvey({
      id: this.masterId,
      masterSurveyName: this.masterSurveyName,
      surveyDescription: this?.surveyDescription,
      surveyVersionDescription: this?.surveyVersionDescription,
      surveySections: this.surveySections,
      status: this?.surveyStatus,
      parent: {
        id: this.parentSurveyId
      },
      surveyTemplateConfigVersion: this.surveyTemplateConfigVersion
    });
    this.surveyItemMaster = null;
  }

  checkSurveyNamePattern(): boolean {
    return !this.masterSurveyName.match(/^[^!@#*&(){}<>\/;`%$^:?]*$/);
  }

  checkIfSurveyHasUnsavedChanges(): void {
    if (!this.disableSaveUpdateButton) {
      this.showTablePopUp = true;
      this.masterSurveyService.setSurveyState(true);
    } else {
      this.onShowTable();
    }
  }

  onChange(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
  }

  ngOnDestroy(): void {
    this.removeWrongQuestions();
    this.setSharedSurveyData();
    this.shareSurveyData.setIsAddMasterSurvey(false);
  }

  checkIfTempSectionIdExists(): boolean {
    if (this.surveySections.length === 0) {
      return false;
    } else if (this.surveySections[0].tempSectionId === null) {
      return true;
    } else
      return false;
  }

  mapSectionAndQuestionTempId(): void {
    let newTempMaxSectionId = 0;
    let newTempMaxQuestionId = 0;
    this.surveySections.forEach(section => {
      section.tempSectionId = ++newTempMaxSectionId;
      section.sectionQuestions.forEach(question => {
        question.tempSurveyQuestionId = ++newTempMaxQuestionId;
      })
    });
  }

  onOkImportFileSection(): void {
    this.showUploadSectionFile = false;
    this.errorOnUploadFileSection = '';
    if (this.csvFileSectionData) {
      const response = this.importQuestionFileService.validateCsvSections(this.csvFileSectionData);
      if (response.isValidQuestions) {
        this.listOfImportedSectionFile = this.importQuestionFileService.mapSurveyCSVSection(this.csvFileSectionData);
        if (this.checkDuplicateSurveyImport(this.listOfImportedSectionFile)) {
          let newMaxTempSectionId = this.findMaxSectionId();
          let newMaxTempQuestionId = this.findMaxQuestionId();
          this.listOfImportedSectionFile.forEach(importedSection => {
            delete importedSection.id;
            importedSection.sectionSkipCondition = null;
            importedSection.sectionQuestions.forEach(question => {
              question.id = null;
              question.questionDisplayCondition = null;
              question.questionSkipCondition = null;
              question.sectionSkipConditions = [];
              question.isImported = false;
              question.tempSurveyQuestionId = ++newMaxTempQuestionId;
            });
          });
          let countSection = this.surveySections.length;
          this.listOfImportedSectionFile.forEach(section => {
            section[this.surveyControlsEnum.isImportedSection] = false;
            section.sectionSequenceNo = ++countSection;
            section.tempSectionId = ++newMaxTempSectionId;
          });
          this.isImportSection = true;
          this.surveySections = [...this.surveySections, ...this.listOfImportedSectionFile];
          this.showUploadSectionFile = false;
          this.sectionListOfSelectedSurvey = [];
          this.setSharedSurveyData();
          this.duplicateSectionNamesOnImport = [];
          this.onChange();
        } else {
          this.showUploadSectionFile = true;
          this.errorOnUploadFileSection = this.surveyControlsEnum.DuplicateSectionMessage + this.duplicateSectionNamesOnImport.join(',');
        }
        this.fileInputSection.nativeElement.value = "";
        this.csvFileSectionData = [];
      } else {
        this.showUploadSectionFile = true;
        this.errorOnUploadFileSection = response.errors;
      }
    }
    this.fileInputSection.nativeElement.value = "";
  }

  onCancelFileSectionImport(): void {
    this.showUploadSectionFile = false;
    this.fileInputSection.nativeElement.value = "";
    this.selectedFileName = '';
  }

  onImportSectionFile(): void {
    this.showUploadSectionFile = true;
    this.errorOnUploadFileSection = '';
    this.selectedFileName = '';
    this.csvFileSectionData = [];
  }

  onImportQuestionSelectFile(event): void {
    this.errorOnUploadFileSection = '';
    this.selectedFileName = '';
    this.csvFileSectionData = [];
    this.listOfImportedSectionFile = [];
    const selectedFile = event.target.files[0];
    this.selectedFileName = selectedFile.name;
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile);
    fileReader.onload = (event) => {
      const extention = selectedFile.name.split(".").pop();
      if (extention === this.surveyControlsEnum.csv) {
        const records = (fileReader.result as string).split(/\r\n|\n/);
        const headers = this.getHeaderArray(records);
        const mandatoryHeaders = ['ID', 'Details', 'QuestionType', 'SectionName'];
        const checkHeaders = (mandatoryHeaderArray, allHeaders) => allHeaders.every(header => mandatoryHeaderArray.includes(header));
        if (checkHeaders(headers, mandatoryHeaders)) {
          this.csvFileSectionData = this.getRecordsFromImportCsvFile(records, headers.length);
        } else {
          this.errorOnUploadFileSection = this.surveyControlsEnum.MissingHeader;
        }
      } else {
        this.errorOnUploadFileSection = this.surveyControlsEnum.InvalidCsvFile;
      }
    }
  }

  getHeaderArray(csvRecordsArr: string[]): string[] {
    const headers = (csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getRecordsFromImportCsvFile(records: string[], headerLength: number): SurveyQuestionImport[] {
    const csvArr = [];
    for (let i = 1; i < records.length; i++) {
      const currentRecord = (records[i]).split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
      if (currentRecord.length == headerLength) {
        const csvRecord: SurveyQuestionImport = new SurveyQuestionImport();
        csvRecord.sequenceNo = i;
        csvRecord.id = currentRecord[0].split("_")[0];
        csvRecord.name = currentRecord[0].split("_")[1];
        csvRecord.details = currentRecord[1].trim();
        csvRecord.type = currentRecord[2].trim();
        csvRecord.sectionName = currentRecord[3].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  isSurveyTemplateConfigVersionActive(surveyTemplateConfigVersion: SurveyTemplateConfigVersion) {
    return this.activeSurveyTemplateConfigVersions?.some(item => item?.id === surveyTemplateConfigVersion?.id)
  }

  onConfigTemplateChange(value: SurveyTemplateConfigVersion) {
    if (this.areEqual(this.surveyTemplateConfigVersion, value)) {
      return
    }
    if (this.surveyTemplateConfigVersion && !this.areEqual(this.surveyTemplateConfigVersion, value) && this.isAnyQuestionMappedToSkill()) {
      this.notificationDialogService.show({
        title: this.translate.instant("surveyTemplate.masterSurvey.surveyTemplateConfigVersion.title"),
        message: this.translate.instant("surveyTemplate.masterSurvey.surveyTemplateConfigVersion.message"),
        listOfActions: [new NotificationDialogAction(NotificationDialogActionType.OK, () => {
          this.unmapSkills();
          this.setSurveyTemplateConfigVersion(value);
          this.setSharedSurveyData();
          this.onChange();
        })],
        rejectAction: new NotificationDialogAction(NotificationDialogActionType.CANCEL, () => this.setSurveyTemplateConfigVersion(cloneDeep(this.surveyTemplateConfigVersion)))
      });
    } else {
      this.setSurveyTemplateConfigVersion(value);
      this.setSharedSurveyData();
      this.onChange();
    }
  }

  areEqual(surveyTemplateConfigVersion1: SurveyTemplateConfigVersion, surveyTemplateConfigVersion2: SurveyTemplateConfigVersion) {
    return surveyTemplateConfigVersion1?.id === surveyTemplateConfigVersion2?.id;
  }

  private unmapSkills(): void {
    this.surveySections
      .flatMap(s => s.sectionQuestions)
      .forEach(sq => sq.skill = null);
  }

  private setSurveyTemplateConfigVersion(surveyTemplateConfigVersion: SurveyTemplateConfigVersion): void {
    this.surveyTemplateConfigVersion = surveyTemplateConfigVersion;
  }

  private isAnyQuestionMappedToSkill(): boolean {
    return this.surveySections
      .flatMap(s => s.sectionQuestions)
      .some(e => !!e.skill);
  }

  private addImportedSections() {
    let maxTempSectionId = this.findMaxSectionId();
    this.deleteUnneededFields();
    let countSection = this.surveySections.length;
    this.selectedSectionsForImport.forEach(section => {
      section[this.surveyControlsEnum.isImportedSection] = true;
      section.sectionSequenceNo = ++countSection;
      section.tempSectionId = ++maxTempSectionId;
    });
    this.isImportSection = true;
    this.surveySections = [...this.surveySections, ...this.selectedSectionsForImport];
    this.showImportSectionModal = false;
    this.sectionListOfSelectedSurvey = [];
    this.setSharedSurveyData();
    this.duplicateSectionNamesOnImport = [];
    this.onChange();
  }

  private deleteUnneededFields() {
    let maxTempQuestionId = this.findMaxQuestionId();
    this.selectedSectionsForImport.forEach(importedSection => {
      delete importedSection.id;
      importedSection.sectionSkipCondition = null;
      importedSection.sectionQuestions.forEach(question => {
        question.id = null;
        question.questionDisplayCondition = null;
        question.questionSkipCondition = null;
        question.sectionSkipConditions = [];
        question.isImported = true;
        question.tempSurveyQuestionId = ++maxTempQuestionId;
        question.skill = this.isSameSurveyConfigVersion() ? question.skill : null;
      });
    });
  }

  /**
   * This method validates if the source survey template, from which sections are being imported, shares the same template configuration version as the current survey template.
   */
  private isSameSurveyConfigVersion() {
    return this.masterSurveyToImportSectionsFrom.surveyTemplateConfigVersion.id === this.surveyTemplateConfigVersion?.id;
  }

  private findMaxSectionId(): number {
    const tempSurveyIds: number[] = this.surveySections?.map(section => section.tempSectionId);
    return tempSurveyIds?.length > 0 ? Math.max(...tempSurveyIds) : 0;
  }

  private findMaxQuestionId(): number {
    const tempSurveyQuestionIds: number[] = this.surveySections?.flatMap(section => section.sectionQuestions)
      .map(sectionQuestion => sectionQuestion.tempSurveyQuestionId);
    return tempSurveyQuestionIds?.length > 0 ? Math.max(...tempSurveyQuestionIds) : 0;
  }
}
