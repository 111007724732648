<div *ngIf="showLoader">
  <clr-spinner>Loading ...</clr-spinner>
</div>
<div *ngIf="!showLoader">

  <div *ngIf="!isParticipantDetails">
    <div class="datagrid-area">
    <div *ngIf="isSavedRecommendation" class="alert alert-success" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
          </div>
          <span class="alert-text">
            {{"analysisAndReports.reports.trainingRecommendation.recommendationSavedMsg" | translate}}
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="isRestoredRecommendation" class="alert alert-success" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
          </div>
          <span class="alert-text">
            {{"analysisAndReports.reports.trainingRecommendation.recommendationRestoredMsg" | translate}}
          </span>
        </div>
      </div>
    </div>
      <div *ngIf="hasErrorOnSave" class="alert alert-danger" id="save-alert-message" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
            </div>
            <span class="alert-text">
              {{'errorMsg.saveErrorMsg'|translate}}
            </span>
          </div>
        </div>
      </div>
      <form #addForm="ngForm" autocomplete="off" class=" clr-row clr-form" clrForm>
        <clr-combobox-container class="clr-col-6">
          <label class="clr-col-3">{{"analysisAndReports.reports.skillGroup" | translate}}</label>
          <clr-combobox [(ngModel)]="skillGroupSelected" [disabled]="disabled" class="clr-col-8" clrMulti="true"
                        name="multiSelect">
            <ng-container *clrOptionSelected="let selected">
              {{selected}}
            </ng-container>
            <clr-options>
              <clr-option *clrOptionItems="let skill of skillGroups" [clrValue]="skill.skillGroupName">
                {{skill.skillGroupName}}
              </clr-option>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>
        <div *hasAccess="functionality.analysis" class="clr-col-6 btn-alignment">
          <button (click)="exportTrainingRecommendation()" [disabled]="!showDatagrid" class="btn btn-sm btn-primary">
            {{"analysisAndReports.reports.export" | translate}}
          </button>
          <button (click)="showRestoreRecommendation = true" [disabled]="!showDatagrid" class="btn btn-sm btn-primary">
            {{"analysisAndReports.reports.trainingRecommendation.restoreBtn" | translate}}
          </button>
          <button (click)="onSetThreshold()" *ngIf="!ksaDetails?.isAdvance" [disabled]="!showDatagrid"
                  class="btn btn-sm btn-primary">
            {{"analysisAndReports.reports.setThresholdBtn" | translate}}
          </button>
          <button (click)="onShowColorModel()" [disabled]="!showDatagrid" class="btn btn-sm btn-primary">
            {{"analysisAndReports.reports.colorPickerBtn" | translate}}
          </button>
          <button (click)="onSaveTrainingRecommendation()" [disabled]="!showDatagrid" class="btn btn-sm btn-primary">
            {{"analysisAndReports.reports.saveBtn" | translate}}
          </button>

          <button (click)="onOpenNewWindow()" *ngIf="showNewWindowButton" [disabled]="!showDatagrid"
                  class="btn btn-sm btn-primary">
            {{"analysisAndReports.reports.openNewWindow" | translate}}
          </button>
          <button (click)="onRefresh()" [disabled]="!showDatagrid" class="btn btn-sm btn-primary">
            {{"analysisAndReports.reports.refresh" | translate}}
          </button>
        </div>
      </form>
    <div *ngIf="isSavedResponse" class="alert alert-success" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
          </div>
          <span class="alert-text">{{'analysisAndReports.responseManagement.responseSuccess' | translate}}</span>
        </div>
      </div>
    </div>
    <clr-datagrid *ngIf="showDatagrid" class="role-profile-datagrid" (clrDgRefresh)="trainingRecommendationRefresh($event)" [clrDgLoading]="loading">
      <clr-dg-column class="width-120">{{"analysisAndReports.reports.actions" | translate}}</clr-dg-column>
      <clr-dg-column *ngFor="let column of responseColumnSettings; let i = index"
                     [clrDgField]="column?.responseColumnDisplay?.questionName" class="width-175"
                     [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''">
        <ng-template clrDgHideableColumn [(clrDgHidden)]="responseColumnSettings[i].isHideable">
          {{column?.responseColumnDisplay?.questionName}}
        </ng-template>
      </clr-dg-column>
      <clr-dg-column class="width-100">
        {{"analysisAndReports.reports.trainingRecommendation.courseCounts" | translate}}
      </clr-dg-column>
      <clr-dg-column *ngFor="let column of (responseData[0]?.participantAnalysisList || []); let i = index"
                     [hidden]="!skillGroupSelected?.includes(column.skillGroupName)"
                     class="width-220">
        {{column?.skill?.skillName}}
      </clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell class="width-120">Pin</clr-dg-cell>
        <clr-dg-cell *ngFor="let column of responseColumnSettings; let i = index"
                     [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''"
                     style="text-align: center" class="width-175">
          <clr-icon shape="pin" title="pin" (click)="setPinnedColumn(column.responseColumnDisplay.questionName)"
                    [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'pinned-column' : ''">
          </clr-icon>
        </clr-dg-cell>
        <clr-dg-cell  class="width-100"></clr-dg-cell>
        <clr-dg-cell *ngFor="let column of (responseData[0]?.participantAnalysisList || []);let j = index"
                     [hidden]="!skillGroupSelected?.includes(column.skillGroupName)"
                     class="width-220"></clr-dg-cell>
      </clr-dg-row>
      <clr-dg-row *ngFor="let response of responseData; let i = index">
        <clr-dg-cell class="width-120">
          <clr-icon (click)="onShowParticipantDetails(response, i)" shape="eye"></clr-icon>
          <clr-icon (click)="onResetByParticipant(response.participantId, i)" *hasAccess="functionality.analysis"
                    class="left-margin"
                    shape="undo"></clr-icon>
          <clr-icon (click)="onShowResponse(response)" class="left-margin" shape="pop-out"></clr-icon>
          <clr-icon *ngIf="response.visited" class="left-margin" shape="check" style="color:#2e8500"></clr-icon>
        </clr-dg-cell>
        <clr-dg-cell *ngFor="let column of responseColumnSettings;" class="width-175"
                     [attr.name]="column?.responseColumnDisplay?.questionName+i"
                     [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''">
          {{analysisService.getAnswerFromParticipant(response.responseColumnWithParticipants, column?.responseColumnDisplay?.id)}}
        </clr-dg-cell>
        <clr-dg-cell class="width-100" [attr.name]="'Course Counts'+i">
          <ul style="list-style-type: none;">
            <li>{{"analysisAndReports.reports.trainingRecommendation.score" | translate}}</li>
            <li *ngIf="ksaDetails?.isAdvance">{{"analysisAndReports.reports.trainingRecommendation.target" |
              translate}}</li>
            <li *ngIf="ksaDetails?.isAdvance">{{"analysisAndReports.reports.trainingRecommendation.delta" |
              translate}}</li>
            <li>{{"analysisAndReports.reports.trainingRecommendation.training" | translate}}</li>
          </ul>
        </clr-dg-cell>
        <clr-dg-cell *ngFor="let column of response.participantAnalysisList;let j = index"
                     [attr.name]="column?.skill?.skillName+i"
                     [hidden]="!skillGroupSelected?.includes(column.skillGroupName)" class="width-220">
          {{!hasHeatMapValue(column) ?
          ("analysisAndReports.reports.notAvailable" | translate) : column.heatMapValue + '%'}}<br>
          <div *ngIf="ksaDetails?.isAdvance">
            {{column.roleMapAnalysisList[0].roleMapValue === 0 ? 0 + "%" :
            column.roleMapAnalysisList[0].roleMapValue === null ? ("analysisAndReports.reports.notAvailable" |
                translate) :
              column.roleMapAnalysisList[0].roleMapValue + '%'}}<br>
            <span [style.color]="column.tableFontColorCode" [style.background]="column.tableBackgroundColorCode">
              {{!hasHeatMapValue(column) ?
              ("analysisAndReports.reports.notAvailable" | translate) : column.roleMapAnalysisList[0].deltaValue + '%'}}<br>
            </span>
          </div>
          <!-- Course dropdown -->
          <div class="clr-row">
            <clr-select-container class="select-container clr-col-10">
              <label style="display:none"></label>
              <select [(ngModel)]="column.roleMapAnalysisList[0].recommendedCourse"
                      [compareWith]="areCoursesEqual" class="select-dropdown" clrSelect id="options{{i}}{{j}}">
                <ng-container *ngFor="let course of courseList">
                  <option *ngIf="course.skillId == column.skill.id"
                          [ngValue]="{id: course.courseList.id}">
                    {{getCourseNameWithVersion(course.courseList)}}
                  </option>
                </ng-container>
                <ng-container>
                  <option [ngValue]="column.roleMapAnalysisList[0].recommendedCourse?.status === 'Archived' ? column.roleMapAnalysisList[0].recommendedCourse : null">
                    {{"analysisAndReports.reports.trainingRecommendation.noRecommendedCourse" | translate}}
                  </option>
                </ng-container>
              </select>
            </clr-select-container>
            <clr-tooltip class="clr-col-2 tooltip tooltip-xs tooltip-top-left toolTip-icon">
              <clr-icon badge="success" clrTooltipTrigger shape="info-circle"></clr-icon>
              <clr-tooltip-content>
                {{extractRecommendedCourseName(column.roleMapAnalysisList[0].recommendedCourse)}}
              </clr-tooltip-content>
            </clr-tooltip>
          </div>
          <!-- /Course dropdown -->
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-column-toggle>
          <clr-dg-column-toggle-title class="column-toggle-title">Columns
            <clr-icon (click)="onSaveColumnSettings()" class="save-col-icon" role="button" shape="floppy"
                      title="Save"></clr-icon>
          </clr-dg-column-toggle-title>
        </clr-dg-column-toggle>
        <clr-dg-pagination #pagination [clrDgPageSize]="pageSizeService.getPageSize() | async"
                           class="training-pagination"
                           [clrDgTotalItems]="total"
                           [(clrDgPage)]="page">
          <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
            {{"analysisAndReports.reports.trainingRecommendation.recommendationPerPage" | translate}}
          </clr-dg-page-size>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
          {{"analysisAndReports.reports.trainingRecommendation.recommendations" | translate}}
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
    </div>
  </div>

  <div *ngIf="isParticipantDetails">
    <app-participant-details (isParticipantDetails)="onShowParticipant($event)" [ksaId]="ksaId"
                             [participantData]="participantData"
                             [participantIndex]="participantIndex">
    </app-participant-details>
  </div>
</div>

<color-picker *ngIf="isColorModelOpen" [options]="options" [colorData]="this.colorData" [ksaId]="ksaId"
              [isColorModalOpen]="isColorModelOpen" [dataGridType]="dataGridType" (addColorToTable)="addColor($event)"
              [successMsgTimeout]="this.successMsgTimeout" (closeColorPicker)="onHideColorModal()"
              (deleteColorFromTable)="colorData.splice($event,1)"></color-picker>

<clr-modal [(clrModalOpen)]="showRestoreRecommendation">
  <h3 class="modal-title">
    {{"analysisAndReports.reports.trainingRecommendation.restoreConfirmationMsgHeader" | translate}}
  </h3>
  <div class="modal-body">
    <p>{{"analysisAndReports.reports.trainingRecommendation.restoreConfirmationMsg" | translate}}</p>
  </div>
  <div class="modal-footer">
    <button (click)="showRestoreRecommendation = false" class="btn btn-outline btn-sm">
      {{'admin.cancelBtn' | translate}}
    </button>
    <button (click)="onRestoreAllTrainings()" class="btn btn-primary btn-sm">
      {{"analysisAndReports.reports.trainingRecommendation.restoreBtn" | translate}}
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showThresholdSetter" (clrModalOpenChange)="onCancelSettingThreshold(thresholdForm)">
  <h3 class="modal-title">
    {{"analysisAndReports.reports.trainingRecommendation.setThresholdHeader" | translate}}
  </h3>
  <div class="modal-body">
    <form clrForm #thresholdForm="ngForm">
      <clr-input-container class="clr-row">
        <label class="clr-col-10">{{'analysisAndReports.reports.trainingRecommendation.threshold' | translate}}*</label>
        <input class="clr-col-2" clrInput type="text" [(ngModel)]="threshold" name="threshold"
               oninput="if(this.value > 100 || this.value < 0) {this.value = null;}" placeholder="00" required/>
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="showThresholdSetter = false" class="btn btn-outline btn-sm">
      {{'admin.cancelBtn' | translate}}
    </button>
    <button (click)="saveThreshold()" class="btn btn-primary btn-sm">
      {{"admin.saveBtn" | translate}}
    </button>
  </div>
</clr-modal>
