/*
 * Copyright 2022 VMware, Inc. All rights reserved. VMware Confidential
 */

import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {MasterSurveyTranslationQuestion} from "../../../../models/master-survey-translation.model";

@Injectable({providedIn: 'root'})
export class TranslateMasterSurveyService {
  questionsSubject: Subject<MasterSurveyTranslationQuestion[]> = new Subject();

}
