/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {DisplayLogicParameter, ParticipantQuestion} from '../models/participant-ksa.model';
import {isEqual} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DisplayLogicFunctionalityService {
  setIsNullDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.answerValuesText === '') {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsNotNullDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.answerValuesText !== '') {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsSelectedDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.displayConditionAnswerLabelID === displayLogicParams.selectedAnswerLabelsID &&
      displayLogicParams.selectedAnswerAction === true) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsNotSelectedDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.displayConditionAnswerLabelID === displayLogicParams.selectedAnswerLabelsID &&
      !displayLogicParams.selectedAnswerAction) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsEqualToDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.answerValuesText === displayLogicParams.displayValueQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsNotEqualToDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.answerValuesText !== displayLogicParams.displayValueQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsGreaterThenDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.answerValuesText > displayLogicParams.displayValueQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsNotGreaterThenDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (!(displayLogicParams.answerValuesText > displayLogicParams.displayValueQuestion)) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsLessThenDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.answerValuesText < displayLogicParams.displayValueQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsNotLessThenDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (!(displayLogicParams.answerValuesText < displayLogicParams.displayValueQuestion)) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsLessThenOrEqualToDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.answerValuesText <= displayLogicParams.displayValueQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsGreaterThenOrEqualToDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.answerValuesText >= displayLogicParams.displayValueQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsDisplayedDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (displayLogicParams.isDisplayQuestion === true) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setIsNotDisplayedDisplayLogic(displayLogicParams: DisplayLogicParameter): ParticipantQuestion[] {
    let resultQuestionList: ParticipantQuestion[] = [];

    if (!displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, true);
    } else if (displayLogicParams.isDisplayQuestion) {
      resultQuestionList = this.setDisplayLogicOnQuestion(displayLogicParams, false);
    }

    return resultQuestionList;
  }

  setDisplayLogicOnQuestion(displayLogicParams: DisplayLogicParameter, valueOfDisplay: boolean): ParticipantQuestion[] {
    displayLogicParams.questionListParticipant.forEach(question => {
      if (displayLogicParams.isQuestionDisplayAction) {
        if ((question?.id === displayLogicParams.selectedQuestionToDisplay?.id ||
            question?.sectionQuestionId === displayLogicParams.selectedQuestionToDisplay?.id) &&
          displayLogicParams.isDisplayQuestion) {
          const oldIsDisplayValue = question.isDisplay;

          question.isDisplay = valueOfDisplay && question.isRoleProfileDisplay;

          if (!isEqual(oldIsDisplayValue, question.isDisplay)) {
            question.question.isModified = true;
          }
        }
      } else {
        if (question?.sectionID === displayLogicParams.surveySectionID &&
          displayLogicParams.isDisplayQuestion) {
          const oldIsDisplayValue = question.isDisplay;
          const oldIsSectionQuestionDisplayValue = question.isSectionQuestionDisplay;

          question.isDisplay = valueOfDisplay && question.isRoleProfileDisplay;
          question.isSectionQuestionDisplay = valueOfDisplay;

          if (!isEqual(oldIsDisplayValue, question.isDisplay) || !isEqual(oldIsSectionQuestionDisplayValue, question.isSectionQuestionDisplay)) {
            question.question.isModified = true;
          }

        }
      }
    });
    return displayLogicParams.questionListParticipant;
  }
}
