/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {AnonymityTypeIdEnum, QuestionMode} from '../../../enums/ksa-survey-settings.enum';
import {QuestionType} from '../../../enums/questionType.enum';
import {SurveyControls} from '../../../enums/survey-controls.enum';
import {KsaRoleProfile} from '../../../models/ksa-role-profile-skill.model';
import {AnswerLabel} from '../../../models/master-survey-questions.model';
import {ParticipantQuestion} from '../../../models/participant-ksa.model';
import {Participant, ParticipantSurvey} from '../../../models/particpant-survey.model';
import {AnalysisShareService} from '../../../services/analysis-share.service';
import {DisplayLogicService} from '../../../services/display-logic.service';
import {KsaSurveyPageFunctionalityService} from '../../../services/ksa-survey-page-functionality.service';
import {ParticipantService} from '../../../services/participant.service';
import {ParticipantFunctionalityService} from '../../../services/participate-functionality.service';
import {SkipLogicService} from '../../../services/skip-logic.service';
import {AnonymityType} from '../../../models/ksa-role-profile-set.model';
import {FreeTextValidationService} from '../../../services/free-text-validation.service';
import {Observable, of} from 'rxjs';
import {TealiumUtagService} from '../../../services/utag.service';
import {KsaQuestionAnswerByParticipant} from '../../../models/ksa-non-technical-answers.model';
import {KsaTechnicalQuestionAnswerByParticipant} from '../../../models/ksa-technical-answers.model';
import {cloneDeep} from 'lodash';
import {hideSpinner, showSpinner} from '../../../common/spinner';
import {TranslateService} from '@ngx-translate/core';
import {QuestionVisibilityUtil} from '../util/QuestionVisibilityUtil';

@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent implements OnInit {
  questionList: ParticipantQuestion[] = [{index: 0}];
  questionsToDisplay: ParticipantQuestion[] = [{
    index: 0,
    isDisplay: true,
    isSkipDisplay: true,
    isRoleProfileDisplay: true
  }];
  end = 0;
  initial = 0;
  displayPreButton: boolean;
  questionTypeEnum: typeof QuestionType = QuestionType;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  ksaDetails: ParticipantSurvey;
  roleProfiles: Array<KsaRoleProfile> = [];
  skillsIdsJobRoleProfile: number[];
  nextIndex = 0;
  firstIndex = 0;
  progressBarCount = 0;
  masterKsaId = 0;
  techQuestionCount = 0;
  nonTechQuestionCount = 0;
  unansweredQuestionFound = false;
  showRoleProfileChangeWarning = false;
  showSurveyResumeMessage = true;
  showNextButton = false;
  showSubmitButton = false;
  showSaveButton = false;
  ksaErrorMessage = '';
  validPageFound = false;
  questionModeEnum: typeof QuestionMode = QuestionMode;
  freeTextInvalidAnswerInputs: Array<ParticipantQuestion> = [];
  showAlertSaved = false;

  @ViewChild('previewContainer') previewContainer: ElementRef;

  private readonly ALERT_HIDE_TIMEOUT = 3000;

  constructor(private participantService: ParticipantService,
              private displayLogicService: DisplayLogicService, private skipLogicService: SkipLogicService,
              private route: ActivatedRoute, private analysisShareService: AnalysisShareService,
              private participantFunctionalityService: ParticipantFunctionalityService,
              private ksaSurveyPageFunctionalityService: KsaSurveyPageFunctionalityService,
              private router: Router,
              readonly freeTextValidationService: FreeTextValidationService,
              private readonly utagService: TealiumUtagService,
              private readonly translate: TranslateService) {
  }

  ngOnInit(): void {
    this.utagService.trackStep(this.router.url);
    this.getKsaDetails();
  }

  getKsaDetails(ksaDetails$: Observable<ParticipantSurvey> = null): void {
    this.route.params.subscribe(params => {
      showSpinner(this.translate.instant('spinnerLabels.common.loading'));
      ksaDetails$ = ksaDetails$ || this.participantService.getKsaDetails(params.id);
      ksaDetails$.pipe(finalize(() => {
        hideSpinner();
        if (this.ksaDetails) {
          this.populateSurvey();
        }
      })).subscribe(ksaDetail => {
          this.participantService.initiateAnswering(params.id).subscribe();
          if (ksaDetail.surveyCompleted) {
            this.router.navigateByUrl(`/completed`);
          }
          if (ksaDetail.masterKsa.isAdvance && this.roleProfiles?.length === 0) {
            this.participantService.getKsaRoles(ksaDetail.masterKsa?.ksaJobProfileSet?.id)
              .subscribe(response => {
                this.roleProfiles = response;
              });
          }

          if (ksaDetail.lastVisitedQuestionIndex !== null) {
            setTimeout(() => {
              this.showSurveyResumeMessage = false;
            }, this.ALERT_HIDE_TIMEOUT);
          } else {
            this.showSurveyResumeMessage = false;
          }
          this.ksaDetails = ksaDetail;
          this.masterKsaId = ksaDetail.masterKsa.id;
        },
        error => {
          this.ksaErrorMessage = error.error.message;
          if (this.ksaErrorMessage.includes(this.surveyControlsEnum.ksaStatusClosed)) {
            this.analysisShareService.setClosedKsaStatu(true);
            this.router.navigateByUrl(`/completed`);
          }
        });
    });
  }

  populateSurvey(): void {
    this.ksaDetails.masterKsa.ksaSection.sort((previousSection, nextSection) =>
      (previousSection.sectionSequenceNo > nextSection.sectionSequenceNo ? 1 : -1));
    this.questionList =
      this.participantService.populateSurveyQuestions(this.ksaDetails.masterKsa.ksaSection, false, this.ksaDetails, null);
    this.ksaPreviewQuestionInitialize();
    this.preAnswerParticipantInfoQuestions();
    this.checkForUnansweredQuestion();
    this.getProgressBarCount();
  }

  ksaPreviewQuestionInitialize(): void {
    this.showSubmitButton = false;
    this.showNextButton = false;
    if (this.ksaDetails.lastVisitedQuestionIndex == null) {
      this.ksaDetails.lastVisitedQuestionIndex = 0;
    }
    this.ksaDetails.lastVisitedQuestionIndex === 0 ?
      this.onDisplayNextList(0) : this.onDisplayNextList(this.ksaDetails?.lastVisitedQuestionIndex);
    if (this.ksaDetails.lastVisitedQuestionIndex === 0) {
      // Initialize questions with display/skip logic on first survey opening
      this.ksaSurveyPageFunctionalityService.setDisplayOnInitialize(this.questionList);
    }
    this.firstIndex = this.questionList[0].index;

    if (this.questionList[this.questionList.length - 1]?.index === this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index) {
      this.showSubmitButton = true;
      this.showNextButton = false;
    }

    if (this.questionList[this.questionList.length - 1]?.index === this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index
      && this.questionList[0]?.index === this.questionsToDisplay[0]?.index) {
      this.showSaveButton = true;
    }
  }

  onRoleProfileSelect(id: number, question: ParticipantQuestion): void {
    if ((question.roleProfileInitialValue !== '' && question.roleProfileInitialValue !== null)) {
      this.showRoleProfileChangeWarning = true;
    }
    question.question.isModified = true;
    question.roleProfileInitialValue = question.question.answerValuesText;
    this.onRoleProfileSelection(id, question);
  }

  onRoleProfileSelection(id: number, question: ParticipantQuestion): void {
    this.participantService.getKsaRoleProfileSkill(id).subscribe(response => {
      this.skillsIdsJobRoleProfile = response;
      if (this.ksaDetails.masterKsa.surveyQuestionMode.id === this.questionModeEnum.RoleProfileDriven) {
        this.questionList.filter(q => !q.isRoleProfileDisplay).forEach(q => {
          q.question.isModified = true;
          q.isRoleProfileDisplay = true;
        });
        this.ksaSurveyPageFunctionalityService.setDisplayOnInitialize(this.questionList);

        this.questionList
          .filter(q => q.skill?.id && !this.skillsIdsJobRoleProfile.includes(q.skill?.id))
          .forEach(QuestionVisibilityUtil.hideQuestionsWhenRoleProfileFilterIsApplied);
      }

      const questionMap = QuestionVisibilityUtil.groupQuestionsBySectionId(this.questionList);
      questionMap.forEach((questions, key) => {
        if (!QuestionVisibilityUtil.shouldSectionBeVisible(questions)) {
          questions.forEach(QuestionVisibilityUtil.hideQuestionsWhenRoleProfileFilterIsApplied);
        }
      });
      this.mapVisitedQuestion(question);
      this.checkForUnansweredQuestion();
      this.checkUnansweredQuestions();
      this.checkNextPageValidity();
    });
  }

  onDisplayNextList(start: number): void {
    this.showSubmitButton = false;
    this.showNextButton = false;
    this.questionsToDisplay = [];
    this.initial = start;
    this.ksaDetails.lastVisitedQuestionIndex = start;
    const objNextButton = this.ksaSurveyPageFunctionalityService.onDisplayNextList(start, this.questionList);
    this.questionsToDisplay = objNextButton.questionsToDisplay;
    // For non-tech questions, we need to preserve empty result, because if we don't, there are problems in analysis/response tab
    this.questionsToDisplay.forEach(q => {
      if (q.ksaQuestionAnswerByParticipant?.length === 0) {
        q.question.isModified = true;
      }
    });
    this.displayPreButton = objNextButton.displayPreButton;
    this.nextIndex = this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index;
    this.checkForUnansweredQuestion();
    if (this.questionList[this.questionList.length - 1]?.index > this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index) {
      this.showSubmitButton = false;
      this.showNextButton = true;
    }
    this.checkNextPageValidity();
    this.scrollToTop();
  }

  onDisplayPreList(): void {
    this.showSubmitButton = false;
    this.showNextButton = false;
    this.initial = this.questionList[this.questionsToDisplay[0].index - 1].index - 1;
    const objPreButton = this.ksaSurveyPageFunctionalityService.onDisplayPreList(this.questionList,
      this.initial);
    this.questionsToDisplay = objPreButton.questionsToDisplay;
    this.initial = objPreButton.initial;
    this.displayPreButton = objPreButton.displayPreButton;
    this.nextIndex = objPreButton.nextIndex;
    this.checkForUnansweredQuestion();
    if (this.questionList[this.questionList.length - 1]?.index > this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index) {
      this.showSubmitButton = false;
      this.showNextButton = true;
    }
    this.scrollToTop();
  }

  onAnswerInputFreeText(question: ParticipantQuestion): void {
    if (!this.freeTextValidationService.isAnswerValid(question)) {
      this.unMapVisitedQuestion(question);
      this.flagFreeTextAnswerInvalid(question);
      this.checkForUnansweredQuestion();
      return;
    }
    question.question.isModified = true;
    this.removeFlaggedFreeTextAnswerInvalid(question);

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForFree(question, this.questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForFree(question, this.questionList, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForFree(question, this.questionList, false);
    }
    this.checkForUnansweredQuestion();
    this.checkUnansweredQuestions();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  checkNextPageValidity(): void {
    if (this.questionList[this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index]?.index - 1) {
      this.validPageFound = this.ksaSurveyPageFunctionalityService.checkIfNextPageIsBlank(
        this.questionList[this.questionsToDisplay[this.questionsToDisplay.length - 1].index].index - 1,
        this.questionList);
      if (!this.validPageFound) {
        this.showSaveButton = false;
        this.showSubmitButton = true;
        this.showNextButton = false;
      } else {
        this.showSaveButton = false;
        this.showSubmitButton = false;
        this.showNextButton = true;
      }
    }
  }

  checkUnansweredQuestions(): void {
    this.showSaveButton = this.questionList[this.questionList.length - 1]?.index === this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index
      && this.questionList[0]?.index === this.questionsToDisplay[0]?.index && this.unansweredQuestionFound;
  }

  onAnswerInputMultiple(question: ParticipantQuestion, answerLabel: AnswerLabel): void {
    question.question.answerLabels.forEach(answerLabel => {
      if (answerLabel.isChecked == null) {
        answerLabel.isChecked = false;
      }
    });

    question.question.isModified = true;
    answerLabel.isChecked = !answerLabel.isChecked;

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForMultiple(question, this.questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMultiple(question, this.questionList, answerLabel, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMultiple(question, this.questionList, answerLabel, false);
    }

    this.checkForUnansweredQuestion();
    this.checkUnansweredQuestions();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  onAnswerRadioInputMatrix(question: ParticipantQuestion, rowNumber: number, columnNumber: number, event: PointerEvent): void {
    question.question.answerControls.forEach(control => {
      if (control.rowNumber === rowNumber && control.colNumber === columnNumber) {
        control.isChecked = (event.target as HTMLInputElement).checked;
      } else if (control.rowNumber === rowNumber) {
        control.isChecked = false;
      }
    });
    question.question.isModified = true;

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForMatrixRadio(question, this.questionList,
        (event.target as HTMLInputElement).checked, rowNumber, columnNumber);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMatrixRadio(question, this.questionList,
        (event.target as HTMLInputElement).checked, rowNumber, columnNumber, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMatrixRadio(question, this.questionList,
        (event.target as HTMLInputElement).checked, rowNumber, columnNumber, false);
    }
    this.checkForUnansweredQuestion();
    this.checkUnansweredQuestions();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  onAnswerInputRadio(question: ParticipantQuestion, selectedAnswerLabel: AnswerLabel): void {
    this.ksaSurveyPageFunctionalityService.selectAnswerInputRadio(question.question.answerLabels, selectedAnswerLabel);
    question.question.isModified = true;

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForRadio(question, this.questionList, false);
    }

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForRadio(question, this.questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForRadio(question, this.questionList, true);
    }
    this.checkForUnansweredQuestion();
    this.checkUnansweredQuestions();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  onAnswerCheckInputMatrix(question: ParticipantQuestion, rowNumber: number, columnNumber: number, event: PointerEvent): void {
    question.question.answerControls.forEach(control => {
      if (control.rowNumber === rowNumber && control.colNumber === columnNumber) {
        control.isChecked = (event.target as HTMLInputElement).checked;
        return;
      }
    });

    question.question.isModified = true;
    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForMatrixCheckBox(question, this.questionList,
        (event.target as HTMLInputElement).checked, rowNumber, columnNumber);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMatrixCheckBox(question, this.questionList,
        (event.target as HTMLInputElement).checked, rowNumber, columnNumber, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMatrixCheckBox(question, this.questionList,
        (event.target as HTMLInputElement).checked, rowNumber, columnNumber, false);
    }
    this.checkForUnansweredQuestion();
    this.checkUnansweredQuestions();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  onAnswerInputScale(question: ParticipantQuestion, answer: number, index: number): void {
    question.question.answerValuesText = answer;
    question.question.scaleValueIndex = index;
    question.question.isModified = true;

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForScale(question, this.questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForScale(question, this.questionList, answer, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForScale(question, this.questionList, answer, false);
    }

    this.checkForUnansweredQuestion();
    this.checkUnansweredQuestions();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  onNext(): void {
    const hasChanges = this.ksaDetails.masterKsa.ksaSection.some(s =>
      (s.isCoreSection && s.ksaTechnicalQuestions.some(q => q.sectionQuestion.question.isModified) || (!s.isCoreSection && s.ksaQuestions.some(q => q.question.isModified))));

    if (!hasChanges && !this.ksaDetails.surveyCompleted) {
      this.getKsaDetails(of(this.ksaDetails));
      return;
    }

    const ksaDetails: ParticipantSurvey = cloneDeep(this.ksaDetails);
    ksaDetails.ksaQuestionAnswerByParticipants = [];
    ksaDetails.ksaTechnicalQuestionAnswerByParticipants = [];
    ksaDetails.masterKsa.ksaSection.forEach(section => {
      if (section.isCoreSection) {
        section.ksaTechnicalQuestions
          .filter(q => q.sectionQuestion.question.isModified)
          .forEach(questionTechnical => {
            if (questionTechnical.sectionQuestion.question.answerLabels.length > 1) {
              this.participantFunctionalityService.mapMultipleAnswerLabelListForTechnicalQuestion(ksaDetails, questionTechnical);
              questionTechnical.sectionQuestion.question.questionType.id === this.questionTypeEnum.MatrixTable ? (
                this.participantFunctionalityService.mapMatrixAnswerControlsForTechnicalQuestion(ksaDetails,
                  questionTechnical, this.techQuestionCount)
              ) : (
                this.participantFunctionalityService.mapMultipleChoiceLabelsForTechnicalQuestion(ksaDetails,
                  questionTechnical, this.techQuestionCount)
              );
              this.techQuestionCount++;
            } else {
              if (!questionTechnical.sectionQuestion.isDisplay) {
                this.participantFunctionalityService.mapNonLabelQuestionForFalseIsDisplayForTechnicalQuestion(ksaDetails,
                  questionTechnical);
              } else {
                this.participantFunctionalityService.mapNonLabelQuestionForTrueIsDisplayForTechnicalQuestion(ksaDetails,
                  questionTechnical);
              }
              this.techQuestionCount++;
            }
          });
      } else {
        section.ksaQuestions
          .filter(q => q.question.isModified)
          .forEach(questionNonTechnical => {
              if (questionNonTechnical.question.answerLabels.length > 1) {
                this.participantFunctionalityService.mapMultipleAnswerLabelListForNonTechnicalQuestion(ksaDetails, questionNonTechnical);

                questionNonTechnical.question.questionType.id === this.questionTypeEnum.MatrixTable ? (
                  this.participantFunctionalityService.mapMatrixAnswerControlsForNonTechnicalQuestion(ksaDetails,
                    questionNonTechnical, this.nonTechQuestionCount)
                ) : (
                  this.participantFunctionalityService.mapMultipleChoiceLabelsForNonTechnicalQuestion(ksaDetails,
                    questionNonTechnical, this.nonTechQuestionCount));
                this.nonTechQuestionCount++;
              } else {
                if (!questionNonTechnical.isDisplay) {
                  this.participantFunctionalityService.mapNonLabelQuestionForFalseIsDisplayForNonTechnicalQuestion(ksaDetails,
                    questionNonTechnical);
                } else {
                  this.participantFunctionalityService.mapNonLabelQuestionForTrueIsDisplayForNonTechnicalQuestion(ksaDetails,
                    questionNonTechnical);
                }
                this.nonTechQuestionCount++;
              }
            }
          );
      }
    });
    ksaDetails.masterKsa = {};
    ksaDetails.masterKsa.id = this.masterKsaId;
    showSpinner(this.translate.instant('spinnerLabels.common.saving'));
    this.participantService.submitKsaDetails(ksaDetails)
      .pipe(finalize(hideSpinner))
      .subscribe((data: ParticipantSurvey) => {
          if (!this.ksaDetails.surveyCompleted) {
            this.toggleShowAlertSaved();
          }
          this.questionList = [{index: 0}];
          this.questionsToDisplay = [{
            index: 0,
            isDisplay: true,
            isSkipDisplay: true,
            isRoleProfileDisplay: true
          }];
          this.updateKsaDetails(data);
          this.getKsaDetails(of(this.ksaDetails));
          this.scrollToTop();
        },
        () => {
          this.ksaDetails.surveyCompleted = false;
          this.getKsaDetails();
        });
    this.techQuestionCount = 0;
    this.nonTechQuestionCount = 0;
  }

  mapVisitedQuestion(selectedQuestion: ParticipantQuestion): void {
    const objQuestion = this.questionList.find(question => (question.isDisplay === true &&
      question.index === selectedQuestion.index));
    if (objQuestion != null) {
      if (objQuestion.question.answerLabels.length > 1) {
        if (objQuestion.question.questionType.id === this.questionTypeEnum.MatrixTable) {
          const isLabelSelected = objQuestion.question.answerControls.find(label => {
            return label.isChecked === true;
          });
          isLabelSelected === undefined ? objQuestion.isVisited = false : objQuestion.isVisited = true;
        } else {
          const isLabelSelected = objQuestion.question.answerLabels.find(label => {
            return label.isChecked === true;
          });
          isLabelSelected === undefined ? objQuestion.isVisited = false : objQuestion.isVisited = true;
        }
      } else {
        objQuestion.question.answerValuesText === '' ? objQuestion.isVisited = false : objQuestion.isVisited = true;
      }
    }
    this.getProgressBarCount();
  }

  unMapVisitedQuestion(selectedQuestion: ParticipantQuestion): void {
    const objQuestion: ParticipantQuestion = this.questionList.find(question => (question.isDisplay &&
      question.index === selectedQuestion.index));

    if (objQuestion) {
      objQuestion.isVisited = false;
      this.getProgressBarCount();
    }
  }

  getProgressBarCount(): void {
    let totalQuestionCount = this.questionList.filter(question =>
      (!question.isRoleProfileQuestionVisbileForKsa && question.isDisplay === true && question.isMandatory === true &&
        question.question.questionType.id !== this.questionTypeEnum.Description)).length;

    let totalVisitedQuestionCount = this.questionList.filter(question =>
      (!question.isRoleProfileQuestionVisbileForKsa && question.isDisplay === true && question.isMandatory === true &&
        question.isVisited === true &&
        question.question.questionType.id !== this.questionTypeEnum.Description)).length;
    if (totalQuestionCount == null) {
      totalQuestionCount = 1;
    }

    if (totalVisitedQuestionCount == null) {
      totalVisitedQuestionCount = 1;
    }
    this.progressBarCount = Math.round((totalVisitedQuestionCount * 100) / totalQuestionCount);
  }

  onSubmit(): void {
    this.ksaDetails.surveyCompleted = true;
    this.onNext();
  }

  hideAlerts(): void {
    this.showAlertSaved = false;
    this.showSurveyResumeMessage = false;
  }

  isMatrixCheckboxChecked(question, rowIndex, colIndex): boolean {
    return question?.question?.answerControlMatrixList?.[rowIndex]?.[colIndex]?.isAnswerChecked;
  }

  private preAnswerParticipantInfoQuestions(): void {
    this.questionsToDisplay?.forEach(question => this.populateDefaultValueForQuestionByTags(question));
  }

  private populateDefaultValueForQuestionByTags(question: ParticipantQuestion): void {
    const masterKsaAnonymityType: AnonymityType = this.ksaDetails.masterKsa.anonymityType;
    const participantFieldsMap: Participant = this.ksaDetails.participant;
    if (masterKsaAnonymityType && masterKsaAnonymityType.id !== AnonymityTypeIdEnum.None || !participantFieldsMap) {
      return;
    }

    const fieldNameLowerCase: string = question.question.mapSurveyQuestionWithTags?.[0]?.surveyQuestionTag?.tagName.toLowerCase();
    if (fieldNameLowerCase) {
      this.getParticipantFieldsWithValues().forEach(pair => {
        if (pair[0].toLowerCase() === fieldNameLowerCase && pair[1]) {
          question.question.answerValuesText = pair[1];
          question.question.isModified = true;
        }
      });
    }

    this.checkUnansweredQuestions();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  private getParticipantFieldsWithValues(): [string, any][] {
    const participant = this.ksaDetails.participant;

    if (!participant) {
      return [];
    }
    return Object.entries(participant);
  }

  private flagFreeTextAnswerInvalid(question: ParticipantQuestion): void {
    if (!this.freeTextInvalidAnswerInputs.includes(question)) {
      this.freeTextInvalidAnswerInputs.push(question);
    }
  }

  private removeFlaggedFreeTextAnswerInvalid(question: ParticipantQuestion): void {
    if (this.freeTextInvalidAnswerInputs.includes(question)) {
      this.freeTextInvalidAnswerInputs.forEach((value, index) => {

        if (value === question) {
          this.freeTextInvalidAnswerInputs.splice(index, 1);
        }
      });
    }
  }

  private checkForUnansweredQuestion(): void {
    this.unansweredQuestionFound = this.freeTextInvalidAnswerInputs.length !== 0 ||
      this.ksaSurveyPageFunctionalityService.mandatoryQuestionAnswered(this.questionsToDisplay, this.ksaDetails.masterKsa.isAdvance);
  }

  private toggleShowAlertSaved(): void {
    this.showAlertSaved = true;
    setTimeout(() => {
      this.showAlertSaved = false;
    }, this.ALERT_HIDE_TIMEOUT);
  }

  private updateKsaDetails(ksaDetails: ParticipantSurvey): void {
    this.ksaDetails.surveyCompleted = ksaDetails.surveyCompleted;
    this.ksaDetails.lastVisitedQuestionIndex = ksaDetails.lastVisitedQuestionIndex;
    const questionAnswerMap: Map<number, Array<KsaQuestionAnswerByParticipant>> = this.createQuestionAnswerMap(ksaDetails);
    const technicalQuestionAnswerMap: Map<number, Array<KsaTechnicalQuestionAnswerByParticipant>> = this.createTechnicalQuestionAnswerMap(ksaDetails);
    this.ksaDetails.masterKsa.ksaSection?.forEach(section => {
      section.ksaQuestions?.forEach(q => {
        questionAnswerMap.get(q.id)?.forEach(answer => {
          q.ksaQuestionAnswerByParticipant = q.ksaQuestionAnswerByParticipant || new Array<KsaQuestionAnswerByParticipant>();
          this.updateAnswerList(q.ksaQuestionAnswerByParticipant, answer);
        });
      });
      section.ksaTechnicalQuestions?.forEach(q => {
        technicalQuestionAnswerMap.get(q.id)?.forEach((answer: any) => {
          q.ksaTechnicalQuestionAnswerByParticipant = q.ksaTechnicalQuestionAnswerByParticipant || new Array<any>();
          this.updateAnswerList(q.ksaTechnicalQuestionAnswerByParticipant, answer);
        });
      });
    });

    ksaDetails.ksaQuestionAnswerByParticipants?.forEach(answer => {
      this.ksaDetails.ksaQuestionAnswerByParticipants = this.ksaDetails.ksaQuestionAnswerByParticipants || new Array<KsaQuestionAnswerByParticipant>();
      this.updateAnswerList(this.ksaDetails.ksaQuestionAnswerByParticipants, answer);
    });

    ksaDetails.ksaTechnicalQuestionAnswerByParticipants?.forEach(answer => {
      this.ksaDetails.ksaTechnicalQuestionAnswerByParticipants = this.ksaDetails.ksaTechnicalQuestionAnswerByParticipants || new Array<KsaTechnicalQuestionAnswerByParticipant>();
      this.updateAnswerList(this.ksaDetails.ksaTechnicalQuestionAnswerByParticipants, answer);
    });

    this.resetIsModifiedFlag();
  }

  private updateAnswerList(list: Array<Partial<{ id: number }>>, answer: Partial<{ id: number }>): void {
    const index = list.findIndex(e => e.id === answer.id);
    if (index !== -1) {
      list[index] = answer;
    } else {
      list.push(answer);
    }
  }

  private resetIsModifiedFlag(): void {
    this.ksaDetails.masterKsa.ksaSection.forEach(s => {
        if (s.isCoreSection) {
          s.ksaTechnicalQuestions.forEach(q => q.sectionQuestion.question.isModified = false);
        } else {
          s.ksaQuestions.forEach(q => q.question.isModified = false);
        }
      }
    );
  }

  private createTechnicalQuestionAnswerMap(ksaDetails: ParticipantSurvey): Map<number, Array<KsaTechnicalQuestionAnswerByParticipant>> {
    const technicalQuestionAnswerMap: Map<number, Array<KsaTechnicalQuestionAnswerByParticipant>> = new Map();
    ksaDetails.ksaTechnicalQuestionAnswerByParticipants?.forEach(q => {
      const answers = technicalQuestionAnswerMap.get(q.technicalQuestionId) || new Array<KsaQuestionAnswerByParticipant>();
      answers.push(q);
      technicalQuestionAnswerMap.set(q.technicalQuestionId, answers);
    });
    return technicalQuestionAnswerMap;
  }

  private createQuestionAnswerMap(ksaDetails: ParticipantSurvey): Map<number, Array<KsaQuestionAnswerByParticipant>> {
    const questionAnswerMap: Map<number, Array<KsaQuestionAnswerByParticipant>> = new Map();
    ksaDetails.ksaQuestionAnswerByParticipants?.forEach(q => {
      const answers = questionAnswerMap.get(q.questionId) || new Array<KsaQuestionAnswerByParticipant>();
      answers.push(q);
      questionAnswerMap.set(q.questionId, answers);
    });
    return questionAnswerMap;
  }

  private scrollToTop() {
    this.previewContainer.nativeElement.scrollTop = 0;
  }

}
