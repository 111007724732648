import {Component, OnInit} from '@angular/core';
import {KsaAdministrationService} from '../../../../services/ksa-administration.service';
import {MasterSurveyService} from '../../../../services/master-survey.service';
import {TranslateService} from '@ngx-translate/core';
import {KsaShareService} from '../../../../services/ksa-share.service';
import {KsaRoleProfileService} from '../../../../services/ksa-role-profile.service';
import {GlobalThreshold, QuestionMode} from '../../../../enums/ksa-survey-settings.enum';
import {KsaStatus} from '../../../../enums/status.enum';
import {hideSpinner, showSpinner} from '../../../../common/spinner';
import {finalize} from 'rxjs/operators';
import {UserService} from '../../../../services/user.service';
import {Functionality} from '../../../../enums/functionality.enum';
import {KsaSettingsService} from './ksa-settings.service';

@Component({
  selector: 'app-ksa-settings',
  templateUrl: './ksa-settings.component.html',
  styleUrls: ['./ksa-settings.component.scss']
})
export class KsaSettingsComponent implements OnInit {

  surveyQuestionModes: any[] = [];
  masterSurveyId: any;
  errorMsg = '';
  roleProfileSet: any = '';
  questionModeEnum: typeof QuestionMode = QuestionMode;
  thresholdEnum: typeof GlobalThreshold = GlobalThreshold;
  statusEnum: typeof KsaStatus = KsaStatus;
  ksaJobProfileSet: any = {
    ksaJobProfileSet: {
      id: ''
    }
  };
  roleProfileSets: any = {
    content: []
  };
  newKsaData: any = this.ksaDataFormat();
  surveySaveSuccessAlert = false;
  errorMessage = false;
  messageSuccessTimer = true;
  disableSaveBtnWhileSaving = false;
  hasWritePermission: boolean;
  ksaSections;

  constructor(public ksaAdministrationService: KsaAdministrationService,
              public masterSurveyService: MasterSurveyService, private translate: TranslateService,
              private ksaShareService: KsaShareService, private ksaRoleProfileService: KsaRoleProfileService,
              private readonly userService: UserService,
              private readonly ksaSettingsService: KsaSettingsService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.ksaAdministration);
    this.ksaShareService.getKSADetails().subscribe(data => {
      this.assignData(data);
      this.ksaSections = this.getKsaTechnicalSections(this.newKsaData);
    });
    this.getSurveyQuestionModes();
  }


  assignData(data: any) {
    if (data != null) {
      this.masterSurveyId = data.masterSurvey.id;
      this.newKsaData = {...data};
      this.roleProfileSet = data?.ksaJobProfileSet?.id;
      if (this.newKsaData?.isAdvance && this.roleProfileSet === null) {
        this.disableSaveBtnWhileSaving = true;
      }

      data.isAdvance && this.getRoleProfileSets();
      this.newKsaData.ksaSection.sort((previousSection, nextSection) => (previousSection.sectionSequenceNo > nextSection.sectionSequenceNo ? 1 : -1));
    }
  }

  getRoleProfileSets(): void {
    this.ksaRoleProfileService.getRoleProfileSetsMetadata(this.newKsaData.id).subscribe(data => {
      this.roleProfileSets = data;
    });
  }

  getSurveyQuestionModes(): void {
    this.ksaAdministrationService.getSurveyQuestionModes().subscribe(data => {
      this.surveyQuestionModes = data;
    });
  }

  onUpdateKsa(): void {
    if (this.newKsaData.surveyQuestionMode.id === this.questionModeEnum.ManuallyChooseQuestionSections) {
      this.saveSelectedSectionsToLocalStorage();
    }
    this.newKsaData.ksaSection.map(ksaSection => {
      const tempId = ksaSection.section.id;
      const isSectionMandatoryTemp = ksaSection.section.isMandatorySection;
      ksaSection.section = {};
      ksaSection.section.id = tempId;
      ksaSection.section.isMandatorySection = isSectionMandatoryTemp;
      ksaSection.ksaTechnicalQuestions.map(question => {
        const questionTechId = question.sectionQuestion.id;
        question.sectionQuestion = {};
        question.sectionQuestion.id = questionTechId;
      });
      ksaSection.ksaQuestions.map(question => {
        const questionNonTechId = question.section.id;
        question.section = {};
        question.section.id = questionNonTechId;
      });
    });
    this.disableSaveBtnWhileSaving = true;
    if (this.newKsaData.isAdvance && this.roleProfileSet) {
      this.ksaJobProfileSet.ksaJobProfileSet.id = this.roleProfileSet;
      this.newKsaData = {...this.newKsaData, ...this.ksaJobProfileSet};
    } else {
      this.newKsaData.ksaJobProfileSet = null;
    }
    if (this.newKsaData.surveyQuestionMode.id !== this.questionModeEnum.ManuallyChooseQuestionSections) {
      this.newKsaData?.ksaSection?.forEach((element: any) => {
          element.isQuestionSectionsSelected = true;
        }
      );
    }
    this.newKsaData.isAdvance && (this.newKsaData.globalThreshold = this.thresholdEnum.GlobalThreshold);
    delete this.newKsaData.ksaJobProfileSets;
    delete this.newKsaData.anonymityType.anonymityTypeName;
    delete this.newKsaData.geo.countryList;
    delete this.newKsaData.geo.geoName;
    delete this.newKsaData.surveyQuestionMode.surveyQuestionModeName;
    this.newKsaData.masterSurvey = {};
    this.newKsaData.masterSurvey.id = this.masterSurveyId;
    showSpinner(this.translate.instant('spinnerLabels.common.saving'));
    this.ksaAdministrationService.updateKsa(this.newKsaData)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.ksaShareService.setKSADetails(data);
          this.surveySaveSuccessAlert = true;
          this.messageSuccessTimer = true;
          this.errorMessage = false;
          this.disableSaveBtnWhileSaving = false;
        },
        () => {
          this.surveySaveSuccessAlert = false;
          this.errorMessage = true;
          this.disableSaveBtnWhileSaving = false;
        });
    setTimeout(() => {
      this.messageSuccessTimer = false;
    }, 3000);
  }

  setSurveyQuestionMode(id): void {
    this.newKsaData.surveyQuestionMode.id = id;
  }

  private getKsaTechnicalSections(ksa) {
    return ksa?.ksaSection?.filter(ksaSection => ksaSection.section.isTechnicalSection);
  }

  private saveSelectedSectionsToLocalStorage(): void {
    const sectionIds: number[] = this.ksaSections?.filter(e => e.isQuestionSectionsSelected).map(e => e.section.id) || [];
    this.ksaSettingsService.saveSectionIdsToLocalStorage(this.newKsaData.masterSurvey.id, sectionIds);
  }

  private ksaDataFormat(): any {
    return {
      anonymityType: {
        id: ''
      },
      country: '',
      customer: '',
      description: '',
      geo: {
        id: ''
      },
      globalThreshold: this.thresholdEnum.GlobalThreshold,
      isAdvance: false,
      ksaName: '',
      ksaSection: [
        {
          isCoreSection: false,
          isQuestionSectionsSelected: false,
          ksaQuestions: [
            {
              isPageBreakAdded: false,
              isMandatory: false,
              question: {
                id: ''
              },
              questionNote: '',
              questionText: '',
              questionType: {
                id: ''
              },
              section: {
                id: ''
              },
              skill: {
                id: ''
              },
              questionDisplayCondition: {
                id: ''
              },
              language: {
                id: ''
              },
              questionSkipCondition: {
                id: ''
              },
              sectionQuestionSequenceNo: ''
            }
          ],
          mandatory: false,
          section: {
            id: ''
          },
          sectionName: '',
          sectionSequenceNo: ''
        }
      ],
      ksaTeam: {
        id: ''
      },
      masterSurvey: {
        id: ''
      },
      surveyQuestionMode: {
        id: this.questionModeEnum.ManuallyChooseQuestionSections
      },
      validTill: ''
    };
  }
}
