/********************************************************************** *
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
 **********************************************************************/

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AnalysisResponseData, CourseListAnalysis} from '../../../models/analysis-response-data.model';
import {CertificateListAnalysis} from '../../../models/certificate-list.model';
import {ContentEntity} from '../../../models/ksa-role-profile-set.model';
import {AnalysisShareService} from '../../../services/analysis-share.service';
import {ChartOptions} from '../../../models/respose-data.model';
import {ChartComponent} from 'ng-apexcharts';
import {finalize} from "rxjs/operators";
import {Functionality} from "../../../enums/functionality.enum";
import {KsaDetails} from '../../../models/master-ksa.model';
import {Status} from "../../../enums/status.enum";
import {ButtonId} from "../../../enums/buttonId.enum";

@Component({
  selector: 'app-participant-details',
  templateUrl: './participant-details.component.html',
  styleUrls: ['./participant-details.component.scss']
})
export class ParticipantDetailsComponent implements OnInit {

  @Output() isParticipantDetails: EventEmitter<boolean> = new EventEmitter();
  @Input() ksaId: number;
  @Input() participantId: number;
  @Input() participantData: AnalysisResponseData;
  @Input() participantIndex: number;
  courseList: CourseListAnalysis[] = [];
  saveSuccess: boolean = false;
  ksaSet: ContentEntity;
  certificateDetails: CertificateListAnalysis;
  roleNameOnLoad: number;
  @ViewChild("chart") chart: ChartComponent;
  chartOptions: Partial<ChartOptions>;
  ksaDetails: KsaDetails;
  functionality: typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;
  scorecardPreview: boolean = false;

  constructor(private readonly analysisService: AnalysisShareService) {
  }

  ngOnInit(): void {
    this.getKsaDetails();
    this.getKsaRoleProfilesByKsaSetAndKsaId(this.ksaId);
    this.getRecommendedCourses();
    this.getCertificationData();
    this.roleNameOnLoad = this.participantData?.participantAnalysisList[0]?.roleMapAnalysisList[0]?.participantRoleProfile?.recommendedKsaRoleProfile?.id ?
      this.participantData?.participantAnalysisList[0]?.roleMapAnalysisList[0]?.participantRoleProfile?.recommendedKsaRoleProfile?.id : null;
    if (this.participantData.participantAnalysisList[0].roleMapAnalysisList[0].participantRoleProfile.recommendedKsaRoleProfile === null) {
      this.participantData.participantAnalysisList[0].roleMapAnalysisList[0].participantRoleProfile.recommendedKsaRoleProfile = {};
      this.participantData.participantAnalysisList[0].roleMapAnalysisList[0].participantRoleProfile.recommendedKsaRoleProfile.id = null;
    }
    this.fillRoleAlignmentChartData();
  }

  getKsaDetails(): void{
    this.analysisService.getKsaDetails(this.ksaId).subscribe(data => {
      this.ksaDetails = data;
    })
  }

  getCertificationData(): void{
    this.analysisService.getCertificationData(this.ksaId, this.participantData.participantId).subscribe(data => {
      this.certificateDetails = data;
    })
  }

  getRecommendedCourses(): void {
    this.analysisService.getRecommendedCourses(this.ksaId).subscribe(data => {
      this.courseList = data.filter(skill => skill.courseList.status !== Status.Archived.toString());
    })
  }

  setRecommendedCourse(event, index): void {
    const courseSelected = this.courseList.find(course => {
      return course.courseList.id == event;
    })
    this.participantData.participantAnalysisList[index].roleMapAnalysisList[0].recommendedCourse = {};
    this.participantData.participantAnalysisList[index].roleMapAnalysisList[0].recommendedCourse = courseSelected?.courseList || null;
  }

  saveParticipantDetails(): void {
    this.analysisService.saveParticipantAnalysisData(this.ksaId,this.participantData).subscribe(data =>{
      this.saveSuccess = true;
    })
    setTimeout(() => {
      this.saveSuccess = false;
    }, 3000);
  }

  getKsaRoleProfilesByKsaSetAndKsaId(ksaId: number): void {
    this.analysisService.getKsaRoleProfilesByKsaSetAndKsaId(ksaId).subscribe(data => {
      this.ksaSet = data;
    })
  }

  changeDeltaValues(responseId, roleProfileId, event): void {
    const confirmation = window.confirm("Changing role profiles will remove the previously saved responses. Are you sure you want to continue?")
    if (confirmation) {

      this.analysisService.getMappedDeltaValues(this.ksaId, responseId, roleProfileId).pipe(finalize(() => {
        this.fillRoleAlignmentChartData();
      })).subscribe(data => {
        this.participantData.participantAnalysisList = [],
          this.participantData.participantAnalysisList = data.participantAnalysisList;
          this.participantData.roleAlignmentAverage = data.roleAlignmentAverage;
          this.roleNameOnLoad = this.participantData.participantAnalysisList[0].roleMapAnalysisList[0].participantRoleProfile.recommendedKsaRoleProfile.id;
      })
    }

    else {
      event.target.value = this.roleNameOnLoad;
    }
  }

  navigateBack(): void{
    const confirmation = window.confirm("All unsaved changes will be lost. Please click on cancel to remain on the same page and save your progress before proceeding")
    if(confirmation){
      this.isParticipantDetails.emit(false);
    }
  }

  fillRoleAlignmentChartData(): void {
    const roleAlignmentAverageModified = this.participantData.roleAlignmentAverage < 0 ? 100 + this.participantData.roleAlignmentAverage : 100;
    this.chartOptions = {
      series: [Number((roleAlignmentAverageModified).toFixed())],
      chart: {
        type: "radialBar",
        height: 120,
        offsetY: -5
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%"
          },
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#ff0000",
            strokeWidth: "97%",
            margin: 4,
            dropShadow: {
              enabled: true,
              top: 1,
              left: 0,
              opacity: 0.01,
              blur: 0
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: false
            }
          }
        }
      },
      labels: [""]
    };
  }
}
