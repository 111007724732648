<div class="clr-row">
  <div *hasPermission="[functionality.dashboard]; op 'OR'" class="clr-col-4">
    <div class="card">
      <div class="card-header card-title-box">
        {{'analysisAndReports.dashboard.surveyTemp'|translate}}
      </div>
      <div class="card-block">
        <div *ngIf="surveyTempSeries.length>0">
          <apx-chart
            [series]="chartOptionsType?.seriesTempSurvey"
            [chart]="chartOptionsType?.chart"
            [labels]="chartOptionsType?.labelsTempSurvey"
            [responsive]="chartOptionsType?.responsive"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
  <div *hasPermission="[functionality.dashboard]; op 'OR'" class="clr-col-4">
    <div class="card">
      <div class="card-header card-title-box">
        {{'analysisAndReports.dashboard.survey'|translate}}
      </div>
      <div class="card-block chartAlign">
        <div *ngIf="surveyTempSeries.length>0">
          <apx-chart
            [series]="chartOptionsType?.seriesSurvey"
            [chart]="chartOptionsType?.chart"
            [labels]="chartOptionsType?.labelSurvey"
            [responsive]="chartOptionsType?.responsive"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-break-row">
  </div>
  <div *hasPermission="[functionality.dashboard]; op 'OR'" class="clr-col-4">
    <div class="card">
      <div class="card-header card-title-box">
        {{'analysisAndReports.dashboard.surveyType'|translate}}
      </div>
      <div class="card-block chart-bottom-align chart-type">
        <div *ngIf="surveyTypeSeries.length>0">
          <apx-chart
            [series]="chartOptionsType?.series"
            [chart]="chartOptionsType?.chart"
            [labels]="chartOptionsType?.labels"
            [responsive]="chartOptionsType?.responsive"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
  <div *hasPermission="[functionality.dashboard]; op 'OR'" class="clr-col-4">
    <div class="card chart-block">
      <div class="card-header card-title-box">
        {{'analysisAndReports.dashboard.surveyAnonumity'|translate}}
      </div>
      <div class="card-block">
        <div *ngIf="surveyAnonymitySeries.length>0">
          <apx-chart
            [series]="chartOptionsType?.seriesAnonymity"
            [chart]="chartOptionsType?.chart"
            [labels]="chartOptionsType?.labelsAnonymity"
            [responsive]="chartOptionsType?.responsive"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
