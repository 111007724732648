/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/
import {Component, OnInit, ViewChild} from '@angular/core';
import {TrackingService} from "../../../services/tracking.service";
import {TrackingParticipant, TrackModel} from "../../../models/tracking-model";
import {ApexChart, ApexNonAxisChartSeries, ApexPlotOptions, ChartComponent} from "ng-apexcharts";
import {TranslateService} from '@ngx-translate/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from 'rxjs/operators';
import {DatePipe, formatDate} from "@angular/common";
import {ClrLoadingState, ClrSignpostContent} from "@clr/angular";
import * as FileSaver from 'file-saver';


export class ChartOptions {
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  labels?: string[];
  plotOptions?: ApexPlotOptions;
}

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  ksaTrack: TrackModel[];
  ksaPieChartResponse = [];
  @ViewChild("chart") chart: ChartComponent;
  @ViewChild("participantsErrorsSignPost") signpostContent: ClrSignpostContent;
  chartOptions: Partial<ChartOptions>;
  showBouncedEmailsTriggerWarning = false;
  ksaToBounceEmailsCheck: TrackModel;
  showBouncedErrorMsg: boolean;

  private readonly DATE_FORMAT_LOCALE: string = 'en_GB';
  private readonly DATE_FORMAT_PATTERN: string = 'dd-MM-yyyy';
  private readonly NOT_AVAILABLE: string = "N/A";

  constructor(private readonly trackingService: TrackingService, private readonly translate: TranslateService, private readonly datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.getTrackingList();
  }

  getTrackingList(): void {
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.trackingService.getTracking()
        .pipe(finalize(() => hideSpinner()))
        .subscribe(ksaTrack => {
          this.ksaTrack = ksaTrack;
          this.ksaPieChartResponse.push(this.ksaTrack);
          this.fillRoleAlignmentChartData();
        })
    });
  }

  exportData(chosenKsaTrack: TrackModel): void {
    const template = this.buildTemplate(chosenKsaTrack);
    const blob = new Blob([template], {
      type: 'text/plain;charset=utf-8'
    });
    FileSaver.saveAs(blob, `${chosenKsaTrack.ksaName}-tracking-report.txt`);
  }

  private buildTemplate(track: TrackModel): string {
    return `KSA Name: ${track.ksaName}
    \nCustomer: ${track.customer}
    \nEmail Date: ${this.datePipe.transform(track.inviteEmailDate)}
    \nCreated By: ${track.createdBy}
    \nGEO: ${track?.geo?.geoName}
    \nLanguage: ${track?.language}
    \nStatus: ${track.status}
    \nAverage Time (HH:MM): ${track.avgTime}
    \nMax Time (HH:MM): ${track.maxTime}
    \nMin Time (HH:MM): ${track.minTime}
    \nKsa Expiration: ${this.datePipe.transform(track.validTill)}
    \nCompleted (${track.completedBy.length}/${track.total}):
    ${this.collectData(track.completedBy)}
    \nIn Progress (${track.inProgressBy.length}/${track.total}):
    ${this.collectData(track.inProgressBy)}
    \nNot Started (${track.notStartedBy.length}/${track.total}):
    ${this.collectData(track.notStartedBy)}
    \nInvite Email errors (${track.notSentInviteEmail.length}/${track.total}):
    ${this.collectData(track.notSentInviteEmail)}
    \nBounced Emails (${track.bouncedInviteEmail.length}/${track.total}):
    ${this.collectData(track.bouncedInviteEmail)}`;
  }

  private collectData(collection: TrackingParticipant[]): string {
    let data: string = "";
    collection.forEach(participant => {
      data += `\n- ${participant.firstName ?? ''} ${participant.lastName ?? ''}, ${participant.email}, ${participant.surveyUrl}\n`
    })
    return data;
  }

  fillRoleAlignmentChartData(): void {
    this.ksaPieChartResponse[0].forEach((data, index) => {
        this.chartOptions = {
          series: [(data.completionProgress == null ? 0 : Math.floor(data.completionProgress))],
          chart: {
            height: 90,
            offsetY: 0,
            offsetX: 0,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "20%"
              }
            }
          },
          labels: [""]
        };
        this.ksaTrack[index].chartOptions = this.chartOptions;
      }
    );
  }

  triggerBouncedMailsCheck(): void {
    this.showBouncedEmailsTriggerWarning = false;

    if (!this.ksaToBounceEmailsCheck) {
      return;
    }

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaToBounceEmailsCheck.bouncedEmailsCheckTriggered = true;
    this.trackingService.getBouncingMailCheck(this.ksaToBounceEmailsCheck.ksaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        },
        error => {
          this.ksaToBounceEmailsCheck.bouncedEmailsCheckTriggered = false;
          this.showBouncedErrorMsg = true;

          setTimeout(() => {
            this.showBouncedErrorMsg = false;
          }, 10000);
        });
  }

  onTriggerCancel(): void {
    this.showBouncedEmailsTriggerWarning = false;
    this.ksaToBounceEmailsCheck = null;
  }

  onTriggerBouncedEmailsCheck(trackedKsa: TrackModel): void {
    this.showBouncedEmailsTriggerWarning = true;
    this.ksaToBounceEmailsCheck = trackedKsa;
    this.signpostContent.close();
  }

  getTrackedKsaLastBouncedCheck(trackedKsa: TrackModel): string {
    if (trackedKsa.bouncedEmailsLastCheckDate) {
      return formatDate(trackedKsa.bouncedEmailsLastCheckDate, this.DATE_FORMAT_PATTERN, this.DATE_FORMAT_LOCALE);
    }
    return this.NOT_AVAILABLE;
  }

  bouncedBtnState(trackedKsa: TrackModel): ClrLoadingState {
    return trackedKsa.bouncedEmailsCheckTriggered ? ClrLoadingState.LOADING : ClrLoadingState.DEFAULT
  }
}
