<clr-alert clrAlertType="danger" *ngIf="showBouncedErrorMsg" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
  <clr-alert-item>{{'analysisAndReports.tracking.bouncedErrorMsg' | translate}}</clr-alert-item>
</clr-alert>
<clr-datagrid [clrDgRowSelection]="false" class="parent-datagrid" xmlns="http://www.w3.org/1999/html">
  <clr-dg-column class="action-cell"></clr-dg-column>
  <clr-dg-column [clrDgField]="'ksaName'">
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.ksaId'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'customer'">
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.customer'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column>
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.emailDate'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'createdBy'">
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.createdBy'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'geo.geoName'">
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.geo'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'language'">
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.language'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'status'">
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.status'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column>
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.completed'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column>
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.inProgress'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column>
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.notStarted'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column>
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.inviteEmailErrors'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'avgTime'">
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.averageTime'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'minTime'">
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.minTime'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'maxTime'">
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.maxTime'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-column>
    <ng-container *clrDgHideableColumn="{hidden: false}">
      {{'analysisAndReports.tracking.ksaExpiration'|translate}}
    </ng-container>
  </clr-dg-column>
  <clr-dg-row *clrDgItems="let ksaTracked of ksaTrack; let i = index">
    <clr-dg-cell class="action-cell">
      <clr-dg-action-overflow>
        <button class="action-item"
                (click)="exportData(ksaTracked)">{{'analysisAndReports.tracking.download'|translate}}</button>
      </clr-dg-action-overflow>
    </clr-dg-cell>
    <clr-dg-cell>{{ksaTracked.ksaName}}</clr-dg-cell>
    <clr-dg-cell> {{ksaTracked.customer}}</clr-dg-cell>
    <clr-dg-cell>
      <div>{{'analysisAndReports.tracking.invited'|translate}}:{{ksaTracked.inviteEmailDate  |date}}</div>
    </clr-dg-cell>
    <clr-dg-cell>{{ksaTracked.createdBy}}</clr-dg-cell>
    <clr-dg-cell>{{ksaTracked.geo.geoName}}</clr-dg-cell>
    <clr-dg-cell>{{ksaTracked.language}}</clr-dg-cell>
    <clr-dg-cell>{{ksaTracked.status}}</clr-dg-cell>
    <clr-dg-cell>
      <clr-signpost class="participants-signpost">
        <button id="open-completed-by" class="btn btn-sm btn-link" clrSignpostTrigger>
          {{ksaTracked.completedBy.length}}/{{ksaTracked.total}}
        </button>
        <clr-signpost-content *clrIfOpen [clrPosition]="'bottom-left'">
          <ng-container [ngTemplateOutlet]="participants"
                        [ngTemplateOutletContext]="{participants:ksaTracked.completedBy,
                        noParticipantsMsgKey:'analysisAndReports.tracking.noParticipantsCompleted'}">
          </ng-container>
        </clr-signpost-content>
      </clr-signpost>
      <apx-chart [series]=ksaTracked.chartOptions.series
                 [chart]="chartOptions.chart"
                 [plotOptions]="chartOptions.plotOptions"
                 [labels]="chartOptions.labels">
      </apx-chart>
    </clr-dg-cell>
    <clr-dg-cell>
      <clr-signpost>
        <button id="open-in-progress-by" class="btn btn-sm btn-link" clrSignpostTrigger>
          {{ksaTracked.inProgressBy.length}}/{{ksaTracked.total}}
        </button>
        <clr-signpost-content *clrIfOpen [clrPosition]="'bottom-left'">
          <ng-container [ngTemplateOutlet]="participants"
                        [ngTemplateOutletContext]="{participants:ksaTracked.inProgressBy,
                        titleKey: 'analysisAndReports.tracking.participants',
                        noParticipantsMsgKey:'analysisAndReports.tracking.noParticipantsInProgress'}">
          </ng-container>
        </clr-signpost-content>
      </clr-signpost>
    </clr-dg-cell>
    <clr-dg-cell>
      <clr-signpost>
        <button id="opne-not-started-by" class="btn btn-sm btn-link" clrSignpostTrigger>
          {{ksaTracked.notStartedBy.length}}/{{ksaTracked.total}}
        </button>
        <clr-signpost-content *clrIfOpen [clrPosition]="'bottom-left'">
          <ng-container [ngTemplateOutlet]="participants"
                        [ngTemplateOutletContext]="{participants:ksaTracked.notStartedBy,
                        titleKey: 'analysisAndReports.tracking.participants',
                        noParticipantsMsgKey:'analysisAndReports.tracking.noParticipantsNotStarted'}">
          </ng-container>
        </clr-signpost-content>
      </clr-signpost>
    </clr-dg-cell>
    <clr-dg-cell>
      <clr-signpost>
        <button id="open-not-sent-invite-email" class="btn btn-sm btn-link" clrSignpostTrigger>
          {{ksaTracked.notSentInviteEmail.length + ksaTracked.bouncedInviteEmail.length}}/{{ksaTracked.total}}
        </button>
        <clr-signpost-content #participantsErrorsSignPost *clrIfOpen [clrPosition]="'bottom-left'">
          <ng-container [ngTemplateOutlet]="participantsErrors"
                        [ngTemplateOutletContext]="{participants: ksaTracked.notSentInviteEmail,
                    titleKey: 'analysisAndReports.tracking.inviteEmailErrorsSignpostTitle',
                    noParticipantsMsgKey:'analysisAndReports.tracking.noInviteEmailErrors',
                    bouncedTitleKey:'analysisAndReports.tracking.inviteEmailBouncedTitle',
                    ksaInfo: ksaTracked,
                    participantsBounced: ksaTracked.bouncedInviteEmail,
                    noBouncedParticipantsMsgKey: 'analysisAndReports.tracking.noBouncedEmailErrors' }">
          </ng-container>
        </clr-signpost-content>
      </clr-signpost>
    </clr-dg-cell>
    <clr-dg-cell>{{ksaTracked.avgTime}}</clr-dg-cell>
    <clr-dg-cell>{{ksaTracked.minTime}}</clr-dg-cell>
    <clr-dg-cell>{{ksaTracked.maxTime}}</clr-dg-cell>
    <clr-dg-cell>{{ksaTracked.validTill | date}}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'analysisAndReports.tracking.ksaPerPage'|translate}}
      </clr-dg-page-size>
      {{pagination.firstItem + 1}}
      - {{pagination.lastItem + 1}} {{'analysisAndReports.tracking.of'|translate}} {{pagination.totalItems}}
      {{'analysisAndReports.tracking.ksa'|translate}}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<clr-modal id="bouncedEmailsModal" [(clrModalOpen)]="showBouncedEmailsTriggerWarning">
  <h3 class="modal-title">{{'analysisAndReports.tracking.bouncedWarningTitle' | translate}}</h3>
  <div class="modal-body">{{'analysisAndReports.tracking.bouncedWaringMsg' | translate}}</div>
  <div class="modal-footer">
    <button (click)="onTriggerCancel()" id="onDeleteParticipantCancelBtn"
            class="btn btn-link btn-sm">{{'admin.cancelBtn' | translate}}
    </button>
    <button (click)="triggerBouncedMailsCheck()" id="onDeleteParticipantProceedBtn"
            class="btn btn-primary btn-sm">{{'admin.proceedBtn' | translate}}
    </button>
  </div>
</clr-modal>

<ng-template #participants let-participants='participants' let-noParticipantsMsgKey='noParticipantsMsgKey'
             let-titleKey='titleKey'>
  <span *ngIf="participants?.length <= 0; else participantsBlock">{{noParticipantsMsgKey | translate}}</span>
  <ng-template #participantsBlock>
    <h4 class="participants-title">
      <span>{{titleKey | translate}}</span>
    </h4>
    <ul class="list compact">
      <li *ngFor="let participant of participants">{{participant.firstName}} {{participant.lastName}}
        ({{participant.email}})
      </li>
    </ul>
  </ng-template>
</ng-template>

<ng-template #participantsErrors let-participants='participants' let-noParticipantsMsgKey='noParticipantsMsgKey'
             let-ksaInfo="ksaInfo" let-titleKey='titleKey' let-bouncedTitleKey='bouncedTitleKey'
             let-participantsBounced='participantsBounced'
             let-noBouncedParticipantsMsgKey='noBouncedParticipantsMsgKey'>
  <h4 class="participants-title">
    <span>{{titleKey | translate}}</span>
  </h4>
  <ul class="list compact">
    <span *ngIf="participants?.length <= 0">{{noParticipantsMsgKey | translate}}</span>
    <li *ngFor="let participant of participants">{{participant.firstName}} {{participant.lastName}}
      ({{participant.email}})
    </li>
  </ul>
  <ul class="list compact">
    <h6 class="participants-title">
      <span>{{bouncedTitleKey | translate}}</span>
    </h6>
    <span id="bouncedEmailsLastCheck">
        {{'analysisAndReports.tracking.lastBouncedCheck' | translate}}{{getTrackedKsaLastBouncedCheck(ksaInfo)}}</span>
    <button *ngIf="ksaInfo.status === 'Active'" id="bouncedEmailsBtn" class="btn btn-sm btn-primary"
            [disabled]="ksaInfo.bouncedEmailsCheckTriggered"
            (click)="onTriggerBouncedEmailsCheck(ksaInfo)"
            [clrLoading]="bouncedBtnState(ksaInfo)">{{'analysisAndReports.tracking.triggerCheck' | translate}}</button>
    <div *ngIf="ksaInfo.bouncedEmailsCheckTriggered && ksaInfo.status === 'Active'" role="tooltip" aria-haspopup="true"
         class="tooltip tooltip-md tooltip-top-left">
      <clr-icon shape="info-circle" id="bouncedBtnLoadingInfo"></clr-icon>
      <span class="tooltip-content">{{'analysisAndReports.tracking.bouncedBtnLoadingInfo'| translate}}</span>
    </div>
    <br/>
    <span *ngIf="participantsBounced?.length <= 0">{{noBouncedParticipantsMsgKey | translate}}</span>
    <li *ngFor="let participant of participantsBounced">{{participant.firstName}} {{participant.lastName}}
      ({{participant.email}})
    </li>
  </ul>
</ng-template>
