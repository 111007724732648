<div class="button-container" *ngIf="!isAddTemplate && !isEditTemplate">
  <button id="{{btnId.btnMngEmailTemplateAdd}}" (click)="isAddTemplate = true" id="addRowBtn"
          class="btn btn-sm btn-primary" *hasAccess="functionality.manageEmailTemplate">
    {{'emailTemplate.addTemplate' | translate}}
  </button>
  <button id="{{btnId.btnMngEmailTemplateMultipleDelete}}" (click)=" deleteSelectedTemplate(selected)"
          class="btn btn-sm btn-primary" *hasAccess="functionality.manageEmailTemplate">
    {{'emailTemplate.deleteTemplates' | translate}}
  </button>
</div>

<clr-datagrid class="parent-datagrid" #dg *ngIf="!isAddTemplate && !isEditTemplate" [(clrDgSelected)]="selected"
  (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-column [clrDgField]="'emailTemplateName'">{{'emailTemplate.templateName' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'description'">{{'emailTemplate.templateDescription' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'createdDate'">{{'emailTemplate.creationDate' | translate}}
    <clr-dg-filter>
      <app-date-filter (filterDate)='getDateFilter($event)'></app-date-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column class="action-cell"></clr-dg-column>
  <clr-dg-row *ngFor="let emailTemplate of emailTemplates.content" [clrDgItem]="emailTemplate">
    <ng-container *ngIf="emailTemplate.id === emailTemplate.id && emailTemplate.id!==0">
      <clr-dg-cell>{{emailTemplate.emailTemplateName}}
      </clr-dg-cell>
      <clr-dg-cell>{{emailTemplate.description}}
      </clr-dg-cell>
      <clr-dg-cell>{{emailTemplate.createdDate | date}}
      </clr-dg-cell>
      <clr-dg-cell class="action-cell">
        <clr-dg-action-overflow *hasAccess="functionality.manageEmailTemplate">
          <button id="{{btnId.btnMngEmailTemplateEdit}}" type="button" class="action-item" (click)="onEditTemplate(emailTemplate.id)">{{'admin.editBtn' |
            translate}}</button>
          <button id="{{btnId.btnMngEmailTemplateDelete}}" type="button" class="action-item" (click)="onShowDeleteEmailPopUp(emailTemplate.id)">{{'admin.deleteBtn' |
            translate}}</button>
        </clr-dg-action-overflow>
      </clr-dg-cell>
    </ng-container>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total" class="email-pagination">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Email Templates per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Templates
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<div *ngIf="isAddTemplate">
  <app-edit-email-template [placeHolderNameList]="placeHolderNameList" (isAddTemplate)="isAddTemplateData($event)"
  (isEditTemplate)="isEditTemplateData($event)">
  </app-edit-email-template>
</div>

<div *ngIf="isEditTemplate">
  <app-edit-email-template [templateId]="templateId" [placeHolderNameList]="placeHolderNameList"
  (isAddTemplate)="isAddTemplateData($event)"
  (isEditTemplate)="isEditTemplateData($event)">
  </app-edit-email-template>
</div>

<clr-modal [(clrModalOpen)]="showDeleteEmailPopUp" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'admin.emailTemplate.deleteEmailTemplate' | translate}}</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>
    {{'admin.emailTemplate.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteEmailPopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteEmailTemplate()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showMultiDeleteEmailPopUp" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'admin.emailTemplate.deleteEmailTemplate' | translate}}</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>
    {{'admin.emailTemplate.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showMultiDeleteEmailPopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="deleteTemplates()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>
