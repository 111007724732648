/* *********************************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * *********************************************************************/

import {NgxLoggerLevel, NGXLoggerMonitor, NGXLogInterface} from "ngx-logger";
import {HttpClient} from "@angular/common/http";
import {API_KSA_CORE_V1} from "./constants/api-prefix.constants";

type LogMessage = {
  level: string,
  message: string
}

export class LoggerMonitor implements NGXLoggerMonitor {

  private readonly LOGS_URL: string = `${API_KSA_CORE_V1}/logs`;

  constructor(private readonly httpClient: HttpClient) {
  }

  onLog(log: NGXLogInterface): void {
    const logMessage: LogMessage = {
      level: NgxLoggerLevel[log.level],
      message: log.message
    };
    this.httpClient.post(this.LOGS_URL, logMessage).subscribe();
  }

}
