/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component} from '@angular/core';
import {LoggerMonitor} from "./common/logger-monitor.service";
import {NGXLogger} from "ngx-logger";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'KSA-ENGG';

  constructor(private readonly logger: NGXLogger,
              private readonly httpClient: HttpClient) {
  }

  ngOnInit(): void {
    this.logger.registerMonitor(new LoggerMonitor(this.httpClient));
  }
}
