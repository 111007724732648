/********************************************************************** *
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
 ********************************************************************** */

import {AfterViewChecked, ChangeDetectorRef, Component, Input, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {RoleProfileGroup} from '../../../models/role-profile-groups.model';
import {RoleProfilePage} from '../../../models/role-profile-page.model';
import {RoleProfileGroupService} from '../../../services/role-profile-groups.service';
import {RoleProfileService} from '../../../services/role-profile.service';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {TranslateService} from '@ngx-translate/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from 'rxjs/operators';
import {SurveyTemplateConfigVersion} from "../../../models/config-version.model";
import {
  SkillRoleProfileManagementService
} from "../tab-mng-skills-skill-order-role-profiles-and-role-profile-groups/skill-role-profile-management.service";

@Component({
  selector: 'app-mng-role-profile-groups',
  templateUrl: './mng-role-profile-groups.component.html',
  styleUrls: ['./mng-role-profile-groups.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MngRoleProfileGroupsComponent implements AfterViewChecked {

  @Input() surveyTemplateConfigVersion: SurveyTemplateConfigVersion;

  state: ClrDatagridStateInterface;
  page: number;
  loading: boolean = false;
  total: number;
  allRoleProfiles: any[] = []
  addRoleProfileGroupFormOpen: boolean = false;
  editRoleProfileGroupFormOpen: boolean = false;
  showDeletePopUp: boolean = false;
  roleProfileGroups: RoleProfileGroup = {
    content: []
  };
  roleProfiles: RoleProfilePage = {
    content: []
  };
  errorMsg: string = '';
  deleteId: number;

  newRoleProfileGroup: any = {
    name: '',
    description: '',
    roleProfileGroupRoleProfileMaps: []
  }

  newRoleProfileGroupToPush: any = {
    name: '',
    description: '',
    roleProfileGroupRoleProfileMaps: []
  }

  functionality: typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;

  constructor(public readonly roleProfileGroupService: RoleProfileGroupService,
              public readonly roleProfileService: RoleProfileService,
              private readonly translate: TranslateService,
              private readonly cdr: ChangeDetectorRef,
              public skillRoleProfileManagementService: SkillRoleProfileManagementService) {
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getRoleProfiles(): void {
    this.roleProfileService.getRoleProfiles(this.surveyTemplateConfigVersion.id).subscribe(data => {
      this.roleProfiles = data;
    })
  }

  getSelectedRoleProfiles(id: number): void {
    this.roleProfileGroupService.getSelectedRoleProfiles(id, this.surveyTemplateConfigVersion.id)
      .subscribe(data => {
        this.allRoleProfiles = data;
        this.newRoleProfileGroup.roleProfileGroupRoleProfileMaps = this.allRoleProfiles.filter((e) => {
          return e.isSelected === true;
        });
      });
  }

  onAddRoleProfileGroup(form: NgForm): void {
    if (form.invalid || this.newRoleProfileGroup.roleProfileGroupRoleProfileMaps.length === 0) {
      return;
    }
    this.newRoleProfileGroupToPush.name = (form.value.name).trim();
    this.newRoleProfileGroupToPush.description = (form.value.description);
    this.newRoleProfileGroupToPush.surveyTemplateConfigVersion = this.surveyTemplateConfigVersion;
    this.newRoleProfileGroupToPush.roleProfileGroupRoleProfileMaps = this.newRoleProfileGroup.roleProfileGroupRoleProfileMaps.map((id: any) =>
      ({roleProfile: id}));

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.roleProfileGroupService.createRoleProfileGroup(this.newRoleProfileGroupToPush)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.addRoleProfileGroupFormOpen = false;
          form.onReset();
          this.refresh(this.state);
          this.newRoleProfileGroup = {
            name: '',
            description: '',
            roleProfileGroupRoleProfileMaps: []
          };
          this.errorMsg = '';
        },
        error => this.errorMsg = error.error.message);
  }

  onEditRoleProfileGroup(form: NgForm): void {
    if (form.invalid || this.newRoleProfileGroup.roleProfileGroupRoleProfileMaps.length === 0) {
      return;
    }
    this.newRoleProfileGroupToPush.id = this.newRoleProfileGroup.id;
    this.newRoleProfileGroupToPush.name = (this.newRoleProfileGroup.name).trim();
    this.newRoleProfileGroupToPush.description = (this.newRoleProfileGroup.description);
    this.newRoleProfileGroupToPush.surveyTemplateConfigVersion = this.surveyTemplateConfigVersion;
    this.newRoleProfileGroupToPush.roleProfileGroupRoleProfileMaps = this.newRoleProfileGroup.roleProfileGroupRoleProfileMaps.map((skill: any) =>
      ({
        roleProfile: {id: skill.roleProfileId},
        id: skill.id
      }))

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.roleProfileGroupService.updateRoleProfileGroup(this.newRoleProfileGroupToPush)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.editRoleProfileGroupFormOpen = false;
          form.onReset();
          this.refresh(this.state);
          this.newRoleProfileGroup = {
            name: '',
            description: '',
            roleProfileGroupRoleProfileMaps: [],
            surveyTemplateConfigVersion: null
          };
          this.errorMsg = '';
        },
        error => this.errorMsg = error.error.message);
  }

  onSetAddRoleProfileGroupFormOpen(): void {
    this.getRoleProfiles();
    this.addRoleProfileGroupFormOpen = true;
  }

  onSetEditRoleProfileGroupFormOpen(): void {
    this.editRoleProfileGroupFormOpen = true;
  }

  onEditRoleProfileGroupDetails(roleProfileGroup: any): void {
    this.getSelectedRoleProfiles(roleProfileGroup.id);
    this.newRoleProfileGroup.id = roleProfileGroup.id;
    this.newRoleProfileGroup.name = roleProfileGroup.name;
    this.newRoleProfileGroup.description = roleProfileGroup.description;
    this.newRoleProfileGroup.surveyTemplateConfigVersion = this.surveyTemplateConfigVersion;
    this.onSetEditRoleProfileGroupFormOpen();
  }

  onDeleteRoleProfileGroup(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.roleProfileGroupService.deleteRoleProfileGroup(this.deleteId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.refresh(this.state);
          this.deleteId = 0;
          this.showDeletePopUp = false;
          this.errorMsg = ''
        },
        error => this.errorMsg = error.error.message);
  }

  onCancelAdding(form: NgForm): void {
    form.reset();
    this.newRoleProfileGroup = {
      name: '',
      description: '',
      roleProfileGroupRoleProfileMaps: [],
      surveyTemplateConfigVersion: null
    }
    this.addRoleProfileGroupFormOpen = false;
    this.errorMsg = '';
  }

  onCancelEditing(form: NgForm): void {
    form.reset();
    this.newRoleProfileGroup = {
      name: '',
      description: '',
      roleProfileGroupRoleProfileMaps: [],
      surveyTemplateConfigVersion: null
    }
    this.editRoleProfileGroupFormOpen = false;
    this.errorMsg = ''
  }

  onShowDeletePopUp(id: any): void {
    this.deleteId = id;
    this.showDeletePopUp = true
  }

  resetError(): void {
    this.errorMsg = ''
  }

  refresh(state: ClrDatagridStateInterface) {
    this.state = state;
    this.loading = true;
    const filters: { [prop: string]: any } = {};
    if (state.filters) {
      for (const filter of state.filters) {
        const {property, value} = <{ property: string; value: string }>filter;
        filters[property] = value;
      }
    }

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));

    this.roleProfileGroupService.getRoleProfilesGroups(this.surveyTemplateConfigVersion.id, filters?.name === undefined ? '' : filters.name,
      filters?.['roleProfileGroupRoleProfileMaps.roleProfile.name'] === undefined ? '' : filters?.['roleProfileGroupRoleProfileMaps.roleProfile.name'],
      filters?.description === undefined ? '' : filters.description,
      state.page.current - 1, state.page.size, state?.sort?.by === undefined ? this.functionality.createdDate : state?.sort?.by, state?.sort?.reverse === undefined ? 'ASC' : state?.sort?.reverse)
      .pipe(finalize(() => hideSpinner()))
      .subscribe((results: any) => {
        this.roleProfileGroups = results;
        this.total = results.totalElements;
        this.loading = false;
      })
  }
}
