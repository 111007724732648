/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  AnswerLabel,
  AnswerProperty,
  AnswerPropertyForm,
  MapQuestionTag,
  MasterSurveyQuestion,
  SurveyQuestionTag
} from '../../../../../../models/master-survey-questions.model';
import {MngSectionComponent} from '../mng-section.component';
import {SurveyControls} from "../../../../../../enums/survey-controls.enum";
import {QuestionType} from '../../../../../../enums/questionType.enum';
import {ShareSurveyDataService} from '../../../../../../services/share-survey-data.service';
import {MasterSurveyService} from '../../../../../../services/master-survey.service';
import {Subscription} from "rxjs";
import {UserService} from '../../../../../../services/user.service';
import {Functionality} from '../../../../../../enums/functionality.enum';


const MINIMUM_LABEL_VALUE: number = 2;
const MAXIMUM_LABEL_VALUE: number = 10;

@Component({
  selector: 'app-matrix-table',
  templateUrl: './matrix-table.component.html',
  styleUrls: ['./matrix-table.component.scss']
})
export class MatrixTableComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  index: number;
  move = new EventEmitter();
  sectionReference!: MngSectionComponent;
  public matrixIndex: number = 0;
  sectionQuestions!: MasterSurveyQuestion[];
  questionData: any;
  questionSequence!: number;
  tempSurveyQuestionId: number;
  showDisplayAnswerLabel: boolean = false;
  isDisable: boolean = false;
  mapSkill: {
    id: number
  } = {
    id: 0
  }
  isAddPageBreak: boolean = false;
  tempRow = [];
  tempColumn = [];
  skillToShow: string = '';
  displayLogicToShow= this.defaultDisplayLogicMessage();
  skipLogicToShow = this.defaultSkipLogicMessage();
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  matrixTypes = {
    radio: this.surveyControlsEnum.radio,
    multiChoice: this.surveyControlsEnum.multiChoice
  };
  showDisplayLogicModal: boolean = false;
  showSkipLogicModal: boolean = false;
  showMandatoryTypePopUp: boolean = false;
  showMapSkillPopUp: boolean = false;
  isAddNoteTrue: boolean = false;
  showImageUploadPopUp: boolean = false;
  showDeleteQuestionPopUp: boolean = false;
  showMatrixAnswerLabel: boolean;
  convertedImage: string;
  questionTypeEnum: typeof QuestionType = QuestionType;
  showMapTagPopUp: boolean = false;
  tagData: SurveyQuestionTag = {
    tagName: ''
  };
  tagList: MapQuestionTag[] = [];
  tagItemSelected: SurveyQuestionTag[] = [];
  mapSurveyQuestionWithTags: SurveyQuestionTag[] = [];
  questionText: String;
  errorAnswerLabelSkipLogic: boolean = false;
  errorOnDisplayLogicItemDelete: boolean;
  showDisplayMessage: boolean = false;
  showSkipMessage: any;
  dependentQuestionDisplayLogic: string = '';
  subscriptions: Subscription[] = [];
  hasWritePermission: boolean;

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.manageSurveyTemplate);
    if (this.questionData) {
      this.onInitializationMatrixQuestionData();
    } else {
      this.setDefaultQuestionData();
      this.onChoiceInputForGridInit();
      this.onChoiceInputForRowInit();
      this.onChoiceInputForColumnInit();
      this.changeDetectOnQuestion();
    }
  }

  changeDetectOnQuestion(): void {
    this.questionData.valueChanges.subscribe((val: MasterSurveyQuestion) => {
      this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1] = this.questionData.getRawValue();
    });
  }

  ngAfterViewInit(): void {
    this.changeDetectOnQuestion();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef,
              private shareSurveyData: ShareSurveyDataService,
              private masterSurveyService: MasterSurveyService,
              private readonly userService: UserService) {
  }

  get answerLabelArray(): FormArray {
    return this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels] as FormArray;
  }

  get answerLabelTemp1Array(): FormArray {
    return this.questionData.controls.tempArr1.controls[this.surveyControlsEnum.answerLabels] as FormArray;
  }

  get answerLabelTemp2Array(): FormArray {
    return this.questionData.controls.tempArr2.controls[this.surveyControlsEnum.answerLabels] as FormArray;
  }

  onInitializationMatrixQuestionData(): void {
    this.skillToShow = this.questionData.skill ? this.questionData.skill.skillName : '';
    this.questionText = this.questionData.question.questionText;
    if (this.questionData.isPageBreakAdded) {
      this.isAddPageBreak = true;
    }
    if (this.questionData.question?.mapSurveyQuestionWithTags) {
      this.questionData.question?.mapSurveyQuestionWithTags.forEach(element => {
        this.mapSurveyQuestionWithTags.push(element.surveyQuestionTag);
      });
    } else {
      this.questionData = this.formBuilder.group({
        question: this.formBuilder.group({
          mapSurveyQuestionWithTags: this.formBuilder.array([this.onCreateQuestionTags()])
        })
      });
    }
    this.assignQuestionData();
  }

    assignQuestionData(): void {
    this.questionData = this.formBuilder.group({
      tempArr1: this.formBuilder.group({
        answerLabels: this.formBuilder.array((this.questionData.question.answerLabels.filter(label => label.isRow == false)).map(labels =>
          this.createAnswerLabel(labels)))
      }),
      tempArr2: this.formBuilder.group({
        answerLabels: this.formBuilder.array((this.questionData.question.answerLabels.filter(label => label.isRow == true)).map(labels =>
          this.createAnswerLabel(labels)))
      }),
      id: this.questionData.id,
      question: this.formBuilder.group({
        id: this.questionData.question.id,
        questionType: {
          id: this.questionData.question.questionType.id,
        },
        image: [this.questionData.question.image],
        questionText: [this.questionData.question.questionText, {
          validators: [Validators.required, Validators.maxLength(1000)],
        }],
        answerProperty: this.formBuilder.group(this.createPropertyOnInit(this.questionData.question.answerProperty)),
        answerLabels: this.formBuilder.array(this.questionData.question.answerLabels.map(label => this.createAnswerLabel(label))),
        mapSurveyQuestionWithTags: this.formBuilder.array(this.questionData.question.mapSurveyQuestionWithTags.map(tag =>
          this.createQuestionTag(tag)))
      }),
      questionNote: [this.questionData.questionNote, {
        validators: [Validators.maxLength(100)],
      }],
      language: {
        id: this.questionData.language.id
      },
      skill: {
        id: this.questionData.skill?.id
      },
      sectionQuestionSequenceNo: this.questionSequence,
      tempSurveyQuestionId: this.questionData.tempSurveyQuestionId,
      isMandatory: [this.questionData.isMandatory],
      isPageBreakAdded: [this.questionData.isPageBreakAdded],
      isImported: [this.questionData.isImported],
      isVersioned: [this.questionData.isVersioned],
      questionDisplayCondition: this.questionData.questionDisplayCondition ? this.questionData.questionDisplayCondition :
        this.createDisplayControl(),
      questionSkipCondition: this.questionData.questionSkipCondition ? this.questionData.questionSkipCondition : this.createSkipControl(),
    });

    if(this.questionData.controls.isImported.value){
      this.questionData.controls.question.controls[this.surveyControlsEnum.questionText].disable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].disable();
      this.questionData.controls.tempArr1.controls[this.surveyControlsEnum.answerLabels].disable();
      this.questionData.controls.tempArr2.controls[this.surveyControlsEnum.answerLabels].disable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).disable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).disable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.isRadio).disable();
      this.questionData.controls[this.surveyControlsEnum.questionNote].disable();
    }
    if(this.questionData.value.questionDisplayCondition.tempSectionId){
      this.onUpdateDisplay(true);
    }else{
      this.onUpdateDisplay(false);
    }

    if(this.questionData.value.questionSkipCondition.skipCondition.id){
      this.onUpdateSkip(true);
    }else{
      this.onUpdateSkip(false);
    }
  }

  createQuestionTag(tag: MapQuestionTag) {
    return ({
      id: tag.id,
      surveyQuestionTag: {
        id: tag.surveyQuestionTag.id,
        tagName: tag.surveyQuestionTag.tagName
      }
    });
  }

  createSkipControl(): FormGroup {
    return this.formBuilder.group({
      skipCondition: this.formBuilder.group({
        id: null
      }),
      answerLabelSequenceNo: null,
      colAnswerLabelSequenceNo: null,
      skipType: null,
    });
  }

  createDisplayControl(): FormGroup {
    return this.formBuilder.group({
      answerLabelSequenceNo: null,
      colAnswerLabelSequenceNo: null,
      displayValue: null,
      questionSequenceNo: null,
      sectionSequenceNo: null,
      skipCondition: {
        id: null
      },
      tempSectionId:null,
      tempSurveyQuestionId:null
    });
  }

  createProperty(): AnswerPropertyForm {
    return ({
      answerRow: [2, [Validators.maxLength(5), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(MINIMUM_LABEL_VALUE),
        Validators.required]],
      answerColumn: [2, [Validators.maxLength(5), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(MINIMUM_LABEL_VALUE),
        Validators.required]],
      isRadio: [true, Validators.required],
    });
  }

  createAnswerLabel(label: AnswerLabel): FormGroup {
    return this.formBuilder.group({
      id: label.id,
      labelText: [label.labelText, {
        validators: [Validators.required, Validators.maxLength(100)],
      }],
      answerLabelSequenceNo: [label.answerLabelSequenceNo],
      isRow: [label.isRow],
    });
  }

  onCreateAnswer(): FormGroup {
    return this.formBuilder.group({
      labelText: ['', {
        validators: [],
      }],
      answerLabelSequenceNo: [],
      isRow: [false],
    });
  }

  setDefaultQuestionData(): void {
    this.questionData = this.formBuilder.group({
      tempArr1: this.formBuilder.group({
        answerLabels: this.formBuilder.array([this.onCreateAnswer1(1)]),
      }),
      tempArr2: this.formBuilder.group({
        answerLabels: this.formBuilder.array([this.onCreateAnswer2(1)]),
      }),
      question: this.formBuilder.group({
        questionType: {
          id: this.questionTypeEnum.MatrixTable,
        },
        questionText: ['', {
          validators: [Validators.required, Validators.maxLength(1000)],
        }],
        image: [],
        answerLabels: this.formBuilder.array([this.onCreateAnswer()]),
        answerProperty: this.formBuilder.group(this.createProperty()),
        mapSurveyQuestionWithTags: this.formBuilder.array([])
      }),
      questionNote: ['', {
        validators: [Validators.maxLength(100)],
      }],
      language: {
        id: 1
      },
      skill: this.mapSkill,
      sectionQuestionSequenceNo: this.questionSequence,
      tempSurveyQuestionId: this.shareSurveyData.findMaxQuestionId()+1,
      isMandatory: [true],
      isPageBreakAdded: [false],
      isImported: [false],
      isVersioned: [false],
      questionDisplayCondition: this.createDisplayControl(),
      questionSkipCondition: this.createSkipControl(),
    });
  }

  onCreateAnswer1(seqNo: number): FormGroup {
    return this.formBuilder.group({
      labelText: ['', {
        validators: [],
      }],
      answerLabelSequenceNo: seqNo,
      isRow: [false],
    });
  }

  onCreateAnswer2(seqNo: number): FormGroup {
    return this.formBuilder.group({
      labelText: ['', {
        validators: [],
      }],
      answerLabelSequenceNo: seqNo,
      isRow: [true],
    });
  }

  createPropertyOnInit(info: AnswerProperty) {
    return ({
      id: info.id,
      answerRow: [ info.answerRow, {
        validators: [Validators.maxLength(5), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(MINIMUM_LABEL_VALUE), Validators.required],
      }],
      answerColumn: [ info.answerColumn, {
        validators: [Validators.maxLength(5), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(MINIMUM_LABEL_VALUE), Validators.required],
      }],
      isRadio: [info.isRadio, Validators.required],
    });
  }

  onEditButton(): void {
    this.dependentQuestionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.shareSurveyData.validateQuestionDisplayLogic(this.questionData);
    if(validateQuestionDisplayLogicResponse.isValidQuestion){
      this.questionData.controls.question.controls[this.surveyControlsEnum.questionText].enable();
      this.questionData.controls[this.surveyControlsEnum.questionNote].enable();
      this.isDisable = false;
      this.questionData.get(this.surveyControlsEnum.isImported).setValue(false);
      delete this.questionData.controls.question.controls[this.surveyControlsEnum.id]?.value;
      this.questionData.controls.question.controls[this.surveyControlsEnum.image].get(this.surveyControlsEnum.id)?.setValue();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].enable();
      this.questionData.controls.tempArr1.controls[this.surveyControlsEnum.answerLabels].enable();
      this.questionData.controls.tempArr2.controls[this.surveyControlsEnum.answerLabels].enable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].controls.forEach(label => {
        label.get(this.surveyControlsEnum.id).setValue();
      });
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].enable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.id).setValue();
    }else{
      this.errorOnDisplayLogicItemDelete = true;
      if(validateQuestionDisplayLogicResponse.dependentQuestion){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentQuestion.question.questionText;
      }
      if(validateQuestionDisplayLogicResponse.dependentSection){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      }
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
      }, 5000);
    }
  }


  onChoiceInputForGridInit(): void {
    let currentChoiceValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).value;
    this.answerLabelArray.clear();
    for (let sequenceAnswerLabelRow: number = 1; sequenceAnswerLabelRow <= currentChoiceValue; sequenceAnswerLabelRow++) {
      (this.answerLabelArray).push(this.onCreateAnswer2(sequenceAnswerLabelRow));
    }
    currentChoiceValue = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).value;
    for (let sequenceAnswerLabelColumn: number = 1; sequenceAnswerLabelColumn <= currentChoiceValue; sequenceAnswerLabelColumn++) {
      (this.answerLabelArray).push(this.onCreateAnswer1(sequenceAnswerLabelColumn));
    }
  }

  onChoiceInputForRowInit(): void {
    const currentChoiceValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).value;
    this.answerLabelTemp2Array.clear();
    for (let sequenceAnswerLabelRow: number = 1; sequenceAnswerLabelRow <= currentChoiceValue; sequenceAnswerLabelRow++) {
      (this.answerLabelTemp2Array).push(this.onCreateAnswer2(sequenceAnswerLabelRow));
    }
    this.tempRow = [...this.answerLabelTemp2Array.value];
  }

  onChoiceInputForRow(): void {
    const currentChoiceValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).value;
    (this.answerLabelTemp2Array).push(this.onCreateAnswer2(currentChoiceValue));
    (this.answerLabelArray).push(this.onCreateAnswer2(currentChoiceValue));
    this.tempRow = [...this.answerLabelTemp2Array.value];
  }

  onChoiceInputForColumnInit(): void {
    const currentChoiceValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).value;
    this.answerLabelTemp1Array.clear();
    for (let sequenceAnswerLabelColumn: number = 1; sequenceAnswerLabelColumn <= currentChoiceValue; sequenceAnswerLabelColumn++) {
      (this.answerLabelTemp1Array).push(this.onCreateAnswer1(sequenceAnswerLabelColumn));
    }
    this.tempColumn = [...this.answerLabelTemp1Array.value]
  }

  onChoiceInputForColumn(): void {
    const currentChoiceValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).value;
    (this.answerLabelTemp1Array).push(this.onCreateAnswer1(currentChoiceValue));
    (this.answerLabelArray).push(this.onCreateAnswer1(currentChoiceValue));
    this.tempColumn = [...this.answerLabelTemp1Array.value];
  }

  isUpArrayHidden(data: number): boolean {
    if (data == 1) {
      return true;
    }
    return false;
  }

  isDownArrayHidden(data: number): boolean {
    if (this.sectionReference?.sectionData.sectionQuestions.length == data) {
      return true;
    }
    return false;
  }

  onAddPageBreak(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.isAddPageBreak = true;
    this.questionData.controls.isPageBreakAdded.setValue(true);
  }

  onRemovePageBreak(): void {
    this.isAddPageBreak = false;
    this.questionData.controls.isPageBreakAdded.setValue(false);
  }

  onInputAnswerLabelTextForColumn(index: number, data: string): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const answerLabel: AnswerLabel = this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].controls.filter(anwserlabel =>
      anwserlabel.controls.isRow.value == false && anwserlabel.controls.answerLabelSequenceNo.value == index + 1);
    answerLabel[0].get(this.surveyControlsEnum.labelText).setValue(data);
  }

  onInputAnswerLabelTextForRow(index: number, data: string): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const answerLabel: AnswerLabel = this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].controls.filter(anwserlabel =>
      anwserlabel.controls.isRow.value == true && anwserlabel.controls.answerLabelSequenceNo.value == index + 1);
    answerLabel[0].get(this.surveyControlsEnum.labelText).setValue(data);
  }

  onAddColumn(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentFromValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).value;
    if (currentFromValue < MAXIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).setValue(currentFromValue + 1);
      this.onChoiceInputForColumn();
    }
  }

  onAddRow(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentFromValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).value;
    if (currentFromValue < MAXIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).setValue(currentFromValue + 1);
      this.onChoiceInputForRow();
    }
  }

  onRemoveRow(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentFromValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).value;
    if (currentFromValue > MINIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).setValue(currentFromValue - 1);
      this.questionData.controls.tempArr2.controls[this.surveyControlsEnum.answerLabels].controls.splice(currentFromValue - 1, 1);
      const arrayLength = this.answerLabelArray.length;
      let index;
      for(let rowIndex=arrayLength-1;rowIndex>=0;rowIndex--){
        if(this.answerLabelArray.controls[rowIndex].value.isRow){
          index = rowIndex;
          break;
        }
      }
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].controls.splice(index, 1);
      this.questionData.controls.tempArr2.value[this.surveyControlsEnum.answerLabels].splice(currentFromValue - 1, 1);
      this.questionData.controls.question.value[this.surveyControlsEnum.answerLabels].splice(index, 1);
    }
  }

  onRowInput(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const initialValue: number = this.questionData.controls.tempArr2.controls[this.surveyControlsEnum.answerLabels].length;
    const currentFromValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).value;
    let noOfRows: number = initialValue - currentFromValue;
    if (currentFromValue <= MAXIMUM_LABEL_VALUE && currentFromValue >= MINIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).setValue(initialValue);
      if (initialValue < currentFromValue) {
        noOfRows = currentFromValue - initialValue;
        for (let countRow: number = 0; countRow < noOfRows; countRow++) {
          this.onAddRow();
        }
      } else {
        for (let countRow: number = 0; countRow < noOfRows; countRow++) {
          this.onRemoveRow();
        }
      }
    }
  }

  onColumnInput(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const initialValue: number = this.questionData.controls.tempArr1.controls[this.surveyControlsEnum.answerLabels].length;
    const currentFromValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).value;
    let noOfRows: number = initialValue - currentFromValue;
    if (currentFromValue <= MAXIMUM_LABEL_VALUE && currentFromValue >= MINIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).setValue(initialValue);
      if (initialValue < currentFromValue) {
        noOfRows = currentFromValue - initialValue;
        for (let countColumn: number = 0; countColumn < noOfRows; countColumn++) {
          this.onAddColumn();
        }
      } else {
        for (let countColumn: number = 0; countColumn < noOfRows; countColumn++) {
          this.onRemoveColumn();
        }
      }
    }
  }

  onRemoveColumn(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentFromValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).value;
    if (currentFromValue > MINIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).setValue(currentFromValue - 1);
      this.questionData.controls.tempArr1.controls[this.surveyControlsEnum.answerLabels].controls.splice(currentFromValue - 1, 1);
      const arrayLength = this.answerLabelArray.length;
      let index;
      for(let columnIndex=arrayLength-1;columnIndex>=0;columnIndex--){
        if(!this.answerLabelArray.controls[columnIndex].value.isRow){
          index = columnIndex;
          break;
        }
      }
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].controls.splice(index, 1);
      this.questionData.controls.tempArr1.value[this.surveyControlsEnum.answerLabels].splice(currentFromValue - 1, 1);
      this.questionData.controls.question.value[this.surveyControlsEnum.answerLabels].splice(index, 1);
    }
  }

  onDeleteImage(): void {
    this.convertedImage = '';
    this.questionData.controls.question.get(this.surveyControlsEnum.image).setValue();
  }

  onRemoveQuestion(): void {
    this.dependentQuestionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.shareSurveyData.validateQuestionDisplayLogic(this.questionData);
    if(validateQuestionDisplayLogicResponse.isValidQuestion){
      this.sectionReference.removeQuestion(this.questionData);
      this.showDeleteQuestionPopUp = false;
      this.masterSurveyService.setSurveyUpdateStatus(false);
    }else{
      this.showDeleteQuestionPopUp = true;
      this.errorOnDisplayLogicItemDelete = true;
      if(validateQuestionDisplayLogicResponse.dependentQuestion){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentQuestion.question.questionText;
      }
      if(validateQuestionDisplayLogicResponse.dependentSection){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      }
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
      }, 5000);
    }
  }

  isLastQuestion(): boolean {
    if (this.sectionReference?.sectionData.sectionQuestions.length == this.questionData.value.sectionQuestionSequenceNo
      && this.sectionReference.surveySections.length == this.sectionReference?.sectionData.sectionSequenceNo) {
      return true;
    }
    return false;
  }

  isFirstQuestion(): boolean {
    if (this.sectionReference?.sectionData.sectionSequenceNo == 1 && this.questionData.value.sectionQuestionSequenceNo == 1) {
      return true;
    }
    return false;
  }

  onCreateQuestionTags(): MapQuestionTag {
    return ({
      surveyQuestionTag: {
        id: undefined,
        tagName: ''
      }
    });
  }

  showMapTag(): void {
    this.masterSurveyService.getSurveyTag().subscribe(data => {
      this.tagList = data;
    });
    this.showMapTagPopUp = true;
  }

  onAddTag(): void {
    this.masterSurveyService.createSurveyTag(this.tagData).subscribe(data => {
      this.tagList = data;
      this.tagData.tagName = '';
      this.showMapTag();
    });
  }

  onTagSelection(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.questionData.controls.question.controls[this.surveyControlsEnum.mapSurveyQuestionWithTags].controls = [];
    this.questionData.value.mapSurveyQuestionWithTags = [];
    this.questionData.controls.question.value.mapSurveyQuestionWithTags = [];

    this.sectionReference.sectionData.sectionQuestions.forEach(question => {
      if (question.sectionQuestionSequenceNo == this.questionData.value.sectionQuestionSequenceNo) {
        question.question.mapSurveyQuestionWithTags = [];
      }
    });

    this.mapSurveyQuestionWithTags?.forEach(tag => {
      this.sectionReference.sectionData.sectionQuestions.forEach(question => {
        if (question.sectionQuestionSequenceNo == this.questionData.value.sectionQuestionSequenceNo) {
          question.question.mapSurveyQuestionWithTags.push(this.getCreateQuestionTags(tag.id, tag.tagName));
        }
      })
    });
    this.showMapTagPopUp = false;
  }

  getCreateQuestionTags(tagId: number, tag: string): MapQuestionTag {
    return ({
      surveyQuestionTag: {
        id: tagId,
        tagName: tag
      }
    });
  }

  autoGrowTextZone(textBox): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    textBox.target.style.height = this.surveyControlsEnum.zeroPixel;
    textBox.target.style.height = (textBox.target.scrollHeight + 25) + this.surveyControlsEnum.pixel;
  }

  onChangeUpdateStatus(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
  }

  onOpenShowSkipLogic(): void{
    let isAllAnswerLabelValid = true;
    const noOfRows: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerRow).value;
    const noOfCols: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerColumn).value;
    const totalAnswerLabel: number = noOfRows + noOfCols;

    for(let indexAnswerLabel=0;indexAnswerLabel<totalAnswerLabel;indexAnswerLabel++){
      if(this.answerLabelArray.value[indexAnswerLabel].labelText=='' || this.answerLabelArray.value[indexAnswerLabel].labelText==undefined){
        isAllAnswerLabelValid = false;
      }
    }

    if(isAllAnswerLabelValid){
      this.showSkipLogicModal = true;
      this.errorAnswerLabelSkipLogic = false;
    }else{
      this.showSkipLogicModal = false;
      this.errorAnswerLabelSkipLogic = true;
      setTimeout(() => {
        this.errorAnswerLabelSkipLogic = false;
      }, 5000);
    }
  }

  onCheckUpdateStatus(): void{
    this.masterSurveyService.setSurveyUpdateStatus(false);
  }

  onUpdateDisplay(data: boolean): void{
    this.showDisplayMessage = data;
    if(this.showDisplayMessage===true){
        this.sectionReference?.surveySections?.forEach(section => {
          if (section.tempSectionId == this.questionData.value.questionDisplayCondition.tempSectionId) {
            section.sectionQuestions?.forEach(question => {
              if (question.tempSurveyQuestionId == this.questionData.value.questionDisplayCondition.tempSurveyQuestionId) {
                this.displayLogicToShow.questionText = question.question?.questionText;
                this.displayLogicToShow.questionTypeId = question.question?.questionType.id;
                if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.RadioButton ||
                  this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MultipleChoice) {
                    if(this.questionData.value.questionDisplayCondition.answerLabel){
                      this.displayLogicToShow.answerLabel = this.questionData.value.questionDisplayCondition.answerLabel.labelText;
                    }else if(this.questionData.value.questionDisplayCondition.answerLabelSequenceNo){
                      const labels: AnswerLabel[] = question.question.answerLabels;
                      labels.forEach(answer=>{
                        if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
                          this.displayLogicToShow.answerLabel = answer.labelText;
                        }
                      });
                    }
                  }
                if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MatrixTable) {
                  const labels: AnswerLabel[] = question.question.answerLabels;
                  const rowAnswers: AnswerLabel[] = [];
                  const colAnswers: AnswerLabel[] = [];

                  labels.forEach(label => {
                    if (label.isRow) {
                      rowAnswers.push(label);
                    } else {
                      colAnswers.push(label);
                    }
                  });

                  if (this.questionData.value.questionDisplayCondition.answerLabel) {
                    this.displayLogicToShow.answerLabel = this.questionData.value.questionDisplayCondition.answerLabel.labelText;
                  } else if (this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
                    rowAnswers.forEach(answer => {
                      if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
                        this.displayLogicToShow.answerLabel = answer.labelText;
                      }
                    });
                  }

                  if(this.questionData.value.questionDisplayCondition.colAnswerLabel){
                    this.displayLogicToShow.colAnswerLabel = this.questionData.value.questionDisplayCondition.colAnswerLabel.labelText;
                  }else if(this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo){
                    colAnswers.forEach(answer=>{
                      if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo) {
                        this.displayLogicToShow.colAnswerLabel = answer.labelText;
                      }
                    });
                  }
                }
              }
            });
            this.displayLogicToShow.displayValue = this.questionData.value?.questionDisplayCondition?.displayValue;
            const subscription = this.shareSurveyData.getSkipConditions().subscribe(conditions => {
              const skipCondition = conditions.find(condition => {
                return condition.id == this.questionData.value.questionDisplayCondition.skipCondition.id;
              });
              this.displayLogicToShow.skipConditionName = skipCondition.skipConditionName;
            });
            this.subscriptions.push(subscription);
          }
        });
    }
  }

  onUpdateSkip(data) : void {
    this.showSkipMessage = data;
    if (data) {
      this.skipLogicToShow.skipType = this.questionData.controls.questionSkipCondition.value.skipType;

      const labels: AnswerLabel[] = this.questionData.controls.question.controls.answerLabels.value;
      const rowAnswers: AnswerLabel[] = [];
      const colAnswers: AnswerLabel[] = [];

      labels.forEach(label => {
        if (label.isRow) {
          rowAnswers.push(label);
        } else {
          colAnswers.push(label);
        }
      });

      if (this.questionData.controls.questionSkipCondition.value.answerLabel) {
        this.skipLogicToShow.answerLabel = this.questionData.controls.questionSkipCondition.value.answerLabel?.labelText;
      }else{
        rowAnswers.forEach(rowAnswer => {
          if (rowAnswer.answerLabelSequenceNo == this.questionData.controls.questionSkipCondition.value.answerLabelSequenceNo) {
            this.skipLogicToShow.answerLabel = rowAnswer.labelText;
          }
        });
      }


      if(this.questionData.controls.questionSkipCondition.value.colAnswerLabel){
        this.skipLogicToShow.colAnswerLabel = this.questionData.controls.questionSkipCondition.value.colAnswerLabel?.labelText;
      }else{
        colAnswers.forEach(colAnswer => {
          if (colAnswer.answerLabelSequenceNo == this.questionData.controls.questionSkipCondition.value.colAnswerLabelSequenceNo) {
            this.skipLogicToShow.colAnswerLabel = colAnswer.labelText;
          }
        });
      }

      const subscription = this.shareSurveyData.getSkipConditions().subscribe(conditions => {
        const skipCondition = conditions.find(condition => {
          return condition.id == this.questionData.controls.questionSkipCondition.value.skipCondition.id;
        });
        this.skipLogicToShow.skipConditionName = skipCondition.skipConditionName;
      });
      this.subscriptions.push(subscription);
    }
  }

  defaultDisplayLogicMessage() {
    return{
      questionText:'',
      skipConditionName:'',
      answerLabel:'',
      colAnswerLabel:'',
      displayValue:undefined,
      questionTypeId:undefined
    }
  }

  defaultSkipLogicMessage(){
    return{
      skipType : '',
      answerLabel:'',
      colAnswerLabel:'',
      skipConditionName:''
    }
  }

  ngOnDestroy(): void {
    this.questionData.valueChanges.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
