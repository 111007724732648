/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HasPermissionDirective} from "./has-permission.directive";
import {HasAccessDirective} from "./has-access.directive";

@NgModule({
  declarations: [
    HasPermissionDirective,
    HasAccessDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HasPermissionDirective,
    HasAccessDirective
  ],
})
export class DirectiveModule { }
