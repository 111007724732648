<header class="ksa-header">
  <div class="branding">
    <a class="nav-link" routerLink="Dashboard" routerLinkActive="active">
      <div class="branding">
        <div class="vmware-logo-icon"></div>
        <span class="title">{{'header.title' | translate}}</span>
      </div>
    </a>
  </div>
  <div class="header-actions">
    <clr-dropdown>
      <button class="nav-text" clrDropdownTrigger>
        {{username}}
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a (click)="logout()" clrDropdownItem>{{'header.logout' | translate}}</a>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</header>
