/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {HeatMapService} from '../../../services/heat-map.service';
import {ColorCodeService} from "../../../services/color-code.service";
import {Options} from "@angular-slider/ngx-slider";
import {ColorCode} from "../../../models/color-data.model";
import {AnalysisShareService} from '../../../services/analysis-share.service';
import {ResponseColumnSettings} from '../../../models/respose-data.model';
import {ResponseService} from '../../../services/response.service';
import {Analysis} from '../../../enums/analaysis.enum';
import {ExcelService} from "../../../services/excel.service";
import {AnalysisResponseData, ParticipantAnalysisListEntity} from '../../../models/analysis-response-data.model';
import {SkillGroup} from '../../../models/role-profile-groups.model';
import {Functionality} from "../../../enums/functionality.enum";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {ClrDatagridStateInterface} from "@clr/angular";
import {AnalysisService} from "../../../services/analysisService";
import {TranslateService} from '@ngx-translate/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from "rxjs/operators";
import {DataGridTypeEnum} from "../../../enums/data-grid-type.enum";
import {PageSizeService} from "../../../services/page-size.service";

@Component({
  selector: 'app-heat-map-report',
  templateUrl: './heat-map-report.component.html',
  styleUrls: ['./heat-map-report.component.scss']
})
export class HeatMapReportComponent implements OnChanges, OnDestroy {

  responseColumnSettings: (ResponseColumnSettings)[];
  isColorModelOpen: boolean = false;
  page: number;
  loading: boolean = false;
  total: number;
  errorMsg: string = '';
  skillGroups: (SkillGroup)[] = [];
  disabled: boolean = false;
  skillGroupSelected = [];
  colorData: (ColorCode)[] = [];
  value: number = 40;
  highValue: number = 60;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  dataGridType: DataGridTypeEnum = DataGridTypeEnum.HEAT_MAP;
  @Input() responseData: AnalysisResponseData[];
  analysisEnum: typeof Analysis = Analysis;
  showAnswers: boolean = false;
  isParticipantDetails: boolean = false;
  participantId: number;
  showDatagrid: boolean = false;
  @Input() ksaId: number;
  participantData: AnalysisResponseData;
  participantIndex: number;
  participantName: string = '';
  colorRoleMapData: (ColorCode)[] = [];
  @Input() showNewWindowButton: boolean = true;
  showLoader: boolean = false;

  functionality: typeof Functionality = Functionality;
  isHidden: boolean = true;
  isSavedResponse: boolean = false;
  successMsgTimeout: number = 5000;
  pinnedColumn: string;
  state: ClrDatagridStateInterface;

  constructor(public responseService: ResponseService,
              private heatMapService: HeatMapService,
              private colorCodeService: ColorCodeService,
              private analysisShareService: AnalysisShareService,
              private excelService: ExcelService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              public analysisService: AnalysisService,
              private readonly translate: TranslateService,
              public readonly pageSizeService: PageSizeService) {
  }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showDatagrid = false;
    this.skillGroups = [];
    if (this.responseData) {
      this.operations();
    }
  }

  async operations() {
    await this.getAllResponseColumnData();
    await this.getAllSkillGroups();
    await this.getAllColorCodesById();
  }

  mapColorToTable(): void {
    if (this.colorData && this.colorData.length > 0) {
      this.analysisShareService.mapColorToGivenTableType(this.responseData, this.colorData, this.dataGridType);
    } else {
      this.applyDefaultTableColors();
    }
    this.showDatagrid = true;
  }

  addColor(color: ColorCode):void{
    this.colorData.push(color);
    this.mapColorToTable();
  }

  between(x, min, max) {
    return x >= min && x <= max;
  }

  testRange(numberToCheck, bottom, top): boolean {
    return (numberToCheck >= bottom && numberToCheck <= top);
  }

  getAllColorCodesById(): void {
    this.colorCodeService.getAllColorCodesByKsaIdAndType(this.ksaId, this.dataGridType).subscribe(data => {
      if (data) {
        this.colorData = data;
        this.mapColorToTable();
      }
    });
  }

  applyDefaultTableColors(): void {
    this.colorCodeService.setLoadDefaultColorByColorDto(this.ksaId, this.dataGridType).subscribe(colorData => {
      if (colorData) {
        this.analysisShareService.mapColorToGivenTableType(this.responseData, colorData, this.dataGridType);
        this.showDatagrid = true;
      }
    });
  }

  onShowColorModel(): void {
    this.isColorModelOpen = true;
    this.getAllColorCodesById();
  }

  onHideColorModal(): void{
    this.mapColorToTable();
    this.isColorModelOpen = false;
  }

  onShowAnswers(response): void {
    this.participantId = response.participantId;
    this.participantName = response?.participantAnalysisList[0]?.participant?.firstName + " " +
      response?.participantAnalysisList[0]?.participant?.lastName;
    response.visited = true;
    this.showAnswers = true;
    this.router.navigate([]).then((result) => {
      window.open(`${environment.API_URL}` +'/#/ParticipantAnswers/'+ this.ksaId+'/'+this.participantId, '_blank');
    });
  }

  onShowParticipantDetails(data, index): void {
    data.visited = true;
    this.isParticipantDetails = true;
    this.participantIndex = index;
    this.participantData = data;
  }

  onShowParticipant(data: boolean): void {
    this.isParticipantDetails = data;
  }

  getAllSkillGroups(): void {
    this.skillGroupSelected = [];
    this.heatMapService.getAllSkillGroups(this.ksaId).subscribe(data => {
      this.skillGroups = data;
      this.skillGroups.forEach(data => {
        this.skillGroupSelected?.push(data.skillGroupName);
      });
    });
  }

  exportExcelHeatMap(): void {
    this.analysisShareService.exportExcelData(this.excelService.generateHeatMapExcelData.bind(this.excelService), this.ksaId, this.dataGridType);
  }

  getAllResponseColumnData(): void {
    this.responseService.getAllResponseColumnData(this.ksaId).subscribe(data => {
      if (data) {
        this.showDatagrid = true;
        this.responseColumnSettings = data;
      }
    })
  }

  onOpenNewWindow(): void {
    window.open(`${environment.API_URL}` + '/#' + '/AnalysisReport/' + 'HeatMap' + '/' + this.ksaId, '_blank', 'location=yes,height=1000,width=1000,scrollbars=yes,status=yes');
  }

  onRefresh(): void {
    this.heatMapRefresh(this.state);
  }

  ngOnDestroy(): void {
    this.analysisShareService.setAnalysisResponseData(this.responseData);
  }

  onSaveColumnSettings() {
    this.responseService.updateResponseColumnData(this.responseColumnSettings).subscribe(data => {
      if (data) {
        this.responseColumnSettings = data;
        this.heatMapRefresh(this.state);
        this.isSavedResponse = true;
      }
      setTimeout(() => {
        this.isSavedResponse = false;
      }, this.successMsgTimeout);
    })
  }

  setPinnedColumn(questionName: string) {
    this.pinnedColumn = '';
    this.pinnedColumn = questionName;
  }

  heatMapRefresh(state: ClrDatagridStateInterface) {
    this.pageSizeService.setPageSize(state?.page?.size);
    this.state = state;
    this.loading = true;
    const colFilters: ({ columnName: string; columnValue: string; })[] = [];
    let columnName = '';
    let data;
    if (state?.filters) {
      state.filters.forEach((filter,i)=> {
        const {property, value} = <{ property: string; value: string }>filter;
        colFilters.push({columnValue : value, columnName : property});
        columnName = property;
      })
      data={
        ksaId: this.ksaId,
        size: state?.page.size,
        sortColumn: columnName,
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false)  ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0,
        analysisFilterDTOS: colFilters
      }
    } else {
      data = {
        ksaId: this.ksaId,
        size: state?.page.size,
        sortColumn: state?.sort ? state.sort.by : "",
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false) ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0
      }
    }

    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.analysisShareService.updateResponse(data)
        .pipe(finalize(() => hideSpinner()))
        .subscribe((results) => {
          this.responseData = results.responseData;
          this.total = results.total;
          this.getAllColorCodesById();
          this.loading = false;
        });
    });

  }

  // The purpose of this method is to populate participantAnalysisList
  // with null data in order to display N/A fo each column instead of empty
  // when there is no data for participantAnalysisList . That logic is needed
  // in order to preserve data-grid style (for empty there is a misalignment!)
  // Number of populated objects is taken from the AnalysisResponseData from whole array
  // with the most data for participantAnalysisList.
  getParticipantAnalysisList(response : AnalysisResponseData): ParticipantAnalysisListEntity[]{
    const responseWithMostAnalysis: ParticipantAnalysisListEntity[] = this.responseData
      ?.sort(response=>response.participantAnalysisList.length)[0]?.participantAnalysisList;

    if(responseWithMostAnalysis && response.participantAnalysisList && response.participantAnalysisList.length === 0){
      const participantAnalysisList: ParticipantAnalysisListEntity[]  = [];
      responseWithMostAnalysis.forEach(analysis=>participantAnalysisList.push({heatMapValue: null, skillGroupName: analysis.skillGroupName}));
      return participantAnalysisList;
    }
    return response.participantAnalysisList;
  }
}
