<clr-modal [clrModalOpen]="isOpen" (clrModalOpenChange)="onOpenChange()" [clrModalSize]="dialogSource?.size">
    <h3 class="modal-title">
        {{dialogSource?.title}}
    </h3>
    <div class="modal-body">
        <clr-alert *ngIf="dialogSource?.alert?.getAlertMessage()" [clrAlertClosable]="false"
                   [clrAlertType]="dialogSource?.alert?.getAlertType()">
            <clr-alert-item>
                <span class="alert-text">
                    {{dialogSource?.alert?.getAlertMessage()}}
                </span>
            </clr-alert-item>
        </clr-alert>
        <form>
            <p [innerHTML]="dialogSource?.message | safeHtml" style="margin-top: 0">
            </p>
            <ng-container #customComponentContainer></ng-container>
        </form>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="!dialogSource?.showOnlyOkButton">
            <button type="button" id="cancel-btn"
                    class="btn btn-outline"
                    *ngIf="!dialogSource?.hideCancelButton && dialogSource?.rejectAction"
                    (click)="onActionClick(dialogSource.rejectAction)">{{dialogSource.rejectAction?.name}}
            </button>
            <ng-container *ngFor="let dialogAction of dialogSource?.listOfActions; let index = index">
                <button type="button" id="confirm-btn"
                        [ngClass]="index === 0 ? 'btn btn-primary' : 'btn btn-outline'"
                        *ngIf="dialogAction"
                        [disabled]="index === 0 && dialogSource?.shouldDisableConfirmAction"
                        (click)="onActionClick(dialogAction)">{{dialogAction?.name}}
                </button>
            </ng-container>
        </ng-container>

        <button type="button" id="ok-btn" class="btn btn-outline"
                *ngIf="dialogSource?.showOnlyOkButton" (click)="onRejection()">
            <ng-container *ngIf="dialogSource?.rejectAction?.name">{{dialogSource?.rejectAction?.name}}</ng-container>
            <ng-container *ngIf="!dialogSource?.rejectAction?.name">{{'common.ok'|translate}}</ng-container>
        </button>
    </div>
</clr-modal>
