/********************************************************************** *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {CoursesService} from '../../../services/courses.service';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Course, courseColumnHeaderList, CourseContent, CourseSkillRequest, Tag} from '../../../models/course.model';
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {SurveyControls} from '../../../enums/survey-controls.enum';
import {TranslateService} from '@ngx-translate/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-mng-courses',
  templateUrl: './mng-courses.component.html',
  styleUrls: ['./mng-courses.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class MngCoursesComponent implements OnInit {
  surveyStatusOption: string[] = ['Draft', 'Archived', 'Active'];
  state: ClrDatagridStateInterface;
  statusToFilter: string = '';
  temp: CourseContent[];
  page: number;
  total: number;
  trainingPreRequisiteToAdd: string = '';
  errorMsg: string = '';
  courses: Course = {
    content: []
  };
  selectedCourses: CourseContent[] = [];
  allCourses: CourseContent[] = [];
  coursesNotBundle: CourseContent[] = [];
  currentCourse: CourseContent = this.initializeCourse();
  courseToEdit: CourseContent = this.initializeCourse();
  addCourseFormOpen: boolean = false;
  editCourseFormOpen: boolean = false;
  newVersionFormOpen: boolean = false;
  courseBundleIsChecked: boolean = false;
  showDeleteCoursePopUp: boolean = false;
  deleteId: number;
  isDefault: boolean = false;
  showWarningMessageCourseIsDefault: boolean = false;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;

  functionality: typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;

  courseColumnHeaderList: (courseColumnHeaderList)[] = this.getColumnHeaders();
  isSavedResponse: boolean = false;
  successMsgTimeout: number = 5000;
  isDuplicateCourseId: boolean = false;
  validationPattern: string = '[A-Za-z0-9.]{0,10}';

  constructor(public coursesService: CoursesService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    const columnHeaderString = sessionStorage.getItem("columnHeaders");
    if (columnHeaderString) {
      this.courseColumnHeaderList = JSON.parse(columnHeaderString);
    }
  }

  getColumnHeaders(): courseColumnHeaderList[] {
    this.courseColumnHeaderList = [
      {
        columnName: "Course Id",
        isHide: false,
        sequenceNumber: 2,
        columnFilterName: "courseId"
      },
      {
        columnName: "Course Name",
        isHide: false,
        sequenceNumber: 3,
        columnFilterName: "courseName"
      },
      {
        columnName: "Description",
        isHide: false,
        sequenceNumber: 4
      },
      {
        columnName: "Child Courses",
        isHide: false,
        sequenceNumber: 5
      },
      {
        columnName: "Training Prerequisites",
        isHide: false,
        sequenceNumber: 6
      },
      {
        columnName: "Course Version",
        isHide: false,
        sequenceNumber: 7,
        columnFilterName:"courseVersion"
      },
      {
        columnName: "Status",
        isHide: false,
        sequenceNumber: 9,
        columnFilterName:"status"
      }
    ]

    return this.courseColumnHeaderList;
  }

  getActiveCourses(): void {
    this.coursesService.getActiveCourses().subscribe((courseSkillRequest: CourseSkillRequest[]) => {
        this.coursesNotBundle = courseSkillRequest.filter(x => x.course.isCourseBundle === false).map(csr => csr.course);
      },
      error => console.log(error));
  }

  getSelectedCoursesByCourseId(id: number): void {
    this.coursesService.getSelectedCoursesByCourseId(id).subscribe(data => {
      this.allCourses = data;
      this.selectedCourses = data;
      this.currentCourse.childCourses = this.selectedCourses.filter((course) => {
        return course.isSelected === true;
      });
    },
      error => console.log(error));
  }

  onAddCourse(form: NgForm): void {
    if (form.invalid || (this.courseBundleIsChecked && this.currentCourse.childCourses.length === 0)) {
      return;
    }
    this.courseToEdit.courseName = (this.currentCourse.courseName).trim();
    this.courseToEdit.courseUrl = (this.currentCourse.courseUrl).trim();
    this.courseToEdit.courseId = (this.currentCourse.courseId).trim();
    this.courseToEdit.courseDescription = (this.currentCourse.courseDescription).trim();
    this.courseToEdit.courseVersion = (this.currentCourse.courseVersion).trim();
    this.courseToEdit.isCourseBundle = !this.courseBundleIsChecked ? false : true;
    this.courseToEdit.isDefault = !this.isDefault ? false : true;

    this.courseToEdit.childCourses = this.courseBundleIsChecked ? this.currentCourse.childCourses.map((childCourse) =>
      ({ childCourse: { id: childCourse.id } })) : [];
    this.courseToEdit.preRequisiteCourses = this.currentCourse.preRequisiteCourses.map((preRequisiteCourses) =>
      ({ trainingPreRequisiteCourse: preRequisiteCourses.trainingPreRequisiteCourse }));

    if (this.currentCourse.courseReference?.id) {
      this.courseToEdit.courseReference.id = this.currentCourse.courseReference?.id;
    } else {
      this.courseToEdit.courseReference = null;
    }

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.coursesService.createCourse(this.courseToEdit)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.addCourseFormOpen = false;
      this.postAddEditOperation(form);
      this.courseToEdit = this.initializeCourse();
    },
      error => this.errorMsg = error.error.message);
      this.isDuplicateCourseId = false;
  }

  onEditCourse(form: NgForm): void {
    if (form.invalid || (this.courseBundleIsChecked && this.currentCourse.childCourses.length === 0)) {
      return;
    }
    this.courseToEdit.id = this.currentCourse.id;
    this.mapCourseDetails();
    this.courseToEdit.childCourses = this.courseBundleIsChecked ? this.currentCourse.childCourses.map((childCourse) =>
      ({ id: childCourse.id, childCourse: { id: childCourse.versionId } })) : [];
    this.courseToEdit.preRequisiteCourses = this.currentCourse.preRequisiteCourses.map((preRequisiteCourses) =>
    ({
      trainingPreRequisiteCourse: preRequisiteCourses.trainingPreRequisiteCourse,
      id: preRequisiteCourses.id
    }));
    if (this.currentCourse.courseReference?.id) {
      this.courseToEdit.courseReference.id = this.currentCourse.courseReference?.id;
    } else {
      this.courseToEdit.courseReference = null;
    }
    this.courseToEdit.isDefault = !this.isDefault ? false : true;

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.coursesService.updateCourse(this.courseToEdit)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.editCourseFormOpen = false;
      this.postAddEditOperation(form);
      this.courseToEdit = this.initializeCourse();
    },
      error => this.errorMsg = error.error.message);
    this.showWarningMessageCourseIsDefault = false;
    this.isDuplicateCourseId = false;
  }

  onCreateNewVersion(form: NgForm): void {
    if (form.invalid || (this.courseBundleIsChecked && this.currentCourse.childCourses.length === 0)) {
      return;
    }
    this.mapCourseDetails();
    this.courseToEdit.childCourses = this.courseBundleIsChecked ? this.currentCourse.childCourses.map((childCourse) =>
      ({ childCourse: { id: childCourse.versionId } })) : [];
    this.courseToEdit.preRequisiteCourses = this.currentCourse.preRequisiteCourses.map((preRequisiteCourses) =>
      ({ trainingPreRequisiteCourse: preRequisiteCourses.trainingPreRequisiteCourse }));
    if (!this.courseToEdit.courseReference.id) {
      this.courseToEdit.courseReference.id = this.courseToEdit.id;
    }
    if (this.currentCourse.courseReference?.id) {
      this.courseToEdit.courseReference.id = this.currentCourse.courseReference?.id;
    } else {
      this.courseToEdit.courseReference = null;
    }
    this.courseToEdit.isDefault = !this.isDefault ? false : true;
    delete this.courseToEdit?.id;

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.coursesService.createCourse(this.courseToEdit)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.newVersionFormOpen = false;
      this.postAddEditOperation(form);
      this.courseToEdit = this.initializeCourse();
    },
      error => this.errorMsg = error.error.message);
      this.isDuplicateCourseId = false;
  }

  mapCourseDetails() {
    this.courseToEdit.id = this.currentCourse.id;
    this.courseToEdit.courseName = (this.currentCourse.courseName).trim();
    this.courseToEdit.courseId = (this.currentCourse.courseId).trim();
    this.courseToEdit.courseUrl = (this.currentCourse.courseUrl).trim();
    this.courseToEdit.courseDescription = (this.currentCourse.courseDescription).trim();
    this.courseToEdit.courseVersion = (this.currentCourse.courseVersion).trim();
    this.courseToEdit.status = this.currentCourse.status;
    this.courseToEdit.isCourseBundle = this.courseBundleIsChecked ? true : false;
    this.courseToEdit.isDefault = this.currentCourse.isDefault;
  }

  onEditCourseDetails(course: CourseContent): void {
    this.currentCourse.id = course.id;
    this.currentCourse.courseReference.id = course.courseReference?.id;
    this.mapCourseDetailsOnEdit(course);
    this.onSetEditCourseFormOpen();
  }

  onSelectNewVersionDetails(course: CourseContent): void {
    this.isDefault = true;
    this.currentCourse.courseReference.id = course?.id;
    this.mapCourseDetailsOnEdit(course);
    this.onSetNewVersionFormOpen();
  }

  mapCourseDetailsOnEdit(course: CourseContent): void {
    this.getSelectedCoursesByCourseId(course.id);
    this.currentCourse.courseId = course.courseId;
    this.currentCourse.courseName = course.courseName;
    this.currentCourse.courseId = course.courseId;
    this.currentCourse.courseDescription = course.courseDescription;
    this.currentCourse.courseVersion = course.courseVersion;
    this.currentCourse.status = course.status;
    this.courseBundleIsChecked = course.childCourses.length > 0 ? true : false;
    this.currentCourse.isCourseBundle = this.courseBundleIsChecked ? true : false;
    this.currentCourse.isDefault = course.isDefault;
    this.currentCourse.courseUrl = course.courseUrl;
    this.currentCourse.preRequisiteCourses = course.preRequisiteCourses.map((preRequisiteCourses) =>
    ({
      trainingPreRequisiteCourse: preRequisiteCourses.trainingPreRequisiteCourse,
      id: preRequisiteCourses.id
    }));
    this.isDefault = course.isDefault;
  }

  onDeleteCourse(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.coursesService.deleteCourse(this.deleteId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.refresh(this.state);
      this.showDeleteCoursePopUp = false
      this.deleteId = 0;
    },
      error => this.errorMsg = error.error.message);
  }

  onShowDeleteCoursePopUp(id: number) {
    this.deleteId = id;
    this.showDeleteCoursePopUp = true;
  }

  onSetEditCourseFormOpen(): void {
    this.editCourseFormOpen = true;
  }

  onSetAddCourseFormOpen(): void {
    this.getActiveCourses()
    this.addCourseFormOpen = true;
    this.isDefault=true;
  }

  onSetNewVersionFormOpen(): void {
    this.newVersionFormOpen = true;
  }

  onActivateCourse(id: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.coursesService.activateCourse(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.refresh(this.state);
    },
      error => console.log(error));
  }

  onArchiveCourse(id: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.coursesService.archiveCourse(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.refresh(this.state);
    },
      error => console.log(error));
  }

  onCancelAdding(form: NgForm): void {
    form.reset();
    this.currentCourse = this.initializeCourse();
    this.trainingPreRequisiteToAdd = '';
    this.addCourseFormOpen = false;
    this.errorMsg = '';
    this.isDuplicateCourseId = false;
  }

  onCancelEditing(form: NgForm): void {
    form.reset();
    this.currentCourse = this.initializeCourse();
    this.trainingPreRequisiteToAdd = '';
    this.editCourseFormOpen = false;
    this.errorMsg = '';
    this.showWarningMessageCourseIsDefault = false;
    this.isDuplicateCourseId = false;
  }

  onCancelNewVersion(form: NgForm): void {
    form.reset();
    this.currentCourse = this.initializeCourse();
    this.trainingPreRequisiteToAdd = '';
    this.newVersionFormOpen = false;
    this.errorMsg = '';
    this.isDuplicateCourseId = false;
  }

  onAddValue(): void {
    this.errorMsg = '';
    if (this.currentCourse.courseName != this.trainingPreRequisiteToAdd) {
      this.currentCourse.preRequisiteCourses.push({
        trainingPreRequisiteCourse: this.trainingPreRequisiteToAdd
      });
      this.trainingPreRequisiteToAdd = '';
    } else {
      this.errorMsg = this.surveyControlsEnum.errorTrainingPreRequisite;
    }
  }

  onRemovePrequisiteCourse(prequisiteCourseIndex: number): void {
    this.currentCourse.preRequisiteCourses.splice(prequisiteCourseIndex, 1);
  }

  onCancelAddingCourse(form: NgForm): void {
    form.reset();
    this.currentCourse = this.initializeCourse();
    this.errorMsg = ''
    this.addCourseFormOpen = false;
  }

  resetError(): void {
    this.errorMsg = '';
  }

  getStatusFilter(status: string) {
    if (status.toString() === "undefined--undefined") {
      this.statusToFilter = "";
    } else {
      this.statusToFilter = status;
    }
    this.refresh(this.state);
  }

  initializeCourse() {
    return {
      courseName: '',
      courseId:'',
      courseDescription: '',
      courseVersion: '',
      isCourseBundle: false,
      isDefault: true,
      courseUrl: '',
      childCourses: [],
      preRequisiteCourses: [],
      tag: {
        id: undefined
      },
      courseReference: {
        id: undefined
      }
    }
  }

  postAddEditOperation(form: NgForm): void {
    form.onReset();
    this.refresh(this.state);
    this.currentCourse = this.initializeCourse();
    this.courseBundleIsChecked = false;
    this.trainingPreRequisiteToAdd = '';
    this.errorMsg = '';
  }

  refresh(state: ClrDatagridStateInterface) {
    this.state = state;
    const filters: { [prop: string]: any } = {};
    if (state.filters) {
      for (const filter of state.filters) {
        const {property, value} = <{ property: string; value: string }>filter;
        filters[property] = value;
      }
    }
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      filters.status = this.statusToFilter;
      this.coursesService.getCourses(
        filters?.['tag.tagName'] === undefined ? '' : filters?.['tag.tagName'],
        filters?.courseId === undefined ? '' : filters?.courseId,
        filters?.courseName === undefined ? '' : filters?.courseName,
        filters?.courseDescription === undefined ? '' : filters.courseDescription,
        filters?.courseVersion === undefined ? '' : filters.courseVersion,
        filters?.status === undefined ? '' : filters.status,
        state.page.current - 1, state.page.size, state?.sort?.by === undefined ? this.surveyControlsEnum.createdDate : state?.sort?.by, state?.sort?.reverse ? state?.sort?.reverse : 'ASC')
        .pipe(finalize(() => hideSpinner()))
        .subscribe((results) => {
          this.courses = results;
          this.temp = results.content;
          this.total = results.totalElements;
        });
    });
  }

  onSaveResponseColumns() {
    sessionStorage.setItem("columnHeaders", JSON.stringify(this.courseColumnHeaderList));
    this.isSavedResponse = true;
    setTimeout(() => {
      this.isSavedResponse = false;
    }, this.successMsgTimeout);
  }

  onInputCourseId(courseId: string, id: number): void {
    this.isDuplicateCourseId = false;
    this.courses?.content.forEach(course => {
      if (course.id != id) {
        if (course.courseId == courseId) {
          this.isDuplicateCourseId = true;
        }
      }
    });
  }

}
