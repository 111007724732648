/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Inject, Injectable, NgZone} from "@angular/core";
import {DOCUMENT} from "@angular/common";

/**
 * @author avalchev
 */
@Injectable({providedIn: 'root'})
export class ZoneManager {

  constructor(private ngZone: NgZone,
              @Inject(DOCUMENT) private document: Document) {
  }

  runIntervalInZone(callback: Function, interval: number): number {
    let intervalInstance: number;
    this.ngZone.runOutsideAngular(() => {
      intervalInstance = this.document.defaultView.setInterval(() => {
        this.ngZone.run(() => {
          callback();
        });
      }, interval);
    });
    return intervalInstance;
  }

}
