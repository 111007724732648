 <form clrForm class="clr-row" autocomplete="off">
    <div class="clr-col-12">
      <clr-select-container class="clr-row">
        <label
          class="clr-col-2">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.ksaRoleProfileSet' | translate}}
          *</label>
        <select class="clr-col-4 solution-group-select-input input-field" clrSelect name="roleProfileSetImported"
                [(ngModel)]="importedRoleProfileSetSelected" required (change)="populateGridWithRoleProfileGroups()">
          <option disabled selected
                  value="">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.selectRoleProfileSetFromList' | translate}}</option>
          <option *ngFor="let roleProfileSet of roleProfileSets?.ksaJobProfileSets"
                  [ngValue]="roleProfileSet">{{roleProfileSet.name}}</option>
        </select>
        <clr-control-error>{{'errorMsg.roleProfileSetRequired'|translate}}</clr-control-error>
      </clr-select-container>
    </div>
 </form>
 <div class="button-container">
   <button *ngIf="hasWritePermission" class="btn btn btn-sm btn-primary" (click)="onSetAddKsaFormOpen()"
           [disabled]="!importedRoleProfileSetSelected">
     {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.addRoleProfileGroup' | translate}}
   </button>
   <button *ngIf="hasWritePermission" (click)="onDeleteMultipleGroups(selectedGroups)" class="btn btn-sm btn-primary"
           [disabled]="!importedRoleProfileSetSelected">
     {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.deleteRoleProfileGroup' | translate}}
   </button>
   <clr-modal [(clrModalOpen)]="addEditRoleProfileGroupFormOpen" [clrModalClosable]="true" class="modal-lg"
              (clrModalOpenChange)="clearForm()">
     <h3
       class="modal-title">{{roleProfileGroup.id ? ('ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.editRoleProfileGroup' | translate) : ('ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.addRoleProfileGroup' | translate)}}
       <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
         <clr-alert-item>{{errorMsg}}</clr-alert-item>
       </clr-alert>
     </h3>
     <div class="modal-body">
       <form clrForm (ngSubmit)="onAddEditRoleProfileGroup(addForm)" #addForm="ngForm" autocomplete="off">
         <clr-input-container class="clr-row">
           <label
             class="clr-col-5"> {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.roleProfileSetName' | translate}}
             *</label>
           <input [(ngModel)]="roleProfileGroup.ksaJobProfileSet.name" class="clr-col-7" clrInput disabled
                  name="setName"
                  type="text"
                  required/>
         </clr-input-container>
         <clr-input-container class="clr-row">
           <label
             class="clr-col-5"> {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.roleProfileGroupName' | translate}}
             *</label>
           <input class="clr-col-7" clrInput type="text" name="roleGroupName"
                  [(ngModel)]="roleProfileGroup.roleProfileGroupName"
                  required placeholder="enter role profile gorup name"/>
           <clr-control-error>{{'errorMsg.roleProfileGroupRequired'|translate}}</clr-control-error>
         </clr-input-container>
         <clr-textarea-container class="clr-row">
          <label
            class="clr-col-5"> {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.description' | translate}}</label>
          <textarea [(ngModel)]="roleProfileGroup.description" class="clr-col-7" clrTextarea name="description"
                    placeholder="enter description" rows="1" type="text"></textarea>
        </clr-textarea-container>
        <clr-toggle-container class="clr-row">
          <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleGroup' | translate}}*</label>
          <label class="clr-col-2 light-label">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.default'|translate}}</label>
          <clr-toggle-wrapper class="clr-col-12 clr-row">
            <input type="checkbox" clrToggle name="options" [(ngModel)]="importFromLibrary"
                   (change)="checkImportFromLibrary()" class="clr-col-5" />
            <label class="clr-col-11 light-label">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.importFromLibrary' | translate}}</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
        <div *ngIf="!importFromLibrary">
            <clr-datagrid class="datagrid-compact clear" [(clrDgSelected)]="roleProfileSelectedFromMaster" [clrDgPreserveSelection]="true">
              <clr-dg-column [clrDgField]="'name'"> {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.roleProfile' | translate}}</clr-dg-column>
              <clr-dg-row *clrDgItems="let roleProfile of masterRoleProfiles; let i = index"
                          [clrDgItem]="roleProfile">
                <clr-dg-cell>{{roleProfile.name}}</clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>
          <button type="button" class="btn btn-outline btn btn-sm btn-secondary"
            (click)="addSelectedRoleProfilesFromMaster()">
              {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.addSelectedRoleProfiles' | translate}}
          </button>
        </div>
        <div *ngIf="importFromLibrary">
          <clr-select-container class="clr-row">
            <label class="clr-col-5"> {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.ksa' | translate}}*</label>
            <select class="clr-col-7" clrSelect [(ngModel)]="importedKsaSelected" name="ksaImported" required (change)="roleProfileGroupKsa(importedKsaSelected)" >
              <option  disabled selected value="">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.selectKsa' | translate}}</option>
              <option *ngFor = "let importedKsa of importedKsas" [ngValue]="importedKsa">{{importedKsa.ksaName}}</option>
            </select>
            <clr-control-error>{{'errorMsg.ksaRequired'|translate}}</clr-control-error>
          </clr-select-container>
          <clr-select-container class="clr-row">
            <label class="clr-col-5"> {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.ksaRoleProfileSet' | translate}}*</label>
            <select class="clr-col-7 solution-group-select-input input-field" clrSelect name="roleProfileSetImported" [(ngModel)]="importedRoleProfileSetSelectedInForm" required>
              <option  disabled selected value="">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.selectRoleProfileSetFromList' | translate}}</option>
              <option *ngFor="let importedRoleProfileSet of roleProfileSets?.ksaJobProfileSets"
                      [ngValue]="importedRoleProfileSet">{{importedRoleProfileSet.name}}</option>
            </select>
            <clr-control-error>{{'errorMsg.roleProfileSetRequired'|translate}}</clr-control-error>
          </clr-select-container>
          <clr-select-container class="clr-row">
            <label class="clr-col-5"> {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.roleProfileGroup' | translate}}*</label>
            <select class="clr-col-7 solution-group-select-input input-field" clrSelect name="roleProfilegGroupImported" [(ngModel)]="importedRoleProfileGroupSelected" required (change)="populateImportedRoleProfiles()">
              <option  disabled selected value="0">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.selectRoleProfileGroup' | translate}}</option>
              <option *ngFor = "let importedRoleProfileGroup of importedRoleProfileSetSelectedInForm?.ksaRoleProfileGroups" [ngValue]="importedRoleProfileGroup">{{importedRoleProfileGroup.roleProfileGroupName}}</option>
            </select>
            <clr-control-error>{{'errorMsg.roleProfileGroupRequired'|translate}}</clr-control-error>
          </clr-select-container>
            <clr-datagrid  [(clrDgSelected)]="roleProfileSelectedFromLibrary">
              <clr-dg-column [clrDgField]="'name'">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.roleProfile' | translate}}</clr-dg-column>
              <clr-dg-row *clrDgItems="let roleProfile of importedRoleProfileGroupSelected?.ksaRoleProfiles; let i = index"
                [clrDgItem]="roleProfile">
                <clr-dg-cell>{{roleProfile.ksaRoleProfileName}}</clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>
          <button type="button" class="btn btn-outline btn btn-sm btn-secondary"
            (click)="addSelectedRoleProfilesFromLibrary()">
             {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.addSelectedRoleProfiles' | translate}}
          </button>
        </div>
          <clr-datagrid class="datagrid-compact clear">
            <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.roleProfile' | translate}}</clr-dg-column>
            <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.action' | translate}}</clr-dg-column>
            <clr-dg-row *clrDgItems="let roleProfile of roleProfilesSelected; let i = index"
              [clrDgItem]="roleProfile">
              <clr-dg-cell>{{!roleProfile?.name ? roleProfile?.ksaRoleProfileName:roleProfile?.name}}</clr-dg-cell>
              <clr-dg-cell><button type="button" class="btn btn-sm btn-danger btn-secondary"
                  (click)="deleteSelectedRpg(roleProfile)">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.delete' | translate}}</button></clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        <div class="add-edit-btn-container clear">
          <button type="button" class="btn btn-outline btn-sm" (click)="clearForm()">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.cancel' | translate}}</button>
          <button type="submit" class="btn btn-sm btn-primary">{{roleProfileGroup.id? ('ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.ok' | translate) : ('ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.add' | translate)}}</button>
        </div>
      </form>
    </div>
  </clr-modal>
  <clr-modal [(clrModalOpen)]="showDeleteWarning" (clrModalOpenChange)="errorMsg = ''">
    <h3 class="modal-title">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.deleteRoleProfileGroup' | translate}}?
      <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
        <clr-alert-item>{{errorMsg}}</clr-alert-item>
      </clr-alert>
    </h3>
    <div class="modal-body">{{'admin.roleProfilesAndGroups.roleProfiles.thisCannotBeUndone' | translate}}.</div>
    <div class="modal-footer">
      <button (click)="showDeleteWarning = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
        translate}}</button>
      <button (click)="onDeleteRoleProfile()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
        translate}}</button>
    </div>
  </clr-modal>
  <div *ngIf="importedRoleProfileSetSelected">
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selectedGroups" class="role-profile-datagrid">
    <clr-dg-column [clrDgField]="'roleProfileGroupName'">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.roleProfileGroupName' | translate}}</clr-dg-column>
    <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.roleProfiles' | translate}}</clr-dg-column>
    <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.description' | translate}}</clr-dg-column>
    <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.modifiedDate' | translate}}</clr-dg-column>
    <clr-dg-column class="action-cell" *ngIf="hasWritePermission"></clr-dg-column>

    <clr-dg-row *ngFor="let roleProfileGroup of roleProfileGroupsReceived.content" [clrDgItem]="roleProfileGroup">
      <clr-dg-cell>{{roleProfileGroup.roleProfileGroupName}}</clr-dg-cell>
      <clr-dg-cell>
        <ul *ngFor="let roleProfile of roleProfileGroup?.ksaRoleProfiles">
          <li>{{roleProfile?.ksaRoleProfileName}}</li>
        </ul>
      </clr-dg-cell>
      <clr-dg-cell>{{roleProfileGroup.description}}</clr-dg-cell>
      <clr-dg-cell>{{roleProfileGroup.lastModifiedDate|date}}</clr-dg-cell>
      <clr-dg-cell class="action-cell" *ngIf="hasWritePermission">
        <clr-dg-action-overflow>
          <button type="button" class="action-item"
                  (click)="onEditKsaDetails(roleProfileGroup)">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.edit' | translate}}</button>
          <button type="button" class="action-item"
                  (click)="onShowDeleteWarning(roleProfileGroup.id)">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.delete' | translate}}</button>
        </clr-dg-action-overflow>
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.groupsPerPage' | translate}}</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.groups' | translate}}
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
  </div>
</div>
