<div>
  <clr-tabs>
    <clr-tab>
      <button clrTabLink>{{'tab.reports.reportPreview' | translate}}</button>
      <ng-template [(clrIfActive)]="reportEditorSelected">
        <clr-tab-content>
          <app-mng-report-templates></app-mng-report-templates>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>{{'tab.reports.scorecards' | translate}}</button>
      <ng-template [(clrIfActive)]="scorecardsEditorSelected">
        <clr-tab-content>
          <scorecards></scorecards>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
  </clr-tabs>
</div>
