/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, OnInit} from '@angular/core';
import {KsaStatus} from "../../../enums/status.enum";
import {KsaEmailManagementService} from "../../../services/ksa-email-management.service";
import {EmailTemplateService} from "../../../services/email-template.service";
import {Anonymity} from "../../../enums/anonymity.enum";
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {MasterKsaSimple} from '../../../models/master-ksa.model';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {TranslateService} from '@ngx-translate/core';
import {finalize} from "rxjs/operators";
import {EmailTemplateTypeEnum} from "../../../enums/email-template-type.enum";

@Component({
  selector: 'app-email-management',
  templateUrl: './email-management.component.html',
  styleUrls: ['./email-management.component.scss']
})
export class EmailManagementComponent implements OnInit {
  isScheduler: boolean = false;
  ksaEmailDetails: MasterKsaSimple[] = [];
  page: number;
  total: number;
  isEditTemplate: boolean = false;
  showInviteDeleteWarning: boolean = false;
  showThanksDeleteWarning: boolean = false;
  showSchedulerDeleteWarning: boolean = false;
  isInvite: boolean = false;
  isThanks: boolean = false;
  statusEnum: typeof KsaStatus = KsaStatus;
  isSendParticipant: boolean = false;
  demoUrlDialog: boolean = false;
  anonymousUrlDialog: boolean = false;
  demoUrl: string;
  anonymousUrl: string;
  placeHolderNameList: any[] = [];
  anonymityType: typeof Anonymity = Anonymity;
  statusToFilter: string = '';
  surveyStatusOption: any = Object.keys(this.statusEnum);
  functionality: typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;
  showReminderButton!: boolean;
  selectedKsaId: number;

  private readonly LOADING: string = "spinnerLabels.common.loading";

  constructor(private readonly emailTemplateService: EmailTemplateService,
              private readonly ksaEmailService: KsaEmailManagementService,
              private readonly translate: TranslateService) {
  }

  ngOnInit(): void {
    this.isInvite = false;
    this.isThanks = false;
    this.getPlaceHolder();
    this.getAllKSAs();
  }

  getAllKSAs(): void {
    showSpinner(this.translate.instant(this.LOADING));
    this.ksaEmailService.getKsaEnhanced()
      .pipe(finalize(() => hideSpinner()))
      .subscribe(surveys => {
        this.ksaEmailDetails = surveys;
      });
  }

  getPlaceHolder(): void {
    this.placeHolderNameList = [];
    this.emailTemplateService.getPlaceHolder().subscribe(data => {
      data.forEach(value => {
        this.placeHolderNameList.push(value.placeholdertype);
      });
    });
  }

  getDemoUrl(id: number): void {
    this.demoUrlDialog = true;
    this.ksaEmailService.getDemoUrl(id).subscribe((data) => {
      if (data) {
        this.demoUrl = data.toString();
      }
    });
  }

  getAnonymousUrl(id: number): void {
    this.anonymousUrlDialog = true;
    this.ksaEmailService.getAnonymousUrl(id).subscribe((data) => {
      if (data) {
        this.anonymousUrl = data.toString();
      }
    });
  }

  onEditInviteTemplate(id): void {
    this.selectedKsaId = id;
    this.isInvite = true;
    this.isThanks = false;
    this.isScheduler = false;
    this.isEditTemplate = true;
  }

  onEditThanksTemplate(id): void {
    this.selectedKsaId = id;
    this.showReminderButton = false;
    this.isThanks = true;
    this.isInvite = false;
    this.isScheduler = false;
    this.isEditTemplate = true;
  }

  onShowInviteDeleteWarning(id: number): void {
    this.selectedKsaId = id;
    this.showInviteDeleteWarning = true;
  }

  onSchedulerInviteDeleteWarning(id): void {
    this.selectedKsaId = id;
    this.showSchedulerDeleteWarning = true;
  }

  onShowThanksDeleteWarning(id): void {
    this.selectedKsaId = id;
    this.showThanksDeleteWarning = true;
  }

  onParticipantInvite(id: number): void {
    this.selectedKsaId = id;
    this.isSendParticipant = true;
    this.isInvite = true;
    this.isThanks = false;
    this.showReminderButton = false;
  }

  onParticipantThanks(id): void {
    this.selectedKsaId = id;
    this.isSendParticipant = true;
    this.isThanks = true;
    this.isInvite = false;
    this.showReminderButton = false;
  }

  onDeleteInviteTemplate(): void {
    showSpinner(this.translate.instant(this.LOADING));
    this.ksaEmailService.deleteKsaEmailTemplate(this.selectedKsaId, EmailTemplateTypeEnum.INVITE)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(() => {
        this.getAllKSAs();
        this.selectedKsaId = null;
        this.showInviteDeleteWarning = false;
      });
  }

  onDeleteSchedulerTemplate(): void {
    showSpinner(this.translate.instant(this.LOADING));
    this.ksaEmailService.deleteKsaEmailTemplate(this.selectedKsaId, EmailTemplateTypeEnum.REMINDER)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(() => {
        this.getAllKSAs();
        this.selectedKsaId = null;
        this.showSchedulerDeleteWarning = false;
      });
  }

  onDeleteThanksTemplate(): void {
    showSpinner(this.translate.instant(this.LOADING));
    this.ksaEmailService.deleteKsaEmailTemplate(this.selectedKsaId, EmailTemplateTypeEnum.THANKS)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(() => {
        this.getAllKSAs();
        this.selectedKsaId = null;
        this.showThanksDeleteWarning = false;
      });
  }

  filterByStatus(status: string): void {
    this.statusToFilter = status;
    showSpinner(this.translate.instant(this.LOADING));
    this.ksaEmailService.getKsaEnhanced()
      .pipe(finalize(hideSpinner))
      .subscribe(data => {
        this.ksaEmailDetails = this.filterKsaEmailDetailsByStatus(data, status);
      });
  }

  isComposeTemplateValue(): void {
    this.getAllKSAs();
    this.selectedKsaId = null;
    this.isEditTemplate = false;
  }

  isSendParticipantValue(): void {
    this.getAllKSAs();
    this.isSendParticipant = false;
    this.selectedKsaId = null;
    this.isInvite = false;
    this.isThanks = false;

  }

  onParticipantReminder(id: number): void {
    this.selectedKsaId = id;
    this.isInvite = false;
    this.isThanks = false;
    this.isScheduler = true;
    this.showReminderButton = true;
    this.isEditTemplate = true;
  }

  private filterKsaEmailDetailsByStatus(data: any[], status: string): any[] {
    return status ? data.filter(item => item.status === status) : data;
  }
}
