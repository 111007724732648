export enum Analysis{
    colorCode = 'colorCode',
    fontColorCode = 'fontColorCode',
    tableBackgroundColorCode = 'tableBackgroundColorCode',
    tableFontColorCode = 'tableFontColorCode',
    defaultColor = '#b41e1e',
    defaultFontColor = '#666666',
    showResultAchieved = 'showResultAchieved',
    showResultDesired = 'showResultDesired'
  }
