/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  AnswerLabel,
  Config,
  DescriptionContent,
  MasterSurveyQuestion
} from '../../../../../../models/master-survey-questions.model';
import {MngSectionComponent} from '../mng-section.component';
import {SurveyControls} from "../../../../../../enums/survey-controls.enum";
import {QuestionType} from '../../../../../../enums/questionType.enum';
import {ShareSurveyDataService} from '../../../../../../services/share-survey-data.service';
import {MasterSurveyService} from '../../../../../../services/master-survey.service';
import {Subscription} from "rxjs";
import {UserService} from '../../../../../../services/user.service';
import {Functionality} from '../../../../../../enums/functionality.enum';

@Component({
  selector: 'app-description-text',
  templateUrl: './description-text.component.html',
  styleUrls: ['./description-text.component.scss']
})
export class DescriptionTextComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  index: number;
  move = new EventEmitter();
  sectionReference!: MngSectionComponent;
  @ViewChild('r') r: ElementRef;
  sectionQuestions!: MasterSurveyQuestion[];
  questionData: any;
  questionSequence!: number;
  tempSurveyQuestionId: number;
  isDisable: boolean = false;
  mapSkill: {
    id: number
  } = {
      id: 0
    }
  public descriptionQn: DescriptionContent = {
    content: ''
  }
  skillToShow: string = '';
  displayLogicToShow= this.defaultDisplayLogicMessage();
  skipLogicToShow = this.defaultSkipLogicMessage();
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  showDisplayLogicModal: boolean = false;
  showMapSkillPopUp: boolean = false;
  displayValueInput: boolean;
  isAddNoteTrue: boolean = false;
  isAddPageBreak: boolean = false;
  showDeleteQuestionPopUp: boolean = false;
  showMatrixAnswerLabel: boolean;
  showDisplayAnswerLabel: boolean;
  public config: Config;
  questionTypeEnum: typeof QuestionType = QuestionType;
  errorOnDisplayLogicItemDelete: boolean = false;
  showDisplayMessage: boolean = false;
  showSkipMessage: any;
  dependentQuestionDisplayLogic: string = '';
  subscriptions: Subscription[] = [];
  hasWritePermission: boolean;

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef,
              private shareSurveyData: ShareSurveyDataService,
              private masterSurveyService: MasterSurveyService,
              private readonly userService: UserService) {
    this.config = {
      toolbar: [
        ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
        ['fontsize', ['fontname', 'color', 'fontsize']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['table', 'link', 'picture']]
      ],
    }
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.manageSurveyTemplate);
    this.descriptionQn = {};
    if (this.questionData) {
      this.onInitializationQuestionData();
    } else {
      this.setDefaultQuestionData();
      this.changeDetectOnQuestion();
    }
  }

  changeDetectOnQuestion(): void {
    this.questionData.valueChanges.subscribe((val: MasterSurveyQuestion) => {
      this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1] = this.questionData.getRawValue();
    });
  }

  ngAfterViewInit(): void {
    this.changeDetectOnQuestion();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  setDefaultQuestionData(): void {
    this.questionData = this.formBuilder.group({
      question: this.formBuilder.group({
        questionType: {
          id: this.questionTypeEnum.Description,
        },
        questionText: ['', {
          validators: [Validators.required, Validators.maxLength(1000)],
        }],
        answerProperty: [],
      }),
      questionNote: ['', {
        validators: [Validators.maxLength(100)],
      }],
      language: {
        id: 1
      },
      skill: this.mapSkill,
      sectionQuestionSequenceNo: this.questionSequence,
      tempSurveyQuestionId: this.shareSurveyData.findMaxQuestionId()+1,
      isPageBreakAdded: [false],
      isImported: [false],
      isVersioned: [false],
      questionDisplayCondition: this.createDisplayControl(),
      questionSkipCondition: this.createSkipControl(),
    })
  }

  checkDescQuestion():void{
    if (this.questionData.controls['question'].controls['questionText'].value) {
      this.questionData.controls['question'].controls['questionText'].valueChanges.subscribe(value => {
        this.onChangeUpdateStatus();
      })
    }
  }

  onInitializationQuestionData(): void {
    this.skillToShow = this.questionData.skill ? this.questionData.skill.skillName : '';
    this.skipLogicToShow = this.questionData.questionSkipCondition;
    if (this.questionData.isPageBreakAdded) {
      this.isAddPageBreak = true;
    }
    this.assignQuestionData();
  }

    assignQuestionData(): void {
    this.questionData = this.formBuilder.group({
      id: this.questionData.id,
      question: this.formBuilder.group({
        id: this.questionData.question.id,
        questionType: {
          id: this.questionData.question.questionType.id,
        },
        questionText: [this.questionData.question.questionText, {
          validators: [Validators.required, Validators.maxLength(1000)],
        }],
        answerProperty: [],
      }),
      language: {
        id: 1
      },
      skill: {
        id: this.questionData.skill?.id
      },
      questionNote: [this.questionData.questionNote, {
        validators: [Validators.maxLength(100)],
      }],
      sectionQuestionSequenceNo: this.questionData.sectionQuestionSequenceNo,
      tempSurveyQuestionId: this.questionData.tempSurveyQuestionId,
      isPageBreakAdded: [this.questionData.isPageBreakAdded],
      isImported: [this.questionData.isImported],
      isVersioned: [this.questionData.isVersioned],
      questionDisplayCondition: this.questionData.questionDisplayCondition ? this.questionData.questionDisplayCondition : this.createDisplayControl(),
      questionSkipCondition: this.questionData.questionSkipCondition ? this.questionData.questionSkipCondition : this.createSkipControl(),
    });

    if(this.questionData.controls.isImported.value){
      this.questionData.controls[this.surveyControlsEnum.questionNote].disable();
    }

    if(this.questionData.value.questionDisplayCondition.tempSectionId){
      this.onUpdateDisplay(true);
    }else{
      this.onUpdateDisplay(false);
    }

    if(this.questionData.value.questionSkipCondition.skipCondition.id){
      this.onUpdateSkip(true);
    }else{
      this.onUpdateSkip(false);
    }
  }

  createSkipControl(): FormGroup {
    return this.formBuilder.group({
      skipCondition: this.formBuilder.group({
        id: null
      }),
      skipType: null
    })
  }

  onEditButton(): void {
    this.dependentQuestionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.shareSurveyData.validateQuestionDisplayLogic(this.questionData);
    if(validateQuestionDisplayLogicResponse.isValidQuestion){
      this.masterSurveyService.setSurveyUpdateStatus(false);
      this.questionData.controls[this.surveyControlsEnum.questionNote].enable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.questionText].enable();
      this.isDisable = false;
      this.questionData.get(this.surveyControlsEnum.isImported).setValue(false);
      delete this.questionData.controls.question.controls[this.surveyControlsEnum.id]?.value;
    }else{
      this.errorOnDisplayLogicItemDelete = true;
      if(validateQuestionDisplayLogicResponse.dependentQuestion){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentQuestion.question.questionText;
      }
      if(validateQuestionDisplayLogicResponse.dependentSection){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      }
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
      }, 5000);
    }
  }

  onAddPageBreak(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.isAddPageBreak = true;
    this.questionData.get(this.surveyControlsEnum.isPageBreakAdded).setValue(true);
  }

  onRemovePageBreak(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.isAddPageBreak = false;
    this.questionData.get(this.surveyControlsEnum.isPageBreakAdded).setValue(false);
  }

  createDisplayControl(): FormGroup {
    return this.formBuilder.group({
      answerLabelSequenceNo: null,
      colAnswerLabelSequenceNo: null,
      displayValue: null,
      skipCondition: {
        id: null
      },
      tempSectionId:null,
      tempSurveyQuestionId:null
    })
  }

  onRemoveQuestion(): void {
    this.dependentQuestionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.shareSurveyData.validateQuestionDisplayLogic(this.questionData);
    if(validateQuestionDisplayLogicResponse.isValidQuestion){
      this.sectionReference.removeQuestion(this.questionData);
      this.showDeleteQuestionPopUp = false;
      this.masterSurveyService.setSurveyUpdateStatus(false);
    }else{
      this.showDeleteQuestionPopUp = true;
      this.errorOnDisplayLogicItemDelete = true;
      if(validateQuestionDisplayLogicResponse.dependentQuestion){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentQuestion.question.questionText;
      }
      if(validateQuestionDisplayLogicResponse.dependentSection){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      }
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
      }, 5000);
    }
  }

  isUpArrayHidden(data: number): boolean {
    if (data == 1) {
      return true;
    }
    return false;
  }

  isDownArrayHidden(data: number): boolean {
    return this.sectionReference?.sectionData.sectionQuestions.length === data;

  }

  isLastQuestion(): boolean {
    return this.sectionReference?.sectionData.sectionQuestions.length === this.questionData.value.sectionQuestionSequenceNo
      && this.sectionReference?.surveySections.length === this.sectionReference.sectionData.sectionSequenceNo;

  }

  isFirstQuestion(): boolean {
    return this.sectionReference?.sectionData.sectionSequenceNo === 1 && this.questionData.value.sectionQuestionSequenceNo === 1;

  }

  onChangeUpdateStatus(): void{
    this.masterSurveyService.setSurveyUpdateStatus(false);
  }

  onUpdateDisplay(data: boolean): void{
    this.showDisplayMessage = data;
    if(this.showDisplayMessage===true){
        this.sectionReference?.surveySections?.forEach(section => {
          if (section.tempSectionId == this.questionData.value.questionDisplayCondition.tempSectionId) {
            section.sectionQuestions?.forEach(question => {
              if (question.tempSurveyQuestionId == this.questionData.value.questionDisplayCondition.tempSurveyQuestionId) {
                this.displayLogicToShow.questionText = question.question?.questionText;
                this.displayLogicToShow.questionTypeId = question.question?.questionType.id;
                if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.RadioButton ||
                  this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MultipleChoice) {
                    if(this.questionData.value.questionDisplayCondition.answerLabel){
                      this.displayLogicToShow.answerLabel = this.questionData.value.questionDisplayCondition.answerLabel.labelText;
                    }else if(this.questionData.value.questionDisplayCondition.answerLabelSequenceNo){
                      const labels: AnswerLabel[] = question.question.answerLabels;
                      labels.forEach(answer=>{
                        if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
                          this.displayLogicToShow.answerLabel = answer.labelText;
                        }
                      });
                    }
                  }
                if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MatrixTable) {
                  const labels: AnswerLabel[] = question.question.answerLabels;
                  const rowAnswers: AnswerLabel[] = [];
                  const colAnswers: AnswerLabel[] = [];

                  labels.forEach(label => {
                    if (label.isRow) {
                      rowAnswers.push(label);
                    } else {
                      colAnswers.push(label);
                    }
                  });

                  if (this.questionData.value.questionDisplayCondition.answerLabel) {
                    this.displayLogicToShow.answerLabel = this.questionData.value.questionDisplayCondition.answerLabel.labelText;
                  }else if(this.questionData.value.questionDisplayCondition.answerLabelSequenceNo){
                    rowAnswers.forEach(answer=>{
                      if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
                        this.displayLogicToShow.answerLabel = answer.labelText;
                      }
                    });
                  }

                  if(this.questionData.value.questionDisplayCondition.colAnswerLabel){
                    this.displayLogicToShow.colAnswerLabel = this.questionData.value.questionDisplayCondition.colAnswerLabel.labelText;
                  }else if(this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo){
                    colAnswers.forEach(answer=>{
                      if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo) {
                        this.displayLogicToShow.colAnswerLabel = answer.labelText;
                      }
                    });
                  }
                }
              }
            });
            this.displayLogicToShow.displayValue = this.questionData.value?.questionDisplayCondition?.displayValue;
            const subscription = this.shareSurveyData.getSkipConditions().subscribe(conditions => {
              const skipCondition = conditions.find(condition => {
                return condition.id == this.questionData.value.questionDisplayCondition.skipCondition.id;
              });
              this.displayLogicToShow.skipConditionName = skipCondition.skipConditionName;
            });
            this.subscriptions.push(subscription);
          }
        });
    }
  }

  onUpdateSkip(data) : void{
    this.showSkipMessage = data;
    if(data){
      this.skipLogicToShow.skipType = this.questionData.value.questionSkipCondition.skipType;
      const subscription = this.shareSurveyData.getSkipConditions().subscribe(conditions => {
        const skipCondition = conditions.find(condition => {
          return condition.id == this.questionData.value.questionSkipCondition.skipCondition.id;
        });
        this.skipLogicToShow.skipConditionName = skipCondition.skipConditionName;
      });
      this.subscriptions.push(subscription);
    }
  }

  defaultSkipLogicMessage(){
    return{
      skipType : '',
      skipConditionName:''
    }
  }

  defaultDisplayLogicMessage() {
    return{
      questionText:'',
      skipConditionName:'',
      answerLabel:'',
      colAnswerLabel:'',
      displayValue:undefined,
      questionTypeId:undefined
    }
  }
  ngOnDestroy(): void {
    this.questionData.valueChanges.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
