/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {Role} from '../enums/role.enum';
import {Functionality} from '../enums/functionality.enum';
import {ScreenNavigation} from '../models/screenNavigation.model';
import {Router} from "@angular/router";
import {Observable, timer} from "rxjs";
import {UsersService} from "./users.service";
import {SharedAuthService} from "./shared-auth.service";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AuthButtons} from "../models/auth.model";
import {UserCacheService} from "./user-cache.service";
import {User} from "../models/auth-user.model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";
import {UserService} from "./user.service";

@Injectable()
export class AuthService {
  role: Role;
  functionality: typeof Functionality = Functionality;
  userRoles: any[];
  isUserLoggedIn: boolean = false;
  users: any = [];
  roles: any[] = [];
  screenNavigation: ScreenNavigation = {}
  userName: string;
  userUrl: string;
  authUrl: string;

  private dateTimerInterval: number = 1000 * 60 * 5;
  private dateTimer: Observable<number>;

  actions = new AuthButtons();

  constructor(private readonly router: Router, private readonly ksaUserService: UsersService,
              private sharedService: SharedAuthService, private http: HttpClient,
              private readonly userCacheService: UserCacheService, private readonly userService: UserService) {
    this.userUrl = `${environment.API_URL}${API_KSA_CORE_V1}/user/`;
    this.authUrl = `${environment.API_URL}${API_KSA_CORE_V1}/userSsoDetails`;

    this.startDateCompare();
  }

  public get userValue() {
    this.sharedService.getCurrentUser().subscribe(user => this.userName = user?.emailId);
    return this.userName;
  }

  isAuthenticated(): boolean {
    return this.userCacheService.getLoginDataFromLocalStorage() != null;
  }

  isExpired(): boolean{
    return this.userCacheService.getExpirationFromLocalStorage();
  }

  private startDateCompare() {
    this.dateTimer = timer(this.dateTimerInterval); // compare every 5 minutes
    this.dateTimer.subscribe(n => {
      if (localStorage.identity) {
        if(this.isExpired()){
          this.logout();
        }
      }
      this.startDateCompare();
    });
  }

  async requestUserDetails(): Promise<boolean> {
    return await this.http.get(`${this.authUrl}`).toPromise().then((response: User) => {
      if (response) {
        this.setUserDataInLocalStorage(response);
        this.userService.setUser(response)
        return true;
      }else{
        return false;
      }
    });
  }

  setUserDataInLocalStorage(data: User): void {
    this.userCacheService.saveUserDataToLocalStorage(data);
  }

  logout() {
    this.userCacheService.removeUserDataFromLocalStorage();
    window.location.replace(`${environment.API_URL}` + "/logout");
  }

}
