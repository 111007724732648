import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";
import {RoleProfile} from '../models/role-profile.model';

@Injectable({providedIn: 'root'})
export class RoleProfileTabularViewService {

  private roleProfileTabularViewUrl: string;

  constructor(private http: HttpClient) {
    this.roleProfileTabularViewUrl = `${environment.API_URL}${API_KSA_CORE_V1}/roleprofile`;
  }

  getRoleProfilesTabularViewData(surveyTemplateConfigVersionId: number): Observable<RoleProfile[]> {
    return this.http.get<RoleProfile[]>(`${this.roleProfileTabularViewUrl}/survey-template-configs/${surveyTemplateConfigVersionId}`);
  }

  saveAllRoleProfiles(surveyTemplateConfigVersionId: number, roleProfiles): Observable<RoleProfile[]> {
    return this.http.put<RoleProfile[]>(`${this.roleProfileTabularViewUrl}/survey-template-configs/${surveyTemplateConfigVersionId}`, roleProfiles);
  }
}
