/**********************************************************************
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 **********************************************************************/

export enum NotificationDialogActionType {

   /**
    * Represents ok action.
    */
   OK = "ok",

   /**
    * Represents yes action.
    */
   YES = "yes",

   /**
    * Represents no action.
    */
   NO = "no",

   /**
    * Represents close action.
    */
   CLOSE = "close",

   /**
    * Represents cancel action.
    */
   CANCEL = "cancel",
}
