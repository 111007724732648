<clr-main-container>
  <div class="content-container">
    <main class="content-area">
      <!-- You can put an optional element here -->
      <clr-datagrid class="original-response-datagrid" (clrDgRefresh)="refreshDatagrid($event)">
        <ng-container *ngFor="let column of responseColumnSettings; let i = index" >
          <clr-dg-column [clrDgField]="column.responseColumnDisplay.questionName">
            <ng-template clrDgHideableColumn [(clrDgHidden)]="responseColumnSettings[i].isHideable">
              {{column.responseColumnDisplay.questionName}}
            </ng-template>
          </clr-dg-column>
        </ng-container>
        <clr-dg-row *ngFor="let response of responseData; let i = index">
          <clr-dg-cell *ngFor="let col of responseData[i].responseColumnWithParticipantDTOS">{{col.answerText}}
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgTotalItems]="total" [(clrDgPage)]="page">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Responses per page</clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} responses
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </main>
  </div>
</clr-main-container>
