/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Functionality} from '../../../enums/functionality.enum'
import {Router} from "@angular/router";
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent implements OnInit {

  hasAnyPermissions: boolean;

  constructor(private readonly router: Router, private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasAnyPermissions = this.userService.hasAnyPermission([Functionality.manageRoleProfileGroup, Functionality.manageRoleProfile, Functionality.manageSkill]);
  }

  getRouterLink(link: string): string {
    return this.router.url === '/' ? ('Home/' + link) : link;
  }

  get functionality(): typeof Functionality {
    return Functionality;
  }

}
