<h4>{{masterSurveyTranslation?.name}}</h4>

<clr-alert clrAlertType="danger" *ngIf="errorMsg">
  <clr-alert-item>{{errorMsg}}</clr-alert-item>
</clr-alert>

<div class="translate-survey-info-wrapper">
  <div>
    <strong>{{publishedQuestions}}</strong> of <strong>{{totalQuestions}}</strong> Questions Edited
  </div>
  <div>
    <button class="btn btn-primary btn-sm" routerLink="/Home/ManageMasterSurvey">
      {{'admin.backBtn' | translate}}</button>
    <button class="btn btn-success btn-sm" (click)="publishMasterSurvey()"
            *hasAccess="functionality.manageSurveyTemplate" [id]="btnId.btnEditTranslationPublish"
            [disabled]="publishedQuestions != totalQuestions || masterSurveyTranslation?.status !== localizationStatusEnum.DRAFT">
      {{'admin.publishBtn' | translate}}
    </button>
  </div>
</div>

<ng-container *ngIf="masterSurveyTranslation?.sections && masterSurveyTranslation?.sections.length > 0">
  <clr-accordion clrAccordionMultiPanel="true">
    <ng-container *ngFor="let s of masterSurveyTranslation.sections">
      <clr-accordion-panel class="translate-survey-clr-accordion-panel">
        <clr-accordion-title>
          <span *ngIf="isSectionPublished(s)">
            <cds-icon solid="true" class="color-green" shape="check-circle"></cds-icon>
          </span>
          {{s.name}}
        </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>


          <clr-accordion clrAccordionMultiPanel="true">
            <ng-container *ngFor="let question of s.questions">
              <clr-accordion-panel class="translate-survey-clr-accordion-panel"
                                   (clrAccordionPanelOpenChange)="onQuestionAccordionOpenEvent(question)">
                <clr-accordion-title>
                  <span *ngIf="question.status === localizationStatusEnum.PUBLISHED">
                    <cds-icon solid="true" class="color-green" shape="check-circle"></cds-icon>
                  </span>
                  <span *ngIf="question.questionType !== questionTypeEnum.Description">{{question.enText}}</span>
                </clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <app-translate-master-survey-question [question]="question"
                                                        [masterSurveyLocalizationStatus]="masterSurveyTranslation.status"
                                                        (onSave)="updateMasterSurveyQuestion($event)">
                  </app-translate-master-survey-question>
                </clr-accordion-content>
              </clr-accordion-panel>
            </ng-container>
          </clr-accordion>


        </clr-accordion-content>
      </clr-accordion-panel>
    </ng-container>
  </clr-accordion>
</ng-container>
