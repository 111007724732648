/*
 *  *********************************************************************
 *  * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Injectable} from "@angular/core";
import {User} from "../models/auth-user.model";
import {Functionality} from "../enums/functionality.enum";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: User;
  private readonly IDENTITY_KEY: string = 'identity';

  constructor() {
    this.user = JSON.parse(localStorage.getItem(this.IDENTITY_KEY));
  }

  getUser(): User {
    return this.user;
  }

  setUser(user: User): void {
    this.user = user;
  }

  hasWritePermission(functionality: Functionality): boolean {
    const permissionArr = this.user?.permissionMap ? Object.values(this.user?.permissionMap) : [];
    return permissionArr.some((x: any) => x.screen.toUpperCase() === functionality.toUpperCase() && x.write);
  }

  hasPermission(functionality: Functionality): boolean {
    const permissionArr = this.user?.permissionMap ? Object.values(this.user?.permissionMap) : [];
    return permissionArr.some((x: any) => x.screen.toUpperCase() === functionality.toUpperCase() && (x.write || x.read));
  }

  hasAnyPermission(functionalities: Functionality[]): boolean {
    for (const functionality of functionalities) {
      if (this.hasPermission(functionality)) {
        return true;
      }
    }
    return false;
  }

}
