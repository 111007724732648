/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClarityModule} from '@clr/angular';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {ClipboardModule} from 'ngx-clipboard';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {FooterComponent} from './components/home/footer/footer.component';
import {HeaderComponent} from './components/home/header/header.component';
import {NavigationComponent} from './components/home/navigation/navigation.component';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';

import {KsaEngagementModule} from './components/ksa-engagement/ksa-engagement.module';
import {AnalysisReportModule} from './components/analysis-reports/analysis-report.module';
import {AdministrationModule} from './components/administration/administration.module';
import {FiltersModule} from './components/filters/filters.module';
// @ts-ignore
import {AgGridModule} from 'ag-grid-angular';

import {ColorPickerModule} from 'ngx-color-picker';
import {NgxSliderModule} from '@angular-slider/ngx-slider';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule, TranslateService, TranslateStore} from "@ngx-translate/core";
import {AuthService} from "./services/auth.service";
import {AnonymousComponent} from './components/participants/anonymous/anonymous.component';
import {DemoComponent} from './components/participants/demo/demo.component';
import {ParticipantComponent} from './components/participants/participant/participant.component';
import {SafePipeModule} from 'safe-pipe';
import {CdsModule} from '@cds/angular';
import {SurveyThanksComponent} from './components/participants/survey-thanks/survey-thanks.component';
import {DirectiveModule} from "./directives/directive.module";
import {UserResolve} from "./services/user-resolve.service";
import {NgApexchartsModule} from "ng-apexcharts";
import {AuthInterceptor} from "./services/authinterceptor";
import {GlobalErrorHandler} from "./common/global-error-handler.service";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {PipesModule} from "./pipes/pipes.module";
import {ClrNotificationDialogModule} from "./common/notification-dialog/clr-notification-dialog.module";

export function translationInitializerFactory(translate: TranslateService): () => Promise<any> {
  return () => new Promise<void>((resolve) => {
    translate.onLangChange.subscribe(() => resolve());
    translate.setDefaultLang('en-us'); // Set your default language here
    translate.use('en-us');
  });
}

export function translateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    DashboardComponent,
    HomeComponent,
    AnonymousComponent,
    DemoComponent,
    ParticipantComponent,
    SurveyThanksComponent
  ],
  imports: [
    DirectiveModule,
    BrowserModule,
    ClarityModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    BreadcrumbModule,
    FormsModule,
    ReactiveFormsModule,
    FiltersModule,
    NgApexchartsModule,
    KsaEngagementModule,
    AnalysisReportModule,
    AdministrationModule,
    SafePipeModule,
    ClipboardModule,
    CdsModule,
    ColorPickerModule,
    ClrNotificationDialogModule,
    NgxSliderModule,
    PipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoader,
        deps: [HttpClient]
      }
    }),
    AgGridModule.withComponents([]),
    LoggerModule.forRoot({level: NgxLoggerLevel.INFO})
  ],
  providers: [
    AuthService,
    UserResolve,
    TranslateStore,
    {
      provide: APP_INITIALIZER,
      useFactory: translationInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
