/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from "../../environments/environment";
import {ColorCode} from "../models/color-data.model";
import {DataGridTypeEnum} from "../enums/data-grid-type.enum";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ColorCodeService {
  private readonly colorCodeUrl: string;

  constructor(private http: HttpClient) {
    this.colorCodeUrl = `${environment.API_URL}${API_KSA_CORE_V1}/color`;
  }

  setAsDefaultColor(dataGridType: DataGridTypeEnum, colorCode: (ColorCode)[]): Observable<any> {
    return this.http.post(`${this.colorCodeUrl}/preference/${dataGridType}`, colorCode);
  }

  getAllColorCodesByKsaIdAndType(id: number, colorType: DataGridTypeEnum): Observable<any[]> {
    return this.http.get<any[]>(`${this.colorCodeUrl}/ksa/${id}/${colorType}`);
  }

  addColorCodesMatrix(colorCodes: Array<ColorCode>): Observable<any> {
    return this.http.post(`${this.colorCodeUrl}`, colorCodes);
  }

  deleteColorCodeMatrix(id: number): Observable<any> {
    return this.http.delete(`${this.colorCodeUrl}/${id}`);
  }

  updateColorCodeMatrix(colorCode: ColorCode): Observable<any> {
    return this.http.put(`${this.colorCodeUrl}`, colorCode);
  }

  getColorCodePreferenceByType(type: DataGridTypeEnum): Observable<boolean> {
    return this.http.get<boolean>(`${this.colorCodeUrl}/preference/${type}`);
  }

  setLoadDefaultColorByColorDto(ksaId: number, dataGridType: DataGridTypeEnum): Observable<any> {
    return this.http.put(`${this.colorCodeUrl}/preference/default/`, {
      ksaId: ksaId,
      dataGridType: dataGridType
    });
  }

  getAllColorCodesByKsaIdAndTypeOrDefault(ksaId: number, dataGridType: DataGridTypeEnum): Observable<any> {
    return this.getAllColorCodesByKsaIdAndType(ksaId, dataGridType).pipe(
      switchMap(result => result?.length > 0 ? of(result) : this.setLoadDefaultColorByColorDto(ksaId, dataGridType))
    );
  }
}
