/*
 *  *********************************************************************
 *  * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Pipe, PipeTransform} from '@angular/core';
import {areLocaleEnumsEqual, LocalizationLocaleEnum} from "../../enums/localization-locale.enum";

@Pipe({
  name: 'displayLocalizedQuestionText'
})
export class DisplayLocalizedQuestionTextPipe implements PipeTransform {

  transform(question: any, language: string | LocalizationLocaleEnum): string {
    if (areLocaleEnumsEqual(language, LocalizationLocaleEnum.ENGLISH)) {
      if (question?.question?.questionText) {
        return question?.question?.questionText;
      }

      return question.questionText;
    }

    if (question?.ksaQuestionLocalizations) {
      return question?.ksaQuestionLocalizations?.filter(kql => areLocaleEnumsEqual(kql.locale, language))[0].text;
    }

    return question.question?.questionLocalizations?.filter(kql => areLocaleEnumsEqual(kql.locale, language))[0].text;
  }

}
