/*******************************************************************
 Copyright 2022 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/
export enum AnalyticsEvents {
  CREATED_KSA = "Created KSA",
  UPDATED_KSA = "Updated KSA",
  DELETED_KSA = "Deleted KSA",
  ACTIVATED_KSA = 'Activated KSA',
  CLOSED_KSA = 'Closed KSA',

  CREATED_SURVEY_TEMPLATE = 'Created Survey Template',
  PUBLISHED_SURVEY_TEMPLATE = 'Published Survey Template',
  ARCHIVED_SURVEY_TEMPLATE = 'Archived Survey Template',

  DOWNLOADED_KSA_REPORT = 'Downloaded KSA Report',

  CREATED_TEAM = "Created team",
  DELETED_TEAM = "Deleted team",
}
