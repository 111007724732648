/*******************************************************************
 Copyright 2022 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {MasterSurveyTranslation} from "../models/master-survey-translation.model";
import {LocalizationLocaleEnum} from "../enums/localization-locale.enum";
import {
  UpdateTranslationRequest
} from "../components/administration/mng-master-survey/translate-master-survey/translate-master-survey.component";
import {LocalizationStatusEnum} from "../enums/localization-status.enum";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class MasterSurveyTranslationService {

  private readonly translationsApiURL = `${API_KSA_CORE_V1}/translations/`;

  constructor(private readonly http: HttpClient) {
  }

  getSupportedTranslationLanguages(): Observable<LocalizationLocaleEnum[]> {
    return this.http.get<LocalizationLocaleEnum[]>(`${environment.API_URL}${this.translationsApiURL}supported-languages`)
  }

  addTranslation(masterSurveyId: number, language: LocalizationLocaleEnum): Observable<LocalizationStatusEnum> {
    const params = new HttpParams()
      .set('masterSurveyId', masterSurveyId.toString())
      .set('language', language.toString());
    return this.http.post<LocalizationStatusEnum>(`${environment.API_URL}${this.translationsApiURL}translate`, {}, {params})
  }

  getMasterSurveyTranslations(masterSurveyId: number, language: LocalizationLocaleEnum): Observable<MasterSurveyTranslation> {
    return this.http.get<MasterSurveyTranslation>(`${environment.API_URL}${this.translationsApiURL}${masterSurveyId}/${language}`)
  }

  updateMasterSurveyTranslation(request: Array<UpdateTranslationRequest>): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}${this.translationsApiURL}`, request);
  }

  updateMasterSurveyStatus(masterSurveyId: number, language: LocalizationLocaleEnum, status: LocalizationStatusEnum): Observable<any> {
    return this.http.put<any>(`${environment.API_URL}${this.translationsApiURL}publish/survey/${masterSurveyId}/${language}/${status}`, []);
  }

  publishMasterSurveyQuestion(questionId: number, language: LocalizationLocaleEnum): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}${this.translationsApiURL}publish/question/${questionId}/${language}`, []);
  }

  getTranslatedLanguages(masterSurveyId: number, status: LocalizationStatusEnum): Observable<string[]> {
    return this.http.get<string[]>(`${environment.API_URL}${this.translationsApiURL}translated-languages/${masterSurveyId}/${status}`)
  }

}
