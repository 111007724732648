<div class="clr-row btn-4" *ngIf="!surveyCompleted">
  <div class="clr-col-1"></div>
  <div class="preview-container clr-col-10" id="questionContainer">
    <div class="logo"></div>
    <ng-container *ngFor="let question of questionsToDisplay">
      <div *ngIf="question.isDisplay" class="question-container">
        <div *ngIf="question?.question?.sectionImage" class="clr-row">
          <div class="clr-col-12 image-bar">
            <span class="img-container">
              <img [src]="'data:image/jpeg;base64,'+question?.question?.sectionImage" alt="Question Image">
            </span>
          </div>
        </div>
        <div [ngSwitch]="question?.question?.questionType?.id">
          <div *ngIf="question?.question?.image" class="clr-row">
            <div class="clr-col-12 image-bar">
              <span class="img-container">
                <img [src]="'data:image/jpeg;base64,'+question?.question?.image?.imageData" alt="Question Image">
              </span>
            </div>
          </div>
          <div class="all-question">
            <div
              *ngIf="(question?.question?.questionType?.id!=questionTypeEnum.Description) && (question?.question?.questionType?.id!=questionTypeEnum.RoleProfile)">
              <div class="clr-col-lg-12 clr-col-12">
                <h5 class="question-Block">
                  {{question?.question?.questionText.trim()}}<span class="mandatory-span"  *ngIf="question.isMandatory">{{question.isMandatory ? '*' : "''"}}</span>
                  </h5>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="questionTypeEnum.FreeText">
            <div *ngIf="!question?.question?.answerProperty.isSingleLine" class="clr-row">
              <div class="clr-col-lg-12 clr-col-12">
                <div class="right">
                  <div class="content">
                    <clr-textarea-container>
                      <textarea (keyup)="onAnswerInputFreeText(question)"
                                [(ngModel)]="question.question.answerValuesText" autocomplete="off" class="text-Block"
                                clrTextarea cols="135" maxLength="1000" name="freeMulti{{question.index}}"
                                rows="4"></textarea>
                    </clr-textarea-container>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="question?.question?.answerProperty.isSingleLine" class="clr-row">
              <div class="clr-col-lg-12 clr-col-12">
                <div class="right">
                  <div class="content">
                    <clr-input-container>
                      <input (keyup)="onAnswerInputFreeText(question)" [(ngModel)]="question.question.answerValuesText"
                             autocomplete="off" class="text-Block" clrInput name="freeSingle{{question.index}}"
                             [pattern]="freeTextValidationService.getFreeTextPattern(question)" [required]="question.isMandatory"/>
                      <clr-control-error>
                        {{'surveyTemplate.questionSheet.invalidInput' | translate}}
                      </clr-control-error>
                    </clr-input-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="questionTypeEnum.RadioButton" class="all-question">
            <div class="clr-row">
              <div class="clr-col-lg-12 clr-col-12">
                <div *ngIf="question?.question?.answerProperty.isVertical">
                  <ng-container>
                    <form #radioForm="ngForm" clrForm autocomplete="off">
                      <clr-radio-wrapper *ngFor="let answer of question?.question?.answerLabels" class="radio-toolbar">
                        <input (click)="onAnswerInputRadio(question,answer)" [checked]="answer.isChecked" clrRadio
                               name="{{question?.question?.questionText}}{{answer.labelText}}" type="radio" value="answer.isChecked"/>
                        <label>{{answer.labelText}}</label>
                      </clr-radio-wrapper>
                    </form>
                  </ng-container>
                </div>
                <div *ngIf="!question?.question?.answerProperty.isVertical">
                  <form clrForm autocomplete="off">
                    <table aria-describedby="Radio Button Table" class="clr-col-12 flex pl-0">
                      <tr>
                        <th scope="row" class="th-display"></th>
                        <td *ngFor="let answer of question?.question?.answerLabels; first as isFirst; last as isLast"
                            class="radio-toolbar">
                          <clr-radio-wrapper>
                            <input (click)="onAnswerInputRadio(question,answer)" [checked]="answer.isChecked" clrRadio
                                   name="{{question?.question?.questionText}}{{answer.labelText}}" type="radio"
                                   value="answer.isChecked"/>
                            <label class="horizontal-radio"
                                   [ngClass]="{'left-radius' : isFirst, 'right-radius' : isLast}">{{answer.labelText}}</label>
                          </clr-radio-wrapper>
                        </td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="questionTypeEnum.MultipleChoice" class="all-question">
            <div class="clr-row">
              <div class="clr-col-lg-12 clr-col-12">
                <ng-container>
                  <form clrForm autocomplete="off">
                    <clr-checkbox-wrapper *ngFor="let answer of question?.question?.answerLabels"
                                          class="checkbox-toolbar">
                      <input (click)="onAnswerInputMultiple(question,answer)" [checked]="answer.isChecked" clrCheckbox
                             name="{{question?.question?.questionText}}{{answer.labelText}}" type="checkbox" value="answer.isChecked"/>
                      <label>{{answer.labelText}}</label>
                    </clr-checkbox-wrapper>
                  </form>
                </ng-container>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="questionTypeEnum.Scale" class="all-question ">
            <form clrForm autocomplete="off">
              <table aria-describedby="Scale Answer Table" class="clr-col-12">
                <tr class="clr-row label-scale">
                  <th scope="row" class="th-display"></th>
                  <td *ngFor="let item of question.question.answerLabels;let i = index" class="clr-col">
                    <strong>{{item.labelText}}</strong>
                  </td>
                </tr>
              </table>
              <table aria-describedby="Scale Options Table" class="clr-col-12 flex">
                <tr>
                  <th scope="row" class="th-display"></th>
                  <td *ngFor="let item of question.question.optionsScale; first as isFirst; last as isLast"
                      class="radio-toolbar">
                    <clr-radio-wrapper>
                      <input (click)="onAnswerInputScale(question, item.optionLabel)"
                             [checked]="item.optionLabel === question.question.answerValuesText" clrRadio
                             name="{{question?.question?.questionText}}{{item.optionLabel}}" type="radio"
                             value="item.optionLabel"/>
                      <label class="horizontal-radio"
                             [ngClass]="{'left-radius' : isFirst, 'right-radius' : isLast}">{{item.optionLabel}}</label>
                    </clr-radio-wrapper>
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <div *ngSwitchCase="questionTypeEnum.Description" class="all-question">
            <div class="clr-row">
              <div class="clr-col-lg-12 clr-col-12 editor-view">
                <div [innerHTML]="question?.question.questionText | safe: 'html'"></div>
              </div>
              <div class="clr-col-lg-12 clr-col-12">
              </div>
            </div>
          </div>
          <div *ngSwitchCase="questionTypeEnum.MatrixTable" class="all-question ">
            <table aria-describedby="Matrix Radio Table" *ngIf="question?.question?.answerProperty.isRadio"
                   class="clr-col-12 ml-12">
              <tr>
                <td class="w-120"></td>
                <div>
                  <th scope="col" *ngFor=" let columnLabel of question?.question?.matrixCols"
                      class="th-center-align font-size-14 w-100">
                    {{columnLabel.labelText}}</th>
                </div>
              </tr>
              <tr *ngFor="let rowLabel of question?.question?.matrixRows;let rowIndex = index">
                <th scope="row" class="th-left-align font-size-14">{{rowLabel.labelText}}</th>
                <div class="mt-7 ml-neg-10">
                <td *ngFor="let colMatrix of question?.question?.matrixCols;let columnIndex = index"
                    class="matrix-cell-row">
                  <clr-radio-wrapper>
                    <input
                      (click)="onAnswerRadioInputMatrix(question, rowLabel.answerLabelSequenceNo, colMatrix.answerLabelSequenceNo , $event.target.checked)"
                      [checked]="question?.question?.answerControlMatrixList?.length > 0 ? question?.question?.answerControlMatrixList[rowIndex][columnIndex]?.isChecked : null"
                      clrRadio name="options{{rowIndex}}{{rowLabel.id}}" type="radio"/>
                  </clr-radio-wrapper>
                </td>
                </div>
              </tr>
            </table>
            <table aria-describedby="Matrix Checkbox Table" *ngIf="!question?.question?.answerProperty.isRadio"
                   class="clr-col-12 ml-12">
              <tr>
                <td class="w-120"></td>
                <div>
                  <th scope="col" *ngFor=" let columnLabel of question?.question?.matrixCols"
                      class="th-center-align font-size-14 w-100">
                    {{columnLabel.labelText}}</th>
                </div>
              </tr>
              <tr *ngFor="let rowLabel of question?.question?.matrixRows;let rowIndex = index">
                <th scope="row" class="th-left-align font-size-14">{{rowLabel.labelText}}</th>
                <div class="mt-7">
                <td *ngFor="let colMatrix of question?.question?.matrixCols; let columnIndex = index"
                    class="matrix-cell-row">
                  <clr-checkbox-wrapper>
                    <input
                      (click)="onAnswerCheckInputMatrix(question, rowLabel.answerLabelSequenceNo, colMatrix.answerLabelSequenceNo, $event.target.checked)"
                      [checked]="question?.question?.answerControlMatrixList?.length > 0 ? question?.question?.answerControlMatrixList[rowIndex][columnIndex]?.isChecked : null"
                      clrCheckbox name="options{{columnIndex}}" type="checkbox">
                  </clr-checkbox-wrapper>
                </td>
                </div>
              </tr>
            </table>
          </div>
          <div *ngSwitchCase="questionTypeEnum.RoleProfile" class="all-question ">
            <div class="clr-row">
              <div class="clr-col-lg-12 clr-col-12">
                <h5 class="question-Block"> {{'surveyTemplate.questionSheet.selectRoleProfile'|translate}}<span *ngIf="question.isMandatory" class="mandatory-span">*</span></h5>
                <br>
                <clr-select-container>
                  <select clrSelect>
                    <option disabled selected value="">{{'surveyTemplate.questionSheet.selectRoleProfile'|translate}}
                    </option>
                  </select>
                </clr-select-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="image-bar clr-row">
      <div class="clr-col-sm-8 clr-col-md-1 clr-col-lg-1 pre-btn-container">
        <div class="pre-btn">
          <button (click)="onDisplayPreList()" aria-label="settings" class="btn btn-icon btn-primary previous-button"
                  [disabled]="firstIndex==this.questionsToDisplay[0].index"
                  type="button">
            <clr-icon id="buttonBack" shape="arrow"></clr-icon>
          </button>
        </div>
      </div>
      <div id="custom-progress-bar" class="clr-col-md-6 clr-col-lg-6 clr-col-sm-8">
        <clr-progress-bar [clrValue]="progressBarCount" [clrSuccess]="true" clrMax="100"></clr-progress-bar>
        <span>{{progressBarCount}}%</span>
      </div>
      <div class="clr-col-sm-8 clr-col-md-1 clr-col-lg-1">
        <div *ngIf="showNextButton && !showSubmitButton" class="next-btn">
          <button (click)="onDisplayNextList(nextIndex)" aria-label="settings"
                  class="btn btn-icon btn-primary next-button" type="button" [disabled]="unansweredQuestionFound">
            <clr-icon id="buttonNext" shape="arrow"></clr-icon>
          </button>
        </div>
        <div>
          <button *ngIf="showSubmitButton && !showNextButton" aria-label="settings" [disabled]="unansweredQuestionFound"
                  class="btn btn-primary submit-survey-btn" type="button" (click)="surveyCompleted = true">
            {{"ksaEngagement.emailManagement.participantKSA.participateKSA.submitBtn" | translate}}
          </button>
        </div>
      </div>
      <div class="clr-col-2"></div>
    </div>
  </div>
  <div class="clr-col-1">
    <button (click)="onShowTable()" *ngIf="surveyDetails?.status=='Published' ||
    surveyDetails?.status=='Archived'" class="btn btn-sm btn-primary btn-show-master-survey" type="button">
      {{'emailTemplate.backBtn' | translate}}
    </button>
  </div>
</div>

<div  *ngIf="surveyCompleted">
  <div class="thanks-heading">
    <h1>{{"ksaEngagement.emailManagement.participantKSA.participateKSA.thankYou" | translate}}</h1>
    <h2>...............................................................</h2>
  </div>
  <div class="thanks-body">
    <h4>
      {{"ksaEngagement.emailManagement.participantKSA.participateKSA.surveyThanksMessage" | translate}}
    </h4>
  </div>
</div>
