import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html'
})
export class StatusFilterComponent {
  @Input() statusOptions: any = [];
  options: string = "";
  @Output() statusFilter = new EventEmitter<any>();

  show() {
    if (this.options === "None")
      this.options = "";
    this.statusFilter.emit(this.options);
  }
}
