/********************************************************************** *
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
 **********************************************************************/

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {RoleProfileService} from '../../../services/role-profile.service';
import {SkillsService} from '../../../services/skills.service';
import {Functionality} from '../../../enums/functionality.enum';
import {ButtonId} from '../../../enums/buttonId.enum';
import {RoleProfileTabularViewService} from '../../../services/role-profile-tabular-view.service';
import {finalize} from 'rxjs/operators';
import {hideSpinner, showSpinner} from '../../../common/spinner';
import {TranslateService} from '@ngx-translate/core';
import {SurveyTemplateConfigVersion} from '../../../models/config-version.model';
import {Status} from '../../../enums/status.enum';
import {
  SkillRoleProfileManagementService
} from '../tab-mng-skills-skill-order-role-profiles-and-role-profile-groups/skill-role-profile-management.service';
import {RoleProfile, RoleProfileSkillMap} from '../../../models/role-profile.model';
import {forkJoin, iif, of} from 'rxjs';
import {cloneDeep} from 'lodash';
import {Skill} from '../../../models/skill.model';

@Component({
  selector: 'app-mng-roles-profiles',
  templateUrl: './mng-roles-profiles.component.html',
  styleUrls: ['./mng-roles-profiles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MngRolesProfilesComponent implements OnInit {

  @Input() surveyTemplateConfigVersion: SurveyTemplateConfigVersion;

  isAdd = false;
  isEdit = false;
  isClone = false;
  selectedRoleProfile: RoleProfile;

  showDeletePopUp = false;
  errorMsg = '';
  deleteId: number;


  functionality: typeof Functionality = Functionality;
  statusEnum: typeof Status = Status;
  btnId: typeof ButtonId = ButtonId;
  showGridView = false;
  skills: Skill[];
  editRoleProfileForGrid: RoleProfile;
  editAll = false;
  editRoleProfileErrorMessage = false;
  editRoleProfileSuccessMessage = false;
  errorMessageGridSave = '';
  roleProfileList: RoleProfile[] = [];
  roleProfileGridView: RoleProfile[] = [];

  constructor(private readonly roleProfileService: RoleProfileService,
              private readonly skillService: SkillsService,
              private readonly roleProfileTabularViewService: RoleProfileTabularViewService,
              private readonly translate: TranslateService,
              public skillRoleProfileManagementService: SkillRoleProfileManagementService) {
  }

  ngOnInit(): void {
    this.loadSkillsAndRoleProfiles();
  }

  onDeleteRoleProfile(): void {
    showSpinner(this.translate.instant('spinnerLabels.common.loading'));
    this.roleProfileService.deleteRoleProfile(this.deleteId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.loadSkillsAndRoleProfiles();
          this.showDeletePopUp = false;
          this.deleteId = 0;
          this.errorMsg = '';
        },
        error => this.errorMsg = error.error.message);
  }

  onShowDeletePopUp(id: any): void {
    this.deleteId = id;
    this.showDeletePopUp = true;
  }

  onShowClonePopUp(roleProfile: RoleProfile): void {
    const toClone = cloneDeep(roleProfile);
    this.filterOutRoleProfileSkillMapsWithoutThreshold([toClone]);
    this.selectedRoleProfile = toClone;
    this.isClone = true;
  }

  resetError(): void {
    this.errorMsg = '';
  }

  toggleGridView(): void {
    this.showGridView = !this.showGridView;
    this.reset();
  }

  onSave(roleProfile?: RoleProfile): void {
    this.filterOutRoleProfileSkillMapsWithoutThreshold(roleProfile ? [roleProfile] : this.roleProfileGridView);
    const update$ = roleProfile ?
      this.roleProfileService.updateRoleProfile(roleProfile) :
      this.roleProfileTabularViewService.saveAllRoleProfiles(this.surveyTemplateConfigVersion.id, this.roleProfileGridView);

    showSpinner(this.translate.instant('spinnerLabels.common.saving'));

    update$
      .pipe(finalize(hideSpinner))
      .subscribe(data => {
          this.editRoleProfileSuccessMessage = true;
          this.loadSkillsAndRoleProfiles();
          this.reset();
          setTimeout(() => {
            this.editRoleProfileSuccessMessage = false;
          }, 3000);
        },
        error => {
          this.errorMessageGridSave = error.error.message;
          this.editRoleProfileErrorMessage = true;
        });

  }

  private filterOutRoleProfileSkillMapsWithoutThreshold(roleProfileArray: RoleProfile[]): void {
    roleProfileArray.forEach(rp => rp.roleProfileSkillMaps = rp.roleProfileSkillMaps.filter(e => e.threshold > 0));
  }

  onEditRoleProfileGridRow(roleProfile): void {
    this.reset();
    this.editRoleProfileForGrid = roleProfile;
  }

  onCancelEditingForRow(): void {
    this.reset();
    this.loadSkillsAndRoleProfiles();
  }

  onEditAll(): void {
    this.reset();
    this.editAll = true;
  }

  onCancelEditAll(): void {
    this.reset();
    this.loadSkillsAndRoleProfiles();
  }

  onSaveModalClose(isSaved: boolean): void {
    this.isAdd = false;
    this.isEdit = false;
    this.isClone = false;
    this.selectedRoleProfile = null;
    if (isSaved) {
      this.loadSkillsAndRoleProfiles();
    }
  }

  reset(): void {
    this.editRoleProfileForGrid = null;
    this.editAll = false;
    this.errorMsg = '';
    this.errorMessageGridSave = '';
  }

  private loadSkillsAndRoleProfiles(): void {
    showSpinner(this.translate.instant('spinnerLabels.common.loading'));
    forkJoin({
      skills: iif(() => !!this.skills,
        of(this.skills),
        this.skillService.getSkillsByConfigVersionIdAndStatus(this.surveyTemplateConfigVersion.id, Status.Active)
      ),
      roleProfiles: this.roleProfileTabularViewService.getRoleProfilesTabularViewData(this.surveyTemplateConfigVersion.id),
    })
      .pipe(finalize(hideSpinner))
      .subscribe(({skills, roleProfiles}) => {
        this.skills = skills;
        this.roleProfileList = cloneDeep(roleProfiles);

        const orderMap = this.getActiveSkillOrderMap(this.skills);
        roleProfiles.forEach(rp => {
          this.addMissingActiveSkills(this.skills, rp.roleProfileSkillMaps);
          this.sortRoleProfileSkillMapByOrderMap(orderMap, rp.roleProfileSkillMaps);
        });
        this.roleProfileGridView = roleProfiles;
      });
  }

  private getActiveSkillOrderMap(skills: { skillName }[]): Map<string, number> {
    const orderMap: Map<string, number> = new Map();
    skills.forEach((value, index) => {
      orderMap.set(value.skillName, index);
    });

    return orderMap;
  }

  private sortRoleProfileSkillMapByOrderMap(orderMap: Map<string, number>, roleProfileSkillMaps: RoleProfileSkillMap[]): void {
    roleProfileSkillMaps.sort((a, b) => {
      const orderA = orderMap.get(a?.skill?.skillName);
      const orderB = orderMap.get(b?.skill?.skillName);

      // If name A is not in the first array, consider it "greater" than B.
      if (orderA === undefined) {
        return 1;
      }

      // If name B is not in the first array, consider it "less" than A.
      if (orderB === undefined) {
        return -1;
      }

      // Otherwise, compare the order of A and B in the first array.
      return orderA - orderB;
    });
  }

  private addMissingActiveSkills(skills: Skill[], roleProfileSkillMaps: RoleProfileSkillMap[]): void {
    for (const skill of skills) {
      if (!roleProfileSkillMaps.find(e => e.skill.id === skill.id)) {
        roleProfileSkillMaps.push({skill} as RoleProfileSkillMap);
      }
    }
  }
}
