/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/
import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {RoleProfileSet} from "../models/ksa-role-profile-set.model";
import {KsaRoleProfileGroup} from "../models/ksa-role-profile-group.model";
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({providedIn: 'root'})
export class KsaRoleProfileService {

  private ksaRoleProfileUrl: string;

  constructor(private http: HttpClient) {
    this.ksaRoleProfileUrl = `${environment.API_URL}${API_KSA_CORE_V1}/ksaRoleProfile/`;
  }

  getKsaJobProfileSet(ksaId, setName, from, to, sortby, sortorder): Observable<RoleProfileSet> {
    ksaId = encodeURIComponent(ksaId);
    setName = encodeURIComponent(setName);
    return this.http.get<RoleProfileSet>(`${this.ksaRoleProfileUrl}?masterKsa.id=${ksaId}&name=${setName}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`);
  }

  getKsaRoleProfileGroups(setId, roleProfileGroupName, from, to, sortby, sortorder): Observable<KsaRoleProfileGroup> {
    setId = encodeURIComponent(setId);
    roleProfileGroupName = encodeURIComponent(roleProfileGroupName);
    return this.http.get<KsaRoleProfileGroup>(`${this.ksaRoleProfileUrl}group/?ksaJobProfileSet.id=${setId}&roleProfileGroupName=${roleProfileGroupName}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`);
  }

  getKsaRoles(id): Observable<any> {
    return this.http.get(`${this.ksaRoleProfileUrl}set/` + id);
  }

  getRoleProfileSetsMetadata(id): Observable<any> {
    return this.http.get<any>(`${this.ksaRoleProfileUrl}?masterKsa.id=${id}`);
  }
  getImportedActiveKsa(): Observable<any[]> {
    return this.http.get<any[]>(`${this.ksaRoleProfileUrl}active/ksa/`);
  }
  getKsaRoleProfileSetById(id: number): Observable<any> {
    return this.http.get<any>(`${this.ksaRoleProfileUrl}ksa/${id}`);
  }

  getRoleProfilesByKsaAndSetId(ksaId: number, setId: number) {
    return this.http.get<any>(`${this.ksaRoleProfileUrl}all/${ksaId}/${setId}`);
  }

  createKsaRoleProfileSet(set): Observable<any> {
    return this.http.post(`${this.ksaRoleProfileUrl}`, set);
  }

  createKsaRoleProfileGroup(group): Observable<any> {
    return this.http.post(`${this.ksaRoleProfileUrl}group/`, group);
  }

  createKsaRoleProfile(role): Observable<any> {
    return this.http.post(`${this.ksaRoleProfileUrl}role/`, role);
  }

  updateKsaRoleRoleProfileSet(set): Observable<any> {
    return this.http.put(`${this.ksaRoleProfileUrl}`, set);
  }

  updateKsaRoleProfileGroup(group): Observable<any> {
    return this.http.put(`${this.ksaRoleProfileUrl}group/`, group);
  }

  updateKsaRoleProfile(role): Observable<any> {
    return this.http.put(`${this.ksaRoleProfileUrl}role/`, role);
  }

  deleteRoleProfileSet(id): Observable<any> {
    return this.http.delete(`${this.ksaRoleProfileUrl}${id}`);
  }

  deleteMultipleRoleProfileSets(id): Observable<any> {
    return this.http.delete(`${this.ksaRoleProfileUrl}multiple/${id}`);
  }

  deleteRoleProfileGroup(id): Observable<any> {
    return this.http.delete(`${this.ksaRoleProfileUrl}group/${id}`);
  }

  deleteMultipleRoleProfileGroups(id): Observable<any> {
    return this.http.delete(`${this.ksaRoleProfileUrl}group/multiple/${id}`);
  }

  deleteRoleProfile(id): Observable<any> {
    return this.http.delete(`${this.ksaRoleProfileUrl}role/${id}`);
  }

  deleteMultipleRoleProfiles(id): Observable<any> {
    return this.http.delete(`${this.ksaRoleProfileUrl}role/multiple/${id}`);
  }
}
