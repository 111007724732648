/***********************************************************************
 * Copyright 2019 VMware, Inc. All rights reserved. VMware Confidential
 **********************************************************************/

import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

/**
 * Pipe for transforming an HTML string into a SafeHtml instance.
 *
 * Usage:
 * <div [innerHtml]="html | safeHtml"></div>
 *
 * @author vananiev
 */
@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
   constructor(private readonly domSanitizer: DomSanitizer) {
   }

   transform(value: string): SafeHtml {
      return this.domSanitizer.bypassSecurityTrustHtml(value);
   }
}
