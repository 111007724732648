<div class="questionSettings">
  <div class="btn-group btn-primary btn-icon buttonUpDown">
    <clr-icon shape="angle-double" (click)="onChangeUpdateStatus();move.emit(-1)"
              [hidden]="isUpArrayHidden(questionData?.value?.sectionQuestionSequenceNo)" class="btn-sm up-down-arrow">
    </clr-icon>
    <clr-icon shape="angle-double" (click)="onChangeUpdateStatus();move.emit(1)"
              [hidden]="isDownArrayHidden(questionData?.value?.sectionQuestionSequenceNo)" class="btn-sm up-down-arrow"
              id="downArrow"></clr-icon>
  </div>
  <clr-dropdown [clrCloseMenuOnItemClick]="true" *ngIf="hasWritePermission">
    <button clrDropdownTrigger aria-label="Dropdown demo button">
      <clr-icon shape="ellipsis-vertical"></clr-icon>
    </button>
    <clr-dropdown-menu *clrIfOpen clrPosition="left-top">
      <div (click)="showDisplayLogicModal=true" [hidden]="isFirstQuestion()" aria-label="Dropdown header Display Logic"
           clrDropdownItem>
        {{'surveyTemplate.questionSheet.commonDLogic'|translate}}
      </div>
      <div (click)="showDeleteQuestionPopUp = true" aria-haspopup="true" aria-label="Dropdown header MapSkill"
           clrDropdownItem>
        {{'surveyTemplate.questionSheet.isDelete'|translate}}
      </div>
      <div (click)="isAddNoteTrue=true" aria-haspopup="true" aria-label="Dropdown header MapSkill" clrDropdownItem
      *ngIf="!questionData.controls.isImported.value">
        {{'surveyTemplate.questionSheet.isNote'|translate}}
      </div>
      <div (click)="onAddPageBreak()" [hidden]="isLastQuestion()" aria-haspopup="true"
        aria-label="Dropdown header MapSkill" clrDropdownItem>
        {{'surveyTemplate.questionSheet.isPageBreak'|translate}}
      </div>
      <div (click)="onEditButton()" *ngIf="isDisable" aria-haspopup="true" aria-label="Dropdown header Edit"
        clrDropdownItem>
        {{'surveyTemplate.questionSheet.isEdit'|translate}}
      </div>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>
<clr-accordion>
  <clr-accordion-panel [clrAccordionPanelOpen]="true">
    <clr-accordion-title class="clr-row">
      <div class="clr-col-12">
      </div>
    </clr-accordion-title>
    <clr-accordion-content>
      <div *ngIf="errorOnDisplayLogicItemDelete" class="alert alert-danger" id="alert-message" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
            </div>
            <span class="alert-text">
              {{'errorMsg.canNotDeleteQuestion'|translate}}  {{dependentQuestionDisplayLogic}}
            </span>
          </div>
        </div>
      </div>
      <form [formGroup]="questionData">
        <div class="clr-row">
          <div class="clr-col-lg-8 clr-col-8">
            <div class="QuestionBanners" draggable="false">
              <div *ngIf="showSkipMessage" class="skipCondition">
                <div class="DynamicChoicesBox LogicBox">
                  <table aria-describedby="Skip Logic Table">
                    <tbody>
                    <tr>
                      <th id="SkipTab" class="InfoBarSkipTab"></th>
                      <td class="SkipDesc">
                        <div class="SkipLogicHeading">
                          <h6>{{'surveyTemplate.questionSheet.skipTo'|translate}}</h6>
                        </div>
                        <div class="SkipLogicEditor">
                          {{'surveyTemplate.questionSheet.isEndOf'|translate}} {{skipLogicToShow.skipType}}
                          {{'surveyTemplate.questionSheet.ifIt'|translate}}{{skipLogicToShow.skipConditionName}}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="showDisplayMessage" class="displayCondition">
                <div class="DynamicChoicesBox LogicBox">
                  <table aria-describedby="Display Logic Table">
                    <tbody>
                    <tr>
                      <th id="DisplayTab" class="InfoBarDisplayTab"></th>
                      <td class="DisplayDesc">
                        <div class="DisplayLogicHeading">
                          <h6>{{'surveyTemplate.questionSheet.isDisplay'|translate}}</h6>
                        </div>
                        <div [ngSwitch]="displayLogicToShow.questionTypeId">
                          <div *ngSwitchCase="questionTypeEnum.FreeText" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                          {{displayLogicToShow.questionText}}
                          {{displayLogicToShow.skipConditionName}}
                        </div>
                          <div *ngSwitchCase="questionTypeEnum.RadioButton" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{displayLogicToShow.answerLabel}}
                            {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                            {{displayLogicToShow.questionText}}
                            {{displayLogicToShow.skipConditionName}} {{displayLogicToShow.displayValue}}
                          </div>
                          <div *ngSwitchCase="questionTypeEnum.MultipleChoice" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{displayLogicToShow.answerLabel}}
                            {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                            {{displayLogicToShow.questionText}}
                            {{displayLogicToShow.skipConditionName}} {{displayLogicToShow.displayValue}}
                          </div>
                          <div *ngSwitchCase="questionTypeEnum.Scale" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{displayLogicToShow?.questionText}}
                            {{displayLogicToShow?.skipConditionName}} {{displayLogicToShow.displayValue}}
                          </div>
                          <div *ngSwitchCase="questionTypeEnum.MatrixTable" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{displayLogicToShow.colAnswerLabel}}
                            {{displayLogicToShow.answerLabel}}
                            {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                            {{displayLogicToShow.questionText}}
                            {{displayLogicToShow.skipConditionName}}
                          </div>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div [hidden]="!this.isDisable">
              <div [innerHTML]="descriptionQn.content | safe: 'html'"> </div>
            </div>
            <div [hidden]="this.isDisable">
              <form clrForm formGroupName="question" #addForm="ngForm">
                <input [ngxSummernote]="config" #r [(ngModel)]="descriptionQn.content" formControlName='questionText'
                  id="summernote" type="text" name="name" (ngModelChange)="checkDescQuestion()">
              </form>
            </div>
            <div *ngIf="isAddNoteTrue || this.questionData.controls.questionNote?.value" class="clr-row note">
              <div class="clr-col-lg-11">
                <clr-input-container>
                  <input autocomplete="off" class="noteText" clrInput formControlName='questionNote' name="text"
                    placeholder="Enter Note Text!" (keyup)="onChangeUpdateStatus()"/>
                  <clr-control-error *clrIfError="'maxlength'" class="error">
                    {{'surveyTemplate.questionSheet.isMaxCharAllowed'|translate}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'pattern'" class="error">
                    {{'surveyTemplate.masterSurvey.surveyCharErrorMsg'|translate}}
                  </clr-control-error>
                </clr-input-container>
              </div>
            </div>
          </div>
          <div class="clr-col-lg-4 clr-col-12">
            <div class="skillBlock" *ngIf="skillToShow">
              <div class="card">
                <div class="card-header">
                  <h6>{{'surveyTemplate.questionSheet.isSKill'|translate}}<span>{{skillToShow}}</span></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
<div *ngIf="isAddPageBreak" draggable="true" class="PageBreak Draggable">
  <clr-icon (click)="onRemovePageBreak()" aria-label="warning" class="btn-sm btn-warning-outline minusIconPagebrak"
            shape="minus-circle">
  </clr-icon>
  <div class="PBInner">
    <div class="perforation">
      <span class="text"> {{'surveyTemplate.questionSheet.isPageBreak'|translate}}</span>
    </div>
  </div>
</div>
<clr-modal *ngIf="showDeleteQuestionPopUp" [(clrModalOpen)]="showDeleteQuestionPopUp">
  <h3 class="modal-title"> {{'surveyTemplate.questionSheet.isDeleteQuestion'|translate}}</h3>
  <div class="modal-body">
    {{'surveyTemplate.mngSurvey.unDeleteMsg'|translate}}
    <div *ngIf="errorOnDisplayLogicItemDelete" class="alert alert-danger" id="alert-message" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
            {{'errorMsg.canNotDeleteQuestion'|translate}}  {{dependentQuestionDisplayLogic}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="showDeleteQuestionPopUp = false"
      class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}</button>
    <button (click)="onRemoveQuestion()"
      class="btn btn-outline-warning btn-sm">{{'surveyTemplate.questionSheet.isDelete'|translate}}</button>
  </div>
</clr-modal>
<app-display-logic-pop-up *ngIf="showDisplayLogicModal" [showDisplayLogicModal]="showDisplayLogicModal"
  [questionData]="questionData" [sectionReference]="sectionReference" (showDisplayLogic)="showDisplayLogicModal=false"
  (onUpdateDisplay)=onUpdateDisplay($event)></app-display-logic-pop-up>
<app-map-skill-pop-up *ngIf="showMapSkillPopUp" [showMapSkillPopUp]="showMapSkillPopUp" [questionData]="questionData"
  [sectionReference]="sectionReference" (showMapSkill)="showMapSkillPopUp=false"></app-map-skill-pop-up>
