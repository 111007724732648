/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {PersonalData} from "../models/scorecard.model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class ScorecardsService {
  private readonly getScorecardsByKsaIdUrl: string;

  constructor(private readonly http: HttpClient) {
    this.getScorecardsByKsaIdUrl = `${environment.API_URL}${API_KSA_CORE_V1}/scorecards`;
  }

  getPersonalDataByKsaId(ksaId): Observable<PersonalData[]> {
    return this.http.get<PersonalData[]>(`${this.getScorecardsByKsaIdUrl}/ksa/${ksaId}/personalData`);
  }

  getScorecardsByKsaIdAndParticipantId(ksaId, participantId): Observable<any> {
    return this.http.get(`${this.getScorecardsByKsaIdUrl}/ksa/${ksaId}/participants/${participantId}`);
  }

  sendScorecards(ksaId: number, participantIds: number[], ccList: string[]): Observable<PersonalData[]> {
    return this.http.post<PersonalData[]>(`${this.getScorecardsByKsaIdUrl}/ksa/${ksaId}`, {
      participantIds,
      ccList
    });
  }

  downloadScorecards(ksaId: number, participantIds: number[]): Observable<HttpResponse<Blob>> {
    return this.http.post(`${this.getScorecardsByKsaIdUrl}/ksa/${ksaId}/zip`, participantIds, {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
