<div *ngIf="editRoleProfileSuccessMessage" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">{{'admin.roleProfilesAndGroups.roleProfilesGrid.roleProfilesSavedMessage' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="errorMessageGridSave !== ''" class="alert alert-danger" id="alert-message" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span class="alert-text">
        {{errorMessageGridSave}}
      </span>
    </div>
  </div>
</div>
<div class="button-container clr-row" *hasAccess="functionality.manageRoleProfile">
  <span class="clr-col-6">
    <button (click)="isAdd = true" class="btn btn-sm btn-primary" id="{{btnId.btnMngRoleProfileAdd}}"
            [disabled]="(editAll && showGridView) || editRoleProfileForGrid?.id"
            *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)">
      {{'admin.roleProfilesAndGroups.roleProfiles.addRoleProfileBtn' | translate}}
    </button>
    <button
      *ngIf="!editAll && showGridView && skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)"
      type="button" (click)="onEditAll()" class="btn btn-primary btn-sm">
      {{'analysisAndReports.reports.roleProfileReport.editAll'|translate}}</button>
    <button (click)="onSave()" *ngIf="editAll && showGridView" class="btn btn-primary btn-sm"
            type="button">
      {{'analysisAndReports.reports.roleProfileReport.saveAll'|translate}}</button>
    <button *ngIf="editAll && showGridView" type="button" (click)="onCancelEditAll()" class="btn btn-primary btn-sm">
      {{'analysisAndReports.reports.roleProfileReport.cancel'|translate}}</button>
  </span>
  <span class="clr-col-6" style="text-align: right;">
    <button class="btn btn-icon" *ngIf="!showGridView" (click)="toggleGridView()">
      <clr-icon *ngIf="!showGridView" class="grid-hover" shape="grid-view lg" size="25"></clr-icon>
    </button>
    <button class="btn btn-icon" [disabled]="editAll && showGridView" *ngIf="showGridView"
            (click)="toggleGridView()">
      <clr-icon *ngIf="showGridView" class="grid-hover" shape="details" size="25"></clr-icon>
    </button>
  </span>
</div>

<app-mng-roles-profiles-save-modal (closeModal)="onSaveModalClose($event)"
                                   *ngIf="isAdd || isClone || isEdit"
                                   [isAdd]="isAdd"
                                   [isClone]="isClone"
                                   [isEdit]="isEdit"
                                   [selectedRoleProfile]="selectedRoleProfile"
                                   [surveyTemplateConfigVersion]="surveyTemplateConfigVersion">
</app-mng-roles-profiles-save-modal>


<clr-modal (clrModalOpenChange)="resetError()" *ngIf="showDeletePopUp" [(clrModalOpen)]="showDeletePopUp">
  <h3 class="modal-title">{{'admin.roleProfilesAndGroups.roleProfiles.deleteRoleProfile' | translate}}?
    <clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true" clrAlertType="danger">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'admin.roleProfilesAndGroups.roleProfiles.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeletePopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteRoleProfile()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
        translate}}</button>
    </div>
  </clr-modal>

<div *ngIf="!showGridView">
  <clr-datagrid class="parent-datagrid">
    <clr-dg-column *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)"
                   class="action-cell"></clr-dg-column>
    <clr-dg-column [clrDgField]="'name'">{{'admin.roleProfilesAndGroups.roleProfiles.roleProfile' | translate}}
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'description'">{{'admin.roleProfilesAndGroups.roleProfiles.description' | translate}}
    </clr-dg-column>
    <clr-dg-column>
      {{'admin.roleProfilesAndGroups.roleProfiles.skills' | translate}}
    </clr-dg-column>

    <clr-dg-row *clrDgItems="let roleProfile of roleProfileList" [clrDgItem]="roleProfile">
      <clr-dg-cell *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)"
                   class="action-cell-header">
        <clr-dg-action-overflow *hasAccess="functionality.manageRoleProfile">
          <button (click)="selectedRoleProfile = roleProfile; isEdit = true;" class="action-item"
                  id="{{btnId.btnMngRoleProfileEdit}}"
                  type="button">{{'admin.editBtn' |
            translate}}</button>
          <button (click)="onShowClonePopUp(roleProfile)" class="action-item" id="{{btnId.btnMngRoleProfileClone}}"
                  type="button">{{'admin.cloneBtn' |
            translate}}</button>
          <button (click)="onShowDeletePopUp(roleProfile.id)" class="action-item" id="{{btnId.btnMngRoleProfileDelete}}"
                  type="button">{{'admin.deleteBtn' |
            translate}}</button>
        </clr-dg-action-overflow>
      </clr-dg-cell>
      <clr-dg-cell>{{roleProfile.name}}</clr-dg-cell>
      <clr-dg-cell>{{roleProfile.description}}</clr-dg-cell>
      <clr-dg-cell>
        <ul *ngFor="let skill of roleProfile.roleProfileSkillMaps">
          <li>{{skill.skill.skillName}}</li>
        </ul>
      </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Role Profiles per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
        of {{pagination.totalItems}} Role Profiles
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>
<div *ngIf="showGridView">
  <clr-datagrid class="parent-datagrid">
    <clr-dg-column *ngIf="!editAll" class="action-cell"></clr-dg-column>
    <clr-dg-column [clrDgField]="'name'">
      {{'admin.roleProfilesAndGroups.roleProfilesGrid.roleProfileName' | translate}}</clr-dg-column>
    <clr-dg-column [clrDgField]="'description'">
      {{'admin.roleProfilesAndGroups.roleProfilesGrid.description' | translate}}</clr-dg-column>
    <clr-dg-column *ngFor="let skill of skills">
      {{skill.skillName}}
    </clr-dg-column>
    <clr-dg-row *clrDgItems="let roleProfile of roleProfileGridView; let i = index" [clrDgItem]="roleProfile">
      <ng-container *ngIf="(editRoleProfileForGrid?.id !== roleProfile.id && roleProfile.id !==0 && !editAll)">
        <clr-dg-cell class="action-cell-header">
          <clr-dg-action-overflow *hasAccess="functionality.analysis">
            <button (click)="onEditRoleProfileGridRow(roleProfile);" class="action-item"
                    type="button">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.edit'
              | translate}}
            </button>
            <button (click)="onShowClonePopUp(roleProfile);" class="action-item"
                    type="button">{{'admin.cloneBtn'| translate}}
            </button>
            <button (click)="onShowDeletePopUp(roleProfile.id)" class="action-item"
                    type="button">{{'admin.deleteBtn' |
              translate}}</button>
          </clr-dg-action-overflow>
        </clr-dg-cell>
        <clr-dg-cell>{{roleProfile.name}}</clr-dg-cell>
        <clr-dg-cell>{{roleProfile.description}}</clr-dg-cell>
        <clr-dg-cell
          *ngFor="let skill of roleProfile.roleProfileSkillMaps | filterRoleProfileSkillMap:statusEnum.Active">
          {{skill.threshold ? skill.threshold : ''}}
        </clr-dg-cell>
      </ng-container>
      <ng-container *ngIf="(editRoleProfileForGrid?.id == roleProfile?.id) && !editAll">
        <clr-dg-cell class="action-cell-header">
          <clr-dg-action-overflow *hasAccess="functionality.analysis">
            <button (click)="onSave(roleProfile)" class="action-item"
                    type="button">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.save'
              | translate}}</button>
            <button (click)="onCancelEditingForRow()" class="action-item"
                    type="button">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.cancel'
              | translate}}</button>
          </clr-dg-action-overflow>
        </clr-dg-cell>
        <clr-dg-cell>
          <input [(ngModel)]="roleProfile.name" clrInput name="GridroleProfileNameInput" size="60">
        </clr-dg-cell>
        <clr-dg-cell>
          <input [(ngModel)]="roleProfile.description" clrInput name="GridroleProfiledescriptionInput" size="60">
        </clr-dg-cell>
        <clr-dg-cell
          *ngFor="let skill of roleProfile.roleProfileSkillMaps | filterRoleProfileSkillMap:statusEnum.Active">
          <input [(ngModel)]="skill.threshold" class="skillInput" clrInput name="skillThreshold{i}"
                 oninput="(this.value > 100 || this.value < 0)&& (this.value = null)"
                 type="number">
        </clr-dg-cell>

      </ng-container>
      <ng-container *ngIf="editAll">
        <clr-dg-cell>
          <input [(ngModel)]="roleProfile.name" clrInput name="GridroleProfileNameInput1" size="60">
        </clr-dg-cell>
        <clr-dg-cell>
          <input [(ngModel)]="roleProfile.description" clrInput name="GridroleProfiledescriptionInput1" size="60">
        </clr-dg-cell>
        <clr-dg-cell
          *ngFor="let skill of roleProfile.roleProfileSkillMaps | filterRoleProfileSkillMap:statusEnum.Active">
          <input [(ngModel)]="skill.threshold" class="skillInput" clrInput name="skillThreshold1{i}"
                 oninput="(this.value > 100 || this.value < 0)&& (this.value = null)"
                 type="number">
        </clr-dg-cell>
      </ng-container>
    </clr-dg-row>
      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="10">
          <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Role Profiles per page</clr-dg-page-size>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Role Profiles
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>

