/*
 * ************************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ************************************************************************
 */

/**
 * These Role types are stored in the Database on the back-end in the ksa_role db table
 */
export class UserRoleType {

  public static readonly SUPER_ADMIN = 'Super Admin';
  public static readonly ADMIN: string = "Admin";
  public static readonly STAKEHOLDER: string = "Stakeholder";
  public static readonly ACCESS_ADMIN: string = "Access Admin";
  public static readonly LSA: string = "LSA";
  public static readonly TRANSLATION_REVIEWER: string = "Translation Reviewer";

}
