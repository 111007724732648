<form clrForm #emailForm="ngForm" autocomplete="off" (change)="onEmailFormChanged()">
  <clr-alert clrAlertType="danger" *ngIf="errorMessage !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
    <clr-alert-item>{{errorMessage}}</clr-alert-item>
  </clr-alert>
  <clr-alert clrAlertType="success" *ngIf="emailSaveSuccessAlert" [clrAlertClosable]="true" [clrAlertSizeSmall]="true">
    <clr-alert-item>{{'emailTemplate.savedMsg' | translate}}</clr-alert-item>
  </clr-alert>
  <clr-alert clrAlertType="danger" *ngIf="invalidEmailAlert" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
    <clr-alert-item>{{'emailTemplate.UndefinedPlaceHolder' | translate}}{{undefinedPlaceHolders}}</clr-alert-item>
  </clr-alert>
  <div class="clr-row">
    <div class="clr-col-11">
      <clr-select-container class="clr-row">
        <label class="clr-col-2">{{'emailTemplate.defaultEmailTemplate' | translate}}</label>
        <select id="email-template" clrSelect name="options" [(ngModel)]="emailTemplateId" class="email clr-col-4"
                #masterEmailTemplate
                (change)="onChangeEmailTemplate(masterEmailTemplate.value)">
          <option value=""></option>
          <option *ngFor="let masterEmailTemplate of masterEmailTemplates" [value]="masterEmailTemplate.id">
            {{masterEmailTemplate.emailTemplateName}} </option>
        </select>
      </clr-select-container>
    </div>
    <div class="clr-col-1 reminderButton" *ngIf="isScheduler">
      <button class="btn btn-sm btn-primary" [disabled]="emailSubject.errors?.required || !isInEditMode()"
              (click)="onSendReminderOpen()"
              type="button">{{'emailTemplate.setReminder' | translate}}</button>
    </div>
  </div>
  <clr-input-container class="clr-row">
    <label class="clr-col-2">{{'emailTemplate.subject' | translate}}
      <span>*</span></label>
    <input #emailSubject="ngModel" clrInput placeholder="Enter Subject" name="subject" required [(ngModel)]="ksaEmailSubject"
      class="clr-col-10 subjectInput" style="width: 37rem;" />
    <clr-control-error>{{'emailTemplate.subjectError' | translate}}</clr-control-error>
  </clr-input-container>
  <br>
  <div>
    <input [ngxSummernote]="config" [(ngModel)]="ksaEmailBody" (ngModelChange)="onEmailFormChanged()" type="text"
           name="name">
  </div>
  <div class="save-back-button">
    <button class="btn btn-sm btn-secondary"
            (click)="onBack()"
            type="button">{{'emailTemplate.backBtn' | translate}}</button>
    <button *ngIf="!isInEditMode()" class="btn btn-sm btn-primary"
            (click)="onSaveKSAEmailTemplate()"
            [disabled]="emailForm.invalid || !isFormUpdated || isFormSaved">
      {{'emailTemplate.saveBtn'| translate}}</button>
    <button *ngIf="isInEditMode()" class="btn btn-sm btn-primary"
            (click)="onUpdateKsaEmailTemplate()"
            [disabled]="emailForm.invalid || !isFormUpdated">
      {{'emailTemplate.updateBtn'| translate}}</button>
  </div>
</form>

<clr-modal [(clrModalOpen)]="showKsaDetailsWarning" class="showMasterTableButton">
  <h3 class="modal-title">{{'emailTemplate.saveWarningMsg' | translate}}</h3>
  <div class="modal-body">{{'emailTemplate.saveWarningMsg2' | translate}}</div>
  <div class="modal-footer">
    <button (click)="onBackButtonClick()" class="btn btn-outline btn-sm">{{'emailTemplate.discard' |
      translate}}</button>
    <button (click)="onSave()"
            [disabled]="emailForm.invalid"
            class="btn btn-primary btn-sm">{{'emailTemplate.saveBtn' |
      translate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showSetReminderModal" class="modal-lg" [clrModalSize]="'lg'"
           (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'emailTemplate.setReminder' | translate}}</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>
    <div>
      <form clrForm #addForm="ngForm" autocomplete="off" class="clr-form">
        <div class="clr-row" *ngIf="!isEditReminder">
          <div class="clr-col-8">
            <clr-date-container class="clr-row">
              <label class="clr-col-4">{{'emailTemplate.date' | translate}}*</label>
              <input  [(ngModel)]="emailReminderData.tillDate" class="clr-control-container clr-col-6" clrDate
                name="date" type="date" />
            </clr-date-container>
            <div class="clr-row">
              <cds-time control-width="" layout="compact" class="time-cds" >
                <label class="time-label" >{{'emailTemplate.time' | translate}}*</label>
                <input type="time" min="00:00" max="23:59" value="11:00" [(ngModel)]="emailReminderData.timeEntered"
                       id="timePicker" name="time" style="margin-left: 160px !important" required />
              </cds-time>
            </div>
            <div class="clr-row">
              <clr-combobox-container class="clr-col-12">
                <label class="clr-col-4">Zone *</label>
                <clr-combobox  class="clr-col-6" [(ngModel)]="emailReminderData.zoneEntered" name="timeZoneList"
                  >
                  <clr-options>
                    <clr-option *clrOptionItems="let item of timeZoneLists" [clrValue]="item.name">
                      {{item.name}}
                    </clr-option>
                  </clr-options>
                </clr-combobox>
              </clr-combobox-container>
            </div>
            <button [disabled]="addForm.invalid || emailReminderData.zoneEntered=='' " class="btn btn-sm btn-primary"
                    (click)="onSaveScheduler(emailReminderData)">{{'admin.addBtn'
              | translate}}</button>
          </div>

        </div>
      </form>
    </div>

    <div>
      <form clrForm #editForm="ngForm" autocomplete="off" class="clr-form">
        <div class="clr-row" *ngIf="isEditReminder">
          <div class="clr-col-8">
            <clr-date-container class="clr-row">
              <label class="clr-col-4">{{'emailTemplate.date' | translate}}*</label>
              <input [(ngModel)]="emailReminderData.tillDate" class="clr-control-container clr-col-6" clrDate
                name="demo" type="date" />
            </clr-date-container>
            <div class="clr-row">
              <cds-time control-width="" layout="compact" class="time-cds" >
                <label  class="time-label" >{{'emailTemplate.time' | translate}}*</label>
                <input type="time" min="00:00" max="23:59" value="11:00" [(ngModel)]="emailReminderData.timeEntered"
                   id="timePicker" name="time" style="margin-left: 160px !important" />
              </cds-time>
            </div>
            <div class="clr-row">
              <clr-combobox-container class="clr-col-12">
                <label class="clr-col-4">Zone *</label>
                <clr-combobox class="clr-col-6" [(ngModel)]="emailReminderData.zoneEntered" name="timeZoneList"
                  >
                  <clr-options>
                    <clr-option *clrOptionItems="let item of timeZoneLists" [clrValue]="item.name">
                      {{item.name}}
                    </clr-option>
                  </clr-options>
                </clr-combobox>
              </clr-combobox-container>
            </div>
            <button (click)="onCancelEditScheduler(editForm);editForm.resetForm()"
                    class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
              translate}}</button>
            <button [disabled]="addForm.invalid || emailReminderData.zoneEntered==''" class="btn btn-sm btn-primary"
                    (click)="onEditScheduler(emailReminderData)">{{'admin.updateBtn'
              | translate}}</button>
          </div>

        </div>
      </form>
    </div>

    <clr-datagrid class="datagrid-compact popup-datagrid scheduler-grid ">
      <clr-dg-column>{{'emailTemplate.date' | translate}}</clr-dg-column>
      <clr-dg-column>{{'emailTemplate.time' | translate}}(HH:MM)</clr-dg-column>
      <clr-dg-column>{{'emailTemplate.zone' | translate}}</clr-dg-column>
      <clr-dg-column>{{'emailTemplate.status' | translate}}</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row *ngFor="let scheduler of schedulerJob">
        <clr-dg-cell>{{scheduler.dateTime|date}}</clr-dg-cell>
        <clr-dg-cell>{{datePipe.transform(scheduler.dateTime, 'hh:mm a')}}</clr-dg-cell>
        <clr-dg-cell>{{scheduler.timeZone}}</clr-dg-cell>
        <clr-dg-cell>
          <clr-tooltip class="flex-child tooltip tooltip-xs tooltip-top-left toolTip-icon" >
            <clr-icon shape="help" clrTooltipTrigger badge="info"></clr-icon>
            <clr-tooltip-content>
              {{scheduler.detailedStatus}}
            </clr-tooltip-content>
          </clr-tooltip>
          {{scheduler.status}}
        </clr-dg-cell>
        <clr-dg-cell >
          <a style="padding-left:15px;">
            <clr-icon shape="pencil"  (click)="onEditInitialize(scheduler)" *ngIf="scheduler.status===statusEnum.Active"></clr-icon>
          </a>
          <a style="padding-left:15px;">
            <clr-icon class="flex-child" shape="trash" *ngIf="scheduler.status===statusEnum.Active" (click)="onShowDeletePopUp(scheduler.jobId,scheduler.jobGroup)"></clr-icon>
          </a>
        </clr-dg-cell>
      </clr-dg-row>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="closePopup()">{{'admin.closeBtn' |
      translate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showDeleteSchedulerPopUp" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{"ksaEngagement.emailManagement.deteleReminderMsg" | translate}}</h3>
  <div class="modal-body">{{'admin.rolesAndUser.user.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteSchedulerPopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteJob()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>
