/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

const baseUrl = 'http://localhost:8081/api/heatmap';

@Injectable({
  providedIn: 'root'
})
export class HeatMapService {

  private skillGroupUrl: string;
  private coursesUrl: string;
  private trainingUrl: string;
  private resetTrainingUrl: string;

  constructor(private http: HttpClient) {
    this.skillGroupUrl = `${environment.API_URL}${API_KSA_CORE_V1}/skillgroup`;
    this.coursesUrl = `${environment.API_URL}${API_KSA_CORE_V1}/skillcourses`;
    this.trainingUrl = `${environment.API_URL}${API_KSA_CORE_V1}/training/allparticipant/`;
    this.resetTrainingUrl = `${environment.API_URL}${API_KSA_CORE_V1}/reset/`;
  }

  getAll(): Observable<any> {
    return this.http.get<any>(`${baseUrl}?ksaMode=${1}`);
  }

  get(id: any): Observable<any> {
    return this.http.get<any>(`${baseUrl}/${id}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/${id}`);
  }

  deleteAll(): Observable<any> {
    return this.http.delete(baseUrl);
  }

  findByTitle(title: any): Observable<any[]> {
    return this.http.get<any[]>(`${baseUrl}?title=${title}`);
  }

  getAllSkillGroups(id: number): Observable<any> {
    return this.http.get<any>(this.skillGroupUrl + '/' + id);
  }

  getAllCoursesById(ksaId: number) {
    return this.http.get<any>(this.coursesUrl + '/' + ksaId);
  }

  saveTrainingRecommendation(ksaId: number,responseData: any) {
    return this.http.put<any>(this.trainingUrl+ksaId,responseData);
  }

  resetTrainingRecommendation(ksaId: number) {
    return this.http.put<any>(this.resetTrainingUrl + ksaId, {});
  }

  resetTrainingByParticipant(ksaId: number, participantId: number) {
    return this.http.put<any>(this.resetTrainingUrl + ksaId + '/' + participantId, {});
  }
}
