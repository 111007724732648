/*
 *  *********************************************************************
 *  * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Injectable} from "@angular/core";
import {SurveyTemplateConfigStatus, SurveyTemplateConfigVersion} from "../../../models/config-version.model";

@Injectable({
  providedIn: 'root'
})
export class SkillRoleProfileManagementService {
  isSurveyTemplateConfigActive(surveyTemplateConfigVersion: SurveyTemplateConfigVersion): boolean {
    return surveyTemplateConfigVersion?.status === SurveyTemplateConfigStatus.ACTIVE;
  }
}
