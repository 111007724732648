/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

export enum DataGridTypeEnum {
  HEAT_MAP = "HEAT_MAP",
  ROLE_MAP = "ROLE_MAP",
  ROLE_PROFILES = "ROLE_PROFILES",
  TRAINING_RECOMMENDATIONS = "TRAINING_RECOMMENDATIONS"
}
