/***********************************************************************
 * Copyright 2019 VMware, Inc. All rights reserved. VMware Confidential
 **********************************************************************/

import {NgModule} from '@angular/core';
import {SafeHtmlPipe} from './safe-html/safe-html.pipe';
import {DisplayLocalizedQuestionTextPipe} from './localization-pipes/display-localized-question-text.pipe';
import {DisplayLocalizedAnswerLabelPipe} from './localization-pipes/display-localized-answer-label.pipe';
import {CommonModule} from '@angular/common';
import {FilterRoleProfileSkillMapPipe} from './role-profile-skill-map/filter-role-profile-skill-map.pipe';

@NgModule({
  declarations: [
    DisplayLocalizedQuestionTextPipe,
    DisplayLocalizedAnswerLabelPipe,
    SafeHtmlPipe,
    FilterRoleProfileSkillMapPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DisplayLocalizedQuestionTextPipe,
    DisplayLocalizedAnswerLabelPipe,
    SafeHtmlPipe,
    FilterRoleProfileSkillMapPipe
  ]
})
export class PipesModule {
}
