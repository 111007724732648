<div class="button-container">
  <button class="btn btn-sm btn-primary" *ngIf="hasWritePermission" (click)="onSetAddEditRoleProfileSetFormOpen()">
    {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.addRoleProfileSet' | translate}}
  </button>
  <button *ngIf="hasWritePermission" (click)="onDeleteMultipleSets(selectedSets)" class="btn btn-sm btn-primary">
    {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.deleteRoleProfileSet' | translate}}
  </button>
  <clr-modal [(clrModalOpen)]="addEditRoleProfileSetFormOpen" [clrModalClosable]="true" class="modal-lg"
             (clrModalOpenChange)="clearForm()">
    <h3
      class="modal-title">{{jobProfileSet.id ? ('ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.editRoleProfileSet' | translate) : 'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.addRoleProfileSet' | translate}}
      <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
        <clr-alert-item>{{errorMsg}}</clr-alert-item>
      </clr-alert>
    </h3>
    <div class="modal-body">
      <form clrForm (ngSubmit)="onAddEditRoleProfileSet(addForm)" #addForm="ngForm" autocomplete="off">
        <clr-input-container class="clr-row">
          <label
            class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.roleProfileSetName' | translate}}
            *</label>
          <input [(ngModel)]="jobProfileSet.name" class="clr-col-7" clrInput name="ksaName" type="text"
                 required placeholder="enter role profile set name"/>
          <clr-control-error>{{'errorMsg.roleProfileSetNameRequired' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-textarea-container class="clr-row">
          <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.description' | translate}}</label>
          <textarea [(ngModel)]="jobProfileSet.description" class="clr-col-7" clrTextarea
                    name="description" placeholder="enter description" rows="1"></textarea>
          <clr-control-error>{{'errorMsg.roleProfileSetDescriptionRequired' | translate}}</clr-control-error>
        </clr-textarea-container>
        <clr-toggle-container class="clr-row">
          <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleGroup' | translate}}*</label>
          <label class="clr-col-2 light-label">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.default'|translate}}</label>
          <clr-toggle-wrapper class="clr-col-12 clr-row">
            <input type="checkbox" clrToggle name="options" [(ngModel)]="importFromLibrary"
              (change)="checkImportFromLibrary()" class="clr-col-5" />
            <label class="clr-col-11 light-label">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.importFromLibrary' | translate}}</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
        <div *ngIf="!importFromLibrary">
          <div>
            <clr-datagrid class="datagrid-compact datagrid-master-role" [(clrDgSelected)]="roleProfileGroupsSelectedFromMaster" [clrDgPreserveSelection]="true">
              <clr-dg-column [clrDgField]="'name'">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.roleProfileGroupName' | translate}}</clr-dg-column>
              <clr-dg-row *clrDgItems="let roleProfileGroup of roleProfileGroups"
                [clrDgItem]="roleProfileGroup">
                <clr-dg-cell>{{roleProfileGroup.name}}</clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>
          </div>
          <button type="button" class="btn btn-outline btn btn-sm btn-secondary add-selected-groups-btn"
            (click)="addSelectedRoleProfileGroupsFromMaster()">
            {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.addSelectedRoleGroups' | translate}}
          </button>
        </div>
        <div *ngIf="importFromLibrary">
          <clr-select-container class="clr-row">
            <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.ksa' | translate}}*</label>
            <select  class="clr-col-7" style="width: 8.5rem;" clrSelect [(ngModel)]="importedKsaSelected" name="ksaImported" (change)="ksaSelected(importedKsaSelected)">
              <option  disabled selected value="">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectKsa' | translate}}</option>
              <option  *ngFor = "let importedKsa of importedKsas" [ngValue]="importedKsa">{{importedKsa.ksaName}}</option>
            </select>
            <clr-control-error>{{'errorMsg.ksaIsRequired'|translate}}</clr-control-error>
          </clr-select-container>
          <clr-select-container class="clr-row">
            <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSet' | translate}}*</label>
            <select id ="selectedRoleProfile" class="clr-col-7 solution-group-select-input input-field" clrSelect name="roleProfileSetImported" [(ngModel)]="importedRoleProfileSetSelected" (change)="populateImportedRoleProfileGroupsDatagrid()">
              <option  disabled selected value="">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSetFromList' | translate}}</option>
              <option *ngFor="let importedRoleProfileSet of roleProfileSets?.ksaJobProfileSets"
                      [ngValue]="importedRoleProfileSet">{{importedRoleProfileSet.name}}</option>
            </select>
            <clr-control-error>{{'errorMsg.roleProfileSetIsRequired'|translate}}</clr-control-error>
          </clr-select-container>
          <div>
            <clr-datagrid class="datagrid-compact datagrid-master-role" [(clrDgSelected)]="roleProfileGroupsSelectedFromLibrary">
              <clr-dg-column [clrDgField]="'name'">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.roleProfileGroupName' | translate}}</clr-dg-column>
              <clr-dg-row *clrDgItems="let roleProfileGroup of roleProfileGroupsFromImport; let i = index"
                [clrDgItem]="roleProfileGroup">
                <clr-dg-cell>{{roleProfileGroup.roleProfileGroupName}}</clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>
          </div>
          <button type="button" class="btn btn-outline btn btn-sm btn-secondary add-selected-groups-btn"
            (click)="addSelectedRoleProfileGroupsFromLibrary()">
            {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.addSelectedRoleGroups' | translate}}
          </button>
        </div>
        <div>
          <clr-datagrid class="datagrid-compact datagrid-master-role">
            <clr-dg-column [clrDgField]="'name'">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.roleProfileGroupName' | translate}}</clr-dg-column>
            <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.action' | translate}}</clr-dg-column>
            <clr-dg-row *clrDgItems="let roleProfileGroup of roleProfileGroupsSelected; let i = index"
              [clrDgItem]="roleProfileGroup">
              <clr-dg-cell>{{!roleProfileGroup?.name ? roleProfileGroup?.roleProfileGroupName:roleProfileGroup?.name}}</clr-dg-cell>
              <clr-dg-cell><button type="button" class="btn btn-sm btn-danger btn-secondary"
                  (click)="deleteSelectedRpg(roleProfileGroup)">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.delete' | translate}}</button></clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </div>
        <div class="add-edit-btn-container">
          <button type="button" class="btn btn-sm btn-outline" (click)="clearForm()">Cancel</button>
          <button type="submit" class="btn btn-sm btn-primary" [disabled]="addForm.invalid || !roleProfileGroupsSelected">{{jobProfileSet.id ? ('ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.edit' | translate):'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.add' | translate}}</button>
        </div>
      </form>
    </div>
  </clr-modal>

  <clr-modal [(clrModalOpen)]="showDeleteWarning" (clrModalOpenChange)="errorMsg = ''">
    <h3 class="modal-title">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.deleteRoleProfileSet' | translate}}?
      <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
        <clr-alert-item>{{errorMsg}}</clr-alert-item>
      </clr-alert>
    </h3>
    <div class="modal-body">{{'admin.roleProfilesAndGroups.roleProfiles.thisCannotBeUndone' | translate}}.</div>
    <div class="modal-footer">
      <button (click)="showDeleteWarning = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
        translate}}</button>
      <button (click)="onDeleteRoleProfileSet()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
        translate}}</button>
    </div>
  </clr-modal>

  <clr-datagrid (clrDgRefresh)="refresh($event)" [(clrDgSelected)]="selectedSets" class="role-profile-datagrid">
    <clr-dg-column
      [clrDgField]="'name'">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.roleProfileSet' | translate}}</clr-dg-column>
    <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.description' | translate}}</clr-dg-column>
    <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.roleProfileGroups' | translate}}</clr-dg-column>
    <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.roleProfiles' | translate}}</clr-dg-column>
    <clr-dg-column>{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.modifiedDate' | translate}}</clr-dg-column>
    <clr-dg-column class="action-cell" *ngIf="hasWritePermission"></clr-dg-column>

    <clr-dg-row *ngFor="let jobProfileSet of jobProfileSets.content" [clrDgItem]="jobProfileSet">
      <clr-dg-cell>{{jobProfileSet.name}}</clr-dg-cell>
      <clr-dg-cell>{{jobProfileSet.description}}</clr-dg-cell>
      <clr-dg-cell>
        <ul *ngFor="let roleProfileGroup of jobProfileSet?.ksaRoleProfileGroups">
          <li>{{roleProfileGroup?.roleProfileGroupName}}</li>
        </ul>
      </clr-dg-cell>
      <clr-dg-cell>
        <div *ngFor="let roleProfileGroup of jobProfileSet?.ksaRoleProfileGroups">
          <ul *ngFor="let roleProfile of roleProfileGroup.ksaRoleProfiles">
            <li>{{roleProfile?.ksaRoleProfileName}}</li>
          </ul>
        </div>
      </clr-dg-cell>
      <clr-dg-cell>{{jobProfileSet.lastModifiedDate|date}}</clr-dg-cell>
      <clr-dg-cell class="action-cell" *ngIf="hasWritePermission">
        <clr-dg-action-overflow>
          <button type="button" class="action-item"
                  (click)="onEditKsaDetails(jobProfileSet)">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.editRow' | translate}}</button>
          <button type="button" class="action-item"
                  (click)="onShowDeleteWarning(jobProfileSet.id)">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.delete' | translate}}</button>
        </clr-dg-action-overflow>
      </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.setsPerPage' | translate}}</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.sets' | translate}}
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>
