/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class DemoService {

  private getKsaDetailsByUrl: string;

  constructor(private http: HttpClient) {
    this.getKsaDetailsByUrl = `${environment.API_URL}${API_KSA_CORE_V1}/survey/ksa/`;
  }

  getKsaDeails(uuid): Observable<any> {
    return this.http.get<any>(`${this.getKsaDetailsByUrl}${uuid}`);
  }
}
