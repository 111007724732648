/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/
import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {RoleProfilePage} from "../models/role-profile-page.model";
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({providedIn: 'root'})
export class RoleProfileService {

  private readonly roleProfileUrl: string = `${environment.API_URL}${API_KSA_CORE_V1}/roleprofile/`;

  constructor(private http: HttpClient) {
  }

  getRoleProfiles(surveyTemplateConfigVersionId: number): Observable<RoleProfilePage> {
    return this.http.get<RoleProfilePage>(`${this.roleProfileUrl}?surveyTemplateConfigVersionId=${surveyTemplateConfigVersionId}`);
  }

  getSelectedSkills(id: number, surveyTemplateConfigVersionId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.roleProfileUrl}skill/${id}/survey-template-configs/${surveyTemplateConfigVersionId}`);
  }

  createRoleProfile(roleProfile: any): Observable<any> {
    return this.http.post(`${this.roleProfileUrl}`, roleProfile);
  }

  updateRoleProfile(roleProfile: any): Observable<any> {
    return this.http.put(`${this.roleProfileUrl}`, roleProfile);
  }

  deleteRoleProfile(id: number): Observable<any> {
    return this.http.delete(`${this.roleProfileUrl}${id}`);
  }

}
