/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

/**
 * @author avalchev
 */
export class EditableType {

  public static readonly SURVEY_TEMPLATE: string = 'SURVEY_TEMPLATE';
  public static readonly KSA: string = 'KSA';

}
