/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

/**
 * @author avalchev
 */
export class Interval {

  public static readonly EDIT_PROJECT_INACTIVE_TIME_PERIOD: number = 150 * 60 * 1000;
  public static readonly EDIT_CHECK_REQUEST_INTERVAL: number = 15 * 1000;

}
