/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {UserCacheService} from "../../services/user-cache.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: any = "";
  loginError: boolean = false;
  private sub: any;
  constructor(private userCacheService: UserCacheService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  this.sub = this.route
           .queryParams
           .subscribe(params => {
              this.loginError = params['loginError'] || false;
           });
    const data = this.userCacheService.getLoginDataFromLocalStorage();
    this.username = data?.email;
    if(data?.email !== undefined){
    window.location.replace(`${environment.API_URL}` + "/saml/login");
    }
  }

  onLogin() {
    window.location.replace(`${environment.API_URL}` + "/saml/login");
  }
}
