/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  AnswerLabel,
  AnswerProperty,
  MapQuestionTag,
  MasterSurveyQuestion,
  SurveyQuestionTag,
} from '../../../../../../models/master-survey-questions.model';
import {MngSectionComponent} from '../mng-section.component';
import {SurveyControls} from "../../../../../../enums/survey-controls.enum";
import {QuestionType} from '../../../../../../enums/questionType.enum';
import {ShareSurveyDataService} from '../../../../../../services/share-survey-data.service';
import {MasterSurveyService} from '../../../../../../services/master-survey.service';
import {Subscription} from "rxjs";
import {UserService} from '../../../../../../services/user.service';
import {Functionality} from '../../../../../../enums/functionality.enum';

@Component({
  selector: 'app-free-text',
  templateUrl: './free-text.component.html',
  styleUrls: ['./free-text.component.scss'],
})
export class FreeTextComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  index: number;
  move = new EventEmitter();
  sectionReference!: MngSectionComponent;
  sectionQuestions!: MasterSurveyQuestion[];
  questionData: any;
  questionSequence!: number;
  tempSurveyQuestionId!: number;
  mapSkill: {
    id: number
  } = {
    id: 0
  };
  skillToShow: string = '';
  displayLogicToShow = this.defaultDisplayLogicMessage();
  skipLogicToShow = this.defaultSkipLogicMessage();
  showDisplayLogicModal: boolean = false;
  showSkipLogicModal: boolean = false;
  showMandatoryTypePopUp: boolean = false;
  showMapSkillPopUp: boolean = false;
  showImageDeletePopUp: boolean = false;
  showImageUploadPopUp: boolean = false;
  isAddNoteTrue: boolean = false;
  isDisable: boolean;
  isAddPageBreak: boolean = false;
  showDeleteQuestionPopUp: boolean = false;
  showMatrixAnswerLabel: boolean;
  convertedImage: string;
  showDisplayAnswerLabel: boolean;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  questionTypeEnum: typeof QuestionType = QuestionType;
  questionText: string;
  errorOnDisplayLogicItemDelete: boolean = false;
  showDisplayMessage: boolean = false;
  showSkipMessage: any;
  dependentQuestionDisplayLogic: string = '';
  mapSurveyQuestionWithTag: SurveyQuestionTag;
  showMapTagPopUp: boolean;
  tagList: MapQuestionTag[] = [];
  subscriptions: Subscription[] = [];
  hasWritePermission: boolean;

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef,
              private shareSurveyData: ShareSurveyDataService,
              private masterSurveyService: MasterSurveyService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.manageSurveyTemplate);
    if (this.questionData) {
      this.onInitializationFreeTextQuestionData();
    } else {
      this.setDefaultQuestionData();
      this.changeDetectOnQuestion();
    }
  }

  ngAfterViewInit(): void {
    this.changeDetectOnQuestion();
  }

  changeDetectOnQuestion(): void{
    this.questionData.valueChanges.subscribe((val: MasterSurveyQuestion) => {
      this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1] = this.questionData.getRawValue();
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  setDefaultQuestionData(): void {
    this.questionData = this.formBuilder.group({
      question: this.formBuilder.group({
        questionType: {
          id: this.questionTypeEnum.FreeText,
        },
        questionText: ['', {
          validators: [Validators.required, Validators.maxLength(1000)],
        }],
        image: [],
        answerLabels: [],
        answerProperty: this.formBuilder.group(this.createProperty()),
        mapSurveyQuestionWithTags: this.formBuilder.array([])
      }),
      skill: this.mapSkill,
      questionNote: ['', {
        validators: [Validators.maxLength(100)],
      }],
      language: {
        id: 1
      },
      sectionQuestionSequenceNo: this.questionSequence,
      tempSurveyQuestionId: this.shareSurveyData.findMaxQuestionId()+1,
      isMandatory: [true],
      isPageBreakAdded: [false],
      isImported: [false], isVersioned: [false],
      questionDisplayCondition: this.createDisplayControl(),
      questionSkipCondition: this.createSkipControl()
    });
  }

  onInitializationFreeTextQuestionData(): void {
    this.skillToShow = this.questionData.skill ? this.questionData.skill.skillName : '';
    this.questionText = this.questionData.question.questionText;
    if (this.questionData.isPageBreakAdded) {
      this.isAddPageBreak = true;
    }
    if (this.questionData.question?.mapSurveyQuestionWithTags) {
      this.mapSurveyQuestionWithTag = this.questionData.question?.mapSurveyQuestionWithTags[0]?.surveyQuestionTag;
    } else {
      this.questionData = this.formBuilder.group({
        question: this.formBuilder.group({
          mapSurveyQuestionWithTags: this.formBuilder.array([this.onCreateQuestionTags()])
        })
      });
    }
    this.assignQuestionData();
  }

  createQuestionTag(tag: MapQuestionTag) {
    return ({
      id: tag.id,
      surveyQuestionTag: {
        id: tag.surveyQuestionTag.id,
        tagName: tag.surveyQuestionTag.tagName
      }
    });
  }

  onCreateQuestionTags(): MapQuestionTag {
    return ({
      surveyQuestionTag: {
        id: undefined,
        tagName: ''
      }
    });
  }

  assignQuestionData(): void {
    this.questionData = this.formBuilder.group({
      id: this.questionData.id,
      question: this.formBuilder.group({
        id: this.questionData.question.id,
        questionType: {
          id: this.questionData.question.questionType.id,
        },
        questionText: [this.questionData.question.questionText, {
          validators: [Validators.required, Validators.maxLength(1000)],
        }],
        image: [this.questionData.question.image],
        answerLabels: [],
        answerProperty: this.formBuilder.group(
          this.createPropertyOnInit(this.questionData.question.answerProperty)
        ),
        mapSurveyQuestionWithTags: this.formBuilder.array(this.questionData.question.mapSurveyQuestionWithTags.map(tag =>
          this.createQuestionTag(tag)))
      }),
      language: {
        id: 1
      },
      skill: {
        id: this.questionData.skill?.id
      },
      questionNote: [this.questionData.questionNote, {
        validators: [Validators.maxLength(100)],
      }],
      sectionQuestionSequenceNo: this.questionData.sectionQuestionSequenceNo,
      tempSurveyQuestionId: this.questionData.tempSurveyQuestionId,
      isMandatory: [this.questionData.isMandatory],
      isPageBreakAdded: [this.questionData.isPageBreakAdded],
      isImported: [this.questionData.isImported],
      isVersioned: [this.questionData.isVersioned],
      questionDisplayCondition: this.questionData.questionDisplayCondition ? this.questionData.questionDisplayCondition : this.createDisplayControl(),
      questionSkipCondition: this.questionData.questionSkipCondition ? this.questionData.questionSkipCondition : this.createSkipControl()
    });

    if(this.questionData.controls.isImported.value){
      this.questionData.controls.question.controls[this.surveyControlsEnum.questionText].disable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.isSingleLine).disable();
      this.questionData.controls[this.surveyControlsEnum.questionNote].disable();
    }

    if(this.questionData.value.questionDisplayCondition.tempSectionId){
      this.onUpdateDisplay(true);
    }else{
      this.onUpdateDisplay(false);
    }
    if(this.questionData.controls.questionSkipCondition.value.skipCondition.id){
      this.onUpdateSkip(true);
    }else{
      this.onUpdateSkip(false);
    }
  }

  createSkipControl(): FormGroup {
    return this.formBuilder.group({
      skipCondition: this.formBuilder.group({
        id: null
      }),
      skipType: null
    });
  }

  createPropertyOnInit(info: AnswerProperty): AnswerProperty {
    return ({
      id: info.id,
      isSingleLine: info.isSingleLine
    })
  }

  createProperty(): AnswerProperty {
    return ({
      isSingleLine: true
    })
  }

  createDisplayControl(): FormGroup {
    return this.formBuilder.group({
      answerLabelSequenceNo: null,
      colAnswerLabelSequenceNo: null,
      displayValue: null,
      skipCondition: {
        id: null
      },
      tempSectionId:null,
      tempSurveyQuestionId:null
    });
  }

  onEditButton(): void {
    this.dependentQuestionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.shareSurveyData.validateQuestionDisplayLogic(this.questionData);
    if(validateQuestionDisplayLogicResponse.isValidQuestion){
      this.masterSurveyService.setSurveyUpdateStatus(false);
      this.questionData.controls.question.controls[this.surveyControlsEnum.questionText].enable();
      this.questionData.controls[this.surveyControlsEnum.questionNote].enable();
      this.isDisable = false;
      this.questionData.get(this.surveyControlsEnum.isImported).setValue(false);
      delete this.questionData.controls.question.controls[this.surveyControlsEnum.id]?.value;
      this.questionData.controls.question.controls[this.surveyControlsEnum.image].get(this.surveyControlsEnum.id)?.setValue();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.id).setValue();
    }else{
      this.errorOnDisplayLogicItemDelete = true;
      if(validateQuestionDisplayLogicResponse.dependentQuestion){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentQuestion.question.questionText;
      }
      if(validateQuestionDisplayLogicResponse.dependentSection){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      }
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
      }, 5000);
    }
  }

  onSingleOrMultiLineInput(value: boolean): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.isSingleLine).setValue(value);
  }

  onAddPageBreak(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.isAddPageBreak = true;
    this.questionData.controls.isPageBreakAdded.setValue(true);
  }

  onRemovePageBreak(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.isAddPageBreak = false;
    this.questionData.controls.isPageBreakAdded.setValue(false);
  }

  onDeleteImage(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.convertedImage = '';
    this.questionData.controls.question.get(this.surveyControlsEnum.image).setValue();
  }

  isUpArrayHidden(data: number): boolean {
    if (data == 1) {
      return true;
    }
    return false;
  }

  isDownArrayHidden(data: number): boolean {
    if (this.sectionReference?.sectionData.sectionQuestions.length == data) {
      return true;
    }
    return false;
  }

  isLastQuestion(): boolean {
    if (this.sectionReference?.sectionData.sectionQuestions.length == this.questionData.value.sectionQuestionSequenceNo
      && this.sectionReference.surveySections.length == this.sectionReference?.sectionData.sectionSequenceNo) {
      return true;
    }
    return false;
  }

  isFirstQuestion(): boolean {
    if (this.sectionReference?.sectionData.sectionSequenceNo == 1 && this.questionData.value.sectionQuestionSequenceNo == 1) {
      return true;
    }
    return false;
  }

  autoGrowTextZone(textBox): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    textBox.target.style.height = this.surveyControlsEnum.zeroPixel;
    textBox.target.style.height = (textBox.target.scrollHeight + 25) + this.surveyControlsEnum.pixel;
  }

  onRemoveQuestion(): void {
    this.dependentQuestionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.shareSurveyData.validateQuestionDisplayLogic(this.questionData);
    if(validateQuestionDisplayLogicResponse.isValidQuestion){
      this.sectionReference.removeQuestion(this.questionData);
      this.showDeleteQuestionPopUp = false;
      this.masterSurveyService.setSurveyUpdateStatus(false);
    }else{
      this.showDeleteQuestionPopUp = true;
      this.errorOnDisplayLogicItemDelete = true;
      if(validateQuestionDisplayLogicResponse.dependentQuestion){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentQuestion.question.questionText;
      }
      if(validateQuestionDisplayLogicResponse.dependentSection){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      }
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
      }, 5000);
    }
  }

  onChangeUpdateStatus(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
  }

  onUpdateDisplay(data: boolean): void{
    this.showDisplayMessage = data;
    if(this.showDisplayMessage===true){
        this.sectionReference?.surveySections.forEach(section => {
          if (section.tempSectionId == this.questionData.value.questionDisplayCondition.tempSectionId) {
            section.sectionQuestions?.forEach(question => {
              if (question.tempSurveyQuestionId == this.questionData.value.questionDisplayCondition.tempSurveyQuestionId) {
                this.displayLogicToShow.questionText = question.question?.questionText;
                this.displayLogicToShow.questionTypeId = question.question?.questionType.id;
                if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.RadioButton ||
                  this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MultipleChoice) {
                    if(this.questionData.value.questionDisplayCondition.answerLabel){
                      this.displayLogicToShow.answerLabel = this.questionData.value.questionDisplayCondition.answerLabel.labelText;
                    }else if(this.questionData.value.questionDisplayCondition.answerLabelSequenceNo){
                      const labels: AnswerLabel[] = question.question.answerLabels;
                      labels.forEach(answer=>{
                        if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
                          this.displayLogicToShow.answerLabel = answer.labelText;
                        }
                      });
                    }
                  }
                if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MatrixTable) {
                  const labels: AnswerLabel[] = question.question.answerLabels;
                  const rowAnswers: AnswerLabel[] = [];
                  const colAnswers: AnswerLabel[] = [];

                  labels.forEach(label => {
                    if (label.isRow) {
                      rowAnswers.push(label);
                    } else {
                      colAnswers.push(label);
                    }
                  });

                  if (this.questionData.value.questionDisplayCondition.answerLabel) {
                    this.displayLogicToShow.answerLabel = this.questionData.value.questionDisplayCondition.answerLabel.labelText;
                  }else if(this.questionData.value.questionDisplayCondition.answerLabelSequenceNo){
                    rowAnswers.forEach(answer=>{
                      if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
                        this.displayLogicToShow.answerLabel = answer.labelText;
                      }
                    });
                  }

                  if(this.questionData.value.questionDisplayCondition.colAnswerLabel){
                    this.displayLogicToShow.colAnswerLabel = this.questionData.value.questionDisplayCondition.colAnswerLabel.labelText;
                  }else if(this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo){
                    colAnswers.forEach(answer=>{
                      if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo) {
                        this.displayLogicToShow.colAnswerLabel = answer.labelText;
                      }
                    });
                  }
                }
              }
            });
            this.displayLogicToShow.displayValue = this.questionData.value?.questionDisplayCondition?.displayValue;
            const subscription = this.shareSurveyData.getSkipConditions().subscribe(conditions => {
              const skipCondition = conditions.find(condition => {
                return condition.id == this.questionData.value.questionDisplayCondition.skipCondition.id;
              });
              this.displayLogicToShow.skipConditionName = skipCondition.skipConditionName;
            });
            this.subscriptions.push(subscription);
          }
        });
    }
  }

  onUpdateSkip(data) : void{
    this.showSkipMessage = data;
    if(data){
      this.skipLogicToShow.skipType = this.questionData.controls.questionSkipCondition.value.skipType;
      const subscription = this.shareSurveyData.getSkipConditions().subscribe(conditions => {
        const skipCondition = conditions.find(condition => {
          return condition.id == this.questionData.controls.questionSkipCondition.value.skipCondition.id;
        });
        this.skipLogicToShow.skipConditionName = skipCondition.skipConditionName;
      });
      this.subscriptions.push(subscription);
    }
  }

  defaultDisplayLogicMessage() {
    return{
      questionText:'',
      skipConditionName:'',
      answerLabel:'',
      colAnswerLabel:'',
      displayValue:undefined,
      questionTypeId:undefined
    }
  }

  defaultSkipLogicMessage(){
    return{
      skipType : '',
      skipConditionName:''
    }
  }

  onTagSelection(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.questionData.controls.question.controls[this.surveyControlsEnum.mapSurveyQuestionWithTags].controls = [];
    this.questionData.value.mapSurveyQuestionWithTags = [];
    this.questionData.controls.question.value.mapSurveyQuestionWithTags = [];

    this.sectionReference.sectionData.sectionQuestions.forEach(question => {
      if (question.sectionQuestionSequenceNo == this.questionData.value.sectionQuestionSequenceNo) {
        question.question.mapSurveyQuestionWithTags = [];
      }
    });

    if (this.mapSurveyQuestionWithTag) {
      this.sectionReference.sectionData.sectionQuestions.forEach(question => {
        if (question.sectionQuestionSequenceNo == this.questionData.value.sectionQuestionSequenceNo) {
          question.question.mapSurveyQuestionWithTags.push(this.getCreateQuestionTags(this.mapSurveyQuestionWithTag.id, this.mapSurveyQuestionWithTag.tagName));
        }
      })
    }
    this.showMapTagPopUp = false;
  }

  getCreateQuestionTags(tagId: number, tag: string): MapQuestionTag {
    return ({
      surveyQuestionTag: {
        id: tagId,
        tagName: tag
      }
    });
  }

  showMapTag(): void {
    this.masterSurveyService.getSurveyTag().subscribe(data => {
      this.tagList = data;
    });
    this.showMapTagPopUp = true;
  }

  ngOnDestroy(): void {
    this.questionData.valueChanges.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
