<div *ngIf="showLoader">
  <clr-spinner>Loading ...</clr-spinner>
</div>
<div *ngIf="!showLoader">
  <clr-modal [(clrModalOpen)]="showImportksaSetPopup" (clrModalOpenChange)="onCloseCloneSet(cloneForm)">
    <h3 class="modal-title">{{'analysisAndReports.reports.roleProfileReport.cloneProfileSet'|translate}}
    </h3>
    <div class="modal-body">
      <clr-alert clrAlertType="danger" *ngIf="cloneErrorMsg !== ''" [clrAlertClosable]="false"
                 [clrAlertSizeSmall]="true">
        <clr-alert-item>{{cloneErrorMsg}}</clr-alert-item>
      </clr-alert>
      <form clrForm autocomplete="off" #cloneForm="ngForm">
        <clr-select-container class="clr-row">
          <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.ksa' |
            translate}}*</label>
          <select class="clr-col-7" style="width: 8.5rem;" clrSelect [(ngModel)]="importedKsaSelected"
                  name="ksaImported"
                  (change)="selectKsa(importedKsaSelected)">
            <option disabled selected value="">
              {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectKsa' | translate}}</option>
            <option *ngFor="let importedKsa of importedKsas" [ngValue]="importedKsa">{{importedKsa.ksaName}}</option>
          </select>
          <clr-control-error>{{'errorMsg.ksaIsRequired'|translate}}</clr-control-error>
        </clr-select-container>
        <clr-select-container class="clr-row">
          <label
            class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSet' |
            translate}}*</label>
          <select class="clr-col-7 solution-group-select-input input-field" clrSelect name="roleProfileSetCloned"
                  [(ngModel)]="cloneRoleProfileSetSelected">
            <option disabled selected value="">
              {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSetFromList' |
              translate}}</option>
            <option *ngFor="let importedRoleProfileSet of ksaJobProfileSets"
                    [ngValue]="importedRoleProfileSet">{{importedRoleProfileSet.name}}</option>
          </select>
          <clr-control-error>{{'errorMsg.roleProfileSetIsRequired'|translate}}</clr-control-error>
        </clr-select-container>
        <clr-input-container>
          <label class="clr-col-5">
            {{'analysisAndReports.reports.roleProfileReport.ksaSetName'|translate}}</label>
          <input [(ngModel)]="newRoleProfileSetName" class="clr-col-7" clrInput name="ImportedRoleProfileSetName" required
                 type="text" required/>
        </clr-input-container>
      </form>
    </div>
    <div class="modal-footer load-clone-ok-btn">
      <button (click)="onCloseCloneSet(cloneForm)" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
        translate}}</button>
      <button (click)="onCreateCloneKsaSet(cloneForm)" class="btn btn-outline btn-sm">OK</button>
    </div>
</clr-modal>

  <clr-modal [(clrModalOpen)]="addRoleProfileFormOpen" [clrModalClosable]="true"
             (clrModalOpenChange)="onCancelAdding(addForm)"
             class="modal-lg">
    <h3 class="modal-title">{{'admin.roleProfilesAndGroups.roleProfiles.addRoleProfileModalTitle' | translate}}
      <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
        <clr-alert-item>{{errorMsg}}</clr-alert-item>
      </clr-alert>
    </h3>
    <div class="modal-body">
      <form clrForm #addForm="ngForm" autocomplete="off">
        <clr-input-container class="clr-row">
          <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.roleProfileSet' |
            translate}}*</label>
          <input [value]="currentSetSelected?.name" class="clr-col-7" clrInput name="roleProfileSetName"
                 required type="text" disabled required/>
        </clr-input-container>
        <clr-select-container class="clr-row">
          <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.roleProfileGroup' |
            translate}}*</label>
          <select class="clr-col-7 solution-group-select-input input-field" clrSelect name="roleProfilegGroupImported"
                  [(ngModel)]="importedRoleProfileGroupSelected" required>
            <option disabled selected value=null>
              {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.selectRoleProfileGroup' | translate}}
            </option>
            <option *ngFor="let importedRoleProfileGroup of currentSetSelected?.ksaRoleProfileGroups"
                    [ngValue]="importedRoleProfileGroup">{{importedRoleProfileGroup?.roleProfileGroupName}}</option>
          </select>
          <clr-control-error>{{'errorMsg.roleProfileGroupRequired'|translate}}</clr-control-error>
        </clr-select-container>
        <clr-input-container class="clr-row">
          <label class="clr-col-5">{{'admin.roleProfilesAndGroups.roleProfiles.roleProfile' | translate}}*</label>
          <input class="clr-col-7" clrInput type="text" [(ngModel)]="newRoleProfile.ksaRoleProfiles[0].ksaRoleProfileName"
                 name="roleProfileName" required placeholder="{{'placeholder.roleProfileName' | translate}}" required/>
          <clr-control-error>{{'errorMsg.roleProfileNameRequired' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-textarea-container class="clr-row">
          <label class="clr-col-5">{{'admin.roleProfilesAndGroups.roleProfiles.description' | translate}}</label>
          <textarea [(ngModel)]="newRoleProfile.ksaRoleProfiles[0].description" class="clr-col-7" clrTextarea
                    name="description" placeholder="{{'placeholder.roleProfileDescription' | translate}}"
                    rows="1"></textarea>
          <clr-control-error>{{'errorMsg.roleProfileDescriptionRequired' | translate}}</clr-control-error>
        </clr-textarea-container>
        <div class="button-container">
        <span class="clr-control-label">{{'admin.roleProfilesAndGroups.roleProfiles.selectSkill' | translate}}
        </span>
        </div>
        <clr-datagrid class="datagrid-compact add-datagrid" [(clrDgSelected)]="selectedSkills"
                      [clrDgPreserveSelection]="true">
          <clr-dg-column [clrDgField]="'skillName'">
            {{'admin.roleProfilesAndGroups.roleProfiles.skill' | translate}}</clr-dg-column>
          <clr-dg-column>{{'admin.roleProfilesAndGroups.roleProfiles.threshold' |
            translate}} (%)*
          </clr-dg-column>
          <clr-dg-column>{{'admin.roleProfilesAndGroups.roleProfiles.comment' | translate}}</clr-dg-column>
          <clr-dg-row *clrDgItems="let skill of skills; let i = index" [clrDgItem]="skill">
            <clr-dg-cell>{{skill?.skillName}}</clr-dg-cell>
            <clr-dg-cell>
              <input clrInput type="number" name="threshold{{i}}" min="1" max="100" placeholder="00"
                     oninput="if(this.value > 100 || this.value < 0) {this.value = null;}" [(ngModel)]="skill.threshold"
                     [disabled]="!selectedSkills?.includes(skill)" required>
            </clr-dg-cell>
            <clr-dg-cell><input clrInput type="text" name="comment{{i}}" [(ngModel)]="skill.comment"
                                placeholder="{{'placeholder.addComment' | translate}}"
                                [disabled]="!selectedSkills?.includes(skill)">
            </clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-outline" (click)="onCancelAdding(addForm)">{{'admin.cancelBtn' |
            translate}}</button>
          <button type="button" (click)="onAddRoleProfile(addForm)" class="btn btn-sm btn-primary"
                  [disabled]="addForm?.invalid">{{'admin.addBtn' |
            translate}}</button>
        </div>
      </form>
    </div>
</clr-modal>
<div *ngIf="saveSuccess" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">
        {{"analysisAndReports.reports.roleProfileReport.roleProfileSavedMsg" | translate}}
    </span>
    </div>
  </div>
</div>
<clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" clrAlertType="danger">
  <clr-alert-item>{{errorMsg}}</clr-alert-item>
</clr-alert>
<form clrForm #addForm="ngForm" autocomplete="off" class="clr-row clr-form">
  <clr-select-container class="clr-col-6 clr-row">
    <label class="clr-col-3">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSet' |
      translate}}*</label>
    <select class="clr-col-8 solution-group-select-input input-field" clrSelect name="roleProfileSetImported"
            (change)="displayRoleAndSkillsBySetIdInDropdown($event)" [(ngModel)]="dropdownSetId">
      <option *ngFor="let importedRoleProfileSet of ksaSets" [value]="importedRoleProfileSet.id"
              [selected]="importedRoleProfileSet?.id === dropdownSetId">
        {{importedRoleProfileSet.name}}</option>
    </select>
    <clr-control-error>{{'errorMsg.roleProfileSetIsRequired'|translate}}</clr-control-error>
  </clr-select-container>
  <div class="btn-alignment clr-col-6" *hasAccess="functionality.analysis">
    <button *ngIf="!editAll" (click)="onEditAll()" class="btn btn-primary btn-sm">
      {{'analysisAndReports.reports.roleProfileReport.editAll'|translate}}</button>
    <button *ngIf="editAll" (click)="onCancelEditAll()" class="btn btn-primary btn-sm">
      {{'analysisAndReports.reports.roleProfileReport.cancel'|translate}}</button>
    <button class="btn btn-primary btn-sm" type="button" (click)="onSetAddKsaFormOpen()">
      {{'analysisAndReports.reports.roleProfileReport.addProfile'|translate}}
    </button>
    <button class="btn btn-primary btn-sm" type="button" (click)="onCloneRoleProfile()">
      {{'analysisAndReports.reports.roleProfileReport.cloneRoleProfile'|translate}}
    </button>
    <button class="btn btn-primary btn-sm" type="button" (click)="onCloneKsaSet()">
      {{'analysisAndReports.reports.roleProfileReport.cloneProfileSet'|translate}}
    </button>
    <button class="btn btn-primary btn-sm" type="button" (click)="onSaveAllSets()">
      {{'analysisAndReports.reports.roleProfileReport.saveAll'|translate}}
    </button>
    <button (click)="onShowColorModel()" class="btn btn-sm btn-primary">
      {{"analysisAndReports.reports.colorPickerBtn" | translate}}
    </button>
    <button class="btn btn-primary btn-sm" type="button"
            (click)="showDeleteMultipleWarning = true"
            [disabled]="selectedRoles.length === 0">
      {{'analysisAndReports.reports.roleProfileReport.delete'|translate}}
    </button>
    <button (click)="onOpenNewWindow()" *ngIf="showNewWindowButton" class="btn btn-sm btn-primary">
      {{"analysisAndReports.reports.openNewWindow" | translate}}
    </button>
    <button (click)="onRefresh()" class="btn btn-sm btn-primary">
      {{"analysisAndReports.reports.refresh" | translate}}
    </button>
  </div>
</form>
<br>
<clr-datagrid class="parent-datagrid role-profile-datagrid" [(clrDgSelected)]="selectedRoles">
  <clr-dg-column [clrDgField]="'ksaRoleProfileName'">
    {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.roleProfileName' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'description'">
    {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.description' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'roleProfileGroupName'">
    {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.group' | translate}}</clr-dg-column>
  <clr-dg-column *ngFor="let skill of skills">
    <div class="percentage-centered"> {{skill.skillName}} </div>
  </clr-dg-column>
  <clr-dg-column class="action-cell"></clr-dg-column>

  <clr-dg-row *clrDgItems="let roleProfile of result;let i = index" [clrDgItem]="roleProfile">
    <ng-container *ngIf="(editRoleProfile?.id !== roleProfile.id && roleProfile.id !==0 && !editAll)">
      <clr-dg-cell [attr.name]="'Role Profile Name'+i">{{roleProfile.ksaRoleProfileName}}</clr-dg-cell>
      <clr-dg-cell [attr.name]="'Description'+i">{{roleProfile.description}}</clr-dg-cell>
      <clr-dg-cell [attr.name]="'Group'+i">{{roleProfile.roleProfileGroupName}}</clr-dg-cell>
      <clr-dg-cell *ngFor="let skill of roleProfile.skills"
                   [style.background]="skill.backgroundColor"
                   [style.color]="skill.fontColor"
                   [attr.name]="skill?.skillName+i"
                   class="percentage-centered">
        {{skill.threshold ? skill.threshold : ''}}
      </clr-dg-cell>
      <clr-dg-cell class="action-cell">
        <clr-dg-action-overflow *hasAccess="functionality.analysis">
          <button (click)="onEditRoleProfile(roleProfile);" class="action-item"
                  type="button">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.edit'
            | translate}}
          </button>
          <button (click)="onShowDeleteWarning(roleProfile.id)" class="action-item"
                  type="button">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.delete'
            | translate}}
          </button>
        </clr-dg-action-overflow>
      </clr-dg-cell>
    </ng-container>
    <ng-container *ngIf="(editRoleProfile?.id == roleProfile?.id) && !editAll">
      <clr-dg-cell>
        <input clrInput name="GridoleProfileNameInput" [(ngModel)]="roleProfile.ksaRoleProfileName" size="60">
      </clr-dg-cell>
      <clr-dg-cell>
        <input clrInput name="GridoleProfiledescriptionInput" [(ngModel)]="roleProfile.description" size="60">
      </clr-dg-cell>
      <clr-dg-cell>
        <div style="padding-top: 30px;">{{roleProfile.roleProfileGroupName}}</div>
      </clr-dg-cell>
      <clr-dg-cell *ngFor="let skill of roleProfile.skills">
        <input class="skillInput" type="number" clrInput name="skillThreshold{i}" [(ngModel)]="skill.threshold"
               oninput="(this.value > 100 || this.value < 0)&& (this.value = null)">
      </clr-dg-cell>
      <clr-dg-cell class="action-cell">
        <clr-dg-action-overflow *hasAccess="functionality.analysis">
          <button type="button" class="action-item"
                  (click)="onEditRoleProfileRow(roleProfile)">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.save'
            | translate}}</button>
          <button type="button" class="action-item"
                  (click)="onCancelEditing()">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.cancel'
            | translate}}</button>
        </clr-dg-action-overflow>
      </clr-dg-cell>
    </ng-container>
    <ng-container *ngIf="editAll">
      <clr-dg-cell>
        <input clrInput name="GridoleProfileNameInput" [(ngModel)]="roleProfile.ksaRoleProfileName" size="60">
      </clr-dg-cell>
      <clr-dg-cell>
        <input clrInput name="GridoleProfiledescriptionInput" [(ngModel)]="roleProfile.description" size="60">
      </clr-dg-cell>
      <clr-dg-cell>
        <div style="padding-top: 30px;">{{roleProfile.roleProfileGroupName}}</div>
      </clr-dg-cell>
      <clr-dg-cell *ngFor="let skill of roleProfile.skills">
        <input class="skillInput" type="number" clrInput name="skillThreshold{i}" [(ngModel)]="skill.threshold"
               oninput="(this.value > 100 || this.value < 0)&& (this.value = null)">
      </clr-dg-cell>
      <clr-dg-cell class="action-cell">
        <clr-dg-action-overflow *hasAccess="functionality.analysis">
          <button type="button" class="action-item"
                  (click)="onEditRoleProfileRow(roleProfile)">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.save'
            | translate}}</button>
          <button type="button" class="action-item"
                  (click)="onCancelEditing()">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.cancel'
            | translate}}</button>
        </clr-dg-action-overflow>
      </clr-dg-cell>
    </ng-container>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Role Profiles per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Role Profiles
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
<clr-modal [(clrModalOpen)]="showDeleteWarning" (clrModalOpenChange)="errorMsg = ''">
  <h3 class="modal-title">{{'admin.roleProfilesAndGroups.roleProfiles.deleteRoleProfile' | translate}}?
    <clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true" clrAlertType="danger">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'admin.roleProfilesAndGroups.roleProfiles.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteWarning = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}
    </button>
    <button (click)="onDeleteRoleProfile()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showDeleteMultipleWarning" (clrModalOpenChange)="errorMsg = ''">
  <h3 class="modal-title">{{'analysisAndReports.reports.roleProfileReport.deleteMultipleRoleProfiles'|translate}}
    <clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true" clrAlertType="danger">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'admin.roleProfilesAndGroups.roleProfiles.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteMultipleWarning = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}
    </button>
    <button (click)="onDeleteMultipleRoleProfile(selectedRoles)" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}
    </button>
  </div>
</clr-modal>

  <clr-modal [(clrModalOpen)]="showCloneRoleProfilePopUp"
             (clrModalOpenChange)="clearCloneProfileDetails(cloneProfileForm)">
    <h3 class="modal-title">{{'analysisAndReports.reports.roleProfileReport.cloneRoleProfileHeader'|translate}}
    </h3>
    <div class="modal-body">
      <clr-alert clrAlertType="danger" *ngIf="cloneRoleProfileDialogErrorMsg !== ''" [clrAlertClosable]="false"
                 [clrAlertSizeSmall]="true">
        <clr-alert-item>{{cloneRoleProfileDialogErrorMsg}}</clr-alert-item>
      </clr-alert>
      <form clrForm autocomplete="off" #cloneProfileForm="ngForm">
        <clr-select-container class="clr-row">
          <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.ksa' |
            translate}}*</label>
          <select required class="clr-col-7" style="width: 8.5rem;" clrSelect
                  [(ngModel)]="cloneRoleProfileDialogImportedKsaSelected"
                  name="ksaImported"
                  (change)="selectKsa(cloneRoleProfileDialogImportedKsaSelected)">
            <option disabled selected value="">
              {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectKsa' | translate}}</option>
            <option *ngFor="let importedKsa of importedKsas" [ngValue]="importedKsa">{{importedKsa.ksaName}}</option>
          </select>
          <clr-control-error>{{'errorMsg.ksaIsRequired'|translate}}</clr-control-error>
        </clr-select-container>
        <clr-select-container class="clr-row">
          <label
            class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSet' |
            translate}}*</label>
          <select required class="clr-col-7 solution-group-select-input input-field" clrSelect
                  name="roleProfileSetCloned"
                  [(ngModel)]="cloneRoleProfileDialogSelectedProfileSet" (change)="fetchRoleGroups()">
            <option disabled selected value="">
              {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSetFromList' |
              translate}}</option>
            <option *ngFor="let importedRoleProfileSet of ksaJobProfileSets"
                    [ngValue]="importedRoleProfileSet">{{importedRoleProfileSet.name}}</option>
          </select>
          <clr-control-error>{{'errorMsg.roleProfileSetIsRequired'|translate}}</clr-control-error>
        </clr-select-container>
        <clr-select-container class="clr-row">
          <label
            class="clr-col-5">{{'analysisAndReports.reports.roleProfileReport.selectRoleProfile'|translate}}</label>
          <select required class="clr-col-7 solution-group-select-input input-field" clrSelect name="roleProfileToClone"
                  [(ngModel)]="cloneRoleProfileSelected" (change)="mapSkillWithThreshold()">
            <option disabled selected value="">
              {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSetFromList' |
              translate}}</option>
            <option *ngFor="let roleProfile of roleProfileListForCloning"
                    [ngValue]="roleProfile">{{roleProfile.ksaRoleProfileName}}</option>
          </select>
          <clr-control-error>{{'errorMsg.roleProfileSetIsRequired'|translate}}</clr-control-error>
        </clr-select-container>
        <clr-select-container class="clr-row">
          <label
            class="clr-col-5">{{'analysisAndReports.reports.roleProfileReport.selectGroupToAddInto'|translate}}</label>
          <select required class="clr-col-7 solution-group-select-input input-field" clrSelect
                  name="roleProfileGroupClonedInto"
                  [(ngModel)]="cloneRoleProfileGroupSelected">
            <option disabled selected value="">
              {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSetFromList' |
              translate}}</option>
            <option *ngFor="let group of currentSetSelected?.ksaRoleProfileGroups"
                    [ngValue]="group">{{group.roleProfileGroupName}}</option>
          </select>
          <clr-control-error>{{'errorMsg.roleProfileSetIsRequired'|translate}}</clr-control-error>
        </clr-select-container>
        <clr-input-container>
          <label class="clr-col-5">
            {{'analysisAndReports.reports.roleProfileReport.roleProfileName'|translate}}</label>
          <input [(ngModel)]="newRoleProfileName" class="clr-col-7" clrInput name="newRoleProfileName" required
                 type="text" required/>
        </clr-input-container>
      </form>
      <div *ngIf="cloneRoleProfileSelected?.ksaSkillWithRoleProfiles">
      <clr-datagrid class="datagrid-compact add-datagrid" [(clrDgSelected)]="newSkills"
                      [clrDgPreserveSelection]="true">
          <clr-dg-column [clrDgField]="'skillName'">
            {{'admin.roleProfilesAndGroups.roleProfiles.skill' | translate}}</clr-dg-column>
          <clr-dg-column>{{'admin.roleProfilesAndGroups.roleProfiles.threshold' |
            translate}} (%)*
          </clr-dg-column>
          <clr-dg-column>{{'admin.roleProfilesAndGroups.roleProfiles.comment' | translate}}</clr-dg-column>
          <clr-dg-row *clrDgItems="let skill of cloneSkills; let i = index;" [clrDgItem]="skill">
            <clr-dg-cell>{{skill?.skillName}}</clr-dg-cell>
            <clr-dg-cell>
              <input clrInput type="number" name="threshold{{i}}" min="1" max="100" placeholder="00"
                     oninput="if(this.value > 100 || this.value < 0) {this.value = null;}" [(ngModel)]="skill.threshold"
                     [disabled]="!newSkills?.includes(skill)" required>
            </clr-dg-cell>
            <clr-dg-cell><input clrInput type="text" name="comment{{i}}" [(ngModel)]="skill.comment"
                                placeholder="{{'placeholder.addComment' | translate}}"
                                [disabled]="!newSkills?.includes(skill)">
            </clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
    </div>
    <div class="modal-footer load-clone-ok-btn">
      <button (click)="clearCloneProfileDetails(cloneProfileForm)" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
        translate}}</button>
      <button (click)="onCreateCloneRoleProfile(cloneProfileForm)" class="btn btn-outline btn-sm"
              [disabled]="cloneProfileForm.invalid || newSkills.length === 0">{{'admin.saveBtn' |
        translate}}</button>
    </div>
  </clr-modal>

</div>

<color-picker *ngIf="isColorModelOpen" [options]="options" [colorData]="this.colorData" [ksaId]="ksaId"
              [isColorModalOpen]="isColorModelOpen" [dataGridType]="dataGridType" (addColorToTable)="addColor($event)"
              [successMsgTimeout]="this.successMsgTimeout" (closeColorPicker)="onHideColorModal()"
              (deleteColorFromTable)="colorData.splice($event,1)"></color-picker>
