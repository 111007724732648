<footer class="footer-section">
  <div class="footer-section-logo-container">
    <div class="vmware-logo-icon-grey"></div>
  </div>
  <div class="footer-section-links">
        <span>
            {{'footer.copyright' | translate : {currentYear: currentYear} }}
        </span>
    <div class="links-container">
      <a href="https://www.vmware.com/help/legal.html">
        {{'footer.termsOfUse' | translate}}
      </a>
      <a href="https://www.vmware.com/help/privacy/california-privacy-rights.html#">
        {{'footer.yourCaliforniaRights' | translate}}
      </a>
      <a href="https://www.vmware.com/help/privacy.html">
        {{'footer.privacy' | translate}}
      </a>
      <a class="ot-sdk-show-settings">
        {{'footer.cookieSettings' | translate}}
      </a>
    </div>
  </div>
</footer>
