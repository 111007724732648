
<div class="survey-Container">
  <div *ngIf="surveySaveSuccessAlert && !surveyUpdateSuccessAlert && messageSuccessTimer" class="alert alert-success"
       role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{'surveyTemplate.masterSurvey.surveySavedMsg'|translate}}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="surveyUpdateSuccessAlert && !surveySaveSuccessAlert && messageSuccessTimer" class="alert alert-success"
       role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{'surveyTemplate.masterSurvey.surveyUpdate'|translate}}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="surveyErrorAlert" class="alert alert-danger" id="alert-message" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{'surveyTemplate.masterSurvey.errorMsg'|translate}}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="surveyErrorAlertsOnQuestion.length!=0 && messageSuccessTimer" class="alert alert-danger"
       id="alert-message" role="alert">
    <div class="alert-items">
      <div *ngFor="let errorMessge of surveyErrorAlertsOnQuestion" class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{errorMessge}}
        </span>
      </div>
    </div>
  </div>
  <div class="clr-row section-name">
    <div class="clr-col-6 survey-btn-container">
      <form #surveyTemplateForm="ngForm" [clrLabelSize]="3" autocomplete="off" clrForm>
        <clr-input-container class="survey-input-container display-label">
          <label>Survey Template Name*</label>
          <input [(ngModel)]="masterSurveyName" autocomplete="off"
                 (keyup)="onSurveyPresent(masterSurveyName)"
                 [disabled]="disableSurveyNameNewVersion || !hasWritePermission"
                 clrInput
                 maxLength="100" name="surveyName" pattern="^[^!@#*&(){}<>\/;`%$^:?]*$"
                 placeholder="Enter Survey Template Name*" required/>
          <clr-control-error *clrIfError="'pattern'" class="errorMessageSurveyName">
            <span>
              {{'errorMsg.specialCharacterErrorMsg' | translate}}
            </span>
          </clr-control-error>
          <clr-control-error *clrIfError="'required'" class="error errorMessageSurveyName">
            <span>
              {{'surveyTemplate.masterSurvey.surveyNameErrorMsg'|translate}}
            </span>
          </clr-control-error>
        </clr-input-container>
        <clr-select-container class="survey-input-container display-label">
          <label>{{'surveyTemplate.masterSurvey.surveyTemplateConfigVersion.label'|translate}}*</label>
          <select (ngModelChange)="onConfigTemplateChange($event)"
                  [compareWith]="areEqual"
                  [disabled]="disableSurveyNameNewVersion || !hasWritePermission"
                  [ngModel]="surveyTemplateConfigVersion"
                  clrSelect
                  id="surveyTemplateConfigVersion" name="surveyTemplateConfigVersion" required>
            <option
              *ngIf="surveyTemplateConfigVersion && !isSurveyTemplateConfigVersionActive(surveyTemplateConfigVersion)"
              [ngValue]="surveyTemplateConfigVersion">
              {{surveyTemplateConfigVersion.name}} ({{surveyTemplateConfigVersion.status}})
            </option>
            <option *ngFor="let configVersion of activeSurveyTemplateConfigVersions"
                    [ngValue]="configVersion">{{configVersion.name}}</option>
          </select>
          <clr-control-error>{{'surveyTemplate.masterSurvey.surveyTemplateConfigVersion.errorMsg' | translate}}</clr-control-error>
        </clr-select-container>
        <div *ngIf="showDuplicateSurveyError" class="errorSurveyName">
          {{'surveyTemplate.masterSurvey.duplicateMsg'|translate}}
        </div>
        <div class="flex">
          <div class="clr-row flex text-area-wrapper">
            <clr-textarea-container class="survey-input-container">
          <textarea [(ngModel)]="surveyDescription" [disabled]="!hasWritePermission" autocomplete="off" maxLength="254"
                    class="clr-control-container " clrTextarea name="surveyDescription"
                    placeholder="Enter Survey Description" rows="1"
                    (keyup)="onChange()"></textarea>
            </clr-textarea-container>
            <div>
              <label class="descriptionTooltip">
                <clr-tooltip class="flex-child flex-child tooltip tooltip-xs tooltip-trigger toolTip-icon">
                  <clr-icon badge="info" clrTooltipTrigger shape="help"></clr-icon>
                  <clr-tooltip-content>
                    {{'placeholder.maxCharDescriptionSurvey' |translate}}
                  </clr-tooltip-content>
                </clr-tooltip>
              </label>
            </div>
          </div>
        <div class="clr-row flex text-area-wrapper">
          <div>
            <clr-textarea-container class="survey-input-container">
          <textarea [(ngModel)]="surveyVersionDescription" [disabled]="!hasWritePermission" autocomplete="off"
                    maxLength="120"
                    class="clr-control-container" clrTextarea name="surveyVersionDescription"
                    placeholder="{{'placeholder.surveyVersionDescription' | translate}}" rows="1"
                    (keyup)="onChange()"></textarea>
            </clr-textarea-container>
          </div>
          <div>
            <label class="descriptionTooltip">
              <clr-tooltip class="flex-child flex-child tooltip tooltip-xs tooltip-trigger toolTip-icon">
                <clr-icon shape="help" clrTooltipTrigger badge="info"></clr-icon>
                <clr-tooltip-content>
                  {{'placeholder.maxCharDescriptionSurveyVersion' |translate}}
                </clr-tooltip-content>
              </clr-tooltip>
            </label>
          </div>
        </div>
        </div>
      </form>
    </div>

    <div class="clr-col-6 survey-btn-container">
      <button (click)="checkIfSurveyHasUnsavedChanges()" class="btn btn-sm btn-primary btn-show-master-survey" type="button">
        {{'emailTemplate.backBtn' | translate}}
      </button>
      <clr-modal [(clrModalOpen)]="showTablePopUp" class="showMaseterTableButton">
        <h3 class="modal-title">{{'surveyTemplate.masterSurveyTabs.saveInfomsg'|translate}}</h3>
        <div class="modal-body">
          <p>{{'surveyTemplate.masterSurveyTabs.saveAlertMsg'| translate}}</p>
        </div>
        <div class="modal-footer">
          <button (click)="showTablePopUp= false" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn'
            | translate}}</button>
          <button (click)="onShowTable()"
                  class="btn btn-primary btn-sm">{{'surveyTemplate.questionSheet.isOk'|translate}}</button>
        </div>
      </clr-modal>

      <clr-dropdown *ngIf="hasWritePermission">
        <button class="btn btn-primary btn-sm import-section" clrDropdownTrigger>
          {{'surveyTemplate.masterSurvey.importSection'|translate}}
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-left">
          <div (click)="onImportSection()" aria-label="Dropdown header Action 1" clrDropdownItem>
            {{'surveyTemplate.masterSurvey.existingSurvey'|translate}}
          </div>
          <div aria-hidden="true" class="dropdown-divider" role="separator"></div>
          <button (click)="onImportSectionFile()"
                  clrDropdownItem>{{'surveyTemplate.masterSurvey.file'|translate}}</button>
        </clr-dropdown-menu>
      </clr-dropdown>
      <button (click)="onAddSection()" *ngIf="hasWritePermission"
              class="btn btn-primary btn-sm">{{'surveyTemplate.masterSurvey.addSection'|translate}}</button>
      <button (click)="onSaveSurvey()" *ngIf="!isEdit && hasWritePermission"
              [disabled]="disableSaveUpdateButton || !surveyTemplateForm.valid"
              id="save"
              class="btn btn-primary btn-sm">{{'surveyTemplate.masterSurvey.save'|translate}}
      </button>
      <button (click)="onUpdateSurvey()" *ngIf="isEdit && hasWritePermission" id="update"
              [disabled]="disableSaveUpdateButton || !surveyTemplateForm.valid"
              class="btn btn-primary btn-sm">{{'surveyTemplate.masterSurvey.save' |translate}}
      </button>
    </div>
  </div>
  <div class="card-block section-card-block">
    <div class="blocksWrapper">
      <app-mng-section (moveSection)="onMoveSection($event,section, i)"
                       (sectionSelected)="onRemoveSection($event)" *ngFor="let section of surveySections; let i=index"
                       [isAdd]="isAdd" [isClone]="isClone" [isEdit]="isEdit"
                       [isNewVersion]="isNewVersion" [sectionData]="section" [surveySections]="surveySections"
                       [isImportSection]="isImportSection" [surveyTemplateConfigVersion]="surveyTemplateConfigVersion">
      </app-mng-section>
    </div>
  </div>
  <clr-modal [(clrModalOpen)]="showImportSectionModal" (clrModalOpenChange)="resetImportModal()">
    <h6 *ngIf="!showSpinnerImportSection"
        class="modal-title">{{'surveyTemplate.masterSurvey.importSectionBtn'|translate}}</h6>
    <div class="modal-body">

      <div *ngIf="showSpinnerImportSection" class="content-container" id="saveSurveySpin">
        <div class="content-area centered-content-area">
          <div class="spinner spinner-sm">{{'surveyTemplate.masterSurvey.loadingSpinner'|translate}}</div>
        </div>
      </div>

      <div *ngIf="!showSpinnerImportSection" class="clr-col-sm-12">
        <div class="alert alert-danger" *ngIf="duplicateSectionNamesOnImport.length>0">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
              </div>
              <span class="alert-text">
                {{'errorMsg.duplicateSectionImportMessage' | translate}}
                <span class="alert-text" *ngFor="let name of duplicateSectionNamesOnImport">
                  '{{name}}'
                </span>
              </span>
            </div>
          </div>
        </div>

        <clr-select-container>
          <label> {{'surveyTemplate.masterSurvey.selectPublishedSurvey'|translate}}</label>
          <select (change)="onSelectPublishedSurvey(masterSurveyId)" clrSelect name="publishedSurvey" [(ngModel)]="masterSurveyId">
            <option disabled selected value="">{{'surveyTemplate.masterSurvey.selectSurvey'|translate}}</option>
            <option *ngFor="let item of publishedSurveys" [value]="item.id">
              {{item.masterSurveyName}} [{{item.version}}]
            </option>
          </select>
        </clr-select-container>
      </div>

      <br>
      <div *ngIf="!showSpinnerImportSection" class="clr-col-sm-12">
        <clr-datagrid [(clrDgSelected)]="selectedSectionsForImport" [clrDgRowSelection]="false">
          <clr-dg-column [clrDgField]="'section.sectionName'">{{'surveyTemplate.masterSurvey.section'|translate}}
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let section of masterSurveyToImportSectionsFrom?.surveySections"
                      [clrDgItem]="section">
            <clr-dg-cell>{{section.section.sectionName}}</clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
    </div>
    <div *ngIf="!showSpinnerImportSection" class="modal-footer">
      <button (click)="showImportSectionModal = false" class="btn btn-outline btn-sm"
              type="button">{{'admin.cancelBtn'|translate}}</button>
      <button (click)="onAddImportedSections()" class="btn btn-sm  btn-primary"
              type="button">{{'admin.addBtn'|translate}}</button>
    </div>
  </clr-modal>
</div>

<clr-modal [(clrModalOpen)]="showUploadSectionFile">
  <h3 class="modal-title">{{'surveyTemplate.mngSurvey.importSectionFile' | translate}}</h3>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-2">
        <div class="clr-file-wrapper">
          <label class="clr-control-label" for="basic"><span class="btn btn-sm">{{'admin.browseBtn' |
              translate}}</span></label>
          <input #fileInput (change)="onImportQuestionSelectFile($event)" accept=".csv" class="clr-file" id="basic"
            type="file" />
        </div>
      </div>
      <div  class="clr-col-6">
        <label class="clr-control-label" for="basic"><strong>{{selectedFileName}}</strong></label>
      </div>
    </div>
    <clr-alert clrAlertType="danger" *ngIf="errorOnUploadFileSection != ''" [clrAlertClosable]="false"
      [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorOnUploadFileSection}}</clr-alert-item>
    </clr-alert>
  </div>
  <div class="modal-footer">
    <button (click)="onCancelFileSectionImport()" class="btn btn-outline btn-sm"
      type="button">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}
    </button>
    <button (click)="onOkImportFileSection()" class="btn btn-primary btn-sm"
      type="button">{{'surveyTemplate.questionSheet.isOk'|translate}}
    </button>
  </div>
</clr-modal>
