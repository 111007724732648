<div class="flex clr-justify-content-end">
  <!--
  <button (click)="revertTranslation()" class="btn btn-sm btn-link">
    <clr-icon shape="refresh"></clr-icon>
    {{'surveyTemplate.localization.revertTranslation'|translate}}
  </button>
  -->
  <button [disabled]="!hasUnsavedChanges" (click)="save()" class="btn btn-sm btn-link">
    <clr-icon shape="floppy"></clr-icon>
    {{'common.save'|translate}}
  </button>
</div>

<div class="question-row-wrapper" style="margin-bottom: 1rem"
     *ngIf="question.questionType === questionTypeEnum.Description">
  <div class="note-editor-container">
    <input [ngModel]="question.enText" [ngxSummernote]="emptyConfig" [ngxSummernoteDisabled]="true" type="text"
           [disabled]="masterSurveyLocalizationStatus !== localizationStatusEnum.DRAFT"/>
  </div>

  <div class="note-editor-container">
    <input (ngModelChange)="updateTranslation($event)" [ngModel]="question.translation"
           [ngxSummernote]="config" type="text"
           [disabled]="masterSurveyLocalizationStatus !== localizationStatusEnum.DRAFT"/>
  </div>
</div>

<div class="question-row-wrapper" *ngIf="question.questionType !== questionTypeEnum.Description">
  <div class="w-100p">
    <strong>{{question.enText}}</strong>
  </div>

  <div class="w-100p clr-input-no-margin-top">
    <input (input)="checkForUnsavedChanges()" [(ngModel)]="question.translation"
           [disabled]="masterSurveyLocalizationStatus !== localizationStatusEnum.DRAFT"
           clrInput type="text"/>
  </div>
</div>

<ng-container *ngIf="!!question.answers.length">
  <div *ngFor="let a of question.answers" class="question-row-wrapper">
    <input clrInput [value]="a.enText" disabled/>
    <input (input)="checkForUnsavedChanges()" clrInput [(ngModel)]="a.translation"
           [disabled]="masterSurveyLocalizationStatus !== localizationStatusEnum.DRAFT"/>
  </div>
</ng-container>
