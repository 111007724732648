import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-sinle-user-report',
  templateUrl: './single-user-report.component.html',
  styleUrls: ['./single-user-report.component.scss']
})
export class SingleUserReportComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
