import {Component, OnInit} from '@angular/core';
import {AnalysisShareService} from '../../../services/analysis-share.service';
import {TealiumUtagService} from "../../../services/utag.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-survey-thanks',
  templateUrl: './survey-thanks.component.html',
  styleUrls: ['./survey-thanks.component.scss']
})
export class SurveyThanksComponent implements OnInit {

  closedKsaStatus: boolean = false;

  constructor(private analysisShareService: AnalysisShareService,
              private utagService: TealiumUtagService,
              private router: Router) { }

  ngOnInit(): void {
    this.utagService.trackStep(this.router.url);
    this.analysisShareService.getClosedKsaStatus().subscribe(data=>{
      this.closedKsaStatus = data;
    });
  }

}
