/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/

import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SkillStatus} from '../../../models/skill-page.model';
import {SkillsService} from '../../../services/skills.service';
import {Functionality} from "../../../enums/functionality.enum";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from "rxjs/operators";
import {SkillGroupOrder, SkillOrder} from "../../../models/skill-group-order.model";
import {SurveyTemplateConfigVersion} from "../../../models/config-version.model";
import {
  SkillRoleProfileManagementService
} from "../tab-mng-skills-skill-order-role-profiles-and-role-profile-groups/skill-role-profile-management.service";

@Component({
  selector: 'app-mng-skill-order',
  templateUrl: './mng-skill-order.component.html',
  styleUrls: ['./mng-skill-order.component.scss']
})
export class MngSkillOrderComponent implements OnInit {

  @Input() surveyTemplateConfigVersion: SurveyTemplateConfigVersion;

  skillGroups: SkillGroupOrder[] = [];
  surveySaveSuccessAlert: boolean = false;
  messageSuccessTimer: boolean = true;
  surveyErrorAlert: boolean;
  functionality: typeof Functionality = Functionality;

  constructor(private readonly skillService: SkillsService, private readonly translate: TranslateService,
              public skillRoleProfileManagementService: SkillRoleProfileManagementService) {
  }

  ngOnInit(): void {
    this.getSkillGroupList();
  }


  moveSkillGroup(shift: number, skillGroup: SkillGroupOrder): void {
    const currentIndex = this.skillGroups.findIndex(s => skillGroup.id === s.id);
    this.shiftElement(this.skillGroups, currentIndex, shift);
  }

  moveSkill(shift: number, skill: SkillOrder, skillGroup: SkillGroupOrder): void {
    const currentIndex = skillGroup.skills.indexOf(skill);
    this.shiftElement(skillGroup.skills, currentIndex, shift);
  }

  onSaveSkillOrder(): void {
    showSpinner(this.translate.instant('spinnerLabels.common.saving'));
    this.updateSequenceNumbers(this.skillGroups);
    this.messageSuccessTimer = true;
    this.skillService.updateSkillGroupOrder(this.skillGroups, this.surveyTemplateConfigVersion.id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe((results) => {
          this.skillGroups = this.filterBySkillStatus(results);
          this.surveySaveSuccessAlert = true;
          this.surveyErrorAlert = false;
          this.sortSkillsAndSkillGroups(this.skillGroups);
          setTimeout(() => {
            this.messageSuccessTimer = false;
          }, 3000);
        },
        () => {
          this.surveySaveSuccessAlert = false;
          this.surveyErrorAlert = true;
        });
  }

  private getSkillGroupList(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.skillService.getSkillGroupsOrder(this.surveyTemplateConfigVersion.id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe((result) => {
        this.skillGroups = this.filterBySkillStatus(result);
        this.sortSkillsAndSkillGroups(this.skillGroups);
      });
  }

  private sortSkillsAndSkillGroups(skillGroups: SkillGroupOrder[]): void {
    const compareFn = (prev, next) => prev.sequenceNo - next.sequenceNo;
    skillGroups.forEach(skillGroup => skillGroup.skills.sort(compareFn));
    skillGroups.sort(compareFn);
  }

  private filterBySkillStatus(skillGroupContent: Array<SkillGroupOrder>): SkillGroupOrder[] {
    skillGroupContent.forEach(sgc => sgc.skills = sgc.skills.filter(s => s.status !== SkillStatus.ARCHIVED));
    return skillGroupContent;
  }

  private shiftElement(arr: any[], index: number, shift: number) {
    const shiftedElement = arr.splice(index, 1)[0];
    arr.splice(index + shift, 0, shiftedElement);
    return arr;
  }

  private updateSequenceNumbers(skillGroups: SkillGroupOrder[]) {
    this.updateSequenceNo(skillGroups);
    skillGroups.forEach(s => this.updateSequenceNo(s.skills));
  }

  private updateSequenceNo(arr: { sequenceNo }[]) {
    for (let i = 0; i < arr.length; i++) {
      arr[i].sequenceNo = i + 1;
    }
  }
}
