<!--
  ~ /* *********************************************************************
  ~  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
  ~  * *********************************************************************/
  -->

<clr-modal *ngIf="isColorModalOpen" [(clrModalOpen)]="isColorModalOpen" [clrModalClosable]="false">
  <h3 class="modal-title">{{"analysisAndReports.reports.colorPickerBtn" | translate}}</h3>
  <div class="modal-body">
    <div *ngIf="colorDefaultSuccessAlert && messageSuccessTimer" class="alert alert-success" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
          </div>
          <span class="alert-text">
                {{'analysisAndReports.reports.heatMap.colorCodeDefaultSuccessMsg'|translate}}
              </span>
        </div>
      </div>
    </div>
    <div *ngIf="colorDefaultErrorAlert" class="alert alert-danger" id="alert-message" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
          </div>
          <span class="alert-text">
                {{'errorMsg.colorCodeDefaultError'|translate}}
              </span>
        </div>
      </div>
    </div>
    <div *ngIf="colorsFailSaveAlert" class="alert alert-danger" id="alert-message" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
          </div>
          <span class="alert-text">
                {{'errorMsg.colorCodesSaveFailError'|translate}}
              </span>
        </div>
      </div>
    </div>


    <div *ngIf="colorRangeErrorAlert" class="alert alert-danger" id="alert-message" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
          </div>
          <span class="alert-text">
                {{'errorMsg.colorCodeRangeError'|translate}}
              </span>
        </div>
      </div>
    </div>

    <div *ngIf="colorDuplicateAlert" class="alert alert-danger" id="alert-message" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
          </div>
          <span class="alert-text">
                {{'errorMsg.colorCodeDuplicateError'|translate}}
              </span>
        </div>
      </div>
    </div>

    <form #addForm="ngForm" id="colorForm" autocomplete="off" clrForm>
      <div class="clr-row">
        <div class="clr-col-9">
          <ngx-slider [(highValue)]="highValue" [(value)]="value" [options]="options"></ngx-slider>
        </div>
        <div class="color-picker-label">{{'analysisAndReports.reports.backgroundColor' | translate}} :</div>
        <div class="clr-col-1">
          <input [(colorPicker)]="selectedColor" [cpAlphaChannel]="'disabled'" [cpDisableInput]="false"
                 [cpOutputFormat]="'hex'" [cpSaveClickOutside]="'true'" [style.background]="selectedColor"
                 class="color-picker"/>
        </div>
        <div class="color-picker-label">{{'analysisAndReports.reports.fontColor' | translate}} :</div>
        <div class="clr-col-1">
          <input [(colorPicker)]="selectedFontColor" [cpAlphaChannel]="'disabled'" [cpDisableInput]="false"
                 [cpOutputFormat]="'hex'" [cpSaveClickOutside]="'true'" [style.background]="selectedFontColor"
                 class="color-picker" />
        </div>
        <div class="clr-col-2" id="addColorBtn">
          <button (click)="onAddColorToList()" class="btn btn-sm btn-primary">{{"analysisAndReports.reports.addBtn"
            | translate}}</button>
        </div>
      </div>
    </form>

    <clr-datagrid class="popup-datagrid">
      <clr-dg-column>{{"analysisAndReports.reports.from" | translate}}</clr-dg-column>
      <clr-dg-column>{{"analysisAndReports.reports.to" | translate}}</clr-dg-column>
      <clr-dg-column>{{"analysisAndReports.reports.backgroundColor" | translate}}</clr-dg-column>
      <clr-dg-column>{{"analysisAndReports.reports.fontColor" | translate}}</clr-dg-column>
      <clr-dg-column class="action-cell"></clr-dg-column>
      <clr-dg-row *clrDgItems="let item of getAvailableColorCodes(); let i = index;">
        <clr-dg-cell>{{item.from}}%</clr-dg-cell>
        <clr-dg-cell>{{item.to}}%</clr-dg-cell>
        <clr-dg-cell>
          <span [style.background]="item.backgroundColorHexCode" class="color-square"></span>
        </clr-dg-cell>
        <clr-dg-cell>
          <span [style.background]="item.fontColorHexCode" class="color-square"></span>
        </clr-dg-cell>
        <clr-dg-cell class="action-cell">
          <clr-dg-action-overflow>
            <button (click)="onDeleteColorCode(item)" class="action-item" type="button">{{'admin.deleteBtn' |
              translate}}</button>
          </clr-dg-action-overflow>
        </clr-dg-cell>
      </clr-dg-row>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button (click)="closeColorPicker.emit()" class="btn btn-outline btn-sm">
      {{'admin.cancelBtn' | translate}}</button>
    <button (click)="applyColorCodeChangesToComponent()"
            class="btn btn-primary btn-sm">{{"analysisAndReports.reports.applyBtn" | translate}}</button>
    <button class="btn btn-primary btn-sm" (click)="openLoadDefaultPopUp()" [disabled]="!loadDefaultBtnEnable || getAvailableColorCodes().length === 0">
      {{"analysisAndReports.reports.loadDefaultBtn" | translate}}</button>
    <button class="btn btn-primary btn-sm"
            (click)="setDefaultColorCode()">{{"analysisAndReports.reports.setDefaultBtn" | translate}}</button>
  </div>
</clr-modal>

<clr-modal *ngIf="showLoadDefaultPopUp" [(clrModalOpen)]="showLoadDefaultPopUp">
  <h3 class="modal-title">{{"analysisAndReports.reports.loadDefaultHeaderMsg" | translate}}</h3>
  <div class="modal-body">{{"analysisAndReports.reports.loadDefaultWarningMsg" | translate}}</div>
  <div class="modal-footer">
    <button (click)="showLoadDefaultPopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="setLoadDefaultColorCode()"
            class="btn btn-outline btn-sm">{{'analysisAndReports.reports.loadDefaultPopUpBtn' |
      translate}}</button>
  </div>
</clr-modal>
