<div class="ksa-info">
  <div *ngIf="surveySaveSuccessAlert && messageSuccessTimer && !errorMessage" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{'ksaEngagement.masterKsa.message.ksaSavedMsg'|translate}}
        </span>
      </div>
    </div>
  </div>
  <clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="true" [clrAlertSizeSmall]="true" clrAlertType="danger">
    <clr-alert-item>{{errorMsg}}</clr-alert-item>
  </clr-alert>
  <form clrForm #addForm="ngForm" autocomplete="off" class="clr-form">
    <div class="clr-row">
      <div class="clr-col-10">
        <div>
          <clr-input-container class="clr-row">
            <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.ksaId' | translate}}*</label>
            <input [(ngModel)]="newKsa.ksaName" [disabled]="!hasWritePermission" class="clr-col-5 input-field" clrInput
                   name="ksaName"
                   placeholder="{{'placeholder.ksaName' | translate}}" required type="text"
                   (focusout)="checkIfKsaNameExists(newKsa.ksaName)"/>
            <clr-control-error>{{'errorMsg.ksaNameRequired' | translate}}</clr-control-error>
          </clr-input-container>
        </div>
        <div class="clr-row">
          <div class="clr-col-4"></div>
          <div *ngIf="showDuplicateKsaError" class="errorSurveyName clr-col-5 input-field">
            {{'ksaEngagement.ksaAdministration.duplicateMsg'|translate}}
          </div>
        </div>
        <div>
          <clr-textarea-container class="clr-row">
            <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.description' | translate}}</label>
            <textarea [(ngModel)]="newKsa.description" [disabled]="!hasWritePermission" class="clr-col-5 input-field"
                      clrTextarea name="description"
                      placeholder="{{'placeholder.ksaDescription' | translate}}" rows="1"></textarea>
          </clr-textarea-container>
        </div>
        <div>
          <clr-input-container class="clr-row">
            <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.customer' | translate}}*</label>
            <input [(ngModel)]="newKsa.customer" [disabled]="!hasWritePermission"
                   class="clr-col-5 input-field" clrInput
                   name="customer"
                   placeholder="{{'placeholder.ksaCustomer' | translate}}" required type="text"/>
            <clr-control-error>{{'errorMsg.customerNameRequired' | translate}}</clr-control-error>
          </clr-input-container>
        </div>
        <div>
          <clr-select-container class="clr-row">
            <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.team' | translate}}*</label>
            <select id="teams" class="clr-col-5 solution-group-select-input input-field"
                    clrSelect name="ksaTeam"
                    (change)="onTeamOptionSelect($event.target.value)" [(ngModel)]="newKsa.ksaTeam.id"
                    [disabled]="!hasWritePermission" required>
              <option value="onOpenTeamModal"
                      *hasAccess="functionality.ksaTeamManagement">{{'ksaEngagement.ksaTeamManagement.addTeam' | translate}}</option>
              <option *ngFor="let team of ksaTeams?.content" [value]="team.id">{{team.name}}</option>
            </select>
            <clr-control-error>{{'errorMsg.teamRequired' | translate}}</clr-control-error>
          </clr-select-container>
        </div>
        <div>
          <clr-select-container class="clr-row">
            <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.geo' | translate}}*</label>
            <select [(ngModel)]="newKsa.geo.id" [disabled]="!hasWritePermission"
                    class="clr-col-5 solution-group-select-input input-field" clrSelect
                    name="geo" required (change)="onSelectGeo()">
              <option *ngFor="let geo of allGeo" [value]="geo.id">{{geo.geoName}}</option>
            </select>
            <clr-control-error>{{'errorMsg.geoRequired' | translate}}</clr-control-error>
          </clr-select-container>
        </div>
        <div>
          <clr-select-container class="clr-row">
            <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.country' | translate}}*</label>
            <select [(ngModel)]="newKsa.country.id" [disabled]="!hasWritePermission"
                    class="clr-col-5 solution-group-select-input input-field" clrSelect
                    name="country" required type="text">
              <option *ngFor="let country of countries[0]?.countryList" [value]="country.id">{{country.countryName}}
              </option>
            </select>
            <clr-control-error>{{'errorMsg.regionCountryRequired' | translate}}</clr-control-error>
          </clr-select-container>
        </div>
        <div>
          <clr-combobox-container class="clr-row">
            <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.masterSurveyTemplate'|translate}}*</label>
            <clr-combobox class="clr-col-5" [(ngModel)]="masterSurveyName" name="masterSurveyName" required
                          (clrInputChange)="onSelectSurveyName()"
                          [disabled]="isDisabled() ">
              <clr-options>
                <clr-option *clrOptionItems="let item of surveyItems" [clrValue]="item">
                  {{item}}
                </clr-option>
              </clr-options>
            </clr-combobox>
            <clr-control-error>{{'errorMsg.defaultTemplateRequired' | translate}}</clr-control-error>
          </clr-combobox-container>
        </div>
        <clr-modal [(clrModalOpen)]="showSurveyChangeWarning" [clrModalClosable]="false">
          <h3 class="modal-title">{{'ksaEngagement.ksaAdministration.warningChangeMasterSurveyHeader'|translate}}
          </h3>
          <div class="modal-footer">
            <button (click)="showSurveyChangeWarning = false;this.newKsa.isAdvance = false;"
                    class="btn btn-primary btn-sm" type="button">{{'admin.okBtn' |
              translate}}</button>
          </div>
        </clr-modal>
        <div>
          <div *ngIf="surveyItemVersions?.length > 1">
            <clr-select-container class="clr-row" id="multipleVersion">
              <label
                class="clr-col-4">{{'ksaEngagement.ksaAdministration.selectMasterSurveyVersion'|translate}}*
              </label>
              <select [(ngModel)]="masterSurveyVersion" class="clr-col-5 input-field" clrSelect
                      name="masterSurveyVersion" (change)="getMasterSurveyTemplateById(masterSurveyVersion)"
                      [disabled]="isDisabled()">
                <option disabled selected value="">{{'ksaEngagement.masterKsa.placeholder.selectSurvey'|translate}}
                </option>
                <option *ngFor="let item of surveyItemVersions" [ngValue]="item.id">{{item.version}}
                  - {{item?.surveyVersionDescription}}</option>
              </select>
              <clr-control-error>{{'errorMsg.defaultTemplateRequired' | translate}}</clr-control-error>
            </clr-select-container>
          </div>
          <div *ngIf="surveyItemVersions?.length == 1">
            <clr-select-container class="clr-row" id="singleVersion">
              <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.selectMasterSurveyVersion'|translate}}*
              </label>
              <select [(ngModel)]="masterSurveyVersion" class="clr-col-5 input-field" clrSelect
                      name="masterSurveyVersion" (change)="getMasterSurveyTemplateById(masterSurveyVersion)"
                      [disabled]="isDisabled()">
                <option selected [value]="surveyItemVersions[0].id">{{surveyItemVersions[0].version}}</option>
              </select>
              <clr-control-error>{{'errorMsg.defaultTemplateRequired' | translate}}</clr-control-error>
            </clr-select-container>
          </div>
        </div>

        <clr-select-container class="clr-row field-height">
          <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.language' | translate}}*</label>
          <select id="languages" [(ngModel)]="selectedMasterSurveyLanguage" class="clr-col-5 input-field" clrSelect
                  name="surveyLanguage" required [disabled]="isDisabled()">
            <option selected *ngFor="let lang of masterSurveyPublishedLanguages" [value]="lang">{{lang}}</option>
          </select>
          <clr-control-error>{{'errorMsg.defaultTemplateRequired' | translate}}</clr-control-error>
        </clr-select-container>

        <div *ngIf="masterSurveyVersion && masterSurveyTemplateSelected?.surveyDescription!=''">
          <clr-textarea-container class="clr-row">
            <label
              class="clr-col-4 surveyDescription">{{'ksaEngagement.ksaAdministration.surveyDescription' | translate}}</label>
            <textarea [value]="masterSurveyTemplateSelected?.surveyDescription" class="clr-col-5 input-field"
                      clrTextarea name="description" rows="1" [disabled]="true"></textarea>
          </clr-textarea-container>
        </div>
        <clr-select-container class="clr-row field-height">
          <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.anonymousSurvey'|translate}} *</label>
          <select [(ngModel)]="newKsa.anonymityType.id" class="clr-col-5 input-field" clrSelect name="surveyType"
                  required [disabled]="isDisabled()">
            <option *ngFor="let type of anonymityTypes" [value]="type.id">{{type.anonymityName}}</option>
          </select>
          <clr-control-error>{{'errorMsg.anomityTypeRequired' | translate}}</clr-control-error>
        </clr-select-container>
        <clr-toggle-container class="clr-row field-height">
          <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.ksaType'|translate}}
            <clr-tooltip class="flex-child tooltip tooltip-lg tooltip-top-right clr-col-1" *ngIf="isSurveySimple">
              <clr-icon shape="help" clrTooltipTrigger badge="info"></clr-icon>
              <clr-tooltip-content>
                {{'ksaEngagement.ksaAdministration.roleProfileQuestionWarning' | translate}}
              </clr-tooltip-content>
            </clr-tooltip>
          </label>
          <clr-toggle-wrapper class="clr-col-1 clr-row">
            <input class="clr-col-1" type="checkbox" clrToggle name="ksaType" [(ngModel)]="newKsa.isAdvance"
                   [disabled]="newKsa.status == statusEnum.Active || masterSurveyVersion == null || masterSurveyName == '' ||
                   !masterSurveyVersion || isSurveySimple || !hasWritePermission"/>
            <label class="clr-col-1">{{'ksaEngagement.ksaAdministration.advanced'|translate}}</label>
          </clr-toggle-wrapper>
          <label class="clr-col-1 light-label">{{'ksaEngagement.ksaAdministration.simple'|translate}}</label>
        </clr-toggle-container>
        <clr-combobox-container class="clr-row field-height">
          <label class="clr-col-4">{{'ksaEngagement.ksaAdministration.stakeholders' | translate}}</label>
          <clr-combobox class="clr-col-5 input-field" [clrMulti]="true" [(ngModel)]="newKsa.stakeholders"
                        [disabled]="!hasWritePermission"
                        (clrSelectionChange)="getAvailableStakeholders($event)"
                        open-combobox-on-click name="stakeholders">
            <span *clrOptionSelected="let selectedUser">
              {{selectedUser.emailId}} ({{selectedUser.fullName}})
            </span>
            <clr-options>
              <clr-option *clrOptionItems="let user of availableStakeholdersUsers" [clrValue]="user">
                {{user.emailId}} ({{user.fullName}})
              </clr-option>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>
      </div>
      <div class="clr-col-2">
        <div class="add-edit-btn-container clear">
          <button *ngIf="hasWritePermission" class="btn btn-sm btn-primary" type="button" (click)="onAddKsa()"
                  [disabled]=
                    "addForm.invalid || showDuplicateKsaError || disableSaveBtnWhileSaving || masterSurveyVersion === undefined">
            {{'admin.saveBtn' | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>


<clr-modal (clrModalOpenChange)="clearForm(ksaTeamsForm)" [(clrModalOpen)]="addKsaTeamFormOpen"
           [clrModalClosable]="true">
  <h3 class="modal-title">{{'ksaEngagement.ksaTeamManagement.addTeam' | translate}}</h3>
  <div class="modal-body">
    <clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true" clrAlertType="danger">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
    <form #ksaTeamsForm="ngForm" (ngSubmit)="onAddKsaTeam(ksaTeamsForm)" autocomplete="off" clrForm>
      <clr-input-container class="clr-row">
        <label class="clr-col-4">{{'ksaEngagement.ksaTeamManagement.teamName' | translate}}* </label>
        <input #isTeamInput="ngModel" [(ngModel)]="getTeamData.name" class="clr-col-8" clrInput name="ksaTeamName"
               pattern="^[^!@#*&(){}<>\/;`%$^:?]*$"
               placeholder="{{'ksaEngagement.ksaTeamManagement.enterTeamName' | translate}}" required
               type="text"/>
        <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}
        </clr-control-error>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterTeam' | translate}}
        </clr-control-error>
      </clr-input-container>
      <clr-textarea-container class="clr-row">
        <label class="clr-col-4">{{'ksaEngagement.ksaTeamManagement.teamDescription' | translate}}</label>
        <textarea #isTeamsDescInput="ngModel" [(ngModel)]="getTeamData.description" class="clr-col-8" clrTextarea
                  name="roleDescription" placeholder="{{'ksaEngagement.ksaTeamManagement.teamDescription' | translate}}"
                  rows="1"></textarea>
      </clr-textarea-container>
      <br>
      <clr-input-container class="clr-row">
        <label class="clr-col-4 clr-control-label">{{'ksaEngagement.ksaTeamManagement.selectUsers' |
          translate}}*</label>
      </clr-input-container>
      <clr-datagrid [(clrDgSelected)]="getTeamData.ksaTeamUserMaps"
                    [clrDgPreserveSelection]="true" class="datagrid-compact child-course-datagrid popup-datagrid clear">
        <clr-dg-column [clrDgField]="'firstName'">{{'ksaEngagement.ksaTeamManagement.firstName' | translate}}
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'lastName'">{{'ksaEngagement.ksaTeamManagement.lastName' | translate}}
        </clr-dg-column>
        <clr-dg-column>{{'ksaEngagement.ksaTeamManagement.role' | translate}}</clr-dg-column>
        <clr-dg-row *clrDgItems="let user of users; let i = index" [clrDgItem]="user"
                    [clrDgSelectable]="user.emailId!=this.username">
          <clr-dg-cell>{{user.firstName}}</clr-dg-cell>
          <clr-dg-cell>{{user.lastName}}</clr-dg-cell>
          <clr-dg-cell>
            <ul *ngFor="let userRole of user.userRoleMaps">
              <li>{{userRole.ksaRole.roleDescription}}</li>
            </ul>
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
      <div class="modal-footer">
        <button (click)="onCancelForm()" class="btn btn-sm btn-outline"
                type="button">{{'admin.cancelBtn'|translate}}</button>
        <button
          [disabled]="isTeamInput.errors?.required ||getTeamData.ksaTeamUserMaps.length===0 || isTeamInput.errors?.pattern"
          class="btn btn-sm btn-primary"
          type="submit">{{'admin.addBtn'|translate}}</button>
      </div>
    </form>
  </div>
</clr-modal>
