/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {EmailTemplate} from "../../../models/email-template.model";
import {EmailTemplateService} from "../../../services/email-template.service";
import {ClrDatagridStateInterface} from '@clr/angular';
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {TranslateService} from '@ngx-translate/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from 'rxjs/operators';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-mng-email-templates',
  templateUrl: './mng-email-templates.component.html',
  styleUrls: ['./mng-email-templates.component.scss']
})
export class MngEmailTemplatesComponent implements OnInit, AfterViewChecked {
  placeHolderNameList: any[] = [];
  state: ClrDatagridStateInterface;
  dateToFilter: string = '';
  page: number;
  loading: boolean = false;
  total: number;
  errorMsg: string = '';
  isEditTemplate: boolean = false;
  isAddTemplate: boolean = false;
  selected: any = [];
  templateId: number;
  showDeleteEmailPopUp: boolean = false;
  deleteId: number;
  showMultiDeleteEmailPopUp:boolean = false;
  emailTemplates: EmailTemplate = {
    content: []
  };

  functionality : typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;
  private datePipe = new DatePipe('en-US');

  constructor(public emailTemplateService: EmailTemplateService,private changeDetector: ChangeDetectorRef,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getPlaceHolder();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  onEditTemplate(id: any) {
    this.templateId = id;
    this.isEditTemplate = true;
  }

  onDeleteEmailTemplate() {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.emailTemplateService.deleteEmailTemplate(this.deleteId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.refresh(this.state);
        this.showDeleteEmailPopUp = false;
        this.errorMsg = '';
    },
        error=>this.errorMsg = error.error.message);
  }

  deleteTemplates(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.selected.map((value: any) => {
      this.emailTemplateService.deleteEmailTemplate(value.id)
        .pipe(finalize(() => hideSpinner()))
        .subscribe(data => {
        this.refresh(this.state);
        this.showMultiDeleteEmailPopUp = false;
      },error => this.errorMsg = error.error.message);
    })
    this.selected = [];
  }

  deleteSelectedTemplate(selected:any[]):void{
    if(selected.length > 0){
      this.showMultiDeleteEmailPopUp = true;
    }
    this.selected = selected;

  }

  getDateFilter(date: string) {
    this.dateToFilter = date ? this.datePipe.transform(new Date(date), 'dd-MM-yyyy') : "";
    this.refresh(this.state);
  }

  getPlaceHolder() {
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.placeHolderNameList = [];
      this.emailTemplateService.getPlaceHolder()
        .pipe(finalize(() => hideSpinner()))
        .subscribe(data => {
          data.forEach(value => {
            this.placeHolderNameList.push(value.name);
          })
        })
    });
  }

  onShowDeleteEmailPopUp(id: any) {
    this.deleteId = id;
    this.showDeleteEmailPopUp = true
  }

  resetError(): void {
    this.errorMsg = '';
  }

  refresh(state: ClrDatagridStateInterface) {
    this.state = state;
    this.loading = true;
    const filters: { [prop: string]: any } = {};
    if (state.filters) {
      for (const filter of state.filters) {
        const {property, value} = <{ property: string; value: string }>filter;
        filters[property] = value;
      }
    }
    filters.createdDate = this.dateToFilter;
    this.emailTemplateService.getEmailTemplatePage(filters?.emailTemplateName === undefined ? '' : filters.emailTemplateName,
      filters?.description === undefined ? '' : filters.description,
      filters?.createdDate === undefined ? '' : filters.createdDate,
      state.page.current - 1, state.page.size, state?.sort?.by === undefined ? 'id' : state?.sort?.by, state?.sort?.reverse === undefined ? 'ASC' : state?.sort?.reverse)
      .subscribe((results: any) => {
        this.emailTemplates = results;
        this.total = results.totalElements;
        this.loading = false;
        this.selected = [];
      })
  }

  isAddTemplateData(showValue : boolean): void{
    this.isAddTemplate = showValue;
  }

  isEditTemplateData(showValue : boolean): void{
    this.isEditTemplate = showValue;
  }
}
