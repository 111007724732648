import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {KsaEmailManagementService} from "../../../services/ksa-email-management.service";

@Component({
  selector: 'app-survey',
  templateUrl: './anonymous.component.html',
  styleUrls: ['./anonymous.component.scss']
})
export class AnonymousComponent implements OnInit {

  ipAddress: string="";
  anonymousDetails: {anonymousUrl : any, ipAddress: any};

  constructor(public ksaEmailService: KsaEmailManagementService, private router: Router) { }

  ngOnInit(): void {
    this.getIp();
  }

  validateUrl(){
    this.anonymousDetails = {anonymousUrl: window.location.href, ipAddress: this.ipAddress};
    this.ksaEmailService.validateAndGetAnonymousId(this.anonymousDetails).subscribe((data) => {
      const uuid = data.split('anonymous/').pop();
      this.router.navigateByUrl(`/survey/${uuid}`);
    })
  }

  getIp(){
    this.ksaEmailService.getIPAddress().subscribe((data : any)=>{
      this.ipAddress=data.ip;
      if (this.ipAddress) {
        this.validateUrl();
      }
    })
  }

}
