/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ResponseService} from "../../../../services/response.service";
import {ResponseColumnSettings, ResponseOriginalData} from "../../../../models/respose-data.model";
import {ClrDatagridStateInterface} from "@clr/angular";

@Component({
  selector: 'app-response-original-data',
  templateUrl: './response-original-data.component.html',
  styleUrls: ['./response-original-data.component.scss']
})
export class ResponseOriginalDataComponent implements OnInit {

  ksaId: number;
  state: ClrDatagridStateInterface;
  loading: boolean = false;
  total: number;
  page: number;
  responseColumnSettings: (ResponseColumnSettings)[];
  responseData: (ResponseOriginalData)[];

  constructor(public responseService: ResponseService,private route: ActivatedRoute,
              private cdr: ChangeDetectorRef) { }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.ksaId = params.id;
    });
    this.getAllResponseColumnData(this.ksaId);
    if(this.state) {
      this.refreshDatagrid(this.state);
    }
  }

  showOriginalData(id: number){
    this.responseService.getOriginalResponseData(id).subscribe((data: any)=>{
      if(data){
        this.responseData = data.responseData;
      }
    })
  }

  getAllResponseColumnData(id: number): void{
    this.responseService.getAllResponseColumnData(id).subscribe(data=>{
      if(data){
        this.responseColumnSettings = data;
      }
    })
  }

  refreshDatagrid(state: ClrDatagridStateInterface) {
    this.state = state;
    this.loading = true;
    const colFilters: ({ columnName: string; columnValue: string; })[] = [];
    let columnName = '';
    let data;
    if (state?.filters) {
      state.filters.forEach((filter,i)=> {
        const {property, value} = <{ property: string; value: string }>filter;
        colFilters.push({columnValue : value, columnName : property});
        columnName = property;
      })
      data={
        ksaId: this.ksaId,
        size: state?.page?.size,
        sortColumn: columnName,
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false)  ? 'ASC' : 'DESC',
        page: state?.page?.current - 1,
        analysisFilterDTOS: colFilters
      }
    }else{
      data={
        ksaId: this.ksaId,
        size: state?.page?.size,
        sortColumn: state?.sort ? state.sort.by : "",
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false)  ? 'ASC' : 'DESC',
        page: state?.page?.current - 1
      }
    }
    this.responseService.updateOriginalResponse(data)
      .subscribe((results) => {
        this.responseData = results.responseData;
        this.total = results.total;
        this.loading = false;
      });

  }

}
