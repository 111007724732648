<clr-main-container>
  <div class="content-container">
    <main class="content-area">
      <clr-datagrid class="original-response-datagrid">
          <clr-dg-column [clrDgField]="'skill'">Skill</clr-dg-column>
          <clr-dg-column [clrDgField]="'question'">Question</clr-dg-column>
          <clr-dg-column [clrDgField]="'answer'">Answer</clr-dg-column>

          <clr-dg-row *clrDgItems="let user of users">
            <clr-dg-cell>{{user.skill}}</clr-dg-cell>
            <clr-dg-cell>{{user.question}}</clr-dg-cell>
            <clr-dg-cell>{{user.answer}}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>{{users.length}}  {{"analysisAndReports.reports.participantAnswer.questions" | translate}}</clr-dg-footer>
      </clr-datagrid>
    </main>
  </div>
</clr-main-container>
