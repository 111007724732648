import {Component, OnInit} from '@angular/core';
import {hideSpinner, showSpinner} from "../../../../common/spinner";
import {TranslateService} from "@ngx-translate/core";
import {finalize} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {
  MasterSurveyTranslation,
  MasterSurveyTranslationQuestion,
  MasterSurveyTranslationSection
} from "../../../../models/master-survey-translation.model";
import {LocalizationStatusEnum} from "../../../../enums/localization-status.enum";
import {MasterSurveyTranslationService} from "../../../../services/master-survey-translation.service";
import {LocalizationLocaleEnum} from '../../../../enums/localization-locale.enum';
import {TranslateMasterSurveyService} from "./translate-master-survey.service";
import {QuestionTypeFile} from "../../../../enums/questionTypeFile.enum";
import {ButtonId} from "../../../../enums/buttonId.enum";
import {Functionality} from "../../../../enums/functionality.enum";

export enum TranslationItemType {
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER'
}

export type UpdateTranslationRequest = {
  updateIds: number[],
  type: TranslationItemType,
  language: LocalizationLocaleEnum,
  newText: string
}

@Component({
  selector: 'app-translate-master-survey',
  templateUrl: './translate-master-survey.component.html',
  styleUrls: ['./translate-master-survey.component.scss']
})
export class TranslateMasterSurveyComponent implements OnInit {
  masterSurveyId: number;
  language: LocalizationLocaleEnum;
  masterSurveyTranslation: MasterSurveyTranslation;
  totalQuestions = 0;
  publishedQuestions = 0;
  errorMsg: string;

  constructor(private readonly masterSurveyTranslationService: MasterSurveyTranslationService,
              private readonly route: ActivatedRoute,
              private readonly translate: TranslateService,
              private readonly service: TranslateMasterSurveyService) {
  }

  ngOnInit(): void {
    this.translate.get("spinnerLabels.common.loading").subscribe(localizedLoadingLabel => {
      showSpinner(localizedLoadingLabel);
      const params = this.route.snapshot.paramMap;

      this.masterSurveyId = +params.get('surveyId');
      this.language = LocalizationLocaleEnum[params.get('language')];

      this.masterSurveyTranslationService.getMasterSurveyTranslations(this.masterSurveyId, this.language)
        .pipe(finalize(hideSpinner))
        .subscribe((data: MasterSurveyTranslation) => {
            this.masterSurveyTranslation = data;
            this.calculateTotalQuestions();
            this.calculatePublishedQuestions();
          },
          error => this.errorMsg = error.error.message);
    });
  }

  onQuestionAccordionOpenEvent(question: MasterSurveyTranslationQuestion) {
    if (question.status !== LocalizationStatusEnum.DRAFT) {
      return;
    }

    this.masterSurveyTranslationService.publishMasterSurveyQuestion(question.id, this.language)
      .subscribe(() => {
        question.status = LocalizationStatusEnum.PUBLISHED;
        this.calculatePublishedQuestions();
      });
  }

  isSectionPublished(section: MasterSurveyTranslationSection): boolean {
    for (const q of section.questions) {
      if (q.status !== LocalizationStatusEnum.PUBLISHED) {
        return false;
      }
    }
    return true;
  }

  updateMasterSurveyQuestion(question: MasterSurveyTranslationQuestion): void {
    this.translate.get("spinnerLabels.common.loading").subscribe(localizedLoadingLabel => {
      showSpinner(localizedLoadingLabel);
      const requestBody: UpdateTranslationRequest[] = this.createUpdateRequestBody(question);

      this.masterSurveyTranslationService.updateMasterSurveyTranslation(requestBody)
        .pipe(finalize(hideSpinner))
        .subscribe(() => {
          this.updateMasterSurveyTranslation(requestBody);
          this.calculatePublishedQuestions();
        });
    });
  }

  private calculatePublishedQuestions(): void {
    this.publishedQuestions = this.getQuestions().filter(question => question.status === LocalizationStatusEnum.PUBLISHED).length;
  }

  private calculateTotalQuestions() {
    this.totalQuestions = this.masterSurveyTranslation.sections.map(s => s.questions.length).reduce((a, b) => a + b, 0);
  }

  private createUpdateRequestBody(question: MasterSurveyTranslationQuestion): UpdateTranslationRequest[] {
    const questionIds: number[] = this.getQuestions().filter(q => q.enText === question.enText).map(q => q.id);

    const requestBody: UpdateTranslationRequest[] = [{
      updateIds: questionIds,
      type: TranslationItemType.QUESTION,
      language: this.language,
      newText: question.translation
    }];

    question.answers.forEach(updatedAnswer => {
      const answerIds: number[] = this.getQuestions().flatMap(q => q.answers).filter(a => a.enText === updatedAnswer.enText).map(a => a.id);

      requestBody.push({
        updateIds: answerIds,
        type: TranslationItemType.ANSWER,
        language: this.language,
        newText: updatedAnswer.translation
      })
    })

    return requestBody;
  }

  private updateMasterSurveyTranslation(updateTranslation: UpdateTranslationRequest[]): void {
    const questionIds = updateTranslation.filter(u => u.type === TranslationItemType.QUESTION).flatMap(u => u.updateIds);
    const answerIds = updateTranslation.filter(u => u.type === TranslationItemType.ANSWER).flatMap(u => u.updateIds);
    const questions = this.getQuestions().filter(q => questionIds.includes(q.id) || q.answers.map(a => a.id).some(value => answerIds.includes(value)));

    updateTranslation.forEach(u => {
      if (u.type === TranslationItemType.QUESTION) {
        questions.filter(q => u.updateIds.includes(q.id))
          .forEach(q => q.translation = u.newText);
      } else {
        questions.flatMap(q => q.answers)
          .filter(a => u.updateIds.includes(a.id))
          .forEach(a => a.translation = u.newText);
      }
    });

    this.service.questionsSubject.next(questions);
  }

  publishMasterSurvey(): void {
    this.translate.get("spinnerLabels.common.loading").subscribe(localizedLoadingLabel => {
      showSpinner(localizedLoadingLabel);

      this.masterSurveyTranslationService.updateMasterSurveyStatus(this.masterSurveyId, this.language, LocalizationStatusEnum.PUBLISHED)
        .pipe(finalize(hideSpinner))
        .subscribe(() => this.masterSurveyTranslation.status = LocalizationStatusEnum.PUBLISHED,
          error => this.errorMsg = error);
    });
  }

  get localizationStatusEnum(): typeof LocalizationStatusEnum {
    return LocalizationStatusEnum;
  }

  get questionTypeEnum(): typeof QuestionTypeFile {
    return QuestionTypeFile;
  }

  get btnId(): typeof ButtonId {
    return ButtonId;
  }

  get functionality(): typeof Functionality {
    return Functionality;
  }

  private getQuestions(): MasterSurveyTranslationQuestion[] {
    return this.masterSurveyTranslation.sections.flatMap(section => section.questions);
  }
}
