<div *ngIf="showLoader">
  <clr-spinner>Loading ...</clr-spinner>
</div>

<div *ngIf="!showLoader">

<div *ngIf="disableOnMissingQuestionTxt || shareSurveyData.getIsSurveyInvalid() || showSaveErrorAlert"
     class="alert alert-danger" id="alert-message" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span *ngIf="disableOnMissingQuestionTxt" class="alert-text">
        {{'surveyTemplate.masterSurvey.missingQuestionTextErrorMsg'|translate}}
      </span>
      <span *ngIf="shareSurveyData.getIsSurveyInvalid()" class="alert-text">
        {{'surveyTemplate.masterSurvey.invalidSurveyMsg'|translate}}
      </span>
      <span *ngIf="!showSaveSuccessMessage && showSaveErrorAlert" class="alert-text">
        {{'surveyTemplate.masterSurvey.errorMsg'|translate}}
      </span>
    </div>
  </div>
</div>
  <div *ngIf="showSaveSuccessMessage && !showSaveErrorAlert" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{'surveyTemplate.masterSurvey.surveySavedMsg'|translate}}
        </span>
      </div>
    </div>
  </div>

<div class="survey-Container">
  <form clrForm class="clr-row">
    <clr-input-container class="clr-col-4">
      <input [(ngModel)]="masterSurveyName" autocomplete="off" class="surveyNameInput" clrInput disabled
             name="surveyName"/>
    </clr-input-container>
    <clr-select-container class="clr-col-6 clr-row">
      <label class="clr-col-3">{{'surveyTemplate.mapSkill.selectSkill'|translate}}*</label>
      <select [(ngModel)]="skillToMap" [disabled]="!hasWritePermission" class="selectSkill clr-col-6" clrSelect
              name="skill" placeholder="select skill*">
        <option *ngFor="let skill of skillNames" [value]="skill.skillName">
          {{skill.skillName}}
        </option>
      </select>
    </clr-select-container>
    <div class="clr-col-2 mapSkill-btn-container">
      <button (click)="onMapSkill()" *ngIf="hasWritePermission"
              [disabled]="selectedQuestions.length == 0 || skillToMap == '' || disableOnMissingQuestionTxt"
              class="btn btn-primary btn-sm">
        {{'tab.masterSurvey.mapSkill'|translate}}</button>
      <button (click)="shareSurveyData.saveSurvey()" *ngIf="hasWritePermission"
              [disabled]="isSaveDisabled || disableOnMissingQuestionTxt"
              class="btn btn-primary btn-sm">
        {{'tab.masterSurvey.save'|translate}}</button>
    </div>
  </form>

  <clr-datagrid [(clrDgSelected)]="selectedQuestions" [clrDgRowSelection]="false" class="parent-datagrid">
    <clr-dg-column [clrDgField]="'question'">{{'surveyTemplate.mapSkill.question'|translate}}</clr-dg-column>
    <clr-dg-column [clrDgField]="'section'">{{'surveyTemplate.mapSkill.section'|translate}}</clr-dg-column>
    <clr-dg-column [clrDgField]="'skill'">{{'surveyTemplate.mapSkill.skill'|translate}}</clr-dg-column>
    <clr-dg-row *clrDgItems="let sectionQuestion of questionDataToDisplay" [clrDgItem]="sectionQuestion"
                [clrDgSelectable]="skillToMap!=''?sectionQuestion.skill !== skillToMap:''">
      <clr-dg-cell>{{sectionQuestion.question}}</clr-dg-cell>
      <clr-dg-cell>{{sectionQuestion.section}}</clr-dg-cell>
      <clr-dg-cell>{{sectionQuestion.skill}}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
          {{'surveyTemplate.mapSkill.skillMapQuestionpage'|translate}}</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
        {{'surveyTemplate.mapSkill.questions'|translate}}
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>
</div>

