<clr-tabs>
    <clr-tab>
      <button clrTabLink>{{'tabKsa.ksaInformation'|translate}}</button>
      <clr-tab-content *clrIfActive='showMetaDataTab' aria-selected="false">
        <app-ksa-metadata (disabledOtherTab)="onDisableOtherTabs($event)"
        (enableRoleProfileTab)="onEnableRoleProfileTab($event)"></app-ksa-metadata>
      </clr-tab-content>
    </clr-tab>
    <clr-tab *ngIf="enableRoleProfileTab">
      <button clrTabLink>{{'tabKsa.roleProfile'|translate}}</button>
      <clr-tab-content *clrIfActive='showRoleProfileTab' aria-selected="false">
        <app-tab-role-profile></app-tab-role-profile>
      </clr-tab-content>
    </clr-tab>
    <clr-tab>
      <button clrTabLink [disabled]="disableSettingsTab">{{'tabKsa.settings'|translate}}</button>
      <clr-tab-content *clrIfActive='showSettingsTab' aria-selected="false">
        <app-ksa-settings></app-ksa-settings>
      </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink [disabled]="disableSettingsTab">{{'tabKsa.ksa'|translate}}</button>
      <clr-tab-content *clrIfActive='showKsaTab' aria-selected="false">
        <app-ksa-details></app-ksa-details>
      </clr-tab-content>
    </clr-tab>
  <clr-tab>
    <button clrTabLink [disabled]="disableSettingsTab">{{'tabKsa.preview'|translate}}</button>
    <clr-tab-content *clrIfActive='showPreviewTab'>
      <app-ksa-preview></app-ksa-preview>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>
<button (click)="showTablePopUp=true" class="btn btn-sm btn-secondary btn-show-ksa-survey" type="button">
  {{'ksaEngagement.ksaAdministration.showKsa'|translate}}
</button>
<clr-modal [(clrModalOpen)]="showTablePopUp" class="showMasetertableButton">
  <h3 class="modal-title">{{'surveyTemplate.masterSurveyTabs.saveInfomsg'|translate}}</h3>
  <div class="modal-body">{{'ksaEngagement.ksaAdministration.saveInfoMsgBodyKsa'|translate}}</div>
  <div class="modal-footer">
    <button (click)="showTablePopUp=false" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' |
      translate}}</button>
    <button (click)="onShowTable()"
            class="btn btn-outline btn-sm">{{'ksaEngagement.ksaAdministration.showKsa'|translate}}</button>
  </div>
</clr-modal>
<clr-modal *ngIf="editingSessionExpired" [clrModalOpen]="true" (clrModalOpenChange)=onSessionExpireClick()>
  <h3 class="modal-title">{{'ksaEngagement.ksaAdministration.sessionExpiredModalTitle'|translate}}
  </h3>
  <div class="modal-body">
    <div class="alert alert-warning">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <hr>
          <span class="alert-text">
              {{'ksaEngagement.ksaAdministration.sessionExpiredModalTextSentenceInfo'|translate}}
            <br>
            {{'ksaEngagement.ksaAdministration.sessionExpiredModalTextSentenceAction'|translate}}
            </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"
            (click)="onSessionExpireClick()">{{'ksaEngagement.ksaAdministration.sessionExpiredModalRedirectButtonText'|translate}}</button>
  </div>
</clr-modal>
