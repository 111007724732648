/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {QuestionType} from '../enums/questionType.enum';
import {TechnicalQuestionKsa} from '../models/master-ksa.model';
import {ParticipantQuestion} from '../models/participant-ksa.model';
import {ParticipantSurvey} from '../models/particpant-survey.model';

@Injectable({
  providedIn: 'root'
})
export class ParticipantFunctionalityService {

  questionTypeEnum: typeof QuestionType = QuestionType;

  mapMultipleAnswerLabelListForTechnicalQuestion(ksaDetails: ParticipantSurvey, questionTechnical): void {
    ksaDetails.ksaTechnicalQuestionAnswerByParticipants.push({
      id: questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.id || null,
      ksaTechnicalAnswerByParticipants: [],
      displayQuestion: questionTechnical.sectionQuestion.isDisplay,
      ksaTechnicalQuestion: {
        id: questionTechnical.id
      },
      visitedQuestion: questionTechnical.sectionQuestion.isDisplay ? questionTechnical.sectionQuestion.isVisited : false,
      sectionQuestionDisplay: questionTechnical.sectionQuestion.isSectionQuestionDisplay,
      skipDisplay: questionTechnical.sectionQuestion.isSkipDisplay,
      roleProfileDisplay: questionTechnical.sectionQuestion.isRoleProfileDisplay
    });
  }

  mapMatrixAnswerControlsForTechnicalQuestion(ksaDetails: ParticipantSurvey, questionTechnical: TechnicalQuestionKsa, techQuestionCount: number): void {
    questionTechnical.sectionQuestion.question.answerControls.forEach((label, index) => {
      ksaDetails.ksaTechnicalQuestionAnswerByParticipants[techQuestionCount].ksaTechnicalAnswerByParticipants.push({
        id: questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.ksaTechnicalAnswerByParticipants[index]?.id || null,
        indexId: questionTechnical.sectionQuestion.isDisplay ? label.indexId : null,
        rowNumber: questionTechnical.sectionQuestion.isDisplay ? label.rowNumber : null,
        columnNumber: questionTechnical.sectionQuestion.isDisplay ? label.colNumber : null,
        isAnswerChecked: questionTechnical.sectionQuestion.isDisplay ? label.isChecked : false
      });
    });
  }

  mapMultipleChoiceLabelsForTechnicalQuestion(ksaDetails: ParticipantSurvey, questionTechnical: TechnicalQuestionKsa, techQuestionCount: number): void {
    questionTechnical.sectionQuestion.question.answerLabels.forEach((label, index) => {
      ksaDetails.ksaTechnicalQuestionAnswerByParticipants[techQuestionCount].ksaTechnicalAnswerByParticipants.push({
        id: questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.ksaTechnicalAnswerByParticipants[index]?.id || null,
        roleProfileId: null,
        indexId: label.indexId ? label.indexId : null,
        answerNumber: null,
        answerText: null,
        isAnswerChecked: questionTechnical.sectionQuestion.isDisplay ? label.isChecked : false,
        answerLabel: {
          id: label.id
        }
      });
    });
  }

  mapNonLabelQuestionForFalseIsDisplayForTechnicalQuestion(ksaDetails: ParticipantSurvey, questionTechnical: TechnicalQuestionKsa): void {
    ksaDetails.ksaTechnicalQuestionAnswerByParticipants.push({
      id: questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.id || null,
      ksaTechnicalAnswerByParticipants: [
        {
          id: questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.ksaTechnicalAnswerByParticipants[0]?.id || null,
          roleProfileId: null,
          answerNumber: null,
          answerText: null,
          isAnswerChecked: false,
        }
      ],
      displayQuestion: false,
      visitedQuestion: false,
      sectionQuestionDisplay: false,
      skipDisplay: false,
      roleProfileDisplay: questionTechnical?.sectionQuestion?.isRoleProfileDisplay || false,
      ksaTechnicalQuestion: {
        id: questionTechnical.id
      }
    });
  }

  mapNonLabelQuestionForTrueIsDisplayForTechnicalQuestion(ksaDetails: ParticipantSurvey, questionTechnical: TechnicalQuestionKsa): void {
    ksaDetails.ksaTechnicalQuestionAnswerByParticipants.push({
      id: questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.id || null,
      ksaTechnicalAnswerByParticipants: [
        {
          id: questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.ksaTechnicalAnswerByParticipants[0]?.id || null,
          roleProfileId: questionTechnical.sectionQuestion.question.questionType.id === this.questionTypeEnum.RoleProfile ?
            questionTechnical.sectionQuestion.question.answerValuesText : null,
          answerNumber: questionTechnical.sectionQuestion.question.questionType.id === this.questionTypeEnum.Scale ?
            questionTechnical.sectionQuestion.question.answerValuesText : null,
          answerText: questionTechnical.sectionQuestion.question.questionType.id === this.questionTypeEnum.FreeText ?
            questionTechnical.sectionQuestion.question.answerValuesText : null,
          isAnswerChecked: false,
          indexId: questionTechnical.sectionQuestion.question.questionType.id === this.questionTypeEnum.Scale ?
            questionTechnical.sectionQuestion.question.scaleValueIndex : null
        }
      ],
      displayQuestion: questionTechnical.sectionQuestion.isDisplay,
      visitedQuestion:  questionTechnical.sectionQuestion.isDisplay ? questionTechnical.sectionQuestion.isVisited : false,
      sectionQuestionDisplay: questionTechnical.sectionQuestion.isSectionQuestionDisplay,
      skipDisplay: questionTechnical.sectionQuestion.isSkipDisplay,
      roleProfileDisplay: questionTechnical.sectionQuestion.isRoleProfileDisplay,
      ksaTechnicalQuestion: {
        id: questionTechnical.id
      }
    });
  }

  mapMultipleAnswerLabelListForNonTechnicalQuestion(ksaDetails: ParticipantSurvey, questionNonTechnical: ParticipantQuestion): void {
    ksaDetails.ksaQuestionAnswerByParticipants.push({
      id: questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.id || null,
      ksaAnswerByParticipants: [],
      displayQuestion: questionNonTechnical.isDisplay,
      ksaQuestion: {
        id: questionNonTechnical.id
      },
      visitedQuestion: questionNonTechnical.isDisplay ? questionNonTechnical.isVisited : false,
      sectionQuestionDisplay: questionNonTechnical.isSectionQuestionDisplay,
      skipDisplay: questionNonTechnical.isSkipDisplay,
      roleProfileDisplay: questionNonTechnical.isRoleProfileDisplay
    });
  }

  mapMatrixAnswerControlsForNonTechnicalQuestion(ksaDetails: ParticipantSurvey, questionNonTechnical: ParticipantQuestion, nonTechQuestionCount: number): void {
    questionNonTechnical.question.answerControls.forEach((label, index) => {
      ksaDetails.ksaQuestionAnswerByParticipants[nonTechQuestionCount].ksaAnswerByParticipants.push({
        id: questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.ksaAnswerByParticipants?.[index]?.id || null,
        indexId: questionNonTechnical.isDisplay ? label.indexId : null,
        rowNumber: questionNonTechnical.isDisplay ? label.rowNumber : null,
        columnNumber: questionNonTechnical.isDisplay ? label.colNumber : null,
        isAnswerChecked: questionNonTechnical.isDisplay ? label.isChecked : false
      });
    });
  }

  mapMultipleChoiceLabelsForNonTechnicalQuestion(ksaDetails: ParticipantSurvey, questionNonTechnical: ParticipantQuestion, nonTechQuestionCount: number): void {
    questionNonTechnical.question.answerLabels.forEach((label, index) => {
      ksaDetails.ksaQuestionAnswerByParticipants[nonTechQuestionCount].ksaAnswerByParticipants.push({
        id: questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.ksaAnswerByParticipants?.[index]?.id || null,
        roleProfileId: null,
        answerNumber: null,
        indexId: label.indexId ? label.indexId : null,
        answerText: null,
        isAnswerChecked: questionNonTechnical.isDisplay ? label.isChecked : false,
        answerLabel: {
          id: label.id
        }
      });
    });
  }

  mapNonLabelQuestionForFalseIsDisplayForNonTechnicalQuestion(ksaDetails: ParticipantSurvey, questionNonTechnical: ParticipantQuestion): void {
    ksaDetails.ksaQuestionAnswerByParticipants.push({
      id: questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.id || null,
      ksaAnswerByParticipants: [
        {
          id: questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.ksaAnswerByParticipants?.[0]?.id || null,
          roleProfileId: null,
          answerNumber: null,
          answerText: null,
          isAnswerChecked: false,
        }
      ],
      displayQuestion: false,
      visitedQuestion: false,
      sectionQuestionDisplay: false,
      skipDisplay: false,
      roleProfileDisplay: questionNonTechnical?.isRoleProfileDisplay || false,
      ksaQuestion: {
        id: questionNonTechnical.id
      }
    });
  }

  mapNonLabelQuestionForTrueIsDisplayForNonTechnicalQuestion(ksaDetails: ParticipantSurvey, questionNonTechnical: ParticipantQuestion): void {
    ksaDetails.ksaQuestionAnswerByParticipants.push({
      id: questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.id || null,
      ksaAnswerByParticipants: [
        {
          id: questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.ksaAnswerByParticipants?.[0]?.id || null,
          roleProfileId: questionNonTechnical.question.questionType.id === this.questionTypeEnum.RoleProfile ?
            questionNonTechnical.question.answerValuesText : null,
          answerNumber: questionNonTechnical.question.questionType.id === this.questionTypeEnum.Scale ?
            questionNonTechnical.question.answerValuesText : null,
          answerText: questionNonTechnical.question.questionType.id === this.questionTypeEnum.FreeText ?
            questionNonTechnical.question.answerValuesText : null,
          isAnswerChecked: false,
          indexId: questionNonTechnical.question.questionType.id === this.questionTypeEnum.Scale ?
            questionNonTechnical.question.scaleValueIndex : null
        }
      ],
      displayQuestion: questionNonTechnical.isDisplay,
      visitedQuestion: questionNonTechnical.isDisplay ? questionNonTechnical.isVisited : false,
      sectionQuestionDisplay: questionNonTechnical.isSectionQuestionDisplay,
      skipDisplay: questionNonTechnical.isSkipDisplay,
      roleProfileDisplay: questionNonTechnical.isRoleProfileDisplay,
      ksaQuestion: {
        id: questionNonTechnical.id
      }
    });
  }
}
