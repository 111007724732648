/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ParticipantQuestion} from "../models/participant-ksa.model";
import {KsaSection} from "../models/master-ksa.model";
import {QuestionType} from "../enums/questionType.enum";
import {SurveyControls} from "../enums/survey-controls.enum";
import {QuestionInitializeService} from "./question-initialize.service";
import {ParticipantSurvey} from '../models/particpant-survey.model';
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {
  private getKsaDetailsByUrl: string;
  private ksaRoleProfileUrl: string;
  questionTypeEnum: typeof QuestionType = QuestionType;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  questionCountOnSurvey: number = 0;
  questionPageBreak: number = 1;

  constructor(private http: HttpClient, private questionInitializeService: QuestionInitializeService) {
    this.getKsaDetailsByUrl = `${environment.API_URL}${API_KSA_CORE_V1}/survey/participant/`;
    this.ksaRoleProfileUrl = `${environment.API_URL}${API_KSA_CORE_V1}/survey/`;
  }

  getKsaRoles(id): Observable<any> {
    return this.http.get(`${this.ksaRoleProfileUrl}set/` + id);
  }

  initiateAnswering(uuid: string): Observable<void> {
    return this.http.put<void>(`${this.getKsaDetailsByUrl}${uuid}`, {});
  }

  getKsaDetails(uuid: string): Observable<any> {
    return this.http.get<any>(`${this.getKsaDetailsByUrl}${uuid}`, {});
  }

  getKsaRoleProfileSkill(id): Observable<any> {
    return this.http.get<any>(`${this.ksaRoleProfileUrl}skill/${id}`);
  }

  submitKsaDetails(ksa): Observable<any> {
    return this.http.post(`${this.getKsaDetailsByUrl}`, ksa);
  }

  populateSurveyQuestions(allKsaSection: KsaSection[], isDemo: boolean, ksaDetails: ParticipantSurvey, isDemoAdvance: boolean): ParticipantQuestion[] {
    const resultParticipantQuestions: ParticipantQuestion[] = [];
    this.questionPageBreak = 1;
    this.questionCountOnSurvey = 0;
    allKsaSection.sort((previousSection, nextSection) =>
      (previousSection.sectionSequenceNo > nextSection.sectionSequenceNo ? 1 : -1));

    allKsaSection.forEach(section => {
      if (section.isQuestionSectionsSelected) {
        if (section.isCoreSection) {
          this.populateTechnicalSection(section, isDemo, ksaDetails, this.questionPageBreak, resultParticipantQuestions, isDemoAdvance);
        } else {
          this.populateNonTechnicalSection(section, isDemo, ksaDetails, this.questionPageBreak, resultParticipantQuestions, isDemoAdvance);
        }
      }
    });
    return resultParticipantQuestions;
  }

  populateTechnicalSection(section: KsaSection,
                           isDemo: boolean,
                           ksaDetails: ParticipantSurvey,
                           questionPageBreak: number,
                           resultParticipantQuestions: ParticipantQuestion[],
                           isDemoAdvance: boolean): void {
    section.ksaTechnicalQuestions.sort((previousQuestion, nextQuestion) =>
      (previousQuestion.sectionQuestion.sectionQuestionSequenceNo > nextQuestion.sectionQuestion.sectionQuestionSequenceNo ? 1 : -1));

    section.ksaTechnicalQuestions.forEach((questionTechnical, questionIndex) => {
      const questionTypeId = questionTechnical.sectionQuestion.question.questionType.id;
      this.questionCountOnSurvey++;
      questionTechnical.sectionQuestion.isPageBreakAdded = questionTechnical.isPageBreakAdded;
      questionTechnical.sectionQuestion.questionSequenceInSurvey = this.questionCountOnSurvey;

      if (questionIndex === 0) {
        section.ksaTechnicalQuestions[0].sectionQuestion.question[this.surveyControlsEnum.sectionImage] =
          section?.section?.image?.imageData;
      }

      questionTechnical.sectionQuestion.question.answerValuesText = '';

      if (questionTypeId === this.questionTypeEnum.MatrixTable ||
        questionTypeId === this.questionTypeEnum.Scale ||
        questionTypeId === this.questionTypeEnum.RadioButton ||
        questionTypeId === this.questionTypeEnum.MultipleChoice) {
        this.setKSAQuestionDetails(questionTechnical?.sectionQuestion, questionTypeId);
        if (!isDemo) {
          if (questionTechnical.ksaTechnicalQuestionAnswerByParticipant?.[0] && questionTechnical.sectionQuestion.question.questionType.id === this.questionTypeEnum.MatrixTable) {
            questionTechnical.sectionQuestion.question.answerControlMatrixList =
              this.questionInitializeService.setAnswerControlMatrixListForTechnicalSection(questionTechnical);
          }
        } else {
          questionTechnical.sectionQuestion.question.answerControlMatrixList = [];
        }
      }

      questionTechnical.sectionQuestion.isRoleProfileQuestionVisbileForKsa = ksaDetails?.masterKsa && !ksaDetails?.masterKsa.isAdvance && questionTypeId === this.questionTypeEnum.RoleProfile;
      questionTechnical.sectionQuestion.isDisplay = questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.displayQuestion;
      questionTechnical.sectionQuestion.isVisited = questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.visitedQuestion;
      questionTechnical.sectionQuestion.isSectionQuestionDisplay =
        questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.sectionQuestionDisplay;
      questionTechnical.sectionQuestion.isSkipDisplay = questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.skipDisplay;
      questionTechnical.sectionQuestion.isRoleProfileDisplay = questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.roleProfileDisplay;
      questionTechnical.sectionQuestion.roleProfileInitialValue = questionTechnical.sectionQuestion.question.answerValuesText
      if (!isDemo) {
        if (questionTechnical.ksaTechnicalQuestionAnswerByParticipant?.[0]) {
          if (questionTechnical?.sectionQuestion?.question?.answerLabels.length > 1) {
            if (questionTypeId === this.questionTypeEnum.MatrixTable) {
              questionTechnical.sectionQuestion.question.answerControls.forEach((answerControl, index) => {
                answerControl.isChecked =
                  questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.ksaTechnicalAnswerByParticipants[index]?.isAnswerChecked;
              });
            } else {
              questionTechnical.sectionQuestion.question.answerLabels.forEach((answerlabel, index) => {
                answerlabel.isChecked =
                  questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.ksaTechnicalAnswerByParticipants[index]?.isAnswerChecked;
              });
            }

          } else {
            const answerParticipant = questionTechnical?.ksaTechnicalQuestionAnswerByParticipant?.[0]?.ksaTechnicalAnswerByParticipants[0];
            if (questionTypeId === this.questionTypeEnum.Scale) {
              questionTechnical.sectionQuestion.question.answerValuesText = answerParticipant?.answerNumber
            }
            if (questionTypeId === this.questionTypeEnum.FreeText || questionTypeId === this.questionTypeEnum.Description) {
              questionTechnical.sectionQuestion.question.answerValuesText = answerParticipant.answerText;
            }
            if (questionTypeId === this.questionTypeEnum.RoleProfile) {
              questionTechnical.sectionQuestion.question.answerValuesText = answerParticipant.roleProfileId;
              questionTechnical.sectionQuestion.roleProfileInitialValue = answerParticipant.roleProfileId;
            }
          }
        }
      }
      questionTechnical.sectionQuestion['sectionID'] = section.section.id;
      questionTechnical.sectionQuestion['sectionSequenceNo'] = section.sectionSequenceNo;
      questionTechnical.sectionQuestion['pageSequenceNo'] = questionPageBreak;
      questionTechnical.sectionQuestion.index = this.questionCountOnSurvey;
      if (isDemo || !questionTechnical.ksaTechnicalQuestionAnswerByParticipant?.[0] ||
        !questionTechnical.ksaTechnicalQuestionAnswerByParticipant?.[0].ksaTechnicalAnswerByParticipants) {
        questionTechnical.sectionQuestion.isDisplay = true;
        questionTechnical.sectionQuestion.isSectionQuestionDisplay = true;
        questionTechnical.sectionQuestion.isSkipDisplay = true;
        questionTechnical.sectionQuestion.isRoleProfileDisplay = true;
        questionTechnical.sectionQuestion.isVisited = false;
      }

      if (questionTypeId === this.questionTypeEnum.RoleProfile && ((!isDemo && !ksaDetails?.masterKsa.isAdvance) || (isDemo && !isDemoAdvance))) {
        questionTechnical.sectionQuestion.isDisplay = false;
        questionTechnical.sectionQuestion.isRoleProfileDisplay = false;
        questionTechnical.sectionQuestion.isPageBreakAdded = false;
      }

      if (questionTechnical.sectionQuestion.isPageBreakAdded) {
        this.questionPageBreak++;
      }
      resultParticipantQuestions.push(questionTechnical.sectionQuestion);
    });
  }

  populateNonTechnicalSection(section: KsaSection,
                              isDemo: boolean,
                              ksaDetails: ParticipantSurvey,
                              questionPageBreak: number,
                              resultParticipantQuestions: ParticipantQuestion[],
                              isDemoAdvance: boolean): void {
    section.ksaQuestions.sort((previousQuestion, nextQuestion) => (previousQuestion.sectionQuestionSequenceNo >
    nextQuestion.sectionQuestionSequenceNo ? 1 : -1));

    section.ksaQuestions.forEach((questionNonTechnical, questionIndex) => {
      this.questionCountOnSurvey++;
      questionNonTechnical.questionSequenceInSurvey = this.questionCountOnSurvey;
      const questionTypeId = questionNonTechnical.question.questionType.id;

      if (questionIndex === 0) {
        section.ksaQuestions[0].question[this.surveyControlsEnum.sectionImage] = section?.section?.image?.imageData;
      }
      questionNonTechnical.question.answerValuesText = '';

      if (questionTypeId === this.questionTypeEnum.MatrixTable ||
        questionTypeId === this.questionTypeEnum.Scale ||
        questionTypeId === this.questionTypeEnum.RadioButton ||
        questionTypeId === this.questionTypeEnum.MultipleChoice) {
        this.setKSAQuestionDetails(questionNonTechnical, questionTypeId);

        if (!isDemo) {
          if (questionNonTechnical.ksaQuestionAnswerByParticipant?.[0]) {
            questionNonTechnical.question.answerControlMatrixList =
              this.questionInitializeService.setAnswerControlMatrixListForNonTechnicalSection(questionNonTechnical);
          }
        } else {
          questionNonTechnical.question.answerControlMatrixList = [];
        }
      }

      questionNonTechnical.isRoleProfileQuestionVisbileForKsa = ksaDetails?.masterKsa && !ksaDetails?.masterKsa.isAdvance && questionTypeId === this.questionTypeEnum.RoleProfile;
      if (questionTypeId === this.questionTypeEnum.RoleProfile) {
        questionNonTechnical.roleProfileInitialValue = questionNonTechnical.question.answerValuesText
      }
      questionNonTechnical.question.questionText = questionNonTechnical.questionText;
      questionNonTechnical.isDisplay = questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.displayQuestion;
      questionNonTechnical.isVisited = questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.visitedQuestion;
      questionNonTechnical.isSectionQuestionDisplay = questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.sectionQuestionDisplay;
      questionNonTechnical.isSkipDisplay = questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.skipDisplay;
      questionNonTechnical.isRoleProfileDisplay = questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.roleProfileDisplay;
      if (!isDemo) {
        if (questionNonTechnical.ksaQuestionAnswerByParticipant?.[0]) {
          if (questionNonTechnical?.question?.answerLabels.length > 1) {
            if (questionTypeId === this.questionTypeEnum.MatrixTable) {
              questionNonTechnical.question.answerControls.forEach((answerControl, index) => {
                answerControl.isChecked =
                  questionNonTechnical.ksaQuestionAnswerByParticipant?.[0].ksaAnswerByParticipants[index].isAnswerChecked;
              });
            } else {
              questionNonTechnical.question.answerLabels.forEach((answerlabel, index) => {
                answerlabel.isChecked =
                  questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0].ksaAnswerByParticipants[index]?.isAnswerChecked;
              });
            }

          } else {
            const answerParticipant = questionNonTechnical?.ksaQuestionAnswerByParticipant?.[0]?.ksaAnswerByParticipants[0];
            if (questionTypeId === this.questionTypeEnum.Scale) {
              questionNonTechnical.question.answerValuesText = answerParticipant?.answerNumber;
            }
            if (questionTypeId === this.questionTypeEnum.FreeText || questionTypeId === this.questionTypeEnum.Description) {
              questionNonTechnical.question.answerValuesText = answerParticipant?.answerText;
            }
            if (questionTypeId === this.questionTypeEnum.RoleProfile) {
              questionNonTechnical.question.answerValuesText = answerParticipant?.roleProfileId;
              questionNonTechnical.roleProfileInitialValue = answerParticipant.roleProfileId;
            }
          }
        }
      }
      questionNonTechnical['sectionID'] = section.section.id;
      questionNonTechnical['sectionSequenceNo'] = section.sectionSequenceNo;
      questionNonTechnical['pageSequenceNo'] = questionPageBreak;
      questionNonTechnical.index = this.questionCountOnSurvey;
      if (isDemo || !questionNonTechnical.ksaQuestionAnswerByParticipant?.[0] ||
        !questionNonTechnical.ksaQuestionAnswerByParticipant?.[0].ksaAnswerByParticipants) {
        questionNonTechnical.isDisplay = true;
        questionNonTechnical.isSectionQuestionDisplay = true;
        questionNonTechnical.isSkipDisplay = true;
        questionNonTechnical.isRoleProfileDisplay = true;
        questionNonTechnical.isVisited = false;
      }
      if (questionTypeId === this.questionTypeEnum.RoleProfile && ((!isDemo && !ksaDetails?.masterKsa.isAdvance) || (isDemo && !isDemoAdvance))) {
        questionNonTechnical.isDisplay = false;
        questionNonTechnical.isRoleProfileDisplay = false
        questionNonTechnical.isPageBreakAdded = false;
      }

      if (questionNonTechnical.isPageBreakAdded) {
        this.questionPageBreak++;
      }
      resultParticipantQuestions.push(questionNonTechnical);
    });
  }


  setKSAQuestionDetails(question: ParticipantQuestion, questionType: number): void {
    switch (questionType) {
      case this.questionTypeEnum.MatrixTable: {
        this.questionInitializeService.setMatrixTypeOnView(question);
        const rowCount = question?.question?.answerProperty.answerRow;
        const colCount = question?.question?.answerProperty.answerColumn;
        question.question.answerControls =
          this.questionInitializeService.setAnswereControlList(rowCount, colCount);
        break;
      }
      case this.questionTypeEnum.Scale: {
        this.questionInitializeService.setScaleTypeOnView(question);
        this.questionInitializeService.setScaleIndex(question);
        break;
      }
      case this.questionTypeEnum.RadioButton: {
        this.questionInitializeService.setLabelIndex(question);
        break;
      }
      case this.questionTypeEnum.MultipleChoice: {
        this.questionInitializeService.setLabelIndex(question);
        break;
      }
      default: {
        throw new Error(`Unhandled Operation Case.`);
      }
    }
  }
}
