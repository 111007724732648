export enum Status {
  Draft = 'Draft',
  Published = 'Published',
  Active = 'Active',
  Archived = 'Archived',
  Closed = 'Closed',
  Expired = 'Expired'
}

export enum KsaStatus {
  Draft = 'Draft',
  Active = 'Active',
  Closed = 'Closed',
  Expired = 'Expired'
}
