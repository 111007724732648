import {NgModule} from '@angular/core';
import {ClarityModule, ClrDatagridModule, ClrModalModule, ClrTabsModule} from "@clr/angular";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from '@angular/common';

import {AnalysisReportRoutingModule} from './analysis-report-routing.module';

import {TrackingComponent} from './tracking/tracking.component';
import {TabReportsComponent} from './tab-reports/tab-reports.component';
import {ResponseManagementComponent} from './response-management/response-management.component';
import {SingleUserReportComponent} from './single-user-report/single-user-report.component';
import {HeatMapReportComponent} from './heat-map-report/heat-map-report.component';
import {RoleMapReportComponent} from './role-map-report/role-map-report.component';
import {RoleProfilingComponent} from './role-profiling/role-profiling.component';
import {CertReportComponent} from './cert-report/cert-report.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
  TranslateService,
  TranslateStore
} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {AgGridModule} from "ag-grid-angular";
import {TabAnalysisComponent} from './tab-analysis/tab-analysis.component';
import {TrainingRecommendationsComponent} from './training-recommendations/training-recommendations.component';
import {ColorPickerModule} from "ngx-color-picker";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {
  ResponseOriginalDataComponent
} from './response-management/response-original-data/response-original-data.component';
import {ParticipantAnswerReportComponent} from './participant-answer-report/participant-answer-report.component';
import {ParticipantDetailsComponent} from './participant-details/participant-details.component';
import {NgApexchartsModule} from "ng-apexcharts";
import {DirectiveModule} from "../../directives/directive.module";
import {
  CertificateOriginalDataComponent
} from './cert-report/certificate-original-data/certificate-original-data.component';
import {MngReportTemplatesComponent} from './mng-report-templates/mng-report-templates.component';
import {ColorPickerComponent} from "./color-picker/color-picker.component";
import {ScorecardsComponent} from "./scorecards/scorecards.component";
import {ScorecardModalComponent} from "./scorecards/view-modal/scorecard.modal.component";
import {ScorecardCcModalComponent} from './scorecards/scorecard-cc-modal/scorecard-cc-modal.component';

export function translateServiceFactory(store: TranslateStore, loader: TranslateLoader, compiler: TranslateCompiler, parser: TranslateParser, missingHandler: MissingTranslationHandler) {
  // @ts-ignore
  const res: TranslateService = new TranslateService(store, loader, compiler, parser, missingHandler);
  res.setDefaultLang('en-us');
  return res;
}

export function translateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}

@NgModule({
   declarations: [
      TrackingComponent,
      TabReportsComponent,
      ResponseManagementComponent,
      SingleUserReportComponent,
      HeatMapReportComponent,
      RoleMapReportComponent,
      RoleProfilingComponent,
     CertReportComponent,
     TabAnalysisComponent,
     TrainingRecommendationsComponent,
     ResponseOriginalDataComponent,
     ParticipantAnswerReportComponent,
     ParticipantDetailsComponent,
     ResponseOriginalDataComponent,
     CertificateOriginalDataComponent,
     MngReportTemplatesComponent,
     ScorecardsComponent,
     ScorecardModalComponent,
     ColorPickerComponent,
     ScorecardCcModalComponent
   ],
  imports: [
    AnalysisReportRoutingModule,
    ClrTabsModule,
    ClrDatagridModule,
    ClrModalModule,
    ClarityModule,
    FormsModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoader,
        deps: [HttpClient]
      }
    }),
    AgGridModule,
    ColorPickerModule,
    NgxSliderModule,
    NgApexchartsModule,
    DirectiveModule,
    ReactiveFormsModule
  ],
  exports: [
    TrackingComponent,
    TabReportsComponent,
    ResponseManagementComponent,
    SingleUserReportComponent,
    HeatMapReportComponent,
    RoleMapReportComponent,
    RoleProfilingComponent,
    CertReportComponent
  ],
  providers: [ ],
})
export class AnalysisReportModule { }
