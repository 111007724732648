import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {RolesService} from '../../../services/roles.service';
import {ContentEntity, EditPermissionRole, Roles} from '../../../models/roles.model';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {TranslateService} from "@ngx-translate/core";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from 'rxjs/operators';
import {UserRoleType} from "../../../models/user-role-type.constants";

@Component({
  selector: 'app-mng-roles',
  templateUrl: './mng-roles.component.html',
  styleUrls: ['./mng-roles.component.scss']
})
export class MngRolesComponent implements OnInit {
  private static readonly UNEDITABLE_ROLES = [UserRoleType.STAKEHOLDER, UserRoleType.SUPER_ADMIN, UserRoleType.TRANSLATION_REVIEWER];
  state: ClrDatagridStateInterface;
  page: number;
  loading: boolean = false;
  total: number;
  public isAddRoleModal: any;
  public isEditRoleModal: any;
  public roleData: Roles = {
    content: [],
  };
  public roleName: any;
  public roleDescription: any;
  error: any;
  getRoleData: any;
  role_functionality_id: any;
  errorMsg: string = '';
  showDeleteRolePopUp: boolean = false;
  deleteId: number;
  roleFunctionalityMapsList: EditPermissionRole = {
    id: 0,
    roleDescription: '',
    roleName: '',
    roleFunctionalityMaps: [
      {
        id: 0,
        functionality: {
          id: 0,
          screen: '',
          module: '',
        },
        read: false,
        write: false,
      }]
  };
  editPermission: EditPermissionRole = {
    id: 0,
    roleDescription: '',
    roleName: '',
    roleFunctionalityMaps: [
      {
        id: 0,
        functionality: {
          id: 0,
          screen: '',
          module: '',
        },
        read: false,
        write: false,
      }]
  };

  functionality: typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;

  constructor(private readonly roleService: RolesService,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.changeDetector.detectChanges();
  }

  onOpenAddRoleModal(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.roleService.getRolePermission()
      .pipe(finalize(() => hideSpinner()))
      .subscribe(role => {
          this.roleFunctionalityMapsList = Object.assign(role);
          this.roleFunctionalityMapsList.roleName = this.roleName;
          this.roleFunctionalityMapsList.roleDescription = this.roleDescription;
          this.isAddRoleModal = true;
        },
        error => this.errorMsg = error.error.message);
  }

  hasAccessSelected(): boolean {
    let hasFunctionalityAccess;
    this.roleFunctionalityMapsList.roleFunctionalityMaps.map(functionalityAccess => {
      if (functionalityAccess.read || functionalityAccess.write) {
        hasFunctionalityAccess = true;
      }
    })
    return hasFunctionalityAccess;
  }

  onAddRole(form: NgForm, rolesFunctionalityMap: any) {
    rolesFunctionalityMap.forEach(value => {
      if (value.write == true) {
        value.read = true;
      }
    })
    this.roleFunctionalityMapsList.roleName = form.value.roleName;
    this.roleFunctionalityMapsList.roleDescription = form.value.roleDescription;
    this.roleFunctionalityMapsList.roleFunctionalityMaps = rolesFunctionalityMap;
    if (form.invalid) {
      return;
    }

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.roleService.addRolePermission(this.roleFunctionalityMapsList)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(res => {
          this.isAddRoleModal = false;
          this.refresh(this.state);
          this.errorMsg = '';
          form.reset();
        },
        error => {
          this.errorMsg = error.error.message;
          this.roleFunctionalityMapsList.roleFunctionalityMaps.forEach(value => {
            value.read = false;
            value.write = false
          })
        },
      );
  }

  onCancelAdding(form: NgForm): void {
    form.reset();
    this.errorMsg = '';
    this.isAddRoleModal = false;
  }

  onOpenRolePrivilegeModal(value: any): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.roleService.editRolePermission(value.id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(res => {
        this.roleFunctionalityMapsList.roleFunctionalityMaps = Object.assign(res);
        this.roleFunctionalityMapsList.roleFunctionalityMaps.forEach(roleFunctionalityMap => {
          if (roleFunctionalityMap.write) {
            roleFunctionalityMap.read = true;
          }
        });
        this.role_functionality_id = value.id;
        this.roleFunctionalityMapsList.roleName = value.roleName;
        this.roleFunctionalityMapsList.roleDescription = value.roleDescription;
        this.errorMsg = '';
        this.isEditRoleModal = true;
      }, error => this.errorMsg = error.error.message);
  }

  onSubmitRoleData(permission: any): void {
    this.editPermission.id = this.role_functionality_id;
    this.editPermission.roleName = permission.roleName;
    this.editPermission.roleDescription = permission.roleDescription;
    this.editPermission.roleFunctionalityMaps = permission.roleFunctionalityMaps;

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.roleService.updateRolePermission(this.editPermission)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(res => {
          this.refresh(this.state);
          this.errorMsg = '';
          this.isEditRoleModal = false;
        },
        error => this.errorMsg = error.error.message);
  }

  onCancelRole(): void {
    this.errorMsg = '';
    this.isEditRoleModal = false;
    this.roleFunctionalityMapsList.roleName = '';
    this.roleFunctionalityMapsList.roleDescription = '';
    this.refresh(this.state);
  }

  OnDeleteRole() {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.roleService.deleteRole(this.deleteId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(res => {
        this.refresh(this.state);
        this.showDeleteRolePopUp = false;
      }, error => {
        this.errorMsg = error.error.message;
      })
  }

  resetError(): void {
    this.errorMsg = '';
  }

  onShowDeleteRolePopUp(id: any) {
    this.deleteId = id;
    this.showDeleteRolePopUp = true;
  }

  refresh(state: ClrDatagridStateInterface) {
    this.state = state;
    this.loading = true;
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const {property, value} = <{ property: string; value: string }>filter;
        filters[property] = value;
      }
    }
    this.roleService.getRole(filters?.roleName === undefined ? '' : filters.roleName,
      filters?.roleDescription === undefined ? '' : filters.roleDescription,
      state.page.current - 1, state.page.size, state?.sort?.by === undefined ? 'id' : state?.sort?.by, state?.sort?.reverse === undefined ? 'ASC' : state?.sort?.reverse)
      .subscribe((results: any) => {
        this.roleData = results;
        this.total = results.totalElements;
        this.loading = false;
      })
  }

  //This method is kind of hardcoded check and will be refactored when logic for roles is refactored.
  isButtonDisabledForRole(roleRecord: ContentEntity): boolean {
    return MngRolesComponent.UNEDITABLE_ROLES.includes(roleRecord.roleName.trim())
  }
}
