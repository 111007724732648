/*
 * Copyright 2022 VMware, Inc. All rights reserved. VMware Confidential
 */

export enum LocalizationStatusEnum {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  PARTIALLY_TRANSLATED = "PARTIALLY_TRANSLATED"

}
