<div class="button-container" *hasAccess="functionality.manageRole">
  <button id="{{btnId.btnMngRoleAdd}}" type="button" class="btn btn-sm btn-primary" (click)="onOpenAddRoleModal()" >
    {{'admin.rolesAndUser.roles.addRoleBtn' | translate}}
  </button>
</div>
<clr-datagrid class="parent-datagrid" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-column [clrDgField]="'roleName'">{{'admin.rolesAndUser.roles.roleName' | translate}}</clr-dg-column>
  <clr-dg-column [clrDgField]="'roleDescription'">{{'admin.rolesAndUser.roles.description' | translate}}</clr-dg-column>
  <clr-dg-column class="action-cell"></clr-dg-column>
  <clr-dg-row *ngFor="let roleRecord of roleData.content">
    <clr-dg-cell>{{roleRecord.roleName}}</clr-dg-cell>
    <clr-dg-cell>{{roleRecord.roleDescription}}</clr-dg-cell>
    <clr-dg-cell class="action-cell">
      <clr-dg-action-overflow *hasAccess="functionality.manageRole">
        <button id="{{btnId.btnMngRoleEdit}}" class="action-item" (click)="onOpenRolePrivilegeModal(roleRecord)"
                [disabled]="isButtonDisabledForRole(roleRecord)">{{'admin.editBtn' |translate}}</button>
        <button id="{{btnId.btnMngRoleDelete}}" class="action-item" (click)="onShowDeleteRolePopUp(roleRecord.id)"
                [disabled]="isButtonDisabledForRole(roleRecord)">{{'admin.deleteBtn' |translate}}</button>
      </clr-dg-action-overflow>
    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Roles per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} roles
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="isAddRoleModal" [clrModalClosable]="true"
           (clrModalOpenChange)="onCancelAdding(addForm)">
  <h3 class="modal-title">{{'admin.rolesAndUser.roles.addRolePermissionModalTitle' | translate}}</h3>
  <div class="modal-body">
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
    <form clrForm clrLabelSize="7" autocomplete="off"
          (ngSubmit)="onAddRole(addForm,this.roleFunctionalityMapsList.roleFunctionalityMaps)" #addForm="ngForm">
      <clr-input-container class="clr-row">
        <label class="clr-col-4">{{'admin.rolesAndUser.roles.roleName' | translate}}* </label>
        <input  #isRoleFilled="ngModel" class="clr-col-8" clrInput type="text" [(ngModel)]="roleFunctionalityMapsList.roleName" name="roleName" required
                placeholder="{{'placeholder.roleName' | translate}}"
                pattern="^[^!@#*&(){}<>\/;`%$^:?]*$"
/>
        <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}</clr-control-error>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.enterRoleName' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-textarea-container class="clr-row">
        <label class="clr-col-4">{{'admin.rolesAndUser.roles.roleDescription' | translate}}</label>
        <textarea #isRoleDescFilled="ngModel" [(ngModel)]="roleFunctionalityMapsList.roleDescription" class="clr-col-8"
                  clrTextarea name="roleDescription" placeholder="{{'placeholder.roleDescription' | translate}}"
                  rows="1"></textarea>
      </clr-textarea-container>
      <clr-datagrid *ngIf="isAddRoleModal" class="datagrid-compact popup-datagrid-role" [clrDgPreserveSelection]="true">
        <clr-dg-column [clrDgField]="'functionality.screen'">{{'admin.rolesAndUser.roles.screen' | translate}}
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'functionality.module'">{{'admin.rolesAndUser.roles.module' | translate}}
        </clr-dg-column>
        <clr-dg-column>{{'admin.rolesAndUser.roles.read' | translate}}</clr-dg-column>
        <clr-dg-column>{{'admin.rolesAndUser.roles.write' | translate}}</clr-dg-column>
        <clr-dg-row *clrDgItems="let rolesKey of this.roleFunctionalityMapsList.roleFunctionalityMaps; let index = index" >
          <clr-dg-cell>{{rolesKey.functionality.screen}}</clr-dg-cell>
          <clr-dg-cell>{{rolesKey.functionality.module}}</clr-dg-cell>
          <clr-dg-cell>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="options{{index}}"  [checked]='(rolesKey.read && rolesKey.write) ||rolesKey.write' value='check'
                     [(ngModel)]='rolesKey.read' [disabled]="rolesKey.write"  />
            </clr-checkbox-wrapper>
          </clr-dg-cell>
          <clr-dg-cell>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="optionsWrite{{index}}" [checked]='rolesKey.write'
                     [(ngModel)]='rolesKey.write'/>
            </clr-checkbox-wrapper>
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-outline" (click)="onCancelAdding(addForm);">{{'admin.cancelBtn' |
          translate}}
        </button>
        <button [disabled]="isRoleFilled.errors?.required || isRoleDescFilled.errors?.required || !hasAccessSelected()"
                class="btn btn-sm btn-primary"
                type="submit">{{'admin.addBtn' |
          translate}}
        </button>
      </div>
    </form>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isEditRoleModal" (clrModalOpenChange)="onCancelRole()" [clrModalClosable]="true">
  <h3 class="modal-title">{{'admin.rolesAndUser.roles.editRolePermissionModalTitle' | translate}}</h3>
  <div class="modal-body" clrForm clrLabelSize="7">
      <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
            </div>
            <span class="alert-text">
             {{errorMsg}}
            </span>
          </div>
        </div>
      </div>
    <clr-input-container class="clr-row">
      <label class="clr-col-4">{{'admin.rolesAndUser.roles.roleName' | translate}}*</label>
      <input #isRoleFilled="ngModel" clrInput type="text" [(ngModel)]="roleFunctionalityMapsList.roleName" name="roleName"
             value="roleName" class="clr-col-8"
             pattern="^[^!@#*&(){}<>\/;`%$^:?]*$" autocomplete="off"
 required />
      <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}</clr-control-error>
      <clr-control-error>{{'errorMsg.enterRoleName' | translate}}</clr-control-error>
    </clr-input-container>
    <clr-textarea-container class="clr-row">
      <label class="clr-col-4">{{'admin.rolesAndUser.roles.roleDescription' | translate}}</label>
      <textarea #isRoleDescFilled="ngModel" [(ngModel)]="roleFunctionalityMapsList.roleDescription" class="clr-col-8"
                clrTextarea name="roleDescription" placeholder="{{'placeholder.roleDescription' | translate}}"
                rows="1"></textarea>
    </clr-textarea-container>
    <clr-datagrid *ngIf="isEditRoleModal" class="datagrid-compact popup-datagrid-role" [clrDgPreserveSelection]="true">
      <clr-dg-column [clrDgField]="'functionality.screen'">{{'admin.rolesAndUser.roles.screen' | translate}}
      </clr-dg-column>
      <clr-dg-column [clrDgField]="'functionality.module'">{{'admin.rolesAndUser.roles.module' | translate}}
      </clr-dg-column>
      <clr-dg-column>{{'admin.rolesAndUser.roles.read' | translate}}</clr-dg-column>
      <clr-dg-column>{{'admin.rolesAndUser.roles.write' | translate}}</clr-dg-column>
      <clr-dg-row *clrDgItems="let rolePermissionKey of roleFunctionalityMapsList.roleFunctionalityMaps;let index=index;">
        <clr-dg-cell>{{rolePermissionKey.functionality.screen}}</clr-dg-cell>
        <clr-dg-cell>{{rolePermissionKey.functionality.module}}</clr-dg-cell>
        <clr-dg-cell>
          <input type="checkbox" clrCheckbox name="optionsRead{{index}}"
                 [checked]='(rolePermissionKey.read && rolePermissionKey.write)|| rolePermissionKey.write' value='check'
                 [(ngModel)]='rolePermissionKey.read' [disabled]="rolePermissionKey.write"/>
        </clr-dg-cell>
        <clr-dg-cell>
          <input type="checkbox" clrCheckbox name="optionsWrite{{index}}" [checked]='rolePermissionKey.write'
                 value='check'
                 [(ngModel)]='rolePermissionKey.write'/>
        </clr-dg-cell>
      </clr-dg-row>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-outline" (click)="isEditRoleModal = false">{{'admin.cancelBtn' |
      translate}}</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="onSubmitRoleData(roleFunctionalityMapsList)"
            [disabled]="isRoleFilled.errors?.required || isRoleDescFilled.errors?.required || !hasAccessSelected()">
      {{'admin.saveBtn' | translate}}
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showDeleteRolePopUp" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'admin.rolesAndUser.roles.deleteRoleMsg' | translate}}</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div><br>
    {{'admin.rolesAndUser.roles.thisCannotBeUndone' | translate}}.</div>

  <div class="modal-footer">
    <button (click)="showDeleteRolePopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="OnDeleteRole()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>
