import {Component} from '@angular/core';
import {Functionality} from "../../../enums/functionality.enum";

@Component({
  selector: 'app-tab-roles-users',
  templateUrl: './tab-roles-users.component.html'
})
export class TabRolesUsersComponent {
  functionality: typeof Functionality = Functionality;
}
