<form clrForm #addForm="ngForm" autocomplete="off" class="clr-form">
  <div>
    <clr-alert clrAlertType="danger" *ngIf="errorMsg != ''"
               [clrAlertClosable]="true"
               [clrAlertSizeSmall]="true" (clrAlertClosedChange)="errorMsg = ''">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </div>
  <div class="clr-row">
    <clr-select-container class="clr-col-6 clr-row">
      <label class="clr-col-3">{{'analysisAndReports.responseManagement.ksaId' | translate}}*</label>
      <select class="clr-col-8" clrSelect (change)="onChange($event.target.value)">
        <option disabled selected value="">
          {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectKsa' | translate}}
        </option>
        <option *ngFor="let ksa of closedKsa" [value]="ksa.id">{{ksa.ksaName}}</option>
      </select>
    </clr-select-container>
  </div>
  <div class="clr-row btn-group-response">
    <div class="left-btn clr-col-3">
      <button class="btn btn-primary btn-sm" (click)="onShowOriginalData()" [disabled]="!showDatagrid">
        {{'analysisAndReports.responseManagement.viewOriginalData' | translate}}</button>
    </div>
    <div class="right-btn clr-col-9" *hasAccess="functionality.responseManagement">
      <button class="btn btn-primary btn-sm" (click)="showRestoreResponse = true" [disabled]="!showDatagrid">
        {{'analysisAndReports.responseManagement.reset' | translate}}</button>
      <button class="btn btn-primary btn-sm" (click)="onUndoData()" [disabled]="!showDatagrid">
        {{'analysisAndReports.responseManagement.undo' | translate}}</button>
      <button class="btn btn-primary btn-sm" (click)="onUpdateAllResponse()" [disabled]="!showDatagrid">
        {{'analysisAndReports.responseManagement.save' | translate}}</button>
      <button class="btn btn-primary btn-sm" (click)="exportExcelResponseManagement()" [disabled]="!showDatagrid">
        {{'analysisAndReports.responseManagement.export' | translate}}
      </button>
      <button class="btn btn-primary btn-sm" (click)="exportResponsesCsv()" [disabled]="!showDatagrid">
        {{'analysisAndReports.responseManagement.exportCsv' | translate}}
      </button>
    </div>
  </div>
</form>
<div *ngIf="isSavedResponse" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">{{'analysisAndReports.responseManagement.responseSuccess' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="isResetResponse" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">{{'analysisAndReports.responseManagement.responseResetSuccess' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="isUndoResponse" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">{{'analysisAndReports.responseManagement.responseUndoSuccess' | translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="occurrenceCount > 0" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">{{occurrenceCount}}{{'analysisAndReports.responseManagement.occurrencesFound' | translate}}</span>
    </div>
  </div>
</div>
<clr-modal [(clrModalOpen)]="isReplaceModelOpen" [clrModalClosable]="true" (clrModalOpenChange)="onCancelFindAndReplace()">
  <h3 class="modal-title">{{'analysisAndReports.responseManagement.replaceColumn' | translate}} - {{selectedColumnName}}</h3>
  <div class="modal-body">
    <div *ngIf="showNoMatchFoundMsg" class="alert alert-danger" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
          </div>
          <span class="alert-text">{{'analysisAndReports.responseManagement.noMatchesFound' | translate}}</span>
        </div>
      </div>
    </div>
    <form clrForm #addForm="ngForm" autocomplete="off" class="clr-form">
      <clr-input-container class="clr-row">
        <label class="clr-col-3">{{'analysisAndReports.responseManagement.findWhat' | translate}}*</label>
        <input clrInput class="input-container" [(ngModel)]="findWhat" type="text" class="clr-col-9"
               name="findWhat"/>
      </clr-input-container>
      <clr-input-container class="clr-row">
        <label class="clr-col-3">{{'analysisAndReports.responseManagement.replaceWith' | translate}}*</label>
        <input clrInput class="input-container" [(ngModel)]="replaceWith" type="text" class="clr-col-9"
               name="replaceWith"/>
      </clr-input-container>
      <clr-checkbox-container class="clr-row">
      <clr-checkbox-wrapper>
        <label class="clr-col-12">{{'analysisAndReports.responseManagement.matchWholeWord' | translate}}</label>
        <input clrCheckbox class="input-container" [checked]="isMatchWholeWord" [(ngModel)]="isMatchWholeWord"
               type="checkbox" class="clr-col-12"
               name="matchWholeWord"/>
      </clr-checkbox-wrapper>
      </clr-checkbox-container>
      <clr-checkbox-container class="clr-row">
        <clr-checkbox-wrapper>
          <label class="clr-col-12">{{'analysisAndReports.responseManagement.matchCase' | translate}}</label>
          <input clrCheckbox class="input-container" [checked]="isMatchCase" [(ngModel)]="isMatchCase"
                 type="checkbox" class="clr-col-12"
                 name="matchWholeWord"/>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="onCancelFindAndReplace()" class="btn btn-outline btn-sm">
      {{'admin.cancelBtn' |translate}}</button>
    <button class="btn btn-primary btn-sm" (click)="onReplaceAllData()" [disabled]="!findWhat || !replaceWith">
      {{'analysisAndReports.responseManagement.replaceAll' | translate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showRestoreResponse">
  <h3 class="modal-title">
    {{"analysisAndReports.responseManagement.restoreConfirmationMsgHeader" | translate}}
  </h3>
  <div class="modal-body">
    <p>{{"analysisAndReports.responseManagement.restoreConfirmationMsg" | translate}}</p>
  </div>
  <div class="modal-footer">
    <button (click)="showRestoreResponse = false" class="btn btn-outline btn-sm">
      {{'admin.cancelBtn' | translate}}
    </button>
    <button (click)="onResetData()" class="btn btn-primary btn-sm">
      {{"analysisAndReports.responseManagement.reset" | translate}}
    </button>
  </div>
</clr-modal>

<clr-datagrid class="parent-datagrid" [clrDgLoading]="loading" *ngIf="showDatagrid" (clrDgRefresh)="refresh($event)">
  <clr-dg-column class="width-75" *hasAccess="functionality.responseManagement">
    {{"analysisAndReports.reports.actions" | translate}}
  </clr-dg-column>
  <ng-container *ngFor="let column of responseColumnSettings; let i = index" >
  <clr-dg-column [clrDgField]="column.responseColumnDisplay.questionName">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="responseColumnSettings[i].isHideable">
      {{column.responseColumnDisplay.questionName}}
    </ng-template>
  </clr-dg-column>
  </ng-container>
  <clr-dg-column *ngFor="let header of certificationDetails?.headerDTOS" class="center-align-title">
    {{header?.name}}
  </clr-dg-column>
  <clr-dg-row *hasAccess="functionality.responseManagement">
    <clr-dg-cell class="width-75" >{{'analysisAndReports.responseManagement.findAndReplace' | translate}}</clr-dg-cell>
    <clr-dg-cell *ngFor="let column of responseColumnSettings; let i = index">
      <clr-icon shape="note" (click)="onShowFindReplaceModel(column.responseColumnDisplay.questionName, i)"
                class="edit-icon"></clr-icon>
    </clr-dg-cell>
    <clr-dg-cell *ngFor="let column of certificationDetails?.headerDTOS">
      <clr-icon shape="lock" class="edit-icon"></clr-icon>
    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-row *ngFor="let response of responseData; let i = index">
    <ng-container *ngIf="editResponseData?.participantId != response.participantId">
      <clr-dg-cell class="width-75" *hasAccess="functionality.responseManagement">
        <clr-icon shape="edit" (click)="onEditResponse(response)"></clr-icon>
      </clr-dg-cell>
      <clr-dg-cell
        *ngFor="let column of responseColumnSettings">{{analysisService.getAnswerFromParticipant(response.responseColumnWithParticipants, column?.responseColumnDisplay?.id)}}
      </clr-dg-cell>
    </ng-container>
    <ng-container *ngIf="editResponseData?.participantId == response.participantId">
      <clr-dg-cell class="width-75" *hasAccess="functionality.responseManagement">
        <clr-icon shape="check" (click)="onUpdateResponseByParticipant()"></clr-icon>
        <clr-icon shape="times" (click)="onCancelUpdateData()" class="left-margin"></clr-icon>
      </clr-dg-cell>
      <clr-dg-cell *ngFor="let column of responseColumnSettings">
        <input clrInput
               [ngModel]="getAnswerText(response, column)"
               (ngModelChange)="updateAnswerText($event, response, column?.responseColumnDisplay)"
               name="skillGroup"/>
      </clr-dg-cell>
    </ng-container>
    <clr-dg-cell *ngFor="let item of getCertificationDataByParticipantId(response.participantId)">
      <div class="achieved" *ngIf="item.resultAchieved != null">
        <div class="clr-row">
          <div class="clr-col-6 certification-icon">
            <clr-tooltip>
              <clr-icon badge="success" class="certification-icon" clrTooltipTrigger shape="certificate"></clr-icon>
              <clr-tooltip-content>
                {{'analysisAndReports.responseManagement.achieved' | translate}}
              </clr-tooltip-content>
            </clr-tooltip>
          </div>
          <div class="clr-col-6 certification-checkbox">
            <input [(ngModel)]="item.resultAchieved" [checked]="item.resultAchieved" clrCheckbox
                [disabled]="editResponseData?.participantId !== response.participantId"   name="resultAchieved" type="checkbox" />
          </div>
        </div>
      </div>
      <div *ngIf="item.resultDesired != null" class="desired">
        <div class="clr-row">
          <div class="clr-col-6 certification-icon">
            <clr-tooltip>
              <clr-icon badge="success" class="certification-icon" clrTooltipTrigger shape="bullseye" ></clr-icon>
              <clr-tooltip-content>
                {{'analysisAndReports.responseManagement.desired' | translate}}
              </clr-tooltip-content>
            </clr-tooltip>
          </div>
          <div class="clr-col-6 certification-checkbox">
            <input [(ngModel)]="item.resultDesired" [checked]="item.resultDesired" clrCheckbox
                   name="resultDesired"
                   type="checkbox"
                   [disabled]="editResponseData?.participantId !== response.participantId"
            />
          </div>
        </div>
      </div>
      <div class="clr-col-6" *ngIf="item.resultAchieved == null || item.resultDesired == null">
        {{'analysisAndReports.reports.notAvailable' | translate}}</div>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-column-toggle>
      <clr-dg-column-toggle-title class="column-toggle-title">Columns
        <clr-icon role="button" shape="floppy" title="Save" (click)="onSaveResponseColumns()" class="save-col-icon"> </clr-icon>
      </clr-dg-column-toggle-title>
    </clr-dg-column-toggle>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgTotalItems]="total" [(clrDgPage)]="page">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
        {{'analysisAndReports.responseManagement.responsesPerPage' | translate}}
      </clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
      {{'analysisAndReports.responseManagement.responses' | translate}}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
