/*
 * ************************************************************************************
 * Copyright 2024 VMware, Inc.  All rights reserved. VMware Confidential
 * ************************************************************************************
 */

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KsaSettingsService {

  private readonly LAST_SELECTED_SECTION_IDS: string = 'lastSelectedSectionIds';


  setDefaultSelectedSectionsForKsa(masterSurveyId, ksaSections): void {
    const lastSelectedSectionIds: Set<number> = this.readLastSelectedSectionIds(masterSurveyId);
    const hasAnySelection = ksaSections?.some(ksaSection => lastSelectedSectionIds.has(ksaSection.section.id));
    if (hasAnySelection) {
      ksaSections?.filter(ksaSection => !lastSelectedSectionIds.has(ksaSection.section.id))
        .forEach(ksaSection => ksaSection.isQuestionSectionsSelected = false);
    }
  }

  saveSectionIdsToLocalStorage(masterSurveyId, sectionIds: number[]): void {
    localStorage.setItem(`${this.LAST_SELECTED_SECTION_IDS}-${masterSurveyId}`, JSON.stringify(sectionIds));
  }

  private readLastSelectedSectionIds(masterSurveyId): Set<number> {
    const lastSelectedSectionIds = localStorage.getItem(`${this.LAST_SELECTED_SECTION_IDS}-${masterSurveyId}`);
    return new Set(lastSelectedSectionIds ? JSON.parse(lastSelectedSectionIds) : []);
  }

}
