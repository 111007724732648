<clr-tabs>
  <clr-tab *hasPermission="[functionality.manageUser]; op 'OR'">
    <button clrTabLink>{{'tab.rolesAndUser.mngUserTab' | translate}}</button>
    <clr-tab-content *clrIfActive>
      <app-mng-users></app-mng-users>
    </clr-tab-content>
  </clr-tab>
  <clr-tab *hasPermission="[functionality.manageRole]; op 'OR'">
    <button clrTabLink>{{'tab.rolesAndUser.mngRoleTab' | translate}}</button>
    <clr-tab-content>
      <app-mng-roles></app-mng-roles>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>
