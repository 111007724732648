import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnswerLabel, MasterSurveyQuestion} from '../../../../../../models/master-survey-questions.model';
import {MasterSurveySection, SectionSkipCondition} from '../../../../../../models/master-survey-section.model';
import {MasterSurveyService} from '../../../../../../services/master-survey.service';
import {QuestionType} from "../../../../../../enums/questionType.enum";
import {SurveyControls} from "../../../../../../enums/survey-controls.enum";

@Component({
  selector: 'app-section-display-logic-pop-up',
  templateUrl: './section-display-logic-pop-up.component.html',
  styleUrls: ['./section-display-logic-pop-up.component.scss']
})
export class SectionDisplayLogicPopUpComponent implements OnInit {

  @Input() sectionData: MasterSurveySection;
  @Input() showDisplayLogicPopUp;
  @Output() showDisplayLogicModal: EventEmitter<boolean> = new EventEmitter();
  @Input() surveySections: MasterSurveySection[];
  @Output() onUpdateDisplay: EventEmitter<boolean> = new EventEmitter();

  warningTextDisplayLogic: string;
  showDisplayAnswerLabel: boolean = false;
  selectedSection: MasterSurveySection[];
  questionsSelectedSection: MasterSurveyQuestion[];
  selectedSectionDisplayLogic: MasterSurveySection;
  selectedQuestionDisplayLogic: MasterSurveyQuestion;
  selectedAnswerLabelDisplayLogic: string = '';
  selectedSkipTypeDisplayLogic: number;
  displayValueDisplayLogic: number = undefined;
  selectedColumnAnswer: string = '';
  selectedRowAnswer = '';
  answerLabelSelectedQuestionColumn = new Array<AnswerLabel>();
  answerLabelSelectedQuestionRow = new Array<AnswerLabel>();
  answerLabelSelectedQuestion: AnswerLabel[];
  valueAnswerLabelOptions: AnswerLabel[];
  displayValueInput: boolean;
  showMatrixAnswerLabel: boolean;
  questionTypeEnum: typeof QuestionType = QuestionType;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  tempSectionDisplayLogicData: SectionSkipCondition = this.setDefaultSkipCondition();

  constructor(private masterService: MasterSurveyService) { }

  ngOnInit(): void {
    this.onShowDisplayLogicModal();
    if(this.sectionData?.sectionSkipCondition){
      this.tempSectionDisplayLogicData = this.onInitializationDisplayLogic(this.sectionData.sectionSkipCondition);
    }
  }

  onInitializationDisplayLogic(sectionSkipCondition: SectionSkipCondition): SectionSkipCondition{
    [...this.questionsSelectedSection] = [];
    this.selectedSection?.forEach(section => {
      if (section.tempSectionId == sectionSkipCondition?.tempSectionId) {
        this.selectedSectionDisplayLogic = section;

        this.selectedQuestionDisplayLogic = this.selectedSectionDisplayLogic.sectionQuestions.find(question => {
          return question.tempSurveyQuestionId == sectionSkipCondition?.tempSurveyQuestionId;
        });

        this.questionsSelectedSection = section.sectionQuestions?.filter(questionval =>
          { return questionval.question?.questionType?.id !== this.questionTypeEnum.Description
            && questionval.question.questionText &&
            questionval.question?.questionType?.id !== this.questionTypeEnum.RoleProfile});

        this.valueAnswerLabelOptions = [];
        this.onFetchSkipType();

        this.selectedSkipTypeDisplayLogic = sectionSkipCondition?.skipCondition?.id;

        if (this.selectedQuestionDisplayLogic?.question?.questionType?.id != this.questionTypeEnum.MatrixTable) {
          if (sectionSkipCondition?.answerLabelSequenceNo) {
            this.showDisplayAnswerLabel = true;
            this.answerLabelSelectedQuestion = this.selectedQuestionDisplayLogic?.question?.answerLabels;
            this.selectedQuestionDisplayLogic?.question?.answerLabels?.forEach(answer => {
              if (answer?.answerLabelSequenceNo == sectionSkipCondition?.answerLabelSequenceNo) {
                this.selectedAnswerLabelDisplayLogic = answer?.answerLabelSequenceNo + ' : ' + answer?.labelText;
              }
            });
          } else {
            this.answerLabelSelectedQuestion = [];
            this.showDisplayAnswerLabel = false;
          }
          if (sectionSkipCondition?.displayValue) {
            this.displayValueInput = true;
            this.displayValueDisplayLogic = sectionSkipCondition?.displayValue;
          } else {
            this.displayValueInput = false;
          }
        } else {
          this.showDisplayAnswerLabel = false;
          this.showMatrixAnswerLabel = true;
          this.answerLabelSelectedQuestionColumn = [];
          this.answerLabelSelectedQuestionRow = [];
          const labels: AnswerLabel[] = this.selectedQuestionDisplayLogic?.question?.answerLabels;
            labels?.forEach(label => {
              if (label.isRow) {
                this.answerLabelSelectedQuestionRow.push(label);
              } else {
                this.answerLabelSelectedQuestionColumn.push(label);
              }
            })
            this.answerLabelSelectedQuestionRow?.forEach(answerRow => {
              if (answerRow.answerLabelSequenceNo == sectionSkipCondition?.answerLabelSequenceNo) {
                this.selectedRowAnswer = answerRow.answerLabelSequenceNo + ' : ' + answerRow.labelText;
              }
            });
            this.answerLabelSelectedQuestionColumn?.forEach(answerColumn => {
              if (answerColumn.answerLabelSequenceNo == sectionSkipCondition?.colAnswerLabelSequenceNo) {
                this.selectedColumnAnswer = answerColumn.answerLabelSequenceNo + ' : ' + answerColumn.labelText;
              }
            });
        }
      }
    });

    return ({
      answerLabelSequenceNo: sectionSkipCondition.answerLabel?.answerLabelSequenceNo,
      displayValue: sectionSkipCondition.displayValue,
      colAnswerLabelSequenceNo: sectionSkipCondition.colAnswerLabelSequenceNo,
      tempSectionId: sectionSkipCondition.tempSectionId,
      tempSurveyQuestionId: sectionSkipCondition.tempSurveyQuestionId,
      skipCondition: {
        id: sectionSkipCondition.skipCondition.id
      }
    });
  }

  onFetchSkipType(): void{
    this.valueAnswerLabelOptions=[];
    const questionType = this.selectedQuestionDisplayLogic?.question?.questionType?.id;
    switch (questionType) {
      case this.questionTypeEnum.FreeText:
        this.showDisplayAnswerLabel = false;
        this.displayValueInput = false;
        this.showMatrixAnswerLabel = false;
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[0].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition);
            }
          });
        });
        break;
      case this.questionTypeEnum.RadioButton:
        this.showDisplayAnswerLabel = true;
        this.displayValueInput = false;
        this.showMatrixAnswerLabel = false;
        [...this.answerLabelSelectedQuestion] = [...this.selectedQuestionDisplayLogic?.question.answerLabels];
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[1].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition);
            }
          });
        });
        break;
      case this.questionTypeEnum.MultipleChoice:
        this.showDisplayAnswerLabel = true;
        this.displayValueInput = false;
        this.showMatrixAnswerLabel = false;
        [...this.answerLabelSelectedQuestion] = [...this.selectedQuestionDisplayLogic.question.answerLabels];
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[2].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition);
            }
          });
        });
        break;
      case this.questionTypeEnum.Scale:
        this.showDisplayAnswerLabel = false;
        this.displayValueInput = true;
        this.showMatrixAnswerLabel = false;
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[3].questionTypeSkipConditionMaps.forEach(condition => {
            this.valueAnswerLabelOptions.push(condition.skipCondition)
          });
        });
        break;
      case this.questionTypeEnum.Description:
        this.showDisplayAnswerLabel = false;
        this.displayValueInput = false;
        this.showMatrixAnswerLabel = false;
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[4].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition);
            }
          });
        });
        break;
      case this.questionTypeEnum.MatrixTable:
        this.showMatrixAnswerLabel = true;
        this.showDisplayAnswerLabel = false;
        this.displayValueInput = false;
        for (let i = 0; i < this.selectedQuestionDisplayLogic?.question.answerLabels.length; i++) {
          const isRowValue = this.selectedQuestionDisplayLogic?.question.answerLabels[i].isRow;
          if (isRowValue) {
            this.answerLabelSelectedQuestionRow.push(this.selectedQuestionDisplayLogic?.question?.answerLabels[i]);
          } else {
            this.answerLabelSelectedQuestionColumn.push(this.selectedQuestionDisplayLogic?.question?.answerLabels[i]);
          }
        }
        this.masterService.getQuestionSkipTypes().subscribe(data => {
          data[5].questionTypeSkipConditionMaps.forEach(condition => {
            if(condition.skipCondition.id!=3 && condition.skipCondition.id!=4){
              this.valueAnswerLabelOptions.push(condition.skipCondition);
            }
          });
        });
        break;
    }
  }

  onShowDisplayLogicModal(): void {
    this.selectedSectionDisplayLogic = undefined;
    this.selectedQuestionDisplayLogic =undefined;
    this.selectedRowAnswer ='';
    this.selectedAnswerLabelDisplayLogic = '';
    this.selectedSkipTypeDisplayLogic = undefined;
    this.selectedColumnAnswer = '';
    this.selectedRowAnswer = '';
    this.showMatrixAnswerLabel = false;
    this.showDisplayAnswerLabel = false;
    [...this.selectedSection] =[];
    this.showDisplayLogicPopUp = true;
    this.warningTextDisplayLogic = 'Please make sure the order of questions and sections are correct, before adding display logic input.';
    [...this.selectedSection] = this.surveySections?.filter(section => {
      return section.tempSectionId!=this.sectionData.tempSectionId;
    }) || [];
  }

  onDisplayLogicSectionSelect(): void {
    this.selectedQuestionDisplayLogic =null;
    this.selectedRowAnswer ='';
    this.selectedAnswerLabelDisplayLogic = '';
    this.selectedSkipTypeDisplayLogic = undefined;
    this.selectedColumnAnswer = '';
    this.selectedRowAnswer = '';
    this.showMatrixAnswerLabel = false;
    this.showDisplayAnswerLabel = false;
    this.answerLabelSelectedQuestion=[];
    this.answerLabelSelectedQuestionColumn=[];
    this.answerLabelSelectedQuestionRow=[];
    this.tempSectionDisplayLogicData.tempSectionId = this.selectedSectionDisplayLogic.tempSectionId;

    [...this.questionsSelectedSection] = this.selectedSectionDisplayLogic.sectionQuestions;

    this.questionsSelectedSection = this.questionsSelectedSection.filter(questionval =>
      { return questionval.question.questionType.id !== this.questionTypeEnum.Description &&
        questionval.question.questionText &&
         questionval.question?.questionType?.id !== this.questionTypeEnum.RoleProfile});

    this.showDisplayAnswerLabel = false;
  }

  onDisplayLogicQuestionSelect(): void {
    this.tempSectionDisplayLogicData.tempSurveyQuestionId = null;
    this.tempSectionDisplayLogicData.answerLabelSequenceNo = null;
    this.tempSectionDisplayLogicData.colAnswerLabelSequenceNo = null;
    this.tempSectionDisplayLogicData.displayValue = null;
    this.tempSectionDisplayLogicData.answerLabel = null;
    this.tempSectionDisplayLogicData.colAnswerLabel = null;
    this.selectedSkipTypeDisplayLogic = null;
    this.tempSectionDisplayLogicData.tempSurveyQuestionId = this.selectedQuestionDisplayLogic.tempSurveyQuestionId;
    this.answerLabelSelectedQuestion=[];
    this.answerLabelSelectedQuestionColumn=[];
    this.answerLabelSelectedQuestionRow=[];
    this.onFetchSkipType();
    this.valueAnswerLabelOptions = [];
  }

  onDisplayLogicValueAnswerLabelSelect(data: any): void {
    this.tempSectionDisplayLogicData.answerLabelSequenceNo = Number((data.target.value as string).charAt(0));
  }

  onDisplayLogicSkipTypeSelect(data: number): void {
    if(data==3 || data==4){
      this.showMatrixAnswerLabel = false;
      this.showDisplayAnswerLabel = false;
      this.selectedColumnAnswer = '';
      this.selectedRowAnswer ='';
      this.selectedAnswerLabelDisplayLogic ='';
      this.tempSectionDisplayLogicData.answerLabelSequenceNo = undefined;
      this.tempSectionDisplayLogicData.colAnswerLabelSequenceNo=undefined;
    }else{
      this.onFetchSkipType();
    }
    this.tempSectionDisplayLogicData.skipCondition.id = Number(data);
  }

  onDisplayValueInput(data: number): void {
    this.tempSectionDisplayLogicData.displayValue = data;
  }

  onCancelDisplayLogic(): void {
    this.showDisplayLogicModal.emit(false);
    this.selectedSectionDisplayLogic = null;
    this.selectedQuestionDisplayLogic = null;
    this.selectedAnswerLabelDisplayLogic = '';
    this.selectedSkipTypeDisplayLogic = undefined;
    this.selectedColumnAnswer = '';
    this.selectedRowAnswer = '';
    this.sectionData.sectionSkipCondition = this.setDefaultSkipCondition();
    this.masterService.setSurveyUpdateStatus(false);
    this.onUpdateDisplay.emit(false);
  }

  setDefaultSkipCondition() {
    return {
      answerLabelSequenceNo: undefined,
      colAnswerLabelSequenceNo: undefined,
      displayValue: undefined,
      skipCondition: {
        id: undefined
      },
      tempSectionId: null,
      tempSurveyQuestionId:null
    }
}

  isDisabledDisplayLogicButton(): boolean {
    let isDisableButton = false;
    if (!this.selectedSectionDisplayLogic || !this.selectedQuestionDisplayLogic || !this.selectedSkipTypeDisplayLogic) {
      isDisableButton = true;
      return isDisableButton;
    }
    if (this.selectedQuestionDisplayLogic?.question.questionType.id == this.questionTypeEnum.Scale &&
      !this.displayValueDisplayLogic) {
      isDisableButton = true;
      return isDisableButton;
    }
    if ((this.selectedQuestionDisplayLogic?.question.questionType.id == this.questionTypeEnum.RadioButton ||
      this.selectedQuestionDisplayLogic?.question.questionType.id == this.questionTypeEnum.MultipleChoice) &&
      !this.selectedAnswerLabelDisplayLogic) {
      isDisableButton = true;
      return isDisableButton;
    }
    if (this.selectedQuestionDisplayLogic?.question.questionType.id == this.questionTypeEnum.MatrixTable &&
      (!this.selectedColumnAnswer ||
        !this.selectedRowAnswer)) {
      isDisableButton = true;
    }
    return isDisableButton;
  }


  onDisplayLogicColumnValueAnswerSelect(data): void {
    this.tempSectionDisplayLogicData.colAnswerLabelSequenceNo=Number((data.target.value as string).charAt(0));
  }

  onDisplayLogicRowValueAnswerSelect(data): void {
    this.tempSectionDisplayLogicData.answerLabelSequenceNo=Number((data.target.value as string).charAt(0));

  }

  onOkDisplayLogic(): void{
    this.masterService.setSurveyUpdateStatus(false);
    this.showDisplayLogicPopUp=false;
    this.showDisplayLogicModal.emit(false);
    this.sectionData.sectionSkipCondition = this.tempSectionDisplayLogicData;
    this.onUpdateDisplay.emit(true);
  }

}
