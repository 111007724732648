import {Component, OnInit} from '@angular/core';
import {Functionality} from "../../../enums/functionality.enum";
import {ConfigVersionService} from "../../../services/config-version.service";
import {SurveyTemplateConfigStatus} from "../../../models/config-version.model";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {TranslateService} from "@ngx-translate/core";
import {finalize} from "rxjs/operators";
import {
  SurveyTemplateConfigVersionWithMasterSurveyName
} from "../../../models/config-version-with-master-survey-names.model";
import {ClrNotificationDialogService} from "../../../common/notification-dialog/clr-notification-dialog.service";
import {NotificationDialogAction} from "../../../common/notification-dialog/notification-dialog-action.model";
import {
  NotificationDialogActionType
} from "../../../common/notification-dialog/notification-dialog-action-type.constants";

import {Router} from "@angular/router";
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-mng-skills-role-profiles-and-groups',
  templateUrl: './mng-skills-role-profiles-and-groups.component.html',
  styleUrls: ['./mng-skills-role-profiles-and-groups.component.scss']
})
export class MngSkillsRoleProfilesAndGroupsComponent implements OnInit {

  createConfigBtnsExpanded = false;
  page: number;
  total: number;

  allConfigVersions: SurveyTemplateConfigVersionWithMasterSurveyName[] = [];
  selectedConfigVersionIds: number[] = [];

  selectedConfigVersionIdsToClone: SurveyTemplateConfigVersionWithMasterSurveyName[] = [];
  cloneSurveyTemplateConfigModalOpen = false;
  functionality: typeof Functionality = Functionality;
  hasWritePermissions: boolean;

  constructor(private readonly configVersionService: ConfigVersionService,
              private readonly translate: TranslateService,
              private readonly notificationDialogService: ClrNotificationDialogService,
              private readonly router: Router,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.loadConfigVersions();
    this.hasWritePermissions = this.userService.hasWritePermission(this.functionality.manageSkill)
      && this.userService.hasWritePermission(this.functionality.manageRoleProfile)
      && this.userService.hasWritePermission(this.functionality.manageRoleProfileGroup);
  }

  onDeactivateConfigVersions(): void {
    this.notificationDialogService.show({
      title: this.translate.instant('admin.mngSkillsRoleProfilesAndGroups.deactivationDialog.title'),
      message: this.translate.instant('admin.mngSkillsRoleProfilesAndGroups.deactivationDialog.message'),
      listOfActions: [new NotificationDialogAction(NotificationDialogActionType.YES, () => this.deactivateConfigurationVersions()
      )],
      rejectAction: new NotificationDialogAction(NotificationDialogActionType.CANCEL, () => this.selectedConfigVersionIds = [])
    });
  }

  // tslint:disable-next-line:typedef
  private deactivateConfigurationVersions() {
    this.configVersionService.deactivateAll(this.selectedConfigVersionIds)
      .subscribe(() => {

        this.allConfigVersions
          .filter(config => this.selectedConfigVersionIds.includes(config.id))
          .forEach(config => config.status = SurveyTemplateConfigStatus.INACTIVE);

        this.selectedConfigVersionIds = [];
      });
  }

  onCreate() {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.configVersionService.create({
      id: null,
      name: `Configuration_name_${new Date().toDateString()}`,
      status: SurveyTemplateConfigStatus.ACTIVE
    })
      .pipe(finalize(() => hideSpinner()))
      .subscribe(createdConfig => this.routeToEditConfigPage(createdConfig.id));
  }

  routeToEditConfigPage(configId: number) {
    void this.router.navigate([`/Home/ManageSkillsRoleProfilesAndGroups/${configId}`]);
  }

  private loadConfigVersions(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.configVersionService.getAllConfigVersionsWithMasterSurveyNames()
      .pipe(finalize(() => hideSpinner()))
      .subscribe(allConfigVersions => this.allConfigVersions = allConfigVersions)
  }

  get surveyTemplateConfigStatus(): typeof SurveyTemplateConfigStatus {
    return SurveyTemplateConfigStatus;
  }

  openCloneSurveyModal(): void {
    this.createConfigBtnsExpanded = false;
    this.cloneSurveyTemplateConfigModalOpen = true;
  }

  cloneSurveyTemplateConfigVersion(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.cloneSurveyTemplateConfigModalOpen = false;

    const surveyTemplateConfigVersionToClone = this.selectedConfigVersionIdsToClone[0];

    this.configVersionService
      .clone({
        id: surveyTemplateConfigVersionToClone.id,
        name: `${surveyTemplateConfigVersionToClone.name}_cloned_${new Date().toDateString()}`,
        status: SurveyTemplateConfigStatus.ACTIVE
      })
      .pipe(finalize(() => hideSpinner()))
      .subscribe(clonedConfigSurveyTemplate =>
        this.routeToEditConfigPage(clonedConfigSurveyTemplate.id))
  }
}
