/**********************************************************************
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 **********************************************************************/
import {NotificationDialogActionType} from './notification-dialog-action-type.constants';

export class NotificationDialogAction {
   name: NotificationDialogActionType;
   callback: any;

   constructor(actionType: NotificationDialogActionType, callback) {
      this.name = actionType || NotificationDialogActionType.OK;
      this.callback = callback;
   }
}
