import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MasterSurveyService} from '../../../../../services/master-survey.service';
import {SurveyControls} from "../../../../../enums/survey-controls.enum";
import {ImageUploadValidationService} from "../../../../../services/image-upload-validation.service";

@Component({
  selector: 'app-image-upload-pop-up',
  templateUrl: './image-upload-pop-up.component.html',
  styleUrls: ['./image-upload-pop-up.component.scss']
})
export class ImageUploadPopUpComponent {
  @Input() showImageUploadPopUp;
  @Input() questionData;
  @Output() showImageUpload: EventEmitter<boolean> = new EventEmitter();
  selectedImage: any;
  imageURL: string = '';
  receivedImageData: any;
  isUploadImageInvalid: boolean = false;
  serverUploadError: boolean = false;
  imageInvalidErrorMsg: string = '';
  serverUploadErrorMsg: string = '';
  base64Data: any;
  convertedImage: any;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;

  constructor(private masterService: MasterSurveyService,
              readonly imageUploadValidationService: ImageUploadValidationService) {
  }

  onImageSelect(event: any): void {
    this.selectedImage = event.target.files[0];
    this.serverUploadError = false;

    if (this.imageUploadValidationService.validateUploadImageFile(this.selectedImage)) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.imageURL = reader.result as string;
      };
      this.isUploadImageInvalid = false;
      this.imageInvalidErrorMsg = '';
    } else {
      this.imageInvalidErrorMsg = this.imageUploadValidationService.extractErrorMsgFromInvalidFile(this.selectedImage);
      this.isUploadImageInvalid = true;
      this.selectedImage = null;
      this.imageURL = '';
    }
  }

  onClosePopUp(): void {
    this.showImageUpload.emit(false)
  }

  onUploadImage(): void {
    const uploadData = new FormData();
    uploadData.append('imageFile', this.selectedImage, this.selectedImage.name);
    this.masterService.uploadImage(uploadData).subscribe(
      res => {
        this.masterService.setSurveyUpdateStatus(false);
        this.receivedImageData = res;
        this.base64Data = this.receivedImageData.imageData;
        this.convertedImage = ImageUploadValidationService.IMAGE_BASE_64 + this.base64Data;
        this.questionData.controls.question.get(this.surveyControlsEnum.image).setValue(this.receivedImageData);
        this.showImageUploadPopUp = false;
      },
      err => {
        this.serverUploadError = true;
        this.serverUploadErrorMsg = err.error.message;
      }
    )
  }

  isUploadButtonDisabled(): boolean {
    return this.isUploadImageInvalid || !this.selectedImage;
  }
}
