/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs/Observable";
import {dashboardKsaModel, dashboardSurveyModel} from "../models/dashboard.model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  dashboardUrl: string;
  constructor(private http: HttpClient) {
    this.dashboardUrl = `${environment.API_URL}${API_KSA_CORE_V1}/dashboard/`;
  }
  getDashboardKsa():Observable<dashboardKsaModel>{
    return  this.http.get<dashboardKsaModel>(this.dashboardUrl+'ksa');
  }
  getDashboardSurvey():Observable<dashboardSurveyModel>{
    return  this.http.get<dashboardSurveyModel>(this.dashboardUrl+'survey');
  }
}
