/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {SurveyControls} from '../enums/survey-controls.enum';
import {ParticipantQuestion, SkipLogicParameter} from '../models/participant-ksa.model';
import {QuestionType} from "../enums/questionType.enum";
import {isEqual} from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class SkipLogicFunctionalityService {

  surveyControlsEnum: typeof SurveyControls = SurveyControls;

  setIsNullSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.answerValuesText === '') {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsNotNullSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.answerValuesText !== '') {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsSelectedSkipLogicMatrix(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    return this.setIsSelectedSkipLogic(skipLogicParams);
  }

  setIsNotSelectedSkipLogicMatrix(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.skipConditionAnswerLabelID !== skipLogicParams.selectedAnswerLabelsID &&
      skipLogicParams.selectedAnswerAction === false) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }


  setIsSelectedSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.skipConditionAnswerLabelID === skipLogicParams.selectedAnswerLabelsID &&
      skipLogicParams.selectedAnswerAction === true) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsNotSelectedSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.skipConditionAnswerLabelID === skipLogicParams.selectedAnswerLabelsID &&
      skipLogicParams.selectedAnswerAction === false) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsEqualToSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.displayValueQuestion === skipLogicParams.answerValuesText) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsNotEqualToSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.answerValuesText !== skipLogicParams.displayValueQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsGreaterThenSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.answerValuesText > skipLogicParams.displayValueQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsNotGreaterThenSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.answerValuesText <= skipLogicParams.displayValueQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsLessThenSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.answerValuesText < skipLogicParams.displayValueQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsNotLessThenSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    let resultParticipantQuestionList: ParticipantQuestion[] = [];

    if (skipLogicParams.answerValuesText >= skipLogicParams.displayValueQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, false);
    } else if (skipLogicParams.isDisplayQuestion) {
      resultParticipantQuestionList = this.setSkipLogicOnQuestions(skipLogicParams, true);
    }

    return resultParticipantQuestionList;
  }

  setIsLessThenOrEqualToSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    return this.setIsNotGreaterThenSkipLogic(skipLogicParams);
  }

  setIsGreaterThenOrEqualToSkipLogic(skipLogicParams: SkipLogicParameter): ParticipantQuestion[] {
    return this.setIsNotLessThenSkipLogic(skipLogicParams);
  }

  setSkipLogicOnQuestions(skipLogicParams: SkipLogicParameter, valueOfDisplay: boolean): ParticipantQuestion[] {
    const typeOfSkip = skipLogicParams?.selectedQuestionToDisplay?.questionSkipCondition?.skipType;
    const questionList: ParticipantQuestion[] = skipLogicParams.questionListParticipant;
    const indexOfSelectedQuestion = questionList.indexOf(skipLogicParams?.selectedQuestionToDisplay);

    for (let i = indexOfSelectedQuestion + 1; i < questionList.length; i++) {
      const isEligibleForSkipCheck: boolean = !questionList[i].questionDisplayCondition || valueOfDisplay === false;
      if (!isEligibleForSkipCheck) {
        //if the question has display condition, we don't want to show it until the display condition is satisfied
        //that's why we allow only to hide it
        continue;
      }
      if (typeOfSkip === this.surveyControlsEnum.survey && skipLogicParams.isDisplayQuestion) {
        this.updateQuestionProperties(questionList[i], valueOfDisplay);
      } else if (typeOfSkip === this.surveyControlsEnum.section && skipLogicParams.isDisplayQuestion) {

        if (questionList[i].sectionQuestionSequenceNo === 1) {
          //This block here is to stop on the first question of next section, as the skip is valid for the current section only
          break;
        } else {
          this.updateQuestionProperties(questionList[i], valueOfDisplay);
        }
      }
    }

    return questionList;
  }

  private updateQuestionProperties(question, valueOfDisplay): void {
    let hasAnswersRemoved = false;
    if (valueOfDisplay === false) {
      hasAnswersRemoved = this.removeQuestionAnswers(question);
    }

    const oldIsDisplayValue = question.isDisplay;
    const oldIsSkipDisplayValue = question.isSkipDisplay;

    question.isDisplay = valueOfDisplay && question.isRoleProfileDisplay;
    question.isSkipDisplay = valueOfDisplay;
    if (hasAnswersRemoved || !isEqual(oldIsDisplayValue, question.isDisplay) || !isEqual(oldIsSkipDisplayValue, question.isSkipDisplay)) {
      question.question.isModified = true;
    }
  }

  private removeQuestionAnswers(question: ParticipantQuestion): boolean {
    let hasChanges = false;
    if (question && question.isVisited) {
      if (question.question.answerLabels.length > 1) {
        if (question.question.questionType.id === QuestionType.MatrixTable) {
          question.question.answerControls.filter(label => label.isChecked).forEach(label => {
            label.isChecked = false;
            hasChanges = true;
          });
        } else {
          question.question.answerLabels.filter(label => label.isChecked).forEach(label => {
            label.isChecked = false;
            hasChanges = true;
          });
        }
      } else {
        hasChanges = !!question.question.answerValuesText;
        question.question.answerValuesText = '';
      }
      question.isVisited = false;
    }

    return hasChanges;
  }
}
