<div *ngIf="isErrorOnLoad" class="alert alert-danger" id="alert-message" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span class="alert-text">
        {{errorMessage}}
    </span>
    </div>
  </div>
</div>

<form clrForm #addForm="ngForm" autocomplete="off" class="clr-form clr-row">
  <clr-select-container class="clr-col-6 clr-row">
    <label class="clr-col-3">{{'analysisAndReports.responseManagement.ksaId' | translate}}*</label>
    <select (change)="onSelectKsaDetails($event.target.value)" class="clr-col-8" clrSelect>
      <option disabled selected value="">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectKsa' | translate}}</option>
      <option *ngFor="let ksaDetails of closedKsaList" [value]="ksaDetails.id">
        {{ksaDetails.ksaName}}
      </option>
    </select>
    <clr-control-error>{{'errorMsg.ksaIsRequired'|translate}}</clr-control-error>
  </clr-select-container>
  <clr-toggle-container class="clr-col-6 clr-row field-height" *ngIf="ksaId">
    <label class="clr-col-3">{{'ksaEngagement.ksaAdministration.ksaType'|translate}} </label>
    <clr-toggle-wrapper class="clr-col-1 clr-row">
      <input [disabled]="ksaIdIsAdvancedMap[selectedKsa?.id]" (change)="onSelectKsaMode($event.target.checked)" [ngModel]="selectedKsa?.isAdvance" class="clr-col-1" clrToggle name="ksaType"
             type="checkbox"/>
      <label class="clr-col-2">{{'ksaEngagement.ksaAdministration.advanced'|translate}}</label>
    </clr-toggle-wrapper>
    <label class="clr-col-2 light-label">{{'ksaEngagement.ksaAdministration.simple'|translate}}</label>
  </clr-toggle-container>
</form>
<br>
<div>
  <clr-tabs>
    <clr-tab>
      <button clrTabLink>{{'tab.analysis.heatMap' | translate}}</button>
      <ng-template [(clrIfActive)]="heatMapSelected">
      <clr-tab-content>
        <app-heat-map-report [ksaId]="ksaId" [responseData]="responseData"
                             [showNewWindowButton]="true"></app-heat-map-report>
      </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab *ngIf="selectedKsa?.isAdvance">
      <button clrTabLink>{{'tab.analysis.roleMap' | translate}}</button>
      <ng-template  [(clrIfActive)]="roleMapSelected">
      <clr-tab-content *clrIfActive>
        <app-role-map-report [ksaId]="ksaId" [responseData]="responseData"
                             [showNewWindowButton]="true"></app-role-map-report>
      </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab *ngIf="selectedKsa?.isAdvance">
      <button clrTabLink>{{'tab.analysis.roleProfiles' | translate}}</button>
      <ng-template  [(clrIfActive)]="roleProfileSelected">
      <clr-tab-content>
        <app-role-profiling [ksaId]="ksaId" [showNewWindowButton]="true"></app-role-profiling>
      </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>{{'tab.analysis.trainingRecommendations' | translate}}</button>
      <ng-template  [(clrIfActive)]="trainingSelected" >
      <clr-tab-content>
        <app-training-recommendations [ksaId]="ksaId" [responseData]="responseData"
                                      [showNewWindowButton]="true"></app-training-recommendations>
      </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>{{'tab.analysis.certification' | translate}}</button>
      <ng-template  [(clrIfActive)]="certificationSelected">
      <clr-tab-content *clrIfActive>
        <app-cert-report [ksaId]="ksaId" [showNewWindowButton]="true"></app-cert-report>
      </clr-tab-content>
      </ng-template>
    </clr-tab>
  </clr-tabs>
</div>
