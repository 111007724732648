import {ParticipantQuestion} from "../models/participant-ksa.model";
import {RegexValidation} from "../components/constants/regex-validation.constants";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FreeTextValidationService {

  static readonly EMAIL: string = "email";

  isAnswerValid(question: ParticipantQuestion): boolean {
    const answer: string = question.question.answerValuesText.toString();
    return answer === '' || this.getFreeTextPattern(question).test(answer);
  }

  getFreeTextPattern(question: ParticipantQuestion): RegExp {
    if (question.question.questionText.toLowerCase() === FreeTextValidationService.EMAIL) {
      return RegexValidation.EMAIL_REGEX;
    } else {
      return RegexValidation.FREE_TEXT_REGEX;
    }
  }
}
