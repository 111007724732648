<div class="ksa-info">
  <div *ngIf="saveSuccess" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{"analysisAndReports.reports.participantRecommendations.savedSucessMsg" | translate}}
      </span>
      </div>
    </div>
</div>
<div *ngIf="participantData">
  <form clrForm #addForm="ngForm" autocomplete="on" class="clr-row clr-form">
  <clr-select-container class="clr-col-5">
    <label class="clr-col-4">{{"analysisAndReports.reports.participantRecommendations.roleProfile" | translate}}</label>

    <select clrSelect name="options1" (change)="changeDeltaValues(participantData.participantId,participantData.participantAnalysisList[0].roleMapAnalysisList[0].participantRoleProfile.recommendedKsaRoleProfile.id, $event)"
          [(ngModel)]="participantData.participantAnalysisList[0].roleMapAnalysisList[0].participantRoleProfile.recommendedKsaRoleProfile.id">
          <option *ngFor="let roleProfile of ksaSet"
            [selected]="roleNameOnLoad === roleProfile.id"
            [value]="roleProfile.id">
            {{roleProfile.ksaRoleProfileName}}
          </option>
        </select>
  </clr-select-container>
  <div class="clr-col-3 btn-alignment">
    <apx-chart
      [series]=[chartOptions.series]
      [chart]="chartOptions.chart"
      [plotOptions]="chartOptions.plotOptions"
      [labels]="chartOptions.labels">
    </apx-chart>
    <div class="roleAlignmentAverage-cell-alignment">
      {{ksaDetails?.isAdvance ? ('analysisAndReports.reports.participantRecommendations.roleProfileAlginment' | translate) :
      ('analysisAndReports.reports.participantRecommendations.simpleThreshold' | translate)}}
      {{': ' + participantData?.roleAlignmentAverage + '%'}}
    </div>
  </div>
  <div class="clr-col-4 btn-alignment">
    <button class="btn btn-sm btn-primary" type="button" (click)="navigateBack()">
      {{"analysisAndReports.reports.participantRecommendations.backBtn" | translate}}
    </button>
    <button class="btn btn-sm btn-primary" type="button" (click)="scorecardPreview=true">
      {{'analysisAndReports.scorecards.previewScorecard' | translate}}
    </button>
    <button class="btn btn-sm btn-primary" type="button" (click)="saveParticipantDetails()"
            *hasAccess="functionality.analysis">
      {{"analysisAndReports.reports.participantRecommendations.saveBtn" | translate}}
    </button>
  </div>
  </form>
  <clr-modal *ngIf="scorecardPreview" [(clrModalOpen)]="scorecardPreview"
             [clrModalClosable]="true" [clrModalSize]="'lg'">
    <h3 class="modal-title">{{'analysisAndReports.scorecards.scorecardPreview' | translate}}</h3>
    <div class="modal-body">
      <scorecard-modal [participantId]="participantData.participantId" [ksaId]="ksaId"></scorecard-modal>
    </div>
    <div class="modal-footer">
      <button id="{{btnId.btnCancelScorecard}}" class="btn btn-sm" (click)="scorecardPreview=false">
        {{'common.cancel' | translate}}
      </button>
    </div>
  </clr-modal>
</div>
<div class="participant-details-div participant-block" *ngIf="participantData">
  <div class="card">
    <div class="card-block">
      <h4 class="card-title card-header">{{"analysisAndReports.reports.participantRecommendations.participantDetails" | translate}}</h4>
      <div style="text-align: center">
        <div class="profile-picture">
          <clr-icon shape="user" class="person-icon"></clr-icon>
        </div>
        <div>{{participantData?.participantAnalysisList[0]?.participant?.firstName}}&nbsp;
          {{participantData?.participantAnalysisList[0]?.participant?.lastName}}</div>
        <div>{{participantData?.participantAnalysisList[0]?.participant?.jobTitle}}</div>
      </div>
    </div>
    <ul class="list-group" *ngFor="let item of participantData.responseColumnWithParticipants">
      <li class="list-group-item">
        <div class="clr-row">
          <div class="clr-col-6 participant-details-font">
            {{item?.responseColumnDisplay?.questionName}}
          </div>
          <div class="clr-col-6 participant-details-font">
            {{item?.answerText}}
          </div>
        </div>
      </li>
    </ul>
  </div>
<br>
  <div class="card">
    <div class="card-block skills-header">
      <h4 class="card-title card-header">{{"analysisAndReports.reports.participantRecommendations.certifications" | translate}}</h4>
    </div>
    <div>
      <clr-datagrid *ngIf="certificateDetails">
        <clr-dg-column>{{"analysisAndReports.reports.participantRecommendations.certificationName" | translate}}</clr-dg-column>
        <clr-dg-column>{{"analysisAndReports.reports.participantRecommendations.achieved" | translate}}</clr-dg-column>
        <clr-dg-column>{{"analysisAndReports.reports.participantRecommendations.desired" | translate}}</clr-dg-column>
        <clr-dg-row *clrDgItems="let column of certificateDetails?.certificateData[0]?.certificationByParticipants; let i = index">
          <clr-dg-cell>{{column.analysisCertification?.certificationName}}</clr-dg-cell>

          <clr-dg-cell>
            <div *ngIf="column?.resultAchieved" class="achieved-desired">
              <div class="clr-row">
                <div class="clr-col-2">
                  <clr-tooltip>
                    <clr-icon badge="success" clrTooltipTrigger shape="certificate"></clr-icon>
                    <clr-tooltip-content>
                      {{'analysisAndReports.responseManagement.achieved' | translate}}
                    </clr-tooltip-content>
                  </clr-tooltip>
                </div>
              </div>
            </div>
          </clr-dg-cell>
          <clr-dg-cell>
            <div *ngIf="column?.resultDesired" class="desired">
              <div class="clr-row">
                <div class="clr-col-2">
                  <clr-tooltip>
                    <clr-icon badge="success" clrTooltipTrigger shape="bullseye"></clr-icon>
                    <clr-tooltip-content>
                      {{'analysisAndReports.responseManagement.desired' | translate}}
                    </clr-tooltip-content>
                  </clr-tooltip>
                </div>
              </div>
            </div>
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
    </div>
  </div>
<br>
  <div class="card">
    <div class="card-block skills-header">
      <h4 class="card-title card-header">{{"analysisAndReports.reports.participantRecommendations.skillAndTrainingRecommendations" | translate}}</h4>
    </div>

    <div>
      <clr-datagrid>
        <clr-dg-column>{{"analysisAndReports.reports.participantRecommendations.skill" | translate}}</clr-dg-column>
        <clr-dg-column>{{"analysisAndReports.reports.participantRecommendations.score" | translate}}</clr-dg-column>
        <clr-dg-column *ngIf="ksaDetails?.isAdvance">{{"analysisAndReports.reports.participantRecommendations.target" | translate}}</clr-dg-column>
        <clr-dg-column *ngIf="ksaDetails?.isAdvance">{{"analysisAndReports.reports.participantRecommendations.delta" | translate}}</clr-dg-column>
        <clr-dg-column class="width-220">{{"analysisAndReports.reports.participantRecommendations.courseRecommendation" | translate}}</clr-dg-column>
        <clr-dg-column>{{"analysisAndReports.reports.participantRecommendations.publicNotes" | translate}}</clr-dg-column>

        <clr-dg-row *clrDgItems="let response of participantData?.participantAnalysisList; let i = index">
          <clr-dg-cell class="column-score">
            {{response.skill.skillName}}
          </clr-dg-cell>
          <clr-dg-cell class="column-score">
            <div [style.color]="response.colorCode">
              {{response.heatMapValue != null ? response.heatMapValue+'%' : "NA"}}
            </div>
          </clr-dg-cell>
          <clr-dg-cell class="column-score" *ngIf="ksaDetails?.isAdvance">
            {{response.roleMapAnalysisList[0].roleMapValue != null ? response.roleMapAnalysisList[0].roleMapValue+'%': 'NA'}}
          </clr-dg-cell>
          <clr-dg-cell class="column-score" *ngIf="ksaDetails?.isAdvance">
            <div [style.color]="response.tableBackgroundColorCode">
            {{response.roleMapAnalysisList[0].deltaValue != null ? response.roleMapAnalysisList[0].deltaValue+'%': 'NA'}}
            </div>
          </clr-dg-cell>
          <clr-dg-cell class="course-dropdown-cell">
            <div class="clr-row">
              <clr-select-container class="course-dropdown clr-col-11">
                <select id="courseSelected" clrSelect name="options{{i}}" (change)="setRecommendedCourse($event.target.value,i)" class="clr-col-12">
                  <option selected value="null || =>0">No</option>
                  <ng-container *ngFor="let course of courseList">
                    <option id="courseOption" *ngIf="course.skillId == response.skill.id"
                            [selected]="response.roleMapAnalysisList[0].recommendedCourse?.id === course.courseList.id ?
                response.roleMapAnalysisList[0].recommendedCourse?.id === course.courseList.id : false"
                            [value]="course.courseList.id"
                            title='{{course.courseList.courseName}}{{course.courseList.courseVersion === "" ? "" : " ["+course.courseList.courseVersion+"]"}}'>
                      {{course.courseList.courseName}}{{course.courseList.courseVersion === "" ? "" : " [" + course.courseList.courseVersion + "]"}}
                    </option>
                  </ng-container>
                </select>
              </clr-select-container>
              <clr-tooltip class="tooltip tooltip-xs tooltip-top-left toolTip-icon clr-col-1">
                <clr-icon badge="success" clrTooltipTrigger shape="info-circle"></clr-icon>
                <clr-tooltip-content>
                  {{response.roleMapAnalysisList[0].recommendedCourse?.courseName && (response.roleMapAnalysisList[0].recommendedCourse?.status !== 'Archived') ?
                  response.roleMapAnalysisList[0].recommendedCourse?.courseName : "analysisAndReports.reports.trainingRecommendation.noRecommendedCourse" | translate}}
                  {{response.roleMapAnalysisList[0]?.recommendedCourse?.courseVersion && (response.roleMapAnalysisList[0].recommendedCourse?.status !== 'Archived') ?
                  ' [' + response.roleMapAnalysisList[0].recommendedCourse?.courseVersion + ']' : ''}}
                </clr-tooltip-content>
              </clr-tooltip>
            </div>
          </clr-dg-cell>
          <clr-dg-cell>
            <textarea rows="5" cols="100" style="padding-top: 0;" clrTextarea [(ngModel)]="response.roleMapAnalysisList[0].courseNote"></textarea>
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
    </div>
    <div>
      <div class="clr-row">
        <div class="clr-col-6">
          <textarea clrTextarea name="description" placeholder="Private Notes" rows="5" cols="100"
            [(ngModel)]="participantData.ksaParticipantNote.privateNote"></textarea>
        </div>
        <div class="clr-col-6">
          <textarea clrTextarea name="description" placeholder="Public Notes" rows="5" cols="100"
          [(ngModel)]="participantData.ksaParticipantNote.publicNote"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
