/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/
import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Course, CourseSkillRequest} from "../models/course.model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({providedIn: 'root'})
export class CoursesService {

  private courseUrl: string;

  constructor(private http: HttpClient) {
    this.courseUrl = `${environment.API_URL}${API_KSA_CORE_V1}/course/`;
  }

  getCourses(filter1, filter2, filter3, filter4, filter5, filter6, from, to, sortby, sortorder): Observable<Course> {
    filter1 = encodeURIComponent(filter1);
    filter2 = encodeURIComponent(filter2);
    filter3 = encodeURIComponent(filter3);
    filter4 = encodeURIComponent(filter4);
    filter5 = encodeURIComponent(filter5);
    return this.http.get<Course>(`${this.courseUrl}?tag.tagName=${filter1}&courseId=${filter2}&courseName=${filter3}&courseDescription=${filter4}&courseVersion=${filter5}&status=${filter6}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`);
  }

  getActiveCourses(): Observable<CourseSkillRequest[]> {
    return this.http.get<CourseSkillRequest[]>(`${this.courseUrl}active/`);
  }

  getSelectedCoursesByCourseId(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.courseUrl}active/${id}`);
  }

  getCoursesByVersion(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.courseUrl}versions/${id}`);
  }

  createCourse(course: Course): Observable<any> {
        return this.http.post(`${this.courseUrl}`, course);
    }

    updateCourse(course: Course): Observable<any> {
        return this.http.put(`${this.courseUrl}`, course);
    }

    deleteCourse(id: number): Observable<any> {
        return this.http.delete(`${this.courseUrl}${id}`);
    }

    activateCourse(id: number): Observable<any> {
        return this.http.put(`${this.courseUrl}active/`, `${id}`);
    }

    archiveCourse(id: number): Observable<any> {
        return this.http.put(`${this.courseUrl}archive/`, `${id}`);
    }
}
