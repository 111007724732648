/*******************************************************************
 Copyright 2022 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/
import {Component, OnInit} from '@angular/core';
import {QuestionMode} from '../../../../enums/ksa-survey-settings.enum';
import {QuestionType} from '../../../../enums/questionType.enum';
import {SurveyControls} from '../../../../enums/survey-controls.enum';
import {KsaRoleProfile} from '../../../../models/ksa-role-profile-skill.model';
import {AnswerLabel} from '../../../../models/master-survey-questions.model';
import {ParticipantQuestion} from '../../../../models/participant-ksa.model';
import {DisplayLogicService} from '../../../../services/display-logic.service';
import {KsaSurveyPageFunctionalityService} from '../../../../services/ksa-survey-page-functionality.service';
import {ParticipantService} from '../../../../services/participant.service';
import {SkipLogicService} from '../../../../services/skip-logic.service';
import {KsaShareService} from "../../../../services/ksa-share.service";
import {cloneDeep} from "lodash";
import {FreeTextValidationService} from "../../../../services/free-text-validation.service";
import {QuestionVisibilityUtil} from '../../../participants/util/QuestionVisibilityUtil';

@Component({
  selector: 'app-ksa-preview',
  templateUrl: './ksa-preview.component.html',
  styleUrls: ['./ksa-preview.component.scss']
})
export class KsaPreviewComponent implements OnInit {
  questionList: ParticipantQuestion[] = [{index: 0}];
  questionsToDisplay: ParticipantQuestion[] = [{
    index: 0,
    isDisplay: true,
    isSkipDisplay: true,
    isRoleProfileDisplay: true
  }];
  initial: number = 0;
  displayPreButton: boolean;
  questionTypeEnum: typeof QuestionType = QuestionType;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  ksaDetails: any;
  roleProfiles: Array<KsaRoleProfile> = [];
  skillsIdsJobRoleProfile: number[];
  nextIndex: number = 0;
  firstIndex: number = 0;
  unansweredQuestionFound: boolean = false;
  progressBarCount: number = 0;
  questionModeEnum: typeof QuestionMode = QuestionMode;
  validPageFound: boolean = false;
  showRoleProfileChangeWarning: boolean = false;
  showNextButton: boolean = false;
  showSubmitButton: boolean = false;
  surveyCompleted: boolean = false;
  freeTextInvalidAnswerInputs: Array<ParticipantQuestion> = [];

  constructor(private displayLogicService: DisplayLogicService,
              private skipLogicService: SkipLogicService,
              private participantService: ParticipantService,
              private ksaSurveyPageFunctionalityService: KsaSurveyPageFunctionalityService,
              private ksaShareService: KsaShareService,
              readonly freeTextValidationService: FreeTextValidationService) {
  }

  ngOnInit(): void {
    this.ksaShareService.getKSADetails().subscribe(data => {
      this.ksaDetails = data;
      if (this.ksaDetails?.isAdvance) {
        this.ksaDetails.ksaJobProfileSet?.ksaRoleProfileGroups?.flatMap(x => x.ksaRoleProfiles).forEach(x => this.roleProfiles.push(x));
      }
    });
    this.populateSurvey();
  }


  populateSurvey(): void {
    this.questionList = cloneDeep(this.participantService.populateSurveyQuestions(this.ksaDetails.ksaSection, true, null, this.ksaDetails.isAdvance));
    this.ksaPreviewQuestionInitialize();
  }

  ksaPreviewQuestionInitialize(): void {
    this.showSubmitButton = false;
    this.showNextButton = false;
    this.onDisplayNextList(0);
    this.ksaSurveyPageFunctionalityService.setDisplayOnInitialize(this.questionList);
    this.firstIndex = this.questionList[0].index;
    this.checkForUnansweredQuestion();
    if (this.questionList[this.questionList.length - 1]?.index === this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index && this.freeTextInvalidAnswerInputs.length === 0) {
      this.showSubmitButton = true;
      this.showNextButton = false;
    }
  }

  onRoleProfileSelect(id: number, question: ParticipantQuestion): void {
    if (question.question.answerValuesText !== '' && question.roleProfileInitialValue !== '') {
      this.showRoleProfileChangeWarning = true;
    }
    question.roleProfileInitialValue = question.question.answerValuesText;
    this.onRoleProfileSelection(id, question);
  }

  onRoleProfileSelection(id: number, question: ParticipantQuestion): void {
    this.participantService.getKsaRoleProfileSkill(id).subscribe(response => {
      this.skillsIdsJobRoleProfile = response;

      if (this.ksaDetails.surveyQuestionMode.id === this.questionModeEnum.RoleProfileDriven) {
        this.questionList.filter(q => !q.isRoleProfileDisplay).forEach(q => {
          q.question.isModified = true;
          q.isRoleProfileDisplay = true;
        });
        this.ksaSurveyPageFunctionalityService.setDisplayOnInitialize(this.questionList);

        this.questionList
          .filter(q => q.skill?.id && !this.skillsIdsJobRoleProfile.includes(q.skill?.id))
          .forEach(QuestionVisibilityUtil.hideQuestionsWhenRoleProfileFilterIsApplied);
      }

      const questionMap = QuestionVisibilityUtil.groupQuestionsBySectionId(this.questionList);
      questionMap.forEach((questions, key) => {
        if (!QuestionVisibilityUtil.shouldSectionBeVisible(questions)) {
          questions.forEach(QuestionVisibilityUtil.hideQuestionsWhenRoleProfileFilterIsApplied);
        }
      });

      this.mapVisitedQuestion(question);
      this.checkForUnansweredQuestion();
      this.checkNextPageValidity();
    });
  }

  onDisplayNextList(start: number): void {
    this.showSubmitButton = false;
    this.showNextButton = false;
    this.initial = start;

    const objNextButton = this.ksaSurveyPageFunctionalityService.onDisplayNextList(start, this.questionList);

    this.questionsToDisplay = objNextButton.questionsToDisplay;
    this.displayPreButton = objNextButton.displayPreButton;
    this.nextIndex = this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index;

    this.checkForUnansweredQuestion();
    if (this.questionList[this.questionList.length - 1]?.index > this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index) {
      this.showSubmitButton = false;
      this.showNextButton = true;
    }
    this.checkNextPageValidity();
    if (document.getElementById('questionContainer')) {
      document.getElementById('questionContainer').scrollTop = 0;
    }
  }

  onDisplayPreList(): void {
    this.showSubmitButton = false;
    this.showNextButton = false;
    const objPreButton = this.ksaSurveyPageFunctionalityService.onDisplayPreList(this.questionList, this.initial);

    this.questionsToDisplay = objPreButton.questionsToDisplay;
    this.initial = objPreButton.initial;
    this.displayPreButton = objPreButton.displayPreButton;
    this.nextIndex = objPreButton.nextIndex;

    this.checkForUnansweredQuestion();
    if (this.questionList[this.questionList.length - 1]?.index > this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index) {
      this.showSubmitButton = false;
      this.showNextButton = true;
    }
    if (document.getElementById('questionContainer')) {
      document.getElementById('questionContainer').scrollTop = 0;
    }
  }

  onAnswerInputFreeText(question: ParticipantQuestion): void {
    if (!this.freeTextValidationService.isAnswerValid(question)) {
      this.unMapVisitedQuestion(question);
      this.flagFreeTextAnswerInvalid(question);
      this.checkForUnansweredQuestion();
      return;
    }

    this.removeFlaggedFreeTextAnswerInvalid(question);

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForFree(question, this.questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForFree(question, this.questionList, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForFree(question, this.questionList, false);
    }

    this.checkForUnansweredQuestion();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  checkNextPageValidity(): void {
    if (this.questionList[this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index]?.index - 1) {
      this.validPageFound = this.ksaSurveyPageFunctionalityService.checkIfNextPageIsBlank(
        this.questionList[this.questionsToDisplay[this.questionsToDisplay.length - 1].index].index - 1,
        this.questionList)
      if (!this.validPageFound) {
        this.showSubmitButton = true;
        this.showNextButton = false;
      } else {
        this.showSubmitButton = false;
        this.showNextButton = true;
      }
    }
    if (this.questionList[this.questionList.length - 1]?.index === this.questionsToDisplay[this.questionsToDisplay.length - 1]?.index
      && this.questionList[0]?.index !== this.questionsToDisplay[0]?.index) {
      this.showSubmitButton = true;
    }
  }

  onAnswerInputRadio(question: ParticipantQuestion, selectedAnswerLabel: AnswerLabel): void {
    this.ksaSurveyPageFunctionalityService.selectAnswerInputRadio(question.question.answerLabels, selectedAnswerLabel);

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForRadio(question, this.questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForRadio(question, this.questionList, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForRadio(question, this.questionList, false);
    }

    this.checkForUnansweredQuestion();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  onAnswerInputMultiple(question: ParticipantQuestion, answerLabel: AnswerLabel): void {
    question.question.answerLabels.forEach(answerLabel => {

      if (answerLabel[this.surveyControlsEnum.isChecked] === null) {
        answerLabel[this.surveyControlsEnum.isChecked] = false;
      }
    });

    answerLabel.isChecked = !answerLabel.isChecked;

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForMultiple(question, this.questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMultiple(question, this.questionList, answerLabel, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMultiple(question, this.questionList, answerLabel, false);
    }

    this.checkForUnansweredQuestion();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  onAnswerInputScale(question: ParticipantQuestion, answer: number): void {
    question.question.answerValuesText = answer;

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForScale(question, this.questionList);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForScale(question, this.questionList, answer, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForScale(question, this.questionList, answer, false);
    }

    this.checkForUnansweredQuestion();
    this.mapVisitedQuestion(question);
    this.checkNextPageValidity();
  }

  onAnswerRadioInputMatrix(question: ParticipantQuestion, rowNumber: number, columnNumber: number, checkedValue: boolean): void {
    question.question.answerControls.forEach(control => {

      if (control.rowNumber === rowNumber && control.colNumber === columnNumber) {
        control.isChecked = checkedValue;
      } else if (control.rowNumber === rowNumber) {
        control.isChecked = false;
      }

    });

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForMatrixRadio(question, this.questionList,
        checkedValue, rowNumber, columnNumber);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMatrixRadio(question, this.questionList,
        checkedValue, rowNumber, columnNumber, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMatrixRadio(question, this.questionList,
        checkedValue, rowNumber, columnNumber, false);
    }

    this.checkForUnansweredQuestion();
    this.mapVisitedQuestion(question);
    this.onMatrixAnswerCheckAnswerControl(question);
    this.checkNextPageValidity();
  }

  onAnswerCheckInputMatrix(question: ParticipantQuestion, rowNumber: number, columnNumber: number, checkedValue: boolean): void {
    question.question.answerControls.forEach(control => {

      if (control.rowNumber === rowNumber && control.colNumber === columnNumber) {
        control.isChecked = checkedValue;
      }
    });

    if (question.questionSkipCondition?.id) {
      this.questionList = this.skipLogicService.skipLogicForMatrixCheckBox(question, this.questionList,
        checkedValue, rowNumber, columnNumber);
    }

    if (question.questionDisplayConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMatrixCheckBox(question, this.questionList,
        checkedValue, rowNumber, columnNumber, true);
    }

    if (question.sectionSkipConditions?.length > 0) {
      this.questionList = this.displayLogicService.displayLogicForMatrixCheckBox(question, this.questionList,
        checkedValue, rowNumber, columnNumber, false);
    }

    this.checkForUnansweredQuestion();
    this.mapVisitedQuestion(question);
    this.onMatrixAnswerCheckAnswerControl(question);
    this.checkNextPageValidity();
  }

  onMatrixAnswerCheckAnswerControl(question: ParticipantQuestion): void {
    question.question.answerControlMatrixList = Object.assign(Object.values(question?.question?.answerControls?.reduce((grouped, row) => {
      const isRow = row.rowNumber;

      if (grouped[isRow] == null) {
        grouped[isRow] = [];
      }

      grouped[isRow].push(row);
      return grouped;

    }, {})));
  }

  mapVisitedQuestion(selectedQuestion: ParticipantQuestion): void {
    const objQuestion = this.questionList.find(question => (question.isDisplay === true &&
      question.index === selectedQuestion.index));
    if (objQuestion != null) {
      if (objQuestion.question.answerLabels.length > 1) {
        if (objQuestion.question.questionType.id === this.questionTypeEnum.MatrixTable) {
          const isLabelSelected = objQuestion.question.answerControls.find(label => {
            return label.isChecked === true;
          });
          isLabelSelected === undefined ? objQuestion.isVisited = false : objQuestion.isVisited = true;
        } else {
          const isLabelSelected = objQuestion.question.answerLabels.find(label => {
            return label.isChecked === true;
          });
          isLabelSelected === undefined ? objQuestion.isVisited = false : objQuestion.isVisited = true;
        }
      } else {
        objQuestion.question.answerValuesText === '' ? objQuestion.isVisited = false : objQuestion.isVisited = true;
      }
    }
    this.getProgressBarCount();
  }

  unMapVisitedQuestion(selectedQuestion: ParticipantQuestion): void {
    const objQuestion: ParticipantQuestion = this.questionList.find(question => (question.isDisplay &&
      question.index === selectedQuestion.index));

    if (objQuestion) {
      objQuestion.isVisited = false;
      this.getProgressBarCount();
    }
  }

  getProgressBarCount(): void {
    let totalQuestionCount = this.questionList.filter(question =>
      (!question.isRoleProfileQuestionVisbileForKsa && question.isDisplay === true && question.isMandatory === true &&
        question.question.questionType.id !== this.questionTypeEnum.Description)).length;

    let totalVisitedQuestionCount = this.questionList.filter(question =>
      (!question.isRoleProfileQuestionVisbileForKsa && question.isDisplay === true && question.isMandatory === true &&
        question.isVisited === true &&
        question.question.questionType.id !== this.questionTypeEnum.Description)).length;
    if (totalQuestionCount == null) {
      totalQuestionCount = 1;
    }

    if (totalVisitedQuestionCount == null) {
      totalVisitedQuestionCount = 1;
    }
    this.progressBarCount = Math.round((totalVisitedQuestionCount * 100) / totalQuestionCount);
  }

  getQuestionTypeId(question: any): number {
    return question?.questionType?.id ?? question?.question?.questionType?.id;
  }

  private flagFreeTextAnswerInvalid(question: ParticipantQuestion): void {
    if (!this.freeTextInvalidAnswerInputs.includes(question)) {
      this.freeTextInvalidAnswerInputs.push(question);
    }
  }

  private removeFlaggedFreeTextAnswerInvalid(question: ParticipantQuestion): void {
    if (this.freeTextInvalidAnswerInputs.includes(question)) {
      this.freeTextInvalidAnswerInputs.forEach((value, index) => {

        if (value === question) {
          this.freeTextInvalidAnswerInputs.splice(index, 1);
        }
      })
    }
  }

  private checkForUnansweredQuestion(): void {
    this.unansweredQuestionFound = this.freeTextInvalidAnswerInputs.length !== 0 ||
      this.ksaSurveyPageFunctionalityService.mandatoryQuestionAnswered(this.questionsToDisplay, this.ksaDetails.isAdvance);
  }

}
