import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html'
})
export class DateFilterComponent {
  temp: string = "";
  @Output() filterDate = new EventEmitter<any>();
  date: any;
  inValidDate: boolean = false;
  @Output() isInValidDate = new EventEmitter<boolean>();

  show() {
    if (this.temp) {
      const x = this.temp.split('/');
      const m = x[0];
      const d = x[1];
      const y = x[2];
      if (parseInt(m) > 12 || parseInt(d) > 31) {
        this.inValidDate = true;
        this.date = "";
      } else {
        this.inValidDate = false;
        this.date = y + "-" + m + "-" + d;
      }
    } else {
      this.date = "";
    }
    this.filterDate.emit(this.date);
    this.isInValidDate.emit(this.inValidDate);
  }
}
