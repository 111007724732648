<div *ngIf="templateUploadSuccess" class="alert alert-success"
     role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">
          {{'tab.reports.templateUploadSuccessMessage' | translate}}
        </span>
    </div>
  </div>
</div>
<div *ngIf="hasReportDownloadFailed" class="alert alert-danger" id="alert-message" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span class="alert-text">
          {{'tab.reports.reportDownloadFailedMsg'|translate}}
        </span>
    </div>
  </div>
</div>
<div>
  <form clrForm #addForm="ngForm" autocomplete="off" class="clr-form clr-row" clrLabelSize="4">
    <div class="clr-col-7 clr-row">
      <clr-select-container class="clr-col-8">
        <label>{{'analysisAndReports.responseManagement.ksaId' | translate}}*</label>
        <select (change)="onSelectKsaDetails($event.target.value)" clrSelect>
          <option disabled selected value="">
            {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectKsa'
            | translate}}</option>
          <option *ngFor="let ksaDetails of closedKsaList" [value]="ksaDetails.id">
            {{ksaDetails.ksaName}}
          </option>
        </select>
        <clr-control-error>{{'errorMsg.ksaIsRequired'|translate}}</clr-control-error>
      </clr-select-container>
      <div class="clr-col-4 template-icon-container" *ngIf="ksaTemplateName !== ''">
        <span>
          <clr-icon shape="file" badge="info" size="30"></clr-icon>
        </span>
        <span>
          <p style="margin-top: 0;">{{ksaTemplateName}}</p>
        </span>
      </div>
    </div>
    <div class="clr-col-5 reports-btn-container">
      <div class="template-btn-group">
        <label for="txtFileUpload" title="upload template" *ngIf="selectedKsaId && hasWritePermission"
               style="padding-left: 0;">
          <span class="btn btn-icon">
            <clr-icon shape="upload"></clr-icon>
          </span>
        </label>
        <input name="UploadDoc" id="txtFileUpload" (change)="uploadTemplate($event)" accept=".docx" class="clr-file"
               type="file"/>
        <button id="downloadKsaReport" *ngIf="isKsaSelected" type="button" title="download report"
                class="btn btn-icon" aria-label="eye" (click)="downloadDocx()">
          <clr-icon shape="download"></clr-icon>
        </button>
        <button id="viewKsaTemplate" (click)="viewPdf()" type="button" class="btn btn-icon" aria-label="eye"
                title="view pdf" *ngIf="isKsaSelected">
          <cds-icon shape="eye"></cds-icon>
        </button>
      </div>
    </div>
  </form>
</div>
<div class="center">
  <div *ngIf="reportsUrl" class="iframe-container">
    <div id="loadImg">
      <clr-spinner>loading..</clr-spinner>
    </div>
    <iframe title="report" class="embed-pdf-size" [src]="reportsUrl" type="application/pdf" id="iframeData"
            (load)="loadPdfAfterBtnClick()">
    </iframe>
  </div>
</div>
