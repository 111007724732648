import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ClrDatagridStateInterface} from '@clr/angular';
import {RoleProfileStandardProperties} from '../../../../../enums/ksa-role-profile.enum';
import {
  ContentEntity,
  KsaRoleProfileGroupsEntity,
  KsaRoleProfilesEntity,
  KsaSkillWithRoleProfilesEntity,
  RoleProfileSet
} from '../../../../../models/ksa-role-profile-set.model';
import {KsaRoleProfileService} from '../../../../../services/ksa-role-profile.service';
import {MasterSurveyService} from '../../../../../services/master-survey.service';
import {KsaShareService} from "../../../../../services/ksa-share.service";
import {RoleProfileGroupService} from "../../../../../services/role-profile-groups.service";
import {TranslateService} from "@ngx-translate/core";
import {finalize} from "rxjs/operators";
import {hideSpinner, showSpinner} from "../../../../../common/spinner";
import {UserService} from '../../../../../services/user.service';
import {Functionality} from '../../../../../enums/functionality.enum';

@Component({
  selector: 'app-job-profile-set',
  templateUrl: './job-profile-set.component.html',
  styleUrls: ['./job-profile-set.component.scss']
})
export class JobProfileSetComponent implements OnInit {
  roleProfileGroupsSelectedFromMaster: any = [];
  roleProfileGroupsSelectedFromLibrary: any = []
  roleProfileGroupsFromImport: any = [];
  importedKsas: any[] = [];
  importedKsaSelected: any = {};
  importedRoleProfileSetSelected: any;
  ksaData: any;
  roleProfileSets: any = {};
  jobProfileSets: RoleProfileSet = {
    content: [],
  };
  selectedSets: ContentEntity[] = [];
  showDeleteWarning: boolean = false;
  addEditRoleProfileSetFormOpen: boolean = false;
  statusToFilter: string = '';
  page: number;
  total: number;
  roleProfileGroups: any[] = [];
  roleProfileGroupsSelected: any[] = [];
  importFromLibrary: boolean = false;
  deleteSetId: number;
  state: ClrDatagridStateInterface;
  roleProfileEnum: typeof RoleProfileStandardProperties = RoleProfileStandardProperties;
  jobProfileSet: any = this.initializeJobProfileSetValues();
  newJobProfileSetToPush: any = this.initializeJobProfileSetValues();
  errorMsg: string = '';
  hasWritePermission: boolean;

  constructor(public readonly ksaShareService: KsaShareService,
              public readonly masterSurveyService: MasterSurveyService,
              private readonly ksaRoleProfileService: KsaRoleProfileService,
              public readonly translate: TranslateService,
              private readonly roleProfileGroupService: RoleProfileGroupService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.ksaAdministration);
    this.getKsaData();
  }

  getRoleProfileGroups(): void {
    this.roleProfileGroupService.findRoleProfileGroupsBySurveyTemplate(this.ksaData.masterSurvey.id)
      .subscribe(data => {
        this.roleProfileGroups = data;
      })
  }

  getKsaData(): void {
    this.ksaShareService.getKSADetails().subscribe(data => {
      this.ksaData = data;
    })
  }

  getImportedKsaRoleProfileSet(): void {
    this.importedKsaSelected={}
    this.importedRoleProfileSetSelected ={}
    this.ksaRoleProfileService.getImportedActiveKsa().subscribe((data) => {
      this.importedKsas = data;
    })
  }


  ksaSelected(importedKsaSelected:any):void{
    const profile = (document.getElementById('selectedRoleProfile')) as HTMLSelectElement;
    if(profile){
      profile.value = undefined;
    }
    this.roleProfileSets =[];
    this.roleProfileGroupsFromImport=[];
    this.ksaRoleProfileService.getKsaRoleProfileSetById(importedKsaSelected.id).subscribe((data) => {
      this.roleProfileSets = data;
    })
  }
  onAddEditRoleProfileSet(form: NgForm): void {
    this.addEditValueMapping();
    !this.jobProfileSet.id ? (
        this.ksaRoleProfileService.createKsaRoleProfileSet(this.newJobProfileSetToPush).subscribe(data => {
            this.postAddEditOperation(form);
          },
          error => this.errorMsg = error.error.message)) :
      (this.ksaRoleProfileService.updateKsaRoleRoleProfileSet(this.newJobProfileSetToPush).subscribe(data => {
          this.postAddEditOperation(form);
        },
        error => this.errorMsg = error.error.message));
  }

  addEditValueMapping(): void {
    this.jobProfileSet.id && (this.newJobProfileSetToPush.id = this.jobProfileSet.id);
    this.newJobProfileSetToPush.name = this.jobProfileSet.name;
    this.newJobProfileSetToPush.description = this.jobProfileSet.description;
    this.newJobProfileSetToPush.masterKsa.id = this.ksaData.id;
    this.jobProfileSet.ksaRoleProfileGroups = this.roleProfileGroupsSelected;
    this.newJobProfileSetToPush.ksaRoleProfileGroups = this.roleProfileGroupsSelected.map((roleProfileGroup: any) =>
      ({
        id: roleProfileGroup.roleProfileGroupName && roleProfileGroup.id,
        description: roleProfileGroup.description,
        roleProfileGroupName: roleProfileGroup.name ? roleProfileGroup.name : roleProfileGroup.roleProfileGroupName,
        ksaRoleProfiles: roleProfileGroup.ksaRoleProfiles ? (roleProfileGroup.ksaRoleProfiles.map((roleProfile: any) => ({
          id: roleProfileGroup.roleProfileGroupName && roleProfile.id,
          description: roleProfile.description,
          ksaRoleProfileName: roleProfile.ksaRoleProfileName,
          ksaSkillWithRoleProfiles: roleProfile.ksaSkillWithRoleProfiles.map((skill: any) => ({
            id: this.jobProfileSet.id && skill.id,
            comment: skill.comment,
            threshold: skill.threshold,
            ksaSkill: {id: skill.ksaSkill.id},
          }))
        }))) : (roleProfileGroup.roleProfileGroupRoleProfileMaps.map((roleProfile: any) => ({
          description: roleProfile.roleProfile.description,
          ksaRoleProfileName: roleProfile.roleProfile.name,
          ksaSkillWithRoleProfiles: roleProfile.roleProfile.roleProfileSkillMaps.map((skill: any) => ({
            id: this.jobProfileSet.id && skill.id,
            comment: skill.comment,
            threshold: skill.threshold,
            ksaSkill: {id: skill.skill.id},
          }))
        })))
      }))
  }

  onSetAddEditRoleProfileSetFormOpen(): void {
    this.getRoleProfileGroups();
    this.addEditRoleProfileSetFormOpen = true;
  }

  addSelectedRoleProfileGroupsFromMaster(): void {
    this.roleProfileGroupsSelectedFromMaster.forEach(groupToPush => {
      (!this.roleProfileGroupsSelected.find(groupNameToFind => (groupNameToFind.name ?
        (groupNameToFind.name == groupToPush.name) : (groupNameToFind.roleProfileGroupName == groupToPush.name)))) &&
      this.roleProfileGroupsSelected.push(groupToPush)
    })
    this.roleProfileGroupsSelectedFromMaster = [];
  }

  addSelectedRoleProfileGroupsFromLibrary(): void {
    this.roleProfileGroupsSelectedFromLibrary.forEach(groupToPush => {
      const roleProfileGroupsSelected = this.roleProfileGroupsSelected.find(groupNameToFind => (groupNameToFind.name ?
        (groupNameToFind.name === groupToPush.roleProfileGroupName) :
        (groupNameToFind.roleProfileGroupName === groupToPush.roleProfileGroupName)));
      if (!roleProfileGroupsSelected) {
        delete groupToPush.id;
        groupToPush.ksaRoleProfiles.forEach(roleProfile => {
          delete roleProfile.id;
        });
        this.roleProfileGroupsSelected.push(groupToPush);
      }
    });
    this.roleProfileGroupsSelectedFromLibrary = [];
  }

  onEditKsaDetails(ksa: any): void {
    this.getRoleProfileGroups();
    this.jobProfileSet.id = ksa.id;
    this.jobProfileSet.name = ksa.name;
    this.jobProfileSet.description = ksa.description;
    this.jobProfileSet.masterKsa.id = this.ksaData.id;
    this.roleProfileGroupsSelected = [];
    this.roleProfileGroupsSelected = ksa.ksaRoleProfileGroups.map((roleProfileGroup: any) => {
      return roleProfileGroup;
    })
    this.jobProfileSet.ksaRoleProfileGroups = ksa.ksaRoleProfileGroups.map((roleProfileGroup: KsaRoleProfileGroupsEntity) =>
      ({
        id: roleProfileGroup.id,
        description: roleProfileGroup.description,
        roleProfileGroupName: roleProfileGroup.roleProfileGroupName,
        ksaRoleProfiles: roleProfileGroup.ksaRoleProfiles.map((roleProfile: KsaRoleProfilesEntity) => ({
          id: roleProfile.id,
          description: roleProfile.ksaRoleProfileName,
          ksaRoleProfileName: roleProfile.description,
          ksaSkillWithRoleProfiles: roleProfile.ksaSkillWithRoleProfiles.map((skill: KsaSkillWithRoleProfilesEntity) => ({
            id: skill.id,
            comment: skill.comment,
            threshold: skill.threshold,
            ksaSkill: {id: skill.ksaSkill.id},
          }))
        }))
      }))
    this.onSetAddEditRoleProfileSetFormOpen();
  }

  onDeleteRoleProfileSet(): void {
    this.ksaRoleProfileService.deleteRoleProfileSet(this.deleteSetId).subscribe(data => {
        this.refresh(this.state);
        this.showDeleteWarning = false;
        this.deleteSetId = 0;
        this.errorMsg = '';
      },
      error => this.errorMsg = error.error.message);
  }

  onShowDeleteWarning(id: any): void {
    this.deleteSetId = id;
    this.showDeleteWarning = true;
  }

  onDeleteMultipleSets(selectedSets) {
    const ids: any[] = [];
    selectedSets.forEach(function (value) {
      ids.push(value.id);
    });
    this.ksaRoleProfileService.deleteMultipleRoleProfileSets(ids).subscribe(data => {
        this.refresh(this.state);
        this.selectedSets = [];
      },
      error => {
        console.log(error);
      })
  }

  postAddEditOperation(form: NgForm): void {
    this.clearForm();
    this.refresh(this.state);
  }

  clearForm(): void {
    this.roleProfileGroupsSelected = [];
    this.addEditRoleProfileSetFormOpen = false;
    this.clearRoleProfileSetForm();
  }

  clearRoleProfileSetForm(): void {
    this.jobProfileSet = this.initializeJobProfileSetValues();
    this.newJobProfileSetToPush = this.initializeJobProfileSetValues();
    this.errorMsg = '';
  }

  initializeJobProfileSetValues(): any {
    return {
      name: this.roleProfileEnum.setName,
      description: '',
      masterKsa: {
        id: '',
      },
      ksaRoleProfileGroups: [
        {
          description: '',
          ksaRoleProfiles: [
            {
              description: '',
              ksaRoleProfileName: '',
              ksaSkillWithRoleProfiles: [
                {
                  comment: '',
                  ksaSkill: {
                    id: '',
                  },
                  threshold: '',
                }
              ]
            }
          ],
          roleProfileGroupName: '',
        }
      ]
    }
  }

  deleteSelectedRpg(roleProfileGroup: KsaRoleProfileGroupsEntity): void {
    this.roleProfileGroupsSelected = this.roleProfileGroupsSelected.filter(item => {
      return item.id != roleProfileGroup.id;
    })
  }

  checkImportFromLibrary(): void {
    this.importFromLibrary && this.getImportedKsaRoleProfileSet();
  }

  populateImportedRoleProfileGroupsDatagrid(): void {
    this.roleProfileGroupsFromImport = this.importedRoleProfileSetSelected.ksaRoleProfileGroups.map((roleProfileGroup) => {
      return roleProfileGroup;
    })
  }

  refresh(state: ClrDatagridStateInterface): void {
    this.state = state;
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const {property, value} = <{ property: string; value: string }>filter;
        filters[property] = value;
      }
    }
    this.ksaRoleProfileService.getKsaJobProfileSet(
      this.ksaData?.id,
      filters?.['name'] ? filters?.['name'] : '',
      state.page.current - 1, state.page.size, state?.sort?.by === undefined ? 'id' : state?.sort?.by, state?.sort?.reverse === undefined ? 'ASC' : state?.sort?.reverse)
      .pipe(finalize(() => hideSpinner()))
      .subscribe((results: any) => {
        this.jobProfileSets = results;
        this.total = results.totalElements;
      })
  }
}
