import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {RoleProfileStandardProperties} from '../../../../../enums/ksa-role-profile.enum';
import {ActiveSkill, KsaRoleSkill, SkillList, SkillListArray} from '../../../../../models/ksa-role-profile-skill.model';
import {KsaRoleProfileService} from '../../../../../services/ksa-role-profile.service';
import {KsaShareService} from '../../../../../services/ksa-share.service';
import {SkillsService} from "../../../../../services/skills.service";
import {KsaAdministrationService} from "../../../../../services/ksa-administration.service";
import {UserService} from '../../../../../services/user.service';
import {Functionality} from '../../../../../enums/functionality.enum';

@Component({
  selector: 'app-job-role-profile',
  templateUrl: './job-role-profile.component.html',
  styleUrls: ['./job-role-profile.component.scss']
})
export class JobRoleProfileComponent implements OnInit {

  errorMsg: string = '';
  addRoleProfileFormOpen: boolean = false;
  ksaData: any;
  roleProfileSets: any = {};
  importedRoleProfileSetSelected: any;
  importedRoleProfileGroupSelected: any;
  rolesReceivedByKsaIdSetId: any;
  deleteRoleProfileId: number;
  showDeleteWarning: boolean = false;
  selectedRoles: any[] = [];
  roleProfileEnum: typeof RoleProfileStandardProperties = RoleProfileStandardProperties;
  editRoleProfile: any = {
    id: '',
    ksaRoleProfileName: '',
    description: '',
    profileSetId: '',
    profileSetName: '',
    roleProfileGroupID: '',
    roleProfileGroupName: '',
    roleProfileGroupDescription: '',
    profileSetDescription: '',
    skills: [{
      skillName: '',
      skillId: '',
      ksaSkillId: '',
      threshold: '',
      KSASkillWithRoleProfileComment: '',
    }]
  }
  newRoleProfile: any = this.initializeRoleProfile();
  editRoleProfileToPush: any = this.initializeRoleProfile();
  skills: ActiveSkill[];
  result: any[] = [
    {
      ksaRoleProfileName: '',
      description: '',
      skills: [],
    }
  ];
  skillsList: SkillListArray = [];
  selectedSkills: KsaRoleSkill[] = [];
  hasWritePermission: boolean;

  constructor(private readonly skillService: SkillsService,
              private readonly ksaRoleProfileService: KsaRoleProfileService,
              private readonly ksaShareService: KsaShareService,
              private readonly ksaAdministrationService: KsaAdministrationService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.ksaAdministration);
    this.getKsaData();
    this.getKsaSets();
  }


  getKsaData(): void {
    this.ksaShareService.getKSADetails().subscribe(data => {
      this.ksaData = data;
      this.getSkills();
    })
  }

  getKsaSets(): void {
    this.ksaRoleProfileService.getKsaRoleProfileSetById(this.ksaData?.id).subscribe(data => {
      this.roleProfileSets = data;
    })
  }

  onEditRoleProfile(roleProfile): void {
    this.editRoleProfile = roleProfile;
  }

  onSetAddKsaFormOpen(): void {
    this.addRoleProfileFormOpen = true;
  }

  getSkills(): void {
    if (this.ksaData?.id) {
      this.ksaAdministrationService.getSkillsByKsaId(this.ksaData.id).subscribe(data => {
        this.skills = data;
      })
    }
  }

  getRoleProfilesByKsaAndSetId(ksaId, setId): void {
    this.ksaRoleProfileService.getRoleProfilesByKsaAndSetId(ksaId, setId).subscribe(data => {
      this.rolesReceivedByKsaIdSetId = Object.values(data.reduce((groupedSkills, role) => {
        const id = role.id;

        if (groupedSkills[id] == null) {
          groupedSkills[id] = [];
        }

        groupedSkills[id].push(role);
        return groupedSkills;
      }, {}))
      this.mapSkills();
      this.mapRoleProfiles();
    })
  }

  mapSkills(): void {
    this.skillsList = [];
    this.rolesReceivedByKsaIdSetId.forEach((roleProfile) => {
      const skills: SkillList[] = [];
      roleProfile.forEach((data) => {
        skills.push({
          skillName: data?.skillName,
          skillId: data?.skillId,
          ksaSkillId: data?.ksaSkillId,
          threshold: data?.threshold,
          KSASkillWithRoleProfileComment: data?.KSASkillWithRoleProfileComment
        })
      })
      this.skillsList.push(skills);
    })
  }

  mapRoleProfiles() {
    this.result = this.rolesReceivedByKsaIdSetId.map((data: any, index: any) => ({
      id: data[0]?.id,
      ksaRoleProfileName: data[0]?.ksaRoleProfileName,
      description: data[0]?.description,
      profileSetId: data[0]?.profileSetId,
      profileSetName: data[0]?.profileSetName,
      roleProfileGroupID: data[0]?.roleProfileGroupID,
      roleProfileGroupName: data[0]?.roleProfileGroupName,
      roleProfileGroupDescription: data[0]?.roleProfileGroupDescription,
      profileSetDescription: data[0]?.profileSetDescription,
      skills: this.skillsList[index]
    }))
  }

  onAddRoleProfile(addForm: NgForm): void {
    this.newRoleProfile.roleProfileGroupName = this.importedRoleProfileGroupSelected?.roleProfileGroupName;
    this.newRoleProfile.description = this.importedRoleProfileGroupSelected?.description;
    this.newRoleProfile.id = this.importedRoleProfileGroupSelected?.id;
    this.newRoleProfile.ksaJobProfileSet.id = this.importedRoleProfileSetSelected?.id;
    this.newRoleProfile.ksaJobProfileSet.name = this.importedRoleProfileSetSelected?.name;
    this.newRoleProfile.ksaJobProfileSet.description = this.importedRoleProfileSetSelected?.description;
    this.newRoleProfile.ksaRoleProfiles[0].ksaSkillWithRoleProfiles = this.selectedSkills?.map((skill: any) => ({
      ksaSkill: {id: skill?.id},
      threshold: skill?.threshold,
      comment: skill?.comment
    }))
    this.ksaRoleProfileService.createKsaRoleProfile(this.newRoleProfile).subscribe(data => {
        addForm?.onReset();
        this.addRoleProfileFormOpen = false;
        this.selectedSkills = [];
        this.getRoleProfilesByKsaAndSetId(this.ksaData.id, this.importedRoleProfileSetSelected.id);
        this.clearRoleProfileDetails();
      },
      error => this.errorMsg = error.error.message);
  }

  onEditRoleProfileRow(): void {
    this.editRoleProfileToPush.roleProfileGroupName = this.editRoleProfile.roleProfileGroupName;
    this.editRoleProfileToPush.description = this.editRoleProfile.roleProfileGroupDescription;
    this.editRoleProfileToPush.id = this.editRoleProfile.roleProfileGroupID;
    this.editRoleProfileToPush.ksaJobProfileSet.id = this.editRoleProfile.profileSetId;
    this.editRoleProfileToPush.ksaJobProfileSet.name = this.editRoleProfile.profileSetName;
    this.editRoleProfileToPush.ksaJobProfileSet.description = this.editRoleProfile.profileSetDescription;
    this.editRoleProfileToPush.ksaRoleProfiles[0].id = this.editRoleProfile.id;
    this.editRoleProfileToPush.ksaRoleProfiles[0].ksaRoleProfileName = this.editRoleProfile.ksaRoleProfileName;
    this.editRoleProfileToPush.ksaRoleProfiles[0].description = this.editRoleProfile.description;
    this.editRoleProfileToPush.ksaRoleProfiles[0].ksaSkillWithRoleProfiles = this.editRoleProfile.skills.map(skill => ({
      id: skill.ksaSkillId,
      ksaSkill: {id: skill?.skillId},
      threshold: skill.threshold,
      comment: skill.ksaskillWithRoleProfileComment,
    }));
    this.ksaRoleProfileService.updateKsaRoleProfile(this.editRoleProfileToPush).subscribe(data => {
        this.getRoleProfilesByKsaAndSetId(this.ksaData.id, this.importedRoleProfileSetSelected.id);
        this.editRoleProfile = [];
      },
      error => this.errorMsg = error.error.message)
  }

  onDeleteRoleProfile(): void {
    this.ksaRoleProfileService.deleteRoleProfile(this.deleteRoleProfileId).subscribe(data => {
      this.displayRoleAndSkillsBySetId();
      this.showDeleteWarning = false;
      this.deleteRoleProfileId = 0;
      this.errorMsg = '';
    },
      error => this.errorMsg = error.error.message);
  }

  onDeleteMultipleRoleProfile(selected): void {
    const ids: any[] = [];
    selected.forEach(function (value) {
      ids.push(value.id);
    });
    this.ksaRoleProfileService.deleteMultipleRoleProfiles(ids).subscribe(data => {
        this.displayRoleAndSkillsBySetId();
      },
      error => {
        console.log(error);
      })
  }

  onShowDeleteWarning(id: any): void {
    this.deleteRoleProfileId = id;
    this.showDeleteWarning = true;
  }

  onCancelAdding(): void {
    this.addRoleProfileFormOpen = false;
    this.clearRoleProfileDetails();
  }

  onCancelEditing(): void {
    this.editRoleProfile = [];
  }

  clearRoleProfileDetails(): void {
    this.newRoleProfile = this.initializeRoleProfile();
    this.editRoleProfileToPush = this.initializeRoleProfile();
    this.errorMsg = '';
  }

  initializeRoleProfile(): any {
    return {
      roleProfileGroupName: '',
      description: '',
      id: '',
      ksaRoleProfiles: [
        {
          ksaRoleProfileName: this.roleProfileEnum.roleName,
          description: '',
          ksaSkillWithRoleProfiles: [
            {
              ksaSkill: {
                id: ''
              },
              threshold: '',
              comment: ''
            }
          ]
        }
      ],
      ksaJobProfileSet: {
        id: '',
        name: '',
        description: ''
      }
    }
  }

  displayRoleAndSkillsBySetId(): void {
    this.skillsList = [];
    this.result = [];
    this.getRoleProfilesByKsaAndSetId(this.ksaData.id, this.importedRoleProfileSetSelected.id);
  }
}
