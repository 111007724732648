<div *ngIf="ksaSectionData.isQuestionSectionsSelected">
  <div class="QuestionBanners" draggable="false">
    <div *ngIf="ksaSectionData?.section?.sectionSkipCondition?.id" class="displayCondition">
      <div class="DynamicChoicesBox LogicBox">
        <table aria-label="display logic table">
          <tbody>
          <tr>
            <th id="DisplayTab" class="InfoBarDisplayTab"></th>
            <td class="DisplayDesc">
              <div class="DisplayLogicHeading">
                <h6>{{'surveyTemplate.questionSheet.isDisplay'|translate}}</h6>
              </div>
              <div
                *ngIf="!showDisplayAnswerLabel && !ksaSectionData?.section?.sectionSkipCondition?.colAnswerLabel"
                class="displayLogicEditor">
                {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                {{ksaSectionData?.section?.sectionSkipCondition?.answerLabel?.labelText}}
                {{ksaSectionData?.section?.sectionSkipCondition?.skipCondition?.skipConditionName}}
                {{ksaSectionData?.section?.sectionSkipCondition?.conditionSectionQuestion?.question.questionText}}
              </div>
              <div *ngIf="showDisplayAnswerLabel" class="displayLogicEditor">
                {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                {{ksaSectionData?.section?.sectionSkipCondition?.answerLabel?.labelText}}
                {{'surveyTemplate.questionSheet.optionIn'|translate}}
                {{ksaSectionData?.section?.sectionSkipCondition?.conditionSectionQuestion?.question.questionText}}
                {{'surveyTemplate.questionSheet.questionInSection'|translate}}
                {{ksaSectionData?.section?.sectionSkipCondition?.conditionMasterSurveySection?.sectionQuestionSequenceNo}}
              </div>
              <div *ngIf="ksaSectionData?.section?.sectionSkipCondition?.colAnswerLabel"
                   class="displayLogicEditor">
                {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                {{ksaSectionData?.section?.sectionSkipCondition?.colAnswerLabel?.labelText}},
                {{ksaSectionData?.section?.sectionSkipCondition?.answerLabel?.labelText}}
                {{ksaSectionData?.section?.sectionSkipCondition?.skipCondition?.skipConditionName}}
                {{ksaSectionData?.section?.sectionSkipCondition?.conditionSectionQuestion?.question.questionText}}
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="cardImage">
    <div *ngIf="ksaSectionData?.section?.image?.imageData" class="clr-col-12 image-bar">
      <div class="card">
        <div class="clr-row">
          <div class="clr-col-12">
            <img [src]="'data:image/jpeg;base64,'+ksaSectionData?.section?.image?.imageData" alt="section image">
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let question of questionsKsa">
    <div *ngIf="ksaDetails.isAdvance || question?.question?.questionType?.id!=questionTypeEnum.RoleProfile">
      <div [ngSwitch]="question?.question?.questionType?.id">
        <div
          *ngIf="question?.question.questionType?.id!=questionTypeEnum.Description && question?.question.questionType?.id!=questionTypeEnum.RoleProfile"
          class="dropdown-custom">
          <clr-dropdown [clrCloseMenuOnItemClick]="true">
            <button clrDropdownTrigger aria-label="Dropdown demo button">
              <clr-icon shape="ellipsis-vertical"></clr-icon>
            </button>
            <clr-dropdown-menu clrPosition="left-top" *clrIfOpen>
              <button (click)="openMandatoryQuestionPopup(question)"
                      [disabled]="ksaDetails.status !== statusEnum.Draft"
                      clrDropdownItem>{{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.mandatoryModal'|translate}}
              </button>
            </clr-dropdown-menu>
          </clr-dropdown>
        </div>

        <clr-accordion>
          <clr-accordion-panel [clrAccordionPanelOpen]="true">
            <clr-accordion-content *clrIfExpanded>
              <div class="QuestionBanners" draggable="false">
                <div *ngIf="question.questionSkipCondition?.id" class="skipCondition">
                  <div class="DynamicChoicesBox LogicBox">
                    <table aria-label="question banners table">
                      <tbody>
                      <tr>
                        <th id="SkipTab" class="InfoBarSkipTab"></th>
                        <td class="SkipDesc">
                          <div class="SkipLogicHeading">
                            <h6>{{'surveyTemplate.questionSheet.skipTo'|translate}}
                            </h6>
                          </div>
                          <div class="SkipLogicEditor">
                            <div *ngSwitchCase="questionTypeEnum.FreeText">
                              {{'surveyTemplate.questionSheet.isEndOf'|translate}}
                              {{question?.questionSkipCondition?.skipType}}
                              {{'surveyTemplate.questionSheet.ifIt'|translate}}{{question?.questionSkipCondition?.skipCondition?.skipConditionName}}
                            </div>
                            <div *ngSwitchCase="questionTypeEnum.RadioButton">
                              {{'surveyTemplate.questionSheet.isEndOf'|translate}} {{question?.questionSkipCondition?.skipType}}
                              if
                              {{question?.questionSkipCondition?.answerLabel?.labelText}} option
                              {{question?.questionSkipCondition?.skipCondition?.skipConditionName}}
                            </div>
                            <div *ngSwitchCase="questionTypeEnum.MultipleChoice">
                              {{'surveyTemplate.questionSheet.isEndOf'|translate}} {{question?.questionSkipCondition?.skipType}}
                              if
                              {{question?.questionSkipCondition?.answerLabel?.labelText}} option
                              {{question?.questionSkipCondition?.skipCondition?.skipConditionName}}
                            </div>
                            <div *ngSwitchCase="questionTypeEnum.Scale">
                              {{'surveyTemplate.questionSheet.isEndOf'|translate}} {{question?.questionSkipCondition?.skipType}}
                              if display
                              value {{question?.questionSkipCondition?.skipCondition?.skipConditionName}} {{question?.questionSkipCondition?.displayValue}}
                            </div>
                            <div *ngSwitchCase="questionTypeEnum.MatrixTable">
                              {{'surveyTemplate.questionSheet.isEndOf'|translate}} {{question?.questionSkipCondition?.skipType}}
                              if
                              {{question?.questionSkipCondition?.colAnswerLabel?.labelText}} column and
                              {{question?.questionSkipCondition?.answerLabel?.labelText}} row
                              {{question?.questionSkipCondition?.skipCondition?.skipConditionName}}
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div *ngIf="question.questionDisplayCondition?.id" class="displayCondition">
                  <div class="DynamicChoicesBox LogicBox">
                    <table aria-label="display condition table">
                      <tbody>
                      <tr>
                        <th id="DisplayTabTwo" class="InfoBarDisplayTab"></th>
                        <td class="DisplayDesc">
                          <div class="DisplayLogicHeading">
                            <h6>{{'surveyTemplate.questionSheet.isDisplay'|translate}}
                            </h6>
                          </div>
                          <div
                            *ngIf="!showDisplayAnswerLabel && !question?.questionDisplayCondition?.colAnswerLabel"
                            class="displayLogicEditor">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{question?.questionDisplayCondition?.answerLabel?.labelText}}
                            <span
                              *ngIf="question?.questionDisplayCondition?.answerLabel">{{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}</span>
                            {{question?.questionDisplayCondition?.conditionSectionQuestion?.question.questionText}}
                            {{question?.questionDisplayCondition?.skipCondition?.skipConditionName}} {{question?.questionDisplayCondition?.displayValue}}
                          </div>
                          <div class="displayLogicEditor" *ngIf="showDisplayAnswerLabel">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{question?.questionDisplayCondition?.answerLabel?.labelText}}{{'surveyTemplate.questionSheet.optionIn'|translate}}
                            {{question?.questionDisplayCondition?.conditionSectionQuestion?.question?.questionText}}{{'surveyTemplate.questionSheet.questionInSection'|translate}}{{question?.questionDisplayCondition?.conditionMasterSurveySection?.sectionQuestionSequenceNo}}
                            {{question?.questionDisplayCondition?.skipCondition?.skipConditionName}}
                          </div>
                          <div class="displayLogicEditor"
                               *ngIf="question?.questionDisplayCondition?.colAnswerLabel">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{question?.questionDisplayCondition?.colAnswerLabel?.labelText}},
                            {{question?.questionDisplayCondition?.answerLabel?.labelText}}
                            {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                            {{question?.questionDisplayCondition?.conditionSectionQuestion?.question.questionText}}
                            {{question?.questionDisplayCondition?.skipCondition?.skipConditionName}}
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="all-question">
                <div class="clr-row">
                  <div *ngIf="question?.question?.image" class="clr-col-12 image-bar">
                          <span class="img-container">
                            <img [src]="'data:image/jpeg;base64,'+question?.question?.image?.imageData"
                                 alt="question image">
                          </span>
                  </div>
                </div>
                <div
                  *ngIf="![questionTypeEnum.Description, questionTypeEnum.RoleProfile].includes(question?.question.questionType?.id)">
                  <h5 class="question-Block">
                    <span
                      *ngIf="question.isMandatory">*</span> {{ question | displayLocalizedQuestionText : ksaDetails?.language }}
                  </h5>
                </div>
                <div *ngSwitchCase="questionTypeEnum.FreeText">
                  <div *ngIf="!question?.question?.answerProperty.isSingleLine">
                    <div class="right">
                      <div class="content">
                        <clr-textarea-container>
                                <textarea clrTextarea cols="135" disabled maxLength="1000"
                                          placeholder="{{'placeholder.enterYourAnswer' | translate}}"
                                          rows="4"></textarea>
                        </clr-textarea-container>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="question?.question?.answerProperty.isSingleLine">
                    <div class="right">
                      <div class="content">
                        <clr-input-container>
                          <input class="text-Block" clrInput disabled
                                 placeholder="{{'placeholder.enterYourAnswer' | translate}}"/>
                        </clr-input-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="questionTypeEnum.RadioButton">
                  <div *ngIf="question?.question?.answerProperty.isVertical">
                    <ng-container>
                      <form clrForm>
                        <clr-radio-wrapper *ngFor="let answer of question?.question?.answerLabels"
                                           class="radio-toolbar">
                          <label>{{answer | displayLocalizedAnswerLabel : ksaDetails?.language}}</label>
                        </clr-radio-wrapper>
                      </form>
                    </ng-container>
                  </div>
                  <div *ngIf="!question?.question?.answerProperty.isVertical">
                    <form clrForm>
                      <table class="clr-col-12" aria-label="display read-only answer label text table">
                        <tr>
                          <th scope="row" class="th-display"></th>
                          <td *ngFor="let answer of question?.question?.answerLabels" class="radio-toolbar">
                            <clr-radio-wrapper>
                              <label>{{answer | displayLocalizedAnswerLabel : ksaDetails?.language}}</label>
                            </clr-radio-wrapper>
                          </td>
                        </tr>
                      </table>
                    </form>
                  </div>
                </div>
                <div *ngSwitchCase="questionTypeEnum.MultipleChoice">
                  <ng-container>
                    <form clrForm>
                      <clr-checkbox-wrapper *ngFor="let answer of question?.question?.answerLabels"
                                            class="checkbox-toolbar">
                        <label>{{answer | displayLocalizedAnswerLabel : ksaDetails?.language}}</label>
                      </clr-checkbox-wrapper>
                    </form>
                  </ng-container>
                </div>
                <div *ngSwitchCase="questionTypeEnum.Scale">
                  <form clrForm>
                    <table class="clr-col-12" aria-label="read only answer label text table">
                      <tr class="clr-row label-scale">
                        <th scope="row" class="th-display"></th>
                        <td *ngFor="let answer of question.question.answerLabels" class="clr-col">
                          <strong>{{answer | displayLocalizedAnswerLabel : ksaDetails?.language}}</strong>
                        </td>
                      </tr>
                    </table>
                    <table class="clr-col-12" aria-label="read only question options scale text table">
                      <tr>
                        <th scope="row" class="th-display"></th>
                        <td *ngFor="let item of question.question.optionsScale" class="radio-toolbar">
                          <clr-radio-wrapper>
                            <input clrRadio name="answer.labelText" type="radio" value="item.optionLabel"/>
                            <label>{{item.optionLabel}}</label>
                          </clr-radio-wrapper>
                        </td>
                      </tr>
                    </table>
                  </form>
                </div>
                <div *ngSwitchCase="questionTypeEnum.Description">
                  <div *ngIf="ksaSectionData.isCoreSection">
                    <div class="clr-col-lg-11 clr-col-11 editor-view">
                      <div [innerHTML]="question | displayLocalizedQuestionText : ksaDetails?.language | safe: 'html'">
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!ksaSectionData.isCoreSection">
                    <div *ngIf="ksaDetails.status == statusEnum.Draft">
                      <input [ngxSummernote]="config" [(ngModel)]="question.editText"
                             id="summernote" name="name" on-blur="setKsaDetails(question)" type="text">
                    </div>
                    <div *ngIf="ksaDetails.status != statusEnum.Draft">
                      <div class="clr-col-lg-11 clr-col-11 editor-view">
                        <div [innerHTML]="question.editText | safe: 'html'"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="questionTypeEnum.MatrixTable">
                  <table *ngIf="question?.question?.answerProperty.isRadio" class="clr-col-12"
                         aria-label="read only answer label text table">
                    <tr>
                      <td class="clr-col-1"></td>
                      <th scope="col" *ngFor=" let collabel of question?.question?.matrixCols"
                          class="matrix-cell-cols">
                        {{collabel | displayLocalizedAnswerLabel: ksaDetails.language}}</th>
                    </tr>
                    <tr *ngFor="let rowMatrix of question?.question?.matrixRows ;let rowIndex = index">
                      <th scope="row">{{rowMatrix | displayLocalizedAnswerLabel: ksaDetails.language}}</th>
                      <td *ngFor="let rowMatrixValue of question?.question?.matrixCols" class="matrix-cell-row">
                        <clr-radio-wrapper>
                          <input clrRadio disabled name="options{{rowIndex}}"
                                 type="radio" value="rowMatrixValue.labelText">
                        </clr-radio-wrapper>
                      </td>
                    </tr>
                  </table>
                  <table *ngIf="!question?.question?.answerProperty.isRadio" class="clr-col-12"
                         aria-label="read only answer label text table">
                    <tr>
                      <td class="clr-col-1"></td>
                      <th scope="col" *ngFor=" let collabel of question?.question?.matrixCols"
                          class="matrix-cell-cols">
                        {{collabel | displayLocalizedAnswerLabel: ksaDetails.language}}</th>
                    </tr>
                    <tr *ngFor="let rowMatrix of question?.question?.matrixRows ;let rowIndex = index">
                      <th scope="row">{{rowMatrix | displayLocalizedAnswerLabel: ksaDetails.language}}</th>
                      <td *ngFor="let rowMatrixValue of question?.question?.matrixCols" class="matrix-cell-row">
                        <clr-checkbox-wrapper>
                          <input clrCheckbox disabled type="checkbox">
                        </clr-checkbox-wrapper>
                      </td>
                    </tr>
                  </table>
                </div>
                <div *ngSwitchCase="questionTypeEnum.RoleProfile" class="all-question ">
                  <div *ngIf="ksaDetails.isAdvance">
                    <div class="clr-row">
                      <h5 class="question-Block clr-col-3">
                        <span
                          *ngIf="question.isMandatory">*</span> {{question | displayLocalizedQuestionText : ksaLanguage}}
                      </h5>
                      <clr-select-container class="clr-col-5">
                        <select clrSelect class="clr-col-10">
                          <option disabled selected value="">
                            {{question | displayLocalizedQuestionText : ksaLanguage}}
                          </option>
                          <option *ngFor="let item of roleProfiles" [value]="item.id">
                            {{item.ksaRoleProfileName}}
                          </option>
                        </select>
                      </clr-select-container>
                    </div>
                    <div *ngIf="question?.questionNote">
                      <h5>{{'surveyTemplate.questionSheet.isNote'|translate}} -
                        {{question?.questionNote}}</h5>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="question?.questionNote && question?.question.questionType?.id!=questionTypeEnum.RoleProfile">
                  <h5>{{'surveyTemplate.questionSheet.isNote'|translate}} -
                    {{question?.questionNote}}</h5>
                </div>
              </div>
            </clr-accordion-content>
          </clr-accordion-panel>
        </clr-accordion>

        <div *ngIf="ksaDetails.status!=statusEnum.Active">
          <div *ngIf="question.isPageBreakAddedForKSA" class="PageBreak Draggable" draggable="true">
            <clr-icon (click)="onPageBreak(question,false)" aria-label="warning"
                      class="btn-sm btn-warning-outline minusIconPagebrak" shape="minus-circle">
            </clr-icon>
            <div class="PBInner">
              <div class="perforation">
                <span class="text"> {{'surveyTemplate.questionSheet.isPageBreak'|translate}}</span>
              </div>
            </div>
          </div>
          <div *ngIf="!question.isLastQuestion">
            <div *ngIf="!question.isPageBreakAddedForKSA" class="PageBreak Draggable" draggable="true">
              <clr-icon (click)="onPageBreak(question,true)" aria-label="warning"
                        class="btn-sm btn-warning-outline plusIconPagebrak" shape="plus-circle">
              </clr-icon>
              <div class="PBInner">
                <div class="perforation">
                  <span class="text"> {{'surveyTemplate.questionSheet.isPageBreak'|translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-mandatory-pop-up *ngIf="showMandatoryTypePopUp" [questionData]="selectedQuestionData"
                      [showMandatoryTypePopUp]="showMandatoryTypePopUp"
                      (showMandatory)="onShowMandatory()">
</app-mandatory-pop-up>
