import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmailParticipantService} from '../../../../services/email-participant.service';
import {KsaEmailManagementService} from "../../../../services/ksa-email-management.service";
import {DialogOperationMode} from "../../../../enums/dialog-operation-mode.enum";
import {EmailParticipant} from "../../../../models/participant.model";
import {hideSpinner, showSpinner} from "../../../../common/spinner";
import {finalize} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";
import {KsaEmailDTO} from "../../../../models/ksa-email-dto.model";
import {ClrLoadingState} from "@clr/angular";

@Component({
  selector: 'app-send-email-invite',
  templateUrl: './send-email-invite.component.html',
  styleUrls: ['./send-email-invite.component.scss']
})
export class SendEmailInviteeComponent implements OnInit {

  readonly DIALOG_MODES = DialogOperationMode;

  @Input() ksaId: number;
  @Input() isThanks: boolean;
  @Input() isInvite: boolean;

  ksaEmail: KsaEmailDTO;
  showInvalidParticipantAlert: boolean;
  invalidParticipant: any;
  ccEmail: string = '';
  toEmailParticipants: EmailParticipant[] = [];
  ccEmailParticipants: string[] = [];
  participantForDeleting: EmailParticipant;
  errorMessage: string = '';
  emailSubject: string;
  emailBody: string;
  isEmailSendToAll: boolean = false;
  isEmailSendToUpdated: boolean = false;
  shouldShowDeleteConfirmDialog: boolean;
  shouldDeleteAllParticipants: boolean;
  isParticipantDialogOpened: boolean;
  participantDialogMode: DialogOperationMode;

  sendBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  @Output() isSendParticipant: EventEmitter<boolean> = new EventEmitter();

  constructor(private readonly serviceEmailParticipant: EmailParticipantService,
              public readonly ksaEmailService: KsaEmailManagementService,
              private readonly translateService: TranslateService,
              private readonly translate: TranslateService) {
  }

  ngOnInit(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaEmailService.getKsaEmailDtoByKsaId(this.ksaId)
      .pipe(finalize(hideSpinner))
      .subscribe((response: KsaEmailDTO) => {
          this.ksaEmail = response;
          this.setSendBtnState();
          this.setEmailSubjectAndBody();
          this.getEmailToList();
        },
        error => this.setErrorMessage(error))

  }


  getEmailToList(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.serviceEmailParticipant.getAllParticipants(this.ksaEmail.masterKsaId)
      .pipe(finalize(hideSpinner))
      .subscribe(participants => {
        participants.forEach(participant => {
          if (participant.isTo) {
            this.toEmailParticipants.push(participant);
          }
        });
      }, error => this.setErrorMessage(error));
  }

  sendAll(): void {
    if (this.isInvite) {
      showSpinner(this.translateService.instant('ksaEngagement.emailManagement.sendInvite.progressSend'));
      this.ksaEmailService.sendInviteAll(this.ksaEmail.id, this.ccEmailParticipants)
        .pipe(finalize(hideSpinner))
        .subscribe(this.handleSuccessfulEmailSend,
          error => this.setErrorMessage(error));
    } else if (this.isThanks) {
      showSpinner(this.translateService.instant('ksaEngagement.emailManagement.sendInvite.thanksSend'));
      this.ksaEmailService.sendThanksAll(this.ksaEmail.id, this.ccEmailParticipants)
        .pipe(finalize(hideSpinner))
        .subscribe(this.handleSuccessfulEmailSend,
          error => {
            this.setErrorMessage(error);
          });
    }
  }

  private readonly handleSuccessfulEmailSend = (response: KsaEmailDTO): void => {
    this.ksaEmail = response;
    this.setSendBtnState();
    this.setMailData();
    this.ccEmailParticipants = [];
    this.toEmailParticipants = [];
    this.getEmailToList();
  }

  setMailData(): void {
    if (this.isSendToAllLabel()) {
      this.setMailDataToAll();
    } else {
      this.setMailDataUpdated();
    }
  }

  setMailDataToAll(): void {
    this.isEmailSendToAll = true;
    setTimeout(() => {
      this.isEmailSendToAll = false;
    }, 10000);
  }

  private setErrorMessage(error): void {
    this.errorMessage = error.error.message
    setTimeout(() => {
      this.errorMessage = '';
    }, 10000);
  }

  setMailDataUpdated(): void {
    this.isEmailSendToUpdated = true;
    setTimeout(() => {
      this.isEmailSendToUpdated = false;
    }, 10000);
  }

  setInvalidParticipants(invalidParticipants: string[]): void {
    if (invalidParticipants.length > 0) {
      this.showInvalidParticipantAlert = true;
      this.invalidParticipant = invalidParticipants.toString();
      setTimeout(() => {
        this.showInvalidParticipantAlert = false;
      }, 10000);
    } else {
      this.showInvalidParticipantAlert = false;
    }
  }

  onSurveyPresent(ccEmail): void {
    const NewCCEmail = ccEmail.split(';').join(',');
    this.ccEmailParticipants = NewCCEmail.split(',');

  }

  uploadListener(event): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      showSpinner(this.translateService.instant('ksaEngagement.emailManagement.sendInvite.progressImport'));
      this.serviceEmailParticipant.importParticipant(fileList[0], this.ksaEmail.masterKsaId)
        .pipe(finalize(hideSpinner))
        .subscribe(participantImport => {
          this.setInvalidParticipants(participantImport.invalidParticipantEmails);
          participantImport.importedParticipants
            .forEach(importedParticipant => this.toEmailParticipants.push(importedParticipant));
          this.errorMessage = '';
        }, error => {
          this.setErrorMessage(error);
        });
    }
  }

  autoGrowTextZone(e): void {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight + 25) + "px";
  }

  onBack(): void {
    this.isSendParticipant.emit(false);
  }

  private isActionButtonsDisabled(): boolean {
    const disableSaveButton: boolean = !this.toEmailParticipants
      || (this.isInvite && !!this.ksaEmail.lastInvitedDate || this.isThanks && !!this.ksaEmail.lastThanksDate);
    return disableSaveButton && this.isAllParticipantsEmailsSend();
  }

  onManageParticipant(dialogMode: DialogOperationMode) {
    this.isParticipantDialogOpened = true;
    this.participantDialogMode = dialogMode;
  }

  updateParticipant(participant: EmailParticipant) {
    const participantIndex: number = this.toEmailParticipants.findIndex(p => p.id === participant.id);
    if (participantIndex === -1) {
      this.serviceEmailParticipant.getAllParticipants(this.ksaEmail.masterKsaId).subscribe(participants => {
        this.toEmailParticipants = participants.filter(toParticipant => toParticipant.isTo);
      });
    } else {
      this.toEmailParticipants[participantIndex] = participant;
    }
  }

  onParticipantWindowClose() {
    this.isParticipantDialogOpened = false;
  }

  onClearParticipants() {
    this.shouldShowDeleteConfirmDialog = true;
    this.shouldDeleteAllParticipants = true;
  }

  onDeleteParticipant(participant: EmailParticipant) {
    this.shouldShowDeleteConfirmDialog = true;
    this.shouldDeleteAllParticipants = false;
    this.participantForDeleting = participant;
  }

  onDeleteParticipantsConfirmed(shouldDeleteAll: boolean) {
    if (shouldDeleteAll) {
      showSpinner(this.translateService.instant('ksaEngagement.emailManagement.sendInvite.confirmationDialog.progressAll'));
      this.serviceEmailParticipant.deleteAllParticipants(this.ksaEmail.masterKsaId)
        .pipe(finalize(hideSpinner))
        .subscribe(() => {
          this.toEmailParticipants = [];
          this.shouldShowDeleteConfirmDialog = false;
        }, (error: HttpErrorResponse) => {
          this.setErrorMessage(error);
        });
    } else {
      showSpinner(this.translateService.instant('ksaEngagement.emailManagement.sendInvite.confirmationDialog.progressAll'));
      this.serviceEmailParticipant.deleteParticipant(this.participantForDeleting.id)
        .pipe(finalize(hideSpinner))
        .subscribe(() => {
          this.removeParticipantFromToList(this.participantForDeleting);
          this.shouldShowDeleteConfirmDialog = false;
          this.participantForDeleting = null;
        }, (error: HttpErrorResponse) => {
          this.setErrorMessage(error);
        });
    }
  }

  private findSentInviteParticipants(): EmailParticipant[] {
    return this.isThanks ? this.toEmailParticipants.filter(p => p.isThanksSent) : this.toEmailParticipants.filter(p => p.isInviteSent);
  }

  isAllParticipantsEmailsSend(): boolean {
    return this.isSendingInProgress() || this.toEmailParticipants.length === this.findSentInviteParticipants().length;
  }

  isSendToAllLabel(): boolean {
    return this.toEmailParticipants.length === 0 ||
      (!this.isAllParticipantsEmailsSend() && this.findSentInviteParticipants().length === 0);
  }

  onDeleteParticipantCancel(): void {
    this.shouldShowDeleteConfirmDialog = false;
    this.participantForDeleting = null;
  }

  private setEmailSubjectAndBody() {
    const template = this.isInvite
      ? this.ksaEmail?.inviteEmailTemplate
      : this.ksaEmail?.thanksEmailTemplate;

    if (template) {
      this.emailSubject = template.subject;
      this.emailBody = template.emailBody;
    }
  }

  private removeParticipantFromToList(participant: EmailParticipant): void {
    if (this.toEmailParticipants.length > 0) {
      const index = this.toEmailParticipants.indexOf(participant, 0);
      if (index > -1) {
        this.toEmailParticipants.splice(index, 1);
      }
    }
  }

  isSendingInProgress(): boolean {
    return this.isInvite ? this.ksaEmail?.inviteEmailInProgress : this.ksaEmail?.thanksEmailInProgress;
  }

  private setSendBtnState(): void {
    this.sendBtnState = this.isSendingInProgress() ? ClrLoadingState.LOADING : ClrLoadingState.DEFAULT;
  }
}
