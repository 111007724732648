/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, OnInit, ViewChild} from '@angular/core';
import {DashboardService} from "../../services/dashboard.service";
import {dashboardKsaModel, dashboardSurveyModel} from "../../models/dashboard.model";
import {ApexChart, ApexNonAxisChartSeries, ApexResponsive, ChartComponent} from "ng-apexcharts";
import {finalize} from "rxjs/operators";
import {ApexLegend} from "ng-apexcharts/lib/model/apex-types";
import {DashboardEnum} from "../../enums/dashboard.enum";
import {Functionality} from "../../enums/functionality.enum";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  seriesAnonymity: ApexNonAxisChartSeries;
  seriesTempSurvey: ApexNonAxisChartSeries;
  seriesSurvey: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  labelSurvey: any;
  labelsAnonymity:any;
  labelsTempSurvey:any;
  legend: ApexLegend;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  functionality: typeof Functionality = Functionality;

  @ViewChild("chart") chart: ChartComponent;
  public chartOptionsType: Partial<ChartOptions>;

  dashboardKsa: dashboardKsaModel = {
    ksaCount: 0,
    draftKsaCount: 0,
    activeKsaCount: 0,
    simpleKsaCount: 0,
    advanceKsaCount: 0,
      anonymousKsaCount: 0,
      noneKsaCount: 0,
      semiAnonymousKsaCount: 0,
      participantCount: 0,
      closedKsaCount: 0
    };
  dashboardSurvey: dashboardSurveyModel={
    skillCount:0,
    roleProfileCount:0,
    surveyCount:0,
    draftSurveyCount:0,
    publishedSurveyCount:0,
    questionCount:0,
    archiveSurveyCount:0
  };
  errorMsg: string = '';

  surveyTypeSeries:number[] = [];
  surveyTypeLabel:string[] =[];

  surveyAnonymitySeries:number[] = [];
  surveyAnonymityLabel:string[] =[];
  surveyTempSeries:number[] = [];
  surveyTempLabel:string[] =[];
  surveySeries:number[] = [];
  surveyLabel:string[] =[];


  label:string;

        constructor(private dashboardService:DashboardService) {}
        ngOnInit(): void {
          this.loadData();
        }

        loadData():void{
          this.dashboardService.getDashboardKsa().pipe(finalize(()=>{
            this.dashboardPieChart()
          })).subscribe(DashboardKsa=> {
            this.dashboardKsa = DashboardKsa;
            this.surveyTypeSeries.push(this.dashboardKsa.simpleKsaCount, this.dashboardKsa.advanceKsaCount);
            this.surveyTypeLabel.push(DashboardEnum.SimpleKsa, DashboardEnum.AdvanceKsa);

            this.surveyAnonymitySeries.push(this.dashboardKsa.noneKsaCount, this.dashboardKsa.anonymousKsaCount, this.dashboardKsa.semiAnonymousKsaCount);
            this.surveyAnonymityLabel.push(DashboardEnum.None, DashboardEnum.Anonymous, DashboardEnum.semiAnonymous);
            this.surveySeries.push(this.dashboardKsa.draftKsaCount, this.dashboardKsa.activeKsaCount, this.dashboardKsa.closedKsaCount);
            this.surveyLabel.push(DashboardEnum.Draft, DashboardEnum.Active, DashboardEnum.Close)
          })

          this.dashboardService.getDashboardSurvey().pipe(finalize(()=>{
            this.dashboardPieChart()
          })).subscribe(survey=>{
              this.dashboardSurvey = survey;
              this.surveyTempSeries.push(this.dashboardSurvey.draftSurveyCount,this.dashboardSurvey.publishedSurveyCount,this.dashboardSurvey.archiveSurveyCount);
              this.surveyTempLabel.push(DashboardEnum.Draft,DashboardEnum.Published,DashboardEnum.Archive);

            },
            error => this.errorMsg = error.error.message);
        }

  dashboardPieChart(){
    this.chartOptionsType = {
      series: this.surveyTypeSeries,
      seriesAnonymity: this.surveyAnonymitySeries,
      seriesTempSurvey:this.surveyTempSeries,
      seriesSurvey:this.surveySeries,
      chart: {
        type: DashboardEnum.Donut
      },
      labels: this.surveyTypeLabel,
      labelsAnonymity: this.surveyAnonymityLabel,
      labelsTempSurvey:this.surveyTempLabel,
      labelSurvey:this.surveyLabel,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "top",
            }
          }
        }
      ]
    };

  }

}
