/********************************************************************** *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ClrDatagridStateInterface} from "@clr/angular";
import {
  ClosedKsa,
  ResponseColumnDisplay,
  ResponseColumnSettings,
  ResponseColumnWithParticipants,
  ResponseData
} from "../../../models/respose-data.model";
import {ResponseService} from "../../../services/response.service";
import {Router} from "@angular/router";
import {ExcelService} from "../../../services/excel.service";
import {environment} from "../../../../environments/environment";
import {Functionality} from "../../../enums/functionality.enum";
import {TranslateService} from '@ngx-translate/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from 'rxjs/operators';
import {AnalysisService} from "../../../services/analysisService";
import {CertificateService} from "../../../services/certificate.service";
import {CertificateDetails, CertificationByParticipants} from "../../../models/certification.model";

@Component({
  selector: 'app-response-management',
  templateUrl: './response-management.component.html',
  styleUrls: ['./response-management.component.scss']
})
export class ResponseManagementComponent implements OnInit {
  state: ClrDatagridStateInterface;
  isReplaceModelOpen: boolean = false;
  statusToFilter: string = "";
  page: number;
  loading: boolean = false;
  total: number;
  errorMsg: string = '';
  pageSize = 10;
  selectedColumnName: string = '';
  selectedColumnIndex: number;
  isEditResponse: boolean = false;
  findWhat: string;
  replaceWith: string;
  isMatchWholeWord: boolean = false;
  isMatchCase: boolean = false;
  isSavedResponse: boolean = false;
  closedKsa: (ClosedKsa)[];
  selectedKsaId: number;
  selectedKsaName: string;
  responseColumnSettings: (ResponseColumnSettings)[];
  responseData: (ResponseData)[];
  certificationDetails: CertificateDetails;
  editResponseData;
  showRestoreResponse: boolean = false;
  isResetResponse: boolean = false;
  isNoMatchFound: boolean = true;
  showNoMatchFoundMsg: boolean = false;
  occurrenceCount: number = 0;
  showDatagrid: boolean = false;

  functionality: typeof Functionality = Functionality;
  isUndoResponse: boolean = false;

  constructor(public responseService: ResponseService,
              private router: Router,
              private excelService: ExcelService,
              private cdr: ChangeDetectorRef,
              private translate: TranslateService,
              public analysisService: AnalysisService,
              private readonly certificationService: CertificateService) {
  }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.getAllClosedKsa();
  }

  onChange(ksaId: number): void {
    this.selectedKsaId = ksaId;
    this.selectedKsaName = this.closedKsa.filter((x) => x.id == ksaId)[0].ksaName;
    this.getAllResponseColumnData();
    this.getAllCertificationColumnData();
    if(this.state) {
      this.refresh(this.state);
    }
  }

  getAllClosedKsa(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.responseService.getAllClosedKsa()
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        if (data) {
          this.closedKsa = data;
        }
      });
  }

  getAllResponseColumnData(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.responseService.getAllResponseColumnData(this.selectedKsaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      if (data) {
        this.showDatagrid = true;
        this.responseColumnSettings = data;
      }
    });
  }

  getAllCertificationColumnData(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.certificationService.getCertificateData(this.selectedKsaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        if (data) {
          this.certificationDetails = data;
        }
      });
  }

  onResetData(): void {
    this.showRestoreResponse = false;
    const data = {
      ksaId: this.selectedKsaId
    };
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.responseService.resetData(data)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      if (data) {
        this.refresh(this.state);
        this.isResetResponse = true;
      }
      setTimeout(() => {
        this.isResetResponse = false;
      }, 5000);
    });
  }

  onUndoData(): void {
    this.refresh(this.state);
    this.isUndoResponse = true;
    setTimeout(() => {
      this.isUndoResponse = false;
    }, 5000);
  }

  onCancelFindAndReplace(): void {
    this.isReplaceModelOpen = false;
    this.findWhat = '';
    this.replaceWith = '';
    this.isMatchWholeWord = false;
    this.isMatchCase = false;
  }

  onReplaceAllData(){
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.occurrenceCount = 0;
    this.responseData.forEach(data=>{
      if (this.isMatchCase && this.isMatchWholeWord && data.responseColumnWithParticipants[this.selectedColumnIndex].answerText == this.findWhat) {
        data.responseColumnWithParticipants[this.selectedColumnIndex].answerText = this.replaceWith;
        this.isNoMatchFound = false;
        this.occurrenceCount++;
      } else if (!this.isMatchCase && this.isMatchWholeWord && data.responseColumnWithParticipants[this.selectedColumnIndex].answerText.toLowerCase() == this.findWhat.toLowerCase()) {
        data.responseColumnWithParticipants[this.selectedColumnIndex].answerText = this.replaceWith;
        this.isNoMatchFound = false;
        this.occurrenceCount++;
      } else if (this.isMatchCase && !this.isMatchWholeWord && data.responseColumnWithParticipants[this.selectedColumnIndex].answerText.includes(this.findWhat)) {
        const regex = new RegExp(this.findWhat, "g");
        const ansText = data.responseColumnWithParticipants[this.selectedColumnIndex].answerText;
        data.responseColumnWithParticipants[this.selectedColumnIndex].answerText = ansText.replace(regex, this.replaceWith);
        this.isNoMatchFound = false;
        this.occurrenceCount++;
      } else if (!this.isMatchCase && !this.isMatchWholeWord && data.responseColumnWithParticipants[this.selectedColumnIndex].answerText.toLowerCase().includes(this.findWhat.toLowerCase())) {
        const regex = new RegExp(this.findWhat, "i");
        const ansText = data.responseColumnWithParticipants[this.selectedColumnIndex].answerText;
        data.responseColumnWithParticipants[this.selectedColumnIndex].answerText = ansText.replace(regex, this.replaceWith);
        this.isNoMatchFound = false;
        this.occurrenceCount++;
      }
    });

    hideSpinner();
    if(this.isNoMatchFound){
      this.showNoMatchFoundMsg = true;
    }else{
      this.showNoMatchFoundMsg = false;
      this.isNoMatchFound = true;
      this.isReplaceModelOpen = false;
      this.findWhat = '';
      this.replaceWith = '';
      this.isMatchWholeWord = false;
      this.isMatchCase = false;
      setTimeout(() => {
        this.occurrenceCount = 0;
      }, 2000);
    }
  }

  onUpdateResponseByParticipant(): void {
    const data = {
      responseData: this.responseData,
      ksaId: this.selectedKsaId
    };

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.responseService.updateResponseData(data)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        if (data) {
          const changedData: ResponseData = data.responseData.filter(changed => !this.responseData.includes(changed))[0];
          if (changedData) {
           this.responseData[this.responseData
             .indexOf(this.responseData.filter(res => res.participantId === changedData.participantId)[0])] = changedData;
            this.isEditResponse = false;
            this.editResponseData = [];
         }
       }
        setTimeout(() => {
          this.isSavedResponse = false;
        }, 5000);
     });

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.certificationService.updateCertificateData(this.certificationDetails)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(result => {
        if (result) {
          this.certificationDetails = result;
          this.isSavedResponse = true;
        }
        setTimeout(() => {
          this.isSavedResponse = false;
        }, 5000);
      });
  }

  onUpdateAllResponse(): void {
    const data = {
      responseData: this.responseData,
      ksaId: this.selectedKsaId
    };

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.responseService.updateResponseData(data)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      if (data) {
        this.refresh(this.state);
        this.isSavedResponse = true;
      }

      setTimeout(() => {
        this.isSavedResponse = false;
      }, 5000);
    });
  }

  onSaveResponseColumns(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.responseService.updateResponseColumnData(this.responseColumnSettings)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      if (data) {
        this.responseColumnSettings = data;
        this.isSavedResponse = true;
      }
      setTimeout(() => {
        this.isSavedResponse = false;
      }, 5000);
    })
  }

  onCancelUpdateData(): void {
    this.isEditResponse = false;
    this.editResponseData = [];
    this.refresh(this.state);
  }

  onShowOriginalData(): void {
    this.router.navigate([]).then((result) => {
      window.open(`${environment.API_URL}` +'/#/ResponseOriginalData/'+ this.selectedKsaId, '_blank');
    });
  }

  onShowFindReplaceModel(columnName, i): void {
    this.showNoMatchFoundMsg = false;
    this.isReplaceModelOpen = true;
    this.selectedColumnIndex = i;
    this.selectedColumnName = columnName;
  }

  exportExcelResponseManagement(): void {
    this.excelService.generateResponseManagementExcelData(this.responseData,this.certificationDetails, this.selectedKsaName);
  }

  onEditResponse(data): void {
    this.editResponseData = data;
    this.isEditResponse = true;
  }

  refresh(state: ClrDatagridStateInterface) {
    this.state = state;
    this.loading = true;
    const colFilters: ({ columnName: string; columnValue: string; })[] = [];
    let columnName = '';
    let data;
    if (state?.filters) {
      state.filters.forEach((filter,i)=> {
        const {property, value} = <{ property: string; value: string }>filter;
        colFilters.push({columnValue : value, columnName : property});
        columnName = property;
      })
      data={
        ksaId: this.selectedKsaId,
        size: state?.page?.size,
        sortColumn: columnName,
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false)  ? 'ASC' : 'DESC',
        page: state?.page?.current - 1,
        analysisFilterDTOS: colFilters
      }
    }else{
      data={
        ksaId: this.selectedKsaId,
        size: state?.page?.size,
        sortColumn: state?.sort ? state.sort.by : "",
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false)  ? 'ASC' : 'DESC',
        page: state?.page?.current - 1
      }
    }
    this.responseService.updateResponse(data)
      .subscribe((results) => {
        this.responseData = results.responseData;
        this.total = results.total;
      });

    this.certificationService.getCertificateData(this.selectedKsaId)
      .subscribe(result => {
        if (result) {
          this.certificationDetails = result;
          this.loading = false;
        }
      });
  }

  updateAnswerText(answerText: string, response: ResponseData, responseColumnDisplay: ResponseColumnDisplay): void {
    const responseColumn: ResponseColumnWithParticipants = response.responseColumnWithParticipants
      ?.find((participantAnswerForQuestion) => responseColumnDisplay.id && participantAnswerForQuestion?.responseColumnDisplay?.id === responseColumnDisplay.id);
    if (responseColumn) {
      responseColumn.answerText = answerText;
    } else {
      const responseColumns: (ResponseColumnWithParticipants)[] = this.responseData.find(data => data.participantId === response.participantId).responseColumnWithParticipants;
      const newAnsweredResponseColumn: ResponseColumnWithParticipants = {
        answerText: answerText,
        isDisplay: true,
        participantId: response.participantId,
        responseColumnDisplay: responseColumnDisplay
      }
      responseColumns.push(newAnsweredResponseColumn);
    }
  }

  getAnswerText(response: ResponseData, column: ResponseColumnSettings): string {
    const answerFromParticipant: string = this.analysisService.getAnswerFromParticipant(response.responseColumnWithParticipants, column?.responseColumnDisplay?.id);
    return answerFromParticipant === 'N/A' ? '' : answerFromParticipant;
  }

  getCertificationDataByParticipantId(participantId: number): CertificationByParticipants[] {
    return this.certificationDetails?.certificateData?.find(data => data.participant.id === participantId)?.certificationByParticipants ?? [];
  }

  exportResponsesCsv(): void {
    showSpinner(this.translate.instant('analysisAndReports.responseManagement.downloadingCsv'));
    this.responseService.downloadCsvFile(this.selectedKsaId)
      .pipe(finalize(hideSpinner))
      .subscribe(response => {
          saveAs(response.body, `${this.selectedKsaName}.csv`);
        },
        error => this.errorMsg = this.translate.instant('analysisAndReports.responseManagement.csv-error'));
  }
}
