/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DialogOperationMode} from "../../../../../enums/dialog-operation-mode.enum";
import {EmailParticipant} from "../../../../../models/participant.model";
import {hideSpinner, showSpinner} from "../../../../../common/spinner";
import {NgForm} from "@angular/forms";
import {ClrForm} from "@clr/angular";
import {Observable} from "rxjs";
import {EmailParticipantService} from "../../../../../services/email-participant.service";
import {finalize} from "rxjs/operators";
import {MasterKsaSimple} from "../../../../../models/master-ksa.model";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-participant-dialog',
  templateUrl: './participant-dialog.component.html',
  styleUrls: ['./participant-dialog.component.scss']
})
export class ParticipantDialogComponent implements OnInit {

  readonly DIALOG_MODES = DialogOperationMode;

  @Input()
  dialogMode: DialogOperationMode;
  @Input()
  participants: EmailParticipant[];
  @Input()
  ksaId: number;
  @Output()
  onParticipantUpdate = new EventEmitter<EmailParticipant>();
  @Output()
  onDialogClose = new EventEmitter<void>();

  @ViewChild('participantForm')
  participantForm: NgForm;
  @ViewChild(ClrForm)
  clrForm: ClrForm;

  participant: EmailParticipant;
  selectedParticipant: EmailParticipant;

  errorMessage: string;

  constructor(private readonly serviceEmailParticipant: EmailParticipantService,
              private readonly translate: TranslateService) {
  }

  ngOnInit(): void {
    this.participant = {} as EmailParticipant;
    this.selectedParticipant = {} as EmailParticipant;
    this.errorMessage = '';
  }

  close() {
    this.onDialogClose.emit();
  }

  onSave() {
    if (!this.isFormValid()) {
      return;
    }
    this.participant.isTo = true;
    this.participant.masterKsa = {id: this.ksaId} as MasterKsaSimple;
    showSpinner(this.translate.instant('ksaEngagement.emailManagement.sendInvite.participantDialog.progress'));
    const templateObservable: Observable<EmailParticipant> = this.dialogMode === DialogOperationMode.CREATE ?
      this.serviceEmailParticipant.createParticipant(this.participant) :
      this.serviceEmailParticipant.updateParticipant(this.participant.id, this.participant);
    templateObservable.pipe(finalize(hideSpinner))
      .subscribe(participant => {
        this.onParticipantUpdate.emit(participant);
        this.close();
      }, (error: HttpErrorResponse) => {
        this.errorMessage = error.error.message;
      });
  }

  onParticipantChanged(participant: EmailParticipant) {
    this.participant = Object.assign({}, participant);
    this.errorMessage = '';
  }

  isEmailExist(participantEmail: string): boolean {
    const filteredParticipants = this.participants.filter(participant => participant.id !== this.selectedParticipant.id);
    return filteredParticipants.some(participant => participant.email === participantEmail);
  }

  private isFormValid(): boolean {
    const isFormValid = this.participantForm.valid && this.isEmailSelected();

    if (!isFormValid) {
      this.clrForm.markAsTouched();
    }

    return isFormValid;
  }

  private isEmailSelected(): boolean {
    if (this.dialogMode === DialogOperationMode.EDIT) {
      if (!this.participant.id) {
        this.errorMessage = this.translate
          .instant("ksaEngagement.emailManagement.sendInvite.participantDialog.validation.noParticipantSelected");
        return false;
      }
    }
      return true;
  }
}
