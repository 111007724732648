<div class="button-container" *hasAccess="functionality.manageSkill">
  <button id="{{btnId.btnMngSkillAdd}}" class="btn btn-sm btn-primary" (click)="onSetAddSkillFormOpen()"
          *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)">
    {{'admin.coursesAndSkills.skills.addSkillBtn' | translate}}
  </button>
  <button id="{{btnId.btnMngSkillGroup}}" (click)="onSetSkillGroup()" class="btn btn-sm btn-primary"
          *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)">
    {{'admin.coursesAndSkills.skills.mngSkillGroupBtn' | translate}}
  </button>
</div>

<div *ngIf="isSavedResponse" class="alert alert-success" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
      </div>
      <span class="alert-text">{{'admin.coursesAndSkills.columnSavedMsg' | translate}}</span>
    </div>
  </div>
</div>

<clr-modal (clrModalOpenChange)="onCancelAdding(addForm)" [(clrModalOpen)]="addSkillFormOpen" [clrModalClosable]="true">
  <h3 class="modal-title">{{'admin.coursesAndSkills.skills.addSkillModalTitle' | translate}}</h3>
  <div class="modal-body modal-body-layout">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>
    <form #addForm="ngForm" (ngSubmit)="onAddSkill(addForm)" autocomplete="off" clrForm>
      <clr-input-container class="clr-row">
        <label class="clr-col-4">{{'admin.coursesAndSkills.skills.skillName' | translate}}*</label>
        <input [(ngModel)]="newSkill.skillName" class="clr-col-8" clrInput name="name"
               placeholder="{{'placeholder.skillName' | translate}}" required
               type="text"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.skillNameRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-textarea-container class="clr-row">
        <label class="clr-col-4">{{'admin.coursesAndSkills.skills.skillDesc' | translate}}</label>
        <textarea [(ngModel)]="newSkill.skillDescription" class="clr-col-8" clrTextarea name="description"
                  placeholder="{{'placeholder.skillDescription' | translate}}" rows="1"></textarea>
      </clr-textarea-container>
      <clr-select-container class="clr-row">
        <label class="clr-col-4">{{'admin.coursesAndSkills.skills.skillGroup' | translate}}*</label>
        <select [(ngModel)]="newSkill.skillGroup.id" class="clr-col-8 " clrSelect name="options" required>
          <option *ngFor="let skillGroup of skillGroups.content" [value]="skillGroup.id">
            {{skillGroup.skillGroupName}}
          </option>
        </select>
        <clr-control-error>{{'errorMsg.skillGroupRequired' | translate}}</clr-control-error>
      </clr-select-container>
      <br>
      <div class="button-container">
        <span class="clr-control-label">{{'admin.coursesAndSkills.skills.courses' | translate}}* </span>
      </div>
      <clr-datagrid [(clrDgSelected)]="selected" (clrDgSelectedChange)="onSelectCourse($event)" [clrDgPreserveSelection]="true"
                    class="datagrid-compact child-course-datagrid popup-datagrid clear">
        <clr-dg-column
          [clrDgField]="'courseName'">{{'admin.coursesAndSkills.courses.coursesGridColumnTitle' | translate}}
        </clr-dg-column>
        <clr-dg-column>{{'admin.coursesAndSkills.courses.isDefaultGridColumnTitle' | translate}}
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let course of activeCourses" [clrDgItem]="course">
          <clr-dg-cell>{{getCourseNameWithVersion(course)}}</clr-dg-cell>
          <clr-dg-cell>
            <input clrCheckbox class="input-container" [checked]="course?.isDefault"
                   (change)="onDefaultSelected($event, course)"
                   [disabled]="isCurrentCourseSelected(course)"
                   type="checkbox" class="clr-col-12"
                   name="isDefault"/>
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
      <div class="modal-footer">
        <button (click)="onCancelAdding(addForm)" class="btn btn-sm btn-outline" type="button">{{'admin.cancelBtn' |
          translate}}
        </button>
        <button [disabled]="addForm.invalid || selected.length < 1" class="btn btn-sm btn-primary"
                type="submit">{{'admin.addBtn' | translate}}
        </button>
      </div>
    </form>
  </div>
</clr-modal>

<clr-modal (clrModalOpenChange)="onCancelEditing(editForm)" [(clrModalOpen)]="editSkillFormOpen"
           [clrModalClosable]="true">
  <h3 class="modal-title">{{'admin.coursesAndSkills.skills.editSkillModalTitle' | translate}}</h3>
  <div class="modal-body modal-body-layout">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>
    <div class="alert alert-warning alert-sm" role="alert" *ngIf="warningSequenceNoChange">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
          </div>
          <span class="alert-text">
            {{'admin.coursesAndSkills.skills.changeSkillGroup' | translate}}
          </span>
        </div>
      </div>
      <button type="button" class="close" aria-label="Close">
        <clr-icon aria-hidden="true" (click)="warningSequenceNoChange = false" shape="close"></clr-icon>
      </button>
    </div>

    <form #editForm="ngForm" (ngSubmit)="onEditSkill(editForm)" autocomplete="off" clrForm>
      <clr-input-container class="clr-row">
        <label class="clr-col-4">{{'admin.coursesAndSkills.skills.skillName' | translate}}*</label>
        <input [(ngModel)]="newSkill.skillName" class="clr-col-8" clrInput name="name"
                placeholder="{{'placeholder.skillName' | translate}}" required
               type="text"/>
        <clr-control-error *clrIfError="'required'">{{'errorMsg.skillNameRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-textarea-container class="clr-row">
        <label class="clr-col-4">{{'admin.coursesAndSkills.skills.description' | translate}}</label>
        <textarea [(ngModel)]="newSkill.skillDescription" class="clr-col-8" clrTextarea name="description"
                  placeholder="{{'placeholder.skillDescription' | translate}}" rows="1"></textarea>
      </clr-textarea-container>
      <clr-select-container class="clr-row">
        <label class="clr-col-4">{{'admin.coursesAndSkills.skills.skillGroup' | translate}}*</label>
        <select [(ngModel)]="newSkill.skillGroup.id" class="clr-col-8 " clrSelect name="options"
                (change)="getWarningMessage()" required [disabled]="newSkill.status === 'Active'">
          <option *ngFor="let skillGroup of skillGroups.content" [value]="skillGroup.id">
            {{skillGroup.skillGroupName}}
          </option>
        </select>
        <clr-control-error>{{'errorMsg.skillGroupRequired' | translate}}</clr-control-error>
      </clr-select-container>
      <br>
      <div class="button-container">
        <span class="clr-control-label">{{'admin.coursesAndSkills.skills.courses' | translate}}* </span>
      </div>
      <clr-datagrid [(clrDgSelected)]="selected" (clrDgSelectedChange)="onSelectCourse($event)" [clrDgPreserveSelection]="true"
                    class="datagrid-compact child-course-datagrid popup-datagrid clear">
        <clr-dg-column
          [clrDgField]="'courseName'">{{'admin.coursesAndSkills.courses.coursesGridColumnTitle' | translate}}
        </clr-dg-column>
        <clr-dg-column>{{'admin.coursesAndSkills.courses.isDefaultGridColumnTitle' | translate}}
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let course of activeCourses" [clrDgItem]="course">
          <clr-dg-cell>{{getCourseNameWithVersion(course)}}</clr-dg-cell>
          <clr-dg-cell>
            <input clrCheckbox class="input-container" [checked]="course.isDefault"
                   (change)="onDefaultSelected($event, course)"
                   [disabled]="isCurrentCourseSelected(course)"
                   type="checkbox" class="clr-col-12"
                   name="isDefault"/>
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
      <div class="modal-footer">
        <button (click)="onCancelEditing(editForm)" class="btn btn-sm btn-outline" type="button">{{'admin.cancelBtn' |
          translate}}
        </button>
        <button [disabled]="editForm.invalid " class="btn btn-sm btn-primary"
                type="submit">{{'admin.okBtn' |
          translate}}
        </button>
      </div>
    </form>
  </div>
</clr-modal>

<clr-modal (clrModalOpenChange)="resetError()" [(clrModalOpen)]="showDeleteSkillPopUp">
  <h3 class="modal-title">{{'admin.coursesAndSkills.skills.deleteSkill' | translate}}?
    <clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true" clrAlertType="danger">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'admin.coursesAndSkills.skills.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteSkillPopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}
    </button>
    <button (click)="onDeleteSkill()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}
    </button>
  </div>
</clr-modal>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="parent-datagrid">
  <ng-container *ngFor="let column of skillColumnHeaderList; let i = index">
    <clr-dg-column *ngIf="column.columnFilterName" [clrDgField]="column.columnFilterName" >
      <ng-template clrDgHideableColumn [(clrDgHidden)]="skillColumnHeaderList[i].isHide">
        {{column.columnName}}
      </ng-template>
      <clr-dg-filter *ngIf="column.columnFilterName == 'status'">
        <app-status-filter [statusOptions]="surveyStatusOption" (statusFilter)='getStatusFilter($event)'>
        </app-status-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column *ngIf="!column.columnFilterName">
      <ng-template clrDgHideableColumn [(clrDgHidden)]="skillColumnHeaderList[i].isHide">
        {{column.columnName}}
      </ng-template>
      <clr-dg-filter *ngIf="column.columnFilterName == 'status'">
        <app-status-filter [statusOptions]="surveyStatusOption" (statusFilter)='getStatusFilter($event)'>
        </app-status-filter>
      </clr-dg-filter>
    </clr-dg-column>
  </ng-container>
  <clr-dg-column class="action-cell"
                 *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)"></clr-dg-column>
  <clr-dg-row *ngFor="let skill of allSkills.content" [clrDgItem]="skill">
    <clr-dg-cell>{{skill.skillName}}</clr-dg-cell>
    <clr-dg-cell>{{skill.skillDescription}}</clr-dg-cell>
    <clr-dg-cell>{{skill.skillGroup.skillGroupName}}</clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let course of skill?.courses">
        <li>{{ course }}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell>{{skill.status}}</clr-dg-cell>
    <clr-dg-cell class="action-cell"
                 *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)">
      <clr-dg-action-overflow *hasAccess="functionality.manageSkill">
        <button id="{{btnId.btnMngSkillEdit}}" type="button" class="action-item"
                *ngIf="(skill.status === 'Draft' || skill.status === 'Active')"
                (click)="onEditSkillDetails(skill);">{{'admin.editBtn' | translate}}
        </button>
        <button id="{{btnId.btnMngSkillDelete}}" type="button" class="action-item" *ngIf="(skill.status === 'Draft')"
                (click)="onShowDeleteSkillPopUp(skill.id)">{{'admin.deleteBtn' | translate}}
        </button>
        <button id="{{btnId.btnMngSkillActivate}}" type="button" class="action-item"
                *ngIf="(skill.status === 'Draft') || (skill.status === 'Archived')"
                (click)="onActivateSkill(skill.id)">{{'admin.activateBtn' | translate}}
        </button>
        <button id="{{btnId.btnMngSkillArchive}}" type="button" class="action-item" *ngIf="(skill.status === 'Active')"
                (click)="onArchiveSkill(skill.id)">{{'admin.archiveBtn' | translate}}
        </button>
      </clr-dg-action-overflow>
    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    <clr-dg-column-toggle>
      <clr-dg-column-toggle-title class="column-toggle-title">Columns
        <clr-icon role="button" shape="floppy" title="Save" (click)="onSaveResponseColumns()" class="save-col-icon"> </clr-icon>
      </clr-dg-column-toggle-title>
    </clr-dg-column-toggle>
    <clr-dg-pagination #pagination [(clrDgPage)]="page" [clrDgPageSize]="10" [clrDgTotalItems]="total">
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Skills per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} skills
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<!--Skill Group Model-->
<clr-modal (clrModalOpenChange)="closeSkillGroup()" [(clrModalOpen)]="isSkillGroupVisible" [clrModalClosable]="true">
  <h3 class="modal-title">{{'admin.coursesAndSkills.skills.mngSkillGrpModalTitle' | translate}}</h3>
  <div class="modal-body">
    <div class="alert alert-danger alert-sm" role="alert" *ngIf="errorMsg !== ''">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
             {{errorMsg}}
            </span>
        </div>
      </div>
    </div>

    <button (click)="onAddSkillGroupRow()" id="addSkillGroupRowBtn" class="btn btn-sm btn-primary"
            [disabled]="!skillGroupAddBtn">
      {{'admin.coursesAndSkills.skills.addSkillGroupBtn' | translate}}
    </button>
    <clr-datagrid #dg>
      <clr-dg-column [clrDgField]="'skillGroupName'">{{'admin.coursesAndSkills.skills.skillGroup' | translate}}
      </clr-dg-column>
      <clr-dg-column>{{'admin.coursesAndSkills.skills.action' | translate}}</clr-dg-column>
      <clr-dg-row *clrDgItems="let skillGroup of skillGroups.content" [clrDgItem]="skillGroup">
        <ng-container *ngIf="skillGroup.id === 0">
          <clr-dg-cell><input [(ngModel)]="addSkillGroup.skillGroupName"
                              (change)="addSkillGroup.skillGroupName=addSkillGroup.skillGroupName?.trim()" clrInput
                              name="skillGroup"/>
          </clr-dg-cell>
          <clr-dg-cell>
            <button type="button" class="btn btn-sm btn-primary" (click)="onSaveSkillGroup()"
                    [disabled]="addSkillGroup.skillGroupName === ''">{{'admin.addBtn' | translate}}
            </button>
            <button type="button" class="btn btn-sm btn-outline"
                    (click)="onCancelAddingSkillGroup()">{{'admin.cancelBtn' | translate}}
            </button>
          </clr-dg-cell>
        </ng-container>
        <ng-container *ngIf="skillGroup.id === editSkillGroup?.id && skillGroup.id !== 0">
          <clr-dg-cell><input clrInput [(ngModel)]="editSkillGroup.skillGroupName"
                              (change)="editSkillGroup.skillGroupName=editSkillGroup.skillGroupName?.trim()"
                              name="skillGroup"/>
          </clr-dg-cell>
          <clr-dg-cell>
            <button type="button" class="btn btn-sm btn-primary" [disabled]="editSkillGroup.skillGroupName === ''"
                    (click)="onUpdateSkillGroup()">{{'admin.saveBtn' | translate}}</button>
            <button type="button" class="btn btn-sm btn-outline" (click)="onResetSkillGroup()">{{'admin.cancelBtn' |
              translate}}</button>
          </clr-dg-cell>
        </ng-container>

        <ng-container *ngIf="editSkillGroup?.id !== skillGroup.id && skillGroup.id !== 0">
          <clr-dg-cell>{{skillGroup.skillGroupName}}</clr-dg-cell>
          <clr-dg-cell>
            <button type="button" class="btn btn-sm btn-primary" (click)="editSkillGroup = skillGroup">{{'admin.editBtn'
              | translate}}</button>
            <button *ngIf="!skillGroup.isSkillMapped" type="button" class="btn btn-sm btn-outline"
              (click)="onShowDeleteSkillGroupPopUp(skillGroup.id)">{{'admin.deleteBtn' | translate}}</button>
          </clr-dg-cell>
        </ng-container>
      </clr-dg-row>
    </clr-datagrid>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="showDeleteSkillGroupPopUp" (clrModalOpenChange)="resetError()">
  <h3 class="modal-title">{{'admin.coursesAndSkills.skills.deleteSkillGroup' | translate}}?
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'admin.coursesAndSkills.skills.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteSkillGroupPopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onDeleteSkillGroup()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>
