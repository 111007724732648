/*
 *  ***********************************************************************************
 *  * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 *  * **********************************************************************************
 */

import {Pipe, PipeTransform} from '@angular/core';
import {RoleProfileSkillMap} from '../../models/role-profile.model';
import {Status} from '../../enums/status.enum';

@Pipe({
  name: 'filterRoleProfileSkillMap'
})
export class FilterRoleProfileSkillMapPipe implements PipeTransform {
  transform(roleProfileSkillMaps: RoleProfileSkillMap[], status: Status): RoleProfileSkillMap[] {
    return roleProfileSkillMaps?.filter(e => e.skill.status === status) || [];
  }

}
