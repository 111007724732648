import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from "../models/user.model";
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  userUrl: string;
  roleUrl: string;

  constructor(private http: HttpClient) {
    this.userUrl = `${environment.API_URL}${API_KSA_CORE_V1}/user/`;
    this.roleUrl = `${environment.API_URL}${API_KSA_CORE_V1}/ksarole/`;
  }

  getRoles(): Observable<any[]> {
    return this.http.get<any[]>(`${this.roleUrl}`)
  }

  getUserById(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.userUrl}${id}/userroles`);
  }

  getUser(filter1, filter2, filter3, filter4, from, to, sortby, sortorder): Observable<User> {
    filter1 = encodeURIComponent(filter1);
    filter2 = encodeURIComponent(filter2);
    filter3 = encodeURIComponent(filter3);
    filter4 = encodeURIComponent(filter4);
    return this.http.get<User>(`${this.userUrl}?firstName=${filter1}&lastName=${filter2}&emailId=${filter3}&userRoleMaps.ksaRole.roleName=${filter4}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`)
  }

  getUserData(): Observable<any[]> {
    return this.http.get<any[]>(`${this.userUrl}all/`)
  }

  saveUser(user: any): Observable<any> {
    return this.http.post<any>(`${this.userUrl}`, user)
  }

  UpdateUser(user: any): Observable<any> {
    return this.http.put<any>(`${this.userUrl}`, user)
  }

  deleteUser(id: any): Observable<any> {
    return this.http.delete<any>(`${this.userUrl}` + id)
  }

  deleteMultipleUsers(ids: any[]): Observable<any[]> {
    return this.http.delete<any[]>(`${this.userUrl}multiple/` + ids)
  }

  getPermissions(userEmail:string): Observable<User> {
     return this.http.get<User>(`${this.userUrl}`+`{email_id}?email_id=${userEmail}`)
  }
}
