<div *ngIf="showLoader">
  <clr-spinner>Loading ...</clr-spinner>
</div>
<div *ngIf="!showLoader">
  <div class="ksa-info">
    <div *ngIf="saveSuccess" class="alert alert-success" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
          </div>
          <span class="alert-text">
            {{"analysisAndReports.reports.roleMap.roleMapSavedMsg" | translate}}
          </span>
        </div>
      </div>
    </div>
    <div class="ksa-info">
      <div *ngIf="resetSuccess" class="alert alert-success" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
            </div>
            <span class="alert-text">
              {{"analysisAndReports.reports.roleMap.roleMapResetMsg" | translate}}
            </span>
          </div>
        </div>
      </div>

      <div *ngIf="!isParticipantDetails">
        <div class="datagrid-area">
        <form #addForm="ngForm" autocomplete="off" class=" clr-row clr-form" clrForm>
          <div class="clr-col-5">
            <clr-combobox-container class="clr-row">
              <label class="clr-col-3">{{'surveyTemplate.questionSheet.isSkillGroup'|translate}}</label>
              <clr-combobox [(ngModel)]="skillGroupSelected" [disabled]="disabled" class="clr-col-8" clrMulti="true"
                            name="multiSelect">
                <ng-container *clrOptionSelected="let selected">
                  {{selected}}
                </ng-container>
                <clr-options>
                  <clr-option *clrOptionItems="let skill of skillGroups" [clrValue]="skill.skillGroupName">
                    {{skill.skillGroupName}}
                  </clr-option>
                </clr-options>
              </clr-combobox>
            </clr-combobox-container>
          </div>
          <div class="clr-col-3">
            <form autocomplete="off" clrForm>
              <clr-select-container>
                <label
                  class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSet'
                  |
                  translate}}*</label>
                <select (change)="onChangeRoleMapDataWithKsaSet($event)"
                        class="clr-col-7 solution-group-select-input input-field" clrSelect
                        id="roleProfileSetDropdown" name="roleProfileSetImported">
                  <option disabled selected value=null>
                    {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileSet.selectRoleProfileSetFromList' |
                    translate}}
                  </option>
                  <option *ngFor="let roleProfileSet of ksaSets"
                          [selected]="roleProfileSelected?.id === roleProfileSet?.id" [value]="roleProfileSet.id">
                    {{roleProfileSet.name}}
                  </option>
                </select>
                <clr-control-error>{{'errorMsg.roleProfileSetIsRequired'|translate}}</clr-control-error>
              </clr-select-container>
            </form>
          </div>
          <div *hasAccess="functionality.analysis" class="clr-col-4 btn-alignment">
            <button (click)="saveRoleMap()" [disabled]="!showDatagrid"
                    class="btn btn-primary btn-sm">{{"analysisAndReports.reports.saveAllBtn" | translate}}
            </button>
            <button (click)="onShowColorModel()" [disabled]="!showDatagrid"
                    class="btn btn-sm btn-primary">{{"analysisAndReports.reports.colorPickerBtn" | translate}}
            </button>
            <button (click)="exportExcelRoleMap()" [disabled]="!showDatagrid"
                    class="btn btn-sm btn-primary">{{"analysisAndReports.reports.export" | translate}}
            </button>
            <button (click)="onOpenNewWindow()" *ngIf="showNewWindowButton" [disabled]="!showDatagrid"
                    class="btn btn-sm btn-primary">
              {{"analysisAndReports.reports.openNewWindow" | translate}}
            </button>
            <button (click)="onRefresh()" [disabled]="!showDatagrid" class="btn btn-sm btn-primary">
              {{"analysisAndReports.reports.refresh" | translate}}
            </button>
          </div>
        </form>
        <div>
          <div *ngIf="isSavedResponse" class="alert alert-success" role="alert">
            <div class="alert-items">
              <div class="alert-item static">
                <div class="alert-icon-wrapper">
                  <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
                </div>
                <span class="alert-text">{{'analysisAndReports.responseManagement.responseSuccess' | translate}}</span>
              </div>
            </div>
          </div>
          <clr-datagrid *ngIf="showDatagrid" class="role-profile-datagrid" (clrDgRefresh)="roleMapRefresh($event)" [clrDgLoading]="loading">
            <clr-dg-column class="width-120">{{"analysisAndReports.reports.actions" | translate}}</clr-dg-column>
            <clr-dg-column *ngFor="let column of responseColumnSettings; let i = index"
                           class="width-175" [clrDgField]="column?.responseColumnDisplay?.questionName"
                           [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''">
              <ng-template [(clrDgHidden)]="responseColumnSettings[i].isHideable" clrDgHideableColumn>
                {{column?.responseColumnDisplay?.questionName}}
              </ng-template>
            </clr-dg-column>
            <clr-dg-column class="width-220">{{"analysisAndReports.reports.roleMap.roleProfile" | translate}}
            </clr-dg-column>
            <clr-dg-column class="width-100">{{"analysisAndReports.reports.roleMap.roleAlignment" | translate}}
            </clr-dg-column>
            <clr-dg-column *ngFor="let participantAnalysisColumn of (responseData[0]?.participantAnalysisList || [])"
                           [hidden]="!skillGroupSelected?.includes(participantAnalysisColumn.skillGroupName)"
                           class="width-175">
              {{participantAnalysisColumn?.skill?.skillName}}
            </clr-dg-column>
            <clr-dg-row>
              <clr-dg-cell class="width-120">Pin</clr-dg-cell>
              <clr-dg-cell *ngFor="let column of responseColumnSettings; let i = index"
                           [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''"
                           style="text-align: center" class="width-175">
                <clr-icon shape="pin" title="pin" (click)="setPinnedColumn(column.responseColumnDisplay.questionName)"
                          [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'pinned-column' : ''">
                </clr-icon>
              </clr-dg-cell>
              <clr-dg-cell class="width-220"></clr-dg-cell>
              <clr-dg-cell class="width-100"></clr-dg-cell>
              <clr-dg-cell *ngFor="let participantAnalysisColumn of (responseData[0]?.participantAnalysisList || []);let j = index"
                           [hidden]="!skillGroupSelected?.includes(participantAnalysisColumn.skillGroupName)" class="width-175"></clr-dg-cell>
            </clr-dg-row>
            <clr-dg-row *ngFor="let response of responseData; let i = index">
              <clr-dg-cell class="width-120">
                <clr-icon (click)="onShowParticipantDetails(response)" shape="eye"></clr-icon>
                <clr-icon (click)="onResetRoleMap(response.participantId,i)" *hasAccess="functionality.analysis"
                          class="left-margin"
                          shape="undo"></clr-icon>
                <clr-icon (click)="onShowAnswers(response)" class="left-margin" shape="pop-out"></clr-icon>
                <clr-icon *ngIf="response.visited" class="left-margin" shape="check" style="color:#2e8500"></clr-icon>
              </clr-dg-cell>
              <clr-dg-cell *ngFor="let column of responseColumnSettings;" class="width-175"
                           [attr.name]="column?.responseColumnDisplay?.questionName+i"
                           [ngClass]="(pinnedColumn == column.responseColumnDisplay.questionName) ? 'datagrid-row-sticky' : ''">
                {{analysisService.getAnswerFromParticipant(response.responseColumnWithParticipants, column?.responseColumnDisplay?.id)}}
              </clr-dg-cell>
              <clr-dg-cell class="width-220" [attr.name]="'Role Profile'+i">
                <span>
                  <clr-select-container class="clr-col-10" style="margin-top: 0;display: inline-block;">
                    <select (change)="changeDeltaValues(response.participantId,$event.target.value)"
                            class="select-dropdown" clrSelect
                            name="options">
                      <option
                        [selected]="response.participantAnalysisList[0]?.roleMapAnalysisList[0]?.participantRoleProfile?.recommendedKsaRoleProfile === null"
                        disabled>select role profile
                      </option>
                      <option *ngFor="let roleProfile of ksaSet"
                              [selected]="response.participantAnalysisList[0]?.roleMapAnalysisList[0]?.participantRoleProfile?.recommendedKsaRoleProfile?.id === roleProfile.id &&
                            response.participantAnalysisList[0]?.roleMapAnalysisList[0]?.participantRoleProfile?.recommendedKsaRoleProfile?.id"
                              value="{{roleProfile.id}}">
                        {{roleProfile.ksaRoleProfileName}}
                      </option>
                    </select>
                  </clr-select-container>
                </span>
                <span>
                  <clr-tooltip class="clr-col-2 tooltip tooltip-xs tooltip-top-left toolTip-icon">
                    <clr-icon badge="success" clrTooltipTrigger shape="info-circle"></clr-icon>
                    <clr-tooltip-content>
                      {{response?.participantAnalysisList[0]?.roleMapAnalysisList[0]?.participantRoleProfile?.recommendedKsaRoleProfile?.ksaRoleProfileName}}
                    </clr-tooltip-content>
                  </clr-tooltip>
                </span>
              </clr-dg-cell>
              <clr-dg-cell class="width-100" [attr.name]="'Role Alignment'+i">
                <apx-chart [chart]="chartOptions?.chart" [labels]="chartOptions?.labels"
                           [plotOptions]="chartOptions?.plotOptions" [series]=[chartOptionsList[i]?.series]>
                </apx-chart>
                <div class="roleAlignmentAverage-cell-alignment">
                  {{response.roleAlignmentAverage}}%
                </div>
              </clr-dg-cell>
              <clr-dg-cell *ngFor="let participantAnalysisColumn of response?.participantAnalysisList;let j = index"
                           [attr.name]="participantAnalysisColumn?.skill?.skillName+i"
                           [hidden]="!skillGroupSelected?.includes(participantAnalysisColumn.skillGroupName)"
                           [style.background]="participantAnalysisColumn.tableBackgroundColorCode"
                           [style.color]="participantAnalysisColumn.tableFontColorCode" class="width-175">
                {{participantAnalysisColumn.roleMapAnalysisList[0].deltaValue === null ? 'NA'
                : (participantAnalysisColumn.roleMapAnalysisList[0].deltaValue + '%')}}
              </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
              <clr-dg-column-toggle>
                <clr-dg-column-toggle-title class="column-toggle-title">Columns
                  <clr-icon (click)="onSaveColumnSettings()" class="save-col-icon" role="button" shape="floppy"
                            title="Save"></clr-icon>
                </clr-dg-column-toggle-title>
              </clr-dg-column-toggle>
              <clr-dg-pagination #pagination [clrDgPageSize]="pageSizeService.getPageSize() | async" [clrDgTotalItems]="total" [(clrDgPage)]="page">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Responses per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} responses
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
        </div>
      </div>
    </div>

    <color-picker *ngIf="isColorModelOpen" [options]="options" [colorData]="this.colorData" [ksaId]="ksaId"
                  [isColorModalOpen]="isColorModelOpen" [dataGridType]="dataGridType" (addColorToTable)="addColor($event)"
                  [successMsgTimeout]="this.successMsgTimeout" (closeColorPicker)="onHideColorModal()"
                  (deleteColorFromTable)="colorData.splice($event,1)"></color-picker>

    <div *ngIf="isParticipantDetails">
      <app-participant-details [ksaId]="ksaId" [participantData]="participantData"
                               (isParticipantDetails)="onShowParticipant($event)"></app-participant-details>
    </div>

    <clr-modal (clrModalOpenChange)="onCancelSetChange()" [(clrModalOpen)]="showSetChangeMessage">
      <h3 class="modal-title">
        {{'analysisAndReports.reports.roleMap.setChangeWarning' | translate}}
      </h3>
      <div class="modal-body">
        {{'analysisAndReports.reports.roleMap.setChangeCondition' | translate}}
      </div>
      <div class="modal-footer">
        <button (click)="onCancelSetChange()" class="btn btn-outline btn-sm">
          {{'admin.cancelBtn' | translate}}
        </button>
        <button (click)="changeSet()" class="btn btn-primary btn-sm">
          {{"admin.okBtn" | translate}}
        </button>
      </div>
    </clr-modal>
