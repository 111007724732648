/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Status} from '../../../../../enums/status.enum';
import {SurveyControls} from '../../../../../enums/survey-controls.enum';
import {AnswerLabel, MasterSurveyQuestion} from '../../../../../models/master-survey-questions.model';
import {
  MasterSurveySection,
  ValidateQuestionDisplayLogicResponse
} from '../../../../../models/master-survey-section.model';
import {MasterSurvey} from '../../../../../models/master-survey.model';
import {MasterSurveyService} from '../../../../../services/master-survey.service';
import {DescriptionTextComponent} from './description-text/description-text.component';
import {FreeTextComponent} from './free-text/free-text.component';
import {MatrixTableComponent} from './matrix-table/matrix-table.component';
import {MultipleChoiceComponent} from './multiple-choice/multiple-choice.component';
import {RadioButtonComponent} from './radio-button/radio-button.component';
import {ScaleComponent} from './scale/scale.component';
import {ShareSurveyDataService} from '../../../../../services/share-survey-data.service';
import {SelectRoleProfileComponent} from "./select-role-profile/select-role-profile.component";
import {QuestionType} from '../../../../../enums/questionType.enum';
import {Image} from '../../../../../models/image.model';
import {SkipType} from '../../../../../enums/skip-type.enum';
import {SurveyQuestionImport} from '../../../../../models/survey-import-questions.model';
import {ImportQuestionFileService} from '../../../../../services/import-question-file.service';
import {MasterSurveyManagerService} from "../master-survey-manager.service";
import {ImageUploadValidationService} from "../../../../../services/image-upload-validation.service";
import {SurveyTemplateConfigVersion} from '../../../../../models/config-version.model';
import {NotificationDialogAction} from '../../../../../common/notification-dialog/notification-dialog-action.model';
import {
  NotificationDialogActionType
} from '../../../../../common/notification-dialog/notification-dialog-action-type.constants';
import {ClrNotificationDialogService} from '../../../../../common/notification-dialog/clr-notification-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../../../services/user.service';
import {Functionality} from '../../../../../enums/functionality.enum';

@Component({
  selector: 'app-mng-section',
  templateUrl: './mng-section.component.html',
  styleUrls: ['./mng-section.component.scss']
})
export class MngSectionComponent implements OnInit {
  @Output() sectionSelected: EventEmitter<any> = new EventEmitter();
  @Output() moveSection: EventEmitter<any> = new EventEmitter();
  @Input() sectionData!: MasterSurveySection;
  @Input() surveySections!: MasterSurveySection[];
  @Input() surveyTemplateConfigVersion: SurveyTemplateConfigVersion;
  @Input() isEdit!: boolean;
  @Input() isClone!: boolean;
  @Input() isNewVersion!: boolean;
  @Input() isAdd!: boolean;
  @ViewChild('viewContainerReference', {static: true, read: ViewContainerRef}) VCR: ViewContainerRef;
  selectedImage: File;
  imageURL: string = '';
  receivedImageData: Image;
  base64Data: any;
  convertedImage: any;
  isPanelOpen: boolean = false;
  showDeleteSectionPopUp: boolean = false;
  showImageUploadPopUp: boolean = false;
  showImageDeletePopUp: boolean = false;
  showDisplayLogicPopUp: boolean = false;
  showSectionTypePopUp: boolean = false;
  showMandatoryTypePopUp: boolean = false;
  showDuplicateSectionError: boolean = false;
  serverUploadError: boolean = false;
  componentsReferences = Array<ComponentRef<any>>();
  subscriptions = [];
  showImportQuestionModal: boolean = false;
  publishedSurveys: MasterSurvey[];
  statusEnum: typeof Status = Status;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  selected: any = [];
  sectionListOfSelectedSurvey: MasterSurveySection[];
  sectionName: string = '';
  counter: number = 0;
  questionListOfSelectedSection: MasterSurveyQuestion[] = [];
  selectedSection: MasterSurveySection;
  isPreviewData: boolean;
  showDisplayAnswerLabel: boolean;
  displayLogicToShow = this.defaultDisplayLogicMessage();
  questionTypeEnum: typeof QuestionType = QuestionType;
  skipEnum: typeof SkipType = SkipType;
  isUploadImageInvalid: boolean = false;
  imageInvalidErrorMsg: string = '';
  serverUploadErrorMsg: string = '';
  showSpinnerImportQuestion: boolean = false;
  @Input() isImportSection: boolean;
  errorOnDisplayLogicItemDelete: boolean = false;
  dependentSection: string = '';
  dependentQuestion: string = '';
  showDisplayMessage: boolean;
  dependentSectionDisplayLogic: string = '';
  originalMandatorySelection: boolean;
  originalSectionTypeSelection: boolean;
  revertMandatoryChange: boolean = true;
  revertTypeChange: boolean = true;
  showUploadQuestionFile: boolean = false;
  listOfImportedSectionQuestions: MasterSurveyQuestion[];
  csvFileData: SurveyQuestionImport[];
  errorOnUploadFileQuestion: string = '';
  @ViewChild('fileInput')
  fileInputQuestion: ElementRef;
  selectedFileName: string = '';
  hasWritePermission: boolean;
  private selectedSurvey: MasterSurvey;

  constructor(private readonly masterService: MasterSurveyService,
              private readonly componentFactoryResolver: ComponentFactoryResolver,
              private readonly shareSurveyData: ShareSurveyDataService,
              private readonly importQuestionFileService: ImportQuestionFileService,
              private readonly masterSurveyManagerService: MasterSurveyManagerService,
              private readonly imageUploadValidationService: ImageUploadValidationService,
              private readonly notificationDialogService: ClrNotificationDialogService,
              private readonly translate: TranslateService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.manageSurveyTemplate);
    this.sortSectionQuestions();
    this.isPreviewDataAvailable();
    this.initializeQuestionData();
    if (this.isAdd && this.shareSurveyData.getIsAddMasterSurvey() && !this.isImportSection) {
      this.sectionData.sectionQuestions = new Array<MasterSurveyQuestion>();
    }
    this.receivedImageData = null;
    this.sectionData.sectionQuestions.forEach(question => {           //This code is for request for survey template else will give 400 bad request
      question.sectionSkipConditions = [];
    });

    if (this.sectionData.section.sectionName != '') {
      this.onInitializeSectionEdit(this.sectionData);
    }
    if (this.sectionData.sectionSkipCondition == null) {
      this.setDefaultSkipCondition();
    }
    this.sectionData.isSectionNameRequiredError = false;
    this.sectionData.isSectionNamePatternError = this.checkSectionNamePatternValidation();
  }

  sortSectionQuestions(): void {
    this.sectionData?.sectionQuestions?.sort((previousQuestion, nextQuestion) =>
      (previousQuestion.sectionQuestionSequenceNo > nextQuestion.sectionQuestionSequenceNo ? 1 : -1));
  }

  setDefaultSkipCondition(): void {
    this.sectionData.sectionSkipCondition = {
      answerLabelSequenceNo: undefined,
      colAnswerLabelSequenceNo: undefined,
      displayValue: undefined,
      questionSequenceNo: undefined,
      sectionSequenceNo: undefined,
      skipCondition: {
        id: undefined
      },
      tempSectionId: null,
      tempSurveyQuestionId: null
    }
  }

  isPreviewDataAvailable(): void {
    this.shareSurveyData.getSurvey().subscribe(survey => {
      this.isPreviewData = !!survey;
    })
  }

  onSectionNameInput(sectionName: string): void {
    this.onCheckUpdateStatus();
    let counter = 0;

    this.showDuplicateSectionError = false;
    this.sectionData.isSectionNameRequiredError = false;
    this.sectionData.section.sectionName = sectionName;

    if (this.sectionData.section.sectionName) {
      if (!this.masterSurveyManagerService.isSurveySectionNameUnique(this.sectionData.section.sectionName)) {
        this.showDuplicateSectionError = true;
        this.sectionData.isSectionNameRequiredError = true;
      }
      this.surveySections.forEach(section => {
        if (section.section.sectionName == this.sectionData.section.sectionName) {
          counter++;
          if (counter > 1) {
            this.showDuplicateSectionError = true;
            this.sectionData.isSectionNameRequiredError = true;
          }
        }
      });
    }

    this.sectionData.isSectionNamePatternError = this.checkSectionNamePatternValidation();
  }

  isUpArrayHidden(section: MasterSurveySection): boolean {
    return section.sectionSequenceNo == 1;

  }

  isDownArrayHidden(section: MasterSurveySection): boolean {
    return this.surveySections.length == section.sectionSequenceNo;

  }

  onTechnicalSectionInput(value: boolean): void {
    this.sectionData.isTechnicalSection = value;
  }

  onMandatorySectionInput(value: boolean): void {
    this.sectionData.isMandatorySection = value;
  }

  onImageSelect(event: any): void {
    this.selectedImage = event.target.files[0];
    this.serverUploadError = false;

    if (this.imageUploadValidationService.validateUploadImageFile(this.selectedImage)) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.imageURL = reader.result as string;
      };
      this.isUploadImageInvalid = false;
      this.imageInvalidErrorMsg = '';
    } else {
      this.imageInvalidErrorMsg = this.imageUploadValidationService.extractErrorMsgFromInvalidFile(this.selectedImage);
      this.isUploadImageInvalid = true;
      this.selectedImage = null;
      this.imageURL = '';
    }
  }

  resetUploadFilePopUpMsg(): void {
    this.showImageUploadPopUp = false;
    this.isUploadImageInvalid = false;
  }

  isUploadButtonDisabled(): boolean {
    return this.isUploadImageInvalid || !this.selectedImage;
  }

  onUploadImage(): void {
    const uploadData = new FormData();
    uploadData.append(this.surveyControlsEnum.imageFile, this.selectedImage, this.selectedImage.name);
    this.masterService.uploadImage(uploadData).subscribe(
      res => {
        this.receivedImageData = res;
        this.base64Data = this.receivedImageData.imageData;
        this.convertedImage = ImageUploadValidationService.IMAGE_BASE_64 + this.base64Data;
        this.sectionData.image = this.receivedImageData;
        this.showImageUploadPopUp = false;
      },
      err => {
        this.serverUploadError = true;
        this.serverUploadErrorMsg = err.error.message;
      }
    )
  }

  onInitializeSectionEdit(section: MasterSurveySection): void {
    this.sectionData.section.id = section.section.id;
    this.sectionData.isTechnicalSection = section.isTechnicalSection;
    this.sectionData.isMandatorySection = section.isMandatorySection;
    this.sectionData.section.sectionName = section.section.sectionName;

    this.sectionName = section.section.sectionName;
    if (section.image) {
      this.receivedImageData = section.image;
      this.base64Data = this.receivedImageData.imageData;
      this.convertedImage = ImageUploadValidationService.IMAGE_BASE_64 + this.base64Data;
      this.sectionData.image = section.image;
    } else {
      this.convertedImage = null;
    }

    if (section.sectionSkipCondition?.conditionMasterSurveySection?.tempSectionId) {
      const tempSectionId = section.sectionSkipCondition?.conditionMasterSurveySection?.tempSectionId;
      const tempSurveyQuestionId = section.sectionSkipCondition?.conditionSectionQuestion?.tempSurveyQuestionId;

      if (section.sectionSkipCondition?.answerLabel) {
        this.sectionData.sectionSkipCondition.answerLabelSequenceNo = section.sectionSkipCondition?.answerLabel.answerLabelSequenceNo;
      }
      if (section.sectionSkipCondition?.colAnswerLabel) {
        this.sectionData.sectionSkipCondition.colAnswerLabelSequenceNo = section.sectionSkipCondition?.colAnswerLabel.answerLabelSequenceNo;
      }
      this.sectionData.sectionSkipCondition.tempSectionId = tempSectionId;
      this.sectionData.sectionSkipCondition.tempSurveyQuestionId = tempSurveyQuestionId;
    }
    if (this.sectionData.sectionSkipCondition?.tempSectionId) {
      this.onUpdateDisplay(true);
    } else {
      this.onUpdateDisplay(false);
    }

    [...this.sectionData.sectionQuestions] = [...section.sectionQuestions];
  }

  getQuestionTypeName(questionType: number) {
    let questionName;
    switch (questionType) {
      case this.questionTypeEnum.FreeText: {
        questionName = FreeTextComponent;
        break;
      }
      case this.questionTypeEnum.RadioButton: {
        questionName = RadioButtonComponent;
        break;
      }
      case this.questionTypeEnum.MultipleChoice: {
        questionName = MultipleChoiceComponent;
        break;
      }
      case this.questionTypeEnum.Scale: {
        questionName = ScaleComponent;
        break;
      }
      case this.questionTypeEnum.Description: {
        questionName = DescriptionTextComponent;
        break;
      }
      case this.questionTypeEnum.MatrixTable: {
        questionName = MatrixTableComponent;
        break;
      }
      case this.questionTypeEnum.RoleProfile: {
        questionName = SelectRoleProfileComponent;
        break;
      }
      default: {
        throw new Error(`Unhandled question case.`);
      }
    }
    return questionName;
  }

  initializeQuestionOnView(questions: MasterSurveyQuestion[]): void {
    questions.forEach((question, index) => {
      if (question) {
        const questionType = question.question.questionType.id;
        this.initializeDynamicComponent(index, question,
          this.componentFactoryResolver.resolveComponentFactory(this.getQuestionTypeName(questionType)));
      }
    });
  }

  initializeDynamicComponent(index: number, questionData: MasterSurveyQuestion, componentFactory: ComponentFactory<any>): void {
    setTimeout(() => {
      let isDisable: boolean;
      const questionReference = this.VCR.createComponent(componentFactory);
      const questionComponent = questionReference.instance;
      questionComponent.questionSequence = ++this.counter;

      questionComponent.questionData = questionData;
      if (questionData.isImported) {
        isDisable = true;
      } else if (questionData.isImportedCsv) {
        isDisable = false;
      } else {
        this.sectionData.sectionQuestions[index] = questionData;
        isDisable = false;
      }
      questionComponent.isDisable = isDisable;
      this.subscriptions.push(questionComponent.move.subscribe((shift: number) => {
        this.moveQuestion(shift, questionReference);
      }));
      questionComponent.sectionReference = this;
      this.componentsReferences.push(questionReference);
    }, 1000);
  }

  onRemoveSection(section: MasterSurveySection): void {
    this.onCheckUpdateStatus();
    this.dependentSectionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.validateSectionDisplayLogic(section);
    if (validateQuestionDisplayLogicResponse.isValidQuestion) {
      this.sectionSelected.emit(section);
      this.showDeleteSectionPopUp = false;
    } else {
      this.showDeleteSectionPopUp = true;
      this.errorOnDisplayLogicItemDelete = true;
      this.dependentSectionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
        this.dependentSection = '';
        this.dependentQuestion = '';
      }, 5000);
    }
  }

  validateSectionDisplayLogic(sectionDetails: MasterSurveySection): ValidateQuestionDisplayLogicResponse {
    let validateSectionDisplayLogicResponse: ValidateQuestionDisplayLogicResponse = {
      isValidQuestion: true,
      dependentQuestion: undefined,
      dependentSection: ''
    }
    this.shareSurveyData.getSurvey().subscribe(survey => {
      survey?.surveySections?.some(section => {
        if (sectionDetails.tempSectionId != section.tempSectionId) {
          if (section.sectionSkipCondition?.skipCondition.id) {
            if (section.sectionSkipCondition.tempSectionId === sectionDetails.tempSectionId) {
              validateSectionDisplayLogicResponse.isValidQuestion = false;
              validateSectionDisplayLogicResponse.dependentSection = this.surveyControlsEnum.section + section.section.sectionName;
              return;
            }
          }
        }

      });
      if (validateSectionDisplayLogicResponse.isValidQuestion) {
        validateSectionDisplayLogicResponse = this.validateQuestionSectionDelete(sectionDetails, survey);
      }
    });
    return validateSectionDisplayLogicResponse;
  }

  validateQuestionSectionDelete(section: MasterSurveySection, survey: MasterSurvey): ValidateQuestionDisplayLogicResponse {
    const validateSectionDisplayLogicResponse = {
      isValidQuestion: true,
      dependentQuestion: undefined,
      dependentSection: ''
    }
    survey.surveySections.some(sectionData => {
      if (section.tempSectionId != sectionData.tempSectionId) {
        sectionData.sectionQuestions.some(questionData => {
          if (questionData.questionDisplayCondition?.skipCondition.id) {
            if (questionData.questionDisplayCondition.tempSectionId == section.tempSectionId) {
              validateSectionDisplayLogicResponse.isValidQuestion = false;
              validateSectionDisplayLogicResponse.dependentSection =
                this.surveyControlsEnum.question + questionData.question.questionText;
              return;
            }
          }
        });
      }
    });
    return validateSectionDisplayLogicResponse;
  }

  onDeleteImageSection(): void {
    this.onCheckUpdateStatus();
    this.imageURL = '';
    this.convertedImage = null;
    this.showImageDeletePopUp = false;
    this.receivedImageData = null;
    this.sectionData.image = undefined;
  }

  onImportQuestion(): void {
    this.selectedSurvey = null;
    this.publishedSurveys = [];
    this.showImportQuestionModal = true;
    this.showSpinnerImportQuestion = true;
    this.masterService.getMasterSurvey().subscribe(
      survey => {
        survey.forEach(survey => {
          if (survey.status == this.statusEnum.Published) {
            this.publishedSurveys.push(survey);
          }
        });
        this.showSpinnerImportQuestion = false;
      });
  }

  onSelectPublishedSurvey(surveyId: number): void {
    this.sectionListOfSelectedSurvey = [];
    for (const publishedSurvey of this.publishedSurveys) {
      if (publishedSurvey.id == surveyId) {
        this.sectionListOfSelectedSurvey = publishedSurvey.surveySections;
        this.selectedSurvey = publishedSurvey;
      }
    }
    this.questionListOfSelectedSection = [];
  }

  onSelectSection(section: MasterSurveySection): void {
    this.selectedSection = section;
    this.questionListOfSelectedSection = [];
    if (section?.sectionQuestions) {
      section.sectionQuestions.forEach(question => {
        if (question.question.questionType.id != 5) {
          this.questionListOfSelectedSection.push(question);
        }
      });
    }
  }

  onAddImportedQuestions(questionsImported: MasterSurveyQuestion[]): void {
    const hasSkills = questionsImported.some(e => e.skill);
    if (!hasSkills || this.isSameSurveyConfigVersion()) {
      this.addImportedQuestions(questionsImported);
    } else {
      this.showDifferentSurveyConfigTemplateDialog(questionsImported);
    }
  }

  onCancelImportQuestion(): void {
    this.selectedSurvey = null;
    this.showImportQuestionModal = false;
    this.sectionListOfSelectedSurvey = [];
    this.questionListOfSelectedSection = [];
  }

  findMaxQuestionId(): number {
    const tempSurveyQuestionIds: number[] = this.surveySections?.flatMap(section => section.sectionQuestions)
      .map(sectionQuestion => sectionQuestion.tempSurveyQuestionId);
    return tempSurveyQuestionIds?.length > 0 ? Math.max(...tempSurveyQuestionIds) : 0;
  }

  onCreateQuestion(questionType): void {
    this.onCheckUpdateStatus();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.getQuestionTypeName(questionType));
    this.createDynamicComponent(componentFactory);
  }

  createDynamicComponent(componentFactory: ComponentFactory<any>): void {
    const questionReference = this.VCR.createComponent(componentFactory);
    const questionComponent = questionReference.instance;
    questionComponent.questionSequence = ++this.counter;
    this.subscriptions.push(questionComponent.move.subscribe((shift: number) => {
      this.moveQuestion(shift, questionReference);
    }));
    this.sectionData.sectionQuestions.push(questionComponent.questionData);
    questionComponent.sectionReference = this;
    this.componentsReferences.push(questionReference);
  }

  moveQuestion(shift: number, componentRef: ComponentRef<any>): void {
    const currentIndex = this.VCR.indexOf(componentRef.hostView);
    const len = this.VCR.length;
    let destinationIndex = currentIndex + shift;

    let currentQuestion = this.sectionData.sectionQuestions[currentIndex];
    let destinationQuestion = this.sectionData.sectionQuestions[destinationIndex];

    if (destinationIndex === len) {
      destinationIndex = 0;
    }

    if (destinationIndex === -1) {
      destinationIndex = len - 1;
    }

    if (shift == 1) {
      [currentQuestion, destinationQuestion] = [destinationQuestion, currentQuestion];
      currentQuestion.sectionQuestionSequenceNo = [destinationQuestion.sectionQuestionSequenceNo, destinationQuestion.sectionQuestionSequenceNo = currentQuestion?.sectionQuestionSequenceNo][0];
      [this.componentsReferences[currentIndex], this.componentsReferences[destinationIndex]] = [this.componentsReferences[destinationIndex], this.componentsReferences[currentIndex]];
    } else {
      [destinationQuestion, currentQuestion] = [currentQuestion, destinationQuestion];
      destinationQuestion.sectionQuestionSequenceNo = [currentQuestion.sectionQuestionSequenceNo, currentQuestion.sectionQuestionSequenceNo = destinationQuestion?.sectionQuestionSequenceNo][0];
      [this.componentsReferences[destinationIndex], this.componentsReferences[currentIndex]] = [this.componentsReferences[currentIndex], this.componentsReferences[destinationIndex]];
    }

    let sectionQuestionSequence = 0;
    this.componentsReferences?.forEach((reference) => {
      sectionQuestionSequence++;
      if (reference.instance.questionData) {
        reference.instance.questionData.controls.sectionQuestionSequenceNo.value = sectionQuestionSequence;
        reference.instance.questionData.value.sectionQuestionSequenceNo = sectionQuestionSequence;
      }
    });

    this.VCR.move(componentRef.hostView, destinationIndex);
    this.sortSectionQuestions();
  }

  removeQuestion(question: any): void {
    const key = question.value.sectionQuestionSequenceNo;
    if (this.VCR.length < 1) return;
    const componentRef = this.componentsReferences.filter(
      reference => {
        return reference.instance?.questionData?.value?.sectionQuestionSequenceNo == key;
      }
    )[0];
    const vcrIndex: number = this.VCR.indexOf(componentRef.hostView);
    this.VCR.remove(vcrIndex);
    const index = this.sectionData.sectionQuestions.findIndex(function (questionSurvey) {
      return questionSurvey?.sectionQuestionSequenceNo === key;
    });
    if (index !== -1) this.sectionData.sectionQuestions.splice(index, 1);
    for (let index = 0; index < this.sectionData.sectionQuestions.length; index++) {
      if (this.sectionData.sectionQuestions[index]?.sectionQuestionSequenceNo) {
        this.sectionData.sectionQuestions[index].sectionQuestionSequenceNo = index + 1;
      }
    }
    this.counter--;
    let referenceIndex = 0;
    this.componentsReferences.forEach((reference, index) => {
      if (reference.instance?.questionData?.controls?.sectionQuestionSequenceNo?.value == key) {
        index = referenceIndex;
        reference.instance.questionData = null;
      } else {
        referenceIndex++;
        reference.instance.questionData.controls.sectionQuestionSequenceNo.value = referenceIndex;
        reference.instance.questionData.value.sectionQuestionSequenceNo = referenceIndex;
      }
    });
    this.componentsReferences.splice(index, 1);
    this.sortSectionQuestions();
    this.sectionData.sectionQuestions = this.sectionData.sectionQuestions.filter(question => {
      return question !== undefined
    });
  }

  checkSectionNamePatternValidation(): boolean {
    return !this.sectionData.section?.sectionName.match(/^[^!@#*&(){}<>\/;`%$^:?]*$/);
  }

  onCheckUpdateStatus(): void {
    this.masterService.setSurveyUpdateStatus(false);
  }

  private addImportedQuestions(questionsImported: MasterSurveyQuestion[]): void {
    let newMaxTempQuestionId = this.findMaxQuestionId();
    let countOfLastQuestion = this.sectionData.sectionQuestions.length;
    const questionsToAdd = [];
    questionsImported.forEach(question => {
      delete question.id;
      question.isImported = true;
      question.isPageBreakAdded = null;
      question.questionDisplayCondition = null;
      question.questionSkipCondition = null;
      question.sectionSkipConditions = [];
      question.sectionQuestionSequenceNo = ++countOfLastQuestion;
      question.tempSurveyQuestionId = ++newMaxTempQuestionId;
      question.skill = this.isSameSurveyConfigVersion() ? question.skill : null;
      this.sectionData.sectionQuestions.push(question);
      questionsToAdd.push(question);
    });
    this.initializeQuestionOnView(questionsToAdd);
    this.sortSectionQuestions();
    this.questionListOfSelectedSection = [];
    this.sectionListOfSelectedSurvey = [];
    this.showImportQuestionModal = false;
    this.masterService.setSurveyUpdateStatus(false);
  }

  onUpdateDisplay(data: boolean): void {
    this.showDisplayMessage = data;
    if (this.showDisplayMessage === true) {
      this.surveySections.forEach(section => {
        if (section.tempSectionId == this.sectionData.sectionSkipCondition.tempSectionId) {
          section.sectionQuestions.forEach(question => {
            if (question.tempSurveyQuestionId == this.sectionData.sectionSkipCondition.tempSurveyQuestionId) {
              this.displayLogicToShow.questionText = question.question.questionText;
              this.displayLogicToShow.questionTypeId = question.question.questionType.id;

              if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.RadioButton ||
                this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MultipleChoice) {
                if (this.sectionData.sectionSkipCondition.answerLabel) {
                  this.displayLogicToShow.answerLabel = this.sectionData.sectionSkipCondition.answerLabel.labelText;
                } else if (this.sectionData.sectionSkipCondition.answerLabelSequenceNo) {
                  const labels: AnswerLabel[] = question.question.answerLabels;
                  labels.forEach(answer => {
                    if (answer.answerLabelSequenceNo == this.sectionData.sectionSkipCondition.answerLabelSequenceNo) {
                      this.displayLogicToShow.answerLabel = answer.labelText;
                    }
                  });
                }
              }

              if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MatrixTable) {
                const labels: AnswerLabel[] = question.question.answerLabels;
                const rowAnswers: AnswerLabel[] = [];
                const colAnswers: AnswerLabel[] = [];

                labels.forEach(label => {
                  if (label.isRow) {
                    rowAnswers.push(label);
                  } else {
                    colAnswers.push(label);
                  }
                });

                if (this.sectionData.sectionSkipCondition.answerLabel) {
                  this.displayLogicToShow.answerLabel = this.sectionData.sectionSkipCondition.answerLabel.labelText;
                } else if (this.sectionData.sectionSkipCondition.answerLabelSequenceNo) {
                  rowAnswers.forEach(answer => {
                    if (answer.answerLabelSequenceNo == this.sectionData.sectionSkipCondition.answerLabelSequenceNo) {
                      this.displayLogicToShow.answerLabel = answer.labelText;
                    }
                  });
                }

                if (this.sectionData.sectionSkipCondition.colAnswerLabel) {
                  this.displayLogicToShow.colAnswerLabel = this.sectionData.sectionSkipCondition.colAnswerLabel.labelText;
                } else if (this.sectionData.sectionSkipCondition.colAnswerLabelSequenceNo) {
                  colAnswers.forEach(answer => {
                    if (answer.answerLabelSequenceNo == this.sectionData.sectionSkipCondition.colAnswerLabelSequenceNo) {
                      this.displayLogicToShow.colAnswerLabel = answer.labelText;
                    }
                  });
                }

              }
            }
          });
          this.displayLogicToShow.displayValue = this.sectionData.sectionSkipCondition.displayValue;
          this.masterService.getAllSkipConditions().subscribe(data => {
            data.forEach(condition => {
              if (condition.id == this.sectionData.sectionSkipCondition.skipCondition.id)
                this.displayLogicToShow.skipConditionName = condition.skipConditionName;
            });
          });
        }
      });
    }
  }

  defaultDisplayLogicMessage() {
    return {
      questionText: '',
      skipConditionName: '',
      answerLabel: '',
      colAnswerLabel: '',
      displayValue: undefined,
      questionTypeId: undefined
    }
  }

  openMandatorySectionPopup(): void {
    this.revertMandatoryChange = true;
    this.showMandatoryTypePopUp = true;
    this.originalMandatorySelection = this.sectionData.isMandatorySection;
  }

  onCancelMandatorySelection(): void {
    if (this.revertMandatoryChange) {
      this.showMandatoryTypePopUp = false;
      this.sectionData.isMandatorySection = this.originalMandatorySelection;
    }
  }

  openSectionTypePopup(): void {
    this.revertTypeChange = true;
    this.showSectionTypePopUp = true;
    this.originalSectionTypeSelection = this.sectionData.isTechnicalSection;
  }

  onCancelSectionTypeSelection(): void {
    if (this.revertTypeChange) {
      this.showSectionTypePopUp = false;
      this.sectionData.isTechnicalSection = this.originalSectionTypeSelection;
    }
  }

  onImportQuestionFile(): void {
    this.showUploadQuestionFile = true;
    this.errorOnUploadFileQuestion = '';
    this.csvFileData = [];
    this.selectedFileName = '';
  }

  onImportQuestionSelectFile(event): void {
    this.errorOnUploadFileQuestion = '';
    this.csvFileData = [];
    this.selectedFileName = '';
    this.listOfImportedSectionQuestions = [];
    const selectedFile = event.target.files[0];
    this.selectedFileName = selectedFile.name;
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile);
    fileReader.onload = (event) => {
      const extention = selectedFile.name.split(".").pop();
      if (extention === this.surveyControlsEnum.csv) {
        const records = (fileReader.result as string).split(/\r\n|\n/);
        const headers = this.getHeaderArray(records);
        const mandatoryHeaders = ['ID', 'Details', 'QuestionType', 'SectionName'];
        const checkHeaders = (mandatoryHeaderArray, allHeaders) => allHeaders.every(header => mandatoryHeaderArray.includes(header));
        if (checkHeaders(headers, mandatoryHeaders)) {
          this.csvFileData = this.getRecordsFromImportCsvFile(records, headers.length);
        } else {
          this.errorOnUploadFileQuestion = this.surveyControlsEnum.MissingHeader;
        }
      } else {
        this.errorOnUploadFileQuestion = this.surveyControlsEnum.InvalidCsvFile;
      }
    }
  }

  getHeaderArray(csvRecordsArr: string[]): string[] {
    const headers = (csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (const header in headers) {
      headerArray.push(header);
    }
    return headerArray;
  }

  getRecordsFromImportCsvFile(records: string[], headerLength: number): SurveyQuestionImport[] {
    const csvArr = [];
    for (let i = 1; i < records.length; i++) {
      const currentRecord = (records[i]).split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/); // if question contains comma(,) then question text will add by double quotes("question text")
      if (currentRecord.length == headerLength) {
        const csvRecord: SurveyQuestionImport = new SurveyQuestionImport();
        csvRecord.sequenceNo = i;
        csvRecord.id = currentRecord[0].split("_")[0];
        csvRecord.name = currentRecord[0].split("_")[1];
        csvRecord.details = currentRecord[1].trim();
        csvRecord.type = currentRecord[2].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  onOkImportFileQuestion(): void {
    this.errorOnUploadFileQuestion = '';
    if (this.csvFileData) {
      const response = this.importQuestionFileService.validateCsvQuestions(this.csvFileData);
      if (response.isValidQuestions) {
        this.listOfImportedSectionQuestions = this.importQuestionFileService.mapSurveyCSVQuestion(this.csvFileData);
        this.onAddImportedQuestionsFile(this.listOfImportedSectionQuestions);
        this.showUploadQuestionFile = false;
        this.listOfImportedSectionQuestions = [];
        this.csvFileData = [];
      } else {
        this.showUploadQuestionFile = true;
        this.errorOnUploadFileQuestion = response.errors;
      }
    }
    this.fileInputQuestion.nativeElement.value = "";
  }

  onCancelFileSectionImport(): void {
    this.showUploadQuestionFile = false;
    this.fileInputQuestion.nativeElement.value = "";
    this.selectedFileName = '';
  }

  onAddImportedQuestionsFile(questionsImported: MasterSurveyQuestion[]): void {
    this.onCheckUpdateStatus();
    let newMaxTempQuestionId = this.findMaxQuestionId();
    let countOfLastQuestion = this.sectionData.sectionQuestions.length;
    const questionsToAdd = [];
    questionsImported.forEach(question => {
      question.isImportedCsv = true;
      question.sectionQuestionSequenceNo = ++countOfLastQuestion;
      question.tempSurveyQuestionId = ++newMaxTempQuestionId;
      this.sectionData.sectionQuestions.push(question);
      questionsToAdd.push(question);
    });
    this.initializeQuestionOnView(questionsToAdd);
    this.masterService.setSurveyUpdateStatus(false);
  }

  onAccordionPanelOpen(isPanelOpen: boolean): void {
    this.isPanelOpen = isPanelOpen;
    if (this.isPanelOpen) {
      if (this.sectionData.section.sectionName) {
        this.initializeQuestionOnView(this.sectionData.sectionQuestions);
      }
    } else {
      this.VCR.clear();
    }
  }

  private initializeQuestionData() {
    this.sectionData.sectionQuestions.forEach((questionData, index) => {
      if (questionData) {

        if (questionData.questionDisplayCondition?.conditionMasterSurveySection?.tempSectionId) {
          const tempSectionId = questionData.questionDisplayCondition.conditionMasterSurveySection.tempSectionId;
          const tempSurveyQuestionId = questionData.questionDisplayCondition.conditionSectionQuestion.tempSurveyQuestionId;

          if (questionData.questionDisplayCondition.answerLabel) {
            questionData.questionDisplayCondition.answerLabelSequenceNo = questionData.questionDisplayCondition.answerLabel.answerLabelSequenceNo;
          }
          if (questionData.questionDisplayCondition.colAnswerLabel) {
            questionData.questionDisplayCondition.colAnswerLabelSequenceNo = questionData.questionDisplayCondition.colAnswerLabel.answerLabelSequenceNo;
          }
          questionData.questionDisplayCondition.tempSectionId = tempSectionId;
          questionData.questionDisplayCondition.tempSurveyQuestionId = tempSurveyQuestionId;
        }
      }
    });
  }

  /**
   * This method validates if the source survey template, from which questions are being imported,
   * shares the same template configuration version as the current survey template.
   */
  private isSameSurveyConfigVersion(): boolean {
    return this.selectedSurvey?.surveyTemplateConfigVersion?.id === this.surveyTemplateConfigVersion?.id;
  }

  private showDifferentSurveyConfigTemplateDialog(questionsImported: MasterSurveyQuestion[]): void {
    this.notificationDialogService.show({
      title: this.translate.instant('surveyTemplate.masterSurvey.import.surveyTemplateConfigVersion.title'),
      message: this.translate.instant('surveyTemplate.masterSurvey.import.surveyTemplateConfigVersion.message'),
      listOfActions: [new NotificationDialogAction(NotificationDialogActionType.OK, () => {
        this.addImportedQuestions(questionsImported);
      })],
      rejectAction: new NotificationDialogAction(NotificationDialogActionType.CANCEL, () => {
      })
    });
  }
}
