/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient, HttpEvent} from "@angular/common/http";
import {MasterKsa} from "../models/master-ksa.model";
import {KsaEmailDTO, KsaEmailTemplateDTO} from "../models/ksa-email-dto.model";
import {EmailTemplateTypeEnum} from "../enums/email-template-type.enum";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class KsaEmailManagementService {

  ksaEmail: string;
  ksaAdministrationUrl: string;
  ksaSurvey: string;

  constructor(private http: HttpClient) {
    this.ksaEmail = `${environment.API_URL}${API_KSA_CORE_V1}/ksaemail`;
    this.ksaAdministrationUrl = `${environment.API_URL}${API_KSA_CORE_V1}/masterksa/`;
    this.ksaSurvey = `${environment.API_URL}${API_KSA_CORE_V1}/survey`;
  }

  addKsaEmail(ksaEmail: KsaEmailDTO): Observable<KsaEmailDTO> {
    return this.http.post<KsaEmailDTO>(`${this.ksaEmail}`, ksaEmail);
  }

  addKsaEmailTemplate(ksaEmailId: number, templateType: EmailTemplateTypeEnum, ksaEmailTemplate: KsaEmailTemplateDTO): Observable<KsaEmailDTO> {
    return this.http.post<KsaEmailDTO>(`${this.ksaEmail}/${ksaEmailId}/templates/${templateType}`, ksaEmailTemplate);
  }

  updateKsaEmailTemplate(ksaEmailId: number, ksaEmailTemplate: KsaEmailTemplateDTO): Observable<KsaEmailDTO> {
    return this.http.put<KsaEmailDTO>(`${this.ksaEmail}/${ksaEmailId}/templates`, ksaEmailTemplate);
  }

  deleteKsaEmailTemplate(ksaId: number, templateType: EmailTemplateTypeEnum,): Observable<any> {
    return this.http.delete(`${this.ksaEmail}/${ksaId}/templates/${templateType}`);
  }

  getKsa(filter1, filter2, filter3, from, to, sortby, sortorder): Observable<MasterKsa> {
    filter1 = encodeURIComponent(filter1);
    filter2 = encodeURIComponent(filter2);
    return this.http.get<MasterKsa>(`${this.ksaAdministrationUrl}?ksa_name=${filter1}&geo.geoName=${filter2}&status=${filter3}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`);
  }

  getReminderDetail(ksaid: number): Observable<any> {
    return this.http.get(`${this.ksaEmail}/reminder/getJob?ksaEmailId=${ksaid}`)
  }

  getKsaEnhanced(): Observable<any> {
    return this.http.get<any>(`${this.ksaAdministrationUrl}all/ksa`);
  }

  getTimeZoneScheduler(): Observable<any> {
    return this.http.get<any[]>(`${this.ksaEmail}/reminder/timezones`)
  }

  getKsaEmailDtoByKsaId(ksaId: number): Observable<KsaEmailDTO> {
    return this.http.get<KsaEmailDTO>(`${this.ksaEmail}/masterksa/${ksaId}`)
  }

  sendInviteAll(id: number, ccEmail: Array<string>): Observable<KsaEmailDTO> {
    return this.http.put<KsaEmailDTO>(`${this.ksaEmail}/${id}/invite/email`, ccEmail);
  }

  sendThanksAll(id: number, ccEmail: Array<string>): Observable<KsaEmailDTO> {
    return this.http.put<KsaEmailDTO>(`${this.ksaEmail}/${id}/thanks/email`, ccEmail);
  }

  createScheduleJob(scheduleJob): Observable<any> {
    return this.http.post(`${this.ksaEmail}/reminder/createJob`, scheduleJob);
  }

  updateScheduleJob(scheduleJob): Observable<any> {
    return this.http.post(`${this.ksaEmail}/reminder/editJob`, scheduleJob);
  }

  deleteScheduleJob(jobid, goupid): Observable<any> {
    return this.http.delete(`${this.ksaEmail}/reminder/deleteJob/?jobId=${jobid}&jobGroup=${goupid}`);
  }

  getDemoUrl(id: number): Observable<HttpEvent<string>> {
    const requestOptions = Object.assign(
      {responseType: 'text'}
    );
    return this.http.get<string>(`${this.ksaAdministrationUrl}${id}/demo`, requestOptions);
  }

  getAnonymousUrl(id:number) : Observable<HttpEvent<string>> {
    const requestOptions = Object.assign(
      { responseType: 'text' }
    );
    return this.http.get<string>(`${this.ksaAdministrationUrl}${id}/anonymous`,requestOptions);
  }

  validateAndGetAnonymousId(anonymousDetails: any):  Observable<any>{
    const requestOptions = Object.assign(
      { responseType: 'text' }
    );
    return this.http.post(`${environment.API_URL}${API_KSA_CORE_V1}/survey/anonymous`, anonymousDetails, requestOptions);
  }

  getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  setEmailReminder(id:number) : Observable<HttpEvent<string>> {
    const requestOptions = Object.assign(
      { responseType: 'text' }
    );
    return this.http.get<string>(`${this.ksaAdministrationUrl}${id}/demo`,requestOptions);
  }
}
