import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SurveyControls} from '../../../../../enums/survey-controls.enum';
import {AnswerLabel} from '../../../../../models/master-survey-questions.model';
import {MasterSurveyService} from '../../../../../services/master-survey.service';
import {QuestionType} from "../../../../../enums/questionType.enum";

@Component({
  selector: 'app-skip-logic-pop-up',
  templateUrl: './skip-logic-pop-up.component.html',
  styleUrls: ['./skip-logic-pop-up.component.scss']
})
export class SkipLogicPopUpComponent implements OnInit {
  @Input() questionData: any;
  @Input() showSkipLogicModal:boolean;
  @Input() sectionReference;
  @Output() showSkipLogic: EventEmitter<boolean> = new EventEmitter();
  @Output() onUpdateSkip: EventEmitter<boolean> = new EventEmitter();

  questionSkipTypes: any;
  Survey: string = "Survey";
  Section: string = "Section";
  questionType : string = '';
  answerLabelRows: AnswerLabel[]=[];
  answerLabelColumn: AnswerLabel[]=[];
  selectedSkipTypeSkipLogic:string='';
  selectedAnswerLabelSkipLogic:string='';
  selectedColumnAnswer:string='';
  selectedRowAnswer:string='';
  selectedSkipType:string='';
  skipToAnswerLabelSkipLogic:string='';
  questionTypeEnum: typeof QuestionType = QuestionType;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;

  constructor(private masterService:MasterSurveyService) { }

  ngOnInit(): void {
    this.getQuestionType();
    this.getQuestionSkipTypes();
    if(this.questionData.value.questionSkipCondition){
      this.onInitializationSkipLogic(this.questionData.value.questionSkipCondition);
    }else{
      this.questionData.value.questionSkipCondition = this.createSkipControl();
    }
  }

  onInitializationSkipLogic(questionSkipCondition) {
    if (questionSkipCondition?.skipCondition?.id) {
      this.skipToAnswerLabelSkipLogic=questionSkipCondition.skipType;
      this.selectedSkipType = questionSkipCondition.skipCondition.id;
      if(this.questionType=='Radio' || this.questionType=="Multi-Choice"){
        if(questionSkipCondition.answerLabelSequenceNo){
          this.selectedAnswerLabelSkipLogic = questionSkipCondition.answerLabelSequenceNo;
        }else{
        this.selectedAnswerLabelSkipLogic = questionSkipCondition.answerLabel?.answerLabelSequenceNo;
        }
      }else if(this.questionType=='Scale'){
        this.questionData.value.questionSkipCondition.displayValue = questionSkipCondition.displayValue;
      }else if(this.questionType=='Matrix'){
        if(questionSkipCondition.answerLabelSequenceNo){
          this.selectedRowAnswer = questionSkipCondition.answerLabelSequenceNo;
        }else{
        this.selectedRowAnswer = questionSkipCondition.answerLabel?.answerLabelSequenceNo;
        }
        if(questionSkipCondition.colAnswerLabelSequenceNo){
          this.selectedColumnAnswer = questionSkipCondition.colAnswerLabelSequenceNo;
        }else{
        this.selectedColumnAnswer = questionSkipCondition.colAnswerLabel?.answerLabelSequenceNo;
        }
      }
    }
  }

  getQuestionType() {
    this.questionType='';
    const questionTypeId = this.questionData.value.question.questionType.id;
    switch(questionTypeId){
      case this.questionTypeEnum.FreeText:
        this.questionType="FreeText";
        break;
      case this.questionTypeEnum.RadioButton:
      this.questionType="Radio";
        break;
      case this.questionTypeEnum.MultipleChoice:
      this.questionType="Multi-Choice";
        break;
      case this.questionTypeEnum.Scale:
      this.questionType="Scale";
        break;
      case this.questionTypeEnum.Description:
      this.questionType="DescriptionText";
        break;
      case this.questionTypeEnum.MatrixTable:
      this.questionType="Matrix";
      this.questionData.controls.question.controls.answerLabels.value.forEach(label => {
        if(label.isRow){
          this.answerLabelRows.push(label);
        }else{
          this.answerLabelColumn.push(label);
        }
      });
      break;
    }
  }

  getQuestionSkipTypes(): void {
    this.masterService.getQuestionSkipTypes().subscribe(data => {
      this.questionSkipTypes = data;
      this.questionSkipTypes = this.questionSkipTypes.find((skipType) => {
        return skipType?.questionTypeName === this.questionType;
      });
      this.questionSkipTypes = this.questionSkipTypes?.questionTypeSkipConditionMaps?.map((typeName) => {
        return typeName;
      });
     this.questionSkipTypes =  this.questionSkipTypes.filter(condition=>{
        return condition.skipCondition.id!=3 && condition.skipCondition.id!=4;
      });
    });
  }

  onSkipLogicValueAnswerLabelSelect(data: number): void {
    this.questionData.value.questionSkipCondition.answerLabel = null;
    this.questionData.value.questionSkipCondition.answerLabelSequenceNo = data;
  }

  onSkipLogicRowValueAnswerSelect(data: number): void {
    this.questionData.value.questionSkipCondition.answerLabel = null;
    this.questionData.value.questionSkipCondition.colAnswerLabel = null;
    this.questionData.value.questionSkipCondition.answerLabelSequenceNo=data;
  }

  onSkipLogicColumnValueAnswerSelect(data: number): void {
    this.questionData.value.questionSkipCondition.answerLabel = null;
    this.questionData.value.questionSkipCondition.colAnswerLabel = null;
    this.questionData.value.questionSkipCondition.colAnswerLabelSequenceNo=data;
  }

  onSkipLogicSkipTypeSelect(data: number): void {
    this.questionData.value.questionSkipCondition.skipCondition.id = data;
  }

  onSkipTypeSelect(data : string):void{
    this.questionData.value.questionSkipCondition.skipType = data;
  }

  onCancelSkipCondition(): void {
    this.showSkipLogic.emit(false);
    this.selectedSkipTypeSkipLogic='';
    this.selectedAnswerLabelSkipLogic='';
    this.selectedColumnAnswer='';
    this.selectedRowAnswer='';
    this.selectedSkipType='';
    this.questionData.value.questionSkipCondition.displayValue=undefined;
    this.skipToAnswerLabelSkipLogic='';
    this.questionData.controls.questionSkipCondition.value = null;
    this.questionData.value.questionSkipCondition = null;
    this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1].questionSkipCondition = null;
    this.onUpdateSkip.emit(false);
    this.masterService.setSurveyUpdateStatus(false);
  }

  createSkipControl() {
    return {
      skipCondition: {
        id: null
      },
      answerLabelSequenceNo: null,
      skipType: null
    };
  }

  onOkSkipLogic(){
    this.masterService.setSurveyUpdateStatus(false);
    this.questionData.controls.questionSkipCondition.value = this.questionData.value.questionSkipCondition;
    this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1].questionSkipCondition = this.questionData.value.questionSkipCondition;
    this.onUpdateSkip.emit(true);
  }

  isDisabledSkipLogicButton(): boolean{
    let isDisableButton = false;
    if (!this.selectedSkipType) {
      isDisableButton = true;
    }
    if(!this.skipToAnswerLabelSkipLogic){
      isDisableButton = true;
    }
    if(this.questionData.value.question.questionType.id==this.questionTypeEnum.Scale &&
      !this.questionData.value.questionSkipCondition.displayValue){
      isDisableButton = true;
    }
    if((this.questionData.value.question.questionType.id==this.questionTypeEnum.RadioButton ||
      this.questionData.value.question.questionType.id==this.questionTypeEnum.MultipleChoice) &&
      !this.selectedAnswerLabelSkipLogic){
      isDisableButton = true;
    }
    if(this.questionData.value.question.questionType.id==this.questionTypeEnum.MatrixTable &&
      (!this.selectedColumnAnswer ||
        !this.selectedRowAnswer)){
      isDisableButton = true;
    }
  return isDisableButton;
  }
}
