import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ClrDatagridStateInterface} from '@clr/angular';
import {KsaRoleProfileGroup} from '../../../../../models/ksa-role-profile-group.model';
import {KsaRoleProfileService} from '../../../../../services/ksa-role-profile.service';
import {KsaShareService} from '../../../../../services/ksa-share.service';
import {MasterSurveyService} from '../../../../../services/master-survey.service';
import {RoleProfileService} from '../../../../../services/role-profile.service';
import {UsersService} from '../../../../../services/users.service';
import {RoleProfileStandardProperties} from '../../../../../enums/ksa-role-profile.enum';
import {KsaAdministrationService} from '../../../../../services/ksa-administration.service';
import {RoleProfile} from "../../../../../models/role-profile.model";
import {UserService} from '../../../../../services/user.service';
import {Functionality} from '../../../../../enums/functionality.enum';

@Component({
  selector: 'app-role-profile-groups',
  templateUrl: './role-profile-groups.component.html',
  styleUrls: ['./role-profile-groups.component.scss']
})
export class RoleProfileGroupsComponent implements OnInit {

  roleProfileSelectedFromLibrary: any = [];
  roleProfileGroupsFromImport: any = [];
  importedKsas: any[] = [];
  importedKsaSelected: any = {};
  importedRoleProfileSetSelected: any;
  importedRoleProfileSetSelectedInForm: any;
  ksaData: any;
  roleProfileGroupsReceived: KsaRoleProfileGroup = {
    content: [],
  }
  selectedGroups: any[] = [];
  addEditRoleProfileGroupFormOpen: boolean = false;
  statusToFilter: string = '';
  page: number;
  loading: boolean = false;
  total: number;
  roleProfilesSelected: any[] = [];
  importFromLibrary: boolean = false;
  deleteGroupId: number;
  showDeleteWarning: boolean = false;
  state: ClrDatagridStateInterface;
  roleProfileEnum: typeof RoleProfileStandardProperties = RoleProfileStandardProperties;
  roleProfileGroup: any = this.initlaizeRoleProfileGroup();
  roleProfileGroupToPush: any = this.initlaizeRoleProfileGroup();
  errorMsg: string = '';
  masterRoleProfiles: RoleProfile[] = [];
  roleProfileSets: any = {};
  roleProfileSelectedFromMaster = [];
  importedRoleProfileGroupSelected = {
    ksaRoleProfiles: [],
  }
  roleProfilesFromImport = [];
  hasWritePermission: boolean;

  constructor(public readonly ksaShareService: KsaShareService,
              public readonly usersService: UsersService,
              public readonly masterSurveyService: MasterSurveyService,
              private readonly roleProfileService: RoleProfileService,
              private readonly ksaRoleProfileService: KsaRoleProfileService,
              private readonly ksaAdministrationService: KsaAdministrationService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.ksaAdministration);
    this.getKsaData();
    this.getKsaSets();
  }

  getKsaData(): void {
    this.ksaShareService.getKSADetails().subscribe(data => {
      this.ksaData = data;
    })
  }

  getKsaSets(): void {
    this.ksaRoleProfileService.getKsaRoleProfileSetById(this.ksaData.id).subscribe(data => {
      this.roleProfileSets = data;
    })
  }

  getImportedKsaRoleProfileSet(): void {
    this.ksaRoleProfileService.getImportedActiveKsa().subscribe((data) => {
      this.importedKsas = data;
    })
  }
  roleProfileGroupKsa(importedKsaSelected):void{
    this.roleProfileSets =[];
    this.importedRoleProfileGroupSelected.ksaRoleProfiles =[];
    this.roleProfileGroupsFromImport=[];
    this.importedRoleProfileSetSelectedInForm = [];
    this.ksaRoleProfileService.getKsaRoleProfileSetById(importedKsaSelected.id).subscribe((data) => {
      this.roleProfileSets = data;
    })
  }

  getAllRoleProfiles(): void {
    this.ksaAdministrationService.getRoleProfilesByKsaId(this.ksaData?.id).subscribe((data) => {
      this.masterRoleProfiles = data;
    });
  }

  onAddEditRoleProfileGroup(form: NgForm): void {
    this.addEditValueMapping();
    !this.roleProfileGroup.id ? (
        this.ksaRoleProfileService.createKsaRoleProfileGroup(this.roleProfileGroupToPush).subscribe(data => {
            this.postAddEditOperations(form);
          },
          error => this.errorMsg = error.error.message)) :
      (this.ksaRoleProfileService.updateKsaRoleProfileGroup(this.roleProfileGroupToPush).subscribe(data => {
          this.postAddEditOperations(form);
        },
        error => this.errorMsg = error.error.message));
  }

  addEditValueMapping(): void {
    this.roleProfileGroupToPush.ksaJobProfileSet.id = this.roleProfileGroup?.ksaJobProfileSet?.id;
    this.roleProfileGroupToPush.ksaJobProfileSet.name = this.roleProfileGroup.ksaJobProfileSet.name;
    this.roleProfileGroupToPush.ksaJobProfileSet.description = this.roleProfileGroup.ksaJobProfileSet.description;
    this.roleProfileGroupToPush.roleProfileGroupName = this.roleProfileGroup.roleProfileGroupName;
    this.roleProfileGroupToPush.description = this.roleProfileGroup.description;
    this.roleProfileGroup.id && (this.roleProfileGroupToPush.id = this.roleProfileGroup.id)
    this.roleProfileGroup.ksaRoleProfiles = this.roleProfilesSelected
    this.roleProfileGroupToPush.ksaRoleProfiles = this.roleProfilesSelected.map((roleProfile: any) =>
      ({
        id: this.roleProfileGroup.id && roleProfile.id,
        description: roleProfile.description,
        ksaRoleProfileName: roleProfile.ksaRoleProfileName ? roleProfile.ksaRoleProfileName : roleProfile.name,
        ksaSkillWithRoleProfiles: roleProfile.ksaSkillWithRoleProfiles ? (roleProfile.ksaSkillWithRoleProfiles.map((skill: any) => ({
          id: this.roleProfileGroup.id && skill.id,
          comment: skill.comment,
          threshold: skill.threshold,
          ksaSkill: {id: skill.ksaSkill.id},
        }))) : (
          roleProfile.roleProfileSkillMaps.map((skill: any) => ({
            id: this.roleProfileGroup.id && skill.id,
            comment: skill.comment,
            threshold: skill.threshold,
            ksaSkill: {id: skill.skill.id},
          }))
        )
      }))
  }

  onSetAddKsaFormOpen(): void {
    this.getAllRoleProfiles();
    this.roleProfileGroup.ksaJobProfileSet.name = this.importedRoleProfileSetSelected.name;
    this.roleProfileGroup.ksaJobProfileSet.description = this.importedRoleProfileSetSelected.description;
    this.roleProfileGroup.ksaJobProfileSet.id = this.importedRoleProfileSetSelected.id;
    this.addEditRoleProfileGroupFormOpen = true;
  }

  addSelectedRoleProfilesFromMaster(): void {
    this.roleProfileSelectedFromMaster.forEach(groupToPush => {
      (!this.roleProfilesSelected.find(groupNameToFind => (groupNameToFind.name ? (groupNameToFind.name == groupToPush.name) : (groupNameToFind.ksaRoleProfileName == groupToPush.name)))) && this.roleProfilesSelected.push(groupToPush)
    })
    this.roleProfileSelectedFromMaster = [];
  }

  addSelectedRoleProfilesFromLibrary(): void {
    this.roleProfileSelectedFromLibrary.forEach(groupToPush => {
      (!this.roleProfilesSelected.find(groupNameToFind => (groupNameToFind.name ? (groupNameToFind.name == groupToPush.ksaRoleProfileName) : (groupNameToFind.ksaRoleProfileName == groupToPush.ksaRoleProfileName)))) && this.roleProfilesSelected.push(groupToPush)
    })
    this.roleProfileSelectedFromLibrary = [];
  }

  onEditKsaDetails(ksa: any): void {
    this.getAllRoleProfiles();
    this.roleProfileGroup.ksaJobProfileSet.name = this.importedRoleProfileSetSelected.name;
    this.roleProfileGroup.ksaJobProfileSet.description = this.importedRoleProfileSetSelected.description;
    this.roleProfileGroup.ksaJobProfileSet.id = this.importedRoleProfileSetSelected.id;
    this.roleProfileGroup.roleProfileGroupName = ksa.roleProfileGroupName;
    this.roleProfileGroup.description = ksa.description;
    this.roleProfileGroup.id = ksa.id;
    this.roleProfileGroup.ksaRoleProfiles = this.roleProfilesSelected;
    this.roleProfilesSelected = ksa.ksaRoleProfiles.map((roleProfile: any) =>
      ({
        description: roleProfile.description,
        ksaRoleProfileName: roleProfile.ksaRoleProfileName,
        ksaSkillWithRoleProfiles: roleProfile.ksaSkillWithRoleProfiles.map((skill: any) => ({
          comment: skill.comment,
          threshold: skill.threshold,
          ksaSkill: {id: skill.ksaSkill.id}
        }))
      }))
    this.onSetAddKsaFormOpen();
  }

  onDeleteRoleProfile(): void {
    this.ksaRoleProfileService.deleteRoleProfileGroup(this.deleteGroupId).subscribe(data => {
        this.refresh(this.state);
        this.showDeleteWarning = false;
        this.deleteGroupId = 0;
        this.errorMsg = '';
      },
      error => this.errorMsg = error.error.message);
  }

  deleteSelectedRpg(roleProfileGroup): void {
    this.roleProfilesSelected = this.roleProfilesSelected.filter(item => {
      return item.id != roleProfileGroup.id;
    })
  }

  onDeleteMultipleGroups(selectedGroups): void {
    const ids: any[] = [];
    selectedGroups.forEach(function (value) {
      ids.push(value.id);
    });
    this.ksaRoleProfileService.deleteMultipleRoleProfileGroups(ids).subscribe(data => {
        this.refresh(this.state);
        this.selectedGroups = [];
      },
      error => {
        console.log(error)
      })
  }

  onShowDeleteWarning(id: any) {
    this.deleteGroupId = id;
    this.showDeleteWarning = true;
  }

  checkImportFromLibrary(): void {
    this.importFromLibrary && this.getImportedKsaRoleProfileSet();
  }

  populateImportedRoleProfiles(): void {
    this.roleProfilesFromImport = this.importedRoleProfileGroupSelected?.ksaRoleProfiles.map((roleProfile) => {
      return roleProfile;
    })
  }

  populateGridWithRoleProfileGroups(): void {
    this.refresh(this.state);
  }

  clearForm(): void {
    this.clearRoleProfileGroupForm();
    this.roleProfilesSelected = [];
    this.addEditRoleProfileGroupFormOpen = false;
    this.errorMsg = '';
  }

  clearRoleProfileGroupForm(): void {
    this.roleProfileGroup = this.initlaizeRoleProfileGroup();
    this.roleProfileGroupToPush = this.initlaizeRoleProfileGroup();
    this.errorMsg = '';
  }

  postAddEditOperations(form: NgForm): void {
    this.clearForm();
    this.refresh(this.state);
  }

  initlaizeRoleProfileGroup(): any {
    return {
      roleProfileGroupName: this.roleProfileEnum.groupname,
      description: '',
      ksaRoleProfiles: [
        {
          description: '',
          ksaRoleProfileName: '',
          ksaSkillWithRoleProfiles: [
            {
              comment: '',
              ksaSkill: {
                id: '',
              },
              threshold: '',
            }
          ]
        }
      ],
      ksaJobProfileSet: {
        id: '',
        name: '',
        description: '',
      }
    }
  }

  refresh(state: ClrDatagridStateInterface): void {
    this.state = state;
    this.loading = true;
    const filters: { [prop: string]: any } = {};
    if (state?.filters) {
      for (const filter of state.filters) {
        const {property, value} = <{ property: string; value: string }>filter;
        filters[property] = value;
      }
    }
    this.ksaRoleProfileService.getKsaRoleProfileGroups(this.importedRoleProfileSetSelected?.id,
      filters?.['roleProfileGroupName'] ? filters?.['roleProfileGroupName'] : '',
      state?.page?.current - 1, state?.page?.size, state?.sort?.by ? state?.sort?.by : 'id', state?.sort?.reverse ? state?.sort?.reverse : 'ASC')
      .subscribe((results: any) => {
        this.roleProfileGroupsReceived = results;
        this.total = results.totalElements;
        this.loading = false;
      })
  }
}
