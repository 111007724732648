/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {MasterKsaSimple} from "./master-ksa.model";

/**
 * @author avalchev
 */
export class EditingKsaData {

  ksaId: number = 0;
  userId: number = 0;
  firstName: string = "";
  lastName: string = "";
  email: string = "";

  constructor(ksa?: MasterKsaSimple) {
    if (ksa) {
      this.ksaId = ksa.id;
      this.userId = ksa.editingUser.id;
      this.firstName = ksa.editingUser.firstName;
      this.lastName = ksa.editingUser.lastName;
      this.email = ksa.editingUser.emailId;
    }
  }
}
