/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {TrackModel} from "../models/tracking-model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  tracking: string;
  bouncedEmailsCheck: string;

  constructor(private http: HttpClient) {
    this.tracking = `${environment.API_URL}${API_KSA_CORE_V1}/masterksa/trackksa`;
    this.bouncedEmailsCheck = `${environment.API_URL}${API_KSA_CORE_V1}/ksaemail`;
  }

  getTracking(): Observable<TrackModel[]> {
    return this.http.get<TrackModel[]>(this.tracking);
  }

  getBouncingMailCheck(id: number): Observable<any> {
    return this.http.get(this.bouncedEmailsCheck + `/${id}/bounced`);
  }
}
