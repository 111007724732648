/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Functionality} from "../enums/functionality.enum";
import {ButtonId} from "../enums/buttonId.enum";

export class AuthButton {
    public btnName: string;
    public pageName: string;
}

export class AuthButtons{
  buttons: (AuthButton)[] = [];
  constructor() {
    this.buttons = [
      {btnName: ButtonId.btnMngUserEdit, pageName: Functionality.manageUser},
      {btnName: ButtonId.btnMngUserAdd, pageName: Functionality.manageUser},
      {btnName: ButtonId.btnMngUserDelete, pageName: Functionality.manageUser},
      {btnName: ButtonId.btnMngUserMultipleDelete, pageName: Functionality.manageUser},

      {btnName: ButtonId.btnMngRoleEdit, pageName: Functionality.manageRole},
      {btnName: ButtonId.btnMngRoleAdd, pageName: Functionality.manageRole},
      {btnName: ButtonId.btnMngRoleDelete, pageName: Functionality.manageRole},

      {btnName: ButtonId.btnMngCourseDelete, pageName: Functionality.manageCourse},
      {btnName: ButtonId.btnMngCourseAdd, pageName: Functionality.manageCourse},
      {btnName: ButtonId.btnMngCourseEdit, pageName: Functionality.manageCourse},
      {btnName: ButtonId.btnMngCourseArchive, pageName: Functionality.manageCourse},
      {btnName: ButtonId.btnMngCourseActivate, pageName: Functionality.manageCourse},
      {btnName: ButtonId.btnMngCourseNewVersion, pageName: Functionality.manageCourse},

      {btnName: ButtonId.btnMngSkillDelete, pageName: Functionality.manageSkill},
      {btnName: ButtonId.btnMngSkillAdd, pageName: Functionality.manageSkill},
      {btnName: ButtonId.btnMngSkillEdit, pageName: Functionality.manageSkill},
      {btnName: ButtonId.btnMngSkillArchive, pageName: Functionality.manageSkill},
      {btnName: ButtonId.btnMngSkillActivate, pageName: Functionality.manageSkill},
      {btnName: ButtonId.btnMngSkillGroup, pageName: Functionality.manageSkill},

      {btnName: ButtonId.btnMngRoleProfileEdit, pageName: Functionality.manageRoleProfile},
      {btnName: ButtonId.btnMngRoleProfileAdd, pageName: Functionality.manageRoleProfile},
      {btnName: ButtonId.btnMngRoleProfileDelete, pageName: Functionality.manageRoleProfile},

      {btnName: ButtonId.btnMngRoleProfileGroupEdit, pageName: Functionality.manageRoleProfile},
      {btnName: ButtonId.btnMngRoleProfileGroupAdd, pageName: Functionality.manageRoleProfile},
      {btnName: ButtonId.btnMngRoleProfileGroupDelete, pageName: Functionality.manageRoleProfile},

      {btnName: ButtonId.btnMngEmailTemplateEdit, pageName: Functionality.manageEmailTemplate},
      {btnName: ButtonId.btnMngEmailTemplateAdd, pageName: Functionality.manageEmailTemplate},
      {btnName: ButtonId.btnMngEmailTemplateDelete, pageName: Functionality.manageEmailTemplate},
      {btnName: ButtonId.btnMngEmailTemplateMultipleDelete, pageName: Functionality.manageEmailTemplate},

      {btnName: ButtonId.btnMngSurveyTemplateEdit, pageName: Functionality.manageSurveyTemplate},
      {btnName: ButtonId.btnMngSurveyTemplateAdd, pageName: Functionality.manageSurveyTemplate},
      {btnName: ButtonId.btnMngSurveyTemplateDelete, pageName: Functionality.manageSurveyTemplate},
      {btnName: ButtonId.btnMngSurveyTemplateArchive, pageName: Functionality.manageSurveyTemplate},
      {btnName: ButtonId.btnMngSurveyTemplateMultiArchive, pageName: Functionality.manageSurveyTemplate},
      {btnName: ButtonId.btnMngSurveyTemplatePublish, pageName: Functionality.manageSurveyTemplate},
      {btnName: ButtonId.btnMngSurveyTemplateNewVersion, pageName: Functionality.manageSurveyTemplate},
      {btnName: ButtonId.btnMngSurveyTemplateClone, pageName: Functionality.manageSurveyTemplate},

      {btnName: ButtonId.btnEmailManagementAddThanksMail, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementEditThanksMail, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementDeleteThanksMail, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementSendThanksMail, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementAddInviteMail, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementEditInviteMail, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementDeleteInviteMail, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementSendInviteMail, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementReminderMail, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementDemoUrl, pageName: Functionality.ksaEmailManagement},
      {btnName: ButtonId.btnEmailManagementAnonymousUrl, pageName: Functionality.ksaEmailManagement},

      {btnName: ButtonId.btnKsaAdministrationAdd, pageName: Functionality.ksaAdministration},
      {btnName: ButtonId.btnKsaAdministrationEdit, pageName: Functionality.ksaAdministration},
      {btnName: ButtonId.btnKsaAdministrationDelete, pageName: Functionality.ksaAdministration},
      {btnName: ButtonId.btnKsaAdministrationMultiDelete, pageName: Functionality.ksaAdministration},
      {btnName: ButtonId.btnKsaAdministrationClone, pageName: Functionality.ksaAdministration},
      {btnName: ButtonId.btnKsaAdministrationExpire, pageName: Functionality.ksaAdministration},
      {btnName: ButtonId.btnKsaAdministrationActivate, pageName: Functionality.ksaAdministration},

      {btnName: ButtonId.btnTeamManagementAdd, pageName: Functionality.ksaTeamManagement},
      {btnName: ButtonId.btnTeamManagementDelete, pageName: Functionality.ksaTeamManagement},
      {btnName: ButtonId.btnTeamManagementMultipleDelete, pageName: Functionality.ksaTeamManagement},
      {btnName: ButtonId.btnTeamManagementEdit, pageName: Functionality.ksaTeamManagement},
    ]
  }

}

