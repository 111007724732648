<div class="questionSettings">
  <div class="btn-group btn-primary btn-icon buttonUpDown">
    <clr-icon shape="angle-double" (click)="onChangeUpdateStatus();move.emit(-1)"
              [hidden]="isUpArrayHidden(questionData?.value?.sectionQuestionSequenceNo)" class="btn-sm up-down-arrow">
    </clr-icon>
    <clr-icon shape="angle-double" (click)="onChangeUpdateStatus();move.emit(1)"
              [hidden]="isDownArrayHidden(questionData?.value?.sectionQuestionSequenceNo)" class="btn-sm up-down-arrow"
              id="downArrow"></clr-icon>
  </div>
  <clr-dropdown [clrCloseMenuOnItemClick]="true" *ngIf="hasWritePermission">
    <button clrDropdownTrigger aria-label="Dropdown demo button">
      <clr-icon shape="ellipsis-vertical"></clr-icon>
    </button>
    <clr-dropdown-menu *clrIfOpen clrPosition="left-top">
      <div (click)="showDisplayLogicModal=true" [hidden]="isFirstQuestion()" aria-label="Dropdown header Display Logic"
           clrDropdownItem>
        {{'surveyTemplate.questionSheet.commonDLogic'|translate}}
      </div>
      <div (click)="showSkipLogicModal = true" aria-label="Dropdown header Add Skip Logic" clrDropdownItem>
        {{'surveyTemplate.questionSheet.commonSLogic'|translate}}
      </div>
      <div (click)="showMandatoryTypePopUp = true" aria-label="Dropdown header Mandatory" clrDropdownItem>
        {{'surveyTemplate.questionSheet.isMandatory'|translate}}
      </div>
      <div (click)="showDeleteQuestionPopUp = true" aria-haspopup="true" aria-label="Dropdown header MapSkill"
        clrDropdownItem>
        {{'surveyTemplate.questionSheet.isDelete'|translate}}
      </div>
      <div (click)="isAddNoteTrue=true" aria-haspopup="true" aria-label="Dropdown header MapSkill" clrDropdownItem
      *ngIf="!questionData.controls.isImported.value">
        {{'surveyTemplate.questionSheet.isNote'|translate}}
      </div>
      <div (click)="onAddPageBreak()" [hidden]="isLastQuestion()" aria-haspopup="true"
        aria-label="Dropdown header MapSkill" clrDropdownItem>
        {{'surveyTemplate.questionSheet.isPageBreak'|translate}}
      </div>
      <div (click)="showMapTag()" *ngIf="!questionData.controls.isImported.value" aria-haspopup="true" aria-label="Dropdown header tagData" clrDropdownItem>
        {{'surveyTemplate.questionSheet.mapTag'|translate}}
      </div>
      <div (click)="onEditButton()" *ngIf="questionData.controls.isImported.value" aria-haspopup="true" aria-label="Dropdown header Edit"
        clrDropdownItem>
        {{'surveyTemplate.questionSheet.isEdit'|translate}}
      </div>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>
<clr-accordion>
  <clr-accordion-panel [clrAccordionPanelOpen]="true">
    <clr-accordion-title class="clr-row ml-0">
      <div>{{questionText?.trim()}}<span class="mandatory-span" *ngIf="!questionData.isMandatory">
        {{questionData?.controls?.isMandatory?.value ? '*':''}}
      </span>
      </div>
    </clr-accordion-title>
    <clr-accordion-content>
      <div *ngIf="errorOnDisplayLogicItemDelete" class="alert alert-danger" id="alert-message" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
            </div>
            <span class="alert-text">
              {{'errorMsg.canNotDeleteQuestion'|translate}}  {{dependentQuestionDisplayLogic}}
            </span>
          </div>
        </div>
      </div>
      <form [formGroup]="questionData">
        <div class="clr-row">
          <div class="clr-col-lg-8 clr-col-8">
            <div class="QuestionBanners" draggable="false">
              <div *ngIf="showSkipMessage" class="skipCondition">
                <div class="DynamicChoicesBox LogicBox">
                  <table aria-describedby="Skip Logic Table">
                    <tbody>
                    <tr>
                      <th id="SkipTab" class="InfoBarSkipTab"></th>
                      <td class="SkipDesc">
                        <div class="SkipLogicHeading">
                          <h6>{{'surveyTemplate.questionSheet.skipTo'|translate}}</h6>
                        </div>
                        <div class="SkipLogicEditor">
                          {{'surveyTemplate.questionSheet.isEndOf'|translate}} {{skipLogicToShow.skipType}}
                          {{'surveyTemplate.questionSheet.ifIt'|translate}}{{skipLogicToShow.skipConditionName}}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div *ngIf="showDisplayMessage" class="displayCondition">
                <div class="DynamicChoicesBox LogicBox">
                  <table aria-describedby="Display Logic Table">
                    <tbody>
                    <tr>
                      <th id="DisplayTab" class="InfoBarDisplayTab"></th>
                      <td class="DisplayDesc">
                        <div class="DisplayLogicHeading">
                          <h6>{{'surveyTemplate.questionSheet.isDisplay'|translate}}</h6>
                        </div>
                        <div [ngSwitch]="displayLogicToShow.questionTypeId">
                          <div *ngSwitchCase="questionTypeEnum.FreeText" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{displayLogicToShow.questionText}}
                            {{displayLogicToShow.skipConditionName}}
                          </div>
                          <div *ngSwitchCase="questionTypeEnum.RadioButton" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{displayLogicToShow.answerLabel}}
                            {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                            {{displayLogicToShow.questionText}}
                            {{displayLogicToShow.skipConditionName}} {{displayLogicToShow.displayValue}}
                          </div>
                          <div *ngSwitchCase="questionTypeEnum.MultipleChoice" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{displayLogicToShow.answerLabel}}
                            {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                            {{displayLogicToShow.questionText}}
                            {{displayLogicToShow.skipConditionName}} {{displayLogicToShow.displayValue}}
                          </div>
                          <div *ngSwitchCase="questionTypeEnum.Scale" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{displayLogicToShow?.questionText}}
                            {{displayLogicToShow?.skipConditionName}} {{displayLogicToShow.displayValue}}
                          </div>
                          <div *ngSwitchCase="questionTypeEnum.MatrixTable" class="all-question">
                            {{'surveyTemplate.questionSheet.willDisplay'|translate}}
                            {{displayLogicToShow.colAnswerLabel}}
                            {{displayLogicToShow.answerLabel}}
                            {{'surveyTemplate.questionSheet.isSelectedForQuestion'|translate}}
                            {{displayLogicToShow.questionText}}
                            {{displayLogicToShow.skipConditionName}}
                          </div>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <form formGroupName="question" clrForm>
              <clr-textarea-container>
                <textarea clrTextarea formControlName='questionText' (keyup)="autoGrowTextZone($event)" [(ngModel)]="questionText"
                  placeholder="Enter Question Text!" rows="1"></textarea>
                <clr-control-error *clrIfError="'required'" class="error">
                  {{'surveyTemplate.questionSheet.enterQuestionText'|translate}}
                </clr-control-error>
                <clr-control-error *clrIfError="'pattern'" class="error">
                </clr-control-error>
              </clr-textarea-container>
            </form>
            <div class="cardImage">
              <div *ngIf="this.questionData.controls.question.controls.image.value?.imageData" class="card-title">
                <div class="card">
                  <div class="clr-row">
                    <div class="clr-col-12 question-image">
                      <img
                        [src]="'data:image/jpeg;base64,'+this.questionData.controls.question.controls.image.value?.imageData"
                        alt="Question Image">
                      <clr-icon (click)="onDeleteImage()" aria-label="warning" [hidden]="questionData.controls.isImported.value"
                                class="btn-sm btn-warning-outline deleteImageIcon" shape="minus-circle">
                      </clr-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button (click)="showImageUploadPopUp = true" *ngIf="!convertedImage" [disabled]="questionData.controls.isImported.value"
              class="btn btn-outline btn-sm">
              {{'surveyTemplate.questionSheet.isAddImageQn'|translate}}
            </button>
            <div *ngIf="isAddNoteTrue || this.questionData.controls.questionNote?.value" class="clr-row note">
              <div class="clr-col-lg-11">
                <clr-input-container>
                  <input autocomplete="off" class="noteText" clrInput formControlName='questionNote' name="text"
                    placeholder="Enter Note Text!" (keyup)="onChangeUpdateStatus()"/>
                  <clr-control-error *clrIfError="'maxlength'" class="error">
                    {{'surveyTemplate.questionSheet.isMaxCharAllowed'|translate}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'pattern'" class="error">
                    {{'surveyTemplate.masterSurvey.surveyCharErrorMsg'|translate}}
                  </clr-control-error>
                </clr-input-container>
              </div>
            </div>
          </div>
          <div class="clr-col-lg-4 clr-col-12">
            <div class="skillBlock" *ngIf="skillToShow">
              <div class="card">
                <div class="card-header">
                  <h6>{{'surveyTemplate.questionSheet.isSKill'|translate}} <span>{{skillToShow}}</span></h6>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h6>{{'surveyTemplate.questionSheet.isAnswerProperty'|translate}}</h6>
              </div>
              <div class="card-block">
                <div class="card-text">
                  <clr-radio-wrapper>
                    <input (change)="onSingleOrMultiLineInput(false)"
                      [checked]="!this.questionData.controls.question.controls['answerProperty'].get('isSingleLine').value"
                      [disabled]="questionData.controls.isImported.value" clrRadio name="choice" name="answerChoice" type="radio"
                      value="multiple" />
                    <label>{{'surveyTemplate.questionSheet.isMultipleLine'|translate}}</label>
                  </clr-radio-wrapper>
                  <clr-radio-wrapper>
                    <input (change)="onSingleOrMultiLineInput(true)"
                      [checked]="this.questionData.controls.question.controls['answerProperty'].get('isSingleLine').value"
                      [disabled]="questionData.controls.isImported.value" clrRadio name="choice" name="answerChoice" type="radio"
                      value="single" />
                    <label>{{'surveyTemplate.questionSheet.isSingleLine'|translate}}</label>
                  </clr-radio-wrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
<div *ngIf="isAddPageBreak" draggable="true" class="PageBreak Draggable">
  <clr-icon (click)="onRemovePageBreak()" aria-label="warning" class="btn-sm btn-warning-outline minusIconPagebrak"
            shape="minus-circle">
  </clr-icon>
  <div class="PBInner">
    <div class="perforation">
      <span class="text">{{'surveyTemplate.questionSheet.isPageBreak'|translate}}</span>
    </div>
  </div>
</div>
<clr-modal *ngIf="showImageDeletePopUp" [(clrModalOpen)]="showImageDeletePopUp">
  <h3 class="modal-title">{{'surveyTemplate.questionSheet.isDeleteImageMsg'|translate}}</h3>
  <div class="modal-body">
    <img [src]="convertedImage" alt="Question Image" height="100" width="480">
  </div>
  <div class="modal-footer">
    <button (click)="showImageDeletePopUp = false" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn'
      | translate}}</button>
    <button (click)="onDeleteImage()" class="btn btn-outline-warning btn-sm">{{'surveyTemplate.mngSurvey.deleteBtn' |
      translate}}
    </button>
  </div>
</clr-modal>
<clr-modal *ngIf="showDeleteQuestionPopUp" [(clrModalOpen)]="showDeleteQuestionPopUp">
  <h3 class="modal-title">{{'surveyTemplate.questionSheet.isDeleteQuestion'|translate}}</h3>
  <div class="modal-body">
    {{'surveyTemplate.mngSurvey.unDeleteMsg' | translate}}
    <div *ngIf="errorOnDisplayLogicItemDelete" class="alert alert-danger" id="alert-message" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
            {{'errorMsg.canNotDeleteQuestion'|translate}}  {{dependentQuestionDisplayLogic}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="showDeleteQuestionPopUp = false"
      class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}</button>
    <button (click)="onRemoveQuestion()" class="btn btn-outline-warning btn-sm">{{'surveyTemplate.mngSurvey.deleteBtn' |
      translate}}</button>
  </div>
</clr-modal>

<clr-modal *ngIf="showMapTagPopUp" [(clrModalOpen)]="showMapTagPopUp" (clrModalOpenChange)="showMapTagPopUp = false">
  <h3 class="modal-title">
    {{'surveyTemplate.questionSheet.mapTag'|translate}}
  </h3>
  <div class="modal-body">
    <div class="clr-row questionTag">
    </div>

    <div class="clr-row">
      <div class="clr-col-3">
        <label
          class="clr-control-label pre-requisite-label tag">{{'surveyTemplate.questionSheet.selectTag'|translate}}</label>
      </div>
      <div class="clr-col-8 outer-combobox-container">
        <clr-combobox-container>
          <clr-combobox [(ngModel)]="mapSurveyQuestionWithTag" class="clr-col-8" name="tagName">
            <ng-container *clrOptionSelected="let selected">
              {{selected?.tagName}}
            </ng-container>
            <clr-options>
              <clr-option *clrOptionItems="let state of tagList; field:'tagName'" [clrValue]="state">
                {{state.tagName}}
              </clr-option>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="showMapTagPopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}</button>
    <button (click)="onTagSelection()" class="btn btn-sm btn-primary">{{'admin.okBtn' | translate}}</button>
  </div>
</clr-modal>

<app-display-logic-pop-up *ngIf="showDisplayLogicModal" [showDisplayLogicModal]="showDisplayLogicModal"
  [questionData]="questionData" [sectionReference]="sectionReference" (showDisplayLogic)="showDisplayLogicModal=false"
  (onUpdateDisplay)=onUpdateDisplay($event)></app-display-logic-pop-up>
<app-skip-logic-pop-up *ngIf="showSkipLogicModal" [sectionReference]="sectionReference" [showSkipLogicModal]="showSkipLogicModal"
  [questionData]="questionData" (showSkipLogic)="showSkipLogicModal=false" (onUpdateSkip)=onUpdateSkip($event)></app-skip-logic-pop-up>
<app-mandatory-pop-up *ngIf="showMandatoryTypePopUp" [questionData]="questionData"
  [showMandatoryTypePopUp]="showMandatoryTypePopUp" (showMandatory)="showMandatoryTypePopUp=false">
</app-mandatory-pop-up>
<app-map-skill-pop-up *ngIf="showMapSkillPopUp" [showMapSkillPopUp]="showMapSkillPopUp" [questionData]="questionData"
  [sectionReference]="sectionReference" (showMapSkill)="showMapSkillPopUp=false"></app-map-skill-pop-up>
<app-image-upload-pop-up *ngIf="showImageUploadPopUp" [showImageUploadPopUp]="showImageUploadPopUp"
  [questionData]="questionData" (showImageUpload)="showImageUploadPopUp=false"></app-image-upload-pop-up>
