/*******************************************************************
 Copyright 2022 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

export interface Scorecard {
  personalDetailsDto: PersonalData;
  skillsAndCoursesDtos: SkillsAndCourses[];
  note: string;
}

export interface PersonalData {
  id: number
  email: string
  fullName: string
  firstName: string
  lastName: string
  jobTitle: string
  team: string
  company: string
  manager: string
  country: string
  keyResponsibilities: string
  ksaRoleProfile: string
  scorecardStatus: ScorecardStatus
}

export interface SkillsAndCourses {
  skillName: string
  participantScore: number
  targetScore: number
  recommendedCourse: Course
}

export interface Course {
  id: number;
  courseId: string;
  courseName: string;
  courseVersion: string;
  courseDescription: string;
  courseUrl: string;
}

export enum ScorecardStatus {
  NOT_SENT = "NOT_SENT",
  SENDING = "SENDING",
  SENT = "SENT",
  FAILED = "FAILED"
}
