/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  AnswerLabel,
  AnswerProperty,
  AnswerPropertyForm,
  MasterSurveyQuestion,
  OptionScale
} from '../../../../../../models/master-survey-questions.model';
import {MngSectionComponent} from '../mng-section.component';
import {SurveyControls} from "../../../../../../enums/survey-controls.enum";
import {QuestionType} from '../../../../../../enums/questionType.enum';
import {ShareSurveyDataService} from '../../../../../../services/share-survey-data.service';
import {MasterSurveyService} from '../../../../../../services/master-survey.service';
import {Subscription} from "rxjs";
import {UserService} from '../../../../../../services/user.service';
import {Functionality} from '../../../../../../enums/functionality.enum';

const MINIMUM_LABEL_VALUE: number = 0;
const MAXIMUM_LABEL_VALUE: number = 1000;

@Component({
  selector: 'app-scale',
  templateUrl: './scale.component.html',
  styleUrls: ['./scale.component.scss']
})
export class ScaleComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  index: number;
  move = new EventEmitter();
  sectionReference!: MngSectionComponent;
  sectionQuestions!: MasterSurveyQuestion[];
  questionData: any;
  questionSequence!: number;
  tempSurveyQuestionId: number;
  options: OptionScale[];
  mapSkill: {
    id: number
  } = {
    id: 0
  };
  isAddPageBreak: boolean = false;
  scaleValidationMessage: string = '';
  skillToShow: string = '';
  displayLogicToShow= this.defaultDisplayLogicMessage();
  skipLogicToShow = this.defaultSkipLogicMessage();
  showDisplayLogicModal: boolean = false;
  showSkipLogicModal: boolean = false;
  showMandatoryTypePopUp: boolean = false;
  showMapSkillPopUp: boolean = false;
  isDisable: boolean = false;
  isAddNoteTrue: boolean = false;
  showImageUploadPopUp: boolean = false;
  showDeleteQuestionPopUp: boolean = false;
  showMatrixAnswerLabel: boolean;
  convertedImage: string;
  showDisplayAnswerLabel: boolean;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  questionTypeEnum: typeof QuestionType = QuestionType;
  questionText: string;
  errorOnDisplayLogicItemDelete: boolean = false;
  showDisplayMessage: boolean = false;
  showSkipMessage: any;
  dependentQuestionDisplayLogic: string = '';
  subscriptions: Subscription[] = [];
  hasWritePermission: boolean;

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef,
              private shareSurveyData: ShareSurveyDataService,
              private masterSurveyService: MasterSurveyService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.manageSurveyTemplate);
    if (this.questionData) {
      this.onInitializationScaleQuestionData();
    } else {
      this.setDefaultQuestionData();
      this.onAnswerLabelInput();
      this.changeDetectOnQuestion();
    }
    this.onCreateScaleLabel();
  }

  changeDetectOnQuestion() : void {
    this.questionData.valueChanges.subscribe((val: MasterSurveyQuestion) => {
      this.sectionReference.sectionData.sectionQuestions[this.questionData.get(this.surveyControlsEnum.sectionQuestionSequenceNo).value - 1] = this.questionData.getRawValue();
    });
  }

  ngAfterViewInit(): void {
    this.changeDetectOnQuestion();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  setDefaultQuestionData(): void {
    this.questionData = this.formBuilder.group({
      question: this.formBuilder.group({
        questionType: {
          id: this.questionTypeEnum.Scale,
        },
        questionText: ['', {
          validators: [Validators.required, Validators.maxLength(1000)],
        }],
        image: [],
        answerLabels: this.formBuilder.array([this.onCreateAnswer()]),
        answerProperty: this.formBuilder.group(this.createProperty()),
      }),
      questionNote: ['', {
        validators: [Validators.maxLength(100)],
      }],
      language: {
        id: 1
      },
      skill: this.mapSkill,
      sectionQuestionSequenceNo: this.questionSequence,
      tempSurveyQuestionId: this.shareSurveyData.findMaxQuestionId()+1,
      isMandatory: [true],
      isPageBreakAdded: [false],
      isImported: [false], isVersioned: [false],
      questionDisplayCondition: this.createDisplayControl(),
      questionSkipCondition: this.createSkipControl(),
    });
  }

  onInitializationScaleQuestionData(): void {
    this.skillToShow = this.questionData.skill ? this.questionData.skill.skillName : '';
    this.questionText = this.questionData.question.questionText;
    if (this.questionData.isPageBreakAdded) {
      this.isAddPageBreak = true;
    }
    this.assignQuestionData();
  }

    assignQuestionData(): void {
    this.questionData = this.formBuilder.group({
      id: this.questionData.id,
      question: this.formBuilder.group({
        id: this.questionData.question.id,
        questionType: {
          id: this.questionData.question.questionType.id,
        },
        image: [this.questionData.question.image],
        questionText: [ this.questionData.question.questionText, {
          validators: [Validators.required, Validators.maxLength(1000)],
        }],
        answerProperty: this.formBuilder.group(this.createPropertyOnInit(this.questionData.question.answerProperty)),
        answerLabels: this.formBuilder.array(this.questionData.question.answerLabels.map(label => this.createAnswerLabel(label))),
      }),
      questionNote: [this.questionData.questionNote, {
        validators: [Validators.maxLength(100)],
      }],
      language: {
        id: this.questionData.language.id
      },
      skill: {
        id: this.questionData.skill?.id
      },
      sectionQuestionSequenceNo: this.questionData.sectionQuestionSequenceNo,
      tempSurveyQuestionId: this.questionData.tempSurveyQuestionId,
      isMandatory: [this.questionData.isMandatory],
      isPageBreakAdded: [this.questionData.isPageBreakAdded],
      isImported: [this.questionData.isImported],
      isVersioned: [this.questionData.isVersioned],
      questionDisplayCondition: this.questionData.questionDisplayCondition ? this.questionData.questionDisplayCondition : this.createDisplayControl(),
      questionSkipCondition: this.questionData.questionSkipCondition ? this.questionData.questionSkipCondition : this.createSkipControl(),
    });

    if(this.questionData.controls.isImported.value){
    this.questionData.controls.question.controls[this.surveyControlsEnum.questionText].disable();
    this.questionData.controls[this.surveyControlsEnum.questionNote].disable();
    this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerFrom).disable();
    this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerTo).disable();
    this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.stepSize).disable();
    this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.labelCount).disable();
    }

      if (this.questionData.value.questionDisplayCondition.tempSectionId) {
        this.onUpdateDisplay(true);
      } else {
        this.onUpdateDisplay(false);
      }

      if(this.questionData.value.questionSkipCondition.skipCondition.id){
        this.onUpdateSkip(true);
      }else{
        this.onUpdateSkip(false);
      }
  }

  createSkipControl(): FormGroup {
    return this.formBuilder.group({
      skipCondition: this.formBuilder.group({
        id: null
      }),
      skipType: null,
      displayValue: null
    });
  }

  get answerLabelArray(): FormArray {
    return this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels] as FormArray;
  }

  createDisplayControl(): FormGroup {
    return this.formBuilder.group({
      answerLabelSequenceNo: null,
      colAnswerLabelSequenceNo: null,
      displayValue: null,
      skipCondition: {
        id: null
      },
      tempSectionId:null,
      tempSurveyQuestionId:null
    });
  }

  onEditButton(): void {
    this.dependentQuestionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.shareSurveyData.validateQuestionDisplayLogic(this.questionData);
    if(validateQuestionDisplayLogicResponse.isValidQuestion){
      this.masterSurveyService.setSurveyUpdateStatus(false);
      this.questionData.controls.question.controls[this.surveyControlsEnum.questionText].enable();
      this.questionData.controls[this.surveyControlsEnum.questionNote].enable();
      this.questionData.get(this.surveyControlsEnum.isImported).setValue(false);
      delete this.questionData.controls.question.controls[this.surveyControlsEnum.id]?.value;
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerFrom).enable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerTo).enable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.stepSize).enable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.labelCount).enable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.id).setValue();
      this.questionData.controls.question.controls[this.surveyControlsEnum.image].get(this.surveyControlsEnum.id)?.setValue();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].enable();
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].controls.forEach(label => {
        label.get(this.surveyControlsEnum.id).setValue();
      });
    }else{
      this.errorOnDisplayLogicItemDelete = true;
      if(validateQuestionDisplayLogicResponse.dependentQuestion){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentQuestion.question.questionText;
      }
      if(validateQuestionDisplayLogicResponse.dependentSection){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      }
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
      }, 5000);
    }
  }

  onAddPageBreak(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.isAddPageBreak = true;
    this.questionData.controls.isPageBreakAdded.setValue(true);
  }

  onRemovePageBreak(): void {
    this.isAddPageBreak = false;
    this.questionData.controls.isPageBreakAdded.setValue(false);
  }

  createProperty(): AnswerPropertyForm {
    return ({
      answerFrom: [1, [Validators.maxLength(10), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(MINIMUM_LABEL_VALUE), Validators.required]],
      answerTo: [4, [Validators.maxLength(10), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(MINIMUM_LABEL_VALUE), Validators.required]],
      stepSize: [1, [Validators.maxLength(10), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(1), Validators.required]],
      labelCount: [0, [Validators.maxLength(1), Validators.max(3), Validators.min(0), Validators.required]],
    });
  }

  createAnswerLabel(label: AnswerLabel): FormGroup {
    return this.formBuilder.group({
      id: label.id,
      labelText: [label.labelText, {
        validators: [Validators.required, Validators.maxLength(1000)],
      }],
      answerLabelSequenceNo: [label.answerLabelSequenceNo],
      isRow: [label.isRow],
    });
  }

  onCreateAnswer(): FormGroup {
    return this.formBuilder.group({
      labelText: ['', {
        validators: [Validators.required, Validators.maxLength(100)],
      }],
      answerLabelSequenceNo: [],
      isRow: [false],
    });
  }

  createPropertyOnInit(info: AnswerProperty) {
    return ({
      id: info.id,
      answerFrom: [ info.answerFrom, {
        validators: [Validators.maxLength(10), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(MINIMUM_LABEL_VALUE), Validators.required],
      }],
      answerTo: [ info.answerTo, {
        validators: [Validators.maxLength(10), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(MINIMUM_LABEL_VALUE), Validators.required],
      }],
      stepSize: [ info.stepSize, {
        validators: [Validators.maxLength(10), Validators.max(MAXIMUM_LABEL_VALUE), Validators.min(1), Validators.required],
      }],
      labelCount: [ info.labelCount]
    });
  }

  onCreateScaleLabel(): void {
    const answerFrom: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerFrom).value;
    const answerTo: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerTo).value;
    const stepSize: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.stepSize).value;
    this.options = [];
    if(answerFrom && answerTo && stepSize){
      for (let index: number = answerFrom; index <= answerTo; index = index + stepSize) {
        this.options.push({
          optionLabel: index
        });
      }
    }else{
      this.scaleValidationMessage = this.surveyControlsEnum.scalePropertMessage;
    }
    if (this.options.length < 11) {
      this.scaleValidationMessage = '';
    } else {
      this.scaleValidationMessage = this.surveyControlsEnum.scaleErrorMessage;
    }
  }

  isScaleLengthMax(): boolean {
    if (this.options.length < 10) {
      return true;
    }
    return false;
  }

  onInputAnswerLabelText(index: number, data: string): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].controls[index].get(this.surveyControlsEnum.labelText).setValue(data);
    this.questionData.controls.question.controls[this.surveyControlsEnum.answerLabels].controls[index].get(this.surveyControlsEnum.answerLabelSequenceNo).setValue(index + 1);
  }

  onAnswerLabelInput(): void {
    const answerFormArray: FormArray = this.answerLabelArray;
    answerFormArray.clear();
    const labelCount: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.labelCount).value;
    for (let index: number = 1; index <= labelCount; index++) {
      (answerFormArray).push(this.onCreateAnswer());
    }
  }

  onAddFrom(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentFromValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerFrom).value;
    if (currentFromValue < MAXIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerFrom).setValue(currentFromValue + 1);
    }
    this.onCreateScaleLabel();
  }

  onRemoveFrom(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentFromValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerFrom).value;
    if (currentFromValue > MINIMUM_LABEL_VALUE && this.isScaleLengthMax()) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerFrom).setValue(currentFromValue - 1);
    }
    this.onCreateScaleLabel();
  }

  onAddTo(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentToValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerTo).value;
    if (currentToValue < MAXIMUM_LABEL_VALUE && this.isScaleLengthMax()) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerTo).setValue(currentToValue + 1);
    }
    this.onCreateScaleLabel();
  }

  onRemoveTo(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentToValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerTo).value;
    if (currentToValue > MINIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.answerTo).setValue(currentToValue - 1);
    }
    this.onCreateScaleLabel();
  }

  onAddStepSize(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentStepSizeValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.stepSize).value;
    if (currentStepSizeValue < MAXIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.stepSize).setValue(currentStepSizeValue + 1);
    }
    this.onCreateScaleLabel();
  }

  onRemoveStepSize(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    const currentStepSizeValue = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.stepSize).value;
    if (currentStepSizeValue > MINIMUM_LABEL_VALUE && this.isScaleLengthMax()) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.stepSize).setValue(currentStepSizeValue - 1);
    }
    this.onCreateScaleLabel();
  }

  onAddLabels(): void {
    const currentLabelValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.labelCount).value;
    if (currentLabelValue < 3) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.labelCount).setValue(currentLabelValue + 1);
      this.answerLabelArray.push(this.onCreateAnswer());
    }
  }

  onRemoveLabels(): void {
    const currentLabelValue: number = this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.labelCount).value;
    if (currentLabelValue > MINIMUM_LABEL_VALUE) {
      this.questionData.controls.question.controls[this.surveyControlsEnum.answerProperty].get(this.surveyControlsEnum.labelCount).setValue(currentLabelValue - 1);
      this.answerLabelArray.removeAt(this.answerLabelArray.length - 1);
    }
  }

  onDeleteImage(): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    this.convertedImage = '';
    this.questionData.controls.question.get(this.surveyControlsEnum.image).setValue();
  }

  onRemoveQuestion(): void {
    this.dependentQuestionDisplayLogic = '';
    const validateQuestionDisplayLogicResponse = this.shareSurveyData.validateQuestionDisplayLogic(this.questionData);
    if(validateQuestionDisplayLogicResponse.isValidQuestion){
      this.sectionReference.removeQuestion(this.questionData);
      this.showDeleteQuestionPopUp = false;
      this.masterSurveyService.setSurveyUpdateStatus(false);
    }else{
      this.showDeleteQuestionPopUp = true;
      this.errorOnDisplayLogicItemDelete = true;
      if(validateQuestionDisplayLogicResponse.dependentQuestion){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentQuestion.question.questionText;
      }
      if(validateQuestionDisplayLogicResponse.dependentSection){
        this.dependentQuestionDisplayLogic = validateQuestionDisplayLogicResponse.dependentSection;
      }
      setTimeout(() => {
        this.errorOnDisplayLogicItemDelete = false;
      }, 5000);
    }
  }

  isUpArrayHidden(data: number): boolean {
    if (data == 1) {
      return true;
    }
    return false;
  }

  isDownArrayHidden(data: number): boolean {
    if (this.sectionReference?.sectionData.sectionQuestions.length == data) {
      return true;
    }
    return false;
  }

  isLastQuestion(): boolean {
    if (this.sectionReference?.sectionData.sectionQuestions.length == this.questionData.value.sectionQuestionSequenceNo
      && this.sectionReference.surveySections.length == this.sectionReference.sectionData.sectionSequenceNo) {
      return true;
    }
    return false;
  }

  isFirstQuestion(): boolean {
    if (this.sectionReference?.sectionData.sectionSequenceNo == 1 && this.questionData.value.sectionQuestionSequenceNo == 1) {
      return true;
    }
    return false;
  }

  autoGrowTextZone(textBox): void {
    this.masterSurveyService.setSurveyUpdateStatus(false);
    textBox.target.style.height = this.surveyControlsEnum.zeroPixel;
    textBox.target.style.height = (textBox.target.scrollHeight + 25) + this.surveyControlsEnum.pixel;
  }

  onCheckUpdateStatus(): void{
    this.masterSurveyService.setSurveyUpdateStatus(false);
  }

  onUpdateDisplay(data: boolean): void{
    this.showDisplayMessage = data;
    if(this.showDisplayMessage===true){
        this.sectionReference?.surveySections.forEach(section => {
          if (section.tempSectionId == this.questionData.value.questionDisplayCondition.tempSectionId) {
            section.sectionQuestions?.forEach(question => {
              if (question.tempSurveyQuestionId == this.questionData.value.questionDisplayCondition.tempSurveyQuestionId) {
                this.displayLogicToShow.questionText = question.question?.questionText;
                this.displayLogicToShow.questionTypeId = question.question?.questionType.id;
                if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.RadioButton ||
                  this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MultipleChoice) {
                    if(this.questionData.value.questionDisplayCondition.answerLabel){
                      this.displayLogicToShow.answerLabel = this.questionData.value.questionDisplayCondition.answerLabel.labelText;
                    }else if(this.questionData.value.questionDisplayCondition.answerLabelSequenceNo){
                      const labels: AnswerLabel[] = question.question.answerLabels;
                      labels.forEach(answer=>{
                        if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
                          this.displayLogicToShow.answerLabel = answer.labelText;
                        }
                      });
                    }
                  }
                if (this.displayLogicToShow.questionTypeId == this.questionTypeEnum.MatrixTable) {
                  const labels: AnswerLabel[] = question.question.answerLabels;
                  const rowAnswers: AnswerLabel[] = [];
                  const colAnswers: AnswerLabel[] = [];

                  labels.forEach(label => {
                    if (label.isRow) {
                      rowAnswers.push(label);
                    } else {
                      colAnswers.push(label);
                    }
                  });

                  if (this.questionData.value.questionDisplayCondition.answerLabel) {
                    this.displayLogicToShow.answerLabel = this.questionData.value.questionDisplayCondition.answerLabel.labelText;
                  }else if(this.questionData.value.questionDisplayCondition.answerLabelSequenceNo){
                    rowAnswers.forEach(answer=>{
                      if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.answerLabelSequenceNo) {
                        this.displayLogicToShow.answerLabel = answer.labelText;
                      }
                    });
                  }

                  if(this.questionData.value.questionDisplayCondition.colAnswerLabel){
                    this.displayLogicToShow.colAnswerLabel = this.questionData.value.questionDisplayCondition.colAnswerLabel.labelText;
                  }else if(this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo){
                    colAnswers.forEach(answer => {
                      if (answer.answerLabelSequenceNo == this.questionData.value.questionDisplayCondition.colAnswerLabelSequenceNo) {
                        this.displayLogicToShow.colAnswerLabel = answer.labelText;
                      }
                    });
                  }
                }
              }
            });
            this.displayLogicToShow.displayValue = this.questionData.value?.questionDisplayCondition?.displayValue;
            const subscription = this.shareSurveyData.getSkipConditions().subscribe(conditions => {
              const skipCondition = conditions.find(condition => {
                return condition.id == this.questionData.value.questionDisplayCondition.skipCondition.id;
              });
              this.displayLogicToShow.skipConditionName = skipCondition.skipConditionName;
            });
            this.subscriptions.push(subscription);
          }
        });
    }
  }

  onUpdateSkip(data) : void{
    this.showSkipMessage = data;
    if(data) {
      this.skipLogicToShow.skipType = this.questionData.controls.questionSkipCondition.value.skipType;
      this.skipLogicToShow.displayValue = this.questionData.controls.questionSkipCondition.value.displayValue;
      const subscription = this.shareSurveyData.getSkipConditions().subscribe(conditions => {
        const skipCondition = conditions.find(condition => {
          return condition.id == this.questionData.controls.questionSkipCondition.value.skipCondition.id;
        });
        this.skipLogicToShow.skipConditionName = skipCondition.skipConditionName;
      });
      this.subscriptions.push(subscription);
    }
  }

  defaultDisplayLogicMessage() {
    return{
      questionText:'',
      skipConditionName:'',
      answerLabel:'',
      colAnswerLabel:'',
      displayValue:undefined,
      questionTypeId:undefined
    }
  }

  defaultSkipLogicMessage(){
    return{
      skipType : '',
      skipConditionName:'',
      displayValue:undefined
    }
  }

  ngOnDestroy(): void {
    this.questionData.valueChanges.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
