/*
 *  *********************************************************************
 *  * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Pipe, PipeTransform} from '@angular/core';
import {MasterSurveyLocalizationStatus} from "../../../models/master-survey.model";
import {LocalizationStatusEnum} from "../../../enums/localization-status.enum";

@Pipe({
  name: 'filterSurveyL10nStatus',
  pure: false
})
export class FilterSurveyL10nStatusPipe implements PipeTransform {

  transform(statuses: MasterSurveyLocalizationStatus[], l10Status: LocalizationStatusEnum): MasterSurveyLocalizationStatus[] {
    return statuses.filter(ls => ls.status === l10Status);
  }

}
