/*******************************************************************
 Copyright 2022 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from "@angular/core";
import {ShareSurveyDataService} from "../../../../services/share-survey-data.service";
import {MasterSurvey} from "../../../../models/master-survey.model";

@Injectable({
  providedIn: 'root'
})
export class MasterSurveyManagerService {

  constructor(private shareSurveyDataService: ShareSurveyDataService) {
  }

  /**
   * Checks if the service name is unique for the currently opened survey
   * @param sectionName
   */
  isSurveySectionNameUnique(sectionName: string): boolean {
    return this.countSectionName(sectionName) <= 1;
  }

  /**
   * Counts how many sections, with a given @param sectionName. are present in the currently opened survey
   * @param sectionName
   */
  private countSectionName(sectionName: string): number {
    const survey: MasterSurvey = this.shareSurveyDataService.getSurvey().value;
    return survey?.surveySections?.map(section => section?.section?.sectionName)
      .filter(sn => sn?.toLocaleLowerCase() === sectionName?.toLocaleLowerCase()).length || 0;
  }
}
