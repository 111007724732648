import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {QuestionType} from '../../../../enums/questionType.enum';
import {SurveyControls} from '../../../../enums/survey-controls.enum';
import {QuestionMapSkill} from '../../../../models/master-survey-questions.model';
import {MasterSurveySection} from '../../../../models/master-survey-section.model';
import {SkillPage} from '../../../../models/skill-page.model';
import {MasterSurvey} from "../../../../models/master-survey.model";
import {MasterSurveyService} from "../../../../services/master-survey.service";
import {ShareSurveyDataService} from "../../../../services/share-survey-data.service";
import {Subscription} from "rxjs";
import {SkillsService} from "../../../../services/skills.service";
import {Status} from "../../../../enums/status.enum";
import {UserService} from '../../../../services/user.service';
import {Functionality} from '../../../../enums/functionality.enum';

@Component({
  selector: 'app-map-skill',
  templateUrl: './map-skill.component.html',
  styleUrls: ['./map-skill.component.scss']
})
export class MapSkillComponent implements OnInit, OnDestroy {
  questionData: MasterSurvey;
  questionDataToDisplay: QuestionMapSkill[] = [];
  sections: MasterSurveySection[] = [];
  selectedQuestions: QuestionMapSkill[] = [];
  skillNames: SkillPage[] = [];
  skillToMap: string = '';
  skillNameToDisplay: string = '';
  mapSkill: { id: number } = {id: 0};
  masterSurveyName: string = '';
  questionTypeEnum: typeof QuestionType = QuestionType;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  @Input() surveyItemMaster!: MasterSurvey;
  disableOnMissingQuestionTxt: boolean = false;
  showLoader: boolean = false;
  isSaveDisabled: boolean = true;
  showSaveSuccessMessage: boolean = false;
  showSaveErrorAlert: boolean = false;
  hasWritePermission: boolean;
  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly masterService: MasterSurveyService,
              public readonly shareSurveyData: ShareSurveyDataService,
              private readonly skillService: SkillsService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.manageSurveyTemplate);
    this.showLoader = true;
    this.getQuestions();
    this.shareSurveyData.subscribeForSurveySaveAlert((hasSuccess: boolean) => {
      this.showSaveSuccessMessage = hasSuccess;
      this.showSaveErrorAlert = !hasSuccess;
      this.isSaveDisabled = hasSuccess;
      setTimeout(() => {
        this.showSaveSuccessMessage = false;
        this.showSaveErrorAlert = false;
      }, 5000);
    });
  }

  onMapSkill(): void {
    this.mapSkill.id = (this.skillNames.find(x => x.skillName === this.skillToMap)).id;
    this.selectedQuestions.forEach(selectedQuestion => {
      for (let sIndex = 0; sIndex < this.questionData?.surveySections?.length; sIndex++) {
        for (let qIndex = 0; qIndex < this.questionData?.surveySections[sIndex]?.sectionQuestions?.length; qIndex++) {
          if (selectedQuestion.sectionSequenceNumber == this.questionData?.surveySections[sIndex]?.sectionSequenceNo &&
            selectedQuestion.questionSequenceNumber == this.questionData?.surveySections[sIndex]?.sectionQuestions[qIndex]?.sectionQuestionSequenceNo) {
            this.questionData.surveySections[sIndex].sectionQuestions[qIndex].skill = {id: this.mapSkill.id}
          }
        }
      }
    });
    this.shareSurveyData.setSurvey(this.questionData);
    this.questionDataToDisplay = [];
    this.skillToMap = "";
    this.isSaveDisabled = false;
    this.mapQuestionsToTable();
    this.setSurveyUpdateStatus();
  }

  getActiveSkills(surveyTemplateConfigVersionId: number): void {
    if (!surveyTemplateConfigVersionId || this.skillNames?.length > 0) {
      return;
    }
    const activeSkillsSubscription = this.skillService.getSkillsByConfigVersionIdAndStatus(surveyTemplateConfigVersionId, Status.Active)
      .subscribe(data => {
        this.skillNames = data;
        this.mapQuestionsToTable();
      });
    this.subscriptions.push(activeSkillsSubscription);

  }

  getQuestions(): void {
    const surveyTemplateSubscription = this.shareSurveyData.getSurvey().subscribe(surveyData => {
      this.questionData = surveyData;
      this.masterSurveyName = surveyData?.masterSurveyName;
      this.getActiveSkills(surveyData?.surveyTemplateConfigVersion?.id);
    });
    this.subscriptions.push(surveyTemplateSubscription);
  }

  mapQuestionsToTable(): void {
    for (let sIndex = 0; sIndex < this.questionData?.surveySections.length; sIndex++) {
      for (let qIndex = 0; qIndex < this.questionData.surveySections[sIndex].sectionQuestions.length; qIndex++) {
        if (this.questionData?.surveySections[sIndex]?.sectionQuestions[qIndex]?.question?.questionText == '') {
          this.disableOnMissingQuestionTxt = true;
        }
        if (this.questionData?.surveySections[sIndex]?.sectionQuestions[qIndex]?.question?.questionType.id != this.questionTypeEnum.RoleProfile &&
        this.questionData?.surveySections[sIndex]?.isTechnicalSection) {
          this.questionDataToDisplay.push({
            id: this.questionData?.surveySections[sIndex]?.sectionQuestions[qIndex]?.id,
            sectionSequenceNumber: this.questionData?.surveySections[sIndex]?.sectionSequenceNo,
            questionSequenceNumber: this.questionData.surveySections[sIndex].sectionQuestions[qIndex]?.sectionQuestionSequenceNo,
            question: this.questionData.surveySections[sIndex].sectionQuestions[qIndex]?.question.questionText,
            section: this.questionData.surveySections[sIndex].section.sectionName,
            skill: this.questionData.surveySections[sIndex].sectionQuestions[qIndex].skill == undefined ? ""
              : this.skillNames.find(x => x.id == this.questionData.surveySections[sIndex]?.sectionQuestions[qIndex]?.skill?.id)?.skillName,
            questionTypeId:this.questionData.surveySections[sIndex].sectionQuestions[qIndex].question?.questionType?.id
          });
        }
      }
    }
    this.questionDataToDisplay = this.questionDataToDisplay.filter(questionval=>{return questionval.questionTypeId === this.questionTypeEnum.RadioButton});
    this.showLoader = false;
  }

  setSurveyUpdateStatus(): void{
    this.masterService.setSurveyUpdateStatus(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
