/*
 *  ********************************************************************
 *  * Copyright 2022 VMware, Inc. All rights reserved. VMware Confidential
 *  * *******************************************************************
 */

export class RegexValidation {

  static readonly EMAIL_REGEX: RegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  //Default free text regex that do not let only empty space inputs.
  static readonly FREE_TEXT_REGEX: RegExp = new RegExp(/^(?!\s+$).+/);
}
