import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {KsaDetails} from "../../../models/master-ksa.model";
import {ResponseService} from '../../../services/response.service';
import {ReportsService} from '../../../services/reports.service';
import {TranslateService} from '@ngx-translate/core';
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from "rxjs/operators";
import {saveAs} from "file-saver";
import {TealiumUtagService} from "../../../services/utag.service";
import {AnalyticsEvents} from "../../../enums/analytics-events.enum";
import {API_KSA_CORE_V1} from "../../../common/constants/api-prefix.constants";
import {UserService} from '../../../services/user.service';
import {Functionality} from '../../../enums/functionality.enum';

@Component({
  selector: 'app-mng-report-templates',
  templateUrl: './mng-report-templates.component.html',
  styleUrls: ['./mng-report-templates.component.scss']
})
export class MngReportTemplatesComponent implements OnInit {

  public reportsUrl: object;
  closedKsaList: KsaDetails[] = [];
  selectedKsaId: number;
  isKsaSelected: boolean = false;
  ksaTemplateName: string = '';
  templateUploadSuccess: boolean = false;
  hasReportDownloadFailed: boolean = false;
  hasWritePermission: boolean;

  constructor(private sanitizer: DomSanitizer,
              private responseService: ResponseService,
              private reportsService: ReportsService,
              private translate: TranslateService,
              private utagService: TealiumUtagService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.reports);
    this.getClosedKsaList();
  }

  getClosedKsaList(): void {
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.responseService.getAllClosedKsa()
        .pipe(finalize(() => hideSpinner())).subscribe(ksaList => {
          this.closedKsaList = ksaList;
        },
        error => {
        });
    });
  }

  getTemplate(id){
    return this.sanitizer.bypassSecurityTrustUrl(`${API_KSA_CORE_V1}/template/download/${id}`);
  }

  uploadTemplate(event): void{
    const fileList: FileList = event.target.files;
    const UploadParticipantDetail: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('file', UploadParticipantDetail);
    formData.append('ksaId', (this.selectedKsaId).toString());
    const headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.reportsService.uploadTemplate(formData, this.selectedKsaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.templateUploadSuccess = true;
        setTimeout(() => {
          this.templateUploadSuccess = false;
        }, 3000);
        this.reportsService.getReportName(this.selectedKsaId).subscribe(data => {
            if (data) {
              this.ksaTemplateName = data.fileName;
              if (this.ksaTemplateName === null) {
                this.ksaTemplateName = '';
              }
              this.reportsUrl = undefined
            }
          },
          error => {
            this.ksaTemplateName = '', this.reportsUrl = undefined
          })
    })
  }

  onSelectKsaDetails(ksaId: number) : void{
    this.selectedKsaId = ksaId;
    if (this.selectedKsaId != null || this.selectedKsaId !== undefined) {
      this.isKsaSelected = true;
      showSpinner(this.translate.instant("spinnerLabels.common.loading"));
      this.reportsService.getReportName(this.selectedKsaId)
        .pipe(finalize(() => hideSpinner()))
        .subscribe(data =>{
        if(data){
        this.ksaTemplateName = data.fileName;
        if(this.ksaTemplateName === null){
          this.ksaTemplateName = '';
        }
        this.reportsUrl = undefined}
      },
      error => {this.ksaTemplateName = '',this.reportsUrl = undefined})
    }
  }

  viewPdf(): void {
    this.reportsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${API_KSA_CORE_V1}/reports/${this.selectedKsaId}/report`);
  }

  loadPdfAfterBtnClick(){
    const iframe = <HTMLElement>document.getElementById('iframeData') as HTMLIFrameElement;
    if(!iframe.contentWindow.document.body.innerHTML){
      document.getElementById('loadImg').style.display='block';
    }
    if(iframe.contentWindow.document.body.innerHTML){
      document.getElementById('loadImg').style.display='none';
    }
  }

  downloadDocx() {
    showSpinner(this.translate.instant('tab.reports.downloadReportProgress'));
    this.reportsService.downloadReport(this.selectedKsaId)
      .pipe(finalize(hideSpinner))
      .subscribe(receivedFile => {
        this.hasReportDownloadFailed = false;
        const fileName: string = this.reportsService.extractFileNameFromContentDispositionHeader(receivedFile.headers);
        saveAs(receivedFile.body, fileName);
        this.utagService.trackEvent(AnalyticsEvents.DOWNLOADED_KSA_REPORT);
      }, () => {
        this.hasReportDownloadFailed = true;
      });
  }
}
