/********************************************************************** *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/

import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Analysis} from '../../../enums/analaysis.enum';
import {CertificateDetails} from '../../../models/certification.model';
import {CertificateService} from '../../../services/certificate.service';
import {Functionality} from "../../../enums/functionality.enum";
import {ClrDatagridStateInterface} from "@clr/angular";
import {ResponseColumnSettings} from "../../../models/respose-data.model";
import {ResponseService} from "../../../services/response.service";
import {TranslateService} from '@ngx-translate/core';
import {AnalysisService} from "../../../services/analysisService";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {finalize} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";
import {PageSizeService} from "../../../services/page-size.service";

@Component({
  selector: 'app-cert-report',
  templateUrl: './cert-report.component.html',
  styleUrls: ['./cert-report.component.scss']
})
export class CertReportComponent implements OnInit {

  @Input() ksaId: number;
  certificateDetails: CertificateDetails;
  showDataGrid = false;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  messageSuccessTimer: boolean = true;
  analysisEnum: typeof Analysis = Analysis;
  functionality: typeof Functionality = Functionality;
  @Input() showNewWindowButton: boolean = true;
  showLoader: boolean;
  showRestoreResponse: boolean;
  isResetResponse: boolean;
  page: number;
  loading: boolean = false;
  total: number;
  state: ClrDatagridStateInterface;
  responseColumnSettings: (ResponseColumnSettings)[];
  isSavedResponse: boolean;
  pinnedColumn: string;

  constructor(private certificateService: CertificateService,
              private router: Router,
              public responseService: ResponseService,
              private cdr: ChangeDetectorRef,
              public analysisService: AnalysisService,
              private readonly translate: TranslateService,
              public readonly pageSizeService: PageSizeService) {
  }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.showDataGrid = false;
    if (this.ksaId) {
      this.operations();
    }
  }

  async operations() {
    await this.getAllResponseColumnData();
  }

  getAllResponseColumnData(): void {
    this.responseService.getAllResponseColumnData(this.ksaId).subscribe(data => {
      if (data) {
        this.showDataGrid = true;
        this.responseColumnSettings = data;
      }
    });
  }

  setPinnedColumn(questionName: string) {
    this.pinnedColumn = '';
    this.pinnedColumn = questionName;
  }

  onSaveResponseColumns(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.responseService.updateResponseColumnData(this.responseColumnSettings)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      if (data) {
        this.responseColumnSettings = data;
        this.isSavedResponse = true;
      }
      setTimeout(() => {
        this.isSavedResponse = false;
      }, 5000);
    })
  }

  onSaveButton(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.showDataGrid = false;
    this.messageSuccessTimer = true;
    this.certificateService.updateCertificateData(this.certificateDetails)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(certificateData => {
        this.certificateDetails = certificateData;
        this.onRefresh();
        this.certificateDetails.ksaId = this.ksaId;
        this.showSuccessMessage = true;
        setTimeout(() => {
          this.messageSuccessTimer = false;
        }, 3000);
        this.showErrorMessage = false;
      },
      error => {
        this.showSuccessMessage = false;
        this.showErrorMessage = true;
      });
    this.showDataGrid = true;
  }

  mapCertificateDataOnView(): void {
    this.certificateDetails.certificateData.forEach(certificateData => {
      certificateData.certificationByParticipants.forEach(participantData => {
        participantData[this.analysisEnum.showResultAchieved] = participantData.resultAchieved;
        participantData[this.analysisEnum.showResultDesired] = participantData.resultDesired;
      });
    });
  }

  onOpenNewWindow(): void {
    window.open(`${environment.API_URL}` + '/#' + '/AnalysisReport/' + 'Certification' + '/' + this.ksaId, '_blank', 'location=yes,height=1000,width=1000,scrollbars=yes,status=yes');
  }

  onRefresh(): void {
    this.certificationRefresh(this.state);
  }

  onShowOriginalData(): void {
    this.router.navigate([]).then((result) => {
      window.open(`${environment.API_URL}` + '/#/CertificateOriginalData/' + this.ksaId, '_blank');
    });
  }

  onResetData(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.showRestoreResponse = false;
    const data = {
      ksaId: this.ksaId
    };
    this.certificateService.resetDataCertificate(data)
      .pipe(finalize(() => hideSpinner())).subscribe(data => {
      if (data) {
        this.certificateDetails = data;
        this.certificateDetails.ksaId = this.ksaId;
        this.isResetResponse = true;
        this.onRefresh();
      }
      setTimeout(() => {
        this.isResetResponse = false;
      }, 5000);
    });
  }

  certificationRefresh(state: ClrDatagridStateInterface) {
    this.pageSizeService.setPageSize(state?.page?.size);
    this.state = state;
    this.loading = true;
    const colFilters: ({ columnName: string; columnValue: string; })[] = [];
    let columnName = '';
    let data;
    if (state?.filters) {
      state.filters.forEach((filter, i) => {
        const {property, value} = <{ property: string; value: string }>filter;
        colFilters.push({columnValue: value, columnName: property});
        columnName = property;
      })
      data = {
        ksaId: this.ksaId,
        size: state?.page ? (state?.page?.size) : 10,
        sortColumn: columnName,
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false) ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0,
        analysisFilterDTOS: colFilters
      }
    } else {
      data = {
        ksaId: this.ksaId,
        size: state?.page ? (state?.page?.size) : 10,
        sortColumn: state?.sort ? state.sort.by : "",
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false) ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0
      }
    }
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.certificateService.updateCertification(data)
        .pipe(finalize(() => hideSpinner()))
        .subscribe((results: CertificateDetails) => {
          this.certificateDetails = results;
          this.total = results.total;
          this.loading = false;
        });
    });

  }
}
