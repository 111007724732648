/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {KsaAdministrationService} from '../../../services/ksa-administration.service';
import {MasterSurveyService} from '../../../services/master-survey.service';
import {UsersService} from '../../../services/users.service';
import {MasterKsaSimple} from '../../../models/master-ksa.model';
import {KsaShareService} from '../../../services/ksa-share.service';
import {KsaStatus} from '../../../enums/status.enum';
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {TealiumUtagService} from "../../../services/utag.service";
import {AnalyticsEvents} from "../../../enums/analytics-events.enum";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {TranslateService} from "@ngx-translate/core";
import {finalize, map} from "rxjs/operators";
import {DatePipe} from "@angular/common";
import {UpdateMasterKsaStatus} from "../../../models/update-master-ksa-status";
import {UserCacheService} from '../../../services/user-cache.service';
import {User} from '../../../models/auth-user.model';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-ksa-administration',
  templateUrl: './ksa-administration.component.html',
  styleUrls: ['./ksa-administration.component.scss']
})
export class KsaAdministrationComponent implements OnInit {
  ksa: MasterKsaSimple[] = [];
  dateToFilter: string = "";
  statusToFilter: string = '';
  state: ClrDatagridStateInterface;
  page: number;
  loading: boolean = false;
  total: number;
  selectedKsas: any[] = [];
  errorMsg: string = '';
  statusEnum: typeof KsaStatus = KsaStatus;
  surveyStatusOption: any = Object.keys(this.statusEnum);
  showKsaTable: boolean = true;
  showDeleteWarning: boolean = false;
  deleteId: number;
  isUserEditingKsa = false;

  functionality: typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;
  inValidDate: boolean = false;
  selectedKsa: any = [];
  showMultiDeleteWarning: boolean = false;
  hasWritePermission: boolean;

  constructor(public readonly ksaAdministrationService: KsaAdministrationService,
              public readonly usersService: UsersService,
              public readonly masterSurveyService: MasterSurveyService,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly ksaShareService: KsaShareService,
              private readonly utagService: TealiumUtagService,
              private readonly translate: TranslateService,
              private readonly datePipe: DatePipe,
              private readonly userCacheService: UserCacheService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.ksaAdministration);
    this.getAllKsas();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  getAllKsas(): void {
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.loading = true;
      this.ksaAdministrationService.getKsaEnhanced()
        .pipe(
          map(surveys => surveys.map(survey => this.preProcessSurveyData(survey))),
          finalize(() => hideSpinner()),
        )
        .subscribe(surveys => {
          this.ksa = surveys;
          this.loading = false;
        })
    });
  }

  onEditKsaDetails(id: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaAdministrationService.getMasterKsa(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe((masterKsa: MasterKsaSimple) => {
        const currentUser: User = this.userCacheService.getLoginDataFromLocalStorage();
        if (!masterKsa.editingUser || masterKsa.editingUser?.emailId === currentUser?.email) {
          this.ksaAdministrationService.updateMasterKsaEditor(id).subscribe(editingInfo => {
            masterKsa.editingUser = editingInfo.editingUser;
            masterKsa.lastEditedOn = editingInfo.lastEditedOn;
            this.ksaShareService.setKSADetails(masterKsa);
            this.showKsaTable = false;
            this.ksaAdministrationService.setEditingUserData(masterKsa);
          });
          return;
        }

        this.ksaAdministrationService.setEditingUserData(masterKsa);
        this.isUserEditingKsa = true;
      });
  }

  onActivateKsa(ksaId): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaAdministrationService.activateKsa(ksaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(response => {
          this.updateSingleMasterSurveyDataUI(response);
          this.trackKsaActivation(response);
        },
        error => this.errorMsg = error.error.message);
    setTimeout(() => {
      this.errorMsg = '';
    }, 5000);
  }

  onCloseKsa(ksaId: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaAdministrationService.closeKsa(ksaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(response => {
          this.updateSingleMasterSurveyDataUI(response);
          this.utagService.trackEvent(AnalyticsEvents.CLOSED_KSA);
        },
        error => this.errorMsg = error.error.message);
  }

  onExpireKsa(ksaId: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaAdministrationService.expireKsa(ksaId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(response => this.updateSingleMasterSurveyDataUI(response),
        error => this.errorMsg = error.error.message);
  }

  onDeleteKsa(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaAdministrationService.deleteKsa(this.deleteId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(() => {
          this.getAllKsas();
          this.showDeleteWarning = false;
          this.deleteId = 0;
          this.errorMsg = '';
          this.utagService.trackEvent(AnalyticsEvents.DELETED_KSA);
        },
        error => this.errorMsg = error.error.message);
  }

  onShowDeleteWarning(id: number): void {
    this.deleteId = id;
    this.showDeleteWarning = true;
  }

  onDeleteMultipleKsa(): void {
    const ids: any[] = [];
    this.selectedKsa.forEach(function (value) {
      ids.push(value.id);
    });
    if (ids.length > 0) {
      showSpinner(this.translate.instant("spinnerLabels.common.loading"));
      this.ksaAdministrationService.deleteMultipleKsa(ids)
        .pipe(finalize(() => hideSpinner()))
        .subscribe(data => {
            this.getAllKsas();
            this.showMultiDeleteWarning = false;
            this.errorMsg = '';
            this.utagService.trackEvent(AnalyticsEvents.DELETED_KSA);
          },
          error => this.errorMsg = error.error.message);
    }
    this.selectedKsas = [];
  }

  OnDeleteMultiSelectKsa(selectedKsa): void {
    if (selectedKsa.length !== 0) {
      this.showMultiDeleteWarning = true;
    }
    this.selectedKsa = selectedKsa;
  }

  resetError(): void {
    this.deleteId = 0;
    this.errorMsg = '';
  }

  getStatusFilter(status: string): void {
    this.statusToFilter = status;
    this.loading = true;
    this.ksaAdministrationService.getKsaEnhanced().subscribe(data => {
      status ? this.ksa = data.filter(data => data.status === status) :
        this.ksa = data;
      this.ksa.forEach(survey => {
        survey.membersList = survey.members.split(",");
      });
      this.loading = false;
    })
  }

  getDateFilter(date: string): void {
    this.dateToFilter = date;
    this.loading = true;
    this.ksaAdministrationService.getKsaEnhanced().subscribe(survey => {
      this.ksa = date ? survey.filter(data => data.expirationDate.split("T")[0] === date) : survey;
      this.ksa.forEach(ksaSurvey => {
        ksaSurvey.membersList = ksaSurvey.members.split(",");
      });
      this.loading = false;
    })
  }

  onAddKsaSurveyBtn(): void {
    this.showKsaTable = false;
  }

  onShowKsaTable(data): void {
    this.showKsaTable = data;
    this.getAllKsas();
  }

  private trackKsaActivation(ksaData: any): void {
    const differenceInMinutes = Math.round(Math.abs((Date.parse(ksaData.lastModifiedDate) || 0) - (Date.parse(ksaData.createdDate) || 0)) / 60000);
    this.utagService.trackTimeSpentEvent(AnalyticsEvents.ACTIVATED_KSA, differenceInMinutes);
  }

  timeBetweenDates(from: Date, to: Date): string {
    const milisecInADay: number = 24 * 60 * 60 * 1000.0;
    const duration: number = Math.floor((Number(to) - Number(from)) / milisecInADay);
    return duration + " " + this.translate.instant(duration === 1 ? 'ksaEngagement.ksaAdministration.day' : 'ksaEngagement.ksaAdministration.days');
  }

  getDuration(ksa: MasterKsaSimple): string {
    if (this.isInStatusDraft(ksa)) {
      return this.translate.instant('ksaEngagement.ksaAdministration.notActiveYet');
    }
    if (!ksa.invitedAt) {
      return this.translate.instant('ksaEngagement.ksaAdministration.noInvitations');
    }
    if (!ksa.closedAt && ksa.status != KsaStatus.Active) {
      return this.translate.instant('ksaEngagement.ksaAdministration.NA');
    }
    const firstInviteDate: Date = new Date(ksa.invitedAt);
    const lastDate: Date = new Date(ksa.closedAt || new Date());
    return this.timeBetweenDates(firstInviteDate, lastDate);
  }

  isInStatusDraft(ksa: MasterKsaSimple): boolean {
    return ksa.status === KsaStatus.Draft;
  }

  getFirstInviteText(ksa: MasterKsaSimple): string {
    if (this.isInStatusDraft(ksa)) {
      return this.translate.instant('ksaEngagement.ksaAdministration.notActiveYet');
    }
    if (!ksa.invitedAt) {
      return this.translate.instant('ksaEngagement.ksaAdministration.noInvitations');
    }
    return this.datePipe.transform(ksa.invitedAt);
  }

  getCloseDateText(ksa: MasterKsaSimple): string {
    if (this.isInStatusDraft(ksa)) {
      return '-';
    }
    if (ksa.status == KsaStatus.Active) {
      return this.translate.instant('ksaEngagement.ksaAdministration.ongoing');
    }
    if (!ksa.closedAt) {
      return this.translate.instant('ksaEngagement.ksaAdministration.NA');
    }
    return this.datePipe.transform(ksa.closedAt);
  }

  private updateSingleMasterSurveyDataUI(updatedKsaData: UpdateMasterKsaStatus): void {
    const masterKsa = this.ksa.filter(ksa => ksa.id === updatedKsaData.id)[0]

    masterKsa.status = updatedKsaData.status;
    masterKsa.closedAt = updatedKsaData.closedDate;
    masterKsa.lastEditedOn = updatedKsaData.lastModifiedDate;
    masterKsa.expirationDate = updatedKsaData.expirationDate;

    this.preProcessSurveyData(masterKsa);
  }

  private preProcessSurveyData(masterKsa: MasterKsaSimple): MasterKsaSimple {
    masterKsa.membersList = masterKsa.members.split(",");
    masterKsa.duration = this.getDuration(masterKsa);
    return masterKsa;
  }

}
