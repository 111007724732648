/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Observable, throwError} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {HttpStatusCodes} from "../enums/http-status-codes.enum";
import {UserCacheService} from "./user-cache.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private userCacheService: UserCacheService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((response: HttpEvent<any>) => {
        if (response instanceof HttpResponse && response.status == HttpStatusCodes.OK) {
          this.userCacheService.saveExpirationTimestampToLocalStorage();
        }
      }), map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((httpErrorResponse: HttpErrorResponse,
                  _: Observable<HttpEvent<any>>) => {
          if (httpErrorResponse.status === HttpStatusCodes.UNAUTHORIZED) {
            this.authService.logout();
          }
          return throwError(httpErrorResponse);
        }
      )
    );
  }
}
