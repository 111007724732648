import {DatePipe} from '@angular/common';
import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import moment from 'moment';
import {Status} from '../../../../enums/status.enum';
import {KsaAdministrationService} from '../../../../services/ksa-administration.service';
import {KsaShareService} from '../../../../services/ksa-share.service';
import {hideSpinner, showSpinner} from "../../../../common/spinner";
import {filter, finalize} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from '../../../../services/user.service';
import {Functionality} from '../../../../enums/functionality.enum';

@Component({
  selector: 'app-ksa-details',
  templateUrl: './ksa-details.component.html',
  styleUrls: ['./ksa-details.component.scss']
})
export class KsaDetailsComponent implements OnInit {
  @Output() showPreviewTab = new EventEmitter<boolean>();
  showSpinner = false;
  surveySaveSuccessAlert = false;
  errorMessage = false;
  messageSuccessTimer = true;
  ksaDetails: any;
  ksaSectionData: any[] = [];
  ksaName: string;
  selectedSurveyName: string;
  selectedSurveyVersion: number;
  selectedKSAValidTill: any;
  selectedSectionData: any;
  currentDate = '';
  now = new Date();
  minDate = moment().format('YYYY-MM-DD');
  datePipe = new DatePipe("en-US");
  masterSurveyId = '';
  disableSaveBtnWhileSaving = false;
  showMandatoryTypePopUp = false;
  hasWritePermission: boolean;

  constructor(private readonly ksaAdministrationService: KsaAdministrationService,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly ksaShareService: KsaShareService,
              private readonly translate: TranslateService,
              private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.ksaAdministration);
    this.ksaShareService.getKSADetails()
      .pipe(filter(data => !!data))
      .subscribe(data => {
        this.assignKSAData(data)
        this.selectedKSAValidTill = this.datePipe.transform(data.validTill, 'MM-dd-yyyy');
      });
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  assignKSAData(ksaDetails: any): void {
    this.ksaDetails = ksaDetails;
    this.ksaName = ksaDetails?.ksaName;
    this.masterSurveyId = ksaDetails.masterSurvey.id;
    this.selectedSurveyName = this.ksaDetails.masterSurvey.masterSurveyName;
    this.selectedSurveyVersion = this.ksaDetails.masterSurvey.version;
    this.ksaDetails.ksaSection.sort((previousSection, nextSection) => (previousSection.sectionSequenceNo > nextSection.sectionSequenceNo ? 1 : -1));
    this.ksaSectionData = this.ksaDetails.ksaSection.filter(ksaSection => (ksaSection.section && !ksaSection.section.isTechnicalSection));
  }

  onSaveKSA(): void {
    this.ksaDetails.ksaSection.map(ksaSection => {
      const tempId = ksaSection.section.id;
      const isSectionMandatoryTemp = ksaSection.section.isMandatorySection;
      ksaSection.section = {};
      ksaSection.section.id = tempId;
      ksaSection.section.isMandatorySection = isSectionMandatoryTemp;
      ksaSection.ksaTechnicalQuestions.map(question => {
        const questionTechId = question.sectionQuestion.id;
        question.sectionQuestion = {};
        question.sectionQuestion.id = questionTechId;
      })
      ksaSection.ksaQuestions.map(question => {
        const questionNonTechId = question.section.id;
        question.section = {};
        question.section.id = questionNonTechId;
      })
    })
    this.disableSaveBtnWhileSaving = true;
    this.ksaDetails.validTill = this.datePipe.transform(this.selectedKSAValidTill, 'YYYY-MM-dd');
    delete this.ksaDetails.ksaJobProfileSets;
    this.ksaDetails.masterSurvey = {};
    this.ksaDetails.masterSurvey.id = this.masterSurveyId;
    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.ksaAdministrationService.updateKsa(this.ksaDetails)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.ksaShareService.setKSADetails(data);
          this.surveySaveSuccessAlert = true;
          this.messageSuccessTimer = true;
          this.errorMessage = false;
          this.disableSaveBtnWhileSaving = false;
        },
        () => {
          this.surveySaveSuccessAlert = false;
          this.errorMessage = true;
          this.disableSaveBtnWhileSaving = false;
        });
    setTimeout(() => this.messageSuccessTimer = false, 3000);
  }

  openMandatorySectionPopup(sectionData: any): void {
    this.selectedSectionData = sectionData;
    this.showMandatoryTypePopUp = true;
  }

  onCancelMandatorySelection(): void {
    this.showMandatoryTypePopUp = false;
    this.selectedSectionData = null;
  }

  onMandatorySectionInput(isChecked: boolean): void {
    this.selectedSectionData.section.isMandatorySection = isChecked;
  }

  onSaveMandatorySelection(): void {
    this.showMandatoryTypePopUp = false
    this.ksaDetails.ksaSection
      .filter(ksaSection => ksaSection.id === this.selectedSectionData.id)
      .forEach(ksaSection => ksaSection.section.isMandatorySection = this.selectedSectionData.section.isMandatorySection);
  }

  onQuestionUpdated(question: any): void {
    this.ksaDetails.ksaSection
      .filter(section => section.id === question.section.id)
      .flatMap(section => section.sectionQuestions)
      .filter(sectionQuestion => sectionQuestion.id === question.sectionQuestionId)
      .forEach(sectionQuestion => sectionQuestion.isMandatory = question.isMandatory);
  }

  get statusEnum(): typeof Status {
    return Status;
  }
}
