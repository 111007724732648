/*******************************************************************************
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 ******************************************************************************/

import {NotificationDialogAction} from "./notification-dialog-action.model";
import {ComponentFactory, ComponentRef} from "@angular/core";

export class ClrNotificationAlert {
   constructor(private alertMessage: string = '', private alertType: string = '') {
   }

   getAlertMessage(): string {
      return this.alertMessage;
   }

   setAlertMessage(value: string): void {
      this.alertMessage = value || '';
   }

   getAlertType(): string {
      return this.alertType;
   }

   setAlertType(value: string): void {
      this.alertType = value || '';
   }
}

export class ClrNotificationDialogOptions {

   /**
    * The title of the notification dialog
    */
   readonly title: string;

   /**
    * A message to display in the body
    */
   readonly message: string;

   listOfActions: Array<NotificationDialogAction>;

   /**
    * A component to display as a the body of the dialog
    */
   readonly component: ComponentFactory<any>;

   /**
    * A component ref displayed in the dialog
    */
   componentRef: ComponentRef<any>;

   /**
    * The confirm action
    * @see NotificationDialogAction
    */
   readonly rejectAction: NotificationDialogAction;

   showOnlyOkButton: boolean;
   hideCancelButton: boolean;
   size: string;

   /**
    * @see ClrNotificationAlert
    */
   readonly alert: ClrNotificationAlert;

   shouldDisableConfirmAction: boolean;

   constructor(params: Partial<ClrNotificationDialogOptions>) {
      this.title = params.title || '';
      this.message = params.message || '';
      this.component = params.component;
      this.listOfActions = params.listOfActions || [];
      this.rejectAction = params.rejectAction;
      this.showOnlyOkButton = params.showOnlyOkButton || false;
      this.alert = params.alert || new ClrNotificationAlert();
      this.shouldDisableConfirmAction = params.shouldDisableConfirmAction || false;
      this.hideCancelButton = params.hideCancelButton;
      this.rejectAction = params.rejectAction || null;
      this.size = params.size || '';
   }

}
