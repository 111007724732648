/*
 *  *********************************************************************
 *  * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class PageSizeService {

   private readonly defaultPageSize: number = 10;
   private readonly pageSizeSubject: BehaviorSubject<number>;

   private readonly pageSizeStorageKey: string = 'pageSize';

   constructor() {
      const initialSize = localStorage.getItem(this.pageSizeStorageKey) ? +localStorage.getItem(this.pageSizeStorageKey) : this.defaultPageSize;
      this.pageSizeSubject = new BehaviorSubject<number>(initialSize);
   }

   getPageSize(): Observable<number> {
      return this.pageSizeSubject.asObservable();
   }

   setPageSize(size: number): void {
      if (size) {
         localStorage.setItem(this.pageSizeStorageKey, String(size));
         this.pageSizeSubject.next(size);
      }
   }

}
