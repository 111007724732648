/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {SharedAuthService} from '../services/shared-auth.service';
import {UserCacheService} from "../services/user-cache.service";

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
  private currentUser;
  private permissions = [];
  private logicalOp = 'AND';
  private isHidden = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: SharedAuthService,
    private userCacheService: UserCacheService
  ) {

  }

  ngOnInit() {
    this.currentUser = this.userCacheService.getLoginDataFromLocalStorage();
    this.updateView();
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hasPermissionOp(permop) {
    this.logicalOp = permop;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    //converting hashmap to array for find method use
    const permissionArr = this.currentUser?.permissionMap ? Object.values(this.currentUser?.permissionMap) : [];

    if (this.currentUser && this.currentUser?.permissionMap) {
      for (const checkPermission of this.permissions) {
        const permissionFound = permissionArr.find((x: any) => x.screen.toUpperCase() === checkPermission.toUpperCase() && (x.read || x.write));

        if (permissionFound) {
          hasPermission = true;
          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }

    return hasPermission;
  }
}
