  <div *ngIf="surveySaveSuccessAlert && messageSuccessTimer && !errorMessage" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{'ksaEngagement.masterKsa.message.ksaSavedMsg'|translate}}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="errorMessage && !surveySaveSuccessAlert" class="alert alert-danger" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="error-standard"></cds-icon>
        </div>
        <span class="alert-text">
          {{'ksaEngagement.masterKsa.message.errorMsgUpdate'|translate}}
        </span>
      </div>
    </div>
  </div>
  <div class="add-edit-btn-container clear">
    <button class="btn btn-sm btn-primary" type="button" (click)="onUpdateKsa()" *ngIf="hasWritePermission"
            [disabled]="(newKsaData.isAdvance && roleProfileSet == null) || disableSaveBtnWhileSaving">{{'admin.saveBtn' | translate}}</button>
  </div>
  <div *ngIf="!newKsaData.isAdvance" class="ksaSetting">
    <h5 class="clr-control-label form-label">{{'ksaEngagement.ksaAdministration.simpleKsaSettings'|translate}}</h5>
    <clr-radio-container>
      <label>{{'ksaEngagement.ksaAdministration.surveyQuestionMode'|translate}}</label>
      <clr-radio-wrapper *ngFor="let surveyQuestionMode of surveyQuestionModes">
        <input (change)="setSurveyQuestionMode(surveyQuestionMode?.id)"
               [checked]="surveyQuestionMode.id == newKsaData?.surveyQuestionMode?.id"
               [hidden]="surveyQuestionMode.id == questionModeEnum.RoleProfileDriven"
               clrRadio name="options" required type="radio"
               [disabled]="newKsaData.status == statusEnum.Active|| newKsaData.status == statusEnum.Closed || !hasWritePermission"/>
        <label [hidden]="surveyQuestionMode.id == questionModeEnum.RoleProfileDriven">{{surveyQuestionMode.surveyQuestionModeName}}</label>
      </clr-radio-wrapper>
    </clr-radio-container>
    <clr-input-container class="clr-row">
      <label class="clr-col-3">{{'ksaEngagement.ksaAdministration.recommendedThreshold'|translate}}(%)*</label>
      <input class="clr-col-1" [(ngModel)]="newKsaData.globalThreshold" clrInput name="threshold"
             placeholder="{{'placeholder.zeroThreshold' | translate}}" required type="number"
             [disabled]="newKsaData.status == statusEnum.Active || !hasWritePermission"
             oninput="if(this.value > 100 || this.value < 0) {this.value = null;}"/>
      <clr-control-error>{{'errorMsg.thresholdRequired' | translate}}</clr-control-error>
    </clr-input-container>
    <br>
    <div *ngIf="newKsaData.surveyQuestionMode.id==2">
      <label class="clr-control-label form-label">
        {{'ksaEngagement.ksaAdministration.technicalSections'|translate}}
            </label>
                <clr-checkbox-container clrInline>
                  <clr-checkbox-wrapper
                    *ngFor="let section of ksaSections;let i = index" class="sections-list">
                    <input type="checkbox" clrCheckbox name="'options'+i"
                           [(ngModel)]="section.isQuestionSectionsSelected"
                           [disabled]="newKsaData.status == statusEnum.Active|| newKsaData.status == statusEnum.Closed || !hasWritePermission"/>
                    <label>{{section?.sectionName}}</label>
                  </clr-checkbox-wrapper>
                  <clr-control-error>{{'errorMsg.fieldRequired' | translate}}</clr-control-error>
                </clr-checkbox-container>
        </div>
    </div>
    <div *ngIf="newKsaData?.isAdvance" class="ksaSetting">
        <h5 class="clr-control-label form-label">{{'ksaEngagement.ksaAdministration.advanceKsaSettings'|translate}}</h5>
        <clr-radio-container>
            <label>{{'ksaEngagement.ksaAdministration.surveyQuestionMode'|translate}}</label>
            <clr-radio-wrapper *ngFor="let surveyQuestionMode of surveyQuestionModes">
              <input (change)="setSurveyQuestionMode(surveyQuestionMode.id)" clrRadio name="options" required
                     type="radio" [checked]="surveyQuestionMode.id == newKsaData?.surveyQuestionMode?.id"
                     [disabled]="newKsaData.status == statusEnum.Active|| newKsaData.status == statusEnum.Closed|| !hasWritePermission"/>
                <label>{{surveyQuestionMode.surveyQuestionModeName}}</label>
            </clr-radio-wrapper>
        </clr-radio-container>
        <clr-select-container class="role-profile-select">
            <label>{{'ksaEngagement.ksaAdministration.roleProfileSet'|translate}}*</label>
          <select class="solution-group-select-input input-field" clrSelect name="roleProfileSetImported"
                  [(ngModel)]="roleProfileSet"
                  [disabled]="newKsaData.status == statusEnum.Active|| !hasWritePermission">
            <option disabled selected
                    value="">{{'ksaEngagement.ksaAdministration.selectRoleProfileSet'|translate}}</option>
            <option *ngFor="let item of roleProfileSets.content" [ngValue]="item.id">{{item.name}}
            </option>
          </select>
            <clr-control-error>{{'errorMsg.roleProfileSetIsRequired'|translate}}</clr-control-error>
        </clr-select-container>
      <div *ngIf="newKsaData.surveyQuestionMode.id==2">
        <br>
        <label class="clr-control-label form-label">
          {{'ksaEngagement.ksaAdministration.technicalSections'|translate}}
        </label>
        <clr-checkbox-container clrInline>
          <clr-checkbox-wrapper
            *ngFor="let section of ksaSections;let i = index" class="sections-list">
            <input type="checkbox" clrCheckbox name="'options'+i"
                   [(ngModel)]="section.isQuestionSectionsSelected"
                   [disabled]="newKsaData.status == statusEnum.Active|| newKsaData.status == statusEnum.Closed|| !hasWritePermission"/>
            <label>{{section?.sectionName}}</label>
          </clr-checkbox-wrapper>
          <clr-control-error>{{'errorMsg.fieldRequired' | translate}}</clr-control-error>
        </clr-checkbox-container>
      </div>
    </div>
