import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-tab-reports',
  templateUrl: './tab-reports.component.html',
  styleUrls: ['./tab-reports.component.scss']
})
export class TabReportsComponent implements OnInit {

  reportEditorSelected: boolean = false;
  scorecardsEditorSelected: boolean = false;


  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.reportEditorSelected = true;
  }
}
