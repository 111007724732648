import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {ClarityModule} from '@clr/angular';

import {FiltersRoutingModule} from './filters-routing.module';
import {StatusFilterComponent} from "./status-filter/status-filter.component";
import {DateFilterComponent} from "./date-filter/date-filter.component";
import {CheckboxFilterComponent} from "./checkbox-filter/checkbox-filter.component";

@NgModule({
  declarations: [
    DateFilterComponent,
    CheckboxFilterComponent,
    StatusFilterComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    CommonModule,
    ClarityModule,
    FiltersRoutingModule
  ],
  exports: [
    DateFilterComponent,
    CheckboxFilterComponent,
    StatusFilterComponent
  ],
})
export class FiltersModule {
}
