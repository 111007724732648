/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {Response, ResponseColumnSettings} from "../models/respose-data.model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  private readonly responseUrl: string;

  constructor(private http: HttpClient) {
    this.responseUrl = `${environment.API_URL}${API_KSA_CORE_V1}/response`;
  }

  getAllClosedKsa(): Observable<any[]> {
    return this.http.get<any[]>(`${this.responseUrl}/closedksa`);
  }

  getAllClosedNonAnonymousKsa(): Observable<any[]> {
    return this.http.get<any[]>(`${this.responseUrl}/closedNonAnonymousKsa`);
  }

  getAllResponseColumnData(ksaId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.responseUrl}/setting/${ksaId}`);
  }

  getAllResponseData(ksaId: number): Observable<Response> {
    return this.http.get<Response>(`${this.responseUrl}/${ksaId}`);
  }

  updateResponseColumnData(response: (ResponseColumnSettings)[]): Observable<any> {
    return this.http.put(`${this.responseUrl}/setting/`, response);
  }

  updateResponseData(response): Observable<Response> {
    return this.http.put(`${this.responseUrl}/`, response);
  }

  resetData(data): Observable<any> {
    return this.http.post(`${this.responseUrl}/reset`, data);
  }

  getOriginalResponseData(ksaId: number): Observable<Response> {
    return this.http.get<Response>(`${this.responseUrl}/original/` + `${ksaId}`);
  }

  updateResponse(data): Observable<Response> {
    return this.http.post(`${this.responseUrl}/filter`, data);
  }

  updateOriginalResponse(data): Observable<Response> {
    return this.http.post(`${this.responseUrl}/filter/original`, data);
  }

  downloadCsvFile(ksaId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.responseUrl}/${ksaId}/csv`, {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
