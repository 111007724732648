import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UsersService} from '../../../services/users.service';
import {User} from '../../../models/user.model';
import {NgForm} from "@angular/forms";
import {ClrDatagridStateInterface} from '@clr/angular';
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {TranslateService} from '@ngx-translate/core';
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-mng-users',
  templateUrl: './mng-users.component.html',
  styleUrls: ['./mng-users.component.scss']
})
export class MngUsersComponent implements OnInit {
  state: ClrDatagridStateInterface;
  page: number;
  loading: boolean = false;
  total: number;
  users: User = {
    content: []
  };
  allRoles: any[] = [];
  selected: any = [];
  selectedRoles: any = [];
  isEmailValidationMsg: boolean = false
  emailValidationMsg = "Email Id does not exit in active directory";
  roles: any = {
    content: []
  };
  editUser: any = {
    id: 0,
    firstName: "",
    lastName: "",
    emailId: "",
    userRoleMaps: []
  };
  addUser = {
    id: 0,
    firstName: "",
    lastName: "",
    emailId: "",
    userRoleMaps: []
  };
  roleSelection: any[];
  showDeleteUserPopUp: boolean = false;
  deleteId: number;
  errorMsg: string = '';

  addUserFormOpen: boolean = false;
  editUserFormOpen: boolean = false;
  showMultideletePopup:boolean = false;
  emailRegex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";

  functionality : typeof Functionality = Functionality;
  btnId : typeof ButtonId = ButtonId;
  selectedUser: any[];

  constructor(private service: UsersService, private changeDetector: ChangeDetectorRef,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.populateRoles();
  }
  ngAfterContentInit(): void {
    this.changeDetector.detectChanges();
  }

  onAddUser(): void {
    this.errorMsg = '';
    this.addUserFormOpen = true;
    this.isEmailValidationMsg = false;
  }

  onEditUser(user: any): void {
    this.errorMsg = '';
    this.editUserFormOpen = true;
    this.editUser = user;
    this.getUserById(user.id);
  }

  onResetUser(form:NgForm): void {
    this.refresh(this.state);
    form.reset();
    this.editUserFormOpen = false;
    this.editUser = {
      id: 0,
      firstName: "",
      lastName: "",
      emailId: "",
      userRoleMaps: []
    }
    this.errorMsg = ''
  }

  onCancelAddingUser(form: NgForm): void {
    form.reset();
    this.addUserFormOpen = false;
    this.addUser = {
      id: 0,
      firstName: "",
      lastName: "",
      emailId: "",
      userRoleMaps: []
    };
    this.errorMsg = ''
  }

  getUserById(id: number): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.service.getUserById(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.allRoles = data;
      this.editUser.userRoleMaps = data.filter((e) => {
        return e.isSelected === true
      })
    },
      error => console.log(error));
  }

  populateRoles(): void {
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.service.getRoles()
        .pipe(finalize(() => hideSpinner()))
        .subscribe((results) => {
          this.roles = results;
        })
    });

  }

  onUpdateUser(form: NgForm): void {
    if (form.invalid || this.editUser.userRoleMaps.length === 0) {
      return
    }

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.service.UpdateUser(this.editUser)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.refresh(this.state);
      this.editUser = {
        id: 0,
        firstName: "",
        lastName: "",
        emailId: "",
        userRoleMaps: []
      };
      this.editUserFormOpen = false;
      this.errorMsg = ''
    },
      error => this.errorMsg = error.error.message)
  }

  onSaveUser(): void {
    this.addUser.userRoleMaps = this.addUser.userRoleMaps.map((user: any) => ({
      id: 0,
      ksaRole: {
        id: user.id
      }
    }))

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.service.saveUser(this.addUser)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.refresh(this.state);
      this.addUser = {
        id: 0,
        firstName: "",
        lastName: "",
        emailId: "",
        userRoleMaps: []
      };
      this.addUserFormOpen = false;
      this.errorMsg = ''
    },
      error => {
        this.errorMsg = error.error.message;
        this.addUser.userRoleMaps = [];
      })
  }

  onDeleteMultiSelect(selectedUsers: any[]){
    this.showMultideletePopup = true;
    this.selectedUser = selectedUsers;
  }

  onDeleteSelectedUsers(): void {
    const ids: any[] = [];
    this.selectedUser.forEach(function (value) {
      ids.push(value.id);
    });
    if (ids.length > 0) {
      showSpinner(this.translate.instant("spinnerLabels.common.loading"));
      this.service.deleteMultipleUsers(ids)
        .pipe(finalize(() => hideSpinner()))
        .subscribe(data => {
          this.refresh(this.state);
          this.showMultideletePopup = false;
        },
        error => {
          this.errorMsg = error.error.message;
        })
    }
    this.selected = [];
  }

  onDeleteUser(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.service.deleteUser(this.deleteId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.refresh(this.state);
      this.showDeleteUserPopUp = false;
    },error => {
      this.errorMsg = error.error.message;
    })
  }

  onShowDeleteCoursePopUp(id: any) {
    this.deleteId = id;
    this.showDeleteUserPopUp = true
  }

  resetError(): void {
    this.errorMsg = '';
  }

  refresh(state: ClrDatagridStateInterface) {
    this.state = state;
    this.loading = true;
    const filters: { [prop: string]: any } = {};
    if (state.filters) {
      for (const filter of state.filters) {
        const {property, value} = <{ property: string; value: string }>filter;
        filters[property] = value;
      }
    }
    this.service.getUser(filters?.firstName === undefined ? '' : filters.firstName,
      filters?.lastName === undefined ? '' : filters.lastName,
      filters?.emailId === undefined ? '' : filters.emailId,
      filters?.['userRoleMaps.ksaRole.roleName'] === undefined ? '' : filters?.['userRoleMaps.ksaRole.roleName'], state.page.current - 1, state.page.size, state?.sort?.by === undefined ? 'id' : state?.sort?.by, state?.sort?.reverse === undefined ? 'ASC' : state?.sort?.reverse)
      .subscribe((results: any) => {
        this.users = results;
        this.total = results.totalElements;
        this.loading = false;
      })
  }
}
