/*
 *  * ********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

export enum DialogOperationMode {
  CREATE,
  EDIT
}
