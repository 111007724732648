/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {KsaTeamsService} from '../../../services/ksa-teams.service';
import {UsersService} from '../../../services/users.service';
import {Content, User} from '../../../models/ksa-team.model';
import {ClrDatagridStateInterface} from "@clr/angular";
import {Functionality} from "../../../enums/functionality.enum";
import {ButtonId} from "../../../enums/buttonId.enum";
import {UserCacheService} from "../../../services/user-cache.service";
import {TealiumUtagService} from "../../../services/utag.service";
import {AnalyticsEvents} from "../../../enums/analytics-events.enum";
import {hideSpinner, showSpinner} from "../../../common/spinner";
import {TranslateService} from '@ngx-translate/core';
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-ksa-teams',
  templateUrl: './ksa-teams.component.html',
  styleUrls: ['./ksa-teams.component.scss']
})
export class KsaTeamsComponent implements OnInit {
  state: ClrDatagridStateInterface;
  username: string;
  page: number;
  loading: boolean = false;
  total: number;
  deleteId: number;
  showDeleteTeamPopUp: boolean = false;
  addKsaTeamFormOpen: boolean = false;
  editKsaTeamFormOpen: boolean = false;
  isSingleDelete: boolean = false;
  isMultipleDelete: boolean = false;
  ksaTeams = {"content": []};
  allSelectedId: number[] = [];
  users = [];
  selected: Content[] = [];
  getTeamData = this.getTeamDataType();
  newTeamToAdd = this.newTeamToAddType();
  updateKSATeam = this.updateKSATeamType();
  getRoles: any = {};
  addTeamRoles = [];
  errorMsg: string = '';
  allTeamUser: any[] = [];

  functionality: typeof Functionality = Functionality;
  btnId: typeof ButtonId = ButtonId;

  constructor(public ksaTeamsService: KsaTeamsService, public usersService: UsersService,
              private userCacheService: UserCacheService, private utagService: TealiumUtagService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getUsers();
    const data = this.userCacheService.getLoginDataFromLocalStorage();
    this.username = data?.email;
  }

  getUsers(): void {
    this.translate.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.usersService.getUserData()
        .pipe(finalize(() => hideSpinner()))
        .subscribe(data => {
          this.getRoles = data;
          this.getRoles.filter((item) => {
            return this.addTeamRoles.push(item);
          })
          this.getTeamData.ksaTeamUserMaps = this.addTeamRoles.filter((item, i) => {
            return item.emailId == this.username;
          })
        })
    });
  }

  onAddKsaTeam(form: NgForm): void {
    this.newTeamToAdd.name = this.getTeamData.name;
    this.newTeamToAdd.description = this.getTeamData.description;
    this.newTeamToAdd.ksaTeamUserMaps = this.getTeamData.ksaTeamUserMaps.map((user: User) =>
      ({
        user: {id: user.id},
      }))

    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.ksaTeamsService.createKsaTeam(this.newTeamToAdd)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.refresh(this.state);
        this.addKsaTeamFormOpen = false;
        this.errorMsg = '';
        form.onReset();
        this.getTeamData.ksaTeamUserMaps = [];
        this.utagService.trackEvent(AnalyticsEvents.CREATED_TEAM);
      },
      error => this.errorMsg = error.error.message,
    );
  }

  onDeleteSelectedTeam(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaTeamsService.onDeleteSelectedTeam(this.allSelectedId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.refresh(this.state);
      this.errorMsg = '';
      this.showDeleteTeamPopUp = false;
      this.utagService.trackEvent(AnalyticsEvents.DELETED_TEAM);
    }, error => this.errorMsg = error.error.message);
    this.selected = [];
  }

  onCancel() {
    this.showDeleteTeamPopUp = false;
  }

  onDeleteKsaTeam(): void {
    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaTeamsService.onDeleteKsaTeam(this.deleteId)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
      this.refresh(this.state);
      this.errorMsg = '';
      this.showDeleteTeamPopUp = false;
      this.utagService.trackEvent(AnalyticsEvents.DELETED_TEAM);
    }, error => this.errorMsg = error.error.message);
  }

  resetError(): void {
    this.errorMsg = '';
  }

  onShowDeleteMultipleTeamPopUp(selected: Content[]) {
    const ids: number[] = [];
    if (selected.length > 0) {
      selected.forEach(function (value) {
        ids.push(value.id);
      });
    } else {
      return;
    }
    this.allSelectedId = ids;
    this.showDeleteTeamPopUp = true;
    this.isMultipleDelete = true;
    this.isSingleDelete = false;
  }

  onShowSingleDeleteTeamPopUp(id: number) {
    this.deleteId = id;
    this.isSingleDelete = true;
    this.showDeleteTeamPopUp = true;
    this.isMultipleDelete = false;
  }

  onEditKsaTeamDetails(ksaTeam: any): void {
    this.newTeamToAdd.id = ksaTeam.id;
    this.newTeamToAdd.name = ksaTeam.name;
    this.newTeamToAdd.description = ksaTeam.description;

    showSpinner(this.translate.instant("spinnerLabels.common.loading"));
    this.ksaTeamsService.getKSATeamUser(ksaTeam.id)
      .pipe(finalize(() => hideSpinner())).subscribe(data => {
      this.allTeamUser = data;
      this.newTeamToAdd.ksaTeamUserMaps = data.filter((e) => {
        return e.isSelected === true;
      })
    })
    this.editKsaTeamFormOpen = true;
  }

  onUpdateKsaTeam(form: NgForm): void {
    this.updateKSATeam.id = this.newTeamToAdd.id;
    this.updateKSATeam.name = this.newTeamToAdd.name;
    this.updateKSATeam.description = this.newTeamToAdd.description;
    this.updateKSATeam.ksaTeamUserMaps = this.newTeamToAdd.ksaTeamUserMaps.map((user: any) =>
      ({
        user: {id: user.user.id},
        isSelected: user.user.isSelected,
      }))
    showSpinner(this.translate.instant("spinnerLabels.common.saving"));
    this.ksaTeamsService.updateKsaTeam(this.updateKSATeam)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.refresh(this.state);
        this.editKsaTeamFormOpen = false;
        this.errorMsg = '';
        form.onReset();
      },
      error => this.errorMsg = error.error.message);
  }

  onSetAddKsaTeamFormOpen(): void {
    this.addKsaTeamFormOpen = true;
  }

  clearForm(form: NgForm): void {
    form.reset();
    this.getTeamData.ksaTeamUserMaps = this.addTeamRoles.filter((item, i) => {
      return item.emailId == this.username;
    })
  }

  onCancelForm() {
    this.getTeamData.ksaTeamUserMaps = this.addTeamRoles.filter((item, i) => {
      return item.emailId == this.username;
    })
    this.addKsaTeamFormOpen = false;
  }

  refresh(state: ClrDatagridStateInterface): void {
    this.state = state;
    this.loading = true;
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const {property, value} = <{ property: string; value: string }>filter;
        filters[property] = value;
      }
    }

    this.ksaTeamsService.getKsaTeamsData(filters?.name ? filters.name : '', filters?.description ? filters.description : '',
      state.page.current - 1, state.page.size, state?.sort?.by === undefined ? 'id' : state?.sort?.by, state?.sort?.reverse === undefined ? 'ASC' : state?.sort?.reverse)
      .subscribe((results: any) => {
        this.ksaTeams = results;
        this.total = results.totalElements;
        this.loading = false;
      })
  }

  getTeamDataType(): any {
    return {
      "name": '',
      "description": '',
      "ksaTeamUserMaps": [
        {
          "user": {
            "id": Number,
            "role": ''
          }
        }
      ]
    }
  }

  newTeamToAddType(): any {
    return {
      "id": Number,
      "name": '',
      "description": '',
      "ksaTeamUserMaps": [
        {
          "user": {
            "id": Number,
          }
        }
      ]
    }
  }

  updateKSATeamType(): any {
    return {
      "id": Number,
      "name": "",
      "description": "",
      "ksaTeamUserMaps": [
        {
          "id": Number,
          "user": {
            "id": Number,
          }
        }
      ]
    }
  }

}
