/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from "@angular/core";
import {User} from "../models/auth-user.model";
import {UserService} from "./user.service";

@Injectable({providedIn: 'root'})
export class UserCacheService {
  private readonly IDENTITY_KEY: string = 'identity';
  private readonly EXPIRATION_TIMESTAMP_KEY: string = 'expires';
  private readonly THREE_HOURS: number = 3 * 60 * 60 * 1000;

  constructor(private readonly userService: UserService) {
  }

  getLoginDataFromLocalStorage(): User {
    // get user from local storage
    const cachedUserDetails = this.readUserDataFromLocalStorage();
    return cachedUserDetails ? cachedUserDetails : null;
  }

  getExpirationFromLocalStorage(): boolean {
    // get expiration from local storage
    const cachedUserDetails = this.readTimeoutFromLocalStorage();
    if (cachedUserDetails < new Date().getTime()) {
      this.removeUserDataFromLocalStorage();
      return true;
    }

    //if not expired then false
    return false;
  }

  saveUserDataToLocalStorage(userData: User): void {
    localStorage.setItem(this.IDENTITY_KEY, JSON.stringify(userData));
    this.saveExpirationTimestampToLocalStorage();
  }

  saveExpirationTimestampToLocalStorage(): void {
    localStorage.setItem(this.EXPIRATION_TIMESTAMP_KEY, JSON.stringify(new Date().getTime() + this.THREE_HOURS));
  }

  private readUserDataFromLocalStorage(): any {
    return JSON.parse(localStorage.getItem(this.IDENTITY_KEY));
  }

  private readTimeoutFromLocalStorage(): any {
    return JSON.parse(localStorage.getItem(this.EXPIRATION_TIMESTAMP_KEY));
  }

  removeUserDataFromLocalStorage(): void {
    localStorage.removeItem(this.IDENTITY_KEY);
    localStorage.removeItem(this.EXPIRATION_TIMESTAMP_KEY);
    this.userService.setUser(null);
  }

}
