<div class="button-container" *hasAccess="functionality.manageRoleProfileGroup">
  <button id="{{btnId.btnMngRoleProfileGroupAdd}}" class="btn btn-sm btn-primary"
          (click)="onSetAddRoleProfileGroupFormOpen()"
          *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)">
    {{'admin.roleProfilesAndGroups.roleProfileGroups.addRoleProfileGrpBtn' |
    translate}}
  </button>
</div>
<clr-modal [(clrModalOpen)]="addRoleProfileGroupFormOpen" [clrModalClosable]="true"
           (clrModalOpenChange)="onCancelAdding(addForm)" class="modal-lg">
  <h3 class="modal-title">{{'admin.roleProfilesAndGroups.roleProfileGroups.addRoleProfileGroupModalTitle' |
    translate}}
    <clr-alert clrAlertType="warning" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">
    <form clrForm (ngSubmit)="onAddRoleProfileGroup(addForm)" #addForm="ngForm" autocomplete="off">
      <clr-input-container class="clr-row">
        <label class="clr-col-5">{{'admin.roleProfilesAndGroups.roleProfileGroups.roleProfileGroup' |
            translate}}*</label>
          <input class="clr-col-7 input-label" clrInput type="text" [(ngModel)]="newRoleProfileGroup.name" name="name"
            required placeholder="{{'placeholder.roleProfileGroupName' | translate}}" pattern="^[^!@#*&(){}<>\/;`%$^:?]*$"/>
          <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}</clr-control-error>
          <clr-control-error *clrIfError="'required'">{{'errorMsg.roleProfileGroupNameRequired' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-textarea-container class="clr-row">
          <label class="clr-col-5">{{'admin.roleProfilesAndGroups.roleProfileGroups.description' | translate}}</label>
          <textarea [(ngModel)]="newRoleProfileGroup.description" class="clr-col-7 input-label" clrTextarea
                    name="description" placeholder="{{'placeholder.roleProfileGroupDescription' | translate}}"
                    rows="1"></textarea>
        </clr-textarea-container>
        <br>
        <div class="clr-row">
          <div class="clr-col-5 label-container">
            <span class="clr-control-label">{{'admin.roleProfilesAndGroups.roleProfileGroups.selectRoleProfiles' |
              translate}}* </span>
          </div>
        </div>
        <div>
          <clr-datagrid class="popup-datagrid-role"
            [(clrDgSelected)]="newRoleProfileGroup.roleProfileGroupRoleProfileMaps" [clrDgPreserveSelection]="true">
            <clr-dg-column [clrDgField]="'name'">{{'admin.roleProfilesAndGroups.roleProfileGroups.roleProfile' |
              translate}}</clr-dg-column>
            <clr-dg-row *clrDgItems="let roleProfile of roleProfiles.content; let i = index" [clrDgItem]="roleProfile">
              <clr-dg-cell>{{roleProfile.name}}</clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-outline" (click)="onCancelAdding(addForm)">{{'admin.cancelBtn' |
            translate}}</button>
          <button type="submit" class="btn btn-sm btn-primary"
            [disabled]="addForm.invalid || newRoleProfileGroup.roleProfileGroupRoleProfileMaps.length === 0">{{'admin.addBtn'
            | translate}}</button>
        </div>
      </form>
    </div>
  </clr-modal>

<clr-modal [(clrModalOpen)]="editRoleProfileGroupFormOpen" [clrModalClosable]="true"
           (clrModalOpenChange)="onCancelEditing(editForm)" class="modal-lg">
  <h3 class="modal-title">{{'admin.roleProfilesAndGroups.roleProfileGroups.editRoleProfileGroupModalTitle' |
    translate}}
    <clr-alert clrAlertType="warning" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">
    <form clrForm (ngSubmit)="onEditRoleProfileGroup(editForm)" #editForm="ngForm" autocomplete="off">
      <clr-input-container class="clr-row">
        <label class="clr-col-5">{{'admin.roleProfilesAndGroups.roleProfileGroups.roleProfileGroup' |
            translate}}*</label>
          <input class="clr-col-7 input-label" clrInput type="text" [(ngModel)]="newRoleProfileGroup.name" name="name"
            required placeholder="{{'placeholder.roleProfileGroupName' | translate}}" pattern="^[^!@#*&(){}<>\/;`%$^:?]*$"/>
          <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}</clr-control-error>
          <clr-control-error *clrIfError="'required'">{{'errorMsg.roleProfileGroupNameRequired' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-textarea-container class="clr-row">
          <label class="clr-col-5">{{'admin.roleProfilesAndGroups.roleProfileGroups.description' | translate}}</label>
          <textarea [(ngModel)]="newRoleProfileGroup.description" class="clr-col-7 input-label" clrTextarea
                    name="description" placeholder="{{'placeholder.roleProfileGroupDescription' | translate}}"
                    rows="1"></textarea>
        </clr-textarea-container>
        <br>
        <div class="clr-row">
          <div class="clr-col-5 label-container">
            <span class="clr-control-label">{{'admin.roleProfilesAndGroups.roleProfileGroups.selectRoleProfiles' |
              translate}}* </span>
          </div>
        </div>
        <div>
          <clr-datagrid class="popup-datagrid-role"
            [(clrDgSelected)]="newRoleProfileGroup.roleProfileGroupRoleProfileMaps" [clrDgPreserveSelection]="true">
            <clr-dg-column [clrDgField]="'name'">{{'admin.roleProfilesAndGroups.roleProfileGroups.roleProfile' |
              translate}}</clr-dg-column>
            <clr-dg-row *clrDgItems="let roleProfile of allRoleProfiles; let i = index" [clrDgItem]="roleProfile">
              <clr-dg-cell>{{roleProfile.roleProfileName}}</clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-outline" (click)="onCancelEditing(editForm)">{{'admin.cancelBtn' |
            translate}}</button>
          <button type="submit" class="btn btn-sm btn-primary"
            [disabled]="addForm.invalid || newRoleProfileGroup.roleProfileGroupRoleProfileMaps.length === 0">{{'admin.saveBtn'
            | translate}}</button>
        </div>
      </form>
    </div>
  </clr-modal>

  <clr-modal [(clrModalOpen)]="showDeletePopUp" (clrModalOpenChange)="resetError()">
    <h3 class="modal-title">{{'admin.roleProfilesAndGroups.roleProfileGroups.deleteRoleProfileGroup' | translate}}?
      <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
        <clr-alert-item>{{errorMsg}}</clr-alert-item>
      </clr-alert>
    </h3>
    <div class="modal-body">{{'admin.roleProfilesAndGroups.roleProfileGroups.thisCannotBeUndone' | translate}}.</div>
    <div class="modal-footer">
      <button (click)="showDeletePopUp = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
        translate}}</button>
      <button (click)="onDeleteRoleProfileGroup()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
        translate}}</button>
    </div>
  </clr-modal>

<clr-datagrid class="parent-datagrid" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-column [clrDgField]="'name'">{{'admin.roleProfilesAndGroups.roleProfileGroups.roleProfileGroup' |
    translate}}</clr-dg-column>
  <clr-dg-column>
    {{'admin.roleProfilesAndGroups.roleProfileGroups.roleProfiles' | translate}}
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'description'">{{'admin.roleProfilesAndGroups.roleProfileGroups.description' |
    translate}}</clr-dg-column>
  <clr-dg-column class="action-cell"
                 *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)"></clr-dg-column>
  <clr-dg-row *ngFor="let roleProfileGroup of roleProfileGroups.content" [clrDgItem]="roleProfileGroup">
    <clr-dg-cell>{{roleProfileGroup.name}}</clr-dg-cell>
    <clr-dg-cell>
      <ul *ngFor="let roleProfile of roleProfileGroup.roleProfileGroupRoleProfileMaps">
        <li>{{roleProfile.roleProfile.name}}</li>
      </ul>
    </clr-dg-cell>
    <clr-dg-cell> {{roleProfileGroup.description}}
    </clr-dg-cell>
    <clr-dg-cell class="action-cell"
                 *ngIf="skillRoleProfileManagementService.isSurveyTemplateConfigActive(surveyTemplateConfigVersion)">
      <clr-dg-action-overflow *hasAccess="functionality.manageRoleProfileGroup">
        <button id="{{btnId.btnMngRoleProfileGroupEdit}}" type="button" class="action-item"
                (click)="onEditRoleProfileGroupDetails(roleProfileGroup);">{{'admin.editBtn' |
          translate}}</button>
        <button id="{{btnId.btnMngRoleProfileGroupDelete}}" type="button" class="action-item"
                (click)="onShowDeletePopUp(roleProfileGroup.id)">{{'admin.deleteBtn' |
          translate}}</button>
      </clr-dg-action-overflow>
    </clr-dg-cell>
  </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10" [(clrDgPage)]="page" [clrDgTotalItems]="total" class="role-group-pagination">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Role Profile Groups per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Role Profile Groups
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>

