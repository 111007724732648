/*******************************************************************************
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 ******************************************************************************/

import {ClrNotificationDialogComponent} from "./clr-notification-dialog.component";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {translateHttpLoader} from "../../components/administration/administration.module";
import {ClarityModule} from "@clr/angular";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BrowserModule} from "@angular/platform-browser";
import {PipesModule} from "../../pipes/pipes.module";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ClarityModule,
    PipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoader,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    ClrNotificationDialogComponent
  ],
  exports: [
    ClrNotificationDialogComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class ClrNotificationDialogModule {
}
