/*******************************************************************************
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 ******************************************************************************/

import {Injectable} from "@angular/core";
import {ClrNotificationDialogOptions} from "./clr-notification-dialog-source.model";
import {Subject} from "rxjs";

@Injectable({providedIn: 'root'})
export class ClrNotificationDialogService {

  openWindow: Subject<ClrNotificationDialogOptions> = new Subject();

  constructor() {
  }

  /**
   * Show a ClrNotification dialog.
   *
   * @param options the options for the dialog to be displayed
   * @see ClrNotificationDialogOptions
   */
  show(options: Partial<ClrNotificationDialogOptions>): ClrNotificationDialogOptions {
    const clrNotificationDialogOptions = new ClrNotificationDialogOptions(options);
    this.openWindow.next(clrNotificationDialogOptions);
    return clrNotificationDialogOptions;
  }
}
