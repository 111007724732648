/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {MngMasterSurveyComponent} from '../components/administration/mng-master-survey/mng-master-survey.component';
import {ShareSurveyDataService} from './share-survey-data.service';
import {MasterSurvey} from '../models/master-survey.model';
import { MasterSurveyService } from './master-survey.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})

export class CanDeactivateGuard implements CanDeactivate<MngMasterSurveyComponent> {

  survey: MasterSurvey;
  showSurveyTab: boolean = false;
  hasUnsavedChanges: boolean = false;
  previewState: boolean = false;

  constructor(private shareSurveyData: ShareSurveyDataService, private masterSurveyService: MasterSurveyService) {
    this.masterSurveyService.getSurveyState().subscribe(data => {
      this.showSurveyTab = data;
    });
    this.masterSurveyService.getSurveyUpdateStatus().subscribe(data =>{
      this.hasUnsavedChanges = data;
    })
    this.masterSurveyService.getPreviewState().subscribe(data =>{
      this.previewState = data;
    })
  }

  canDeactivate(component: MngMasterSurveyComponent): boolean {
    if (this.previewState){
      return true;
    }
    if (this.showSurveyTab  && this.hasUnsavedChanges) {
      return true;
    } else {
      if (!this.survey) {
        if (confirm("Are you sure ? Found unsaved changes. Click on cancel to go back and save it, else unsaved data will be lost")) {
          this.shareSurveyData.setSurvey({});
          return true;
        } else {
          return false
        }
      }
    }
  }
}
