/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Injectable} from '@angular/core';
import {EmailTemplate} from "../models/email-template.model";

@Injectable({
  providedIn: 'root'
})
export class EmailValidationService {

  getUndefinedPlaceholders(emailTemplate: EmailTemplate, placeHolderNameList: string[]): string[] {
    const regexForWhiteSpaceSymbols: RegExp = /\s/g;
    const placeholderRegex: RegExp = /\$\{([^}]*)\}/g; // matches any string between ${...}
    const invalidPlaceholderRegex: RegExp = /((\{\{[^}]*}})|(#\{[^}]*}))/g; // matches strings which start with '{{'/'#{' and end with '}}'/'}'
    const placeholdersInBody: RegExpMatchArray = this.getMatchesFirstGroup(emailTemplate.emailBody, placeholderRegex);
    const invalidPlaceholdersInBody: string[] = emailTemplate.emailBody.match(invalidPlaceholderRegex) || [];

    const definedPlaceholders: string[] = placeHolderNameList.map(p => p.replace(regexForWhiteSpaceSymbols, ""));

    return placeholdersInBody.filter(e => !definedPlaceholders.includes(e)).concat(invalidPlaceholdersInBody);
  }

  /**
   * Finds all matches in a text by a specific regex and returns the first group of each match found.
   */
  private getMatchesFirstGroup(text: string, regex: RegExp): string[] {
    const matches: string[] = [];
    let match: RegExpExecArray;

    while(match = regex.exec(text)) {
      matches.push(match[1]);
    }
    return matches;
  }

}
