/* *********************************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * *********************************************************************/

import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ImageUploadValidationService {

  private readonly validFileNameRegex: RegExp = /^[\w,\s-]+\.(jpeg|png|jpg)/i;
  private readonly maxImageSize: number = 1000000;
  private readonly validFileExtension = Array.of('image/png', 'image/jpg', 'image/jpeg');
  static readonly IMAGE_BASE_64: string = 'data:image/jpeg;base64,';

  public validateUploadImageFile(file: File): boolean {
    return this.isValidFileName(file) && this.isValidFileSize(file) && this.isValidFileExtension(file);
  }

  public extractErrorMsgFromInvalidFile(file: File): string {

    if (!this.isValidFileExtension(file)) {
      return "invalidFileExtensionErrorMsg";
    }

    if (!this.isValidFileSize(file)) {
      return "maxImageUploadErrorMsg";
    }

    if (!this.isValidFileName(file)) {
      return "invalidFileNameErrorMsg";
    }
    return '';
  }

  private isValidFileName(file: File): boolean {
    return this.validFileNameRegex.test(file.name) && file.name.length < 225;
  }

  private isValidFileSize(file: File): boolean {
    return file.size < this.maxImageSize;
  }

  private isValidFileExtension(file: File): boolean {
    return this.validFileExtension.indexOf(file.type) !== -1;
  }
}
