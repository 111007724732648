<clr-tabs class="role-profile-tab">
  <clr-tab>
    <button clrTabLink>{{'tabKsa.roleProfileSet'|translate}}</button>
    <clr-tab-content>
      <app-job-profile-set></app-job-profile-set>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>{{'tabKsa.roleProfileGroup'|translate}}</button>
    <clr-tab-content *clrIfActive>
      <app-role-profile-groups></app-role-profile-groups>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>{{'tabKsa.mngRoleProfile'|translate}}</button>
    <clr-tab-content *clrIfActive>
      <app-job-role-profile></app-job-role-profile>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>
