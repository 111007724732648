/* *********************************************************************
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * *********************************************************************/

import {ErrorHandler, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {API_KSA_CORE_V1} from "./constants/api-prefix.constants";

type UnhandledFrontendError = {
  message: string,
  stacktrace: string,
  timeOccurred: number
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private readonly httpClient: HttpClient) {
  }

  handleError(error: any): void {
    const frontendError: UnhandledFrontendError = {
      message: error.message,
      stacktrace: error.stack,
      timeOccurred: new Date().getTime()
    };
    this.httpClient.post(`${API_KSA_CORE_V1}/unhandled-error`, frontendError).subscribe();
    console.error(error);
  }

}
