import {NgModule} from '@angular/core';
import {ClarityModule, ClrDatagridModule, ClrModalModule, ClrTabsModule} from "@clr/angular";
import {FormsModule} from "@angular/forms";
import {CommonModule} from '@angular/common';

import {KsaEngagementRoutingModule} from './ksa-engagement-routing.module';
import {DirectiveModule} from "../../directives/directive.module";

import {EmailManagementComponent} from '../../components/ksa-engagement/email-management/email-management.component';
import {KsaAdministrationComponent} from './ksa-administration/ksa-administration.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
  TranslateService,
  TranslateStore
} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {JobProfileSetComponent} from './ksa-administration/ksa-role-profile/job-profile-set/job-profile-set.component';
import {
  RoleProfileGroupsComponent
} from './ksa-administration/ksa-role-profile/role-profile-groups/role-profile-groups.component';
import {
  TabRoleProfileComponent
} from './ksa-administration/ksa-role-profile/tab-role-profile/tab-role-profile.component';
import {KsaMetadataComponent} from './ksa-administration/ksa-metadata/ksa-metadata.component';
import {KsaDetailsComponent} from './ksa-administration/ksa-details/ksa-details.component';
import {KsaPreviewComponent} from './ksa-administration/ksa-preview/ksa-preview.component';
import {TabKsaComponent} from './ksa-administration/tab-ksa/tab-ksa.component';
import {
  JobRoleProfileComponent
} from './ksa-administration/ksa-role-profile/job-role-profile/job-role-profile.component';
import {KsaSectionComponent} from './ksa-administration/ksa-details/ksa-section/ksa-section.component';
import {SendEmailInviteeComponent} from './email-management/send-email-invite/send-email-invite.component';
import {ComposeEmailComponent} from "./email-management/compose-email/compose-email.component";
import {NgxSummernoteModule} from 'ngx-summernote';
import {SafePipeModule} from 'safe-pipe';
import {KsaSettingsComponent} from './ksa-administration/ksa-settings/ksa-settings.component';
import {ClipboardModule} from "ngx-clipboard";
import {FiltersModule} from "../filters/filters.module";
import {KsaTeamsComponent} from "./ksa-teams/ksa-teams.component";
import {CdsTimeModule} from "@cds/angular";
import {AdministrationModule} from "../administration/administration.module";
import {
  ParticipantDialogComponent
} from "./email-management/send-email-invite/participant-dialog/participant-dialog.component";
import {PipesModule} from "../../pipes/pipes.module";


export function translateServiceFactory(store: TranslateStore, loader: TranslateLoader, compiler: TranslateCompiler, parser: TranslateParser, missingHandler: MissingTranslationHandler) {
  // @ts-ignore
  const res: TranslateService = new TranslateService(store, loader, compiler, parser, missingHandler);
  res.setDefaultLang('en-us');
  return res;
}

export function translateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    EmailManagementComponent,
    KsaTeamsComponent,
    KsaAdministrationComponent,
    JobProfileSetComponent,
    RoleProfileGroupsComponent,
    TabRoleProfileComponent,
    SendEmailInviteeComponent,
    KsaAdministrationComponent,
    KsaMetadataComponent,
    KsaDetailsComponent,
    KsaPreviewComponent,
    TabKsaComponent,
    JobRoleProfileComponent,
    KsaSectionComponent,
    ComposeEmailComponent,
    KsaSettingsComponent,
    ParticipantDialogComponent,
  ],
  imports: [
    DirectiveModule,
    KsaEngagementRoutingModule,
    ClrTabsModule,
    ClrDatagridModule,
    ClrModalModule,
    ClarityModule,
    FormsModule,
    CommonModule,
    SafePipeModule,
    NgxSummernoteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoader,
        deps: [HttpClient]
      }
    }),
    ClipboardModule,
    FiltersModule,
    CdsTimeModule,
    AdministrationModule,
    PipesModule
  ],
  exports: [
    EmailManagementComponent,
    KsaTeamsComponent,
    KsaAdministrationComponent
  ],
  providers: [],
})
export class KsaEngagementModule {
}
