import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {KsaAdministrationService} from '../../../../services/ksa-administration.service';
import {MasterSurveyService} from '../../../../services/master-survey.service';
import {UsersService} from '../../../../services/users.service';
import {TranslateService} from '@ngx-translate/core';
import {KsaShareService} from '../../../../services/ksa-share.service';
import {MasterSurvey} from '../../../../models/master-survey.model';
import {Geo} from '../../../../models/master-ksa.model';
import {Status} from '../../../../enums/status.enum';
import {KsaTeamsService} from '../../../../services/ksa-teams.service';
import moment from 'moment';
import {AnonymityTypeIdEnum, GlobalThreshold, QuestionMode} from '../../../../enums/ksa-survey-settings.enum';
import '@cds/core/alert/register.js';
import '@cds/core/button/register.js';
import {User} from '../../../../models/ksa-team.model';
import {UserCacheService} from '../../../../services/user-cache.service';
import {hideSpinner, showSpinner} from '../../../../common/spinner';
import {filter, finalize} from 'rxjs/operators';
import {TealiumUtagService} from '../../../../services/utag.service';
import {AnalyticsEvents} from '../../../../enums/analytics-events.enum';
import {UserRoleType} from '../../../../models/user-role-type.constants';
import {MultiSelectComboboxModel} from '@clr/angular/forms/combobox/model/multi-select-combobox.model';
import {Functionality} from '../../../../enums/functionality.enum';
import {MasterSurveyTranslationService} from '../../../../services/master-survey-translation.service';
import {LocalizationStatusEnum} from '../../../../enums/localization-status.enum';
import {LocalizationLocaleEnum} from '../../../../enums/localization-locale.enum';
import {UserService} from '../../../../services/user.service';
import {KsaSettingsService} from '../ksa-settings/ksa-settings.service';

@Component({
  selector: 'app-ksa-metadata',
  templateUrl: './ksa-metadata.component.html',
  styleUrls: ['./ksa-metadata.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class KsaMetadataComponent implements OnInit {
  @Output() disabledOtherTab = new EventEmitter<boolean>();
  @Output() enableRoleProfileTab = new EventEmitter<boolean>();
  anonymityTypes: any[] = [];
  surveyQuestionModes: any[] = [];
  masterSurveyTemplate: MasterSurvey;
  masterSurveyTemplateSelected: any;
  surveyItems: any[] = [];
  surveyItemVersions: any[];
  masterSurveyName = '';
  selectedMasterSurveyName = '';
  masterSurveyVersion: any;
  selectedMasterSurveyVersion: any;
  selectedMasterSurveyLanguage = 'English';
  masterSurveyPublishedLanguages = ['English'];
  allGeo: Geo[] = [];
  countries: any = {
    countryList: [],
  };
  ksaTeams: any = {
    content: [],
  };
  ksaDataToedit: any;
  newKsa: any = this.ksaDataFormat();
  showSurveyChangeWarning = false;
  newKsaToPush: any = this.ksaDataFormat();
  message: string;
  errorMsg = '';
  ksaNameToCheck = '';
  surveySaveSuccessAlert = false;
  errorMessage = false;
  messageSuccessTimer = true;
  showDuplicateKsaError = false;
  addKsaTeamFormOpen = false;
  getTeamData = this.getTeamDataType();
  users = [];
  salesUsers = [];
  availableStakeholdersUsers = [];
  username: string;
  getRoles: any = {};
  newTeamToAdd = this.newTeamToAddType();
  disableSaveBtnWhileSaving = false;
  isSurveySimple: boolean;
  hasWritePermission: boolean;

  constructor(public readonly ksaAdministrationService: KsaAdministrationService,
              public readonly usersService: UsersService,
              public readonly masterSurveyService: MasterSurveyService,
              private readonly ksaTeamsService: KsaTeamsService,
              private readonly translate: TranslateService,
              private readonly ksaShareService: KsaShareService,
              private readonly userCacheService: UserCacheService,
              private readonly utagService: TealiumUtagService,
              private readonly masterSurveyTranslationService: MasterSurveyTranslationService,
              private readonly userService: UserService,
              private readonly ksaSettingsService: KsaSettingsService) {
  }

  ngOnInit(): void {
    this.hasWritePermission = this.userService.hasWritePermission(Functionality.ksaAdministration);
    this.getGeo();
    this.getKsaData();
    this.getAnonymityTypes();
    this.getSurveyQuestionModes();
    this.getMasterSurveyWithVersion();
    this.getKsaTeams();
    this.getUsers();
  }

  getKsaData(): void {
    this.ksaShareService.getKSADetails()
      .pipe(filter(data => data))
      .subscribe(data => {
        this.masterSurveyTemplateSelected = data?.masterSurvey;
        this.ksaDataToedit = {...data, language: LocalizationLocaleEnum[data?.language]};
        if (data?.language) {
          this.getPublishedLanguages(data.masterSurvey.id);
          this.selectedMasterSurveyLanguage = this.ksaDataToedit.language;
        }

        data?.isAdvance && this.enableRoleProfileTab.emit(true);
        if (data?.id) {
          this.newKsa = {...data};
          this.masterSurveyName = data.masterSurvey.masterSurveyName;
          this.selectedMasterSurveyName = data.masterSurvey.masterSurveyName;
          this.masterSurveyVersion = data.masterSurvey.id;
          this.selectedMasterSurveyVersion = data.masterSurvey.id;
          this.onSelectSurveyName();
          this.ksaNameToCheck = data.ksaName;
        } else {
          this.disabledOtherTab.emit(true);
        }
      });
  }

  getGeo(): void {
    this.ksaAdministrationService.getGeo().subscribe(data => {
      this.allGeo = data;
      this.onSelectGeo();
    });
  }

  getKsaTeams(): void {
    this.ksaTeamsService.getksaTeams().subscribe(data => this.ksaTeams = data);
  }

  getAnonymityTypes(): void {
    this.ksaAdministrationService.getAnonymityTypes().subscribe(data => this.anonymityTypes = data);
  }

  getSurveyQuestionModes(): void {
    this.ksaAdministrationService.getSurveyQuestionModes().subscribe(data => this.surveyQuestionModes = data);
  }

  getMasterSurveyTemplateById(id: number): void {
    this.newKsa.isAdvance = false;
    showSpinner(this.translate.instant('spinnerLabels.common.loading'));
    this.ksaAdministrationService.getMasterSurveyTemplateById(id)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
        this.ksaAdministrationService.checkIfRoleProfileTypeQuestionExist(this.masterSurveyVersion)
          .subscribe(value => this.isSurveySimple = value);
        data.surveySections?.forEach(element => element.isQuestionSectionsSelected = true);
        this.masterSurveyTemplateSelected = data;
        this.selectedMasterSurveyLanguage = 'English';
      });
    this.newKsa.id && (this.showSurveyChangeWarning = true);
    this.getPublishedLanguages(id);
  }

  onAddKsa(): void {
    this.disableSaveBtnWhileSaving = true;
    this.newKsa?.id && (this.newKsaToPush.id = this.newKsa.id);
    this.newKsaToPush.ksaName = this.newKsa.ksaName;
    this.newKsaToPush.description = this.newKsa.description;
    this.newKsaToPush.customer = this.newKsa.customer;
    this.newKsaToPush.ksaTeam.id = this.newKsa.ksaTeam.id;
    this.newKsaToPush.masterSurvey.id = this.masterSurveyVersion;
    this.newKsaToPush.anonymityType.id = this.newKsa.anonymityType.id;
    this.newKsaToPush.surveyQuestionMode.id = this.newKsa.surveyQuestionMode.id;
    this.newKsaToPush.globalThreshold = this.newKsa.globalThreshold;
    this.newKsaToPush.geo.id = this.newKsa.geo.id;
    this.newKsaToPush.country.id = this.newKsa.country.id;
    this.newKsaToPush.isAdvance = this.newKsa.isAdvance;
    this.newKsaToPush.stakeholders = this.newKsa.stakeholders;
    this.newKsaToPush.language = this.selectedMasterSurveyLanguage.toUpperCase();
    (!this.newKsa?.id && this.newKsa.validTill == '') && this.setDefaultdateValidTill();
    !this.newKsa.isAdvance && (this.newKsaToPush.ksaJobProfileSet = null);

    if (!this.ksaDataToedit?.id) {
      this.mapMasterSurveyToKsaSections();
      const ksaSections: any = this.getKsaTechnicalSections(this.newKsaToPush);
      this.ksaSettingsService.setDefaultSelectedSectionsForKsa(this.newKsaToPush.masterSurvey.id, ksaSections);
      this.newKsaToPush.ksaJobProfileSet = this.newKsa.ksaJobProfileSet;
      this.createKsaMetaData();
    } else {
      if (this.ksaDataToedit.masterSurvey.id !== this.masterSurveyVersion) {
        this.mapMasterSurveyToKsaSections();
      } else {
        this.newKsaToPush.ksaSection = this.newKsa.ksaSection;
      }
      this.updateKsaMetadata();
    }
  }


  createKsaMetaData(): void {
    showSpinner(this.translate.instant('spinnerLabels.common.saving'));
    this.ksaAdministrationService.createKsa(this.newKsaToPush)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.disableSaveBtnWhileSaving = false;
          this.newKsa = {...data};
          this.ksaAdministrationService.ksa = data;
          this.ksaShareService.setKSADetails(data);
          this.enableRoleProfileTab.emit(this.newKsaToPush.isAdvance);
          this.disabledOtherTab.emit(false);
          this.surveySaveSuccessAlert = true;
          this.messageSuccessTimer = true;
          this.errorMessage = false;
          this.errorMsg = '';
          this.utagService.trackEvent(AnalyticsEvents.CREATED_KSA);
        },
        error => {
          this.errorMsg = error.error.message;
          this.disableSaveBtnWhileSaving = false;
          hideSpinner();
        });
    setTimeout(() => this.messageSuccessTimer = false, 3000);
  }

  updateKsaMetadata(): void {
    this.newKsaToPush.ksaSection.map(ksaSection => {
      const tempId = ksaSection.section.id;
      const isSectionMandatoryTemp = ksaSection.section.isMandatorySection;
      ksaSection.section = {};
      ksaSection.section.id = tempId;
      ksaSection.section.isMandatorySection = isSectionMandatoryTemp;
      ksaSection.ksaTechnicalQuestions.map(question => {
        const questionTechId = question.sectionQuestion.id;
        question.sectionQuestion = {id: questionTechId};
      });
      ksaSection.ksaQuestions.map(question => {
        const questionNonTechId = question.section.id;
        question.section = {id: questionNonTechId};
      });
    });
    this.disableSaveBtnWhileSaving = true;
    delete this.newKsaToPush.ksaJobProfileSets;
    this.newKsaToPush.status = this.ksaDataToedit.status;
    this.newKsaToPush.ksaJobProfileSet = this.newKsa.isAdvance ? this.ksaDataToedit.ksaJobProfileSet : null;
    this.newKsaToPush.validTill = this.ksaDataToedit.validTill;
    showSpinner(this.translate.instant('spinnerLabels.common.saving'));
    this.ksaAdministrationService.updateKsa(this.newKsaToPush)
      .pipe(finalize(() => hideSpinner()))
      .subscribe(data => {
          this.disableSaveBtnWhileSaving = false;
          this.ksaShareService.setKSADetails(data);
          this.enableRoleProfileTab.emit(this.newKsaToPush.isAdvance);
          this.surveySaveSuccessAlert = true;
          this.messageSuccessTimer = true;
          this.errorMessage = false;
          this.errorMsg = '';
          this.utagService.trackEvent(AnalyticsEvents.UPDATED_KSA);
        },
        error => {
          this.errorMsg = error.error.message;
          this.disableSaveBtnWhileSaving = false;
        });
    setTimeout(() => this.messageSuccessTimer = false, 3000);
  }

  setDefaultdateValidTill(): void {
    this.newKsaToPush.validTill = new Date();
    this.newKsaToPush.validTill = moment(this.newKsaToPush.validTill).add(15, 'days').format('YYYY-MM-DD');
  }

  getMasterSurveyWithVersion(): void {
    this.ksaAdministrationService.getMasterSurveyWithVersion().subscribe(data => {
      this.surveyItems = Object.keys(data.reduce((grouped, surveyItem) => {
        const surveyName = surveyItem.masterSurveyName;
        if (grouped[surveyName] == null) {
          grouped[surveyName] = [];
        }
        grouped[surveyName].push(surveyItem);
        return grouped;
      }, {}));
    });
  }

  onSelectSurveyName(): void {
    this.ksaAdministrationService.getMasterSurveyWithVersion().subscribe(data =>
      this.surveyItemVersions = data.filter(surveyName => surveyName.masterSurveyName === this.masterSurveyName));
  }

  mapMasterSurveyToKsaSections(): void {
    this.newKsaToPush.ksaSection = this.masterSurveyTemplateSelected.surveySections.map(section => ({
      isCoreSection: section.isTechnicalSection,
      isQuestionSectionsSelected: section.isQuestionSectionsSelected,
      ksaQuestions: !section.isTechnicalSection ? section.sectionQuestions.map(question => ({
        sectionQuestionId: question.id,
        isPageBreakAdded: question.isPageBreakAdded,
        isMandatory: question.isMandatory,
        language: {id: question.language.id},
        question: {id: question.question.id},
        questionNote: question.questionNote,
        questionText: question.question.questionText,
        questionType: {id: question.question?.questionType?.id},
        section: {id: section.id},
        sectionQuestionSequenceNo: question.sectionQuestionSequenceNo,
        questionDisplayCondition: question?.questionDisplayCondition == null ? null : {id: question.questionDisplayCondition.id},
        questionSkipCondition: question?.questionSkipCondition == null ? null : {id: question.questionSkipCondition.id}
      })) : [],
      ksaTechnicalQuestions: section.isTechnicalSection ? (section.sectionQuestions.map(question => ({
        sectionQuestion: {id: question.id},
        isPageBreakAdded: question.isPageBreakAdded,
      }))) : [],
      mandatory: section.isMandatorySection,
      section: {id: section.id},
      sectionName: section.section.sectionName,
      sectionSequenceNo: section.sectionSequenceNo
    }));
    this.newKsaToPush.ksaSection.sort((a, b) => a.sectionSequenceNo > b.sectionSequenceNo ? 1 : -1);
  }

  onSelectGeo() {
    const newKsaGeoId = +this.newKsa.geo.id;
    this.countries = this.allGeo.filter((geo: Geo) => geo.id === newKsaGeoId);
    this.countries[0]?.countryList.sort((countryA, countryB) => countryA.countryName > countryB.countryName ? 1 : -1);
  }

  checkIfKsaNameExists(name: string): void {
    this.ksaAdministrationService.checkIfKsaNameExists(name).subscribe(data => {
      this.showDuplicateKsaError = !!data;
      if (this.newKsa.id && this.ksaNameToCheck === this.newKsa.ksaName) {
        this.showDuplicateKsaError = false;
      }
    });
  }

  ksaDataFormat(): any {
    return {
      anonymityType: {
        id: AnonymityTypeIdEnum.None,
      },
      country: {
        id: '',
      },
      customer: '',
      description: '',
      geo: {
        id: '',
      },
      globalThreshold: GlobalThreshold.GlobalThreshold,
      isAdvance: false,
      ksaName: '',
      language: '',
      ksaSection: [
        {
          isCoreSection: false,
          isQuestionSectionsSelected: false,
          ksaQuestions: [
            {
              isPageBreakAdded: false,
              isMandatory: false,
              language: {
                id: '',
              },
              question: {
                id: '',
              },
              questionNote: '',
              questionText: '',
              questionType: {
                id: '',
              },
              section: {
                id: '',
              },
              skill: {
                id: '',
              },
              questionDisplayCondition: {
                id: '',
              },
              questionSkipCondition: {
                id: '',
              },
              sectionQuestionSequenceNo: '',
            }
          ],
          mandatory: false,
          section: {
            id: '',
          },
          sectionName: '',
          sectionSequenceNo: '',
        }
      ],
      ksaTeam: {
        id: '',
      },
      masterSurvey: {
        id: '',
      },
      surveyQuestionMode: {
        id: QuestionMode.ManuallyChooseQuestionSections,
      },
      validTill: '',
      stakeholders: []
    };
  }

  clearForm(form: NgForm): void {
    form.reset();
    this.getTeamData.ksaTeamUserMaps = this.users.filter(item => item.emailId === this.username);
    const dropDown = (document.getElementById('teams')) as HTMLSelectElement;
    dropDown.value = null;
    this.newKsa.ksaTeam.id = null;
  }

  getTeamDataType(): any {
    return {
      name: '',
      description: '',
      ksaTeamUserMaps: [
        {
          user: {
            id: Number,
            role: ''
          }
        }
      ]
    };
  }

  getUsers(): void {
    const data = this.userCacheService.getLoginDataFromLocalStorage();
    this.username = data?.email;
    this.usersService.getUserData().subscribe(data => {
      this.getRoles = data;
      this.getRoles.forEach(item => this.users.push(item));
      this.salesUsers = this.users.filter(user => this.isStakeholderUser(user))
        .map(user => ({...user, fullName: `${user.firstName} ${user.lastName}`}));
      this.availableStakeholdersUsers = this.salesUsers;
      this.getTeamData.ksaTeamUserMaps = this.users.filter(item => item.emailId === this.username);
    });
  }

  onCancelForm() {
    const dropDown = (document.getElementById('teams')) as HTMLSelectElement;
    dropDown.value = null;
    this.newKsa.ksaTeam.id = null;
    this.getTeamData.ksaTeamUserMaps = this.users.filter(item => item.emailId === this.username);
    this.addKsaTeamFormOpen = false;
  }

  onAddKsaTeam(form: NgForm): void {
    this.newTeamToAdd.name = this.getTeamData.name;
    this.newTeamToAdd.description = this.getTeamData.description;
    this.newTeamToAdd.ksaTeamUserMaps = this.getTeamData.ksaTeamUserMaps.map((user: User) => ({user: {id: user.id}}));

    this.ksaTeamsService.createKsaTeam(this.newTeamToAdd).subscribe(() => {
        this.addKsaTeamFormOpen = false;
        this.errorMsg = '';
        form.onReset();
        this.getTeamData.ksaTeamUserMaps = [];
        this.getKsaTeams();
      },
      error => this.errorMsg = error.error.message
    );
  }

  newTeamToAddType(): any {
    return {
      id: Number,
      name: '',
      description: '',
      ksaTeamUserMaps: [
        {
          user: {
            id: Number
          }
        }
      ]
    };
  }

  onTeamOptionSelect(value: string): void {
    if (value == 'onOpenTeamModal') {
      this.addKsaTeamFormOpen = true;
    }
  }

  getAvailableStakeholders(event: MultiSelectComboboxModel<any>): void {
    this.availableStakeholdersUsers = this.salesUsers.filter(user => !event?.model?.includes(user));
  }

  isDisabled(): boolean {
    return [Status.Active, Status.Closed, Status.Expired].includes(this.newKsa.status) || !this.hasWritePermission;
  }

  get functionality(): typeof Functionality {
    return Functionality;
  }

  get statusEnum(): typeof Status {
    return Status;
  }

  private isStakeholderUser(user: any): boolean {
    return user?.userRoleMaps.some(userRole => userRole?.ksaRole?.roleName === UserRoleType.STAKEHOLDER);
  }

  private getKsaTechnicalSections(ksa): any {
    return ksa?.ksaSection?.filter(ksaSection => ksaSection.isCoreSection);
  }

  private getPublishedLanguages(masterSurveyId: number) {
    this.masterSurveyTranslationService.getTranslatedLanguages(masterSurveyId, LocalizationStatusEnum.PUBLISHED)
      .subscribe(languages => this.masterSurveyPublishedLanguages = ['English', ...languages]);
  }
}
