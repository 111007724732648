<clr-modal [(clrModalOpen)]="showDisplayLogicPopUp" [clrModalClosable]="true" (clrModalOpenChange)="showDisplayLogicModal.emit(false)">
    <h3 class="modal-title">
        {{'admin.surveyTemplates.addSurveyTemplate.surveyTemplate.sectionType.displayLogicSection'|translate}}</h3>
    <div class="modal-body">
      <!-- <div class="alert alert-warning alert-sm" role="alert" *ngIf="warningTextDisplayLogic">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
            </div>
            <span class="alert-text">
              {{warningTextDisplayLogic}}
            </span>
          </div>
        </div>
        <button type="button" class="close" aria-label="Close">
          <clr-icon aria-hidden="true" (click)="this.warningTextDisplayLogic=''" shape="close"></clr-icon>
        </button>
      </div> -->
      <p>{{'surveyTemplate.questionSheet.isThisSection'|translate}}
        <strong>{{'surveyTemplate.questionSheet.will'|translate}}</strong> {{'surveyTemplate.questionSheet.isDisplayConditions'|translate}}
      </p>
      <br>
      <form clrForm autocomplete="off" class="clr-form displayLogic">
        <clr-select-container class="clr-row">
          <label class="clr-col-4">{{'surveyTemplate.questionSheet.isSelectSection'|translate}}</label>
          <select (change)="onDisplayLogicSectionSelect()" class="clr-col-8" clrSelect
                  [(ngModel)]="selectedSectionDisplayLogic"
                  [ngModelOptions]="{standalone: true}">
            <option disabled value="">{{'surveyTemplate.questionSheet.isSelectSection'|translate}}</option>
            <option *ngFor="let sectionItem of selectedSection" [ngValue]="sectionItem">
              {{sectionItem.section.sectionName}}
            </option>
          </select>
        </clr-select-container>
        <clr-select-container class="clr-row">
          <label class="clr-col-4">{{'surveyTemplate.questionSheet.isSelectQuestion'|translate}}</label>
          <select (change)="onDisplayLogicQuestionSelect()" class="clr-col-8" clrSelect [(ngModel)]="selectedQuestionDisplayLogic"
                  [ngModelOptions]="{standalone: true}">
            <option disabled value="">{{'surveyTemplate.questionSheet.isSelectQuestion'|translate}}</option>
            <option *ngFor="let questionItem of questionsSelectedSection" [ngValue]="questionItem">
              {{questionItem?.sectionQuestionSequenceNo}} : {{questionItem?.question.questionText}}
            </option>
          </select>
        </clr-select-container>
          <clr-select-container class="clr-row" *ngIf="showDisplayAnswerLabel">
            <label class="clr-col-4">{{'surveyTemplate.questionSheet.isSelectAnswer'|translate}}</label>
            <select clrSelect class="clr-col-8" [(ngModel)]="selectedAnswerLabelDisplayLogic" [ngModelOptions]="{standalone: true}"
                    (change)="onDisplayLogicValueAnswerLabelSelect($event)">
              <option disabled selected value="">{{'surveyTemplate.questionSheet.isSelectLabel'|translate}}</option>
              <option *ngFor="let item of answerLabelSelectedQuestion">
                {{item.answerLabelSequenceNo}} : {{item.labelText}}
              </option>
            </select>
          </clr-select-container>
          <clr-select-container class="clr-row" *ngIf="showMatrixAnswerLabel">
            <label class="clr-col-4">{{'surveyTemplate.questionSheet.isSelectAnswerCols'|translate}}</label>
            <select clrSelect class="clr-col-8" [(ngModel)]="selectedColumnAnswer" [ngModelOptions]="{standalone: true}"
                    (change)="onDisplayLogicColumnValueAnswerSelect($event)">
              <option disabled selected value="">{{'surveyTemplate.questionSheet.isSelectLabel'|translate}}</option>
              <option *ngFor="let item of answerLabelSelectedQuestionColumn">
                {{item.answerLabelSequenceNo}} : {{item.labelText}}
              </option>
            </select>
          </clr-select-container>
          <clr-select-container class="clr-row" *ngIf="showMatrixAnswerLabel">
            <label class="clr-col-4">{{'surveyTemplate.questionSheet.isSelectAnswerRow'|translate}}</label>
            <select clrSelect class="clr-col-8" [(ngModel)]="selectedRowAnswer" [ngModelOptions]="{standalone: true}"
                    (change)="onDisplayLogicRowValueAnswerSelect($event)">
              <option disabled selected value="">{{'surveyTemplate.questionSheet.isSelectLabel'|translate}}</option>
              <option *ngFor="let item of answerLabelSelectedQuestionRow">
                {{item.answerLabelSequenceNo}} : {{item.labelText}}
              </option>
            </select>
          </clr-select-container>
        <clr-select-container class="clr-row">
          <label class="clr-col-4">{{'surveyTemplate.questionSheet.isSelect'|translate}}</label>
          <select clrSelect class="clr-col-8" #selectDisplayType (change)="onDisplayLogicSkipTypeSelect(selectDisplayType.value)"
                  [(ngModel)]="selectedSkipTypeDisplayLogic" [ngModelOptions]="{standalone: true}">
            <option disabled selected value="">{{'surveyTemplate.questionSheet.isSelecctDisplayType'|translate}}</option>
            <option *ngFor="let item of valueAnswerLabelOptions" [value]="item.id">
              {{item.skipConditionName}}
            </option>
          </select>
        </clr-select-container>
        <clr-input-container class="clr-row" *ngIf="displayValueInput">
              <label class="clr-col-4">{{'surveyTemplate.questionSheet.isEnterValue'|translate}}</label>
              <input (blur)="onDisplayValueInput($event.target.value)" class="clr-col-3" clrInput [(ngModel)]="displayValueDisplayLogic"
                     [ngModelOptions]="{standalone: true}" type="number" />
        </clr-input-container>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="onCancelDisplayLogic()" class="btn btn-outline btn-sm" type="button"> {{'surveyTemplate.questionSheet.isCLear'|translate}}</button>
      <button (click)="onOkDisplayLogic()" class="btn btn-primary btn-sm"
              [disabled]="isDisabledDisplayLogicButton()" type="button">{{'surveyTemplate.questionSheet.isOk'|translate}}</button>
    </div>
  </clr-modal>
