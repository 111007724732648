export enum DashboardEnum {
  SimpleKsa = 'Simple KSA',
  AdvanceKsa = "Advance KSA",
  None = "None",
  Anonymous = "Anonymous",
  semiAnonymous = "Semi-Anonymous",
  Draft = "Draft",
  Active = "Active",
  Close = "Closed",
  Published = "Published",
  Archive = "Archived",
  Donut = "donut"
}
