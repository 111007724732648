/*******************************************************************
 Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Component, Input, OnInit} from '@angular/core';
import {Scorecard, SkillsAndCourses} from "../../../../models/scorecard.model";
import {TranslateService} from "@ngx-translate/core";
import {hideSpinner, showSpinner} from "../../../../common/spinner";
import {finalize} from "rxjs/operators";
import {ScorecardsService} from "../../../../services/scorecards.service";

@Component({
  selector: 'scorecard-modal',
  templateUrl: './scorecard.modal.component.html',
  styleUrls: ['./scorecard.modal.component.scss']
})
export class ScorecardModalComponent implements OnInit {
  @Input() participantId: number;
  @Input() ksaId: number;
  scorecard: Scorecard;
  isLoaded: boolean;
  hasError: boolean;

  constructor(private readonly translateService: TranslateService, private readonly scorecardsService: ScorecardsService) {
  }

  ngOnInit(): void {
    this.translateService.get("spinnerLabels.common.loading").subscribe((translated: string) => {
      showSpinner(translated);
      this.scorecardsService.getScorecardsByKsaIdAndParticipantId(this.ksaId, this.participantId)
        .pipe(finalize(() => hideSpinner()))
        .subscribe(scorecard => {
          this.scorecard = scorecard;
          this.isLoaded = true;
          this.hasError = false;
        }, () => {
          this.isLoaded = false;
          this.hasError = true;
        });
    });
  }

  getTrainingRecommendationMessage(skillsAndCourses: SkillsAndCourses): string {
    if (skillsAndCourses.recommendedCourse) {
      return `${skillsAndCourses.recommendedCourse.courseName} [${skillsAndCourses.recommendedCourse.courseVersion}]`;
    }

    return !skillsAndCourses.recommendedCourse && !this.isScoreValid(skillsAndCourses.participantScore) ?
      this.translateService.instant('analysisAndReports.scorecards.trainingRecommendationsDefaultMessage') :
      this.translateService.instant('common.dash');

  }

  calculateTargetPercentage(skillsAndCourses: SkillsAndCourses): number {
    const participantScore: number = skillsAndCourses.participantScore ?? 0;
    const targetScore: number = skillsAndCourses.targetScore ?? 0;
    return participantScore > targetScore ? 0 : (targetScore - participantScore);
  }

  isScoreValid(participantScore: number | null | undefined): boolean {
    return participantScore !== null && participantScore !== undefined;
  }
}
