/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KsaShareService {

  private ksaDetails: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {
  }

  getKSADetails(): Observable<any> {
    return this.ksaDetails.asObservable();
  }

  setKSADetails(ksa: any) {
    this.ksaDetails.next(ksa);
  }

  clearKSADetails() {
    this.ksaDetails.next(null);
  }
}
