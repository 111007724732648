/*
 *  *********************************************************************
 *  * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */


import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {EditingInfo} from "../models/editing-info";
import {EditableType} from "../models/editable-type.constans";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

/**
 * @author avalchev
 */
@Injectable({
  providedIn: 'root'
})
export class EditingInfoService {

  constructor(private readonly http: HttpClient) {
  }

  updateEditingInfo(id: number, editableType: EditableType): Observable<void> {
    return this.http.put<any>(`${environment.API_URL}${API_KSA_CORE_V1}/editing-info/${id}/type/${editableType}`, {});
  }

  getEditingInfo(id: number, editableType: EditableType): Observable<EditingInfo> {
    return this.http.get<any>(`${environment.API_URL}${API_KSA_CORE_V1}/editing-info/${id}/type/${editableType}`);
  }

}
