import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionType} from '../../../../../enums/questionType.enum';
import {KsaShareService} from '../../../../../services/ksa-share.service';
import {SurveyControls} from '../../../../../enums/survey-controls.enum';
import {ksaQuestionOperations} from '../../../../../enums/ksa-question-operations.enum';
import {KsaRoleProfileService} from '../../../../../services/ksa-role-profile.service';
import {Status} from '../../../../../enums/status.enum';
import {KsaQuestionAddPageBreakEntity} from '../../../../../models/ksa-survey.model';
import {Config, MasterSurveyQuestion} from '../../../../../models/master-survey-questions.model';
import {RoleProfileAnalysis} from '../../../../../models/respose-data.model';
import {KsaDetails} from '../../../../../models/master-ksa.model';
import {FormBuilder} from '@angular/forms';
import {QuestionInitializeService} from '../../../../../services/question-initialize.service';
import {areLocaleEnumsEqual, LocalizationLocaleEnum} from '../../../../../enums/localization-locale.enum';

@Component({
  selector: 'app-ksa-section',
  templateUrl: './ksa-section.component.html',
  styleUrls: ['./ksa-section.component.scss']
})
export class KsaSectionComponent implements OnInit {
  readonly config: Config = {
    toolbar: [
      ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'color', 'fontsize']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['table', 'link', 'picture']]
    ]
  };
  @Input() ksaSectionData!: any;
  @Input() ksaLanguage: LocalizationLocaleEnum;
  @Output() updateQuestion = new EventEmitter<any>();
  editMode = false;
  showDisplayAnswerLabel: boolean;
  showMatrixAnswerLabel: boolean;
  roleProfiles: RoleProfileAnalysis;
  ksaDetails: KsaDetails;
  questionsKsa: MasterSurveyQuestion[] = [];
  selectedQuestionData: any;
  showMandatoryTypePopUp = false;
  selectedQuestion: MasterSurveyQuestion;

  constructor(private readonly ksaShareService: KsaShareService,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly ksaRoleProfileService: KsaRoleProfileService,
              private readonly questionInitializeService: QuestionInitializeService,
              private readonly formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.ksaShareService.getKSADetails().subscribe(data => this.ksaDetails = data);
    this.setQuestionValueChange();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  setQuestionValueChange(): void {
    const lastSection = this.ksaDetails.ksaSection[this.ksaDetails.ksaSection.length - 1];
    if (this.ksaSectionData.isCoreSection) {
      this.ksaSectionData.ksaTechnicalQuestions.sort((previousQuestion, nextQuestion) => (previousQuestion.sectionQuestion.sectionQuestionSequenceNo > nextQuestion.sectionQuestion.sectionQuestionSequenceNo ? 1 : -1));
      const lastQuestionOfSection = this.ksaSectionData.ksaTechnicalQuestions[this.ksaSectionData.ksaTechnicalQuestions.length - 1]
      this.ksaSectionData.ksaTechnicalQuestions.forEach(questionTechnical => {
        questionTechnical.sectionQuestion[SurveyControls.isTechnicalQuestion] = true;
        questionTechnical.sectionQuestion['isPageBreakAddedForKSA'] =
          (questionTechnical.sectionQuestion.question.questionType.id === QuestionType.RoleProfile &&
            !this.ksaDetails?.isAdvance) ? false : questionTechnical.isPageBreakAdded;

        questionTechnical.sectionQuestion['isLastQuestion'] = false;

        if (this.ksaSectionData.sectionSequenceNo == lastSection.sectionSequenceNo) {
          if (lastQuestionOfSection.sectionQuestion.sectionQuestionSequenceNo == questionTechnical.sectionQuestion.sectionQuestionSequenceNo) {
            questionTechnical.sectionQuestion['isLastQuestion'] = true;
          }
        }

        this.questionsKsa.push(questionTechnical.sectionQuestion);
        const questionTypeId = questionTechnical.sectionQuestion.question.questionType.id;
        if ([QuestionType.MatrixTable, QuestionType.RoleProfile, QuestionType.Scale].includes(questionTypeId)) {
          this.setKSAQuestionDetails(questionTechnical?.sectionQuestion, questionTypeId);
        }
      });
    } else {
      this.ksaSectionData?.ksaQuestions.sort((previousQuestion, nextQuestion) => (previousQuestion.sectionQuestionSequenceNo > nextQuestion.sectionQuestionSequenceNo ? 1 : -1));
      const lastQuestionOfSection = this.ksaSectionData.ksaQuestions[this.ksaSectionData.ksaQuestions.length - 1]
      this.ksaSectionData.ksaQuestions.forEach(questionNonTechnical => {
        this.setEditText(questionNonTechnical);
        questionNonTechnical[SurveyControls.isTechnicalQuestion] = false;
        questionNonTechnical['isPageBreakAddedForKSA'] =
          (questionNonTechnical.question.questionType.id === QuestionType.RoleProfile &&
            !this.ksaDetails?.isAdvance) ? false : questionNonTechnical.isPageBreakAdded;

        questionNonTechnical['isLastQuestion'] = false;
        if (this.ksaSectionData.sectionSequenceNo == lastSection.sectionSequenceNo) {
          if (lastQuestionOfSection.sectionQuestionSequenceNo == questionNonTechnical.sectionQuestionSequenceNo) {
            questionNonTechnical['isLastQuestion'] = true;
          }
        }

        this.questionsKsa.push(questionNonTechnical);

        const questionTypeId = questionNonTechnical.question.questionType.id;
        if ([QuestionType.MatrixTable, QuestionType.RoleProfile, QuestionType.Scale].includes(questionTypeId)) {
          this.setKSAQuestionDetails(questionNonTechnical, questionTypeId);
        }
      });
    }
  }

  setEditText(question: any): void {
    if (areLocaleEnumsEqual(this.ksaLanguage, LocalizationLocaleEnum.ENGLISH)) {
      question['editText'] = question.questionText;
      return;
    }

    question['editText'] = question.ksaQuestionLocalizations.filter(kql => areLocaleEnumsEqual(kql.locale, this.ksaLanguage))[0].text;
  }

  setKSAQuestionDetails(question, questionType): void {
    switch (questionType) {
      case QuestionType.MatrixTable:
        this.questionInitializeService.setMatrixTypeOnView(question);
        break;
      case QuestionType.Scale:
        this.questionInitializeService.setScaleTypeOnView(question);
        break;
      case QuestionType.RoleProfile:
        if (this.ksaDetails.ksaJobProfileSet?.id) {
          this.ksaRoleProfileService.getKsaRoles(this.ksaDetails.ksaJobProfileSet?.id)
            .subscribe(res => this.roleProfiles = res)
        }
        break;
      default: {
        throw new Error(`Unhandled Operation Case.`);
      }
    }
  }

  onPageBreak(question: KsaQuestionAddPageBreakEntity, value: boolean): void {
    if (question.isTechnicalQuestion) {
      this.ksaSectionData.ksaTechnicalQuestions.forEach(questionTechnical => {
        if (questionTechnical.sectionQuestion.sectionQuestionSequenceNo == question.sectionQuestionSequenceNo) {
          questionTechnical.isPageBreakAdded = value;
          questionTechnical.sectionQuestion['isPageBreakAddedForKSA'] = value;
        }
      });
    } else {
      question.isPageBreakAdded = value;
      question['isPageBreakAddedForKSA'] = value;
    }
    this.setKsaDetails();
  }

  setKsaDetails(question?: any): void {
    if (question) {
      if (areLocaleEnumsEqual(this.ksaLanguage, LocalizationLocaleEnum.ENGLISH)) {
        question.questionText = question.editText;
      } else {
        question.ksaQuestionLocalizations.filter(kql => areLocaleEnumsEqual(kql.locale, this.ksaLanguage))[0].text = question.editText;
      }
    }

    this.ksaShareService.setKSADetails(this.ksaDetails);
  }

  openMandatoryQuestionPopup(question: MasterSurveyQuestion): void {
    this.selectedQuestion = question;
    this.selectedQuestionData = this.formBuilder.group({
      isMandatory: question.isMandatory
    });
    this.showMandatoryTypePopUp = true;
  }

  onShowMandatory(): void {
    const isSelectedQuestionOriginalValue = this.selectedQuestion && this.selectedQuestion.isMandatory;
    const isSelectedQuestionNewValue = this.selectedQuestionData && this.selectedQuestionData.get(SurveyControls.isMandatory).value;

    if (isSelectedQuestionOriginalValue !== isSelectedQuestionNewValue) {
      this.selectedQuestion.isMandatory = this.selectedQuestionData.get(SurveyControls.isMandatory).value;
      this.updateQuestion.emit(this.selectedQuestion);
    }

    this.showMandatoryTypePopUp = false;
    this.selectedQuestionData = null;
    this.selectedQuestion = null;
  }

  get questionTypeEnum(): typeof QuestionType {
    return QuestionType;
  }

  get surveyControlsEnum(): typeof SurveyControls {
    return SurveyControls;
  }

  get ksaOperationTypeEnum(): typeof ksaQuestionOperations {
    return ksaQuestionOperations;
  }

  get statusEnum(): typeof Status {
    return Status;
  }

}
