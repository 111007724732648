import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private reportsUrl: string;

  constructor(private http: HttpClient) {
    this.reportsUrl = `${environment.API_URL}${API_KSA_CORE_V1}/template`;
  }

  getReport(id): Observable<any> {
    return this.http.get<any>(`${this.reportsUrl}/${id}`);
  }

  getReportName(id): Observable<any> {
    return this.http.get<any>(`${this.reportsUrl}/${id}`);
  }

  uploadTemplate(file, id): Observable<any> {
    return this.http.post<any>(`${this.reportsUrl}/upload/`, file, id);
  }

  downloadReport(ksaId: number): Observable<any> {
    return this.http.get(`${API_KSA_CORE_V1}/reports/${ksaId}/docxReport`, {
      responseType: "blob", observe: "response"
    });
  }

  extractFileNameFromContentDispositionHeader(headers: HttpHeaders): string {
    const contentDisposition: string = headers.get('Content-Disposition') || '';
    const matches = /filename=([^;]+)/ig.exec(contentDisposition);
    return (matches[1] || '').trim();
  }
}
