/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/

import {CourseContent, Tag} from "./course.model";
import {SurveyTemplateConfigVersion} from "./config-version.model";
import {Skill} from "./skill.model";

export interface SkillPage {
  content?: SkillContentEntity[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  size?: number;
  sort?: Sort;
  totalElements?: number;
  totalPages?: number;
  skillName?: string,
  id?: number,
  skillDescription?: string;
  skillGroup?: SkillGroup;
  status?: string;
}

export interface SkillContentEntity extends Skill {
  courseSkillMaps?: (CourseSkillMapsEntity)[] | any;
  skillGroup?: SkillGroup;
  tagSkillMaps?: Tag[];
  skillCourses?: CourseMapping[];
  courses?: string[];
  surveyTemplateConfigVersion?: SurveyTemplateConfigVersion;
}

export interface CourseSkillMapsEntity {
  course?: Course;
  courseId?: number;
  courseName?: string;
  createdBy?: string;
  createdDate?: string;
  id?: number;
  isDefault?: boolean;
  isSelected?: boolean;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  skillMap?: SkillMap;
}

export interface Course {
  courseName?: string;
  createdBy?: string;
  createdDate?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export interface SkillMap {
}

export interface SkillGroup {
  id?: number;
  isSkillMapped?: boolean;
  skillGroupName?: string;
}

export interface Pageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: Sort;
  unpaged: boolean;
}

export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface skillColumnHeaderList {
  columnName?: string,
  isHide?: boolean,
  sequenceNumber?: number,
  columnFilterName?: string
}

export interface CourseMapping {
  course: CourseContent;
  isDefault: boolean;
}

export enum SkillStatus {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  ARCHIVED = 'Archived'
}
