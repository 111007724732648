import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Analysis} from '../../../../enums/analaysis.enum';
import {ResponseColumnSettings, ResponseOriginalData} from '../../../../models/respose-data.model';
import {CertificateService} from '../../../../services/certificate.service';
import {ResponseService} from '../../../../services/response.service';
import {ClrDatagridStateInterface} from "@clr/angular";
import {CertificateDetails} from '../../../../models/certification.model';

@Component({
  selector: 'app-certificate-original-data',
  templateUrl: './certificate-original-data.component.html',
  styleUrls: ['./certificate-original-data.component.scss']
})
export class CertificateOriginalDataComponent implements OnInit {

  page: number;
  total: number;
  state: ClrDatagridStateInterface;
  ksaId: number;
  responseColumnSettings: (ResponseColumnSettings)[];
  responseData: (ResponseOriginalData)[];
  certificateDetails: CertificateDetails;
  analysisEnum: typeof Analysis = Analysis;
  showDataGrid: boolean = false;
  loading: boolean = false;
  showDataGridOnResponse: boolean = false;
  pinnedColumn: string;

  constructor(public responseService: ResponseService, private route: ActivatedRoute,
              private certificateService: CertificateService, private cdr: ChangeDetectorRef) {
  }

  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.ksaId = params.id;
    });
    this.getAllResponseColumnData(this.ksaId);
    if (this.ksaId) {
      this.refreshCertificateDatagrid(this.state);
    }
  }

  getCertificateData(): void {
    this.certificateService.getOriginalCertificateData(this.ksaId).subscribe(certificateData => {
      this.certificateDetails = certificateData;
      this.certificateDetails.ksaId = this.ksaId;
      this.showDataGrid = true;
    });
  }

  getAllResponseColumnData(id: number): void{
    this.loading = true;
    this.responseService.getAllResponseColumnData(id).subscribe(data=>{
      if(data){
        this.responseColumnSettings = data;
        this.loading = false;
        this.showDataGridOnResponse = true;
      }
    })
  }

  refreshCertificateDatagrid(state: ClrDatagridStateInterface) {
    this.state = state;
    this.loading = true;
    const colFilters: ({ columnName: string; columnValue: string; })[] = [];
    let columnName = '';
    let data;
    if (state?.filters) {
      state.filters.forEach((filter,i)=> {
        const {property, value} = <{ property: string; value: string }>filter;
        colFilters.push({columnValue : value, columnName : property});
        columnName = property;
      })
      data={
        ksaId: this.ksaId,
        size: state?.page ?  (state?.page?.size) : 10,
        sortColumn: columnName,
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false)  ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0,
        analysisFilterDTOS: colFilters
      }
    }else{
      data={
        ksaId: this.ksaId,
        size: state?.page ? (state?.page?.size) : 10,
        sortColumn: state?.sort ? state.sort.by : "",
        sortOrder: (state?.sort?.reverse === undefined || state?.sort?.reverse === false)  ? 'ASC' : 'DESC',
        page: state?.page ? (state?.page.current - 1) : 0
      }
    }
    this.certificateService.updateOriginalCertification(data)
      .subscribe((results: CertificateDetails) => {
        this.certificateDetails = results;
        this.total = results.total;
        this.loading = false;
      });
  }
}
