import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RoleProfile, RoleProfileSkillMap} from '../../../../models/role-profile.model';
import {SurveyTemplateConfigVersion} from '../../../../models/config-version.model';
import {cloneDeep} from 'lodash';
import {hideSpinner, showSpinner} from '../../../../common/spinner';
import {finalize} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {SkillsService} from '../../../../services/skills.service';
import {Skill} from '../../../../models/skill.model';
import {Status} from '../../../../enums/status.enum';
import {NgForm} from '@angular/forms';
import {RoleProfileService} from '../../../../services/role-profile.service';

@Component({
  selector: 'app-mng-roles-profiles-save-modal',
  templateUrl: './mng-roles-profiles-save-modal.component.html',
  styleUrls: ['./mng-roles-profiles-save-modal.component.scss']
})
export class MngRolesProfilesSaveModalComponent implements OnInit {

  @Input() isAdd: boolean;
  @Input() isEdit: boolean;
  @Input() isClone: boolean;
  @Input() selectedRoleProfile: RoleProfile;
  @Input() surveyTemplateConfigVersion: SurveyTemplateConfigVersion;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  errorMsg = '';
  roleProfile: RoleProfile;
  roleProfileSkillMap: RoleProfileSkillMap[];

  constructor(private readonly translate: TranslateService,
              private readonly skillService: SkillsService,
              private readonly roleProfileService: RoleProfileService) {
  }

  get headerTitle(): string {
    if (this.isAdd) {
      return 'admin.roleProfilesAndGroups.roleProfiles.addRoleProfileModalTitle';
    }
    if (this.isClone) {
      return 'admin.roleProfilesAndGroups.roleProfiles.cloneRoleProfile';
    }

    return 'admin.roleProfilesAndGroups.roleProfiles.editRoleProfileModalTitle';
  }

  get submitButtonTitle(): string {
    if (this.isAdd) {
      return 'admin.addBtn';
    }
    if (this.isClone) {
      return 'admin.cloneBtn';
    }

    return 'admin.saveBtn';
  }

  ngOnInit(): void {
    this.roleProfile = this.createRoleProfile(cloneDeep(this.selectedRoleProfile));
    this.createRoleProfileSkillMap();

  }

  onCancel(): void {
    this.closeModal.emit(false);
  }

  onSave(form: NgForm): void {
    if (form.invalid || this.roleProfile.roleProfileSkillMaps.length === 0) {
      return;
    }

    const roleProfileToSave = this.trimAndConstructRoleProfile();

    const save$ = this.isEdit ?
      this.roleProfileService.updateRoleProfile(roleProfileToSave) :
      this.roleProfileService.createRoleProfile(roleProfileToSave);

    showSpinner(this.translate.instant('spinnerLabels.common.saving'));
    save$
      .pipe(finalize(() => hideSpinner()))
      .subscribe(() => {
          this.closeModal.emit(true);
        },
        error => this.errorMsg = error.error.message);

  }

  private createRoleProfile(roleProfile?: RoleProfile): RoleProfile {
    if (this.isClone) {
      roleProfile?.roleProfileSkillMaps?.forEach(e => e.id = null);
    }
    return {
      id: this.isClone ? null : roleProfile?.id,
      name: this.isClone ? `${roleProfile?.name}-Cloned` : roleProfile?.name || '',
      description: roleProfile?.description || '',
      roleProfileSkillMaps: roleProfile?.roleProfileSkillMaps || []
    };
  }

  private createRoleProfileSkillMap(): void {
    showSpinner(this.translate.instant('spinnerLabels.common.loading'));
    this.skillService.getSkillsByConfigVersionIdAndStatus(this.surveyTemplateConfigVersion.id)
      .pipe(finalize(hideSpinner))
      .subscribe(data => {
        this.roleProfileSkillMap = data
          .filter(skill => skill.status === Status.Active || !!this.findRoleProfileSkillMapInRoleProfile(skill))
          .map(skill => {
            const roleProfileSkill = this.findRoleProfileSkillMapInRoleProfile(skill);
            return roleProfileSkill ?? {
              comment: '',
              skill
            } as RoleProfileSkillMap;
          });
      });
  }

  private findRoleProfileSkillMapInRoleProfile(skill: Skill): RoleProfileSkillMap {
    return this.roleProfile?.roleProfileSkillMaps?.find(e => e?.skill.id === skill.id);
  }

  private trimAndConstructRoleProfile(): any {
    this.roleProfile.name = this.roleProfile.name?.trim();
    this.roleProfile.description = this.roleProfile.description?.trim();

    return {
      ...this.roleProfile,
      surveyTemplateConfigVersion: this.surveyTemplateConfigVersion
    };
  }
}
