<form clrForm class="clr-row">
  <div class="clr-col-12">
    <clr-select-container class="clr-row">
      <label class="clr-col-2">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.roleProfileSetName' |
        translate}}*</label>
      <select (change)="displayRoleAndSkillsBySetId()" [(ngModel)]="importedRoleProfileSetSelected"
              class="clr-col-7 solution-group-select-input input-field"
              clrSelect name="roleProfileSetImported" required>
        <option disabled selected value="">
          {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfileGroup.selectRoleProfileSetFromList' |
          translate}}
        </option>
        <option *ngFor="let importedRoleProfileSet of roleProfileSets?.ksaJobProfileSets"
                [ngValue]="importedRoleProfileSet">{{importedRoleProfileSet.name}}
        </option>
      </select>
      <clr-control-error>{{'errorMsg.roleProfileSetRequired'|translate}}</clr-control-error>
    </clr-select-container>
  </div>
</form>
<button *ngIf="hasWritePermission" class="btn btn-sm btn-primary" (click)="onSetAddKsaFormOpen()"
        [disabled]="!importedRoleProfileSetSelected">
  {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.addRoleProfile' | translate}}
</button>
<button *ngIf="hasWritePermission" (click)="onDeleteMultipleRoleProfile(selectedRoles)"
        [disabled]="!importedRoleProfileSetSelected"
        class="btn btn-sm btn-primary">
  {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.deleteRoleProfile' | translate}}
</button>
<clr-modal (clrModalOpenChange)="onCancelAdding()" [(clrModalOpen)]="addRoleProfileFormOpen" [clrModalClosable]="true"
           class="modal-lg">
  <h3 class="modal-title">{{'admin.roleProfilesAndGroups.roleProfiles.addRoleProfileModalTitle' | translate}}
    <clr-alert clrAlertType="danger" *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">
    <form #addForm="ngForm" autocomplete="off" clrForm>
      <clr-input-container class="clr-row">
        <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.roleProfileSet' |
          translate}}*</label>
        <input [value]="importedRoleProfileSetSelected?.name" class="clr-col-7" clrInput name="roleProfileSetName"
               disabled required required type="text"/>
      </clr-input-container>
      <clr-select-container class="clr-row">
        <label class="clr-col-5">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.roleProfileGroup' |
          translate}}*</label>
        <select [(ngModel)]="importedRoleProfileGroupSelected" class="clr-col-7 solution-group-select-input input-field"
                clrSelect
                name="roleProfilegGroupImported" required>
          <option disabled selected value="">
            {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.selectRoleProfileGroup' | translate}}
          </option>
          <option *ngFor="let importedRoleProfileGroup of importedRoleProfileSetSelected?.ksaRoleProfileGroups"
                  [ngValue]="importedRoleProfileGroup">{{importedRoleProfileGroup?.roleProfileGroupName}}
          </option>
        </select>
        <clr-control-error>{{'errorMsg.roleProfileGroupRequired'|translate}}</clr-control-error>
      </clr-select-container>
      <clr-input-container class="clr-row">
        <label class="clr-col-5">{{'admin.roleProfilesAndGroups.roleProfiles.roleProfile' | translate}}*</label>
        <input [(ngModel)]="newRoleProfile.ksaRoleProfiles[0].ksaRoleProfileName" class="clr-col-7" clrInput
               name="roleProfileName"
               placeholder="{{'placeholder.roleProfileName' | translate}}" required required type="text"/>
        <clr-control-error>{{'errorMsg.roleProfileNameRequired' | translate}}</clr-control-error>
      </clr-input-container>
      <clr-textarea-container class="clr-row">
        <label class="clr-col-5">{{'admin.roleProfilesAndGroups.roleProfiles.description' | translate}}</label>
        <textarea [(ngModel)]="newRoleProfile.ksaRoleProfiles[0].description" class="clr-col-7" clrTextarea
                  name="description" placeholder="{{'placeholder.roleProfileDescription' | translate}}"
                  rows="1"></textarea>
        <clr-control-error>{{'errorMsg.roleProfileDescriptionRequired' | translate}}</clr-control-error>
      </clr-textarea-container>
      <div class="button-container">
        <span class="clr-control-label">{{'admin.roleProfilesAndGroups.roleProfiles.selectSkill' | translate}}
        </span>
      </div>
      <clr-datagrid [(clrDgSelected)]="selectedSkills"
                    class="datagrid-compact add-datagrid">
        <clr-dg-column [clrDgField]="'skillName'">
          {{'admin.roleProfilesAndGroups.roleProfiles.skill' | translate}}
        </clr-dg-column>
        <clr-dg-column>{{'admin.roleProfilesAndGroups.roleProfiles.threshold' |
          translate}} (%)*
        </clr-dg-column>
        <clr-dg-column>{{'admin.roleProfilesAndGroups.roleProfiles.comment' | translate}}</clr-dg-column>
        <clr-dg-row *clrDgItems="let skill of skills; let i = index" [clrDgItem]="skill">
          <clr-dg-cell>{{skill?.skillName}}</clr-dg-cell>
          <clr-dg-cell>
            <input [(ngModel)]="skill.threshold" [disabled]="!selectedSkills?.includes(skill)" clrInput max="100"
                   min="1" name="threshold{{i}}"
                   oninput="if(this.value > 100 || this.value < 0) {this.value = null;}" placeholder="00"
                   required type="number">
          </clr-dg-cell>
          <clr-dg-cell><input clrInput type="text" name="comment{{i}}" [(ngModel)]="skill.comment"
                              [disabled]="!selectedSkills?.includes(skill)"
                              placeholder="{{'placeholder.addComment' | translate}}">
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-outline" (click)="onCancelAdding()">{{'admin.cancelBtn' |
          translate}}
        </button>
        <button (click)="onAddRoleProfile(addForm)" [disabled]="addForm?.invalid" class="btn btn-sm btn-primary"
                type="button">{{'admin.addBtn' |
          translate}}
        </button>
      </div>
    </form>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="showDeleteWarning" (clrModalOpenChange)="errorMsg = ''">
  <h3 class="modal-title">{{'admin.roleProfilesAndGroups.roleProfiles.deleteRoleProfile' | translate}}?
    <clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true" clrAlertType="danger">
      <clr-alert-item>{{errorMsg}}</clr-alert-item>
    </clr-alert>
  </h3>
  <div class="modal-body">{{'admin.roleProfilesAndGroups.roleProfiles.thisCannotBeUndone' | translate}}.</div>
  <div class="modal-footer">
    <button (click)="showDeleteWarning = false" class="btn btn-outline btn-sm">{{'admin.cancelBtn' |
      translate}}
    </button>
    <button (click)="onDeleteRoleProfile()" class="btn btn-outline-warning btn-sm">{{'admin.deleteBtn' |
      translate}}
    </button>
  </div>
</clr-modal>
<clr-alert *ngIf="errorMsg !== ''" [clrAlertClosable]="false" clrAlertType="danger">
  <clr-alert-item>{{errorMsg}}</clr-alert-item>
</clr-alert>
<div *ngIf=importedRoleProfileSetSelected>
  <clr-datagrid [(clrDgSelected)]="selectedRoles" class="parent-datagrid role-profile-datagrid">
    <clr-dg-column [clrDgField]="'ksaRoleProfileName'">
      {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.roleProfileName' | translate}}
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'description'">
      {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.description' | translate}}
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'roleProfileGroupName'">
      {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.group' | translate}}
    </clr-dg-column>
    <clr-dg-column *ngFor="let skill of skills">
      {{skill.skillName}}
    </clr-dg-column>
    <clr-dg-column class="action-cell" *ngIf="hasWritePermission"></clr-dg-column>
    <clr-dg-row *clrDgItems="let roleProfile of result;let i = index" [clrDgItem]="roleProfile">
      <ng-container *ngIf="editRoleProfile?.id == roleProfile?.id">
        <clr-dg-cell><input [(ngModel)]="editRoleProfile.ksaRoleProfileName" clrInput name="roleProfileName" size="60"/>
        </clr-dg-cell>
        <clr-dg-cell><input [(ngModel)]="editRoleProfile.description" clrInput name="description" size="60"/></clr-dg-cell>
        <clr-dg-cell><input [(ngModel)]="editRoleProfile.roleProfileGroupName" clrInput name="group"/></clr-dg-cell>
        <clr-dg-cell *ngFor="let skill of editRoleProfile.skills;let i= index">
          <input [(ngModel)]="skill.threshold" class="skillInput" clrInput name="skillThreshold{i}"
                 oninput="(this.value > 100 || this.value < 0)&& (this.value = null)"
                 type="number">
        </clr-dg-cell>
        <clr-dg-cell>
          <clr-dropdown [clrCloseMenuOnItemClick]="true">
            <button clrDropdownTrigger aria-label="Dropdown demo button">
              <clr-icon shape="ellipsis-vertical"></clr-icon>
            </button>
            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
              <button (click)="onEditRoleProfileRow()" class="btn btn-sm"
                      type="button">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.save'
                | translate}}
              </button>
              <button (click)="onCancelEditing()" class="btn btn-sm"
                      type="button">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.cancel' |
                translate}}
              </button>
            </clr-dropdown-menu>
          </clr-dropdown>
        </clr-dg-cell>
      </ng-container>
      <ng-container *ngIf="editRoleProfile?.id !== roleProfile.id && roleProfile.id !==0">
        <clr-dg-cell>{{roleProfile.ksaRoleProfileName}}</clr-dg-cell>
        <clr-dg-cell>{{roleProfile.description}}</clr-dg-cell>
        <clr-dg-cell>{{roleProfile.roleProfileGroupName}}</clr-dg-cell>
        <clr-dg-cell *ngFor="let skill of roleProfile.skills">
          {{skill.threshold? skill.threshold : ''}}
        </clr-dg-cell>
        <clr-dg-cell class="action-cell" *ngIf="hasWritePermission">
          <clr-dg-action-overflow>
            <button (click)="onEditRoleProfile(roleProfile);" class="action-item"
                    type="button">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.edit'
              | translate}}
            </button>
            <button (click)="onShowDeleteWarning(roleProfile.id)" class="action-item"
                    type="button">{{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.delete'
              | translate}}
            </button>
          </clr-dg-action-overflow>
        </clr-dg-cell>
      </ng-container>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
          {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.rolesPerPage' | translate}}
        </clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}}
        {{'ksaEngagement.ksaAdministration.ksaRoleProfile.ksaRoleProfile.roles' | translate}}
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>
