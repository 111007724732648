/*******************************************************************
 Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential
 ********************************************************************/

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {EmailTemplate, EmailTemplateType, PlaceHolder} from "../models/email-template.model";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  private readonly emailTemplateUrl: string;
  constructor(private http: HttpClient) {
    this.emailTemplateUrl = `${environment.API_URL}${API_KSA_CORE_V1}/emailtemplate/`;
  }

  getEmailTemplatePage(filter1, filter2, filter3, from, to, sortby, sortorder): Observable<EmailTemplate[]> {
    filter1 = encodeURIComponent(filter1);
    filter2 = encodeURIComponent(filter2);
    filter3 = encodeURIComponent(filter3);
    return this.http.get<EmailTemplate[]>(`${this.emailTemplateUrl}getAll?emailTemplateName=${filter1}&description=${filter2}&createdDate=${filter3}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`)
  }

  createEmailTemplate(emailTempplate: EmailTemplate): Observable<any> {
    return this.http.post(`${this.emailTemplateUrl}`, emailTempplate);
  }


  updateEmailTemplate(emailTempplate: any): Observable<any> {
    return this.http.put(`${this.emailTemplateUrl}`, emailTempplate);
  }

  deleteEmailTemplate(id: number): Observable<any> {
    return this.http.delete(`${this.emailTemplateUrl}${id}`);
  }

  getEmailTemplateById(id: number): Observable<EmailTemplate> {
    return this.http.get<EmailTemplate>(`${this.emailTemplateUrl}${id}`);
  }

  getEmailTemplateType() {
    return this.http.get<EmailTemplateType[]>(`${this.emailTemplateUrl}emailtemplatetype/`);
  }

  getPlaceHolder() {
    return this.http.get<PlaceHolder[]>(`${this.emailTemplateUrl}placeholder/`);
  }

  getInviteEmailTemplates(): Observable<any[]> {
    return this.http.get<any[]>(`${this.emailTemplateUrl}inviteEmailTemplate/`);
  }


  getSchedulerEmailTemplates(): Observable<any[]> {
    return this.http.get<any[]>(`${this.emailTemplateUrl}reminderEmailTemplate/`);
  }
  getThanksEmailTemplates(): Observable<any[]> {
    return this.http.get<any[]>(`${this.emailTemplateUrl}thanksEmailTemplate/`);
  }

}
