/*
 * Copyright 2022 VMware, Inc. All rights reserved. VMware Confidential
 */

export enum LocalizationLocaleEnum {
  ENGLISH = <any>"English",
  JAPANESE = <any>"Japanese",
  SPANISH = <any>"Spanish",
  PORTUGUESE_BRAZIL = <any>"Portuguese (Brazil)",
  CHINESE = <any>"Chinese",
  FRENCH = <any>"French",
  GERMAN = <any>"German"
}

export function areLocaleEnumsEqual(a: string | LocalizationLocaleEnum, b: string | LocalizationLocaleEnum): boolean {
  return a?.toString()?.toLowerCase() === b?.toString()?.toLowerCase();
}
