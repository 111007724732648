<clr-modal [(clrModalOpen)]="showImageUploadPopUp"[clrModalClosable]="true" (clrModalOpenChange)="onClosePopUp()">
    <h5 class="modal-title">{{'surveyTemplate.questionSheet.isUploadImage'|translate}}</h5>
    <div class="modal-body">
      <form class="clr-form">
        <div class="clr-form-control">
          <label class="clr-control-label"></label>
          <div class="clr-control-container">
            <div class="clr-file-wrapper">
              <label class="clr-control-label" for="basic"><span class="btn btn-sm">{{'admin.browseBtn' | translate}}</span></label>
              <input (change)="onImageSelect($event)" accept="image/png, image/jpeg, image/jpg" class="clr-file" id="basic" type="file" />
            </div>
            <div *ngIf="imageURL" class="clr-row">
              <div class="clr-col-10">
                <div class="card">
                  <img [src]="imageURL" alt="Question Image" height="200" width="500">
                </div>
              </div>
            </div>
            <div *ngIf="isUploadImageInvalid" class="alert alert-danger" id="alert-message" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                  </div>
                  <span class="alert-text">
                  {{'surveyTemplate.masterSurvey.'+ imageInvalidErrorMsg |translate}}
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="serverUploadError" class="alert alert-danger" id="alert-message" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                  </div>
                  <span class="alert-text">
                  {{'surveyTemplate.masterSurvey.ServerImageErrorMsg' |translate}}{{serverUploadErrorMsg}}
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="!imageURL">
              <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
              <span class="clr-subtext">{{'surveyTemplate.questionSheet.isImageTypeSupport'|translate}}</span>
              <span>{{'surveyTemplate.questionSheet.isMaxSizeImage'|translate}}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="onClosePopUp()" class="btn btn-outline btn-sm">{{'surveyTemplate.mngSurvey.cancelBtn' | translate}}</button>
      <button [disabled]="isUploadButtonDisabled()" (click)="onUploadImage()" class="btn btn-primary btn-sm">{{'surveyTemplate.questionSheet.isUpload'|translate}}</button>
    </div>
  </clr-modal>
