import {Injectable} from '@angular/core';
import {QuestionType} from '../enums/questionType.enum';
import {QuestionTypeFile} from '../enums/questionTypeFile.enum';
import {SurveyControls} from '../enums/survey-controls.enum';
import {AnswerLabel, MasterSurveyQuestion} from '../models/master-survey-questions.model';
import {MasterSurveySection} from '../models/master-survey-section.model';
import {ResponseFileQuestion, SurveyQuestionImport} from '../models/survey-import-questions.model';
import {MasterSurveyService} from './master-survey.service';
import {ShareSurveyDataService} from './share-survey-data.service';
import {
  MasterSurveyManagerService
} from "../components/administration/mng-master-survey/master-survey/master-survey-manager.service";

const replaceHtmlRegex = /<[^>]*>/g;
const replaceDoubleQuotesRegex = /(^"|"$)/g;

@Injectable({
  providedIn: 'root'
})
export class ImportQuestionFileService {

  constructor(private shareSurveyDataService: ShareSurveyDataService,
              private masterSurveyService: MasterSurveyService,
              private masterSurveyManagerService: MasterSurveyManagerService) {
  }

  questionTypeFileEnum: typeof QuestionTypeFile = QuestionTypeFile;
  surveyControlsEnum: typeof SurveyControls = SurveyControls;
  questionTypeEnum: typeof QuestionType = QuestionType;

  validateCsvQuestions(allQuestions: SurveyQuestionImport[]): ResponseFileQuestion {
    return this.validateAllQuestions(allQuestions);
  }

  validateCsvSections(allQuestions: SurveyQuestionImport[]): ResponseFileQuestion {
    return this.validateAllSections(allQuestions);
  }

  mapSurveyCSVQuestion(allQuestions: SurveyQuestionImport[]): MasterSurveyQuestion[] {
    const listOfImportedSectionQuestions: MasterSurveyQuestion[] = [];
    allQuestions.forEach(question => {

      const questionType = question.type;
      if (questionType && question.name == this.surveyControlsEnum.questionTextFile) {
        switch (questionType) {
          case this.questionTypeFileEnum.FreeText: {
            const questionToImport: MasterSurveyQuestion = this.importFreeQuestionFile(question);
            listOfImportedSectionQuestions.push(questionToImport);
            break;
          }
          case this.questionTypeFileEnum.RadioButton: {
            const questionToImport: MasterSurveyQuestion = this.importRadioQuestionFile(question, allQuestions);
            listOfImportedSectionQuestions.push(questionToImport);
            break;
          }
          case this.questionTypeFileEnum.MultipleChoice: {
            const questionToImport: MasterSurveyQuestion = this.importMultipleQuestionFile(question, allQuestions);
            listOfImportedSectionQuestions.push(questionToImport);
            break;
          }
          case this.questionTypeFileEnum.Scale: {
            const questionToImport: MasterSurveyQuestion = this.importScaleQuestionFile(question);
            listOfImportedSectionQuestions.push(questionToImport);
            break;
          }
          case this.questionTypeFileEnum.Description: {
            const questionToImport: MasterSurveyQuestion = this.importDescriptionQuestionFile(question);
            listOfImportedSectionQuestions.push(questionToImport);
            break;
          }
          case this.questionTypeFileEnum.MatrixTable: {
            const questionToImport: MasterSurveyQuestion = this.importMatrixQuestionFile(question, allQuestions);
            listOfImportedSectionQuestions.push(questionToImport);
            break;
          }
          default: {
          }
        }
      }
    });
    return listOfImportedSectionQuestions;
  }

  mapSurveyCSVSection(allQuestions: SurveyQuestionImport[]): MasterSurveySection[] {
    const listOfImportedSection: MasterSurveySection[] = [];
    const uniqueSectionTitles = [...new Set(allQuestions.map(item => item.sectionName))];
    let newMaxTempQuestionId = this.shareSurveyDataService.findMaxQuestionId();

    uniqueSectionTitles.forEach(section => {
      if (section) {
        const listSectionQuestion: MasterSurveyQuestion[] = [];
        let countOfLastQuestion: number = 0;
        allQuestions.forEach(question => {
          const questionType = question.type;
          if (questionType && question.name == this.surveyControlsEnum.questionTextFile && question.sectionName == section) {
            switch (questionType) {
              case this.questionTypeFileEnum.FreeText: {
                const questionToImport: MasterSurveyQuestion = this.importFreeQuestionFile(question);
                questionToImport.isImportedCsv = true;
                questionToImport.sectionQuestionSequenceNo = ++countOfLastQuestion;
                questionToImport.tempSurveyQuestionId = ++newMaxTempQuestionId;
                listSectionQuestion.push(questionToImport);
                break;
              }
              case this.questionTypeFileEnum.RadioButton: {
                const questionToImport: MasterSurveyQuestion = this.importRadioQuestionFile(question, allQuestions);
                questionToImport.isImportedCsv = true;
                questionToImport.sectionQuestionSequenceNo = ++countOfLastQuestion;
                questionToImport.tempSurveyQuestionId = ++newMaxTempQuestionId;
                listSectionQuestion.push(questionToImport);
                break;
              }
              case this.questionTypeFileEnum.MultipleChoice: {
                const questionToImport: MasterSurveyQuestion = this.importMultipleQuestionFile(question, allQuestions);
                questionToImport.isImportedCsv = true;
                questionToImport.sectionQuestionSequenceNo = ++countOfLastQuestion;
                questionToImport.tempSurveyQuestionId = ++newMaxTempQuestionId;
                listSectionQuestion.push(questionToImport);
                break;
              }
              case this.questionTypeFileEnum.Scale: {
                const questionToImport: MasterSurveyQuestion = this.importScaleQuestionFile(question);
                questionToImport.isImportedCsv = true;
                questionToImport.sectionQuestionSequenceNo = ++countOfLastQuestion;
                questionToImport.tempSurveyQuestionId = ++newMaxTempQuestionId;
                listSectionQuestion.push(questionToImport);
                break;
              }
              case this.questionTypeFileEnum.Description: {
                const questionToImport: MasterSurveyQuestion = this.importDescriptionQuestionFile(question);
                questionToImport.isImportedCsv = true;
                questionToImport.sectionQuestionSequenceNo = ++countOfLastQuestion;
                questionToImport.tempSurveyQuestionId = ++newMaxTempQuestionId;
                listSectionQuestion.push(questionToImport);
                break;
              }
              case this.questionTypeFileEnum.MatrixTable: {
                const questionToImport: MasterSurveyQuestion = this.importMatrixQuestionFile(question, allQuestions);
                questionToImport.isImportedCsv = true;
                questionToImport.sectionQuestionSequenceNo = ++countOfLastQuestion;
                questionToImport.tempSurveyQuestionId = ++newMaxTempQuestionId;
                listSectionQuestion.push(questionToImport);
                break;
              }
              default: {
              }
            }
          }
        });
        const currentSectionArray: MasterSurveySection = this.createSectionData(section, listSectionQuestion);
        listOfImportedSection.push(currentSectionArray);
      }
    });
    return listOfImportedSection;
  }

  createSectionData(section, listOfImportedSection: MasterSurveyQuestion[]): MasterSurveySection {
    return {
      section: {
        sectionName: section
      },
      sectionSequenceNo: this.findSequenceQuestion(),
      isMandatorySection: true,
      isImportedSection: false,
      isTechnicalSection: true,
      sectionQuestions: listOfImportedSection,
      sectionSkipCondition: {
        answerLabelSequenceNo: null,
        colAnswerLabelSequenceNo: null,
        displayValue: null,
        questionSequenceNo: null,
        sectionSequenceNo: null,
        skipCondition: {
          id: null
        }
      },
      tempSectionId: this.findSequenceQuestion(),
    };
  }

  setSectionQuestions(sections: SurveyQuestionImport[]): MasterSurveyQuestion[] {
    const sectionQuestions: MasterSurveyQuestion[] = [];
    sections.forEach(sectionData => {
        const questionToImport: MasterSurveyQuestion = this.importRadioQuestionFile(sectionData, sections);
        sectionQuestions.push(questionToImport);
      }
    )
    return sectionQuestions;
  }

  validateAllQuestions(allQuestions: SurveyQuestionImport[]): ResponseFileQuestion {
    const objResponseFileQuestion = {
      errors: '',
      isValidQuestions: true,
    }
    const questionList: SurveyQuestionImport[] = allQuestions.filter(question => {
      return question.name == this.surveyControlsEnum.questionTextFile;
    });
    questionList.some(question => {
      const questionType = question.type;
      if (questionType) {
        switch (questionType) {
          case this.questionTypeFileEnum.FreeText: {
            this.validateNoAnswerLabelQuestionFile(question, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.RadioButton: {
            this.validateAnswerLabelQuestionFile(question, allQuestions, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.MultipleChoice: {
            this.validateAnswerLabelQuestionFile(question, allQuestions, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.Scale: {
            this.validateNoAnswerLabelQuestionFile(question, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.Description: {
            this.validateNoAnswerLabelQuestionFile(question, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.MatrixTable: {
            this.validateAnswerLabelQuestionFile(question, allQuestions, objResponseFileQuestion);
            if (objResponseFileQuestion.isValidQuestions) {
              this.validateColumnAnswerLabelMatrixQuestionFile(question, allQuestions, objResponseFileQuestion);
            }
            break;
          }
          default: {
            objResponseFileQuestion.errors = this.surveyControlsEnum.InvalidQuestionTypeTextMessage + question.id;
            objResponseFileQuestion.isValidQuestions = false;
            return;
          }
        }
        if (objResponseFileQuestion.isValidQuestions === false) {
          debugger
          return objResponseFileQuestion;
        }
      } else {
      }
    });
    return objResponseFileQuestion;
  }

  validateAnswerLabelQuestionFile(question: SurveyQuestionImport, allQuestions: SurveyQuestionImport[], objResponseFileQuestion): ResponseFileQuestion {
    if (question.name == this.surveyControlsEnum.questionTextFile) {
      if (!question.details) {
        objResponseFileQuestion.errors = this.surveyControlsEnum.MissingQuestionTextMessage + question.id;
        objResponseFileQuestion.isValidQuestions = false;
        return;
      } else {
        const listOfOptions = allQuestions.filter(questionData => {
          return questionData.id == question.id &&
            questionData.name.includes(this.surveyControlsEnum.choiceFile);
        });
        const emptyChoiceOption = listOfOptions.find(option => {
          return option.details == '';
        });
        if (emptyChoiceOption || listOfOptions.length <= 0) {
          objResponseFileQuestion.errors = this.surveyControlsEnum.MisingAnswerLabelMessage + question.id;
          objResponseFileQuestion.isValidQuestions = false;
          return;
        }
      }
    }
  }

  validateColumnAnswerLabelMatrixQuestionFile(question: SurveyQuestionImport, allQuestions: SurveyQuestionImport[], objResponseFileQuestion: ResponseFileQuestion) {
    const listOfColOptions = allQuestions.filter(questionData => {
      return questionData.id == question.id &&
        questionData.name.includes(this.surveyControlsEnum.answerFile);
    });
    const emptyChoiceOption = listOfColOptions.find(option => {
      return option.details == '';
    });
    if (emptyChoiceOption || listOfColOptions.length <= 0) {
      objResponseFileQuestion.errors = this.surveyControlsEnum.MisingAnswerLabelMessage + question.id;
      objResponseFileQuestion.isValidQuestions = false;
      return;
    }
  }

  validateNoAnswerLabelQuestionFile(question: SurveyQuestionImport, objResponseFileQuestion): ResponseFileQuestion {
    if (!question.details) {
      objResponseFileQuestion.errors = this.surveyControlsEnum.MissingQuestionTextMessage + question.id;
      objResponseFileQuestion.isValidQuestions = false;
      return;
    }
  }

  validateAllSections(allQuestions: SurveyQuestionImport[]): ResponseFileQuestion {
    const objResponseFileQuestion = {
      errors: '',
      isValidQuestions: true,
    }
    const questionList: SurveyQuestionImport[] = allQuestions.filter(question => {
      return question.name == this.surveyControlsEnum.questionTextFile;
    });
    questionList.some(question => {
      const questionType = question.type;
      if (questionType) {
        switch (questionType) {
          case this.questionTypeFileEnum.FreeText: {
            this.validateNoAnswerLabelSectionFile(question, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.RadioButton: {
            this.validateAnswerLabelSectionFile(question, allQuestions, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.MultipleChoice: {
            this.validateAnswerLabelSectionFile(question, allQuestions, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.Scale: {
            this.validateNoAnswerLabelSectionFile(question, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.Description: {
            this.validateNoAnswerLabelSectionFile(question, objResponseFileQuestion);
            break;
          }
          case this.questionTypeFileEnum.MatrixTable: {
            this.validateAnswerLabelSectionFile(question, allQuestions, objResponseFileQuestion);
            if (objResponseFileQuestion.isValidQuestions) {
              this.validateColumnAnswerLabelMatrixQuestionFile(question, allQuestions, objResponseFileQuestion);
            }
            break;
          }
          default: {
            objResponseFileQuestion.errors = this.surveyControlsEnum.InvalidQuestionTypeTextMessage + question.id;
            objResponseFileQuestion.isValidQuestions = false;
            return;
          }
        }
        if (objResponseFileQuestion.isValidQuestions === false) {
          return objResponseFileQuestion;
        }
      }
    });
    return objResponseFileQuestion;
  }

  validateNoAnswerLabelSectionFile(question: SurveyQuestionImport, objResponseFileQuestion: ResponseFileQuestion) {
    if (question.sectionName && !this.masterSurveyManagerService.isSurveySectionNameUnique(question.sectionName)) {
      objResponseFileQuestion.errors = this.surveyControlsEnum.DuplicateSectionMessage + question.sectionName + ' at ' + question.id;
      objResponseFileQuestion.isValidQuestions = false;
      return;
    }
    if (question.name == this.surveyControlsEnum.questionTextFile) {
      if (!question.sectionName) {
        objResponseFileQuestion.errors = this.surveyControlsEnum.MissingSectionTextMessage + question.id;
        objResponseFileQuestion.isValidQuestions = false;
        return;
      }
      if (!question.details) {
        objResponseFileQuestion.errors = this.surveyControlsEnum.MissingQuestionTextMessage + question.id;
        objResponseFileQuestion.isValidQuestions = false;
        return;
      }
    }
  }

  validateAnswerLabelSectionFile(question: SurveyQuestionImport, allQuestions: SurveyQuestionImport[], objResponseFileQuestion: ResponseFileQuestion) {
    if (question.sectionName && !this.masterSurveyManagerService.isSurveySectionNameUnique(question.sectionName)) {
      objResponseFileQuestion.errors = this.surveyControlsEnum.DuplicateSectionMessage + question.sectionName + ' at ' + question.id;
      objResponseFileQuestion.isValidQuestions = false;
      return;
    }
    if (question.name == this.surveyControlsEnum.questionTextFile) {
      if (!question.sectionName) {
        objResponseFileQuestion.errors = this.surveyControlsEnum.MissingSectionTextMessage + question.id;
        objResponseFileQuestion.isValidQuestions = false;
        return;
      }
      if (!question.details) {
        objResponseFileQuestion.errors = this.surveyControlsEnum.MissingQuestionTextMessage + question.id;
        objResponseFileQuestion.isValidQuestions = false;
        return;
      } else {
        const listOfOptions = allQuestions.filter(questionData => {
          return questionData.id == question.id &&
            questionData.name.includes(this.surveyControlsEnum.choiceFile) &&
            questionData.name;
        });
        const emptyChoiceOption = listOfOptions.find(option => {
          return option.details == '';
        });
        if (emptyChoiceOption || listOfOptions.length <= 0) {
          objResponseFileQuestion.errors = this.surveyControlsEnum.MisingAnswerLabelMessage + question.id;
          objResponseFileQuestion.isValidQuestions = false;
          return;
        }
      }
    }
  }

  importFreeQuestionFile(question: SurveyQuestionImport): MasterSurveyQuestion {
    return {
      question: {
        questionType: {
          id: this.questionTypeEnum.FreeText,
        },
        questionText: question.details.replace(replaceHtmlRegex, '').replace(replaceDoubleQuotesRegex, ''),
        image: null,
        answerProperty: {
          isSingleLine: true
        },
        mapSurveyQuestionWithTags: []
      },
      questionNote: '',
      language: {
        id: 1
      },
      skill: null,
      sectionQuestionSequenceNo: this.findSequenceQuestion(),
      tempSurveyQuestionId: this.shareSurveyDataService.findMaxQuestionId() + 1,
      isMandatory: true,
      isPageBreakAdded: false,
      isImported: false,
      isVersioned: false,
      questionDisplayCondition: null,
      questionSkipCondition: null,
      questionDisplayConditions: [],
      sectionSkipConditions: [],
    };
  }

  importRadioQuestionFile(question: SurveyQuestionImport, allQuestions: SurveyQuestionImport[]): MasterSurveyQuestion {
    const listOfOptions = allQuestions.filter(questionData => {
      return questionData.id == question.id && questionData.name.includes(this.surveyControlsEnum.choiceFile) && questionData.name;
    });
    const answerLabelsOfQuestion: AnswerLabel[] = [];
    listOfOptions.forEach((option, indexAnswerlabel) => {
      answerLabelsOfQuestion.push(this.onCreateAnswer(option.details, indexAnswerlabel));
    });
    return {
      question: {
        questionType: {
          id: this.questionTypeEnum.RadioButton,
        },
        questionText: question.details.replace(replaceHtmlRegex, '').replace(replaceDoubleQuotesRegex, ''),
        image: null,
        answerLabels: answerLabelsOfQuestion,
        answerProperty: {
          choices: listOfOptions.length,
          isVertical: false,
        },
      },
      questionNote: '',
      language: {
        id: 1
      },
      skill: null,
      sectionQuestionSequenceNo: this.findSequenceQuestion(),
      tempSurveyQuestionId: this.shareSurveyDataService.findMaxQuestionId() + 1,
      isMandatory: true,
      isPageBreakAdded: false,
      isImported: false,
      isVersioned: false,
      questionDisplayCondition: null,
      questionSkipCondition: null,
      questionDisplayConditions: [],
      sectionSkipConditions: [],
    };
  }

  importMultipleQuestionFile(question: SurveyQuestionImport, allQuestions: SurveyQuestionImport[]): MasterSurveyQuestion {
    const listOfOptions = allQuestions.filter(questionData => {
      return questionData.id == question.id && questionData.name.includes(this.surveyControlsEnum.choiceFile) && questionData.name;
    });
    const answerLabelsOfQuestion: AnswerLabel[] = [];
    listOfOptions.forEach((option, indexAnswerlabel) => {
      answerLabelsOfQuestion.push(this.onCreateAnswer(option.details, indexAnswerlabel));
    });
    return {
      question: {
        questionType: {
          id: this.questionTypeEnum.MultipleChoice,
        },
        questionText: question.details.replace(replaceHtmlRegex, '').replace(replaceDoubleQuotesRegex, ''),
        image: null,
        answerLabels: answerLabelsOfQuestion,
        answerProperty: {
          choices: listOfOptions.length
        },
      },
      questionNote: '',
      language: {
        id: 1
      },
      skill: null,
      sectionQuestionSequenceNo: this.findSequenceQuestion(),
      tempSurveyQuestionId: this.shareSurveyDataService.findMaxQuestionId() + 1,
      isMandatory: true,
      isPageBreakAdded: false,
      isImported: false,
      isVersioned: false,
      questionDisplayCondition: null,
      questionSkipCondition: null,
      questionDisplayConditions: [],
      sectionSkipConditions: [],
    };
  }

  importDescriptionQuestionFile(question: SurveyQuestionImport): MasterSurveyQuestion {
    return {
      question: {
        questionType: {
          id: this.questionTypeEnum.Description,
        },
        questionText: question.details.replace(replaceDoubleQuotesRegex, ''),
        image: null,
        answerProperty: {
          isSingleLine: true
        },
      },
      questionNote: '',
      language: {
        id: 1
      },
      skill: null,
      sectionQuestionSequenceNo: this.findSequenceQuestion(),
      tempSurveyQuestionId: this.shareSurveyDataService.findMaxQuestionId() + 1,
      isMandatory: true,
      isPageBreakAdded: false,
      isImported: false,
      isVersioned: false,
      questionDisplayCondition: null,
      questionSkipCondition: null,
      questionDisplayConditions: [],
      sectionSkipConditions: [],
    };
  }

  importMatrixQuestionFile(question: SurveyQuestionImport, allQuestions: SurveyQuestionImport[]): MasterSurveyQuestion {
    const listOfColOptions = allQuestions.filter(questionData => {
      return questionData.id == question.id && questionData.name.includes(this.surveyControlsEnum.choiceFile) && questionData.name;
    });
    const listOfRowOptions = allQuestions.filter(questionData => {
      return questionData.id == question.id && questionData.name.includes(this.surveyControlsEnum.answerFile) && questionData.name;
    });
    let answerLabelsOfQuestion: AnswerLabel[] = [];
    const answerLabelsColOfQuestion: AnswerLabel[] = [];
    const answerLabelsRowOfQuestion: AnswerLabel[] = [];

    listOfRowOptions.forEach((option, indexAnswerlabel) => {
      answerLabelsColOfQuestion.push(this.onCreateAnswer(option.details, indexAnswerlabel));
    });
    listOfColOptions.forEach((option, indexAnswerlabel) => {
      answerLabelsRowOfQuestion.push(this.onCreateColAnswer(option.details, indexAnswerlabel));
    });

    answerLabelsOfQuestion = [...answerLabelsColOfQuestion, ...answerLabelsRowOfQuestion];
    return {
      question: {
        questionType: {
          id: this.questionTypeEnum.MatrixTable,
        },
        questionText: question.details.replace(replaceHtmlRegex, '').replace(replaceDoubleQuotesRegex, ''),
        image: null,
        answerLabels: answerLabelsOfQuestion,
        answerProperty: {
          answerRow: answerLabelsRowOfQuestion.length,
          answerColumn: answerLabelsColOfQuestion.length,
          isRadio: true,
        },
        mapSurveyQuestionWithTags: []
      },
      questionNote: '',
      language: {
        id: 1
      },
      skill: null,
      sectionQuestionSequenceNo: this.findSequenceQuestion(),
      tempSurveyQuestionId: this.shareSurveyDataService.findMaxQuestionId() + 1,
      isMandatory: true,
      isPageBreakAdded: false,
      isImported: false,
      isVersioned: false,
      questionDisplayCondition: null,
      questionSkipCondition: null,
      questionDisplayConditions: [],
      sectionSkipConditions: [],
    };
  }

  onCreateColAnswer(optionText: string, indexAnswerLabel: number): AnswerLabel {
    return {
      labelText: optionText.replace(replaceHtmlRegex, '').replace(replaceDoubleQuotesRegex, ''),
      answerLabelSequenceNo: indexAnswerLabel + 1,
      isRow: true,
    };
  }

  importScaleQuestionFile(question: SurveyQuestionImport): MasterSurveyQuestion {
    return {
      question: {
        questionType: {
          id: this.questionTypeEnum.Scale,
        },
        questionText: question.details.replace(replaceHtmlRegex, '').replace(replaceDoubleQuotesRegex, ''),
        answerLabels: [],
        image: null,
        answerProperty: {
          answerFrom: 1,
          answerTo: 4,
          stepSize: 1,
          labelCount: 0
        },
      },
      questionNote: '',
      language: {
        id: 1
      },
      skill: null,
      sectionQuestionSequenceNo: this.findSequenceQuestion(),
      tempSurveyQuestionId: this.shareSurveyDataService.findMaxQuestionId() + 1,
      isMandatory: true,
      isPageBreakAdded: false,
      isImported: false,
      isVersioned: false,
      questionDisplayCondition: null,
      questionSkipCondition: null,
      questionDisplayConditions: [],
      sectionSkipConditions: [],
    };
  }


  onCreateAnswer(optionText, indexAnswerLabel): AnswerLabel {
    return {
      labelText: optionText.replace(replaceHtmlRegex, '').replace(replaceDoubleQuotesRegex, ''),
      answerLabelSequenceNo: indexAnswerLabel + 1,
      isRow: false,
    };
  }

  findSequenceQuestion(): number {
    const sequenceNo = 0;
    return sequenceNo;
  }
}
