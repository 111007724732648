<form clrForm *ngIf="templateId==0" autocomplete="off" #addForm="ngForm" class="email-template-form">

  <clr-alert clrAlertType="danger" *ngIf="errorMessage !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
    <clr-alert-item>{{errorMessage}}</clr-alert-item>
  </clr-alert>

  <clr-alert clrAlertType="success" *ngIf="emailSaveSuccessAlert" [clrAlertClosable]="true" [clrAlertSizeSmall]="true">
    <clr-alert-item>{{'emailTemplate.savedMsg' | translate}}</clr-alert-item>
  </clr-alert>

  <clr-alert clrAlertType="danger" *ngIf="invalidEmailAlert" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
    <clr-alert-item>{{'emailTemplate.UndefinedPlaceHolder' | translate}}{{undefinedPlaceHolders}}</clr-alert-item>
  </clr-alert>

  <clr-input-container class="clr-row">
    <label class="clr-col-2">{{'emailTemplate.templateName' | translate}} <span>*</span></label>
    <input #emailTempName="ngModel" clrInput placeholder="Enter template name" required name="templateName"
           class="clr-col-3"
           [(ngModel)]="newEmailTemplate.emailTemplateName" [pattern]="specialCharacterPattern"/>
    <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}</clr-control-error>
    <clr-control-error *clrIfError="'required'">{{'emailTemplate.templateNameError' | translate}}</clr-control-error>
  </clr-input-container>

  <clr-textarea-container class="clr-row">
    <label class="clr-col-2">{{'emailTemplate.templateDescription' | translate}}</label>
    <textarea [(ngModel)]="newEmailTemplate.description" class="clr-col-9" clrTextarea name="templateDescription"
              placeholder="Enter description" rows="1"></textarea>
  </clr-textarea-container>

  <clr-select-container class="clr-row">
    <label class="clr-col-2">{{'emailTemplate.emailTemplateType' | translate}} <span>*</span></label>
    <select #select="ngModel" clrSelect name="options" required [(ngModel)]="newEmailTemplate.emailTemplateType.id"
            id="email"
            class="clr-col-3">
      <option *ngFor="let emailTemplateType of emailTemplateTypes" [value]="emailTemplateType.id">
        {{emailTemplateType.emailTemplateTypeName}} </option>
    </select>
    <clr-control-error>{{'emailTemplate.templateTypeError' | translate}}</clr-control-error>
  </clr-select-container>

  <clr-input-container class="clr-row">
    <label class="clr-col-2">{{'emailTemplate.subject' | translate}} <span>*</span></label>
    <input clrInput placeholder="Enter Subject" name="subject" required [(ngModel)]="newEmailTemplate.subject" class="clr-col-9"
      style="width: 37rem;" />
    <clr-control-error>{{'emailTemplate.subjectError' | translate}}</clr-control-error>
  </clr-input-container>
  <br>
  <div>
    <input [ngxSummernote]="config" #r [(ngModel)]="newEmailTemplate.emailBody" id="data" type="text" name="name">
  </div>
  <div class="modal-footer">
    <button class="btn btn-sm btn-secondary"
            (click)="addForm.invalid || this.currentEmailTemplate.id ? isBackToEmailTemplateTable() : showSaveConfirmationDialog = true"
            type="button">{{'emailTemplate.backBtn' |
      translate}}</button>
    <button class="btn btn-sm btn-primary" (click)="onSaveEmailTemplate(newEmailTemplate)"
            [disabled]="addForm.invalid || this.currentEmailTemplate.id">{{'emailTemplate.saveBtn'
      | translate}}</button>
  </div>
</form>

<!--For edit Template-->
<form clrForm *ngIf="templateId!=0" (change)="setIsFormUpdated()" autocomplete="off" #editForm="ngForm"
      class="email-template-form">
  <clr-alert clrAlertType="danger" *ngIf="errorMessage !== ''" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
    <clr-alert-item>{{errorMessage}}</clr-alert-item>
  </clr-alert>

  <clr-alert clrAlertType="success" *ngIf="emailSaveSuccessAlert" [clrAlertClosable]="true" [clrAlertSizeSmall]="true">
    <clr-alert-item>{{'emailTemplate.savedMsg' | translate}}</clr-alert-item>
  </clr-alert>

  <clr-alert clrAlertType="danger" *ngIf="invalidEmailAlert" [clrAlertClosable]="false" [clrAlertSizeSmall]="true">
    <clr-alert-item>{{'emailTemplate.UndefinedPlaceHolder' | translate}}{{undefinedPlaceHolders}}</clr-alert-item>
  </clr-alert>

  <clr-input-container class="clr-row">
    <label class="clr-col-2">{{'emailTemplate.templateName' | translate}}<span>*</span></label>
    <input #editEmailTempName="ngModel" clrInput placeholder="Enter template name" required name="templateName"
           class="clr-col-3"
           [(ngModel)]="currentEmailTemplate.emailTemplateName" [pattern]="specialCharacterPattern"/>
    <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}</clr-control-error>
    <clr-control-error *clrIfError="'required'">{{'emailTemplate.templateNameError' | translate}}</clr-control-error>
  </clr-input-container>
  <clr-textarea-container>
    <label class="clr-col-2">{{'emailTemplate.templateDescription' | translate}}</label>
    <textarea [(ngModel)]="currentEmailTemplate.description" class="clr-col-9" clrTextarea name="templateDescription"
              placeholder="Enter description" rows="1"></textarea>
    <clr-control-error *clrIfError="'pattern'">{{'errorMsg.specialCharacterErrorMsg' | translate}}</clr-control-error>
  </clr-textarea-container>
  <clr-select-container class="clr-row">
    <label class="clr-col-2">{{'emailTemplate.emailTemplateType' | translate}} <span>*</span></label>
    <select clrSelect name="options" id="editTemplateType" [(ngModel)]="currentEmailTemplate.emailTemplateType.id" class="clr-col-3"
      required style="width: 8rem;">
      <option *ngFor="let emailTemplateType of emailTemplateTypes" [ngValue]="emailTemplateType.id">
        {{emailTemplateType.emailTemplateTypeName}} </option>
    </select>
    <clr-control-error>{{'emailTemplate.templateTypeError' | translate}}</clr-control-error>
  </clr-select-container>

  <clr-input-container class="clr-row">
    <label class="clr-col-2">{{'emailTemplate.subject' | translate}}<span>*</span></label>
    <input clrInput required placeholder="Enter Subject" name="subject" [(ngModel)]="currentEmailTemplate.subject"
           class="clr-col-9"
           style="width: 37rem;"/>
    <clr-control-error>{{'emailTemplate.subjectError' | translate}}</clr-control-error>
  </clr-input-container>
  <br>
  <div>
    <div [ngxSummernote]="config" [(ngModel)]="currentEmailTemplate.emailBody" (ngModelChange)="setIsFormUpdated()"
         id="data1" type="text" name="name"></div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-sm btn-secondary"
            (click)="isFormUpdated ? showSaveConfirmationDialog = true : isBackToEmailTemplateTable()"
            type="button">{{'emailTemplate.backBtn' |
      translate}}</button>
    <button class="btn btn-sm btn-primary" (click)="onUpdateEmailTemplate(currentEmailTemplate)"
            [disabled]="editForm.invalid || !this.isFormUpdated">{{'emailTemplate.saveBtn'
      | translate}}</button>
  </div>
</form>

<clr-modal [(clrModalOpen)]="showSaveConfirmationDialog" class="showMasetertableButton">
  <h3 class="modal-title">{{'emailTemplate.saveWarningMsg' | translate}}</h3>
  <div class="modal-body">{{'emailTemplate.saveWarningMsg2' | translate}}</div>
  <div class="modal-footer">
    <button (click)="isBackToEmailTemplateTable()" class="btn btn-outline btn-sm">{{'emailTemplate.discard' |
      translate}}</button>
    <button (click)="onSaveEmailTemplate(currentEmailTemplate.id ? currentEmailTemplate : newEmailTemplate)"
            class="btn btn-primary btn-sm">{{'emailTemplate.saveBtn' |
      translate}}</button>
  </div>
</clr-modal>
