export enum SurveyControls {
  id = 'id',
  skill = 'skill',
  sectionQuestionSequenceNo = 'sectionQuestionSequenceNo',
  questionNote = 'questionNote',
  questionText = 'questionText',
  isImported = 'isImported',
  isPageBreakAdded = 'isPageBreakAdded',
  image = 'image',
  answerProperty = 'answerProperty',
  isSingleLine = 'isSingleLine',
  answerLabels = 'answerLabels',
  answerRow = 'answerRow',
  answerColumn = 'answerColumn',
  labelText = 'labelText',
  isRadio = 'isRadio',
  answerLabelSequenceNo = 'answerLabelSequenceNo',
  choices = 'choices',
  isVertical = 'isVertical',
  answerFrom = 'answerFrom',
  answerTo = 'answerTo',
  stepSize = 'stepSize',
  labelCount = 'labelCount',
  isMandatory = 'isMandatory',
  sectionImage = 'sectionImage',
  matrixCols = 'matrixCols',
  matrixRows = 'matrixRows',
  index = 'index',
  isChecked = 'isChecked',
  selectRoleProfile = 'Select Role Profile',
  descriptionText = 'Description Question',
  MissingSectionMessage = 'Missing section name at section sequence ',
  DuplicateSectionMessage = 'Duplicate Section name found: ',
  SectionNamePatternMessage = 'Section name can not have special character. Please check section name ',
  MissingQuestionTextMessage = 'Missing question text at ',
  MissingSectionTextMessage = 'Missing section text at ',
  InvalidQuestionTypeTextMessage = 'Invalid question type text at ',
  MissingQuestionPropertyMessage = 'Missing question property at ',
  QuestionSequeneMessage = ' section, in question sequence number ',
  MisingAnswerLabelMessage = 'Missing answer label text at ',
  MisingPropertyMessage = 'Missing answer property in question at ',
  MissingSurveyNameMessage = 'Missing survey name',
  PageBreakErrorMessage = 'Please remove page break from last question at survey.',
  survey = 'Survey',
  section = 'Section',
  question = 'Question',
  optionsScale = 'optionsScale',
  imageFile = "imageFile",
  skillGroupBackEndMessage = 'Skill group id 0 is not present in table',
  skilllGroupRequiredMessage = 'Skill Group is required.',
  isTechnicalQuestion = "isTechnicalQuestion",
  mapSurveyQuestionWithTags = "mapSurveyQuestionWithTags",
  surveyQuestionTag = "surveyQuestionTag",
  zeroPixel = "0px",
  pixel = "px",
  radio = "radio",
  multiChoice = "multiChoice",
  scaleErrorMessage = "Scale Label can not exceed more then 10 labels.",
  scalePropertMessage = "Missing question property.",
  lastModifiedDate = 'lastModifiedDate',
  createdDate = 'createdDate',
  errorTrainingPreRequisite = 'Training pre requisite and course name can not be same.',
  isImportedSection = 'isImportedSection',
  duplicateSurveyError = 'Survey name already exists.',
  patternSurveyError = 'Survey name can not have special character',
  ksaStatusClosed = 'KSA status is Closed',
  questionTextFile = 'QuestionText',
  choiceFile = 'Choice',
  answerFile = 'Answer',
  MissingHeader = 'Missing header at csv',
  InvalidCsvFile = 'Invalid CSV file, please check the extension of selected file.',
  csv = 'csv'
}
