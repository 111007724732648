/* ********************************************************************* *
Copyright 2021 VMware, Inc. All rights reserved. VMware Confidential *
**********************************************************************/
import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {RoleProfileGroup} from "../models/role-profile-groups.model";
import {environment} from "../../environments/environment";
import {API_KSA_CORE_V1} from "../common/constants/api-prefix.constants";

@Injectable({providedIn: 'root'})
export class RoleProfileGroupService {

  private roleProfileGroupUrl: string;

  constructor(private http: HttpClient) {
    this.roleProfileGroupUrl = `${environment.API_URL}${API_KSA_CORE_V1}/roleprofilegroup/`;
  }

  getRoleProfilesGroups(surveyTemplateConfigId, filter1, filter2, filter3, from, to, sortby, sortorder): Observable<RoleProfileGroup> {
    filter1 = encodeURIComponent(filter1);
    filter3 = encodeURIComponent(filter3);
    return this.http.get<RoleProfileGroup>(`${this.roleProfileGroupUrl}?surveyTemplateConfigVersionId=${surveyTemplateConfigId}&name=${filter1}&roleProfileGroupRoleProfileMaps.roleProfile.name=${filter2}&description=${filter3}&page=${from}&size=${to}&sort=${sortby},${sortorder === true ? 'ASC' : 'DESC'}`)
  }

  getSelectedRoleProfiles(id: number, surveyTemplateConfigVersionId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.roleProfileGroupUrl}roleprofile/${id}/survey-template-configs/${surveyTemplateConfigVersionId}`);
  }

  createRoleProfileGroup(roleProfileGroup: any): Observable<any> {
    return this.http.post(`${this.roleProfileGroupUrl}`, roleProfileGroup);
  }

  updateRoleProfileGroup(roleProfileGroup: any): Observable<any> {
    return this.http.put(`${this.roleProfileGroupUrl}`, roleProfileGroup);
  }

  deleteRoleProfileGroup(id: number): Observable<any> {
    return this.http.delete(`${this.roleProfileGroupUrl}${id}`);
  }

  findRoleProfileGroupsBySurveyTemplate(surveyTemplateId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.roleProfileGroupUrl}master-survey/${surveyTemplateId}/`);
  }
}
