import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'upperCamelCase'
})
export class UpperCamelCasePipe implements PipeTransform {

  transform(value: any): string {
    if (value == null || value.length === 0) {
      return '';
    }

    if (value.length === 1) {
      return value.toUpperCase();
    }

    return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
  }

}
