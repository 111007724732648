/*
 *  *********************************************************************
 *  * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 *  * ********************************************************************
 */

import {Pipe, PipeTransform} from '@angular/core';
import {areLocaleEnumsEqual, LocalizationLocaleEnum} from "../../enums/localization-locale.enum";

@Pipe({
  name: 'displayLocalizedAnswerLabel'
})
export class DisplayLocalizedAnswerLabelPipe implements PipeTransform {

  transform(answer: any, language: string | LocalizationLocaleEnum): string {
    if (areLocaleEnumsEqual(language, LocalizationLocaleEnum.ENGLISH)) {
      return answer.labelText;
    }

    return answer.answerLabelLocalizations.filter(all => areLocaleEnumsEqual(all.locale, language))[0].text;
  }

}
